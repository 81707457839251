//font-size
$('#font-size').on('click', function() {
  if ('fontSize' in localStorage) {
      localStorage.removeItem('fontSize');
      $('#font-size').removeClass('active');
      $('html').removeClass('large-text');
  } else {
      $('html').addClass('large-text');
      $('#font-size').addClass('active');
      localStorage.setItem('fontSize', true);
  }
});

$('document').ready(function(){
  if ('fontSize' in localStorage) {
      $('html').addClass('large-text');
      $('#font-size').addClass('active');
  } else {
      $('html').removeClass('large-text');
      $('#font-size').removeClass('active');
  }
});

//contrast
$('#contrast').on('click', function(){
  if ('contrast' in localStorage) {
      localStorage.removeItem('contrast');
      $('#contrast').removeClass('active');
      $('body').removeClass('contrast');
  } else {
      $('body').addClass('contrast');
      $('#contrast').addClass('active');
      localStorage.setItem('contrast', true);
  }
});

$('document').ready(function(){
  if ('contrast' in localStorage) {
      $('body').addClass('contrast');
      $('#contrast').addClass('active');
  } else {
      $('body').removeClass('contrast');
      $('#contrast').remove('active');
  }
});