var setTitle = function(){
    if($('html').attr('lang') === "en"){
        return "EBITDA bridge of the Generation Business Line (m PLN)"
    } else {
        return "EBITDA Bridge Linii Biznesowej Wytwarzanie (mln zł)"
    }
}


if($("#EBITDA_bridge_linii_biznesowej_wytwarzanie").length > 0){
    Highcharts.chart('EBITDA_bridge_linii_biznesowej_wytwarzanie', {
        chart: {
            type: 'waterfall',
            height: 550
        },
        plotOptions: {
            animation: false,
            waterfall: {
                maxPointWidth: 45,
                dataLabels: {
                    verticalAlign: 'top',
                    y: - 30,
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"12px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            }
        },   
        title:{
            text: setTitle(),
            style:{
                fontFamily:"\"Energa\", sans-serif",
                fontSize:"18px",
                fontWeight:"bold",
                fontStyle:"normal",
                color:"#333333"
            }
        },
    
        xAxis: {
            type: 'category',
            labels: {
                rotation: 0,
                step: 1,
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"11px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"                
                }                
             },
        },
    
        yAxis: {
            title: {
                text: 'mln zł'
            },
            visible: false
        },
    
        legend: {
            enabled: false
        },
        exporting: { enabled: false }, 
        tooltip: {
            pointFormat: "{point.y:,.1f} mln zł"
        },  
        series: [{
            data: [
            {
                name: 'EBIDA 2017',
                y: 398,
                color: "#660033"
            }, {
                name: 'Przychody ze sprzedaży energii',
                y: 80,
                color: "#bfd73b"
            }, {
                name: 'Przychody z sprzedaży zielonych praw majatkowych',
                y: 35,
                color: "#bfd73b"
            }, {
                name: 'Koszt zakupu uprawnień do emisji',
                y: -116,
                color: "#bfd73b"
            }, {
                name: 'Koszt zużycia kluczowych paliw do produkcji',
                y: -41,
                color: "#bfd73b"
            }, {
                name: 'Pozostałe przychody/koszty',
                y: -27,
                color: "#bfd73b"
            }, {
                name: 'EBITDA 2018',
                y: 329,
                isSum: true,
                color: "#660033"
            }
        ],
            dataLabels: {
                enabled: true,
                format: "{point.y}",
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"14px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            },
            pointPadding: 0
        }]
    });
}
