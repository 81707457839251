export function getItemsNumber(){
  var itemsNumber = $('#slider > ul > li').length;

  if(itemsNumber < 4 ){
    return itemsNumber;
  }else{
    return 4;
  }
}

