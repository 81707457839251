var setTitle = function(){
    if($('html').attr('lang') === "en"){
		console.log("EBITDA bridge by Business Lines (m PLN) in 2015-2018");
		
        return "EBITDA bridge by Business Lines (m PLN) in 2015-2018"
    } else {
        return "EBITDA bridge w podziale na linie biznesowe w okresie 2015-2018 (mln zł)"
    }
}

if($("#chart-EBITDA_bridge_w_podziale_na_linie_biznesowe_w_okresie_2015-2018").length > 0){
    Highcharts.chart('chart-EBITDA_bridge_w_podziale_na_linie_biznesowe_w_okresie_2015-2018', {
        chart: {
            type: 'waterfall',
            height: 550
        },
        plotOptions: {
            animation: false,
            waterfall: {
                maxPointWidth: 45,
                dataLabels: {
                    verticalAlign: 'top',
                    y: - 30,
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"14px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            }
        },    
        title:{
            text: setTitle(),
            style:{
                fontFamily:"\"Energa\", sans-serif",
                fontSize:"18px",
                fontWeight:"bold",
                fontStyle:"normal",
                color:"#333333"
            }
        },
        tooltip:{
            enabled:false
        }, 
        xAxis: {
            type: 'category',
            labels: {
                rotation: 0,
                step: 1,
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"9px",
                    fontWeight:"normal",
                    fontStyle:"normal",
                    color:"#333333"                
                }
            },
        },
        yAxis: {
            title: {
                text: 'mln zł'
            },
            visible: false
        },
        legend: {
            enabled: false
        },
        tooltip:{
            enabled: false
        },
        series: [{
            data: [
            {
                name: 'EBIDA 2015',
                y: 2216,
                color: "#660033"
            }, {
                name: 'Linia Biznesowa Dystrybucja 2015',
                y: 36,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Sprzedaż 2015',
                y: -133,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Wytwarzanie 2015',
                y: -77,
                color: "#bfd73b"
            }, {
                name: 'Pozostałe i korekty 2015',
                y: -15,
                color: "#bfd73b"
            }, {
                name: 'EBIDA 2016',
                y: 20217,
                isSum: true,
                color: "#660033"
            }, {
                name: 'Linia Biznesowa Dystrybucja 2016',
                y: 16,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Sprzedaż 2016',
                y: 45,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Wytwarzanie 2016',
                y: 83,
                color: "#bfd73b"
            }, {
                name: 'Pozostałe i korekty 2016',
                y: -11,
                color: "#bfd73b"
            }, {
                name: 'EBIDA 2017',
                y: 2160,
                isSum: true,
                color: "#660033"
            }, {
                name: 'Linia Biznesowa Dystrybucja 2017',
                y: -28,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Sprzedaż 2017',
                y: -33,
                color: "#bfd73b"
            }, {
                name: 'Linia Biznesowa Wytwarzanie 2017',
                y: -69,
                color: "#bfd73b"
            }, {
                name: 'Pozostałe i korekty 2017',
                y: -17,
                color: "#bfd73b"
            }, {
                name: 'EBITDA 2018',
                y: 2013,
                isSum: true,
                color: "#660033"
            }, 
        ],
            dataLabels: {
                format: "{point.y}",
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"14px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            },
            pointPadding: 0
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 768
                },
                chartOptions: {
                    xAxis: {
                        labels: {
                            rotation: 90,
                        }
                    }
                }
            }]
        }
    });
}
