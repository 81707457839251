if($('.download-center__nav').length > 0) {
  const navStart = $('.download-center__nav').offset().top;
  let flag = false
  $(window).scroll(function(event) {
    let scroll = $(window).scrollTop();
    const nav = $('.download-center__nav').offset().top;
    if(scroll >= nav && flag == false) {
      $('.download-center__nav').addClass('sticky');
      flag = true;
    } else if(scroll < navStart) {
      $('.download-center__nav').removeClass('sticky');
      flag = false;
    }
  });
}

$('.select-all').on('change', function() {
    var checked = this.checked;
    $(this).parents('.accordion__item').first().find("input").each(function(){
        this.checked = checked;
    })
});

$('#select-all').on('click', function() {
  $('input:checkbox').prop('checked',true);
});

$('#uncheck-all').on('click', function() {
  $('input:checkbox').prop('checked',false);
});