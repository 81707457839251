import AOS from 'aos';
import 'aos/dist/aos.css';
import { CountUp } from 'countup.js';
AOS.init();
let flag = true;

$(window).scroll(function(){

  if($('#countup').length > 0) {
    let offsetTop = $('#countup').offset().top;
    let outerHeight = $('#countup').outerHeight();
  
    if($(this).scrollTop() > (offsetTop + outerHeight - $(window).height()) && flag == true) {
      const options = {
        duration: 1.5,
        separator: ' '
      };
    
      var countUp = new CountUp('countup',1190, options);
      countUp.start();   
      flag = false;
    }
  }
})
