export const translations = [
    //rok-2018-najwazniejsze-dane-i-wydarzenia
    {
        pl: 'EBITDA (mln zł)',
        en: 'EBITDA (PLN m)'
    },
    {
        pl: 'Przychody (mln zł)',
        en: 'Revenue (PLN m)'
    },
    {
        pl: 'Zysk netto (mln zł)',
        en: 'Net profit (PLN m)'
    },
    {
        pl: 'EBITDA w podziale na Linie Biznesowe (mln zł)',
        en: 'EBITDA by Business Line (PLN m)'
    },
    {
        pl: 'Linia Biznesowa Sprzedaż 2015',
        en: 'Sales 2015'
    },
    {
        pl: 'Linia Biznesowa Sprzedaż 2016',
        en: 'Sales 2016'
    },
    {
        pl: 'Linia Biznesowa Sprzedaż 2017',
        en: 'Sales 2017'
    },
    {
        pl: 'Linia Biznesowa Wytwarzanie 2015',
        en: 'Generation 2015'
    },
    {
        pl: 'Linia Biznesowa Wytwarzanie 2016',
        en: 'Generation 2016'
    },
    {
        pl: 'Linia Biznesowa Wytwarzanie 2017',
        en: 'Generation 2017'
    },
    {
        pl: 'Linia Biznesowa Dystrybucja 2015',
        en: 'Distribution 2015'
    },
    {
        pl: 'Linia Biznesowa Dystrybucja 2016',
        en: 'Distribution 2016'
    },
    {
        pl: 'Linia Biznesowa Dystrybucja 2017',
        en: 'Distribution 2017'
    },
    {
        pl: 'Pozostałe i korekty 2015',
        en: 'Other and adjustment 2015'
    },
    {
        pl: 'Pozostałe i korekty 2016',
        en: 'Other and adjustment 2016'
    },
    {
        pl: 'Pozostałe i korekty 2017',
        en: 'Other and adjustment 2017'
    },
    {
        pl: 'Wskaźniki rentowności i płynności',
        en: 'Profitability and liquidity ratios'
    },
    {
        pl: 'Wskaźniki rentowności i płynności',
        en: 'Profitability and liquidity ratios'
    },
    {
        pl: 'marża EBITDA',
        en: 'EBITDA margin'
    },
    {
        pl: 'marża zysku',
        en: 'profit margin'
    },
    {
        pl: 'Dystrybucja',
        en: 'Distrybution'
    },
    {
        pl: 'Wytwarzanie',
        en: 'Generation'
    },
    {
        pl: 'Sprzedaż',
        en: 'Sales'
    },
    {
        pl: 'Pozostałe i korekty',
        en: 'Other and adjustments'
    },
    {
        pl: 'Linia Biznesowa Dystrybucja 2015',
        en: 'Other and adjustments'
    },
    {
        pl: 'rentowność  kapitałów własnych',
        en: 'return on equity (ROE)'
    },
    {
        pl: 'rentowność majątku',
        en: 'return on assets (ROA)'
    },
    {
        pl: 'wskaźnik płynności bieżącej',
        en: 'current liquidity ratio'
    },
    {
        pl: 'wskaźnik długu netto/EBITDA',
        en: 'net debt/EBITDA ratio'
    },
    // kluczowe dane operacyjne
    {
        pl: 'Dystrybucja energii elektrycznej (TWh)',
        en: 'Electricity distribution'
    },
    {
        pl: 'Dystrybucja energii elektrycznej wg grup taryfowych (TWh)',
        en: 'Electricity distribution by tariff group'
    },
    {
        pl: 'Taryfa A (HV)',
        en: 'Tariff A (HV)'
    },
    {
        pl: 'Taryfa B (MV)',
        en: 'Tariff B (MV)'
    },
    {
        pl: 'Taryfa C (LV)',
        en: 'Tariff C (LV)'
    },
    {
        pl: 'Taryfa G (LV)',
        en: 'Tariff G (LV)'
    },
    {
        pl: 'Poziom wskaźnika SAID (zakłóceń na odbiorcę)',
        en: 'SAIDI (Minutes per customer in the period)'
    },
    {
        pl: 'Poziom wskaźnika SAIFI (zakłóceń na odbiorcę)',
        en: 'SAIFI (disruptions per customer in the period)'
    },
    {
        pl: 'Planowane',
        en: 'Planned'
    },
    {
        pl: 'Nieplanowane z katastrofami',
        en: 'Unplanned incl catastrophic'
    },
    {
        pl: 'Produkcja energii elektrycznej brutto (TWh)',
        en: 'Gross electricity production (TWh)'
    },
    {
        pl: 'Sprzedaż detaliczna energii w TWh',
        en: 'Retail sales of electricity'
    },
    // Energa na tle branży
    {
        pl: 'Dystrybucja (TWh)',
        en: 'Comparison of distribution'
    },
    {
        pl: 'Produkcja Energii elektrycznej OZE (GWh)',
        en: 'Generation of electricity from RES (GWh)'
    },
    // Sytuacja makroekonomiczna
    {
        pl: 'Roczna dynamika PKB, popytu krajowego, spożycia indywidualnego oraz nakładów inwestycyjnych',
        en: 'Annual changes in GDP, domestic demand, individual consumption and capital expenditures'
    },
    {
        pl: 'Popyt krajowy',
        en: 'Domestic demand'
    },
    {
        pl: 'Sprzedaż indywidualna',
        en: 'Individual consumption'
    },
    {
        pl: 'Capital expenditiures',
        en: 'Individual consumption'
    },
    {
        pl: 'PKB',
        en: 'GDB'
    },
    //  Rynek energii elektrycznej w Polsce
    {
        pl: 'Produkcja i zużycie Energii elektrycznej w Polsce',
        en: 'Production and consumption of electricity in Poland in 2009-2018 (TWh)'
    },
    {
        pl: 'Zużycie energii',
        en: 'Consumption'
    },
    {
        pl: 'Produkcja energii',
        en: 'Production'
    },
    {
        pl: 'Struktura produkcji energii elektrycznej w Polsce w latach 2009-2018 (TWh)',
        en: 'Power generation mix in Poland in 2009-2018 (TWh)'
    },
    {
        pl: 'El. zawodowe na węglu kamiennym',
        en: 'Coal-fired electricity plants'
    },
    {
        pl: 'El. zawodowe na węglu brunatnym',
        en: 'Lignite-fired electricity plants'
    },
    {
        pl: 'El. zawodowe wodne',
        en: 'Hydro electricity plants'
    },
    {
        pl: 'El. zawodowe gazowe',
        en: 'Gas-fired electricity plants'
    },
    {
        pl: 'El. przemysłowe',
        en: 'Industrial power plants'
    },
    {
        pl: 'El. wiatrowe',
        en: 'Wind farms'
    },
    {
        pl: 'Roczne wolumeny wymiany międzysystemowej w Polsce w latach 2009-2018 (TWh)',
        en: 'Monthly cross-border power exchange volumes in Poland 2009-2018 (TWh)'
    },
    {
        pl: 'Ceny energii na rynku SPOT w Polsce i krajach sąsiadujących w 2018 roku (zł/MWh)',
        en: 'Prices of energy in the SPOT market in Poland and in neighbouring countries in 2018 (PLN/MWh)'
    },
    {
        pl: 'Polska',
        en: 'Poland'
    },
    {
        pl: 'Niemcy',
        en: 'Germany'
    },
    {
        pl: 'Skandynawia',
        en: 'Scandinavia'
    },
    {
        pl: 'Ceny węgla kamiennego z kosztem transportu dla elektrowni na węglu kamiennym na koniec roku w latach 2013-2018 (zł/GJ)',
        en: 'Prices of coal including cost of transport for coal-fired power plants at year-end in 2013-2018 (PLN/GJ) '
    },
    {
        pl: 'Ceny Energii RDN',
        en: 'Energy prices on the Day-Ahead Market in 2017-2018 (PLN/MWh)'
    },
    {
        pl: 'styczeń',
        en: 'Jan.'
    },
    {
        pl: 'luty',
        en: 'Feb.'
    },
    {
        pl: 'marzec',
        en: 'Mar.'
    },
    {
        pl: 'kwiecień',
        en: 'Apr.'
    },
    {
        pl: 'maj',
        en: 'May'
    },
    {
        pl: 'czerwiec',
        en: 'June'
    },
    {
        pl: 'lipiec',
        en: 'July'
    },
    {
        pl: 'sierpień',
        en: 'Aug.'
    },
    {
        pl: 'wrzesień',
        en: 'Sept.'
    },
    {
        pl: 'październik',
        en: 'Oct.'
    },
    {
        pl: 'listopad',
        en: 'Nov.'
    },
    {
        pl: 'grudzień',
        en: 'Dec.'
    },
    {
        pl: 'Cena kontraktu terminowego pasmo z dostawą w 2019 roku',
        en: 'Base-load electricity futures price with delivery in 2019'
    },
    {
        pl: 'Wolumen [MW]',
        en: 'Volume [MW]'
    },
    {
        pl: 'Wolumen [MW]',
        en: 'Prices [PLN/MWh]'
    },
    {
        pl: 'Ceny uprawnień do emisji (EUA DEC 2018) w 2018 roku',
        en: 'Prices for emission allowances (EUA DEC 2018) in 2018'
    },
    {
        pl: 'Zestawienie cen na rynku bilansującym i SPOT (Giełda) w 2018 roku (zł/MWh)',
        en: 'Comparison of prices on the balancing market and SPOT market (exchange) in 2018 (PLN/MWh)'
    },
    {
        pl: 'Rynek bilansujący - średnia dobowa',
        en: 'Balancing market - daily avarage'
    },
    {
        pl: 'Giełda - średnia dobowa',
        en: 'SPOT market (exchange) - daily avarage'
    },
    {
        pl: 'Zestawienie cen oraz zdolności wytwórczych stanowiących operacyjną rezerwę mocy w 2018 roku',
        en: 'Prices and generating capacities constituting the operating reserve in 2018'
    },
    {
        pl: 'Zestawienie cen oraz zdolności wytwórczych stanowiących operacyjną rezerwę mocy w 2018 roku',
        en: 'Prices and generating capacities constituting the operating reserve in 2018'
    },
    {
        pl: 'Zdolności wytwórcze stanowiące rezerwę mocy',
        en: 'Generating capacities con situating the operating reserve'
    },
    {
        pl: 'Cena za rezerwę operacyjną',
        en: 'Price of the operating reserve'
    },
    {
        pl: 'Stawka maksymalna [PLN/MWh]',
        en: 'Reference price (PLN/MWh)'
    },
    //linia biznesowa dystrybucja
    {
        pl: "Liczba odbiorców Energi-Operatora SA w tysiącach",
        en: "Number of customers of Energa Operator SA in thousands"
    },
    {
        pl: "Stan na 31 grudnia 2015",
        en: "As at 31 Dec. 2015"
    },
    {
        pl: "Stan na 31 grudnia 2016",
        en: "As at 31 Dec. 2016"
    },
    {
        pl: "Stan na 31 grudnia 2017",
        en: "As at 31 Dec. 2017"
    },
    {
        pl: "Stan na 31 grudnia 2018",
        en: "As at 31 Dec. 2018"
    },
    // linia biznesowa wytwarzanie
    {
        pl: "Źródła energii generowanej w elektrowniach Grupy ENERGA:",
        en: "Sources of energy generated in the ENERGA Group's power plants"
    },
    {
        pl: "Węgiel",
        en: "Coal"
    },
    {
        pl: "Woda",
        en: "Hydro"
    },
    {
        pl: "Wiatr",
        en: "Wind"
    },
    {
        pl: "Biomasa",
        en: "Biomass"
    },
    {
        pl: "Fotowoltaika",
        en: "Photovoltaic"
    },
    // linia biznesowa sprzedaż
    {
        pl: "Na koniec 2015 roku",
        en: "At the end of 2015"
    },
    {
        pl: "Na koniec 2016 roku",
        en: "At the end of 2016"
    },
    {
        pl: "Na koniec 2017 roku",
        en: "At the end of 2017"
    },
    {
        pl: "Na koniec 2018 roku",
        en: "At the end of 2018"
    },
    {
        pl: "Gospodarstwa domowe",
        en: "Household"
    },
    {
        pl: "Odbiorcy biznesowi",
        en: "Bussiness customers"
    },
    //  Wyniki finansowe według obszarów działalności
    {
        pl: "Wyniki EBITDA Grupy Energa w podziale na Linie Biznesowe (mln zł) ",
        en: "EBITDA of the Energa SA Group, by Business Line (PLN m)"
    },
    {
        pl: "POZOSTAŁE oraz wyłacznia i korekty konsolidacyjne",
        en: "OTHER and consolidation eliminations and adjustments"
    },
    {
        pl: "Wyniki Linii Biznesowej Dystrybucja Grupy ENERGA (mln zł)",
        en: "Results of the Distribution Business Line of the Energa Group (m PLN)"
    },
    {
        pl: "Przychody",
        en: "Results of the Distribution Business Line of the Energa Group (m PLN)"
    },
    {
        pl: "Przychody",
        en: "Revenues"
    },
    {
        pl: "Marża na dystrybucji (ze stratami sieciowymi)",
        en: "Distribution margin (incl. grid lasses)"
    },
    {
        pl: "Przychody z przyłączy",
        en: "Connection revenues"
    },
    {
        pl: "OPEX Linii Biznesowej",
        en: "Bussiness line distribution's OPEX"
    },
    {
        pl: "Podatek od nieruchomości",
        en: "Property tax"
    },
    {
        pl: "Wyniki na pozostałej działalności operacyjnej",
        en: "Other"
    },
    {
        pl: "Wyniki Linii Biznesowej Wytwarzanie Grupy Energa (mln zł)",
        en: "Results of the Generation Business Line of the Energa Group (m PLN)"
    },
    {
        pl: "EBITDA Bridge Linii Biznesowej Wytwarzanie (mln zł)",
        en: "EBITDA bridge of the Generation Business Line (m PLN)"
    },
    {
        pl: "Przychody ze sprzedaży energii",
        en: "Revenues on sales of electricity"
    },
    {
        pl: "Przychody z sprzedaży zielonych praw majatkowych",
        en: "Revenues on 'green' property rights"
    },
    {
        pl: "Koszt zakupu uprawnień do emisji",
        en: "Costs of purchasing emission allowances"
    },
    {
        pl: "Koszt zużycia kluczowych paliw do produkcji",
        en: "Cost of main fuel consumption for production"
    },
    {
        pl: "Pozostałe przychody/koszty",
        en: "Other income/costs"
    },
    {
        pl: "Wyniki Linii Biznesowej Sprzedaż Grupy Energa (mln zł)",
        en: "EBITDA bridge of the Sales Business Line (m PLN)"
    },
    //zatrudnienie w grupe energa
    {
        pl: "Rysunek 9: Zatrudnienie w Grupie Energa według stanu na dzień 31 grudnia 2015, 2016, 2017 i 2018 roku (w osobach)*",
        en: "Energa Group’s headcount as at 31 December 2015, 2016, 2017 and 2018"
    },
    {
        pl: "DYSTRYBUCJA",
        en: "DYSTRYBUTION"
    },
    {
        pl: "WYTWARZANIE",
        en: "GENERATION"
    },
    {
        pl: "SPRZEDAŻ",
        en: "SALES"
    },
    {
        pl: "Rysunek 10: Przeciętne zatrudnienie w Grupie Energa w 2015, 2016, 2017 i 2018 roku (w etatach)*",
        en: "Energa Group’s average employment in 2015, 2016, 2017 and 2018 (FTEs)*"
    },
    {
        pl: "Zatrudnienie w Grupie Energa według stanu na dzień 31 grudnia 2018 roku (w osobach) wg płci",
        en: "Energa Group’s headcount as at 31 December 2018 by gender"
    },
    {
        pl: "Kobiety",
        en: "Women"
    },
    {
        pl: "Mężczyźni",
        en: "Men"
    },
    {
        pl: "Zatrudnienie w Grupie Energa według stanu na dzień 31 grudnia 2018 roku (w osobach) wg wykształcenia",
        en: "Energa Group’s headcount as at 31 December 2018 by education"
    },
    {
        pl: "Podstawowe",
        en: "Basick education"
    },
    {
        pl: "Zawodowe",
        en: "Vocational education"
    },
    {
        pl: "Średnie",
        en: "Secondary education"
    },
    {
        pl: "Wyźsze",
        en: "Higher education"
    },
    {
        pl: "Zatrudnienie w Grupie Energa według stanu na dzień 31 grudnia 2018 roku (w osobach) wg wieku",
        en: "Energa Group’s headcount as at 31 December 2018 by age"
    },
    {
        pl: "< 30 lat",
        en: "age < 30"
    },
    {
        pl: "30-50 lat",
        en: "30-50 age"
    },
    {
        pl: "> 50 lat",
        en: "age > 50"
    },
];