"use strict";

var _translations = require("./../chart/translations");

var translator = function translator(word) {
  if (_translations.translations.some(function (translations) {
      return translations.pl === word;
    })) {
    if ($('html').attr('lang') === "en") {
      for (var i in _translations.translations) {
        if (_translations.translations[i].pl === word) {
          return _translations.translations[i].en;
        }
      }
    } else {
      return word;
    }
  } else {
    return word;
  }
};

var langOption = function langOption() {
  if ($('html').attr('lang') === "pl") {
    return {
      decimalPoint: ',',
      thousandsSep: ' ',
      numericSymbols: null,
      months: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czrwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
      printChart: 'Wydrukuj',
      downloadPNG: 'Pobierz PNG',
      downloadJPEG: 'Pobierz JPEG',
      downloadPDF: 'Pobierz PDF',
      downloadSVG: "Pobierz SVG",
      downloadCSV: "Pobierz CSV",
      downloadXLS: "Pobierz XLS",
      viewData: "Pokaż dane",
      openInCloud: "Otwórz w edytorze"
    };
  }

  if ($('html').attr('lang') === "en") {
    return {
      decimalPoint: ',',
      thousandsSep: ' ',
      numericSymbols: null,
      months: ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."],
      printChart: 'Wydrukuj',
      downloadPNG: 'Pobierz PNG',
      downloadJPEG: 'Pobierz JPEG',
      downloadPDF: 'Pobierz PDF',
      downloadSVG: "Pobierz SVG",
      downloadCSV: "Pobierz CSV",
      downloadXLS: "Pobierz XLS",
      viewData: "Pokaż dane",
      openInCloud: "Otwórz w edytorze"
    };
  }
};

Highcharts.setOptions({
  lang: langOption(),
  chart: {
    polar: false,
    height: 450,
    style: {
      fontFamily: "\"Energa\", sans-serif",
      fontSize: "18px",
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#333333"
    }
  },
  title: {
    useHTML: true,
    style: {
      fontFamily: "\"Energa\", sans-serif",
      fontSize: "18px",
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#333333"
    }
  },
  plotOptions: {
    animation: false,
    column: {
      // pointWidth: 45,
      maxPointWidth: 45,
      dataLabels: {
        enabled: true,
        align: "center",
        // format: "{point.y:,.1f}",
        style: {
          textOutline: false,
          fontFamily: "\"Energa\", sans-serif",
          fontSize: "14px",
          fontWeight: "bold",
          fontStyle: "normal",
          padding: "10px",
          color: "#333333"
        }
      }
    },
    pie: {
      showInLegend: true,
      dataLabels: {
        enabled: true,
        // format: "{point.y:,.1f}",
        style: {
          textOutline: false,
          fontFamily: "\"Energa\", sans-serif",
          fontSize: "14px",
          fontWeight: "bold",
          fontStyle: "normal",
          padding: "10px",
          color: "#333333"
        }
      }
    },
    series: {
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          textOutline: false,
          fontFamily: "\"Energa\", sans-serif",
          fontSize: "14px",
          fontWeight: "bold",
          fontStyle: "normal",
          padding: "10px",
          color: "contrast"
        }
      }
    }
  },
  series: {
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: "\"Energa\", sans-serif",
        fontSize: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        padding: "10px",
        color: "#333333"
      }
    }
  },
  legend: {
    enabled: true,
    align: "center",
    verticalAlign: "bottom",
    itemMarginTop: 10,
    itemMarginBottom: 10,
    alignColumns: false,
    itemStyle: {
      fontFamily: "\"Energa\", sans-serif",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#333333"
    },
    labelFormatter: function labelFormatter() {
      return translator(this.name);
    }
  },
  xAxis: {
    labels: {
      useHTML: true,
      formatter: function formatter() {

        var dateLabelsCharte = [
          "Ceny energii na rynku SPOT w Polsce i krajach sąsiadujących w 2018 roku (zł/MWh)",
          "Cena kontraktu terminowego pasmo z dostawą w 2019 roku",
          "Ceny uprawnień do emisji (EUA DEC 2018) w 2018 roku",
          "Zestawienie cen na rynku bilansującym i SPOT (Giełda) w 2018 roku (zł/MWh)",
          "Zestawienie cen oraz zdolności wytwórczych stanowiących operacyjną rezerwę mocy w 2018 roku",
          "Wykres kursu akcji Spółki Energa SA w latach 2015-2018",
          "Zmiana kursu akcji Energi w porównaniu do zmian indeksów WIG, WIG20 i WIG-ENERGIA",
        ]

        if(dateLabelsCharte.indexOf(this.chart.title.textStr) > -1){
          
          var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

          if(!isChrome){
            function formatDate(date) {

              function leadZero(number){
                if(number < 10){
                  return "0"+number
                } else {
                  return number
                }
              }
  
              var day = leadZero(date.getDate());
              var month = leadZero(date.getMonth()+1);
              var year = date.getFullYear();
  
              return day + '.' + month + '.' + year;
            }

            return formatDate(new Date(this.value));
          }
        }
        if (this.value == "ENERGA") {
          return '<img class="chart-img-energa" src="/image/grupa_energa_w_2018/energa-logo.png" style="height:30px; width:80px;" />';
        }

        if (this.value == "ENEA") {
          return '<img class="chart-img-enea" src="/image/grupa_energa_w_2018/enea-logo.png" style="height:30px; width:60px;" />';
        }

        if (this.value == "PGE") {
          return '<img class="chart-img-pge" src="/image/grupa_energa_w_2018/PGE-logo.png" style="height:30px; width:60px;" />';
        }

        if (this.value == "Tauron") {
          return '<img class="chart-img-tauron" src="/image/grupa_energa_w_2018/tauron-logo.png" style="height:40px; width:40px;" />';
        } else {
          return translator(this.value);
        }
      },
      style: {
        textOutline: false,
        fontFamily: "\"Energa\", sans-serif",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        padding: "10px",
        color: "#333333",
        align: 'center'
      }
    }
  },
  yAxis: {
    labels: {
      textOutline: false,
      fontFamily: "\"Energa\", sans-serif",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      padding: "10px",
      color: "#333333"
    },
    title: {
      style: {
        textOutline: false,
        fontFamily: "\"Energa\", sans-serif",
        fontSize: "11px",
        fontWeight: "bold",
        fontStyle: "normal",
        padding: "10px"
      }
    }
  },
  exporting: {
    enabled: false
  },
  data: {
    googleSpreadsheetKey: false,
    googleSpreadsheetWorksheet: false
  },
  navigation: {
    events: {
      showPopup: "function(e){this.chart.indicatorsPopupContainer||(this.chart.indicatorsPopupContainer=document.getElementsByClassName(\"highcharts-popup-indicators\")[0]),this.chart.annotationsPopupContainer||(this.chart.annotationsPopupContainer=document.getElementsByClassName(\"highcharts-popup-annotations\")[0]),\"indicators\"===e.formType?this.chart.indicatorsPopupContainer.style.display=\"block\":\"annotation-toolbar\"===e.formType&&(this.chart.activeButton||(this.chart.currentAnnotation=e.annotation,this.chart.annotationsPopupContainer.style.display=\"block\")),this.popup&&(c=this.popup)}",
      closePopup: "function(){this.chart.annotationsPopupContainer.style.display=\"none\",this.chart.currentAnnotation=null}",
      selectButton: "function(e){var t=e.button.className+\" highcharts-active\";e.button.classList.contains(\"highcharts-active\")||(e.button.className=t,this.chart.activeButton=e.button)}",
      deselectButton: "function(e){e.button.classList.remove(\"highcharts-active\"),this.chart.activeButton=null}"
    },
    bindingsClassName: "tools-container"
  },
  caption: {},
  chartarea: {},
  plotarea: {},
  tooltip: {},
  credits: {},
  stockTools: {
    gui: {
      buttons: ["simpleShapes", "lines", "crookedLines"],
      enabled: false
    }
  },
  annotations: [],
  responsive: {
    rules: [{
      condition: {
        maxWidth: 768
      },
      chartOptions: {
        legend: {
          itemStyle: {
            fontSize: "10px",
          }
        },
        xAxis: {
          labels: {
            // rotation: 90,
            style: {
              fontSize: "10px",
            }
          },
        },
        yAxis: {
          labels: {
            fontSize: "10px",
          }
        },
        title: {
          style: {
            fontSize: "16px"
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontSize: "10px"
              }
            }
          },
          column: {
            dataLabels:{
              style: {
                fontSize: "10px"
              }
            }
          },
          pie: {
            dataLabels: {
              style: {
                fontSize: "10px"
              }
            }
          },
          waterfall: {
            fontSize: "10px",
            dataLabels: {
              style: {
                fontSize: "10px"
              }
            }
          },
        },
        series: {
          dataLabels: {
            style: {
              fontSize: "10px",
            }
          }
        }        
      }
    }]
  }
});

function recursiveIsset(object, path) {
  var result = true;
  path.forEach(function (key) {
    if (typeof object[key] === 'undefined') {
      result = false;
      return false;
    }

    object = object[key];
  });
  return result;
}

$('[data-chart]').each(function (k, chartElement) {
  try {
    var chartObj = $(chartElement).data('chart'); //function: plotOptions.columnrange.formater

    if (recursiveIsset(chartObj, ['plotOptions', 'columnrange', 'formater'])) {
      var skip = chartObj.plotOptions.columnrange.formater;

      chartObj.plotOptions.columnrange.formater = function () {
        var result = this.point.low - this.point.high;
        result = result.toFixed(1);

        if (skip.indexOf(this.key.name) >= 0) {
          if (this.y === this.point.low) return '<span style="color:black;">' + result + '</span>';
        } else if (this.y === this.point.high) return '<span style="color:black;">' + (this.point.high - this.point.low).toFixed(1) + '</span>';
      };
    } //function: plotOptions.column.dataLabels.formatter


    if (recursiveIsset(chartObj, ['plotOptions', 'column', 'dataLabels', 'formatter'])) {
      chartObj.plotOptions.column.dataLabels.formatter = function () {
        if (this.key.name == 'Stan na 31 grudnia 2016') {
          var result = Math.floor(this.y / 18382.49800 * 1000) / 10;
          return '<span>' + result + '%</span>';
        } else if (this.key.name == 'Stan na 31 grudnia 2017') {
          var result = Math.round(this.y / 20416.24000000 * 1000) / 10;
          return '<span>' + result + '%</span>';
        }
      };
    } //function: tooltip.formatter


    if (recursiveIsset(chartObj, ['tooltip', 'formatter'])) {
      chartObj.tooltip.formatter = function () {
        var result = this.point.high - this.point.low;
        var length = 0;

        if (this.series.chart.title.textStr == "Zmiany w stanie zatrudnienia w Grupie Kapitałowej Enea - osoby" || this.series.chart.title.textStr == "Przepływy pieniężne 2017" || this.series.chart.title.textStr == "Wydatki inwestycyjne<sup>1)</sup> GK ENEA 2017") {
          result = result.toFixed(0);
          length = result.length - 3;
        } else {
          result = result.toFixed(1);
          length = result.length - 5;
        }

        if (parseInt(result) > 1000) {
          result = result.split('');
          result.splice(length, 0, ' ');
          result = result.join('');
        }

        result = result.replace('.', ',');
        return '<span style="font-size: 10px">' + this.key.name + '</span><br/><span style=\"color:' + this.point.color + "\">\u25CF</span><span>Warto\u015B\u0107: " + result + '</span>';
      };
    } //function: plotOptions.columnrange.dataLabels.formatter


    if (recursiveIsset(chartObj, ['plotOptions', 'columnrange', 'dataLabels', 'formatter'])) {
      var skip = chartObj.plotOptions.columnrange.dataLabels.formatter;

      chartObj.plotOptions.columnrange.dataLabels.formatter = function () {
        var result = this.point.low - this.point.high;
        var result2 = this.point.high - this.point.low;
        lengthstr = 0;

        if (this.series.chart.title.textStr == "Zmiany w stanie zatrudnienia w Grupie Kapitałowej Enea - osoby" || this.series.chart.title.textStr == "Przepływy pieniężne 2017" || this.series.chart.title.textStr == "Wydatki inwestycyjne<sup>1)</sup> GK ENEA 2017") {
          result = result.toFixed(0);
          result2 = result2.toFixed(0);
          lengthstr = 3;
        } else {
          result = result.toFixed(1);
          result2 = result2.toFixed(1);
          lengthstr = 5;
        }

        if (result2 > 1000) {
          result = result.split('');
          var length = result.length - lengthstr;
          result.splice(length, 0, ' ');
          result = result.join('');
          result2 = result2.split('');
          var length2 = result2.length - lengthstr;
          result2.splice(length2, 0, ' ');
          result2 = result2.join('');
        }

        result = result.replace('.', ',');
        result2 = result2.replace('.', ',');

        if (skip.indexOf(this.key.name) >= 0) {
          if (this.y === this.point.low) return '<span style="color:black;">' + result + '</span>';
        } else if (this.y === this.point.high) return '<span style="color:black;">' + result2 + '</span>';
      };
    }

    Highcharts.chart(chartElement, chartObj, function () {
      this.setTitle({
        text: translator(this.title.textStr)
      });
    });
  } catch (e) {
    console.log(e);
  }
});