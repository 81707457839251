let owl = $('.owl-dystrybycja');
owl.owlCarousel({
  loop:true,
  margin:10,
  nav:false,
  items:1
});

$('.owl-navs .next').click(function(){
  const activeId =  parseInt($('svg .pin.active').attr('data-id'));
  let nextId = activeId + 1;
  let title, color = '';

  if(nextId >= 4){
    nextId = 1;
    $('.pin').removeClass('active');
    
    if($('html').attr('lang') === "pl"){
      $('.pin-pl[data-id="'+nextId+'"]').addClass('active');
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-pl');
    } else {
      $('.pin-en[data-id="'+nextId+'"]').addClass('active');
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-en');
    }
    $('.pin-label[data-id="'+nextId+'"]').addClass('active');
    color = $('.pin[data-id="'+nextId+'"]').attr('data-color');
  }else{    
    $('.pin').removeClass('active');
    
    if($('html').attr('lang') === "pl"){
      $('.pin-pl[data-id="'+nextId+'"]').addClass('active');
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-pl');
    } else {
      $('.pin-en[data-id="'+nextId+'"]').addClass('active');
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-en');
    }
    $('.pin-label[data-id="'+nextId+'"]').addClass('active');
    color = $('.pin[data-id="'+nextId+'"]').attr('data-color');
  }

  $('#title').html(title);
  $('.segmenty-biznesowe .line').css('background-color',color);
  // $('h4#title').css('color',color);
  // $('.h4-before').css('border-color',color);

  owl.trigger('next.owl.carousel');
});

$('.owl-navs .prev').click(function(){
  const activeId =  parseInt($('svg .pin.active').attr('data-id'));
  let nextId = activeId - 1;
  let title, color = '';
  
  if(nextId <= 0){
    nextId = 3;
    $('.pin').removeClass('active');
    if($('html').attr('lang') === "pl"){
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-pl');
      $('.pin-pl[data-id="'+nextId+'"]').addClass('active');
    } else {
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-en');
      $('.pin-en[data-id="'+nextId+'"]').addClass('active');
    }
    $('.pin-label[data-id="'+nextId+'"]').addClass('active');
    color = $('.pin[data-id="'+nextId+'"]').attr('data-color');
  }else{    
    $('.pin').removeClass('active');
    if($('html').attr('lang') === "pl"){
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-pl');
      $('.pin-pl[data-id="'+nextId+'"]').addClass('active');
    } else {
      title = $('.pin[data-id="'+nextId+'"]').attr('data-title-en');
      $('.pin-en[data-id="'+nextId+'"]').addClass('active');
    }
    $('.pin-label[data-id="'+nextId+'"]').addClass('active');
    color = $('.pin[data-id="'+nextId+'"]').attr('data-color');
  }
  $('#title').html(title);
  $('.segmenty-biznesowe .line').css('background-color',color);

  owl.trigger('prev.owl.carousel');
});

$('.list-box').slick({
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  arrows: false
});

$('.list-menu > button:nth-child(1)').click(function(){
  $('.list-box').slick('slickGoTo', 0);

  $('.list-menu > button').removeClass('active');
  $(this).addClass('active');
});
$('.list-menu > button:nth-child(2)').click(function(){
  $('.list-box').slick('slickGoTo', 1);

  $('.list-menu > button').removeClass('active');
  $(this).addClass('active');
});
$('.list-menu > button:nth-child(3)').click(function(){
  $('.list-box').slick('slickGoTo', 2);

  $('.list-menu > button').removeClass('active');
  $(this).addClass('active');
});

$('#scroll-up').click(function(){
  $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
});

$('body').on('click', '.click-pin', function(){
  const nextId =  parseInt($(this).attr('data-id'));
  //let nextId = clickedId + 1;
  let title, color = '';
    
  $('.pin').removeClass('active');
  
  if($('html').attr('lang') === "pl"){
    title = $('.pin[data-id="'+nextId+'"]').attr('data-title-pl');
    $('.pin-pl[data-id="'+nextId+'"]').addClass('active');
  } else {
    title = $('.pin[data-id="'+nextId+'"]').attr('data-title-en');
    $('.pin-en[data-id="'+nextId+'"]').addClass('active');
  }
  $('.pin-label[data-id="'+nextId+'"]').addClass('active');
  color = $('.pin[data-id="'+nextId+'"]').attr('data-color');
  

  $('#title').html(title);
  $('.segmenty-biznesowe .line').css('background-color',color);
  owl.trigger('to.owl.carousel', [nextId-1, 500]);
  
});