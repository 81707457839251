var setTitle = function(){
    if($('html').attr('lang') === "en"){
        return "EBITDA Bridge Linii Biznesowej Dystrybucja (mln zł)"
    } else {
        return "EBITDA bridge of the Distribution Business Line (m PLN)"
    }
}

if($("#EBITDA_bridge_linii_biznesowej_dystrybucja").length > 0){
    Highcharts.chart('EBITDA_bridge_linii_biznesowej_dystrybucja', {
        chart: {
            type: 'waterfall',
            height: 550
        },
        plotOptions: {
            animation: false,
            waterfall: {
                maxPointWidth: 45,
                dataLabels: {
                    verticalAlign: 'top',
                    y: - 30,
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"12px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            }
        },    
        title:{
            text: setTitle(),
            style:{
                fontFamily:"\"Energa\", sans-serif",
                fontSize:"18px",
                fontWeight:"bold",
                fontStyle:"normal",
                color:"#333333"
            }
        },
    
        xAxis: {
            type: 'category',
            labels: {
                rotation: 0,
                step: 1,
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"11px",
                    fontWeight:"normal",
                    fontStyle:"normal",
                    color:"#333333"                
                }
             },
        },
    
        yAxis: {
            title: {
                text: 'mln zł'
            },
            visible: false
        },
    
        legend: {
            enabled: false
        },
        exporting: { enabled: false },   
        tooltip: {
            pointFormat: "{point.y:,.1f} mln zł"
        },
        series: [{
            data: [
            {
                name: 'EBIDA 2017',
                y: 1732,
                color: "#660033"
            }, {
                name: 'Marża na dystrybucji (ze stratami sieciowymi)',
                y: 109,
                color: "#bfd73b"
            }, {
                name: 'Przychody z przyłączy',
                y: -20,
                color: "#bfd73b"
            }, {
                name: 'OPEX Linii Biznesowej',
                y: -134,
                color: "#bfd73b"
            }, {
                name: 'Podatek od nieruchomości',
                y: -15,
                color: "#bfd73b"
            }, {
                name: 'Wyniki na pozostałej działalności operacyjnej',
                y: 31,
                color: "#bfd73b"
            }, {
                name: 'EBITDA 2018',
                y: 1704,
                isSum: true,
                color: "#660033"
            }
        ],
            dataLabels: {
                enabled: true,
                format: "{point.y}",
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"14px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            },
            pointPadding: 0
        }]
    });
}
