import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import { getItemsNumber } from './helper/slider';

if($("#slider-line").length > 0){
  var slider = tns({
      "container": "#slider-line",
      "controlsContainer": "#nav-container",
      "responsive": {
          "992": {
            "items": getItemsNumber(),
          },
          "768": {
            "items": 2
          },
          "576": {
            "items": 1
          }
        },
      "loop": true,
      "slideBy": "page",
      "mouseDrag": true,
      "swipeAngle": false,
      "speed": 400,
  })  
}
