var setTitle = function(){
    if($('html').attr('lang') === "en"){
        return "EBITDA bridge of the Sales Business Line (m PLN)"
    } else {
        return "EBITDA Bridge Linii Biznesowej Sprzedaż (mln zł)"
    }
}

if($("#EBITDA_bridge_linii_biznesowej_sprzedaz").length > 0){
    Highcharts.chart('EBITDA_bridge_linii_biznesowej_sprzedaz', {
        chart: {
            type: 'waterfall',
            height: 550
        },
        plotOptions: {
            animation: false,
            waterfall: {
                pointWmaxPointWidthidth: 45,
                dataLabels: {
                    verticalAlign: 'top',
                    y: - 30,
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"12px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            }
        },   
        title:{
            text: setTitle(),
            style:{
                fontFamily:"\"Energa\", sans-serif",
                fontSize:"18px",
                fontWeight:"bold",
                fontStyle:"normal",
                color:"#333333"
            }
        },
    
        xAxis: {
            type: 'category',
            labels: {
                rotation: 0,
                step: 1,
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"11px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"                
                }                
             },
        },
    
        yAxis: {
            title: {
                text: 'mln zł'
            },
            visible: false
        },
    
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: "{point.y:,.1f} mln zł"
        },
        exporting: { enabled: false },    
        series: [{
            data: [
            {
                name: 'EBIDA 2017',
                y: 85,
                color: "#660033"
            }, {
                name: 'Marża na energii elektrycznej',
                y: 88,
                color: "#5baf42"
            }, {
                name: 'Przychody z sprzedaży usług operatora handlowego',
                y: -46,
                color: "#5baf42"
            }, {
                name: 'Rozwiązanie odpisów aktualizujących dla niezależności spornych - zdarzenie jednorazowe z 2017',
                y: -44,
                color: "#5baf42"
            }, {
                name: 'Rozstrzygnięcie postępowań arbitrażowych dot. CPA - zdarzenie jednorazowe',
                y: -26,
                color: "#5baf42"
            }, {
                name: 'Rezerwa na umowy rodzące obciążenie w Energa Obrót SA, w związku z wdrożeniem Ustawy z dnia 28 grudnia 2018 roku o zmianie ustawy o podatku akcyzowym oraz niektórych innych ustaw',
                y: -138,
                color: "#5baf42"
            }, {
                name: "Pozostałe",
                y: -6,
                color: "#5baf42"
            }, {
                name: 'EBITDA 2018',
                y: -85,
                isSum: true,
                color: "#660033"
            }
        ],
            dataLabels: {
                enabled: true,
                format: "{point.y}",
                style: {
                    fontFamily:"\"Energa\", sans-serif",
                    fontSize:"14px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    color:"#333333"
                }
            },
            pointPadding: 0
        }]
    });
}
