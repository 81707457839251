const svg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1336.193 830.071"
  style="enable-background:new 0 0 1336.193 830.071;" xml:space="preserve">
    <g id="city">
      <polygon class="st0" points="0,356.403 816.409,830.071 862.079,830.071 1336.193,554.043 501.531,73.915 	"/>
      <g>
        <path class="st1" d="M636.418,154.72l55.657,32.8c4.44,2.617,4.042,7.345-1.095,10.719v0c-5.403,3.548-13.958,4.271-18.872,1.434
          l-61.782-35.667c-5.251-3.031-3.011-7.625,4.646-10.123v0C622.238,151.513,631.702,151.941,636.418,154.72z"/>
        <path class="st1" d="M1035.413,383.818l55.656,32.8c4.44,2.617,4.042,7.345-1.095,10.719h0
          c-5.403,3.548-13.958,4.271-18.872,1.434l-61.782-35.667c-5.251-3.031-3.011-7.625,4.646-10.123v0
          C1021.232,380.61,1030.696,381.038,1035.413,383.818z"/>
        <g>
          <g>
            <g>
              <g>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1053.8751" y1="409.6644" x2="1085.2505" y2="409.6644">
                  <stop  offset="0" style="stop-color:#000000"/>
                  <stop  offset="1" style="stop-color:#FFFFFF"/>
                </linearGradient>
                <polygon class="st2" points="1054.901,407.385 1053.875,411.944 1085.25,411.944 1084.224,407.385 						"/>
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1040.5667" y1="397.4244" x2="1071.9421" y2="397.4244">
                  <stop  offset="0" style="stop-color:#000000"/>
                  <stop  offset="1" style="stop-color:#FFFFFF"/>
                </linearGradient>
                <polygon class="st3" points="1041.593,395.144 1040.567,399.704 1071.942,399.704 1070.916,395.144 						"/>
                <g>
                  <path class="st4" d="M1041.948,386.316c-1.884,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
                    c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.485c0,0-0.458,2.995,1.579,4.627
                    c1.223,0.98,2.793,1.33,2.793,1.33s0.916,6.518-1.372,9.14c0,0,0.739,1.939,2.64,1.089c1.107-0.495,1.925-0.369,3.196-0.812
                    c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.028c1.329-0.611,2.997-1.94,3.161-3.048h-1.166
                    C1045.272,383.989,1043.832,386.206,1041.948,386.316z"/>
                  <g>
                    <path class="st5" d="M1051.075,371.981c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                      c-2.187-0.64-4.583-1.105-6.702-0.266c-0.698,0.276-1.356,0.691-2.1,0.784c-0.422,0.052-0.856-0.002-1.271,0.091
                      c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.95-4.56,5.207
                      c-0.13,1.189,0.352,2.469,1.319,3.173c0.812,0.592,1.878,0.719,2.881,0.651c-0.749,0.26-1.037,1.223-0.848,1.993
                      c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.594-1.52,7.594-1.52s1.708,1.874,6.132-0.482
                      c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35C1055.175,373.669,1051.075,371.981,1051.075,371.981
                      z"/>
                    <path class="st6" d="M1048.143,378.564c-0.767,3.114-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                      c-2.183-0.565-3.488-2.145-4.224-4.05c-1.52,0.068-3.019-0.177-4.58-0.981c-0.169-0.087-0.302-0.192-0.448-0.29
                      c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.882,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                      c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.594-1.52,7.594-1.52s1.708,1.874,6.132-0.482
                      c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.325-0.306-3.908-1.148-4.961
                      C1052.341,375.851,1050.493,377.385,1048.143,378.564z"/>
                    <g>
                      <path class="st7" d="M1050.369,366.809c0,0,1.462-1.734-0.891-5.091c-1.009-1.439-4.473-2.064-4.473-2.064
                        s-1.261-1.327-4.529-1.317c-2.336,0.007-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                        c-1.233,1.431-2.286,2.558-2.586,4.423c-0.3,1.865,1.148,4.019,2.895,4.736c2.013,0.825,5.708,0.327,5.708,0.327
                        s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.891c0.171-0.119,3.721,0.134,5.173-1.005
                        c1.321-1.036,2.338-2.456,1.147-4.758C1051.442,367.912,1050.369,366.809,1050.369,366.809z"/>
                      <path class="st8" d="M1040.476,358.337c-2.336,0.008-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                        c-0.307,0.356-0.603,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.384
                        c0.332,0.092,0.678,0.126,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.547,0,1.025,0.105,1.461,0.259
                        c0.687-0.008,1.389,0.148,2.067,0.55c0.209,0.124,0.457,0.159,0.71,0.178c0.265-1.245,1.025-2.36,2.289-2.884
                        c-0.887-2.038-0.25-4.791,1.97-5.725c-0.797-0.241-1.433-0.361-1.433-0.361S1043.744,358.326,1040.476,358.337z"/>
                      <path class="st9" d="M1050.369,366.809c0,0,0.698-0.875,0.311-2.548c-0.434,1.229-1.292,2.245-2.368,3.091
                        c0.08,0.935-0.08,1.915-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.304,1.148-3.207,1.626-5.19,1.15
                        c-0.594-0.143-1.059-0.395-1.466-0.689c-2.207,1.383-4.842,1.965-7.009,0.857c0.441,0.447,0.945,0.819,1.487,1.041
                        c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.608,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.891
                        c0.171-0.119,3.722,0.134,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758
                        C1051.442,367.912,1050.369,366.809,1050.369,366.809z"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path class="st4" d="M1072.477,395.922c-1.883,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
                    c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.485c0,0-0.458,2.995,1.579,4.627
                    c1.223,0.98,2.793,1.329,2.793,1.329s0.916,6.518-1.372,9.14c0,0,0.739,1.939,2.64,1.089
                    c1.107-0.495,1.925-0.369,3.196-0.812c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.029
                    c1.33-0.61,2.997-1.94,3.161-3.048h-1.166C1075.8,393.596,1074.36,395.811,1072.477,395.922z"/>
                  <g>
                    <path class="st5" d="M1081.603,381.587c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                      c-2.187-0.64-4.583-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                      c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.874-1.021,2.934c-1.303-0.12-4.203,1.95-4.56,5.207
                      c-0.13,1.19,0.353,2.468,1.319,3.173c0.812,0.592,1.879,0.719,2.881,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                      c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.594-1.52,7.594-1.52s1.707,1.874,6.132-0.482
                      c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35C1085.703,383.275,1081.603,381.587,1081.603,381.587
                      z"/>
                    <path class="st6" d="M1078.671,388.17c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                      c-2.183-0.564-3.488-2.145-4.224-4.05c-1.52,0.068-3.018-0.178-4.579-0.981c-0.169-0.087-0.303-0.192-0.449-0.291
                      c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.881,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                      c0.189,0.77,1.117,3.474,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.133-0.482
                      c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                      C1082.869,385.457,1081.021,386.991,1078.671,388.17z"/>
                    <g>
                      <path class="st7" d="M1080.897,376.414c0,0,1.462-1.734-0.891-5.091c-1.008-1.439-4.473-2.064-4.473-2.064
                        s-1.262-1.327-4.53-1.317c-2.336,0.007-3.295,1.973-3.295,1.973s-6.626,0.375-6.017,6.01
                        c-1.233,1.431-2.287,2.558-2.587,4.423c-0.3,1.864,1.147,4.019,2.895,4.736c2.013,0.826,5.708,0.327,5.708,0.327
                        s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.892c0.171-0.118,3.721,0.134,5.173-1.005
                        c1.321-1.036,2.338-2.456,1.147-4.758C1081.97,377.518,1080.897,376.414,1080.897,376.414z"/>
                      <path class="st8" d="M1071.004,367.943c-2.336,0.007-3.295,1.973-3.295,1.973s-6.626,0.375-6.017,6.01
                        c-0.307,0.356-0.603,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.384
                        c0.331,0.092,0.678,0.125,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.547,0,1.025,0.104,1.462,0.259
                        c0.687-0.008,1.389,0.148,2.067,0.55c0.21,0.124,0.457,0.159,0.71,0.177c0.265-1.245,1.025-2.36,2.288-2.884
                        c-0.887-2.038-0.25-4.791,1.97-5.725c-0.797-0.24-1.433-0.361-1.433-0.361S1074.272,367.932,1071.004,367.943z"/>
                      <path class="st9" d="M1080.897,376.414c0,0,0.698-0.874,0.311-2.548c-0.434,1.229-1.292,2.245-2.368,3.091
                        c0.08,0.934-0.08,1.914-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.304,1.148-3.206,1.626-5.19,1.15
                        c-0.594-0.143-1.058-0.395-1.466-0.689c-2.207,1.383-4.841,1.965-7.009,0.857c0.441,0.448,0.945,0.819,1.487,1.041
                        c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.892
                        c0.171-0.118,3.721,0.134,5.173-1.005c1.321-1.036,2.338-2.456,1.147-4.758
                        C1081.97,377.518,1080.897,376.414,1080.897,376.414z"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path class="st4" d="M1054.923,400.42c-1.688,0.099-1.319-2.086-1.319-2.086h-1.263c0,0,0.241,2.385,1.263,2.732
                    c0,0-0.841,1.117-2.43-0.423c-1.589-1.54-1.054-2.881-1.054-2.881h-0.436c0,0-0.411,2.684,1.415,4.147
                    c1.096,0.878,2.504,1.192,2.504,1.192s0.821,5.843-1.23,8.193c0,0,0.663,1.739,2.367,0.977
                    c0.992-0.444,1.725-0.331,2.865-0.728c0,0-1.192-0.596-1.589-2.383c-0.397-1.788-1.093-7.546,0.099-8.093
                    c1.192-0.547,2.687-1.739,2.833-2.732h-1.045C1057.902,398.334,1056.611,400.32,1054.923,400.42z"/>
                  <g>
                    <path class="st10" d="M1063.104,387.57c0,0-1.093-2.791-2.063-3.485c-0.97-0.695-2.119-1.09-3.264-1.424
                      c-1.961-0.574-4.109-0.991-6.008-0.239c-0.625,0.247-1.215,0.619-1.883,0.702c-0.378,0.047-0.767-0.002-1.139,0.082
                      c-0.575,0.129-1.488,1.126-1.865,1.589c-0.598,0.733-0.942,1.68-0.916,2.629c-1.168-0.108-3.768,1.748-4.087,4.668
                      c-0.116,1.066,0.316,2.213,1.182,2.845c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.096-0.76,1.786
                      c0.169,0.69,1.001,3.114,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                      c0.819-0.436,1.418-1.649,1.012-2.484c0.904-0.051,4.484-0.757,4.859-3.003
                      C1066.779,389.083,1063.104,387.57,1063.104,387.57z"/>
                    <path class="st11" d="M1060.476,393.471c-0.688,2.792-3.713,4.423-6.345,3.896c-1.147,0.482-2.502,0.595-3.947,0.222
                      c-1.957-0.506-3.127-1.923-3.786-3.63c-1.362,0.061-2.706-0.159-4.105-0.88c-0.151-0.078-0.271-0.172-0.402-0.26
                      c0.097,0.83,0.499,1.629,1.17,2.118c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                      c0.169,0.69,1.001,3.114,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                      c0.819-0.436,1.418-1.649,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003c0.348-2.083-0.274-3.503-1.029-4.447
                      C1064.238,391.039,1062.582,392.414,1060.476,393.471z"/>
                    <g>
                      <path class="st12" d="M1062.471,382.933c0,0,1.31-1.554-0.799-4.563c-0.904-1.29-4.009-1.85-4.009-1.85
                        s-1.131-1.19-4.06-1.18c-2.094,0.006-2.953,1.769-2.953,1.769s-5.94,0.336-5.394,5.388
                        c-1.105,1.283-2.049,2.293-2.318,3.965c-0.269,1.671,1.028,3.603,2.595,4.245c1.804,0.74,5.117,0.293,5.117,0.293
                        s1.252,1.442,3.709,1.45c2.996,0.01,3.405-2.314,3.808-2.592c0.154-0.106,3.336,0.12,4.637-0.901
                        c1.184-0.928,2.096-2.201,1.028-4.265C1063.433,383.922,1062.471,382.933,1062.471,382.933z"/>
                      <path class="st13" d="M1053.603,375.339c-2.094,0.007-2.953,1.769-2.953,1.769s-5.939,0.336-5.394,5.388
                        c-0.275,0.319-0.54,0.621-0.787,0.921c0.138,0.102,0.278,0.191,0.411,0.317c0.696,0.656,1.745,0.991,2.649,1.241
                        c0.297,0.082,0.608,0.112,0.896,0.131c0.6-0.839,1.56-1.429,2.907-1.429c0.49,0,0.918,0.093,1.31,0.232
                        c0.615-0.007,1.245,0.133,1.852,0.493c0.188,0.111,0.409,0.143,0.636,0.159c0.238-1.116,0.918-2.116,2.052-2.586
                        c-0.796-1.827-0.224-4.294,1.766-5.132c-0.714-0.216-1.285-0.324-1.285-0.324S1056.532,375.33,1053.603,375.339z"/>
                      <path class="st14" d="M1062.471,382.933c0,0,0.625-0.784,0.278-2.284c-0.389,1.102-1.158,2.012-2.122,2.771
                        c0.072,0.838-0.072,1.716-0.577,2.628c-0.794,1.431-2.127,2.257-3.602,2.544c-1.17,1.029-2.875,1.458-4.653,1.031
                        c-0.532-0.128-0.949-0.354-1.314-0.617c-1.979,1.24-4.34,1.762-6.282,0.768c0.395,0.401,0.847,0.734,1.333,0.933
                        c1.167,0.478,2.964,0.461,4.088,0.388c0.631-0.041,1.235,0.163,1.766,0.507c0.613,0.396,1.606,0.844,2.971,0.848
                        c2.996,0.01,3.404-2.314,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                        C1063.433,383.922,1062.471,382.933,1062.471,382.933z"/>
                    </g>
                  </g>
                </g>
                <g>
                  <path class="st15" d="M1046.61,405.995c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
                    c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.498-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.339
                    c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.183-0.154,0.378-0.179,0.576c-0.528-0.02-1.033,0.21-1.288,0.674
                    c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.312,0.124,0.472,0.156c0.111,0.819,0.62,1.597,1.436,2.044
                    c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111c0.839,0.46,1.836,0.301,2.426-0.324
                    c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.502,2.034,0.227,2.496-0.616
                    C1047.895,407.589,1047.527,406.498,1046.61,405.995z"/>
                  <path class="st16" d="M1044.732,407.951c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.055-0.114-0.08
                    c-0.012-0.002-0.023,0.003-0.036,0.001c-1.404-0.246-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
                    c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.312,0.124,0.472,0.156
                    c0.111,0.819,0.62,1.597,1.436,2.044c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111
                    c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.645,0.353,1.384,0.315,1.93-0.028
                    c-0.05,0.013-0.097,0.029-0.154,0.031C1045.81,409.091,1045.179,408.649,1044.732,407.951z"/>
                </g>
                <g>
                  <path class="st17" d="M1052.951,409.061c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
                    c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.498-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.338
                    c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.182-0.154,0.378-0.179,0.575c-0.528-0.02-1.033,0.21-1.288,0.674
                    c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156c0.111,0.819,0.62,1.596,1.436,2.044
                    c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112c0.839,0.46,1.836,0.301,2.426-0.324
                    c0.019,0.011,0.034,0.025,0.053,0.036c0.916,0.503,2.034,0.227,2.496-0.616
                    C1054.236,410.654,1053.867,409.563,1052.951,409.061z"/>
                  <path class="st18" d="M1051.072,411.016c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.056-0.114-0.081
                    c-0.013-0.001-0.023,0.004-0.035,0.002c-1.404-0.247-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
                    c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156
                    c0.111,0.819,0.62,1.596,1.436,2.044c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112
                    c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.644,0.353,1.384,0.315,1.929-0.028
                    c-0.05,0.013-0.098,0.029-0.154,0.031C1052.151,412.155,1051.52,411.714,1051.072,411.016z"/>
                </g>
                <g>
                  <path class="st15" d="M1058.419,410.839c-0.163-0.06-0.328-0.076-0.49-0.083c-0.233-0.793-0.853-1.485-1.727-1.804
                    c-0.573-0.21-1.164-0.218-1.699-0.077c-0.233-0.42-0.619-0.77-1.121-0.954c-0.899-0.328-1.86-0.021-2.349,0.685
                    c-0.02-0.008-0.038-0.02-0.058-0.028c-0.982-0.359-2.045,0.082-2.375,0.984c-0.33,0.902,0.199,1.925,1.18,2.284
                    c-0.36,0.986,0.204,2.098,1.259,2.484c0.414,0.151,0.84,0.165,1.23,0.07c0.181,0.433,0.53,0.806,1.024,0.986
                    c0.743,0.272,1.518,0.018,1.946-0.545c0.148,0.106,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.984
                    c0.072-0.196,0.095-0.397,0.091-0.596c0.525-0.06,0.99-0.363,1.172-0.86C1059.63,411.946,1059.207,411.126,1058.419,410.839z
                    "/>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="631.3439" y1="163.97" x2="662.7196" y2="163.97">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st19" points="632.37,161.69 631.344,166.25 662.72,166.25 661.693,161.69 				"/>
            <g>
              <path class="st4" d="M633.012,153.078c-1.883,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
                c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.486c0,0-0.458,2.995,1.579,4.627
                c1.223,0.98,2.794,1.329,2.794,1.329s0.916,6.519-1.373,9.14c0,0,0.739,1.939,2.64,1.089c1.107-0.495,1.925-0.369,3.196-0.812
                c0,0-1.33-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.028c1.329-0.611,2.997-1.94,3.161-3.048h-1.166
                C636.336,150.751,634.895,152.967,633.012,153.078z"/>
              <g>
                <path class="st5" d="M642.138,138.743c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                  c-2.187-0.64-4.583-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                  c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.95-4.56,5.207
                  c-0.13,1.189,0.352,2.468,1.319,3.173c0.812,0.592,1.879,0.719,2.881,0.65c-0.749,0.26-1.036,1.223-0.848,1.993
                  c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.084,7.593-1.52,7.593-1.52s1.707,1.874,6.132-0.482
                  c0.914-0.486,1.582-1.84,1.129-2.772c1.008-0.057,5.002-0.845,5.42-3.35C646.239,140.431,642.138,138.743,642.138,138.743z"/>
                <path class="st6" d="M639.206,145.326c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                  c-2.183-0.565-3.488-2.146-4.224-4.05c-1.52,0.068-3.018-0.178-4.579-0.981c-0.169-0.087-0.303-0.192-0.448-0.29
                  c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.882,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                  c0.189,0.77,1.117,3.473,3.319,3.931c5.226,1.084,7.593-1.52,7.593-1.52s1.707,1.874,6.132-0.482
                  c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                  C643.404,142.613,641.556,144.147,639.206,145.326z"/>
                <g>
                  <path class="st7" d="M641.433,133.57c0,0,1.462-1.734-0.891-5.091c-1.008-1.439-4.473-2.065-4.473-2.065
                    s-1.261-1.327-4.529-1.316c-2.336,0.007-3.295,1.974-3.295,1.974s-6.626,0.375-6.017,6.01
                    c-1.233,1.431-2.286,2.558-2.586,4.423c-0.3,1.865,1.147,4.019,2.894,4.736c2.013,0.825,5.708,0.327,5.708,0.327
                    s1.397,1.608,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.891c0.171-0.119,3.721,0.134,5.173-1.005
                    c1.32-1.036,2.338-2.456,1.147-4.758C642.505,134.674,641.433,133.57,641.433,133.57z"/>
                  <path class="st8" d="M631.539,125.099c-2.336,0.007-3.295,1.974-3.295,1.974s-6.626,0.375-6.017,6.01
                    c-0.307,0.356-0.602,0.693-0.878,1.027c0.154,0.113,0.309,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.384
                    c0.331,0.092,0.678,0.126,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.547,0,1.025,0.104,1.462,0.259
                    c0.687-0.008,1.389,0.148,2.067,0.55c0.21,0.124,0.457,0.159,0.71,0.177c0.265-1.245,1.024-2.36,2.288-2.884
                    c-0.887-2.038-0.25-4.791,1.971-5.725c-0.797-0.241-1.433-0.362-1.433-0.362S634.807,125.088,631.539,125.099z"/>
                  <path class="st9" d="M641.433,133.57c0,0,0.698-0.874,0.31-2.549c-0.434,1.229-1.292,2.245-2.367,3.091
                    c0.08,0.934-0.08,1.915-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.305,1.148-3.206,1.627-5.19,1.15
                    c-0.594-0.143-1.058-0.395-1.466-0.689c-2.207,1.383-4.841,1.965-7.009,0.857c0.441,0.447,0.945,0.818,1.487,1.041
                    c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.891
                    c0.171-0.119,3.721,0.133,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758C642.505,134.674,641.433,133.57,641.433,133.57z"
                    />
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="641.761" y1="169.947" x2="673.1367" y2="169.947">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st20" points="642.787,167.667 641.761,172.227 673.137,172.227 672.11,167.667 				"/>
            <g>
              <path class="st4" d="M642.378,160.751c-1.688,0.099-1.319-2.086-1.319-2.086h-1.263c0,0,0.241,2.385,1.263,2.732
                c0,0-0.841,1.117-2.43-0.423c-1.589-1.54-1.054-2.881-1.054-2.881h-0.436c0,0-0.411,2.684,1.415,4.147
                c1.096,0.878,2.504,1.192,2.504,1.192s0.821,5.843-1.23,8.193c0,0,0.663,1.739,2.367,0.977
                c0.992-0.444,1.725-0.331,2.865-0.728c0,0-1.192-0.596-1.589-2.384c-0.397-1.787-1.093-7.546,0.099-8.093
                c1.192-0.547,2.687-1.739,2.833-2.732h-1.045C645.358,158.665,644.066,160.652,642.378,160.751z"/>
              <g>
                <path class="st21" d="M650.559,147.901c0,0-1.093-2.791-2.063-3.485c-0.97-0.695-2.119-1.089-3.264-1.424
                  c-1.961-0.574-4.109-0.99-6.008-0.239c-0.625,0.247-1.215,0.619-1.883,0.702c-0.378,0.047-0.767-0.002-1.139,0.082
                  c-0.575,0.129-1.488,1.126-1.866,1.589c-0.598,0.733-0.942,1.68-0.916,2.629c-1.168-0.107-3.768,1.748-4.087,4.668
                  c-0.117,1.066,0.316,2.213,1.182,2.844c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                  c0.169,0.69,1.001,3.114,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                  c0.819-0.436,1.418-1.65,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003C654.234,149.414,650.559,147.901,650.559,147.901z"
                  />
                <path class="st22" d="M647.931,153.802c-0.688,2.792-3.713,4.423-6.345,3.896c-1.147,0.482-2.502,0.595-3.947,0.222
                  c-1.957-0.506-3.127-1.923-3.787-3.63c-1.362,0.061-2.706-0.159-4.105-0.88c-0.151-0.078-0.271-0.172-0.402-0.26
                  c0.097,0.83,0.499,1.628,1.17,2.118c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                  c0.169,0.69,1.001,3.114,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                  c0.82-0.436,1.418-1.65,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003c0.348-2.083-0.274-3.503-1.029-4.447
                  C651.694,151.37,650.037,152.745,647.931,153.802z"/>
                <g>
                  <path class="st23" d="M649.927,143.264c0,0,1.31-1.554-0.799-4.564c-0.904-1.289-4.009-1.85-4.009-1.85
                    s-1.131-1.19-4.06-1.18c-2.094,0.007-2.953,1.769-2.953,1.769s-5.94,0.336-5.394,5.388c-1.105,1.282-2.05,2.293-2.318,3.965
                    c-0.269,1.671,1.029,3.603,2.595,4.245c1.804,0.74,5.117,0.293,5.117,0.293s1.252,1.442,3.709,1.45
                    c2.996,0.011,3.404-2.314,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                    C650.888,144.254,649.927,143.264,649.927,143.264z"/>
                  <path class="st24" d="M641.058,135.67c-2.094,0.007-2.953,1.769-2.953,1.769s-5.939,0.336-5.393,5.388
                    c-0.275,0.319-0.54,0.621-0.787,0.921c0.138,0.102,0.278,0.191,0.411,0.317c0.696,0.656,1.745,0.991,2.649,1.241
                    c0.297,0.082,0.608,0.112,0.896,0.131c0.601-0.839,1.56-1.429,2.907-1.429c0.49,0,0.918,0.094,1.31,0.232
                    c0.615-0.007,1.245,0.133,1.852,0.493c0.188,0.111,0.409,0.143,0.636,0.159c0.238-1.116,0.918-2.116,2.052-2.586
                    c-0.796-1.827-0.224-4.295,1.766-5.132c-0.714-0.216-1.285-0.324-1.285-0.324S643.987,135.66,641.058,135.67z"/>
                  <path class="st25" d="M649.927,143.264c0,0,0.625-0.784,0.278-2.284c-0.389,1.102-1.159,2.012-2.122,2.771
                    c0.072,0.838-0.072,1.716-0.577,2.628c-0.794,1.431-2.127,2.257-3.602,2.543c-1.17,1.029-2.875,1.458-4.653,1.031
                    c-0.532-0.128-0.949-0.354-1.314-0.617c-1.979,1.24-4.34,1.762-6.282,0.768c0.395,0.401,0.847,0.734,1.333,0.933
                    c1.167,0.478,2.964,0.461,4.088,0.389c0.631-0.041,1.235,0.164,1.766,0.507c0.613,0.396,1.606,0.844,2.971,0.848
                    c2.996,0.011,3.404-2.314,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                    C650.888,144.254,649.927,143.264,649.927,143.264z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="662.6027" y1="186.9627" x2="693.9784" y2="186.9627">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st26" points="663.629,184.683 662.603,189.243 693.978,189.243 692.952,184.683 				"/>
            <g>
              <path class="st4" d="M664.424,176.279c-1.884,0.111-1.472-2.326-1.472-2.326h-1.408c0,0,0.269,2.661,1.408,3.048
                c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.486c0,0-0.458,2.994,1.579,4.626
                c1.223,0.98,2.793,1.33,2.793,1.33s0.916,6.518-1.372,9.14c0,0,0.74,1.939,2.64,1.09c1.107-0.495,1.925-0.369,3.196-0.812
                c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.028c1.329-0.61,2.997-1.94,3.161-3.048h-1.166
                C667.747,173.952,666.307,176.168,664.424,176.279z"/>
              <g>
                <path class="st27" d="M673.549,161.943c0,0-1.219-3.113-2.301-3.887c-1.082-0.775-2.364-1.216-3.641-1.589
                  c-2.187-0.64-4.584-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                  c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.95-4.56,5.207
                  c-0.13,1.19,0.352,2.469,1.319,3.174c0.812,0.592,1.878,0.719,2.881,0.651c-0.749,0.26-1.037,1.223-0.848,1.993
                  c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.132-0.482
                  c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35C677.65,163.632,673.549,161.943,673.549,161.943z"/>
                <path class="st22" d="M670.618,168.527c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.665-4.403,0.248
                  c-2.183-0.564-3.488-2.145-4.224-4.05c-1.52,0.068-3.018-0.178-4.58-0.981c-0.169-0.087-0.302-0.192-0.448-0.29
                  c0.108,0.926,0.556,1.817,1.305,2.363c0.812,0.592,1.878,0.719,2.881,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                  c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.084,7.593-1.52,7.593-1.52s1.708,1.874,6.132-0.482
                  c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                  C674.816,165.814,672.967,167.348,670.618,168.527z"/>
                <g>
                  <path class="st28" d="M672.844,156.771c0,0,1.462-1.734-0.891-5.091c-1.008-1.439-4.473-2.064-4.473-2.064
                    s-1.261-1.327-4.529-1.316c-2.336,0.007-3.294,1.973-3.294,1.973s-6.626,0.376-6.017,6.01
                    c-1.233,1.431-2.286,2.558-2.586,4.423c-0.3,1.864,1.147,4.019,2.895,4.736c2.013,0.826,5.708,0.327,5.708,0.327
                    s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.892c0.172-0.118,3.721,0.134,5.173-1.005
                    c1.321-1.036,2.338-2.456,1.147-4.758C673.917,157.875,672.844,156.771,672.844,156.771z"/>
                  <path class="st24" d="M662.951,148.3c-2.336,0.007-3.294,1.973-3.294,1.973s-6.626,0.375-6.017,6.01
                    c-0.307,0.356-0.602,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.105,2.955,1.384
                    c0.332,0.091,0.678,0.125,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.547,0,1.025,0.105,1.462,0.258
                    c0.687-0.008,1.389,0.148,2.067,0.55c0.209,0.124,0.457,0.16,0.71,0.177c0.265-1.245,1.025-2.36,2.288-2.884
                    c-0.887-2.038-0.25-4.791,1.971-5.725c-0.797-0.241-1.433-0.362-1.433-0.362S666.219,148.289,662.951,148.3z"/>
                  <path class="st25" d="M672.844,156.771c0,0,0.698-0.874,0.31-2.549c-0.434,1.229-1.292,2.245-2.368,3.091
                    c0.08,0.935-0.08,1.915-0.644,2.932c-0.886,1.595-2.373,2.517-4.018,2.837c-1.305,1.148-3.207,1.627-5.19,1.15
                    c-0.594-0.143-1.058-0.395-1.465-0.689c-2.207,1.383-4.842,1.966-7.009,0.857c0.441,0.447,0.945,0.818,1.487,1.041
                    c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.892
                    c0.172-0.118,3.721,0.134,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758C673.917,157.875,672.844,156.771,672.844,156.771z
                    "/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <path class="st15" d="M635.841,167.079c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
              c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.498-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.339
              c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.183-0.154,0.378-0.179,0.576c-0.528-0.02-1.033,0.21-1.288,0.674
              c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.313,0.124,0.473,0.156c0.111,0.819,0.619,1.597,1.435,2.044
              c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111c0.839,0.46,1.836,0.301,2.426-0.324
              c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.502,2.035,0.227,2.496-0.616C637.126,168.672,636.757,167.582,635.841,167.079z"
              />
            <path class="st16" d="M633.962,169.035c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.055-0.114-0.08
              c-0.013-0.002-0.023,0.003-0.036,0.001c-1.404-0.246-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
              c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.313,0.124,0.473,0.156
              c0.111,0.819,0.619,1.597,1.435,2.044c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111
              c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.645,0.353,1.384,0.315,1.93-0.028
              c-0.049,0.013-0.097,0.029-0.154,0.031C635.041,170.174,634.41,169.733,633.962,169.035z"/>
          </g>
          <g>
            <path class="st17" d="M642.182,170.144c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
              c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.497-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.338
              c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.182-0.154,0.378-0.179,0.575c-0.528-0.02-1.033,0.21-1.288,0.674
              c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156c0.111,0.819,0.62,1.596,1.436,2.044
              c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112c0.839,0.46,1.836,0.301,2.426-0.324
              c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.503,2.034,0.227,2.496-0.616C643.467,171.737,643.098,170.647,642.182,170.144z"
              />
            <path class="st18" d="M640.303,172.099c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.056-0.114-0.081
              c-0.013-0.001-0.023,0.004-0.035,0.002c-1.404-0.247-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
              c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156
              c0.111,0.819,0.62,1.596,1.436,2.044c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112
              c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.644,0.353,1.384,0.315,1.93-0.028
              c-0.05,0.013-0.098,0.029-0.154,0.031C641.382,173.239,640.751,172.798,640.303,172.099z"/>
          </g>
          <g>
            <path class="st15" d="M647.65,171.922c-0.163-0.06-0.328-0.076-0.49-0.083c-0.233-0.793-0.853-1.485-1.727-1.804
              c-0.573-0.21-1.164-0.218-1.699-0.077c-0.233-0.42-0.619-0.77-1.121-0.954c-0.899-0.329-1.86-0.021-2.349,0.685
              c-0.02-0.008-0.038-0.02-0.058-0.028c-0.982-0.359-2.045,0.082-2.375,0.984c-0.33,0.903,0.199,1.925,1.18,2.284
              c-0.36,0.986,0.204,2.098,1.259,2.484c0.414,0.151,0.84,0.165,1.23,0.07c0.181,0.433,0.53,0.806,1.023,0.986
              c0.743,0.272,1.518,0.018,1.946-0.545c0.148,0.106,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.984
              c0.072-0.196,0.096-0.397,0.091-0.596c0.525-0.06,0.99-0.363,1.172-0.86C648.861,173.03,648.438,172.21,647.65,171.922z"/>
          </g>
          <g>
            <path class="st16" d="M643.258,173.792c-1.148-0.128-2.137-0.614-2.217-1.748c-0.825-0.515-1.403-1.429-1.094-2.426
              c-0.903-0.202-1.815,0.205-2.115,1.028c-0.33,0.903,0.199,1.925,1.18,2.284c-0.36,0.986,0.204,2.098,1.259,2.484
              c0.414,0.151,0.84,0.164,1.23,0.07c0.181,0.433,0.53,0.806,1.023,0.986c0.743,0.272,1.518,0.018,1.946-0.545
              c0.148,0.107,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.985c0.047-0.127,0.048-0.258,0.059-0.388
              C646.114,175.528,643.941,175.133,643.258,173.792z"/>
          </g>
        </g>
      </g>
      <path class="st0" d="M1212.385,518.712L522.069,122.437c-7.404-4.25-16.51-4.244-23.908,0.018L107.88,347.261
        c-8.801,5.06-8.817,17.753-0.029,22.835l688.213,397.955c7.422,4.292,16.57,4.294,23.995,0.008l392.334-226.514
        C1221.183,536.47,1221.179,523.781,1212.385,518.712z"/>
      <g>
        <polygon class="st29" points="253.764,417.505 324.191,457.948 419.759,402.689 348.624,362.203 		"/>
      </g>
      <polygon class="st30" points="617.828,330.499 875.563,478.843 982.444,415.889 733.607,264.44 	"/>
      <g>
        <polygon class="st30" points="908.535,631.605 911.59,636.722 1073.927,539.165 1070.872,534.048 		"/>
      </g>
      <g>
        <polygon class="st30" points="893.016,454.764 972.01,500.05 974.99,494.89 895.996,449.604 		"/>
      </g>
      <g>
        <polygon class="st30" points="917.199,438.847 1047.646,519.522 1050.625,514.362 920.178,433.687 		"/>
      </g>
      <polygon class="st30" points="409.474,200.821 576.828,297.442 678.072,235.55 515.487,141.681 	"/>
      <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="591.1475" y1="241.183" x2="618.2462" y2="241.183">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st31" points="592.034,239.214 591.148,243.152 618.246,243.152 617.36,239.214 	"/>
      <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="550.7019" y1="245.5276" x2="577.8006" y2="245.5276">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st32" points="551.588,243.558 550.702,247.497 577.801,247.497 576.914,243.558 	"/>
      <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="562.0874" y1="238.4117" x2="589.1861" y2="238.4117">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st33" points="562.974,236.443 562.087,240.381 589.186,240.381 588.3,236.443 	"/>
      <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="573.9265" y1="231.7871" x2="601.0251" y2="231.7871">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st34" points="574.813,229.818 573.927,233.756 601.025,233.756 600.139,229.818 	"/>
      <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="522.7774" y1="245.2082" x2="555.0324" y2="245.2082">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st35" points="549.803,245.047 548.99,241.432 527.497,241.432 522.777,245.37 532.266,245.37 527.934,248.985 
        555.032,248.985 554.146,245.047 	"/>
      <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="572.2719" y1="216.5265" x2="604.527" y2="216.5265">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.8286" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st36" points="598.484,212.75 576.992,212.75 572.272,216.688 581.76,216.688 577.428,220.303 604.527,220.303 
        603.641,216.365 	"/>
      <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="579.0905" y1="247.9747" x2="606.1891" y2="247.9747">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st37" points="579.977,246.005 579.091,249.944 606.189,249.944 605.303,246.005 	"/>
      <polygon class="st30" points="655.452,668.358 824.09,765.731 989.721,670.104 820.625,572.273 	"/>
      <polygon class="st38" points="699.882,663.58 823.899,737.172 932.205,674.641 807.889,600.749 	"/>
      <polygon class="st30" points="311.589,473.311 346.659,483.898 422.224,438.101 395.681,425.637 	"/>
      <polygon class="st30" points="299.951,479.182 420.758,547.162 473.087,514.96 356.347,447.849 	"/>
      <path class="st30" d="M1212.385,518.712L522.069,122.437c-7.404-4.25-16.51-4.244-23.908,0.018L107.88,347.261
        c-8.801,5.06-8.817,17.753-0.029,22.835l688.213,397.955c7.422,4.292,16.57,4.294,23.995,0.008l392.334-226.514
        C1221.183,536.47,1221.179,523.781,1212.385,518.712z M1172.061,535.626L807.718,746.48L149.176,362.676
        c-3.858-2.249-3.847-7.827,0.021-10.06l362.249-209.144L1172.06,525.56C1175.931,527.798,1175.932,533.386,1172.061,535.626z"/>
      <ellipse class="st30" cx="512.46" cy="363.767" rx="65.052" ry="32.192"/>
      <g>
        
          <rect x="863.926" y="562.37" transform="matrix(0.8586 -0.5126 0.5126 0.8586 -159.3756 552.8616)" class="st30" width="117.252" height="5.96"/>
      </g>
      <ellipse class="st30" cx="965.874" cy="487.582" rx="45.801" ry="22.665"/>
      <ellipse class="st30" cx="1061.014" cy="524.875" rx="48.027" ry="23.767"/>
      <ellipse class="st30" cx="960.947" cy="550.747" rx="43.005" ry="21.282"/>
      <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="784.5237" y1="389.3554" x2="945.3073" y2="389.3554">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st39" points="784.524,347.442 817.061,431.269 945.307,428.967 888.476,348.085 	"/>
      <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="860.0649" y1="426.2934" x2="1009.5981" y2="426.2934">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st40" points="860.065,461.856 988.311,461.856 1009.598,390.731 905.646,390.731 	"/>
      <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="710.4014" y1="335.7106" x2="866.1215" y2="335.7106">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st41" points="710.401,296.628 737.875,374.794 866.122,374.794 814.354,296.628 	"/>
      <polygon class="st30" points="260.903,287.202 399.711,366.718 537.387,287.956 398.181,208.042 	"/>
      <g>
        <g>
          <g>
            <path class="st42" d="M515.837,124.984l0-0.01c-0.005-0.152-0.073-0.284-0.186-0.36c-0.293-0.199-0.627-0.304-0.965-0.304
              c-0.327,0-0.652,0.099-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.193,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.193,0.38l0.033,0.021c0.283,0.182,0.604,0.278,0.927,0.278
              c0.323,0,0.644-0.096,0.927-0.278l0.052-0.033c0.122-0.078,0.194-0.221,0.194-0.384l0-0.008l0-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.017L515.837,124.984z"/>
            <path class="st42" d="M510.677,117.837l0-0.01c-0.005-0.152-0.073-0.284-0.186-0.36c-0.293-0.199-0.627-0.304-0.965-0.304
              c-0.328,0-0.652,0.099-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.192,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.193,0.38l0.033,0.021c0.283,0.182,0.604,0.278,0.927,0.278
              c0.323,0,0.643-0.096,0.927-0.278l0.052-0.033c0.122-0.078,0.194-0.222,0.194-0.384v-0.008v-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.017V117.837z"/>
            <path class="st42" d="M510.677,138.786l0-0.01c-0.005-0.152-0.073-0.283-0.186-0.36c-0.293-0.199-0.627-0.304-0.965-0.304
              c-0.328,0-0.652,0.099-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.192,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.193,0.38l0.033,0.021c0.283,0.182,0.604,0.278,0.927,0.278
              c0.323,0,0.643-0.096,0.927-0.278l0.052-0.033c0.122-0.078,0.194-0.221,0.194-0.384v-0.008v-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.016V138.786z"/>
            <path class="st42" d="M476.943,137.581l0-0.01c-0.005-0.152-0.073-0.283-0.186-0.36c-0.293-0.199-0.627-0.304-0.966-0.304
              c-0.327,0-0.652,0.099-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.193,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.194,0.38l0.033,0.021c0.283,0.182,0.604,0.278,0.927,0.278
              c0.323,0,0.644-0.096,0.927-0.278l0.052-0.033c0.122-0.078,0.194-0.221,0.194-0.384l0-0.008l0-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.017L476.943,137.581z"/>
            <path class="st42" d="M472.032,150.662l-0.001-0.01c-0.005-0.152-0.073-0.283-0.186-0.36c-0.293-0.199-0.627-0.304-0.966-0.304
              c-0.327,0-0.652,0.098-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.193,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.193,0.38l0.033,0.021c0.283,0.182,0.603,0.278,0.927,0.278
              c0.323,0,0.644-0.096,0.927-0.278l0.052-0.033c0.121-0.078,0.194-0.221,0.194-0.384v-0.008v-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.017V150.662z"/>
            <path class="st42" d="M476.943,158.548l0-0.01c-0.005-0.152-0.073-0.283-0.186-0.36c-0.293-0.199-0.627-0.304-0.966-0.304
              c-0.327,0-0.652,0.099-0.938,0.285l-0.043,0.028c-0.121,0.079-0.193,0.223-0.193,0.385v3.981h0.002
              c0,0.006-0.002,0.011-0.002,0.017c0,0.077,0.02,0.268,0.194,0.38l0.033,0.021c0.283,0.182,0.604,0.278,0.927,0.278
              c0.323,0,0.644-0.096,0.927-0.278l0.052-0.033c0.122-0.078,0.194-0.221,0.194-0.384l0-0.008l0-0.002v-3.972h-0.002
              c0-0.006,0.002-0.011,0.002-0.017L476.943,158.548z"/>
            <path class="st43" d="M516.781,196.471c-0.262-0.152-0.55-0.25-0.848-0.294c-0.002-0.004-0.002-0.008-0.004-0.011
              l-12.319-20.836c-0.003-0.006-0.009-0.008-0.012-0.014l-2.115-19.282c0.014-0.049,0.009-0.102-0.016-0.149l-0.671-6.118
              l9.385-11.601c0.016-0.019,0.028-0.04,0.037-0.063v0h0c0.004-0.012,0.007-0.023,0.009-0.035v0c0.008-0.049-0.001-0.1-0.03-0.144
              c-0.028-0.043-0.068-0.072-0.113-0.085h0l0,0c-0.014-0.004-0.029-0.007-0.044-0.008h0h0c-0.018-0.001-0.036,0-0.054,0.003
              l-10.303,1.777l-0.089-0.814l15.992-14.329c0.019-0.016,0.035-0.036,0.047-0.058h0v0c0.008-0.014,0.014-0.029,0.018-0.044
              c0.013-0.047,0.009-0.098-0.014-0.145c-0.024-0.048-0.063-0.082-0.109-0.1h0h0c-0.014-0.006-0.028-0.009-0.043-0.012h0h0
              c-0.025-0.004-0.052-0.003-0.078,0.003l-15.935,3.394l10.784-10.191c0.016-0.014,0.029-0.031,0.039-0.049
              c0.009-0.015,0.016-0.032,0.021-0.048c0.012-0.042,0.01-0.088-0.007-0.131l0,0v0c-0.002-0.004-0.003-0.008-0.005-0.011l0,0
              c-0.022-0.046-0.058-0.08-0.102-0.1c-0.014-0.006-0.029-0.011-0.044-0.014h0l-0.001,0c-0.026-0.005-0.053-0.005-0.08,0
              l-12.625,2.308l-0.369-3.359c-0.001-0.007-0.004-0.014-0.005-0.021c-0.002-0.01-0.004-0.019-0.007-0.028
              c-0.01-0.043-0.026-0.084-0.049-0.121c-0.002-0.004-0.003-0.007-0.005-0.011c-0.006-0.01-0.015-0.018-0.022-0.027l3.65-9.218
              c0.002-0.005,0-0.009,0.001-0.014c0.011-0.033,0.015-0.068,0.008-0.103c-0.001-0.002,0-0.005,0-0.007
              c-0.007-0.027-0.018-0.053-0.036-0.076c-0.005-0.007-0.013-0.01-0.019-0.017c-0.003-0.003-0.003-0.008-0.007-0.011
              c-0.004-0.003-0.009-0.003-0.013-0.006c-0.059-0.047-0.136-0.057-0.204-0.027c-0.005,0.002-0.01,0-0.014,0.002l-15.861,8.156
              c0,0-0.001,0.001-0.001,0.001c-0.029,0.015-0.053,0.039-0.072,0.068c-0.005,0.008-0.009,0.016-0.013,0.024
              c-0.005,0.01-0.013,0.018-0.016,0.029c-0.005,0.016-0.003,0.032-0.004,0.048c0,0.006-0.004,0.01-0.004,0.016
              c0,0.003,0.002,0.004,0.002,0.007c0.002,0.034,0.012,0.066,0.029,0.094c0.004,0.006,0.009,0.011,0.014,0.017
              c0.016,0.021,0.036,0.039,0.061,0.053c0.005,0.003,0.007,0.009,0.013,0.011l2.777,1.294l-0.612,7.419l-12.116,13.862
              c-0.007,0.008-0.01,0.017-0.015,0.025c-0.006,0.01-0.013,0.019-0.017,0.029c-0.006,0.014-0.009,0.029-0.012,0.044
              c-0.002,0.01-0.005,0.019-0.005,0.029c-0.001,0.022,0.002,0.044,0.008,0.065c0.001,0.003,0,0.006,0.001,0.01l0,0
              c0.008,0.024,0.02,0.046,0.037,0.066c0.008,0.009,0.019,0.014,0.028,0.021c0.008,0.007,0.015,0.013,0.024,0.018
              c0.032,0.019,0.067,0.033,0.104,0.033l0,0h0l0,0l0,0c0.022,0,0.044-0.004,0.066-0.011l10.336-3.519l-15.201,16.672
              c-0.009,0.009-0.013,0.021-0.02,0.032c-0.006,0.009-0.013,0.017-0.017,0.027c-0.007,0.016-0.01,0.034-0.012,0.051
              c-0.001,0.008-0.004,0.015-0.005,0.023c-0.001,0.026,0.003,0.051,0.012,0.076c0,0.001,0,0.002,0,0.003
              c0.001,0.002,0.003,0.003,0.004,0.005c0.008,0.019,0.017,0.038,0.031,0.055c0.008,0.01,0.02,0.015,0.029,0.022
              c0.009,0.007,0.016,0.014,0.025,0.019c0.032,0.019,0.065,0.031,0.102,0.031l0,0l0,0l0,0l0,0c0.024,0,0.048-0.004,0.072-0.013
              l14.137-5.338l-9.814,12.406c-0.001,0.002-0.001,0.004-0.002,0.006c-0.024,0.032-0.036,0.071-0.038,0.113
              c0,0.008-0.001,0.015,0,0.023c0.001,0.009-0.002,0.017,0,0.026c0.006,0.033,0.022,0.061,0.042,0.085
              c0.002,0.002,0.001,0.005,0.003,0.007c0.003,0.004,0.008,0.005,0.011,0.008c0.014,0.015,0.03,0.027,0.048,0.037
              c0.007,0.004,0.012,0.008,0.019,0.011c0.025,0.01,0.051,0.017,0.078,0.017h0h0h0c0.013,0,0.026-0.001,0.039-0.004l9.271-1.811
              l-1.535,18.618l-11.871,21.569c-0.279,0.043-0.549,0.132-0.797,0.27l-0.053,0.03c-0.15,0.083-0.238,0.236-0.238,0.407
              c0,0.081,0.025,0.283,0.239,0.402l0.04,0.022c0.349,0.192,0.745,0.293,1.144,0.293c0.399,0,0.794-0.101,1.144-0.294l0.064-0.035
              c0.15-0.082,0.24-0.234,0.24-0.406l0-0.008l-0.001-0.011c-0.006-0.161-0.09-0.299-0.23-0.381
              c-0.199-0.116-0.415-0.198-0.638-0.252l1.695-3.079l11.618-7.153l7.845,18.508v4.482c0,0.065,0.019,0.125,0.046,0.179
              c-0.273,0.044-0.538,0.132-0.78,0.267l-0.053,0.03c-0.15,0.083-0.238,0.236-0.238,0.407c0,0.081,0.025,0.283,0.239,0.401
              l0.04,0.022c0.349,0.192,0.745,0.293,1.144,0.293c0.399,0,0.794-0.101,1.144-0.294l0.064-0.035
              c0.15-0.082,0.24-0.234,0.24-0.406l0-0.008l-0.001-0.011c-0.006-0.161-0.09-0.299-0.23-0.381
              c-0.258-0.15-0.543-0.247-0.837-0.292c0.026-0.053,0.043-0.111,0.043-0.175v-4.49l7.18-18.9l12.712,7.339
              c0.067,0.038,0.141,0.051,0.214,0.05l1.49,2.52c-0.201,0.051-0.396,0.126-0.579,0.228l-0.053,0.03
              c-0.15,0.083-0.239,0.236-0.238,0.407c0,0.081,0.025,0.283,0.239,0.401l0.04,0.022c0.35,0.192,0.745,0.293,1.144,0.293
              c0.399,0,0.794-0.102,1.144-0.294l0.064-0.035c0.15-0.082,0.24-0.234,0.24-0.406l0-0.008l0-0.011
              C517.005,196.691,516.921,196.553,516.781,196.471z M502.74,175.013l-2.668-1.498l1.694-7.384L502.74,175.013z M500.394,156.731
              l-2.738,9.837l-4.327-5.758L500.394,156.731z M497.231,158.083l2.868-7.145l0.553,5.041c-0.036,0.026-0.066,0.062-0.079,0.109
              l-0.022,0.078L497.231,158.083z M499.603,173.723l-0.884,3.854l-0.026,0.014l-5.141-7.265L499.603,173.723z M493.492,169.823
              l8.015-4.398l-1.808,7.881L493.492,169.823z M499.976,173.932l2.65,1.487l-3.425,1.892L499.976,173.932z M501.581,164.449
              l-3.435,1.885l2.612-9.384L501.581,164.449z M500.732,149.193l-0.505-4.601l8.832-5.694L500.732,149.193z M509.066,138.406
              l-8.888,5.73l-0.452-4.119L509.066,138.406z M500.004,150.076l-2.752,1.648l2.037-6.569l0.165-0.091L500.004,150.076z
              M496.465,140.58l1.989-0.343l-1.194,2.989l-2.827-1.541L496.465,140.58z M494.004,141.451l-0.622-0.339l1.824-0.315
              L494.004,141.451z M497.626,128.744l-1.034,2.694l-1.863-1.024L497.626,128.744z M495.76,128.902l-0.182-0.15l0.239-0.051
              L495.76,128.902z M496.119,129.141l0.152-0.537l1.233-0.263L496.119,129.141z M498.632,139.79l-1.046,0.18l1.235-0.672
              l0.001,0.014L498.632,139.79z M498.918,140.18l0.461,4.202l-1.757-0.958L498.918,140.18z M496.329,140.187l-0.413,0.071
              l2.276-6.704l0.582,5.304L496.329,140.187z M496.955,131.637l0.798-2.079l0.294,2.679L496.955,131.637z M498.102,132.735
              l0.003,0.028l-2.083,1.307l0.785-2.046L498.102,132.735z M496.444,131.824l-0.905,2.357l-2.483-2.803l1.257-0.724
              L496.444,131.824z M497.797,133.44l-2.252,6.633l-2.564-3.612L497.797,133.44z M497.469,143.808l1.717,0.937l-0.163,0.089
              c-0.001,0-0.002,0.001-0.003,0.002l-2.513,1.38L497.469,143.808z M499.537,138.294l-0.606-5.526l15.252-7.597L499.537,138.294z
              M513.912,124.847l-15.029,7.486l-0.445-4.057l0.368-0.212L513.912,124.847z M498.738,127.629l-0.081,0.047l-0.278,0.059
              l-0.517-4.708l11.205-5.158L498.738,127.629z M508.55,117.655l-10.736,4.942l-0.32-2.921L508.55,117.655z M497.572,127.907
              l-1.174,0.25l0.866-3.058L497.572,127.907z M497.13,123.878l0.015,0.136l-1.201,4.24l-0.768,0.164l-2.303-1.906L497.13,123.878z
              M496.654,115.445l-4.103-2.778l7.441-5.652L496.654,115.445z M496.971,122.431l-3.819-1.96l3.533-0.646L496.971,122.431z
              M485.136,114.515l13.328-6.853l-6.582,4.999c-0.053,0.04-0.077,0.1-0.078,0.161l-4.264,2.338
              c-0.003,0.002-0.005,0.005-0.009,0.007c-0.026,0.015-0.051,0.033-0.073,0.053L485.136,114.515z M487.537,122.931l0.538-6.518
              l2.023,0.943l1.816,1.048l0.022,2.066L487.537,122.931z M491.94,120.938l0.055,5.043l-4.514-2.364l0.016-0.192L491.94,120.938z
              M487.318,115.607l0,0.001l-0.009-0.004L487.318,115.607z M487.444,124.06l4.556,2.386l0.001,0.122l-5.019,3.091L487.444,124.06
              z M486.94,130.167l5.066-3.12l0.047,4.294l-5.189,1.766l-0.159-0.084L486.94,130.167z M492.058,131.769l0.044,4.094
              l-4.741-2.495L492.058,131.769z M486.826,133.55l5.281,2.779l0.002,0.201l-5.024,3.229l-0.904-0.39l0.475-5.762L486.826,133.55z
              M492.248,169.816l-6.011,3.439l-1.986-8.205L492.248,169.816z M484.147,164.034l0.596-7.222l2.563,9.105L484.147,164.034z
              M486.777,177.23l-3.36-1.889l2.55-1.459L486.777,177.23z M486.337,173.67l5.861-3.353l-4.865,7.227l-0.068-0.038
              L486.337,173.67z M492.479,170.634l0.107,9.862l-4.894-2.751L492.479,170.634z M488.45,166.598l3.933-4.753l0.078,7.143
              L488.45,166.598z M488.094,166.385l-0.275-0.164l-2.738-9.725l0.098-0.019l7.192,4.131l0.007,0.602L488.094,166.385z
              M485.793,156.358l6.517-1.273l0.055,5.048L485.793,156.358z M485.25,156.046l-0.424-0.243l0.239-2.894l7.18-3.887l0.061,5.647
              L485.25,156.046z M487.124,140.222l3.637,1.57l-4.987,2.516l0.28-3.399L487.124,140.222z M486.097,140.394l0.049-0.594
              l0.523,0.226L486.097,140.394z M492.178,142.388l-6.334,2.378l-0.032-0.017l5.441-2.745L492.178,142.388z M491.729,141.763
              l0.449-0.219v0.409L491.729,141.763z M485.814,145.215l6.348,3.384l-7.057,3.821l0.591-7.16L485.814,145.215z M486.325,145.022
              l5.853-2.21l0.058,5.361L486.325,145.022z M491.238,141.552l-3.699-1.597l4.576-2.941l0.063,4.073L491.238,141.552z
              M486.614,124.151l-0.498,6.042l-10.361,6.381L486.614,124.151z M476.369,136.677l9.704-5.977l-0.216,2.614l-0.178,0.195
              L476.369,136.677z M485.803,133.983l-0.575,6.969l-13.96,8.972L485.803,133.983z M471.808,150.065l13.377-8.597l-0.292,3.542
              l-0.129,0.163L471.808,150.065z M484.832,145.748l-0.589,7.138l-8.846,4.79L484.832,145.748z M475.894,157.874l8.31-4.499
              l-0.241,2.923L475.894,157.874z M484.004,165.774l1.862,7.694l-2.621,1.499L484.004,165.774z M474.001,192.369l8.766-16.004
              l1.902,9.436L474.001,192.369z M485.072,185.727l-1.935-9.603l9.411,5.291l-7.414,4.348
              C485.113,185.752,485.095,185.735,485.072,185.727z M492.594,203.12l-7.216-17.024l7.216-4.232V203.12z M492.333,117.699
              l-3.76-2.171l3.725-2.042l3.569,2.417L492.333,117.699z M492.733,118.416l3.597-1.828l0.31,2.828l-3.889,0.711L492.733,118.416z
              M492.758,120.729l4.266,2.19l0.056,0.507l-4.264,2.639L492.758,120.729z M492.826,127.006l1.838,1.521l-1.817,0.387
              L492.826,127.006z M492.852,129.332l2.216-0.472l0.632,0.523l-1.388,0.801l-1.459-0.802L492.852,129.332z M492.857,129.853
              l1.038,0.571l-1.026,0.592L492.857,129.853z M492.878,131.795l2.418,2.73l-2.372,1.488L492.878,131.795z M492.936,137.107
              l2.318,3.266l-2.279,0.393L492.936,137.107z M492.989,142.003l-0.007-0.643l0.594,0.324L492.989,142.003z M492.994,142.467
              l1.01-0.549l3.102,1.692l-1.066,2.669l-3.015-3.535c-0.008-0.01-0.019-0.015-0.029-0.023L492.994,142.467z M493.004,143.35
              l2.782,3.262l-2.73,1.499L493.004,143.35z M493.06,148.577l5.711-3.136l-1.979,6.382l-3.731-3.104L493.06,148.577z
              M493.068,149.257l3.476,2.892l-3.423,2.05L493.068,149.257z M493.126,154.674l6.623-3.968l-2.933,7.308l-3.69-3.317
              L493.126,154.674z M493.133,155.255l3.519,3.163l-3.463,1.999L493.133,155.255z M493.199,161.32l4.112,5.472l-4.028,2.21
              L493.199,161.32z M493.407,180.511l-0.107-9.83l5.031,7.11L493.407,180.511z M493.414,202.905v-20.896l6.533,3.7
              L493.414,202.905z M500.279,185.328c-0.011,0.003-0.023,0-0.035,0.004c-0.023,0.008-0.04,0.026-0.061,0.038l-6.769-3.834v-0.093
              l9.434-5.21L500.279,185.328z M500.68,185.417l2.484-8.796l9.197,15.54L500.68,185.417z"/>
          </g>
          <g>
            <path class="st44" d="M485.378,186.096l7.009-4.111l-0.004-0.474l-7.25,4.252c-0.021-0.012-0.039-0.028-0.062-0.036
              l-1.935-9.603l9.246,5.198l-0.007-0.945l-4.683-2.633l4.787-7.111l-0.018-1.646l-4.011-2.39l3.933-4.753l-0.007-0.635
              l-4.283,5.175l-0.275-0.164l-2.738-9.725l0.098-0.019l7.192,4.131l-0.005-0.476l-6.572-3.774l-0.543-0.312l-0.424-0.243
              l0.239-2.894l0.04-0.488l0.591-7.16l0.118-0.045l6.324,3.371l-0.004-0.467l-5.81-3.097l5.853-2.21v-0.425l-6.334,2.378
              l-0.032-0.017l5.441-2.745l0.926,0.384v-0.435l-0.449-0.19l0.449-0.219v-0.457l-0.939,0.464l-3.699-1.597l4.576-2.941
              l-0.005-0.484l-5.024,3.229l-0.904-0.39l0.475-5.762l0.17-0.058l5.281,2.779l-0.005-0.466l-4.741-2.495l4.652-1.584
              l-0.003-0.428l-5.146,1.751l-0.159-0.084l0.236-2.856l5.037-3.102l-0.003-0.479l-4.992,3.074l0.462-5.599l4.529,2.372
              l-0.004-0.465l-4.489-2.351l0.016-0.192l4.435-2.481l-0.003-0.468l-4.391,2.457l0.527-6.385l-0.792-0.382l-0.602,7.299
              l-12.116,13.862c-0.007,0.008-0.01,0.017-0.015,0.025c-0.004,0.006-0.007,0.012-0.01,0.017c0.029-0.029,0.052-0.059,0.085-0.087
              c0.373-0.313,0.784-0.551,1.203-0.776l10.797-12.352l-0.723,8.768l-0.088,1.065l-0.575,6.969l-0.043,0.515l-0.292,3.542
              l-0.061,0.599l-0.562,0.189l-9.638,12.185c0.279-0.126,0.557-0.253,0.831-0.389l9.37-11.846l-0.589,7.138l-0.04,0.488
              l-0.241,2.923l-0.035,0.425l-1.535,18.618l-11.871,21.569c-0.279,0.043-0.549,0.132-0.797,0.27l-0.053,0.03
              c-0.15,0.083-0.238,0.236-0.238,0.407c0,0.081,0.025,0.283,0.239,0.402l0.04,0.022c0.349,0.192,0.745,0.293,1.144,0.293
              c0.399,0,0.794-0.101,1.144-0.294l0.064-0.035c0.15-0.082,0.24-0.234,0.24-0.406l0-0.008l-0.001-0.011
              c-0.006-0.161-0.09-0.299-0.23-0.381c-0.199-0.116-0.415-0.198-0.638-0.252l1.695-3.079l11.618-7.153l7.811,18.428l-0.016-2.14
              L485.378,186.096z M486.146,139.8l0.523,0.226l-0.573,0.368L486.146,139.8z M486.055,140.909l1.069-0.687l3.637,1.57
              l-4.987,2.516L486.055,140.909z M483.417,175.341l2.55-1.459l0.811,3.348L483.417,175.341z M487.334,177.543l-0.068-0.038
              l-0.928-3.835l5.861-3.353L487.334,177.543z M492.248,169.816l-6.011,3.439l-1.986-8.205L492.248,169.816z M484.743,156.811
              l2.563,9.105l-3.159-1.883L484.743,156.811z M484.004,165.774l1.862,7.694l-2.621,1.499L484.004,165.774z M474.001,192.369
              l8.766-16.004l1.902,9.436L474.001,192.369z"/>
            <path class="st44" d="M470.26,150.421c0.176-0.093,0.991-0.489,1.009-0.498l14.533-15.94l-0.692,0.151L470.26,150.421z"/>
          </g>
          <polygon class="st43" points="474.617,158.537 492.245,149.021 492.461,148.395 474.81,157.987 			"/>
          <polygon class="st43" points="474.776,158.083 474.617,158.537 492.311,155.085 492.306,154.668 			"/>
          <polygon class="st43" points="470.26,150.421 471.808,150.065 492.115,137.015 492.109,136.53 			"/>
          <polygon class="st43" points="471.808,150.065 470.657,150.938 492.56,142.695 492.461,142.281 			"/>
          <polygon class="st43" points="476.369,136.677 475.211,137.517 492.013,131.784 492.009,131.355 			"/>
          <polygon class="st43" points="475.755,136.574 476.369,136.677 491.977,127.064 491.974,126.585 			"/>
        </g>
        <g>
          <path class="st42" d="M493.948,177.314c-10.769-6.912-17.971-14.68-18.042-14.758l-0.302,0.278
            c0.072,0.078,7.303,7.879,18.114,14.82c8.256,5.3,20.056,10.981,32.697,10.981c2.654,0,5.347-0.251,8.05-0.808l-0.083-0.401
            C518.942,190.607,503.872,183.683,493.948,177.314z"/>
        </g>
        <g>
          <path class="st42" d="M494.699,170.567c-13.456-7.691-23.407-15.973-23.506-16.056l-0.263,0.314
            c0.099,0.083,10.074,8.386,23.56,16.094c11.114,6.352,26.706,13.449,40.901,13.449c1.719,0,3.42-0.105,5.088-0.327l-0.054-0.407
            C525.103,185.677,507.101,177.656,494.699,170.567z"/>
        </g>
        <g>
          <path class="st42" d="M496.811,161.552c-12.826-9.977-21.171-20.306-21.253-20.409l-0.32,0.257
            c0.083,0.104,8.452,10.464,21.314,20.47c11.403,8.871,28.676,19.197,47.288,19.197c0.78,0,1.565-0.018,2.349-0.056l-0.019-0.409
            C526.794,181.518,508.635,170.75,496.811,161.552z"/>
        </g>
        <g>
          <path class="st42" d="M509.666,142.673l-0.302,0.277c0.297,0.322,28.841,31.104,47.073,31.104c0.662,0,1.313-0.041,1.946-0.125
            l-0.054-0.407C540.482,175.904,509.971,143.006,509.666,142.673z"/>
        </g>
        <g>
          <path class="st42" d="M532.215,150.863c-10.234-10.328-17.296-21.716-17.367-21.83l-0.349,0.215
            c0.071,0.114,7.154,11.537,17.42,21.898c6.035,6.092,11.975,10.632,17.654,13.495c4.826,2.433,9.473,3.654,13.883,3.654
            c2.097,0,4.141-0.276,6.124-0.829l-0.11-0.395C555.744,170.896,541.635,160.369,532.215,150.863z"/>
        </g>
        <g>
          <path class="st42" d="M526.493,146.965c-10.101-12.459-16.885-25.505-16.953-25.635l-0.365,0.188
            c0.067,0.131,6.869,13.208,16.995,25.7c5.952,7.342,11.872,13.051,17.597,16.967c6.2,4.241,12.185,6.38,17.835,6.38
            c0.891,0,1.775-0.053,2.649-0.16l-0.05-0.407C549.883,171.747,535.793,158.436,526.493,146.965z"/>
        </g>
        <g>
          <path class="st42" d="M546.703,219.901c-2.499-0.554-5.948-5.081-8.186-23.501l-0.407,0.05
            c1.821,14.981,4.682,23.006,8.506,23.852c0.226,0.05,0.448,0.073,0.665,0.073c2.37,0,4.11-2.751,4.19-2.879l-0.349-0.217
            C551.102,217.311,549.12,220.438,546.703,219.901z"/>
        </g>
        <g>
          <path class="st42" d="M558.219,213.343c-2.362-0.473-5.646-4.892-7.879-23.261l-0.407,0.05
            c1.816,14.944,4.577,22.889,8.206,23.613c0.183,0.036,0.364,0.054,0.54,0.054c2.32,0,4.013-2.941,4.089-3.075l-0.357-0.202
            C562.392,210.554,560.517,213.801,558.219,213.343z"/>
        </g>
        <g>
          <g>
            <g>
              <path class="st44" d="M572.665,216.28c-0.219-0.127-0.469-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.244l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                c0.242,0,0.482-0.061,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246v-0.005l0-0.007
                C572.801,216.413,572.75,216.329,572.665,216.28z"/>
              <path class="st44" d="M584.263,216.28c-0.219-0.127-0.47-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.244l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                s0.482-0.061,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246l0-0.005l0-0.007
                C584.399,216.413,584.348,216.329,584.263,216.28z"/>
              <path class="st44" d="M578.387,219.858c-0.219-0.127-0.469-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.243l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                c0.242,0,0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.146-0.142,0.146-0.246l0-0.005l0-0.006
                C578.523,219.991,578.473,219.907,578.387,219.858z"/>
              <g>
                <path class="st44" d="M577.553,151.824c-0.119-0.018-0.236,0.055-0.273,0.171l-4.207,13.225
                  c-0.007,0.023-0.011,0.046-0.012,0.069l-1.255,50.37l-0.055,0.852c-0.009,0.137,0.095,0.256,0.232,0.264
                  c0.143,0.005,0.256-0.095,0.265-0.232l0.055-0.861l1.255-50.344l3.735-11.742l0.224,13.673l-0.124,52.658
                  c0,0.137,0.111,0.249,0.248,0.249h0c0.137,0,0.249-0.111,0.249-0.248l0.124-52.663l-0.249-15.199
                  C577.763,151.944,577.673,151.842,577.553,151.824z"/>
              </g>
              <g>
                <path class="st44" d="M577.441,151.833c-0.131,0.042-0.203,0.182-0.161,0.313l4.03,12.584l-3.565,2.258l-4.334-1.92
                  c-0.126-0.056-0.273,0.001-0.328,0.127c-0.056,0.126,0.001,0.273,0.127,0.328l4.456,1.974
                  c0.032,0.014,0.067,0.021,0.101,0.021c0.047,0,0.093-0.013,0.133-0.039l3.84-2.432c0.096-0.061,0.138-0.178,0.104-0.286
                  l-4.089-12.768C577.712,151.864,577.572,151.791,577.441,151.833z"/>
              </g>
              <g>
                <path class="st44" d="M581.596,164.589c-0.137,0.005-0.245,0.121-0.24,0.258l1.926,51.689c0.005,0.134,0.115,0.24,0.248,0.24
                  c0.003,0,0.006,0,0.01,0c0.137-0.005,0.245-0.121,0.239-0.258l-1.926-51.688C581.849,164.691,581.734,164.584,581.596,164.589
                  z"/>
              </g>
              <g>
                <path class="st44" d="M581.127,207.609c-0.051-0.073-0.137-0.112-0.225-0.106c-0.089,0.007-0.166,0.061-0.204,0.141
                  l-3.029,6.37l-2.683-6.228c-0.038-0.088-0.122-0.146-0.217-0.15c-0.099-0.005-0.184,0.046-0.23,0.13l-2.575,4.734
                  c-0.066,0.121-0.021,0.272,0.1,0.338c0.12,0.065,0.272,0.021,0.337-0.1l2.331-4.284l2.698,6.263
                  c0.039,0.09,0.126,0.149,0.224,0.15c0.002,0,0.003,0,0.005,0c0.096,0,0.183-0.055,0.225-0.142l3.081-6.48l2.043,2.935
                  c0.079,0.113,0.234,0.141,0.346,0.062c0.113-0.078,0.141-0.233,0.062-0.346L581.127,207.609z"/>
              </g>
              <g>
                <path class="st44" d="M582.104,182.388l-4.231,2.577l-4.773-2.812c-0.118-0.07-0.271-0.03-0.341,0.088
                  c-0.07,0.118-0.03,0.271,0.088,0.341l4.901,2.888c0.039,0.023,0.083,0.034,0.126,0.034c0.045,0,0.09-0.012,0.13-0.036
                  l4.358-2.655c0.117-0.071,0.155-0.225,0.083-0.342C582.375,182.354,582.223,182.317,582.104,182.388z"/>
              </g>
              <g>
                <path class="st44" d="M581.995,176.665l-4.119,2.411l-4.575-2.696c-0.119-0.07-0.271-0.03-0.341,0.088
                  c-0.07,0.118-0.03,0.271,0.088,0.341l4.701,2.77c0.039,0.023,0.083,0.034,0.126,0.034c0.044,0,0.087-0.012,0.126-0.034
                  l4.245-2.485c0.119-0.069,0.159-0.222,0.089-0.341C582.266,176.635,582.113,176.595,581.995,176.665z"/>
              </g>
              <g>
                <path class="st44" d="M581.479,171.076l-3.603,2.109l-4.44-2.616c-0.118-0.07-0.271-0.031-0.341,0.088
                  c-0.07,0.118-0.03,0.271,0.088,0.341l4.566,2.69c0.039,0.023,0.083,0.034,0.126,0.034c0.044,0,0.087-0.012,0.126-0.034
                  l3.73-2.183c0.119-0.069,0.159-0.222,0.089-0.34C581.75,171.046,581.597,171.006,581.479,171.076z"/>
              </g>
              <g>
                <path class="st44" d="M582.223,188.167l-4.347,2.686l-4.866-3.01c-0.117-0.072-0.27-0.036-0.343,0.081
                  c-0.072,0.117-0.036,0.27,0.081,0.343l4.997,3.09c0.04,0.025,0.086,0.037,0.131,0.037c0.045,0,0.091-0.012,0.131-0.037
                  l4.478-2.767c0.117-0.072,0.153-0.226,0.081-0.343C582.492,188.131,582.339,188.095,582.223,188.167z"/>
              </g>
              <g>
                <path class="st44" d="M582.223,194.058l-4.347,2.686l-5.145-3.182c-0.117-0.072-0.27-0.036-0.343,0.081
                  c-0.072,0.117-0.036,0.27,0.081,0.343l5.276,3.263c0.04,0.025,0.086,0.037,0.131,0.037c0.045,0,0.091-0.012,0.131-0.037
                  l4.478-2.767c0.117-0.072,0.153-0.226,0.081-0.343C582.492,194.022,582.339,193.986,582.223,194.058z"/>
              </g>
              <g>
                <path class="st44" d="M582.715,199.91l-4.838,2.974l-4.866-3.01c-0.117-0.072-0.27-0.036-0.343,0.081
                  c-0.072,0.117-0.036,0.27,0.081,0.343l4.997,3.09c0.04,0.025,0.086,0.037,0.131,0.037c0.045,0,0.09-0.012,0.13-0.037
                  l4.969-3.054c0.117-0.072,0.154-0.225,0.082-0.342C582.985,199.874,582.831,199.837,582.715,199.91z"/>
              </g>
              <g>
                <path class="st44" d="M582.828,206.023l-4.956,3.185l-5.392-3.277c-0.118-0.072-0.27-0.034-0.342,0.083
                  c-0.072,0.118-0.034,0.271,0.083,0.342l5.525,3.357c0.04,0.024,0.084,0.036,0.129,0.036c0.047,0,0.094-0.013,0.135-0.04
                  l5.087-3.269c0.116-0.074,0.149-0.228,0.075-0.344C583.097,205.982,582.944,205.949,582.828,206.023z"/>
              </g>
              <g>
                <path class="st44" d="M577.985,179.305l-2.397-4.358l2.35-1.365c0.059-0.034,0.079-0.109,0.046-0.168l-2.446-4.382l2.3-1.661
                  c0.056-0.04,0.068-0.118,0.028-0.174c-0.041-0.056-0.118-0.068-0.174-0.028l-2.276,1.644l-1.998-3.578
                  c-0.034-0.06-0.11-0.081-0.169-0.048c-0.06,0.034-0.081,0.109-0.048,0.169l2.012,3.604l-2.202,1.59
                  c-0.051,0.037-0.066,0.106-0.036,0.161l2.278,4.143l-2.474,1.438c-0.058,0.034-0.079,0.107-0.047,0.166l2.365,4.464
                  l-2.188,1.34c-0.057,0.035-0.076,0.108-0.044,0.167l2.295,4.11l-2.346,1.411c-0.057,0.034-0.077,0.107-0.045,0.166
                  l2.323,4.278l-2.548,1.269c-0.031,0.015-0.054,0.042-0.064,0.075c-0.01,0.033-0.006,0.068,0.01,0.097l2.496,4.448
                  l-2.519,1.394c-0.051,0.028-0.075,0.088-0.059,0.143l2.229,7.785c0.013,0.046,0.051,0.08,0.099,0.089
                  c0.007,0.001,0.014,0.002,0.021,0.002c0.04,0,0.078-0.019,0.102-0.053l3.118-4.394c0.028-0.039,0.03-0.091,0.007-0.133
                  l-2.658-4.737l2.429-1.344c0.06-0.033,0.082-0.108,0.049-0.168l-2.37-4.365l2.497-1.243c0.031-0.015,0.053-0.042,0.063-0.075
                  c0.01-0.033,0.006-0.068-0.01-0.097l-2.489-4.458l2.104-1.265c0.057-0.034,0.077-0.107,0.046-0.165l-2.218-4.186l2.514-1.54
                  C577.997,179.436,578.017,179.363,577.985,179.305z M573.246,170.687l2.089-1.509l2.372,4.249l-2.239,1.301L573.246,170.687z
                  M577.729,203.168l-2.922,4.118l-2.131-7.441l2.433-1.347L577.729,203.168z M577.527,196.876l-2.323,1.285l-2.432-4.335
                  l2.439-1.215L577.527,196.876z M577.703,191.093l-2.388,1.189l-2.271-4.181l2.237-1.345L577.703,191.093z M577.369,185.209
                  l-1.996,1.2l-2.232-3.998l2.072-1.27L577.369,185.209z M575.31,180.791l-2.302-4.344l2.366-1.374l2.336,4.249L575.31,180.791z
                  "/>
              </g>
              <g>
                <path class="st44" d="M577.861,196.881l4.602-8.443c0.03-0.056,0.014-0.126-0.039-0.162l-4.605-3.189l4.411-8.148
                  c0.029-0.053,0.015-0.119-0.032-0.156l-4.171-3.346l3.692-8.549c0.027-0.063-0.002-0.136-0.065-0.164
                  c-0.063-0.027-0.136,0.002-0.164,0.065l-3.73,8.636c-0.022,0.051-0.007,0.111,0.037,0.146l4.164,3.341l-4.415,8.155
                  c-0.03,0.056-0.014,0.125,0.039,0.162l4.605,3.189l-4.605,8.448c-0.032,0.058-0.012,0.13,0.044,0.165l5.072,3.149
                  l-1.899,7.541c-0.017,0.066,0.024,0.134,0.09,0.151c0.01,0.003,0.021,0.004,0.03,0.004c0.056,0,0.107-0.038,0.121-0.094
                  l1.922-7.63c0.013-0.052-0.009-0.107-0.055-0.136L577.861,196.881z"/>
              </g>
              <g>
                <path class="st44" d="M578.039,191.1l4.306-8.443c0.027-0.053,0.013-0.118-0.034-0.155l-4.28-3.348l3.687-7.81
                  c0.022-0.046,0.013-0.102-0.023-0.139l-3.84-4.021c-0.047-0.05-0.126-0.052-0.176-0.004c-0.049,0.048-0.051,0.126-0.004,0.176
                  l3.781,3.96l-3.693,7.823c-0.025,0.053-0.01,0.115,0.036,0.151l4.278,3.347l-4.312,8.454c-0.029,0.058-0.01,0.128,0.045,0.162
                  l4.595,2.836l-4.64,9.031c-0.021,0.04-0.018,0.088,0.007,0.126l3.047,4.575c0.024,0.036,0.063,0.055,0.104,0.055
                  c0.024,0,0.048-0.007,0.069-0.021c0.057-0.038,0.073-0.115,0.035-0.173l-3.007-4.514l4.659-9.069
                  c0.03-0.058,0.01-0.129-0.045-0.163L578.039,191.1z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st44" d="M522.686,244.839c-0.219-0.127-0.47-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                  c-0.091,0.05-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.243l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                  s0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.146-0.142,0.146-0.246v-0.005l0-0.007
                  C522.822,244.973,522.771,244.889,522.686,244.839z"/>
                <path class="st44" d="M534.283,244.839c-0.219-0.127-0.47-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                  c-0.091,0.05-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.243l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                  c0.242,0,0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246l0-0.005l0-0.007
                  C534.419,244.973,534.368,244.889,534.283,244.839z"/>
                <path class="st44" d="M528.408,248.417c-0.219-0.127-0.47-0.195-0.723-0.195c-0.245,0-0.488,0.063-0.703,0.183l-0.032,0.018
                  c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.244l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
                  s0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246l0-0.005l0-0.007
                  C528.544,248.551,528.493,248.467,528.408,248.417z"/>
                <g>
                  <path class="st44" d="M527.573,180.384c-0.118-0.018-0.236,0.055-0.274,0.171l-4.207,13.225
                    c-0.007,0.023-0.011,0.046-0.012,0.069l-1.255,50.37l-0.055,0.852c-0.009,0.137,0.095,0.256,0.232,0.264
                    c0.142,0.005,0.255-0.095,0.265-0.232l0.055-0.861l1.255-50.344l3.735-11.742l0.224,13.673l-0.125,52.658
                    c0,0.137,0.111,0.249,0.249,0.249h0c0.137,0,0.248-0.111,0.249-0.248l0.125-52.663l-0.249-15.199
                    C527.784,180.504,527.694,180.402,527.573,180.384z"/>
                </g>
                <g>
                  <path class="st44" d="M527.461,180.393c-0.131,0.042-0.203,0.182-0.161,0.313l4.03,12.585l-3.565,2.257l-4.334-1.92
                    c-0.126-0.056-0.273,0.001-0.328,0.127c-0.056,0.126,0.001,0.273,0.127,0.328l4.456,1.974
                    c0.032,0.014,0.067,0.021,0.101,0.021c0.046,0,0.093-0.013,0.133-0.038l3.84-2.432c0.096-0.061,0.138-0.178,0.104-0.286
                    l-4.089-12.768C527.732,180.423,527.593,180.351,527.461,180.393z"/>
                </g>
                <g>
                  <path class="st44" d="M531.616,193.149c-0.137,0.005-0.244,0.12-0.239,0.258l1.926,51.689c0.005,0.134,0.115,0.24,0.248,0.24
                    c0.003,0,0.006,0,0.01,0c0.137-0.005,0.245-0.121,0.239-0.258l-1.926-51.689C531.87,193.251,531.754,193.146,531.616,193.149
                    z"/>
                </g>
                <g>
                  <path class="st44" d="M531.148,236.169c-0.051-0.073-0.137-0.112-0.225-0.106c-0.089,0.007-0.166,0.061-0.204,0.141
                    l-3.029,6.37l-2.683-6.228c-0.038-0.088-0.122-0.146-0.217-0.15c-0.099-0.004-0.185,0.046-0.23,0.13l-2.575,4.734
                    c-0.066,0.121-0.021,0.272,0.1,0.338c0.121,0.065,0.272,0.021,0.338-0.1l2.33-4.284l2.698,6.263
                    c0.039,0.09,0.126,0.148,0.224,0.15c0.002,0,0.003,0,0.005,0c0.096,0,0.184-0.055,0.225-0.142l3.081-6.48l2.043,2.935
                    c0.079,0.113,0.234,0.141,0.346,0.062c0.113-0.079,0.141-0.234,0.062-0.346L531.148,236.169z"/>
                </g>
                <g>
                  <path class="st44" d="M532.125,210.948l-4.231,2.577l-4.772-2.812c-0.118-0.07-0.271-0.03-0.341,0.088
                    c-0.07,0.118-0.03,0.271,0.088,0.341l4.901,2.888c0.039,0.023,0.083,0.035,0.126,0.035c0.045,0,0.09-0.012,0.13-0.036
                    l4.358-2.655c0.117-0.072,0.155-0.225,0.083-0.342C532.396,210.914,532.243,210.876,532.125,210.948z"/>
                </g>
                <g>
                  <path class="st44" d="M532.016,205.224l-4.119,2.411l-4.575-2.695c-0.118-0.07-0.271-0.031-0.341,0.088
                    c-0.07,0.118-0.03,0.271,0.088,0.341l4.701,2.77c0.039,0.023,0.083,0.035,0.126,0.035c0.044,0,0.087-0.011,0.126-0.034
                    l4.245-2.485c0.119-0.07,0.158-0.222,0.089-0.341C532.286,205.195,532.134,205.155,532.016,205.224z"/>
                </g>
                <g>
                  <path class="st44" d="M531.5,199.636l-3.604,2.109l-4.44-2.616c-0.118-0.07-0.271-0.03-0.341,0.088
                    c-0.07,0.118-0.03,0.271,0.088,0.341l4.566,2.69c0.039,0.023,0.083,0.035,0.126,0.035c0.044,0,0.087-0.011,0.126-0.034
                    l3.729-2.183c0.119-0.07,0.159-0.222,0.089-0.341C531.771,199.606,531.618,199.566,531.5,199.636z"/>
                </g>
                <g>
                  <path class="st44" d="M532.243,216.727l-4.347,2.686l-4.866-3.01c-0.117-0.072-0.27-0.036-0.343,0.081
                    c-0.072,0.117-0.036,0.27,0.081,0.343l4.997,3.091c0.04,0.025,0.086,0.037,0.131,0.037s0.091-0.012,0.131-0.037l4.478-2.767
                    c0.117-0.072,0.153-0.226,0.081-0.342C532.513,216.691,532.359,216.655,532.243,216.727z"/>
                </g>
                <g>
                  <path class="st44" d="M532.243,222.618l-4.347,2.686l-5.145-3.182c-0.117-0.072-0.27-0.036-0.343,0.081
                    c-0.072,0.117-0.036,0.27,0.081,0.343l5.276,3.263c0.04,0.025,0.086,0.037,0.131,0.037s0.091-0.012,0.131-0.037l4.478-2.767
                    c0.117-0.072,0.153-0.225,0.081-0.342C532.513,222.581,532.359,222.545,532.243,222.618z"/>
                </g>
                <g>
                  <path class="st44" d="M532.735,228.469l-4.838,2.974l-4.866-3.01c-0.117-0.072-0.27-0.036-0.343,0.081
                    c-0.072,0.117-0.036,0.27,0.081,0.343l4.997,3.091c0.04,0.025,0.086,0.037,0.131,0.037c0.045,0,0.09-0.012,0.13-0.037
                    l4.969-3.055c0.117-0.072,0.154-0.225,0.082-0.342C533.005,228.433,532.852,228.397,532.735,228.469z"/>
                </g>
                <g>
                  <path class="st44" d="M532.848,234.583l-4.956,3.185l-5.392-3.277c-0.117-0.072-0.271-0.034-0.342,0.083
                    c-0.071,0.117-0.034,0.27,0.083,0.342l5.525,3.358c0.04,0.024,0.084,0.036,0.129,0.036c0.047,0,0.094-0.013,0.135-0.04
                    l5.087-3.269c0.115-0.074,0.149-0.228,0.075-0.344C533.118,234.542,532.965,234.508,532.848,234.583z"/>
                </g>
                <g>
                  <path class="st44" d="M528.005,207.864l-2.396-4.358l2.35-1.365c0.059-0.034,0.079-0.109,0.046-0.169l-2.446-4.382l2.3-1.662
                    c0.056-0.04,0.068-0.118,0.028-0.174c-0.04-0.056-0.118-0.068-0.174-0.028l-2.276,1.644l-1.998-3.578
                    c-0.034-0.06-0.11-0.081-0.169-0.048c-0.06,0.034-0.082,0.109-0.048,0.169l2.012,3.604l-2.202,1.59
                    c-0.051,0.037-0.066,0.106-0.036,0.161l2.278,4.143l-2.474,1.437c-0.058,0.034-0.079,0.107-0.047,0.166l2.365,4.464
                    l-2.188,1.34c-0.057,0.035-0.076,0.109-0.044,0.167l2.294,4.11l-2.346,1.41c-0.057,0.034-0.077,0.108-0.045,0.166
                    l2.323,4.278l-2.548,1.269c-0.031,0.015-0.053,0.042-0.063,0.075c-0.01,0.033-0.006,0.068,0.01,0.098l2.496,4.447
                    l-2.519,1.394c-0.051,0.028-0.075,0.088-0.059,0.143l2.229,7.785c0.013,0.046,0.052,0.08,0.099,0.089
                    c0.007,0.001,0.014,0.002,0.021,0.002c0.04,0,0.078-0.019,0.102-0.053l3.118-4.394c0.028-0.039,0.03-0.091,0.007-0.133
                    l-2.658-4.737l2.429-1.345c0.06-0.033,0.082-0.108,0.049-0.168l-2.37-4.365l2.497-1.243c0.031-0.015,0.053-0.042,0.063-0.075
                    c0.01-0.033,0.006-0.067-0.01-0.097l-2.489-4.458l2.103-1.265c0.057-0.034,0.077-0.106,0.046-0.165l-2.218-4.186l2.514-1.54
                    C528.018,207.996,528.037,207.923,528.005,207.864z M523.267,199.247l2.089-1.509l2.372,4.249l-2.239,1.301L523.267,199.247z
                    M527.749,231.728l-2.922,4.119l-2.131-7.441l2.433-1.347L527.749,231.728z M527.548,225.436l-2.323,1.285l-2.432-4.335
                    l2.439-1.215L527.548,225.436z M527.724,219.653l-2.387,1.189l-2.271-4.181l2.237-1.345L527.724,219.653z M527.39,213.769
                    l-1.996,1.2l-2.232-3.998l2.073-1.27L527.39,213.769z M525.33,209.351l-2.302-4.345l2.365-1.374l2.336,4.248L525.33,209.351z
                    "/>
                </g>
                <g>
                  <path class="st44" d="M527.881,225.441l4.602-8.443c0.031-0.056,0.014-0.126-0.039-0.162l-4.605-3.189l4.411-8.148
                    c0.029-0.053,0.015-0.119-0.032-0.156l-4.171-3.346l3.692-8.549c0.027-0.063-0.002-0.136-0.065-0.164
                    c-0.063-0.027-0.136,0.002-0.164,0.065l-3.73,8.636c-0.022,0.051-0.007,0.111,0.037,0.146l4.164,3.34l-4.415,8.155
                    c-0.03,0.056-0.014,0.126,0.038,0.162l4.605,3.189l-4.605,8.448c-0.031,0.058-0.012,0.131,0.044,0.165l5.072,3.149
                    l-1.899,7.541c-0.017,0.067,0.024,0.134,0.09,0.151c0.01,0.003,0.02,0.004,0.03,0.004c0.056,0,0.107-0.038,0.121-0.094
                    l1.922-7.63c0.013-0.052-0.009-0.107-0.055-0.136L527.881,225.441z"/>
                </g>
                <g>
                  <path class="st44" d="M528.059,219.66l4.306-8.443c0.027-0.053,0.013-0.118-0.034-0.155l-4.28-3.348l3.687-7.81
                    c0.022-0.047,0.013-0.102-0.023-0.139l-3.84-4.021c-0.047-0.049-0.126-0.051-0.176-0.004
                    c-0.05,0.047-0.052,0.126-0.004,0.176l3.781,3.96l-3.693,7.823c-0.025,0.053-0.01,0.116,0.036,0.151l4.277,3.347
                    l-4.312,8.454c-0.029,0.058-0.01,0.129,0.046,0.163l4.595,2.836l-4.64,9.032c-0.021,0.04-0.018,0.088,0.007,0.126
                    l3.048,4.575c0.024,0.036,0.063,0.056,0.103,0.056c0.024,0,0.048-0.007,0.069-0.021c0.057-0.038,0.073-0.115,0.035-0.173
                    l-3.007-4.514l4.659-9.069c0.03-0.058,0.01-0.129-0.045-0.163L528.059,219.66z"/>
                </g>
              </g>
            </g>
            <g>
              <path class="st44" d="M581.481,164.622l-53.819,30.991c-0.119,0.068-0.16,0.221-0.091,0.34c0.046,0.08,0.13,0.125,0.216,0.125
                c0.042,0,0.085-0.011,0.124-0.033l53.819-30.991c0.119-0.069,0.16-0.221,0.091-0.34
                C581.752,164.595,581.6,164.554,581.481,164.622z"/>
            </g>
            <g>
              <path class="st44" d="M581.481,171.075l-53.709,30.743c-0.119,0.068-0.161,0.221-0.092,0.34c0.046,0.08,0.13,0.125,0.216,0.125
                c0.042,0,0.084-0.01,0.123-0.033l53.709-30.743c0.119-0.068,0.161-0.22,0.092-0.34
                C581.753,171.048,581.601,171.007,581.481,171.075z"/>
            </g>
            <g>
              <path class="st44" d="M573.187,165.079l-42.889,24.338c-0.12,0.068-0.162,0.22-0.094,0.339c0.046,0.081,0.13,0.126,0.217,0.126
                c0.042,0,0.084-0.01,0.122-0.032l42.889-24.338c0.12-0.068,0.162-0.22,0.094-0.339
                C573.458,165.053,573.306,165.011,573.187,165.079z"/>
            </g>
            <g>
              <path class="st44" d="M534.468,187.296c-0.118-0.07-0.271-0.031-0.341,0.087c-0.07,0.118-0.032,0.271,0.087,0.341l3.851,2.293
                v6.16c0,0.138,0.111,0.249,0.249,0.249c0.138,0,0.249-0.111,0.249-0.249v-6.301c0-0.088-0.046-0.169-0.121-0.214
                L534.468,187.296z"/>
            </g>
            <g>
              <path class="st44" d="M540.451,183.838c-0.118-0.07-0.271-0.031-0.341,0.087c-0.07,0.118-0.032,0.271,0.086,0.341l3.851,2.293
                v6.16c0,0.138,0.111,0.249,0.249,0.249c0.138,0,0.249-0.111,0.249-0.249v-6.301c0-0.088-0.046-0.169-0.122-0.214
                L540.451,183.838z"/>
            </g>
            <g>
              <path class="st44" d="M546.434,180.38c-0.118-0.07-0.271-0.031-0.341,0.087c-0.07,0.118-0.032,0.271,0.086,0.341l3.851,2.293
                v6.16c0,0.137,0.111,0.249,0.249,0.249c0.138,0,0.249-0.111,0.249-0.249v-6.301c0-0.088-0.046-0.169-0.122-0.214
                L546.434,180.38z"/>
            </g>
            <g>
              <path class="st44" d="M552.684,177.08c-0.118-0.07-0.271-0.031-0.341,0.087c-0.07,0.118-0.032,0.271,0.087,0.341l3.584,2.134
                v6.16c0,0.138,0.111,0.249,0.249,0.249c0.138,0,0.249-0.111,0.249-0.249V179.5c0-0.088-0.046-0.169-0.122-0.214L552.684,177.08
                z"/>
            </g>
            <g>
              <path class="st44" d="M558.697,173.64c-0.118-0.07-0.271-0.032-0.341,0.087c-0.07,0.118-0.032,0.271,0.087,0.341l3.554,2.116
                v6.16c0,0.137,0.111,0.249,0.249,0.249c0.138,0,0.249-0.112,0.249-0.249v-6.301c0-0.088-0.046-0.169-0.121-0.214
                L558.697,173.64z"/>
            </g>
            <g>
              <path class="st44" d="M564.568,170.115c-0.118-0.07-0.271-0.031-0.341,0.087c-0.07,0.118-0.032,0.271,0.086,0.341l3.667,2.183
                v6.16c0,0.137,0.111,0.249,0.249,0.249c0.138,0,0.249-0.111,0.249-0.249v-6.301c0-0.088-0.046-0.169-0.122-0.214
                L564.568,170.115z"/>
            </g>
            <g>
              <path class="st44" d="M573.093,169.616c-0.811,1.41-4.767,8.317-4.98,9.209c-0.462-0.004-3.27-1.448-5.806-2.891
                c-0.058-0.033-0.132-0.014-0.168,0.043l-5.925,9.664l-5.882-2.795c-0.058-0.027-0.126-0.007-0.16,0.047l-5.924,9.664
                l-5.882-2.795c-0.057-0.027-0.125-0.007-0.158,0.046l-6.172,9.729c-0.037,0.058-0.02,0.135,0.039,0.171
                c0.021,0.013,0.044,0.02,0.067,0.02c0.041,0,0.081-0.02,0.105-0.058l6.113-9.635l5.883,2.796
                c0.058,0.027,0.126,0.007,0.159-0.048l5.925-9.664l5.882,2.795c0.058,0.027,0.126,0.007,0.159-0.047l5.92-9.657
                c1.733,0.984,5.539,3.086,5.976,2.831c0.056-0.033,0.089-0.09,0.089-0.155c0.022-0.426,2.976-5.703,4.956-9.146
                c0.034-0.06,0.014-0.136-0.046-0.17C573.203,169.536,573.127,169.556,573.093,169.616z"/>
            </g>
            <g>
              <path class="st44" d="M568.29,172.475c-0.059-0.033-0.133-0.014-0.168,0.043l-5.924,9.664l-5.882-2.795
                c-0.058-0.027-0.126-0.007-0.16,0.047l-5.924,9.664l-5.882-2.795c-0.058-0.027-0.126-0.007-0.16,0.047l-5.927,9.669
                l-6.589-2.738c-0.063-0.026-0.136,0.003-0.163,0.067c-0.026,0.063,0.004,0.136,0.067,0.163l6.688,2.779
                c0.015,0.006,0.032,0.01,0.048,0.01c0.042,0,0.083-0.022,0.106-0.059l5.925-9.664l5.882,2.795
                c0.058,0.027,0.126,0.007,0.16-0.047l5.924-9.664l5.882,2.795c0.058,0.027,0.126,0.007,0.16-0.047l5.92-9.657l5.469,3.114
                c0.059,0.034,0.136,0.013,0.17-0.047c0.034-0.06,0.013-0.136-0.047-0.17L568.29,172.475z"/>
            </g>
            <g>
              <path class="st44" d="M573.191,169.554l-8.76,0.651c-0.048,0.004-0.09,0.035-0.107,0.079l-2.168,5.644l-9.616,1.243
                c-0.044,0.006-0.083,0.035-0.099,0.077l-2.249,5.595l-9.882,1.085c-0.048,0.005-0.088,0.038-0.104,0.083l-1.983,5.745
                l-7.568,0.509c-0.069,0.005-0.121,0.064-0.116,0.133c0.004,0.066,0.059,0.116,0.124,0.116c0.003,0,0.006,0,0.008,0l7.651-0.514
                c0.05-0.003,0.093-0.036,0.109-0.084l1.984-5.749l9.878-1.085c0.046-0.005,0.085-0.035,0.102-0.077l2.25-5.597l9.617-1.243
                c0.045-0.006,0.084-0.036,0.1-0.079l2.167-5.64l8.682-0.645c0.068-0.005,0.12-0.065,0.115-0.133
                C573.32,169.6,573.258,169.549,573.191,169.554z"/>
            </g>
            <g>
              <path class="st44" d="M571.565,166.114l-3.417,6.354l-9.595,1.262c-0.044,0.006-0.082,0.035-0.099,0.076l-2.279,5.578
                l-9.882,1.085c-0.048,0.005-0.088,0.038-0.104,0.083l-1.984,5.749l-9.878,1.085c-0.043,0.005-0.081,0.032-0.099,0.072
                l-2.715,5.887c-0.029,0.063-0.001,0.136,0.061,0.165c0.017,0.008,0.035,0.012,0.052,0.012c0.047,0,0.092-0.027,0.113-0.072
                l2.685-5.823l9.886-1.086c0.048-0.005,0.088-0.038,0.104-0.083l1.984-5.749l9.878-1.085c0.046-0.005,0.084-0.034,0.102-0.077
                l2.28-5.579l9.587-1.261c0.04-0.005,0.074-0.029,0.093-0.064l3.447-6.41c0.033-0.061,0.01-0.136-0.051-0.169
                C571.674,166.031,571.598,166.053,571.565,166.114z"/>
            </g>
          </g>
          <g>
            <path class="st43" d="M584.263,216.28c-0.154-0.089-0.323-0.147-0.498-0.175l-1.456-39.066c0.066-0.078,0.082-0.191,0.027-0.285
              c-0.01-0.018-0.026-0.03-0.039-0.044l-0.443-11.881c0-0.004-0.002-0.006-0.002-0.01c-0.001-0.01-0.003-0.02-0.005-0.03
              c-0.002-0.009-0.001-0.018-0.004-0.027l-4.089-12.768c-0.001-0.002-0.003-0.004-0.004-0.006
              c-0.009-0.025-0.022-0.047-0.037-0.067c-0.003-0.004-0.005-0.01-0.008-0.014c-0.02-0.023-0.044-0.041-0.07-0.056
              c-0.01-0.006-0.022-0.007-0.033-0.011c-0.016-0.006-0.03-0.013-0.047-0.016c-0.002,0-0.004,0-0.006,0
              c-0.012-0.001-0.024,0.001-0.036,0.001c-0.02,0.001-0.039,0.001-0.057,0.006c-0.004,0.001-0.008,0-0.012,0.001
              c-0.009,0.003-0.015,0.009-0.023,0.013c-0.016,0.007-0.032,0.015-0.047,0.026c-0.011,0.008-0.021,0.018-0.031,0.028
              c-0.012,0.012-0.021,0.024-0.03,0.038c-0.002,0.004-0.004,0.008-0.006,0.012l0.207,14.934l-0.534,0.308l0.024,0.01l-1.879,1.082
              l-0.01-0.018l-0.216,0.124l0.01,0.018l-1.445,0.832l0-0.023l-0.505,0.291l-0.001,0.022l-0.078,0.045l-0.044,0.003l-0.497,0.286
              l0.043-0.003l-3.689,2.124l0.016-0.029l-0.41,0.236l-0.015,0.028l-0.086,0.05l-0.017-0.01l-1.017,0.586l0.043-0.006
              l-4.618,2.659l0.008-0.021l-0.342,0.197l-0.008,0.021l-0.044,0.025l-0.014-0.008l-1.025,0.591l0.035-0.005l-4.568,2.631
              l0.007-0.018l-0.352,0.203l-0.007,0.017l-0.071,0.041l-0.011-0.007l-0.992,0.572l0.027-0.003l-4.583,2.639l0.005-0.013
              l-0.349,0.201l-0.005,0.013l-0.086,0.05l-0.008-0.005l-1.005,0.579l0.02-0.002l-4.593,2.645l0.003-0.008l-0.329,0.189
              l-0.003,0.008l-0.079,0.046l-0.006-0.003l-1.017,0.586l0.013-0.001l-4.56,2.625l0.002-0.004l-0.329,0.189l-0.002,0.004
              l-0.096,0.056l-0.003-0.002l-0.935,0.539l0.006,0l-5.436,3.13l-0.113-0.349l-0.017,0.037l-0.766-2.393l0.017-0.001l-0.08-0.244
              l-0.015,0.001l-0.16-0.501l0.011-0.006l-0.157-0.483l-0.009,0.005l-0.664-2.075l-2.192-6.738
              c-0.016-0.016-0.033-0.031-0.053-0.042c-0.01-0.006-0.022-0.007-0.033-0.011c-0.016-0.006-0.031-0.014-0.048-0.016
              c-0.003,0-0.005,0-0.008,0c-0.006-0.001-0.012,0-0.017,0c-0.024-0.001-0.047,0.002-0.069,0.008
              c-0.006,0.001-0.012,0-0.017,0.002c-0.007,0.002-0.011,0.008-0.018,0.011c-0.02,0.009-0.039,0.02-0.056,0.034
              c-0.006,0.005-0.014,0.007-0.019,0.012c0,0.001,0,0.002,0,0.003c0.013,0.096,0.02,0.194,0.026,0.291
              c0.004,0.073,0.004,0.146,0.004,0.219c0,0.277-0.027,0.556-0.089,0.834c-0.005,0.024-0.009,0.048-0.014,0.072l0.006,0.329
              l0.013-0.041l0.203,12.398l0,0.006l0.053,1.58l-0.047,6.256l-0.027,11.669l-0.077,32.297l-0.004,1.89
              c-0.151,0.029-0.297,0.079-0.431,0.154l-0.032,0.018c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.244
              l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178s0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246
              l0-0.005l0-0.007c-0.004-0.097-0.055-0.182-0.14-0.231c-0.154-0.089-0.323-0.147-0.497-0.175l0.012-4.999l3.061-6.439
              l2.043,2.935c0.021,0.031,0.049,0.053,0.079,0.071l0.181,4.863c-0.15,0.029-0.296,0.079-0.43,0.154l-0.032,0.018
              c-0.091,0.05-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.243l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178
              c0.242,0,0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246l0-0.005l0-0.007
              c-0.004-0.098-0.054-0.182-0.14-0.231c-0.154-0.089-0.323-0.147-0.498-0.175l-1.456-39.066c0.066-0.078,0.082-0.191,0.027-0.285
              c-0.01-0.018-0.026-0.03-0.039-0.044l-0.2-5.365l6.109-3.497c0.027,0.01,0.056,0.018,0.087,0.018
              c0.122,0,0.218-0.089,0.24-0.205l5.7-3.262c0.015,0.003,0.028,0.009,0.043,0.009c0.108,0,0.198-0.07,0.232-0.167l5.751-3.292
              c0.088,0,0.162-0.049,0.206-0.118l5.867-3.359c0.01-0.004,0.018-0.011,0.028-0.016l16.397-9.386l0.491,0.309l4.219,2.486
              l-0.001,0.324l-0.012,4.982l-0.002,0.707l-0.004,1.566l-0.011,4.489l-0.008,3.337l-0.059,24.863
              c-0.15,0.029-0.296,0.079-0.431,0.154l-0.032,0.018c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.243
              l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178c0.242,0,0.482-0.062,0.694-0.178l0.039-0.021
              c0.091-0.05,0.146-0.142,0.146-0.246l0-0.005l0-0.006c-0.004-0.098-0.054-0.182-0.14-0.231
              c-0.154-0.089-0.323-0.147-0.497-0.175l0.012-4.998l3.062-6.44l2.043,2.935c0.021,0.031,0.049,0.054,0.079,0.071l0.181,4.863
              c-0.15,0.029-0.296,0.079-0.43,0.154l-0.032,0.018c-0.091,0.051-0.145,0.143-0.144,0.247c0,0.049,0.015,0.172,0.145,0.244
              l0.025,0.013c0.212,0.117,0.452,0.178,0.694,0.178s0.482-0.062,0.694-0.178l0.039-0.021c0.091-0.05,0.145-0.142,0.145-0.246
              l0-0.005l0-0.007C584.399,216.413,584.348,216.329,584.263,216.28z M537.902,190.291l-2.801,4.416l-2.827-1.175L537.902,190.291
              z M531.855,206.23l0.171,4.594l-1.867-1.461L531.855,206.23z M528.012,219.924l1.94,1.197l-1.986,3.643l0.011-4.825
              C527.99,219.935,528.001,219.93,528.012,219.924z M527.98,219.268l0.012-5.213l1.956,1.354L527.98,219.268z M530.337,227.258
              l2.174,1.35l-4.19,2.576L530.337,227.258z M527.951,231.357l0.013-5.521c0.016-0.005,0.032-0.013,0.047-0.021l2.113,1.312
              L527.951,231.357z M530.239,226.904l-1.988-1.234l3.842-2.374L530.239,226.904z M527.987,225.248l2.178-3.996l2.162,1.335
              c-0.029,0.006-0.057,0.015-0.084,0.031L527.987,225.248z M530.072,220.902l-1.82-1.123l3.668-2.267L530.072,220.902z
              M528.318,219.152l1.836-3.599l1.881,1.303L528.318,219.152z M530.062,215.186l-1.842-1.276l3.617-2.203L530.062,215.186z
              M527.993,213.464l0-0.102l2.045-3.778l1.893,1.481L527.993,213.464z M528.244,201.542l1.502-3.479l1.592,1.667L528.244,201.542
              z M531.22,200.418l-1.412,2.99l-1.587-1.273L531.22,200.418z M529.667,197.619l-1.593-1.669l3.08-1.773L529.667,197.619z
              M529.56,197.867l-1.538,3.562l0.012-5.16L529.56,197.867z M529.699,203.64l-1.691,3.583l0.012-4.93L529.699,203.64z
              M528.022,208.139l0.097-0.057l1.721,1.347l-1.845,3.408l0.011-4.691C528.011,208.143,528.017,208.142,528.022,208.139z
              M528.349,207.947l3.356-1.965l-1.746,3.225L528.349,207.947z M528.161,207.481l1.738-3.68l1.919,1.54L528.161,207.481z
              M531.81,205.014l-1.801-1.445l1.589-3.367l0.032-0.018L531.81,205.014z M531.608,199.605c-0.013,0.001-0.025,0.004-0.038,0.007
              l-1.716-1.797l1.553-3.597L531.608,199.605z M527.814,182.309l3.516,10.981l-3.302,2.091L527.814,182.309z M527.926,242.079
              l0.009-3.774c0.034-0.005,0.067-0.015,0.096-0.034l2.456-1.578L527.926,242.079z M527.936,237.74l0.014-5.7l2.655,3.985
              L527.936,237.74z M530.942,235.808l-0.129,0.082l-2.673-4.013l4.486-2.758L530.942,235.808z M533.074,238.935l-1.878-2.698
              l1.736-1.116L533.074,238.935z M532.911,234.559c-0.021,0.007-0.043,0.012-0.062,0.024l-1.6,1.029l1.482-5.886L532.911,234.559z
              M532.682,228.421l-2.231-1.385l2.032-3.955L532.682,228.421z M532.457,222.374l-2.173-1.341l1.987-3.645L532.457,222.374z
              M532.245,216.698l-1.977-1.369l1.795-3.519L532.245,216.698z M532.096,199.343l-0.208-5.589l0.114-0.065l2.964,1.231
              l-2.769,4.366L532.096,199.343z M532.658,199.021l2.54-4.004l2.581,1.072L532.658,199.021z M538.064,195.927l-0.012,0.007
              l-2.719-1.13l2.73-4.304V195.927z M543.888,186.844l-2.632,4.292l-2.642-1.255L543.888,186.844z M538.562,190.131l2.564,1.218
              l-2.564,4.183V190.131z M538.908,195.444l2.444-3.986l2.47,1.174L538.908,195.444z M544.047,192.462l-2.564-1.218l2.564-4.183
              V192.462z M549.859,183.406l-2.619,4.272l-2.63-1.25L549.859,183.406z M544.545,186.673l2.564,1.218l-2.564,4.183V186.673z
              M544.859,192.037l2.476-4.038l2.502,1.189L544.859,192.037z M550.03,189.004l-2.564-1.218l2.564-4.182V189.004z
              M555.83,179.968l-2.607,4.252l-2.617-1.244L555.83,179.968z M550.528,183.215l2.564,1.218l-2.564,4.183V183.215z
              M550.811,188.631l2.508-4.09l2.534,1.204L550.811,188.631z M556.013,185.545l-2.564-1.218l2.564-4.183V185.545z M561.8,176.53
              l-2.594,4.231l-2.605-1.238L561.8,176.53z M556.511,179.756l2.564,1.218l-2.564,4.183V179.756z M556.762,185.224l2.54-4.142
              l2.567,1.22L556.762,185.224z M561.996,182.087l-2.564-1.218l2.564-4.182V182.087z M567.771,173.092l-2.677,4.367
              c-0.795-0.417-1.667-0.893-2.52-1.374L567.771,173.092z M562.494,176.326c0.668,0.378,1.571,0.879,2.466,1.35l-2.466,4.023
              V176.326z M562.713,181.818l2.468-4.026c0.985,0.513,1.936,0.972,2.524,1.169L562.713,181.818z M567.979,178.802
              c-0.412-0.105-1.429-0.586-2.665-1.228l2.665-4.347V178.802z M572.74,170.23c-0.485,0.848-1.263,2.213-2.045,3.615l-2.128-1.212
              L572.74,170.23z M568.477,172.868l2.097,1.194c-0.827,1.486-1.641,2.982-2.097,3.913V172.868z M572.792,176.049l-4.301,2.462
              c0.338-0.747,1.255-2.446,2.3-4.324l2.019,1.15L572.792,176.049z M572.817,175.053l-1.905-1.085
              c0.667-1.197,1.379-2.453,2.021-3.576L572.817,175.053z M576.261,168.203l-0.845,0.61l-0.052-0.094L576.261,168.203z
              M573.446,169.824l1.702-0.98l0.065,0.116l-1.778,1.284L573.446,169.824z M573.427,170.556l1.907-1.378l2.088,3.74l-3.987-2.349
              c-0.003-0.002-0.006-0.002-0.009-0.003L573.427,170.556z M577.338,173.447l-1.959,1.121l-1.851-3.365L577.338,173.447z
              M573.404,171.492l1.759,3.2l-1.487,0.851l-0.368-0.21L573.404,171.492z M573.301,175.615l0.124,0.071l-0.128,0.073
              L573.301,175.615z M573.301,176.38c-0.006-0.004-0.013-0.004-0.018-0.006l0.001-0.033l2.12-1.214l2.027,3.686L573.301,176.38z
              M577.49,178.405l-1.87-3.401l1.881-1.077L577.49,178.405z M577.504,172.553l-1.966-3.521l1.977-1.428L577.504,172.553z
              M582.89,205.999c-0.021,0.007-0.043,0.012-0.063,0.025l-1.6,1.028l1.482-5.886L582.89,205.999z M582.661,199.861l-2.231-1.385
              l2.032-3.956L582.661,199.861z M578.248,172.926l1.478-3.423l1.591,1.666L578.248,172.926z M581.227,171.801l-1.439,3.048
              l-1.59-1.275L581.227,171.801z M579.647,169.06l-1.599-1.674l3.196-2.024L579.647,169.06z M579.539,169.308l-1.539,3.562
              l0.012-5.161L579.539,169.308z M579.679,175.08l-1.691,3.583l0.012-4.931L579.679,175.08z M578.001,179.58l0.097-0.057
              l1.721,1.347l-1.845,3.408l0.011-4.692C577.99,179.584,577.996,179.582,578.001,179.58z M578.329,179.388l3.356-1.965
              l-1.746,3.225L578.329,179.388z M580.018,181.025l1.893,1.481l-3.938,2.399l0-0.102L580.018,181.025z M577.966,196.688
              l2.178-3.996l2.162,1.335c-0.029,0.006-0.058,0.015-0.084,0.031L577.966,196.688z M582.072,194.736l-1.854,3.608l-1.988-1.234
              L582.072,194.736z M580.051,192.342l-1.82-1.124l3.668-2.267L580.051,192.342z M578.298,190.592l1.836-3.599l1.881,1.303
              L578.298,190.592z M580.041,186.626l-1.843-1.276l3.617-2.203L580.041,186.626z M579.927,186.85l-1.968,3.859l0.012-5.214
              L579.927,186.85z M577.992,191.364l1.94,1.197l-1.986,3.644l0.011-4.826C577.969,191.375,577.981,191.37,577.992,191.364z
              M577.991,197.255l2.113,1.312l-2.174,4.231l0.013-5.521C577.96,197.271,577.975,197.263,577.991,197.255z M580.316,198.698
              l2.174,1.349l-4.19,2.576L580.316,198.698z M582.436,193.814l-2.173-1.341l1.987-3.645L582.436,193.814z M582.224,188.138
              l-1.977-1.369l1.795-3.519L582.224,188.138z M582.005,182.264l-1.867-1.461l1.696-3.133L582.005,182.264z M578.141,178.921
              l1.738-3.681l1.92,1.54L578.141,178.921z M581.607,171.578l0.182,4.876l-1.801-1.445L581.607,171.578L581.607,171.578z
              M581.587,171.045c-0.004,0-0.008-0.001-0.013-0.001c-0.009,0.001-0.017,0.006-0.026,0.008l-1.716-1.797l1.553-3.597
              L581.587,171.045z M577.793,153.75l3.514,10.973l-3.303,1.902L577.793,153.75z M577.905,213.52l0.009-3.775
              c0.034-0.005,0.067-0.015,0.096-0.034l2.456-1.578L577.905,213.52z M577.915,209.18l0.013-5.7l2.655,3.986L577.915,209.18z
              M580.922,207.248l-0.129,0.083l-2.673-4.013l4.486-2.757L580.922,207.248z M581.175,207.677l1.736-1.116l0.142,3.814
              L581.175,207.677z"/>
          </g>
        </g>
        <g>
          <path class="st42" d="M568.952,205.257c-2.326-0.445-5.56-4.775-7.76-22.883l-0.407,0.05c1.791,14.736,4.513,22.554,8.091,23.237
            c0.173,0.033,0.342,0.048,0.51,0.048c2.415,0,4.201-3.229,4.281-3.375l-0.36-0.196
            C573.286,202.173,571.334,205.717,568.952,205.257z"/>
        </g>
        <g>
          <path class="st42" d="M584.965,215.787c-3.491-0.324-7.018-5.077-10.2-13.745l-0.385,0.141c3.288,8.956,6.837,13.67,10.55,14.013
            c0.146,0.014,0.29,0.02,0.431,0.02c3.273,0,5.361-3.451,5.452-3.604l-0.352-0.209
            C590.438,212.439,588.215,216.089,584.965,215.787z"/>
        </g>
        <g>
          <g>
            <polygon class="st45" points="548.319,244.835 551.067,246.421 553.815,244.835 551.067,243.248 				"/>
            <polygon class="st46" points="551.067,247.509 553.815,245.982 553.815,244.835 551.067,246.421 				"/>
            <polygon class="st47" points="548.319,246.016 551.067,247.509 551.067,246.421 548.319,244.835 				"/>
            <path class="st48" d="M552.559,232.541l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.204,0.331l-0.055,0.033c-0.156,0.092-0.248,0.259-0.247,0.449v11.866h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.211,0.775,0.323,1.189,0.323
              c0.415,0,0.826-0.112,1.189-0.323l0.066-0.039c0.156-0.091,0.249-0.258,0.249-0.447l0-0.009l0-0.002V232.57h-0.002
              c0-0.007,0.002-0.013,0.002-0.019L552.559,232.541z"/>
            <path class="st49" d="M552.198,232.01c-0.339-0.197-0.726-0.301-1.119-0.301c-0.379,0-0.755,0.098-1.086,0.282l-0.05,0.028
              c-0.14,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.021c0.328,0.18,0.699,0.275,1.074,0.275
              c0.374,0,0.745-0.095,1.074-0.276l0.06-0.033c0.141-0.077,0.225-0.22,0.225-0.381l0-0.008l-0.001-0.01
              C552.408,232.217,552.33,232.087,552.198,232.01z"/>
            <path class="st48" d="M552.198,230.779l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.069-0.188,0.196-0.187,0.339v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.001V230.8h-0.002
              c0-0.005,0.002-0.009,0.002-0.015L552.198,230.779z"/>
            <path class="st49" d="M551.924,230.377c-0.257-0.149-0.55-0.228-0.848-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C552.083,230.533,552.024,230.435,551.924,230.377z"/>
            <path class="st50" d="M552.198,219.238l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.196-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.001V219.26h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L552.198,219.238z"/>
            <path class="st51" d="M551.924,218.836c-0.257-0.149-0.55-0.228-0.848-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.168-0.169,0.29c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C552.083,218.992,552.024,218.894,551.924,218.836z"/>
            <path class="st48" d="M552.198,217.69l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.196-0.187,0.34v0.707h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.339l0-0.007l0-0.002v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L552.198,217.69z"/>
            <path class="st49" d="M551.924,217.287c-0.257-0.149-0.55-0.228-0.848-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.168-0.169,0.29c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C552.083,217.444,552.024,217.345,551.924,217.287z"/>
            <path class="st48" d="M552.198,215.014l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002v-1.921h-0.002
              c0-0.005,0.002-0.009,0.002-0.015L552.198,215.014z"/>
            <path class="st49" d="M551.924,214.612c-0.257-0.149-0.55-0.228-0.848-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.058,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C552.083,214.768,552.024,214.669,551.924,214.612z"/>
          </g>
          <g>
            <polygon class="st45" points="559.851,237.881 562.599,239.468 565.347,237.881 562.599,236.294 				"/>
            <polygon class="st46" points="562.599,240.555 565.347,239.028 565.347,237.881 562.599,239.468 				"/>
            <polygon class="st47" points="559.851,239.062 562.599,240.555 562.599,239.468 559.851,237.881 				"/>
            <path class="st48" d="M564.091,225.587l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.203,0.331l-0.055,0.033c-0.156,0.092-0.248,0.259-0.247,0.448v11.867h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.211,0.774,0.323,1.189,0.323
              c0.414,0,0.826-0.112,1.189-0.323l0.067-0.039c0.156-0.091,0.249-0.258,0.249-0.447l0-0.009l0-0.002v-11.855h-0.002
              c0-0.007,0.002-0.012,0.002-0.019L564.091,225.587z"/>
            <path class="st49" d="M563.73,225.056c-0.339-0.197-0.726-0.301-1.119-0.301c-0.379,0-0.755,0.098-1.087,0.283l-0.05,0.028
              c-0.14,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.02c0.328,0.18,0.699,0.276,1.073,0.276
              s0.745-0.095,1.074-0.276l0.06-0.033c0.141-0.077,0.225-0.22,0.225-0.381l0-0.008l0-0.01
              C563.94,225.263,563.862,225.133,563.73,225.056z"/>
            <path class="st48" d="M563.73,223.825l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.339v-0.007v-0.002v-0.903h-0.002
              c0-0.005,0.002-0.009,0.002-0.015V223.825z"/>
            <path class="st49" d="M563.456,223.423c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.248,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.058,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C563.615,223.579,563.556,223.481,563.456,223.423z"/>
            <path class="st50" d="M563.73,212.284l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-10.897h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V212.284z"/>
            <path class="st51" d="M563.456,211.882c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.017,0.201,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.167,0.17-0.288l0-0.006l0-0.007
              C563.615,212.039,563.556,211.94,563.456,211.882z"/>
            <path class="st48" d="M563.73,210.736l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.069-0.188,0.197-0.187,0.34v0.708h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.001v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V210.736z"/>
            <path class="st49" d="M563.456,210.334c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.248,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.167,0.17-0.289l0-0.006l0-0.008
              C563.615,210.49,563.556,210.392,563.456,210.334z"/>
            <path class="st48" d="M563.73,208.06l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-1.92h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V208.06z"/>
            <path class="st49" d="M563.456,207.658c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C563.615,207.814,563.556,207.715,563.456,207.658z"/>
          </g>
          <g>
            <polygon class="st45" points="571.515,230.948 574.263,232.535 577.011,230.948 574.263,229.362 				"/>
            <polygon class="st46" points="574.263,233.622 577.011,232.095 577.011,230.948 574.263,232.535 				"/>
            <polygon class="st47" points="571.515,232.129 574.263,233.622 574.263,232.535 571.515,230.948 				"/>
            <path class="st48" d="M575.755,218.655l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.204,0.331l-0.055,0.033c-0.155,0.092-0.248,0.259-0.247,0.448v11.867h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.211,0.774,0.323,1.189,0.323
              c0.415,0,0.826-0.112,1.189-0.323l0.067-0.039c0.156-0.091,0.249-0.258,0.249-0.447l0-0.009l0-0.002v-11.855h-0.002
              c0-0.007,0.002-0.013,0.002-0.019L575.755,218.655z"/>
            <path class="st49" d="M575.393,218.124c-0.339-0.197-0.726-0.301-1.119-0.301c-0.379,0-0.755,0.098-1.086,0.282l-0.05,0.028
              c-0.141,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.021c0.328,0.18,0.699,0.275,1.073,0.275
              c0.374,0,0.746-0.095,1.074-0.276l0.06-0.033c0.141-0.077,0.225-0.219,0.225-0.381l0-0.008l-0.001-0.01
              C575.604,218.33,575.525,218.2,575.393,218.124z"/>
            <path class="st48" d="M575.393,216.892l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.938-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              s0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002v-0.903h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L575.393,216.892z"/>
            <path class="st49" d="M575.119,216.49c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.169,0.167-0.169,0.289c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.249,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.007
              C575.279,216.646,575.219,216.548,575.119,216.49z"/>
            <path class="st50" d="M575.393,205.352l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.938-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.196-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              s0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002v-10.897h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L575.393,205.352z"/>
            <path class="st51" d="M575.119,204.949c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.169,0.167-0.169,0.289c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.249,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C575.279,205.106,575.219,205.007,575.119,204.949z"/>
            <path class="st48" d="M575.393,203.803l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.938-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.707h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.237,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              s0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.339l0-0.007l0-0.001v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L575.393,203.803z"/>
            <path class="st49" d="M575.119,203.401c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.169,0.167-0.169,0.289c0,0.058,0.017,0.202,0.17,0.285l0.029,0.016c0.249,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C575.279,203.557,575.219,203.459,575.119,203.401z"/>
            <path class="st48" d="M575.393,201.127l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.938-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              s0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002v-1.92h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L575.393,201.127z"/>
            <path class="st49" d="M575.119,200.725c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.169,0.167-0.169,0.29c0,0.058,0.017,0.201,0.17,0.285l0.029,0.016c0.249,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C575.279,200.881,575.219,200.783,575.119,200.725z"/>
          </g>
        </g>
        <g>
          <path class="st42" d="M561.6,231.372c-3.284-0.168-6.474-4.41-9.483-12.606l-0.385,0.141c3.071,8.366,6.384,12.698,9.847,12.875
            c0.075,0.004,0.148,0.005,0.222,0.005c3.779,0,6.24-4.949,6.345-5.164l-0.369-0.18
            C567.752,226.495,565.237,231.555,561.6,231.372z"/>
        </g>
        <g>
          <path class="st42" d="M572.952,224.542c-3.264-0.157-6.425-4.343-9.398-12.441l-0.385,0.141c3.035,8.268,6.32,12.544,9.764,12.71
            c0.07,0.004,0.139,0.005,0.208,0.005c3.876,0,6.42-5.201,6.528-5.426l-0.369-0.178
            C579.273,219.406,576.679,224.711,572.952,224.542z"/>
        </g>
        <g>
          <g>
            <polygon class="st45" points="565.204,254.309 567.952,255.895 570.7,254.309 567.952,252.722 				"/>
            <polygon class="st46" points="567.952,256.982 570.7,255.456 570.7,254.309 567.952,255.895 				"/>
            <polygon class="st47" points="565.204,255.49 567.952,256.982 567.952,255.895 565.204,254.309 				"/>
            <path class="st48" d="M569.444,242.015l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.203,0.331l-0.055,0.033c-0.156,0.092-0.248,0.259-0.247,0.449v11.866h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0.001,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.212,0.774,0.323,1.189,0.323
              s0.826-0.112,1.189-0.323l0.067-0.039c0.156-0.091,0.249-0.258,0.249-0.447l0-0.009l0-0.002v-11.855h-0.002
              c0-0.007,0.002-0.013,0.002-0.019L569.444,242.015z"/>
            <path class="st49" d="M569.083,241.484c-0.339-0.197-0.726-0.301-1.119-0.301c-0.379,0-0.755,0.098-1.086,0.282l-0.05,0.028
              c-0.14,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.021c0.328,0.18,0.699,0.275,1.074,0.275
              c0.374,0,0.745-0.095,1.074-0.275l0.06-0.033c0.141-0.077,0.225-0.22,0.225-0.381l0-0.008l0-0.01
              C569.293,241.69,569.215,241.56,569.083,241.484z"/>
            <path class="st48" d="M569.083,240.253l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.069-0.188,0.196-0.187,0.339v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.001v-0.903h-0.002
              c0-0.005,0.002-0.009,0.002-0.015V240.253z"/>
            <path class="st49" d="M568.809,239.85c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.249,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.814-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.289l0-0.006l0-0.008
              C568.968,240.007,568.909,239.908,568.809,239.85z"/>
            <path class="st50" d="M569.083,228.712l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.196-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.001v-10.897h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V228.712z"/>
            <path class="st51" d="M568.809,228.31c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.249,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.814-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C568.968,228.466,568.909,228.368,568.809,228.31z"/>
            <path class="st48" d="M569.083,227.164l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.196-0.187,0.34v0.707h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.339v-0.007v-0.001v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V227.164z"/>
            <path class="st49" d="M568.809,226.761c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.249,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.814-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C568.968,226.918,568.909,226.819,568.809,226.761z"/>
            <path class="st48" d="M569.083,224.488l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-1.92h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V224.488z"/>
            <path class="st49" d="M568.809,224.085c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.249,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.814-0.209l0.046-0.025c0.107-0.058,0.17-0.166,0.17-0.288l0-0.006l0-0.008
              C568.968,224.242,568.909,224.143,568.809,224.085z"/>
          </g>
          <g>
            <polygon class="st45" points="576.736,247.355 579.484,248.941 582.232,247.355 579.484,245.768 				"/>
            <polygon class="st46" points="579.484,250.029 582.232,248.502 582.232,247.355 579.484,248.941 				"/>
            <polygon class="st47" points="576.736,248.536 579.484,250.029 579.484,248.941 576.736,247.355 				"/>
            <path class="st48" d="M580.976,235.061l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.203,0.331l-0.055,0.033c-0.156,0.092-0.248,0.259-0.247,0.448v11.867h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.211,0.774,0.323,1.189,0.323
              c0.415,0,0.826-0.112,1.189-0.323l0.067-0.039c0.156-0.091,0.249-0.258,0.249-0.447l0-0.009l0-0.002v-11.855h-0.002
              c0-0.007,0.002-0.012,0.002-0.019L580.976,235.061z"/>
            <path class="st49" d="M580.615,234.53c-0.339-0.197-0.726-0.301-1.118-0.301c-0.379,0-0.755,0.098-1.087,0.283l-0.05,0.028
              c-0.14,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.021c0.328,0.18,0.699,0.275,1.074,0.275
              c0.374,0,0.745-0.095,1.074-0.275l0.06-0.033c0.141-0.077,0.225-0.22,0.225-0.381l0-0.008l-0.001-0.01
              C580.825,234.737,580.747,234.606,580.615,234.53z"/>
            <path class="st48" d="M580.615,233.299l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.339l0-0.007l0-0.002v-0.903h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L580.615,233.299z"/>
            <path class="st49" d="M580.341,232.897c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.045-0.025c0.107-0.058,0.171-0.166,0.171-0.288l0-0.006l0-0.008
              C580.5,233.053,580.441,232.954,580.341,232.897z"/>
            <path class="st50" d="M580.615,221.758l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.069-0.188,0.197-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002V221.78h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L580.615,221.758z"/>
            <path class="st51" d="M580.341,221.356c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.045-0.025c0.107-0.059,0.171-0.166,0.171-0.288l0-0.006l0-0.007
              C580.5,221.512,580.441,221.414,580.341,221.356z"/>
            <path class="st48" d="M580.615,220.21l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.708h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.001v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L580.615,220.21z"/>
            <path class="st49" d="M580.341,219.808c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.136,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.045-0.025c0.107-0.058,0.171-0.166,0.171-0.289l0-0.006l0-0.008
              C580.5,219.964,580.441,219.865,580.341,219.808z"/>
            <path class="st48" d="M580.615,217.534l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.05-0.029c0.118-0.069,0.189-0.195,0.189-0.338l0-0.007l0-0.002v-1.92h-0.002
              c0-0.005,0.002-0.01,0.002-0.015L580.615,217.534z"/>
            <path class="st49" d="M580.341,217.131c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.045-0.025c0.107-0.059,0.171-0.166,0.171-0.288l0-0.006l0-0.008
              C580.5,217.288,580.441,217.189,580.341,217.131z"/>
          </g>
          <g>
            <polygon class="st45" points="588.4,240.422 591.148,242.009 593.896,240.422 591.148,238.835 				"/>
            <polygon class="st46" points="591.148,243.096 593.896,241.569 593.896,240.422 591.148,242.009 				"/>
            <polygon class="st47" points="588.4,241.603 591.148,243.096 591.148,242.009 588.4,240.422 				"/>
            <path class="st48" d="M592.64,228.128l-0.001-0.012c-0.006-0.177-0.093-0.33-0.239-0.419c-0.376-0.231-0.804-0.353-1.239-0.353
              c-0.42,0-0.836,0.115-1.203,0.331l-0.055,0.033c-0.156,0.092-0.248,0.259-0.247,0.448v11.867h0.002
              c0,0.007-0.002,0.013-0.002,0.019c0,0.09,0.026,0.312,0.248,0.442l0.042,0.024c0.363,0.212,0.775,0.323,1.189,0.323
              c0.415,0,0.826-0.112,1.189-0.323l0.067-0.039c0.156-0.091,0.249-0.258,0.249-0.447v-0.009v-0.002v-11.855h-0.003
              c0-0.007,0.003-0.013,0.003-0.019V228.128z"/>
            <path class="st49" d="M592.278,227.597c-0.339-0.197-0.726-0.301-1.118-0.301c-0.379,0-0.755,0.098-1.086,0.283l-0.05,0.028
              c-0.141,0.078-0.224,0.221-0.223,0.382c0,0.076,0.023,0.266,0.224,0.377l0.038,0.021c0.328,0.18,0.699,0.275,1.074,0.275
              c0.374,0,0.745-0.095,1.074-0.276l0.06-0.033c0.141-0.077,0.225-0.22,0.225-0.381l0-0.008l0-0.01
              C592.489,227.804,592.41,227.674,592.278,227.597z"/>
            <path class="st48" d="M592.278,226.366l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.911h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-0.903h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V226.366z"/>
            <path class="st49" d="M592.004,225.964c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.058,0.17-0.166,0.17-0.288v-0.006l0-0.007
              C592.164,226.12,592.104,226.022,592.004,225.964z"/>
            <path class="st50" d="M592.278,214.825l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.069-0.188,0.197-0.187,0.34v10.906h0.002
              c0,0.005-0.002,0.009-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-10.897h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V214.825z"/>
            <path class="st51" d="M592.004,214.423c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.288v-0.006l0-0.008
              C592.164,214.58,592.104,214.481,592.004,214.423z"/>
            <path class="st48" d="M592.278,213.277l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.318c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v0.707h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.237,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.001v-0.699h-0.002
              c0-0.005,0.002-0.01,0.002-0.015V213.277z"/>
            <path class="st49" d="M592.004,212.875c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.289c0,0.058,0.018,0.202,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.166,0.17-0.289v-0.006l0-0.008
              C592.164,213.031,592.104,212.932,592.004,212.875z"/>
            <path class="st48" d="M592.278,210.601l0-0.009c-0.005-0.134-0.071-0.25-0.181-0.317c-0.285-0.175-0.609-0.268-0.939-0.268
              c-0.318,0-0.634,0.087-0.912,0.251l-0.042,0.025c-0.118,0.07-0.188,0.197-0.187,0.34v1.929h0.002
              c0,0.005-0.002,0.01-0.002,0.015c0,0.068,0.019,0.236,0.188,0.335l0.032,0.018c0.275,0.16,0.587,0.245,0.901,0.245
              c0.314,0,0.626-0.085,0.901-0.245l0.051-0.029c0.118-0.069,0.189-0.195,0.189-0.338v-0.007v-0.002v-1.921h-0.002
              c0-0.005,0.002-0.009,0.002-0.015V210.601z"/>
            <path class="st49" d="M592.004,210.199c-0.257-0.149-0.55-0.228-0.847-0.228c-0.287,0-0.572,0.074-0.823,0.214l-0.038,0.021
              c-0.106,0.059-0.17,0.167-0.169,0.29c0,0.058,0.018,0.201,0.17,0.285l0.029,0.016c0.248,0.137,0.53,0.209,0.813,0.209
              c0.284,0,0.565-0.072,0.813-0.209l0.046-0.025c0.107-0.059,0.17-0.167,0.17-0.288v-0.006l0-0.008
              C592.164,210.355,592.104,210.256,592.004,210.199z"/>
          </g>
        </g>
      </g>
      <g>
        <path class="st42" d="M658.953,241.606c-8.374,0-16.718-1.122-24.968-3.366c-25.922-7.05-42.117-22.967-42.278-23.127l0.491-0.493
          c0.159,0.159,16.246,15.961,42,22.957c15.115,4.106,30.557,4.421,45.898,0.934c19.186-4.36,38.274-14.712,56.731-30.768
          l0.456,0.525c-18.55,16.137-37.746,26.542-57.053,30.925C673.145,240.802,666.038,241.606,658.953,241.606z"/>
      </g>
      <g>
        <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="874.8353" y1="658.467" x2="921.1207" y2="658.467">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.794" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st52" points="874.835,676.855 921.121,676.209 917.942,640.079 883.195,640.725 		"/>
        <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="812.7906" y1="699.7601" x2="892.5582" y2="699.7601">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.794" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st53" points="812.791,719.606 873.059,719.606 892.558,679.914 843.828,679.914 		"/>
        <g>
          <g>
            <path class="st54" d="M823.078,619.03l20.864,11.586c0,0,10.443,6.017,21.634-0.445c11.192-6.461,20.299-11.482,20.299-11.482
              v-96.575l-62.798,0.119V619.03z"/>
            <g>
              <path class="st55" d="M854.76,549.418c-6.222,0-10.834-2.621-11.027-2.733l-18.468-10.662v79.538l18.882,11.424
                c2.197,1.247,5.094,1.88,8.61,1.88c5.778,0,11.354-1.734,11.878-1.901l18.012-10.389v-80.517
                c-2.08,1.313-9.001,5.643-17.633,10.627C861.874,548.499,858.424,549.418,854.76,549.418z"/>
              <path class="st56" d="M854.76,549.001c-6.181,0-10.818-2.677-10.818-2.677L824.849,535.3v80.496l19.093,11.552
                c2.556,1.451,5.725,1.934,8.816,1.934c6.182,0,12.048-1.934,12.048-1.934l18.259-10.532V535.3c0,0-7.827,5-18.259,11.023
                C861.328,548.332,857.852,549.001,854.76,549.001z M882.23,536.815v79.52l-17.764,10.246c-0.71,0.224-6.109,1.867-11.708,1.867
                c-3.438,0-6.262-0.612-8.395-1.82l-18.68-11.302v-78.58l17.841,10.301c0.197,0.114,4.903,2.789,11.236,2.789
                c3.738,0,7.258-0.938,10.463-2.789C873.12,542.487,879.587,538.474,882.23,536.815z"/>
            </g>
            <g>
              <path class="st56" d="M864.597,560.011c-10.093,5.827-20.344,0.059-20.447,0l-19.094-11.024l-0.417,0.723l19.094,11.024
                c0.071,0.041,4.807,2.713,11.065,2.713c3.153,0,6.692-0.678,10.217-2.713c10.321-5.959,17.777-10.717,17.855-10.767l-0.03-0.97
                C882.762,549.047,874.901,554.062,864.597,560.011z"/>
            </g>
            <g>
              <path class="st56" d="M864.597,574.059c-10.093,5.828-20.344,0.059-20.447,0l-19.094-11.023l-0.417,0.723l19.094,11.023
                c0.071,0.041,4.807,2.713,11.065,2.713c3.153,0,6.692-0.678,10.217-2.713c10.321-5.959,18.196-10.983,18.274-11.033
                l-0.449-0.703C882.762,563.095,874.901,568.11,864.597,574.059z"/>
            </g>
            <g>
              <path class="st56" d="M864.597,587.272c-10.093,5.828-20.344,0.059-20.447,0l-19.094-11.023l-0.417,0.723l19.094,11.023
                c0.071,0.041,4.807,2.713,11.065,2.713c3.153,0,6.692-0.678,10.217-2.713c10.321-5.959,18.196-10.983,18.274-11.033
                l-0.449-0.703C882.762,576.309,874.901,581.323,864.597,587.272z"/>
            </g>
            <g>
              <path class="st56" d="M864.597,600.903c-10.093,5.827-20.344,0.059-20.447,0l-19.094-11.023l-0.417,0.723l19.094,11.023
                c0.071,0.041,4.807,2.713,11.065,2.713c3.153,0,6.692-0.678,10.217-2.713c10.321-5.959,18.196-10.983,18.274-11.033
                l-0.449-0.703C882.762,589.94,874.901,594.954,864.597,600.903z"/>
            </g>
            <g>
              <path class="st56" d="M864.597,614.117c-10.093,5.828-20.344,0.059-20.447,0l-19.094-11.024l-0.417,0.723l19.094,11.024
                c0.071,0.041,4.807,2.713,11.065,2.713c3.153,0,6.692-0.678,10.217-2.713c10.321-5.959,18.196-10.983,18.274-11.033
                l-0.449-0.703C882.762,603.153,874.902,608.168,864.597,614.117z"/>
            </g>
            <g>
              <polygon class="st56" points="831.539,619.357 832.373,620.131 832.373,540.127 831.539,540.127 					"/>
            </g>
            <g>
              <polygon class="st56" points="837.893,623.201 838.727,624.299 838.727,544.295 837.893,543.313 					"/>
            </g>
            <g>
              <polygon class="st56" points="845.472,628.159 846.306,628.159 846.306,548.155 845.472,547.668 					"/>
            </g>
            <g>
              <rect x="854.137" y="549.406" class="st56" width="0.835" height="78.752"/>
            </g>
            <g>
              <rect x="864.389" y="546.781" class="st56" width="0.835" height="80.004"/>
            </g>
            <g>
              <rect x="871.343" y="542.707" class="st56" width="0.835" height="80.004"/>
            </g>
            <g>
              <rect x="876.493" y="539.301" class="st56" width="0.835" height="80.004"/>
            </g>
            <path class="st57" d="M864.806,630.616c11.192-6.462,21.07-11.927,21.07-11.927v-96.575l-21.106,11.746L864.806,630.616z"/>
            <path class="st57" d="M843.664,630.461l0.278,0.154c0,0,10.443,6.017,21.634-0.445c11.192-6.461,20.299-11.482,20.299-11.482
              v-96.575l-42.212,0.08V630.461z"/>
            <path class="st58" d="M823.078,522.232l17.905,10.338c13.924,8.039,27.958-0.679,27.958-0.679l16.935-9.777l-31.631-17.875
              L823.078,522.232z"/>
          </g>
          <g>
            <polygon class="st55" points="848.131,516.233 831.027,506.082 831.027,517.645 848.36,527.879 860.352,527.879 878.7,518.044 
              878.7,506.552 861.08,516.233 				"/>
            <path class="st56" d="M848.246,515.815l-17.635-10.467v12.535l17.635,10.413h12.211l18.661-10.002v-12.447l-18.145,9.969
              H848.246z M861.374,516.547l16.908-9.289v10.537l-18.036,9.667h-11.773l-17.029-10.055v-10.592l16.375,9.719l0.197,0.117h0.229
              h12.727h0.214L861.374,516.547z"/>
          </g>
          <polygon class="st58" points="830.61,505.349 848.246,515.815 860.973,515.815 879.117,505.847 853.957,491.802 			"/>
          <g>
            <polygon class="st56" points="847.828,528.05 848.663,528.296 848.663,515.815 847.828,515.815 				"/>
          </g>
          <g>
            <polygon class="st56" points="860.556,528.296 861.39,527.796 861.39,515.815 860.556,515.815 				"/>
          </g>
          <g>
            <polygon class="st56" points="868.958,523.516 869.793,523.007 869.793,511.709 868.958,511.709 				"/>
          </g>
          <g>
            <polygon class="st56" points="838.335,522.445 839.17,522.726 839.17,510.919 838.335,510.919 				"/>
          </g>
          <polygon class="st57" points="879.117,505.847 860.973,515.815 848.663,515.815 848.663,528.296 875.167,528.296 
            885.876,522.113 879.117,518.294 			"/>
          <polygon class="st57" points="879.117,505.847 860.973,515.815 860.352,515.815 860.352,528.296 875.167,528.296 
            885.876,522.113 879.117,518.294 			"/>
        </g>
        <g>
          <polygon class="st60" points="778.956,628.43 807.683,645.066 807.683,567.091 778.789,550.249 			"/>
          <path class="st60" d="M781.063,518.097l-2.275-1.155v33.307l28.894,16.842V555.85
            C793.161,546.047,784.323,525.303,781.063,518.097z"/>
          <path class="st59" d="M780.335,520.244v29.116l25.802,15.04v-7.74C792.561,547.082,784.108,528.637,780.335,520.244z"/>
          <path class="st61" d="M800.14,506.801l-1.924-1.074l-0.064,33.307l28.982,16.816v-11.155
            C812.611,534.892,803.399,514.006,800.14,506.801z"/>
          <polygon class="st62" points="778.789,516.942 781.063,518.097 800.14,506.801 798.215,505.727 			"/>
          <path class="st61" d="M800.14,506.801l-19.076,11.296c12.121,29.308,26.619,37.753,26.619,37.753l19.45-11.155
            C812.852,536.45,800.14,506.801,800.14,506.801z"/>
          <polygon class="st61" points="807.683,567.091 827.133,555.85 827.133,544.695 807.683,555.85 			"/>
          <polygon class="st61" points="807.683,645.066 827.133,633.935 827.133,555.85 807.683,567.091 			"/>
          <polygon class="st59" points="785.574,632.236 799.31,640.19 799.31,629.002 785.574,621.071 			"/>
          <polygon class="st59" points="780.014,616.037 806.457,631.303 806.457,621.921 780.014,606.655 			"/>
          <polygon class="st59" points="780.014,604.831 806.457,620.097 806.457,610.715 780.014,595.448 			"/>
          <polygon class="st59" points="780.014,593.624 806.457,608.891 806.457,599.509 780.014,584.242 			"/>
          <polygon class="st59" points="780.014,582.418 806.457,597.685 806.457,588.302 780.014,573.036 			"/>
          <polygon class="st59" points="780.014,571.212 806.457,586.478 806.457,577.096 780.014,561.83 			"/>
          <polygon class="st59" points="780.014,560.006 806.457,575.272 806.457,565.89 780.014,550.623 			"/>
          <polygon class="st63" points="809.1,631.303 825.716,621.846 825.716,612.443 809.1,621.846 			"/>
          <polygon class="st63" points="809.1,620.097 825.716,610.64 825.716,601.237 809.1,610.64 			"/>
          <polygon class="st63" points="809.1,608.891 825.716,599.434 825.716,590.031 809.1,599.434 			"/>
          <polygon class="st63" points="809.1,597.685 825.716,588.228 825.716,578.825 809.1,588.228 			"/>
          <polygon class="st63" points="809.1,586.478 825.716,577.021 825.716,567.619 809.1,577.021 			"/>
          <polygon class="st63" points="809.1,575.272 825.716,565.815 825.716,556.413 809.1,565.815 			"/>
          <g>
            <g>
              <rect x="784.691" y="529.765" class="st56" width="0.386" height="89.084"/>
            </g>
            <g>
              <polygon class="st56" points="790.058,634.86 790.444,635.083 790.444,539.099 790.058,539.099 					"/>
            </g>
            <g>
              <polygon class="st56" points="795.424,637.94 795.811,638.164 795.811,546.563 795.424,546.563 					"/>
            </g>
            <g>
              <rect x="800.791" y="552.406" class="st56" width="0.386" height="76.009"/>
            </g>
          </g>
          <g>
            <path class="st56" d="M789.311,536.869c-4.105-6.732-7.045-13.27-8.624-16.783c-0.088-0.194-0.317-0.282-0.511-0.194
              c-0.195,0.088-0.282,0.316-0.194,0.511c1.586,3.528,4.539,10.094,8.669,16.868c5.344,8.764,11.152,15.394,17.263,19.706
              c0.068,0.048,0.145,0.07,0.222,0.07c0.122,0,0.241-0.057,0.316-0.164c0.123-0.174,0.081-0.415-0.093-0.539
              C800.336,552.095,794.6,545.542,789.311,536.869z"/>
          </g>
          <g>
            <path class="st56" d="M806.331,564.067l-25.802-15.04c-0.185-0.106-0.421-0.044-0.529,0.139
              c-0.107,0.185-0.045,0.421,0.139,0.529l25.802,15.04c0.062,0.035,0.128,0.052,0.194,0.052c0.133,0,0.262-0.069,0.334-0.192
              C806.578,564.411,806.516,564.174,806.331,564.067z"/>
          </g>
          <g>
            <polygon class="st56" points="812.449,629.397 812.835,629.177 812.835,563.702 812.449,563.92 				"/>
            <polygon class="st56" points="815.733,627.528 816.12,627.308 816.12,561.843 815.733,562.061 				"/>
            <polygon class="st56" points="819.018,625.658 819.404,625.439 819.404,559.984 819.018,560.203 				"/>
            <polygon class="st56" points="822.302,623.789 822.689,623.569 822.689,558.125 822.302,558.344 				"/>
          </g>
          <polygon class="st25" points="807.683,645.066 827.133,633.935 827.133,544.695 807.683,555.85 			"/>
        </g>
        <g>
          <polygon class="st64" points="847.659,604.525 875.35,588.315 875.35,579.732 847.659,595.914 			"/>
          <polygon class="st64" points="847.659,620.474 875.35,604.264 875.35,595.681 847.659,611.863 			"/>
          <polygon class="st64" points="847.659,636.423 875.35,620.213 875.35,611.629 847.659,627.812 			"/>
          <polygon class="st64" points="847.659,652.372 875.35,636.162 875.35,627.578 847.659,643.761 			"/>
          <polygon class="st65" points="850.995,663.293 874.835,677.057 875.464,594.834 850.995,580.707 			"/>
          <g>
            <polygon class="st66" points="852.805,654.153 872.213,665.399 872.213,601.912 852.805,590.978 				"/>
            <path class="st56" d="M852.447,654.36l20.123,11.66v-64.316l-20.123-11.338V654.36z M853.162,591.59l18.693,10.532v62.656
              l-18.693-10.831V591.59z"/>
          </g>
          <g>
            <rect x="857.39" y="593.746" class="st56" width="0.715" height="63.264"/>
          </g>
          <g>
            <rect x="866.253" y="598.703" class="st56" width="0.715" height="63.264"/>
          </g>
          <polygon class="st68" points="874.835,677.057 899.988,662.535 899.989,580.707 875.464,594.834 			"/>
          <polygon class="st64" points="872.213,618.539 899.989,602.329 899.989,593.746 872.213,609.928 			"/>
          <polygon class="st64" points="872.213,634.488 899.989,618.278 899.989,609.695 872.213,625.877 			"/>
          <polygon class="st64" points="872.213,650.437 899.989,634.227 899.989,625.643 872.213,641.826 			"/>
          <polygon class="st64" points="872.213,666.386 899.989,650.176 899.989,641.592 872.213,657.775 			"/>
          <polygon class="st69" points="847.659,604.646 872.213,618.411 872.213,609.928 847.659,595.806 			"/>
          <polygon class="st69" points="847.659,652.622 872.213,666.386 872.213,657.904 847.659,643.781 			"/>
          <polygon class="st69" points="847.659,636.63 872.213,650.394 872.213,641.912 847.659,627.79 			"/>
          <polygon class="st69" points="847.659,620.638 872.213,634.402 872.213,625.92 847.659,611.798 			"/>
          <polygon class="st70" points="850.995,580.707 875.464,594.834 899.989,580.707 875.491,566.564 			"/>
          <g>
            <polygon class="st71" points="850.995,578.991 875.464,592.756 875.464,590.485 850.995,576.358 				"/>
            <polygon class="st72" points="875.464,592.756 899.989,578.991 899.989,576.358 875.464,590.485 				"/>
            <polygon class="st73" points="850.995,576.358 875.464,590.485 899.989,576.358 875.491,562.215 				"/>
          </g>
          <g>
            <polygon class="st55" points="877.714,664.955 898.557,652.685 898.557,589.51 877.714,601.468 				"/>
            <path class="st56" d="M877.356,665.58l21.558-12.69v-63.998l-21.558,12.369V665.58z M878.071,601.676l20.128-11.549v62.354
              l-20.128,11.848V601.676z"/>
          </g>
          <g>
            <rect x="880.742" y="599.526" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <rect x="883.696" y="597.797" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <rect x="886.65" y="596.067" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <rect x="889.604" y="594.339" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <rect x="892.558" y="592.61" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <rect x="895.512" y="590.88" class="st56" width="0.715" height="63.436"/>
          </g>
          <g>
            <line class="st67" x1="877.714" y1="611.798" x2="898.557" y2="599.828"/>
            
              <rect x="876.117" y="605.455" transform="matrix(0.8675 -0.4974 0.4974 0.8675 -183.6769 522.0759)" class="st56" width="24.036" height="0.715"/>
          </g>
          <g>
            <line class="st67" x1="877.714" y1="622.727" x2="898.557" y2="610.756"/>
            
              <rect x="876.117" y="616.384" transform="matrix(0.8675 -0.4975 0.4975 0.8675 -189.12 523.5991)" class="st56" width="24.036" height="0.715"/>
          </g>
          <g>
            <line class="st67" x1="877.714" y1="633.656" x2="898.557" y2="621.685"/>
            
              <rect x="876.117" y="627.313" transform="matrix(0.8675 -0.4975 0.4975 0.8675 -194.5572 525.0475)" class="st56" width="24.036" height="0.715"/>
          </g>
          <g>
            <line class="st67" x1="877.714" y1="644.585" x2="898.557" y2="632.614"/>
            
              <rect x="876.117" y="638.242" transform="matrix(0.8675 -0.4975 0.4975 0.8675 -199.9944 526.4961)" class="st56" width="24.036" height="0.715"/>
          </g>
          <g>
            <line class="st67" x1="877.714" y1="655.513" x2="898.557" y2="643.543"/>
            
              <rect x="876.117" y="649.171" transform="matrix(0.8674 -0.4976 0.4976 0.8674 -205.4401 528.0208)" class="st56" width="24.036" height="0.715"/>
          </g>
          <polygon class="st25" points="875.464,594.834 875.362,608.094 872.213,609.928 872.213,618.539 875.296,616.74 875.24,624.114 
            872.213,625.877 872.213,634.488 875.174,632.76 875.117,640.134 872.213,641.826 872.213,650.437 875.051,648.781 
            874.995,656.154 872.213,657.775 872.213,666.386 874.929,664.801 874.835,677.057 899.988,662.535 899.988,650.177 
            899.99,650.176 899.99,641.592 899.988,641.593 899.988,634.228 899.99,634.227 899.99,625.643 899.988,625.644 899.989,618.278 
            899.99,618.278 899.99,609.695 899.989,609.695 899.989,602.329 899.99,602.329 899.99,593.746 899.989,593.746 899.99,580.707 
                  "/>
        </g>
        <g>
          <g>
            <g>
              <polygon class="st74" points="734.775,644.597 758.616,658.361 758.616,589.54 734.775,575.775 					"/>
              <polygon class="st75" points="758.616,589.54 758.616,658.361 782.864,644.362 782.456,575.775 					"/>
              <polygon class="st76" points="734.775,575.775 758.616,589.54 782.456,575.775 758.616,562.011 					"/>
            </g>
            <g>
              <polygon class="st71" points="743.431,575.869 758.689,584.679 758.689,578.676 743.431,569.867 					"/>
              <polygon class="st75" points="758.689,578.676 758.689,584.679 774.208,575.719 773.947,569.867 					"/>
              <polygon class="st25" points="758.689,578.676 758.689,584.679 774.208,575.719 773.947,569.867 					"/>
              <polygon class="st77" points="743.431,569.867 758.689,578.676 773.947,569.867 758.689,561.057 					"/>
            </g>
            <g>
              <g>
                <polygon class="st55" points="761.725,603.667 779.754,593.258 779.754,586.318 761.725,596.727 						"/>
                <path class="st56" d="M761.367,604.286l18.744-10.822v-7.765l-18.744,10.822V604.286z M762.083,596.934l17.314-9.996v6.114
                  l-17.314,9.996V596.934z"/>
              </g>
              <g>
                <rect x="765.402" y="594.28" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="768.867" y="592.442" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="772.332" y="590.604" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="775.797" y="588.488" class="st56" width="0.715" height="6.939"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st55" points="761.725,614.276 779.754,603.867 779.754,596.927 761.725,607.336 						"/>
                <path class="st56" d="M761.367,614.895l18.744-10.822v-7.765l-18.744,10.822V614.895z M762.083,607.543l17.314-9.996v6.114
                  l-17.314,9.996V607.543z"/>
              </g>
              <g>
                <rect x="765.402" y="604.889" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="768.867" y="603.051" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="772.332" y="601.213" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="775.797" y="599.097" class="st56" width="0.715" height="6.939"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st55" points="761.725,624.885 779.754,614.476 779.754,607.536 761.725,617.945 						"/>
                <path class="st56" d="M761.367,625.504l18.744-10.822v-7.765l-18.744,10.822V625.504z M762.083,618.152l17.314-9.996v6.114
                  l-17.314,9.996V618.152z"/>
              </g>
              <g>
                <rect x="765.402" y="615.498" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="768.867" y="613.66" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="772.332" y="611.822" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="775.797" y="609.706" class="st56" width="0.715" height="6.939"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st55" points="761.725,635.494 779.754,625.085 779.754,618.145 761.725,628.554 						"/>
                <path class="st56" d="M761.367,636.113l18.744-10.822v-7.765l-18.744,10.822V636.113z M762.083,628.76l17.314-9.996v6.114
                  l-17.314,9.996V628.76z"/>
              </g>
              <g>
                <rect x="765.402" y="626.107" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="768.867" y="624.269" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="772.332" y="622.431" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="775.797" y="620.315" class="st56" width="0.715" height="6.939"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st55" points="761.725,646.103 779.754,635.694 779.754,628.754 761.725,639.163 						"/>
                <path class="st56" d="M761.367,646.722l18.744-10.822v-7.765l-18.744,10.822V646.722z M762.083,639.37l17.314-9.996v6.114
                  l-17.314,9.996V639.37z"/>
              </g>
              <g>
                <rect x="765.402" y="636.716" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="768.867" y="634.878" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="772.332" y="633.04" class="st56" width="0.715" height="6.939"/>
              </g>
              <g>
                <rect x="775.797" y="630.924" class="st56" width="0.715" height="6.939"/>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon class="st66" points="737.681,592.689 755.71,603.098 755.71,596.158 737.681,585.749 							"/>
                  <path class="st56" d="M737.323,592.895l18.744,10.822v-7.765l-18.744-10.822V592.895z M738.039,586.368l17.314,9.996v6.114
                    l-17.314-9.996V586.368z"/>
                </g>
                <g>
                  <rect x="751.318" y="593.711" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="747.853" y="591.872" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="744.388" y="590.035" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="740.923" y="587.919" class="st56" width="0.715" height="6.939"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st66" points="737.681,603.297 755.71,613.706 755.71,606.767 737.681,596.358 							"/>
                  <path class="st56" d="M737.323,603.504l18.744,10.822v-7.765l-18.744-10.822V603.504z M738.039,596.977l17.314,9.996v6.114
                    l-17.314-9.996V596.977z"/>
                </g>
                <g>
                  <rect x="751.318" y="604.32" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="747.853" y="602.482" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="744.388" y="600.644" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="740.923" y="598.528" class="st56" width="0.715" height="6.939"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st66" points="737.681,613.906 755.71,624.315 755.71,617.376 737.681,606.967 							"/>
                  <path class="st56" d="M737.323,614.113l18.744,10.822v-7.765l-18.744-10.822V614.113z M738.039,607.586l17.314,9.996v6.114
                    l-17.314-9.996V607.586z"/>
                </g>
                <g>
                  <rect x="751.318" y="614.929" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="747.853" y="613.091" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="744.388" y="611.253" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="740.923" y="609.137" class="st56" width="0.715" height="6.939"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st66" points="737.681,624.516 755.71,634.924 755.71,627.985 737.681,617.576 							"/>
                  <path class="st56" d="M737.323,624.722l18.744,10.822v-7.765l-18.744-10.822V624.722z M738.039,618.195l17.314,9.996v6.114
                    l-17.314-9.996V618.195z"/>
                </g>
                <g>
                  <rect x="751.318" y="625.538" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="747.853" y="623.7" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="744.388" y="621.862" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="740.923" y="619.746" class="st56" width="0.715" height="6.939"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st66" points="737.681,635.124 755.71,645.533 755.71,638.594 737.681,628.185 							"/>
                  <path class="st56" d="M737.323,635.331l18.744,10.822v-7.765l-18.744-10.822V635.331z M738.039,628.804l17.314,9.996v6.114
                    l-17.314-9.996V628.804z"/>
                </g>
                <g>
                  <rect x="751.318" y="636.147" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="747.853" y="634.309" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="744.388" y="632.471" class="st56" width="0.715" height="6.939"/>
                </g>
                <g>
                  <rect x="740.923" y="630.355" class="st56" width="0.715" height="6.939"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <path class="st78" d="M758.689,550.571c-0.497,0-0.899,0.199-0.899,0.445c0,0.246,0.403,0.445,0.899,0.445
              c0.497,0,0.9-0.199,0.9-0.445C759.588,550.77,759.186,550.571,758.689,550.571z"/>
            <path class="st78" d="M758.689,569.931c-0.497,0-0.899,0.199-0.899,0.445s0.403,0.445,0.899,0.445c0.497,0,0.9-0.199,0.9-0.445
              S759.186,569.931,758.689,569.931z"/>
            <rect x="757.789" y="551.016" class="st78" width="1.799" height="19.36"/>
          </g>
          <polygon class="st25" points="758.616,589.54 758.616,658.361 782.864,644.362 782.456,575.775 			"/>
          <polygon class="st25" points="758.689,584.679 766.298,585.104 782.456,575.775 774.208,575.719 			"/>
        </g>
        <g>
          <g>
            <polygon class="st79" points="752.239,684.832 813.207,719.606 813.207,678.74 752.239,643.888 				"/>
            <polygon class="st80" points="813.207,719.606 860.035,691.766 860.035,650.638 813.207,678.74 				"/>
            <polygon class="st81" points="752.239,643.888 813.207,678.74 860.035,650.638 800.364,616.267 				"/>
            <polygon class="st25" points="782.069,635.464 771.904,635.464 812.457,670.56 826.848,670.56 860.035,650.638 844.104,650.638 
                      "/>
            <g>
              <polygon class="st82" points="764.726,691.488 812.73,718.786 812.73,684.794 764.726,658.847 					"/>
              <path class="st56" d="M764.249,691.766l48.958,27.839v-35.096l-48.958-26.462V691.766z M765.202,659.647l47.051,25.431v32.889
                l-47.051-26.755V659.647z"/>
            </g>
            <g>
              <polygon class="st83" points="813.684,718.768 855.108,694.14 855.108,661.407 813.684,684.788 					"/>
              <path class="st56" d="M813.207,719.606l42.377-25.194V660.59l-42.377,23.919V719.606z M814.161,685.066l40.47-22.843v31.646
                l-40.47,24.06V685.066z"/>
            </g>
            <g>
              <rect x="778.952" y="666.252" class="st56" width="0.954" height="33.674"/>
            </g>
            <g>
              <polygon class="st56" points="795.841,708.902 796.795,709.344 796.795,676.664 795.841,676.664 					"/>
            </g>
            <g>
              <polygon class="st56" points="826.845,711.112 827.799,710.119 827.799,677.439 826.845,677.439 					"/>
            </g>
            <g>
              <rect x="840.581" y="670.441" class="st56" width="0.954" height="32.254"/>
            </g>
            <g>
              <polygon class="st56" points="812.921,705.554 765.023,678.325 764.551,679.154 812.689,706.519 812.927,706.655 
                855.193,681.978 854.712,681.155 					"/>
            </g>
            <g>
              <polygon class="st56" points="812.921,698.254 765.023,671.025 764.551,671.854 812.689,699.219 812.927,699.355 
                855.193,674.678 854.712,673.854 					"/>
            </g>
            <g>
              <polygon class="st84" points="782.069,635.464 828.202,662.315 828.202,656.102 782.069,629.251 					"/>
              <polygon class="st85" points="828.202,662.315 844.104,653.041 844.104,646.983 828.202,656.102 					"/>
              <polygon class="st86" points="828.202,662.315 844.104,653.041 844.104,646.983 828.202,656.102 					"/>
              <polygon class="st87" points="782.069,629.251 828.202,656.102 844.104,646.983 797.864,620.132 					"/>
            </g>
            <g>
              <path class="st88" d="M811.095,660.385c-0.113-0.067-0.259-0.031-0.327,0.083c-0.067,0.113-0.03,0.259,0.083,0.327l8.94,5.324
                c0.038,0.023,0.08,0.034,0.122,0.034c0.081,0,0.16-0.042,0.205-0.117c0.067-0.113,0.03-0.259-0.083-0.327L811.095,660.385z"/>
            </g>
            <g>
              <polygon class="st56" points="767.464,644.603 812.457,670.56 812.457,659.987 767.464,634.466 					"/>
              <polygon class="st89" points="767.464,634.466 812.457,659.987 814,658.888 768.724,633.674 					"/>
              <polygon class="st90" points="812.457,670.56 814,669.495 814,658.888 812.457,659.987 					"/>
            </g>
          </g>
          <polygon class="st25" points="813.207,678.74 860.035,650.638 860.035,691.766 813.207,719.606 			"/>
        </g>
      </g>
      <g>
        <path class="st42" d="M637.266,253.676c-8.07,0-16.147-0.955-24.175-2.865c-27.075-6.44-44.443-21.737-44.616-21.89l0.464-0.519
          c0.171,0.153,17.429,15.346,44.342,21.74c15.797,3.753,31.782,3.785,47.515,0.095c19.672-4.614,39.007-15.095,57.465-31.151
          l0.456,0.525c-18.55,16.137-37.99,26.67-57.782,31.308C653.09,252.757,645.182,253.676,637.266,253.676z"/>
      </g>
      <g>
        <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="666.2336" y1="559.4292" x2="739.4342" y2="559.4292">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <path class="st91" d="M688.405,546.691l-10.898-7.034l0.424,15.349l-11.698-8.153l0.629,22.727h8.324l19.873,11.162l-0.038,4.938
          l44.413,0v-19.323l-51.403-33.178L688.405,546.691z M706.333,573.546l-0.01,1.249l-9.267-6.459L706.333,573.546z"/>
        <g>
          <path class="st46" d="M690.589,555.833c0,0.041,0.012,0.142,0.119,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            c0.2,0,0.397-0.051,0.572-0.147l0.032-0.018c0.072-0.04,0.114-0.112,0.117-0.194h0.002v-20.05h-1.435V555.833z"/>
          <g>
            <polygon class="st92" points="676.256,542.319 699.488,555.565 708.615,533.289 685.335,519.902 				"/>
            <polygon class="st93" points="677.426,541.558 699.296,554.033 707.473,534.067 685.563,521.474 				"/>
            <g>
              <path class="st94" d="M706.093,533.274l-0.375-0.215l-0.561,1.37l-1.499-0.861l0.56-1.371l-0.375-0.216l-0.56,1.371l-1.5-0.862
                l0.56-1.371l-0.375-0.216l-0.56,1.372l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373l-0.376-0.216
                l-0.56,1.373l-1.503-0.863l0.56-1.374l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216l-0.559,1.375
                l-1.505-0.865l0.559-1.375l-0.376-0.216l-0.559,1.375l-1.506-0.865l0.559-1.376l-0.377-0.216l-0.559,1.376l-1.507-0.866
                l0.559-1.376l-0.377-0.217l-0.559,1.376l-1.508-0.866l0.558-1.377l-0.377-0.217l-0.558,1.377l-1.359-0.781l-0.172,0.424
                l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.693l-1.359-0.779l-0.172,0.423l1.359,0.779
                l-0.686,1.693l-1.359-0.779l-0.171,0.423l1.359,0.779l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.358,0.778l-0.685,1.69
                l-1.358-0.777l-0.171,0.423l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688l-1.358-0.776
                l-0.171,0.422l1.358,0.776l-0.684,1.687l-1.357-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.377,0.215l0.555-1.369
                l1.505,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.555,1.367l0.376,0.214l0.556-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858l-0.556,1.366l0.376,0.214l0.556-1.366l1.502,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.556-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.374,0.214l0.557-1.363l1.497,0.855l-0.557,1.363l0.374,0.213l0.557-1.362l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.419
                l-1.378-0.787l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.788l0.172-0.42l-1.378-0.789l0.688-1.681
                l1.379,0.789l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421
                l-1.379-0.791l0.689-1.685l1.379,0.792l0.173-0.421l-1.38-0.792l0.69-1.686l1.38,0.792l0.173-0.422l-1.38-0.793
                L706.093,533.274z M701.612,532.913l1.5,0.861l-0.69,1.687l-1.499-0.861L701.612,532.913z M692.337,537.485l-1.503-0.861
                l0.687-1.688l1.503,0.861L692.337,537.485z M693.4,536.013l1.502,0.86l-0.687,1.686l-1.502-0.86L693.4,536.013z
                M690.458,536.409l-1.504-0.861l0.687-1.689l1.504,0.862L690.458,536.409z M690.287,536.831l-0.686,1.687l-1.503-0.86
                l0.686-1.687L690.287,536.831z M690.663,537.046l1.503,0.86l-0.687,1.686l-1.503-0.86L690.663,537.046z M692.541,538.121
                l1.502,0.86l-0.687,1.685l-1.501-0.859L692.541,538.121z M694.418,539.196l1.501,0.859l-0.687,1.684l-1.5-0.859
                L694.418,539.196z M694.59,538.775l0.688-1.686l1.501,0.86l-0.688,1.685L694.59,538.775z M695.449,536.667l0.688-1.687
                l1.501,0.861l-0.688,1.687L695.449,536.667z M695.074,536.452l-1.502-0.861l0.688-1.688l1.502,0.862L695.074,536.452z
                M693.196,535.375l-1.503-0.861l0.688-1.689l1.503,0.862L693.196,535.375z M691.317,534.299l-1.504-0.862l0.687-1.69
                l1.504,0.863L691.317,534.299z M689.437,533.221l-1.505-0.862l0.687-1.691l1.505,0.863L689.437,533.221z M689.265,533.644
                l-0.687,1.689l-1.505-0.862l0.686-1.69L689.265,533.644z M688.407,535.755l-0.686,1.687l-1.504-0.861l0.686-1.688
                L688.407,535.755z M687.549,537.864l-0.686,1.687l-1.504-0.86l0.686-1.687L687.549,537.864z M687.926,538.079l1.503,0.86
                l-0.686,1.686l-1.503-0.859L687.926,538.079z M689.805,539.154l1.503,0.86l-0.686,1.685l-1.502-0.859L689.805,539.154z
                M691.683,540.228l1.501,0.859l-0.687,1.684l-1.501-0.858L691.683,540.228z M693.559,541.302l1.5,0.858l-0.687,1.683
                l-1.5-0.858L693.559,541.302z M695.435,542.375l1.5,0.858l-0.687,1.682l-1.499-0.857L695.435,542.375z M695.606,541.954
                l0.687-1.684l1.5,0.859l-0.688,1.683L695.606,541.954z M696.466,539.849l0.688-1.685l1.5,0.859l-0.688,1.685L696.466,539.849z
                M697.325,537.742l0.688-1.686l1.5,0.86l-0.689,1.686L697.325,537.742z M698.186,535.634l0.689-1.687l1.501,0.861l-0.689,1.687
                L698.186,535.634z M697.811,535.419l-1.501-0.861l0.688-1.688l1.502,0.862L697.811,535.419z M695.934,534.342l-1.502-0.862
                l0.688-1.689l1.503,0.862L695.934,534.342z M694.056,533.265l-1.503-0.862l0.688-1.69l1.503,0.863L694.056,533.265z
                M692.177,532.187l-1.504-0.863l0.688-1.691l1.505,0.864L692.177,532.187z M690.296,531.109l-1.505-0.863l0.687-1.692
                l1.505,0.864L690.296,531.109z M688.414,530.029l-1.506-0.864l0.687-1.693l1.507,0.865L688.414,530.029z M688.243,530.452
                l-0.687,1.691l-1.506-0.863l0.687-1.692L688.243,530.452z M687.384,532.566l-0.686,1.69l-1.506-0.862l0.686-1.69
                L687.384,532.566z M686.526,534.678l-0.686,1.688l-1.505-0.861l0.686-1.689L686.526,534.678z M685.669,536.788l-0.685,1.687
                l-1.505-0.861l0.685-1.688L685.669,536.788z M684.812,538.897l-0.685,1.686l-1.505-0.86l0.685-1.687L684.812,538.897z
                M685.189,539.112l1.504,0.86l-0.685,1.685l-1.504-0.859L685.189,539.112z M687.068,540.187l1.503,0.859l-0.686,1.685
                l-1.503-0.859L687.068,540.187z M688.947,541.26l1.502,0.859l-0.686,1.684l-1.502-0.858L688.947,541.26z M690.825,542.334
                l1.501,0.858l-0.686,1.683l-1.501-0.857L690.825,542.334z M692.701,543.406l1.5,0.857l-0.686,1.682l-1.5-0.857L692.701,543.406
                z M694.576,544.478l1.499,0.857l-0.687,1.681l-1.499-0.856L694.576,544.478z M696.45,545.549l1.498,0.856l-0.687,1.68
                l-1.498-0.856L696.45,545.549z M696.622,545.129l0.687-1.682l1.498,0.857l-0.688,1.681L696.622,545.129z M697.481,543.026
                l0.688-1.683l1.499,0.858l-0.688,1.682L697.481,543.026z M698.34,540.922l0.688-1.684l1.499,0.859l-0.688,1.684L698.34,540.922
                z M699.2,538.817l0.688-1.686l1.499,0.86l-0.689,1.685L699.2,538.817z M700.061,536.709l0.689-1.687l1.5,0.861l-0.689,1.686
                L700.061,536.709z M699.736,531.836l1.501,0.862l-0.69,1.688l-1.501-0.861L699.736,531.836z M697.859,530.757l1.502,0.863
                l-0.689,1.689l-1.502-0.862L697.859,530.757z M695.981,529.679l1.503,0.863l-0.689,1.69l-1.502-0.862L695.981,529.679z
                M694.101,528.599l1.504,0.864l-0.689,1.691l-1.504-0.863L694.101,528.599z M692.22,527.518l1.505,0.865l-0.688,1.691
                l-1.505-0.864L692.22,527.518z M690.338,526.438l1.506,0.865l-0.688,1.692l-1.505-0.864L690.338,526.438z M688.454,525.356
                l1.507,0.865l-0.688,1.693l-1.507-0.865L688.454,525.356z M686.57,524.273l1.508,0.866l-0.688,1.694l-1.508-0.865
                L686.57,524.273z M685.711,526.392l1.508,0.865l-0.687,1.693l-1.508-0.865L685.711,526.392z M684.852,528.508l1.507,0.864
                l-0.687,1.692l-1.507-0.864L684.852,528.508z M683.995,530.624l1.507,0.863l-0.686,1.691l-1.507-0.863L683.995,530.624z
                M683.137,532.737l1.507,0.863l-0.685,1.689l-1.506-0.862L683.137,532.737z M682.281,534.85l1.506,0.862l-0.685,1.688
                l-1.506-0.861L682.281,534.85z M681.425,536.961l1.506,0.861l-0.685,1.687l-1.506-0.86L681.425,536.961z M681.391,541.616
                l-1.505-0.859l0.684-1.686l1.506,0.86L681.391,541.616z M683.272,542.69l-1.505-0.859l0.684-1.686l1.505,0.86L683.272,542.69z
                M685.151,543.762l-1.504-0.858l0.685-1.685l1.504,0.859L685.151,543.762z M687.03,544.834l-1.503-0.858l0.685-1.684
                l1.503,0.858L687.03,544.834z M688.907,545.906l-1.502-0.857l0.685-1.683l1.502,0.858L688.907,545.906z M690.783,546.977
                l-1.5-0.856l0.685-1.682l1.501,0.857L690.783,546.977z M692.657,548.047l-1.5-0.856l0.686-1.681l1.5,0.857L692.657,548.047z
                M694.531,549.116l-1.499-0.855l0.686-1.681l1.499,0.856L694.531,549.116z M696.403,550.185l-1.497-0.855l0.686-1.68
                l1.498,0.855L696.403,550.185z M698.274,551.253l-1.497-0.854l0.686-1.679l1.497,0.855L698.274,551.253z M699.132,549.155
                l-1.497-0.855l0.687-1.68l1.497,0.856L699.132,549.155z M699.991,547.055l-1.497-0.856l0.688-1.681l1.497,0.857
                L699.991,547.055z M700.851,544.954l-1.498-0.857l0.688-1.682l1.498,0.857L700.851,544.954z M701.711,542.852l-1.498-0.858
                l0.688-1.683l1.498,0.858L701.711,542.852z M702.572,540.749l-1.498-0.859l0.689-1.685l1.498,0.859L702.572,540.749z
                M703.433,538.643l-1.498-0.859l0.689-1.686l1.499,0.86L703.433,538.643z M704.295,536.537l-1.499-0.86l0.69-1.687l1.499,0.861
                L704.295,536.537z"/>
            </g>
            <g>
              <polygon class="st92" points="684.438,545.558 685.19,545.987 693.816,524.779 693.063,524.346 					"/>
            </g>
            <g>
              <polygon class="st92" points="691.756,549.732 692.505,550.159 701.146,528.994 700.395,528.562 					"/>
            </g>
            <g>
              <polygon class="st92" points="680.97,531.561 703.648,544.544 703.992,543.704 681.313,530.715 					"/>
            </g>
          </g>
          <polygon class="st95" points="699.488,555.565 700.375,555.434 709.037,534.187 708.615,533.289 			"/>
        </g>
        <polygon class="st96" points="676.769,541.049 697.442,554.206 703.248,539.109 682.406,527.125 		"/>
        <g>
          <path class="st46" d="M717.414,571.819c0,0.041,0.012,0.142,0.119,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            s0.397-0.051,0.572-0.147l0.032-0.018c0.072-0.04,0.114-0.112,0.117-0.194h0.002v-20.05h-1.435V571.819z"/>
          <g>
            <polygon class="st92" points="703.081,558.305 726.313,571.552 735.44,549.275 712.16,535.889 				"/>
            <polygon class="st93" points="704.25,557.544 726.121,570.019 734.298,550.054 712.388,537.46 				"/>
            <g>
              <path class="st94" d="M732.918,549.26l-0.375-0.215l-0.561,1.37l-1.499-0.861l0.56-1.371l-0.375-0.215l-0.56,1.371l-1.5-0.861
                l0.56-1.371l-0.375-0.216l-0.56,1.372l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373l-0.376-0.216
                l-0.56,1.373l-1.503-0.863l0.56-1.373l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216l-0.559,1.374
                l-1.505-0.865l0.559-1.375l-0.376-0.216l-0.559,1.375L717.335,542l0.559-1.376l-0.377-0.216l-0.559,1.376l-1.507-0.866
                l0.559-1.376l-0.377-0.217l-0.559,1.376l-1.508-0.866l0.558-1.377l-0.377-0.217l-0.558,1.377l-1.36-0.781l-0.172,0.424
                l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.694l-1.359-0.779l-0.172,0.423l1.359,0.779
                l-0.686,1.693l-1.359-0.779l-0.171,0.423l1.359,0.779l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.359,0.778l-0.685,1.69
                l-1.358-0.777l-0.171,0.423l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688l-1.358-0.776
                l-0.171,0.422l1.358,0.776l-0.684,1.687l-1.358-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.376,0.215l0.555-1.369
                l1.505,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.555,1.367l0.376,0.214l0.556-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858l-0.556,1.366l0.375,0.214l0.556-1.366l1.502,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.556-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.375,0.214l0.557-1.363l1.497,0.855l-0.557,1.363l0.374,0.214l0.557-1.362l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.419
                l-1.378-0.787l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.789l0.172-0.42l-1.378-0.789l0.688-1.681
                l1.379,0.789l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421
                l-1.379-0.791l0.69-1.685l1.379,0.792l0.172-0.421l-1.379-0.792l0.69-1.686l1.38,0.792l0.173-0.422l-1.38-0.792L732.918,549.26
                z M728.437,548.899l1.5,0.861l-0.69,1.687l-1.499-0.861L728.437,548.899z M719.162,553.471l-1.503-0.861l0.687-1.688
                l1.503,0.861L719.162,553.471z M720.225,551.999l1.502,0.861l-0.688,1.686l-1.502-0.86L720.225,551.999z M717.283,552.395
                l-1.504-0.861l0.687-1.689l1.504,0.862L717.283,552.395z M717.112,552.817l-0.686,1.687l-1.503-0.86l0.686-1.687
                L717.112,552.817z M717.488,553.032l1.503,0.86l-0.687,1.686l-1.503-0.86L717.488,553.032z M719.366,554.107l1.502,0.86
                l-0.687,1.685l-1.501-0.859L719.366,554.107z M721.243,555.182l1.501,0.859l-0.687,1.684l-1.5-0.859L721.243,555.182z
                M721.414,554.761l0.688-1.686l1.501,0.86l-0.688,1.685L721.414,554.761z M722.274,552.653l0.688-1.687l1.501,0.861
                l-0.688,1.687L722.274,552.653z M721.899,552.438l-1.502-0.861l0.688-1.688l1.502,0.861L721.899,552.438z M720.021,551.362
                l-1.503-0.861l0.688-1.689l1.503,0.862L720.021,551.362z M718.142,550.285l-1.504-0.862l0.687-1.69l1.504,0.863
                L718.142,550.285z M716.262,549.208l-1.505-0.862l0.687-1.691l1.505,0.863L716.262,549.208z M716.09,549.63l-0.687,1.689
                l-1.504-0.862l0.686-1.69L716.09,549.63z M715.232,551.741l-0.686,1.688l-1.504-0.861l0.686-1.688L715.232,551.741z
                M714.374,553.85l-0.686,1.687l-1.504-0.86l0.685-1.687L714.374,553.85z M714.75,554.065l1.503,0.86l-0.686,1.686l-1.503-0.859
                L714.75,554.065z M716.63,555.14l1.503,0.859l-0.686,1.685l-1.502-0.859L716.63,555.14z M718.507,556.214l1.501,0.859
                l-0.687,1.684l-1.501-0.858L718.507,556.214z M720.384,557.288l1.5,0.858l-0.687,1.683l-1.5-0.858L720.384,557.288z
                M722.259,558.361l1.499,0.858l-0.687,1.682l-1.499-0.857L722.259,558.361z M722.431,557.94l0.687-1.684l1.5,0.859
                l-0.688,1.683L722.431,557.94z M723.29,555.835l0.688-1.685l1.5,0.859l-0.688,1.685L723.29,555.835z M724.15,553.728
                l0.688-1.686l1.5,0.86l-0.688,1.686L724.15,553.728z M725.011,551.62l0.689-1.687l1.501,0.861l-0.689,1.687L725.011,551.62z
                M724.635,551.405l-1.501-0.861l0.688-1.688l1.502,0.862L724.635,551.405z M722.759,550.328l-1.502-0.862l0.688-1.689
                l1.503,0.862L722.759,550.328z M720.881,549.251l-1.503-0.862l0.688-1.69l1.503,0.863L720.881,549.251z M719.001,548.173
                l-1.504-0.863l0.688-1.691l1.505,0.864L719.001,548.173z M717.121,547.095l-1.505-0.863l0.687-1.692l1.505,0.864
                L717.121,547.095z M715.239,546.016l-1.506-0.864l0.687-1.693l1.506,0.865L715.239,546.016z M715.068,546.439l-0.687,1.691
                l-1.506-0.863l0.687-1.692L715.068,546.439z M714.209,548.552l-0.686,1.69l-1.506-0.862l0.686-1.69L714.209,548.552z
                M713.351,550.664l-0.686,1.688l-1.506-0.862l0.686-1.689L713.351,550.664z M712.494,552.774l-0.685,1.688l-1.505-0.861
                l0.685-1.688L712.494,552.774z M711.637,554.883l-0.685,1.686l-1.505-0.86l0.685-1.687L711.637,554.883z M712.013,555.098
                l1.504,0.86l-0.685,1.685l-1.504-0.859L712.013,555.098z M713.893,556.173l1.503,0.859l-0.686,1.685l-1.503-0.859
                L713.893,556.173z M715.772,557.247l1.502,0.859l-0.686,1.684l-1.502-0.858L715.772,557.247z M717.65,558.32l1.501,0.858
                l-0.686,1.683l-1.501-0.857L717.65,558.32z M719.526,559.393l1.5,0.857l-0.686,1.682l-1.5-0.857L719.526,559.393z
                M721.401,560.464l1.499,0.857l-0.687,1.681l-1.499-0.856L721.401,560.464z M723.275,561.535l1.498,0.856l-0.687,1.68
                l-1.498-0.856L723.275,561.535z M723.446,561.115l0.687-1.682l1.498,0.857l-0.688,1.681L723.446,561.115z M724.305,559.012
                l0.688-1.683l1.498,0.858l-0.688,1.682L724.305,559.012z M725.165,556.908l0.688-1.685l1.499,0.859l-0.688,1.684
                L725.165,556.908z M726.025,554.802l0.688-1.685l1.499,0.86l-0.689,1.685L726.025,554.802z M726.886,552.695l0.689-1.686
                l1.5,0.86l-0.689,1.686L726.886,552.695z M726.561,547.822l1.501,0.862l-0.689,1.688l-1.501-0.861L726.561,547.822z
                M724.684,546.744l1.502,0.863l-0.689,1.689l-1.502-0.862L724.684,546.744z M722.805,545.665l1.503,0.863l-0.689,1.69
                l-1.503-0.862L722.805,545.665z M720.926,544.585l1.504,0.864l-0.689,1.691l-1.504-0.863L720.926,544.585z M719.045,543.505
                l1.505,0.864l-0.688,1.691l-1.505-0.864L719.045,543.505z M717.163,542.424l1.506,0.865l-0.688,1.692l-1.505-0.864
                L717.163,542.424z M715.279,541.342l1.507,0.865l-0.688,1.693l-1.507-0.865L715.279,541.342z M713.395,540.259l1.508,0.866
                l-0.688,1.694l-1.508-0.865L713.395,540.259z M712.536,542.378l1.508,0.865l-0.687,1.693l-1.507-0.865L712.536,542.378z
                M711.677,544.494l1.507,0.864l-0.687,1.692l-1.507-0.864L711.677,544.494z M710.819,546.61l1.507,0.864l-0.686,1.69
                l-1.507-0.863L710.819,546.61z M709.962,548.724l1.507,0.863l-0.685,1.689l-1.507-0.862L709.962,548.724z M709.106,550.836
                l1.506,0.862l-0.685,1.688l-1.506-0.861L709.106,550.836z M708.25,552.947l1.506,0.861l-0.685,1.687l-1.506-0.86
                L708.25,552.947z M708.216,557.602l-1.505-0.859l0.684-1.686l1.506,0.86L708.216,557.602z M710.097,558.676l-1.505-0.859
                l0.684-1.685l1.505,0.859L710.097,558.676z M711.976,559.749l-1.503-0.858l0.685-1.685l1.504,0.859L711.976,559.749z
                M713.854,560.821l-1.503-0.858l0.685-1.684l1.503,0.858L713.854,560.821z M715.732,561.892l-1.502-0.857l0.685-1.683
                l1.502,0.858L715.732,561.892z M717.608,562.963l-1.501-0.856l0.685-1.682l1.501,0.857L717.608,562.963z M719.482,564.033
                l-1.5-0.856l0.686-1.681l1.5,0.857L719.482,564.033z M721.356,565.102l-1.499-0.855l0.686-1.681l1.499,0.856L721.356,565.102z
                M723.228,566.171l-1.497-0.855l0.686-1.68l1.498,0.856L723.228,566.171z M725.099,567.239l-1.497-0.854l0.686-1.679
                l1.497,0.855L725.099,567.239z M725.957,565.141l-1.497-0.855l0.687-1.68l1.497,0.856L725.957,565.141z M726.816,563.042
                l-1.497-0.856l0.688-1.681l1.497,0.857L726.816,563.042z M727.676,560.941l-1.497-0.857l0.688-1.682l1.498,0.858
                L727.676,560.941z M728.536,558.839l-1.498-0.858l0.688-1.683l1.498,0.858L728.536,558.839z M729.397,556.735l-1.498-0.858
                l0.689-1.685l1.498,0.859L729.397,556.735z M730.258,554.63l-1.498-0.859l0.689-1.686l1.499,0.86L730.258,554.63z
                M731.12,552.523l-1.499-0.86l0.69-1.687l1.499,0.861L731.12,552.523z"/>
            </g>
            <g>
              <polygon class="st92" points="711.263,561.544 712.015,561.973 720.641,540.765 719.888,540.332 					"/>
            </g>
            <g>
              <polygon class="st92" points="718.581,565.718 719.33,566.146 727.97,544.98 727.219,544.548 					"/>
            </g>
            <g>
              <polygon class="st92" points="707.795,547.547 730.473,560.531 730.817,559.69 708.138,546.701 					"/>
            </g>
          </g>
          <polygon class="st95" points="726.313,571.552 727.2,571.421 735.862,550.174 735.44,549.275 			"/>
        </g>
        <g>
          <path class="st46" d="M677.817,562.129c0,0.041,0.012,0.142,0.119,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            s0.397-0.051,0.572-0.147l0.032-0.018c0.072-0.04,0.114-0.112,0.117-0.194h0.002v-20.05h-1.435V562.129z"/>
          <g>
            <polygon class="st92" points="663.483,548.616 686.716,561.862 695.842,539.585 672.563,526.199 				"/>
            <polygon class="st93" points="664.653,547.854 686.524,560.329 694.701,540.364 672.791,527.771 				"/>
            <g>
              <path class="st94" d="M693.321,539.571l-0.375-0.215l-0.561,1.37l-1.499-0.861l0.56-1.371l-0.375-0.215l-0.56,1.371l-1.5-0.861
                l0.56-1.372l-0.375-0.216l-0.56,1.371l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373l-0.376-0.216
                l-0.56,1.373l-1.503-0.863l0.56-1.373l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216l-0.559,1.374
                l-1.505-0.865l0.559-1.375l-0.376-0.216l-0.559,1.375l-1.506-0.865l0.559-1.375l-0.377-0.216l-0.559,1.376l-1.507-0.866
                l0.559-1.376l-0.377-0.217l-0.559,1.376l-1.508-0.866l0.558-1.377l-0.377-0.217l-0.558,1.377l-1.359-0.781l-0.172,0.424
                l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.693l-1.359-0.779l-0.172,0.423l1.359,0.779
                l-0.686,1.692l-1.359-0.779l-0.172,0.423l1.359,0.778l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.358,0.778l-0.685,1.69
                l-1.358-0.777l-0.171,0.423l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688l-1.358-0.776
                l-0.171,0.422l1.358,0.775l-0.684,1.687l-1.357-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.376,0.215l0.555-1.369
                l1.506,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.555,1.367l0.376,0.214l0.556-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858l-0.556,1.366l0.376,0.214l0.556-1.366l1.502,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.556-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.375,0.214l0.557-1.363l1.497,0.855l-0.557,1.363l0.374,0.214l0.557-1.363l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.419
                l-1.378-0.787l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.789l0.172-0.42l-1.378-0.789l0.688-1.681
                l1.379,0.789l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421
                l-1.379-0.791l0.69-1.685l1.379,0.792l0.172-0.421l-1.379-0.792l0.69-1.686l1.38,0.792l0.173-0.422l-1.38-0.793
                L693.321,539.571z M688.84,539.21l1.5,0.861l-0.69,1.687l-1.5-0.861L688.84,539.21z M679.565,543.781l-1.503-0.861l0.687-1.688
                l1.503,0.861L679.565,543.781z M680.628,542.309l1.502,0.861l-0.688,1.686l-1.502-0.86L680.628,542.309z M677.686,542.705
                l-1.504-0.861l0.687-1.689l1.504,0.862L677.686,542.705z M677.514,543.127l-0.686,1.687l-1.503-0.86l0.686-1.687
                L677.514,543.127z M677.89,543.342l1.503,0.86l-0.687,1.686l-1.503-0.86L677.89,543.342z M679.768,544.418l1.502,0.86
                l-0.687,1.685l-1.501-0.859L679.768,544.418z M681.645,545.492l1.501,0.859l-0.687,1.684l-1.501-0.859L681.645,545.492z
                M681.817,545.071l0.688-1.686l1.501,0.86l-0.688,1.685L681.817,545.071z M682.677,542.963l0.688-1.687l1.501,0.861
                l-0.688,1.687L682.677,542.963z M682.301,542.748l-1.502-0.861l0.688-1.688l1.502,0.861L682.301,542.748z M680.424,541.672
                l-1.503-0.861l0.688-1.689l1.503,0.862L680.424,541.672z M678.545,540.595l-1.504-0.862l0.687-1.69l1.504,0.863
                L678.545,540.595z M676.665,539.518l-1.505-0.862l0.687-1.691l1.505,0.863L676.665,539.518z M676.493,539.94l-0.687,1.689
                l-1.505-0.862l0.686-1.69L676.493,539.94z M675.635,542.051l-0.686,1.688l-1.504-0.861l0.686-1.688L675.635,542.051z
                M674.777,544.16l-0.686,1.687l-1.504-0.86l0.685-1.687L674.777,544.16z M675.153,544.375l1.503,0.86l-0.686,1.686
                l-1.503-0.859L675.153,544.375z M677.032,545.45l1.502,0.859l-0.686,1.685l-1.502-0.859L677.032,545.45z M678.91,546.525
                l1.501,0.859l-0.687,1.684l-1.501-0.858L678.91,546.525z M680.787,547.598l1.5,0.858l-0.687,1.683l-1.5-0.858L680.787,547.598z
                M682.662,548.671l1.5,0.858l-0.687,1.682l-1.499-0.857L682.662,548.671z M682.834,548.25l0.687-1.684l1.5,0.859l-0.688,1.683
                L682.834,548.25z M683.693,546.145l0.688-1.685l1.5,0.86l-0.688,1.684L683.693,546.145z M684.553,544.038l0.688-1.686l1.5,0.86
                l-0.689,1.686L684.553,544.038z M685.413,541.93l0.689-1.687l1.501,0.861l-0.689,1.687L685.413,541.93z M685.038,541.715
                l-1.501-0.861l0.688-1.688l1.502,0.862L685.038,541.715z M683.161,540.638l-1.502-0.861l0.688-1.689l1.502,0.862
                L683.161,540.638z M681.283,539.562l-1.503-0.862l0.688-1.69l1.503,0.863L681.283,539.562z M679.404,538.484l-1.504-0.863
                l0.688-1.691l1.505,0.864L679.404,538.484z M677.524,537.405l-1.505-0.863l0.687-1.692l1.505,0.864L677.524,537.405z
                M675.642,536.326l-1.506-0.864l0.687-1.693l1.507,0.865L675.642,536.326z M675.47,536.749l-0.687,1.691l-1.506-0.863
                l0.686-1.692L675.47,536.749z M674.612,538.862l-0.686,1.69l-1.506-0.862l0.686-1.69L674.612,538.862z M673.754,540.974
                l-0.686,1.688l-1.505-0.861l0.685-1.689L673.754,540.974z M672.897,543.084l-0.685,1.687l-1.505-0.86l0.685-1.688
                L672.897,543.084z M672.04,545.194l-0.685,1.686l-1.505-0.86l0.685-1.687L672.04,545.194z M672.416,545.409l1.504,0.86
                l-0.685,1.685l-1.504-0.859L672.416,545.409z M674.296,546.483l1.503,0.859l-0.686,1.685l-1.503-0.859L674.296,546.483z
                M676.175,547.557l1.502,0.859l-0.686,1.684l-1.502-0.858L676.175,547.557z M678.052,548.63l1.501,0.858l-0.686,1.683
                l-1.501-0.857L678.052,548.63z M679.928,549.703l1.5,0.858l-0.686,1.682l-1.5-0.857L679.928,549.703z M681.804,550.775
                l1.499,0.857l-0.687,1.681l-1.499-0.856L681.804,550.775z M683.677,551.846l1.498,0.856l-0.687,1.68l-1.498-0.856
                L683.677,551.846z M683.849,551.425l0.687-1.682l1.498,0.857l-0.688,1.681L683.849,551.425z M684.708,549.323l0.688-1.683
                l1.499,0.858l-0.688,1.682L684.708,549.323z M685.568,547.219l0.688-1.684l1.499,0.859l-0.688,1.684L685.568,547.219z
                M686.428,545.113l0.689-1.685l1.499,0.86l-0.689,1.685L686.428,545.113z M687.289,543.006l0.689-1.687l1.499,0.861
                l-0.689,1.686L687.289,543.006z M686.964,538.132l1.501,0.862l-0.69,1.688l-1.501-0.861L686.964,538.132z M685.086,537.054
                l1.502,0.863l-0.689,1.689l-1.502-0.862L685.086,537.054z M683.208,535.975l1.503,0.863l-0.689,1.69l-1.502-0.862
                L683.208,535.975z M681.328,534.895l1.504,0.864l-0.688,1.691l-1.504-0.863L681.328,534.895z M679.447,533.815l1.505,0.864
                l-0.688,1.691l-1.505-0.864L679.447,533.815z M677.565,532.734l1.506,0.865l-0.688,1.692l-1.505-0.864L677.565,532.734z
                M675.682,531.652l1.507,0.865l-0.688,1.693l-1.507-0.865L675.682,531.652z M673.797,530.57l1.508,0.866l-0.688,1.694
                l-1.508-0.865L673.797,530.57z M672.938,532.688l1.508,0.865l-0.687,1.693l-1.507-0.865L672.938,532.688z M672.08,534.805
                l1.507,0.864l-0.687,1.692l-1.507-0.864L672.08,534.805z M671.222,536.92l1.507,0.863l-0.686,1.691l-1.507-0.863
                L671.222,536.92z M670.365,539.034l1.507,0.863l-0.685,1.689l-1.507-0.862L670.365,539.034z M669.508,541.146l1.506,0.862
                l-0.685,1.688l-1.506-0.861L669.508,541.146z M668.652,543.257l1.506,0.861l-0.685,1.687l-1.506-0.86L668.652,543.257z
                M668.618,547.912l-1.505-0.859l0.684-1.686l1.506,0.86L668.618,547.912z M670.499,548.986l-1.505-0.859l0.684-1.686
                l1.505,0.859L670.499,548.986z M672.379,550.059l-1.503-0.858l0.684-1.685l1.504,0.859L672.379,550.059z M674.257,551.131
                l-1.503-0.858l0.685-1.684l1.503,0.858L674.257,551.131z M676.134,552.202l-1.502-0.857l0.685-1.683l1.502,0.858
                L676.134,552.202z M678.01,553.273l-1.5-0.856l0.685-1.682l1.501,0.857L678.01,553.273z M679.885,554.343l-1.5-0.856
                l0.686-1.681l1.5,0.857L679.885,554.343z M681.758,555.412l-1.499-0.855l0.686-1.681l1.499,0.856L681.758,555.412z
                M683.63,556.481l-1.498-0.855l0.686-1.68l1.498,0.856L683.63,556.481z M685.501,557.549l-1.497-0.854l0.687-1.679l1.497,0.855
                L685.501,557.549z M686.36,555.451l-1.497-0.855l0.687-1.68l1.497,0.856L686.36,555.451z M687.219,553.352l-1.497-0.856
                l0.688-1.681l1.497,0.857L687.219,553.352z M688.078,551.251l-1.497-0.857l0.688-1.682l1.498,0.858L688.078,551.251z
                M688.939,549.149l-1.498-0.858l0.688-1.684l1.498,0.858L688.939,549.149z M689.8,547.045l-1.498-0.859l0.689-1.685
                l1.498,0.859L689.8,547.045z M690.661,544.94l-1.498-0.859l0.689-1.686l1.499,0.86L690.661,544.94z M691.523,542.833
                l-1.499-0.86l0.69-1.687l1.499,0.861L691.523,542.833z"/>
            </g>
            <g>
              <polygon class="st92" points="671.666,551.854 672.417,552.283 681.043,531.075 680.29,530.642 					"/>
            </g>
            <g>
              <polygon class="st92" points="678.984,556.028 679.733,556.456 688.373,535.29 687.622,534.858 					"/>
            </g>
            <g>
              <polygon class="st92" points="668.198,537.857 690.876,550.841 691.22,550 668.541,537.012 					"/>
            </g>
          </g>
          <polygon class="st95" points="686.716,561.862 687.603,561.731 696.265,540.484 695.842,539.585 			"/>
        </g>
        <polygon class="st96" points="703.556,557.039 724.229,570.196 730.035,555.099 709.194,543.115 		"/>
        <g>
          <path class="st46" d="M705.976,578.229c0,0.041,0.012,0.142,0.119,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            c0.199,0,0.397-0.051,0.572-0.147l0.032-0.018c0.072-0.04,0.114-0.112,0.117-0.194h0.002v-20.05h-1.435V578.229z"/>
          <g>
            <polygon class="st92" points="691.642,564.716 714.875,577.962 724.001,555.685 700.722,542.299 				"/>
            <polygon class="st93" points="692.812,563.955 714.683,576.43 722.86,556.464 700.949,543.871 				"/>
            <g>
              <path class="st94" d="M721.48,555.671l-0.375-0.215l-0.561,1.37l-1.499-0.861l0.561-1.371l-0.375-0.215l-0.56,1.371l-1.5-0.862
                l0.56-1.371l-0.375-0.216l-0.56,1.371l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373l-0.376-0.216
                l-0.56,1.373l-1.503-0.863l0.56-1.373l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216l-0.559,1.374
                l-1.505-0.864l0.559-1.375l-0.377-0.216l-0.559,1.375l-1.506-0.865l0.559-1.376l-0.377-0.216l-0.559,1.376l-1.507-0.866
                l0.559-1.376l-0.377-0.217l-0.559,1.376l-1.508-0.866l0.558-1.377l-0.377-0.217l-0.558,1.377l-1.359-0.781l-0.172,0.424
                l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.694l-1.359-0.78l-0.172,0.424l1.359,0.779
                l-0.686,1.692l-1.359-0.779l-0.171,0.423l1.359,0.779l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.358,0.778l-0.685,1.69
                l-1.358-0.777l-0.171,0.423l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688l-1.358-0.776
                l-0.171,0.422l1.358,0.775l-0.684,1.687l-1.357-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.377,0.215l0.555-1.369
                l1.506,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858l-0.556,1.366l0.376,0.214l0.556-1.366l1.501,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.557-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.375,0.214l0.557-1.363l1.497,0.855l-0.557,1.363l0.374,0.213l0.557-1.362l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.419
                l-1.378-0.787l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.789l0.172-0.42l-1.378-0.789l0.688-1.681
                l1.378,0.789l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421
                l-1.379-0.791l0.69-1.685l1.379,0.792l0.173-0.421l-1.38-0.792l0.69-1.686l1.38,0.793l0.173-0.422l-1.38-0.793L721.48,555.671z
                M716.999,555.31l1.5,0.861l-0.69,1.687l-1.499-0.861L716.999,555.31z M707.724,559.881l-1.503-0.861l0.687-1.688l1.503,0.861
                L707.724,559.881z M708.786,558.409l1.502,0.861l-0.688,1.686l-1.502-0.86L708.786,558.409z M705.845,558.806l-1.504-0.861
                l0.687-1.689l1.504,0.862L705.845,558.806z M705.673,559.228l-0.686,1.687l-1.503-0.86l0.686-1.687L705.673,559.228z
                M706.049,559.443l1.503,0.86l-0.687,1.686l-1.503-0.86L706.049,559.443z M707.927,560.518l1.502,0.86l-0.687,1.685
                l-1.501-0.859L707.927,560.518z M709.804,561.593l1.501,0.859l-0.687,1.684l-1.5-0.859L709.804,561.593z M709.976,561.171
                l0.688-1.686l1.501,0.86l-0.688,1.685L709.976,561.171z M710.836,559.063l0.688-1.687l1.501,0.861l-0.688,1.687
                L710.836,559.063z M710.46,558.848l-1.502-0.861l0.688-1.688l1.502,0.861L710.46,558.848z M708.583,557.772l-1.503-0.861
                l0.687-1.689l1.503,0.862L708.583,557.772z M706.704,556.695l-1.504-0.862l0.687-1.69l1.504,0.863L706.704,556.695z
                M704.824,555.618l-1.505-0.862l0.687-1.691l1.505,0.863L704.824,555.618z M704.652,556.04l-0.687,1.689l-1.505-0.862
                l0.686-1.689L704.652,556.04z M703.794,558.151l-0.686,1.688l-1.504-0.861l0.686-1.688L703.794,558.151z M702.936,560.26
                l-0.686,1.687l-1.504-0.86l0.685-1.687L702.936,560.26z M703.312,560.476l1.503,0.86l-0.686,1.686l-1.503-0.859
                L703.312,560.476z M705.191,561.551l1.503,0.86l-0.686,1.685l-1.502-0.859L705.191,561.551z M707.069,562.625l1.501,0.859
                l-0.687,1.684l-1.501-0.858L707.069,562.625z M708.946,563.698l1.5,0.858l-0.687,1.683l-1.5-0.858L708.946,563.698z
                M710.821,564.771l1.499,0.858l-0.687,1.682l-1.499-0.857L710.821,564.771z M710.993,564.35l0.687-1.684l1.5,0.859
                l-0.688,1.683L710.993,564.35z M711.852,562.245l0.688-1.685l1.5,0.859l-0.688,1.685L711.852,562.245z M712.712,560.139
                l0.688-1.686l1.5,0.86l-0.689,1.686L712.712,560.139z M713.572,558.031l0.689-1.687l1.501,0.861l-0.689,1.687L713.572,558.031z
                M713.197,557.815l-1.501-0.861l0.688-1.688l1.502,0.862L713.197,557.815z M711.32,556.739l-1.502-0.862l0.688-1.689
                l1.502,0.862L711.32,556.739z M709.442,555.662l-1.503-0.862l0.688-1.69l1.504,0.863L709.442,555.662z M707.563,554.584
                l-1.504-0.863l0.688-1.691l1.505,0.864L707.563,554.584z M705.682,553.505l-1.505-0.863l0.687-1.692l1.505,0.864
                L705.682,553.505z M703.801,552.426l-1.506-0.864l0.687-1.693l1.507,0.865L703.801,552.426z M703.629,552.849l-0.687,1.691
                l-1.506-0.863l0.687-1.692L703.629,552.849z M702.771,554.962l-0.686,1.69l-1.506-0.862l0.686-1.69L702.771,554.962z
                M701.913,557.074l-0.686,1.688l-1.505-0.861l0.686-1.689L701.913,557.074z M701.056,559.185l-0.685,1.687l-1.505-0.861
                l0.685-1.688L701.056,559.185z M700.199,561.294l-0.685,1.686l-1.505-0.86l0.685-1.687L700.199,561.294z M700.575,561.509
                l1.504,0.86l-0.685,1.685l-1.504-0.859L700.575,561.509z M702.455,562.583l1.503,0.859l-0.686,1.684l-1.503-0.859
                L702.455,562.583z M704.334,563.657l1.502,0.859l-0.686,1.684l-1.502-0.858L704.334,563.657z M706.211,564.731l1.501,0.858
                l-0.686,1.683l-1.501-0.857L706.211,564.731z M708.087,565.803l1.5,0.857l-0.686,1.682l-1.5-0.857L708.087,565.803z
                M709.962,566.875l1.499,0.857l-0.687,1.681l-1.499-0.856L709.962,566.875z M711.836,567.946l1.498,0.856l-0.687,1.68
                l-1.498-0.856L711.836,567.946z M712.008,567.526l0.687-1.682l1.498,0.857l-0.688,1.681L712.008,567.526z M712.867,565.423
                l0.688-1.683l1.498,0.858l-0.688,1.682L712.867,565.423z M713.727,563.319l0.688-1.684l1.499,0.859l-0.688,1.684
                L713.727,563.319z M714.587,561.213l0.688-1.686l1.499,0.86l-0.689,1.685L714.587,561.213z M715.448,559.106l0.689-1.687
                l1.5,0.86l-0.689,1.686L715.448,559.106z M715.123,554.232l1.501,0.862l-0.69,1.688l-1.501-0.861L715.123,554.232z
                M713.245,553.154l1.502,0.863l-0.689,1.689l-1.502-0.862L713.245,553.154z M711.367,552.075l1.503,0.863l-0.689,1.69
                l-1.502-0.862L711.367,552.075z M709.487,550.996l1.504,0.864l-0.688,1.691l-1.504-0.863L709.487,550.996z M707.606,549.915
                l1.505,0.864l-0.688,1.691l-1.505-0.864L707.606,549.915z M705.724,548.834l1.506,0.865l-0.688,1.692l-1.505-0.864
                L705.724,548.834z M703.841,547.753l1.507,0.865l-0.688,1.693l-1.507-0.865L703.841,547.753z M701.956,546.67l1.508,0.866
                l-0.687,1.694l-1.508-0.865L701.956,546.67z M701.097,548.788l1.508,0.865l-0.687,1.693l-1.508-0.864L701.097,548.788z
                M700.239,550.905l1.507,0.864l-0.686,1.692l-1.507-0.864L700.239,550.905z M699.381,553.02l1.507,0.864l-0.686,1.691
                l-1.507-0.863L699.381,553.02z M698.524,555.134l1.507,0.862l-0.685,1.689l-1.507-0.862L698.524,555.134z M697.667,557.246
                l1.506,0.862l-0.685,1.688l-1.506-0.861L697.667,557.246z M696.811,559.357l1.506,0.861l-0.685,1.687l-1.506-0.86
                L696.811,559.357z M696.777,564.013l-1.506-0.859l0.684-1.686l1.506,0.86L696.777,564.013z M698.658,565.086l-1.505-0.859
                l0.684-1.686l1.505,0.86L698.658,565.086z M700.538,566.159l-1.503-0.858l0.684-1.685l1.504,0.859L700.538,566.159z
                M702.416,567.231l-1.503-0.858l0.685-1.684l1.503,0.858L702.416,567.231z M704.293,568.303l-1.502-0.857l0.685-1.683
                l1.502,0.858L704.293,568.303z M706.169,569.374l-1.5-0.857l0.685-1.682l1.501,0.857L706.169,569.374z M708.044,570.444
                l-1.5-0.856l0.686-1.681l1.5,0.857L708.044,570.444z M709.917,571.513l-1.499-0.855l0.686-1.68l1.499,0.856L709.917,571.513z
                M711.789,572.581l-1.497-0.855l0.686-1.68l1.498,0.855L711.789,572.581z M713.66,573.649l-1.497-0.854l0.686-1.679
                l1.497,0.855L713.66,573.649z M714.519,571.552l-1.497-0.855l0.687-1.68l1.497,0.856L714.519,571.552z M715.378,569.452
                l-1.497-0.856l0.688-1.681l1.497,0.857L715.378,569.452z M716.237,567.351l-1.497-0.857l0.688-1.682l1.498,0.857
                L716.237,567.351z M717.098,565.249l-1.498-0.858l0.688-1.684l1.498,0.858L717.098,565.249z M717.958,563.145l-1.498-0.858
                l0.689-1.685l1.498,0.859L717.958,563.145z M718.82,561.04l-1.498-0.859l0.689-1.686l1.499,0.86L718.82,561.04z
                M719.682,558.934l-1.499-0.86l0.69-1.687l1.499,0.861L719.682,558.934z"/>
            </g>
            <g>
              <polygon class="st92" points="699.825,567.955 700.576,568.383 709.202,547.176 708.449,546.743 					"/>
            </g>
            <g>
              <polygon class="st92" points="707.143,572.129 707.892,572.556 716.532,551.39 715.781,550.958 					"/>
            </g>
            <g>
              <polygon class="st92" points="696.357,553.958 719.035,566.941 719.379,566.1 696.7,553.112 					"/>
            </g>
          </g>
          <polygon class="st95" points="714.875,577.962 715.762,577.831 724.423,556.584 724.001,555.685 			"/>
        </g>
        <polygon class="st96" points="663.742,548.006 684.415,561.164 690.221,546.067 669.379,534.083 		"/>
        <polygon class="st96" points="691.869,564.32 712.542,577.478 718.348,562.381 697.506,550.397 		"/>
        <g>
          <path class="st46" d="M666.151,569.221c0,0.041,0.012,0.142,0.119,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            s0.397-0.051,0.572-0.147l0.032-0.018c0.072-0.04,0.114-0.112,0.117-0.194h0.002v-20.05h-1.435V569.221z"/>
          <g>
            <polygon class="st92" points="651.817,555.708 675.049,568.954 684.176,546.677 660.897,533.291 				"/>
            <polygon class="st93" points="652.987,554.946 674.858,567.421 683.035,547.456 661.124,534.862 				"/>
            <g>
              <path class="st94" d="M681.655,546.663l-0.375-0.215l-0.561,1.37l-1.499-0.861l0.56-1.371l-0.375-0.215l-0.56,1.371l-1.5-0.862
                l0.56-1.371l-0.375-0.216l-0.56,1.372l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373l-0.376-0.216
                l-0.56,1.373l-1.503-0.863l0.56-1.373l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216l-0.559,1.374
                l-1.505-0.865l0.559-1.375l-0.376-0.216l-0.559,1.375l-1.506-0.865l0.559-1.376l-0.377-0.216l-0.559,1.376l-1.507-0.866
                l0.559-1.376l-0.377-0.217l-0.559,1.377l-1.508-0.866l0.559-1.377l-0.377-0.217l-0.558,1.377l-1.359-0.781l-0.172,0.424
                l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.693l-1.359-0.779l-0.172,0.423l1.359,0.779
                l-0.686,1.693l-1.359-0.779l-0.172,0.423l1.359,0.779l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.358,0.778l-0.685,1.69
                l-1.358-0.777l-0.171,0.423l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688l-1.358-0.776
                l-0.171,0.422l1.358,0.775l-0.684,1.687l-1.357-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.376,0.215l0.555-1.369
                l1.506,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.555,1.367l0.376,0.214l0.556-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.857l-0.556,1.366l0.375,0.214l0.556-1.366l1.502,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.556-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.375,0.214l0.557-1.363l1.497,0.854l-0.557,1.363l0.374,0.213l0.557-1.362l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.42l-1.378-0.787
                l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.788l0.172-0.42l-1.378-0.789l0.688-1.681l1.379,0.789
                l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421l-1.379-0.791
                l0.69-1.685l1.379,0.792l0.172-0.421l-1.379-0.792l0.69-1.686l1.38,0.792l0.173-0.422l-1.38-0.793L681.655,546.663z
                M677.173,546.302l1.5,0.861l-0.69,1.687l-1.5-0.861L677.173,546.302z M667.898,550.873l-1.503-0.861l0.687-1.688l1.503,0.861
                L667.898,550.873z M668.961,549.401l1.502,0.861l-0.687,1.686l-1.502-0.86L668.961,549.401z M666.02,549.797l-1.504-0.861
                l0.687-1.689l1.504,0.862L666.02,549.797z M665.848,550.219l-0.686,1.687l-1.503-0.86l0.686-1.687L665.848,550.219z
                M666.224,550.434l1.503,0.86l-0.687,1.686l-1.502-0.86L666.224,550.434z M668.102,551.51l1.502,0.86l-0.687,1.685
                l-1.501-0.859L668.102,551.51z M669.979,552.584l1.501,0.859l-0.687,1.684l-1.5-0.859L669.979,552.584z M670.151,552.163
                l0.688-1.686l1.501,0.86l-0.688,1.685L670.151,552.163z M671.01,550.055l0.688-1.687l1.501,0.861l-0.688,1.687L671.01,550.055z
                M670.635,549.84l-1.502-0.861l0.688-1.688l1.502,0.861L670.635,549.84z M668.758,548.764l-1.503-0.861l0.688-1.689
                l1.503,0.862L668.758,548.764z M666.879,547.687l-1.504-0.862l0.687-1.69l1.504,0.863L666.879,547.687z M664.998,546.61
                l-1.505-0.862l0.687-1.691l1.505,0.863L664.998,546.61z M664.827,547.032l-0.687,1.689l-1.505-0.862l0.686-1.689
                L664.827,547.032z M663.968,549.143l-0.686,1.688l-1.504-0.861l0.686-1.688L663.968,549.143z M663.111,551.252l-0.686,1.686
                l-1.504-0.86l0.685-1.687L663.111,551.252z M663.487,551.467l1.503,0.86l-0.686,1.686l-1.503-0.859L663.487,551.467z
                M665.366,552.542l1.502,0.859l-0.686,1.685l-1.502-0.859L665.366,552.542z M667.244,553.617l1.501,0.859l-0.687,1.684
                l-1.501-0.858L667.244,553.617z M669.12,554.69l1.5,0.858l-0.687,1.683l-1.5-0.858L669.12,554.69z M670.996,555.763l1.5,0.858
                l-0.687,1.682l-1.499-0.857L670.996,555.763z M671.168,555.342l0.687-1.684l1.5,0.859l-0.687,1.683L671.168,555.342z
                M672.027,553.237l0.688-1.685l1.5,0.86l-0.688,1.685L672.027,553.237z M672.887,551.13l0.688-1.686l1.5,0.86l-0.688,1.686
                L672.887,551.13z M673.747,549.022l0.689-1.687l1.501,0.861l-0.689,1.687L673.747,549.022z M673.372,548.807l-1.501-0.861
                l0.688-1.688l1.502,0.862L673.372,548.807z M671.495,547.73l-1.502-0.862l0.688-1.689l1.502,0.862L671.495,547.73z
                M669.617,546.653l-1.503-0.862l0.688-1.69l1.504,0.863L669.617,546.653z M667.738,545.576l-1.504-0.863l0.688-1.691
                l1.505,0.864L667.738,545.576z M665.857,544.497l-1.505-0.863l0.687-1.691l1.505,0.864L665.857,544.497z M663.976,543.418
                l-1.506-0.864l0.687-1.692l1.507,0.865L663.976,543.418z M663.804,543.841l-0.687,1.691l-1.506-0.863l0.686-1.692
                L663.804,543.841z M662.945,545.954l-0.686,1.69l-1.506-0.862l0.686-1.69L662.945,545.954z M662.088,548.066l-0.686,1.688
                l-1.505-0.861l0.685-1.689L662.088,548.066z M661.23,550.176l-0.685,1.687l-1.505-0.861l0.685-1.688L661.23,550.176z
                M660.374,552.285l-0.685,1.686l-1.505-0.86l0.685-1.687L660.374,552.285z M660.75,552.5l1.504,0.86l-0.685,1.685l-1.504-0.859
                L660.75,552.5z M662.63,553.575l1.503,0.859l-0.686,1.685l-1.503-0.859L662.63,553.575z M664.508,554.649l1.502,0.859
                l-0.686,1.684l-1.502-0.858L664.508,554.649z M666.386,555.722l1.501,0.858l-0.686,1.683l-1.501-0.857L666.386,555.722z
                M668.262,556.794l1.5,0.858l-0.686,1.682l-1.5-0.857L668.262,556.794z M670.137,557.867l1.499,0.857l-0.687,1.681
                l-1.499-0.856L670.137,557.867z M672.011,558.938l1.498,0.856l-0.687,1.68l-1.498-0.856L672.011,558.938z M672.183,558.517
                l0.687-1.682l1.498,0.857l-0.688,1.681L672.183,558.517z M673.042,556.414l0.688-1.683l1.499,0.858l-0.688,1.682
                L673.042,556.414z M673.901,554.31l0.688-1.684l1.499,0.859l-0.688,1.684L673.901,554.31z M674.762,552.205l0.689-1.686
                l1.499,0.86l-0.689,1.685L674.762,552.205z M675.622,550.098l0.689-1.687l1.499,0.861l-0.689,1.686L675.622,550.098z
                M675.297,545.224l1.501,0.862l-0.689,1.688l-1.501-0.862L675.297,545.224z M673.42,544.146l1.502,0.863l-0.689,1.689
                l-1.502-0.862L673.42,544.146z M671.542,543.067l1.503,0.863l-0.689,1.69l-1.502-0.862L671.542,543.067z M669.662,541.987
                l1.504,0.864l-0.689,1.691l-1.504-0.863L669.662,541.987z M667.781,540.907l1.505,0.864l-0.688,1.691l-1.505-0.864
                L667.781,540.907z M665.899,539.826l1.506,0.865l-0.688,1.692l-1.505-0.864L665.899,539.826z M664.016,538.744l1.507,0.865
                l-0.688,1.693l-1.507-0.865L664.016,538.744z M662.131,537.662l1.508,0.866l-0.688,1.694l-1.508-0.865L662.131,537.662z
                M661.272,539.78l1.508,0.865l-0.687,1.693l-1.507-0.865L661.272,539.78z M660.414,541.896l1.507,0.864l-0.686,1.692
                l-1.507-0.864L660.414,541.896z M659.556,544.012l1.507,0.863l-0.686,1.69l-1.507-0.863L659.556,544.012z M658.699,546.126
                l1.507,0.863l-0.685,1.689l-1.506-0.862L658.699,546.126z M657.842,548.238l1.506,0.862l-0.685,1.688l-1.506-0.861
                L657.842,548.238z M656.986,550.349l1.506,0.861l-0.685,1.687l-1.506-0.86L656.986,550.349z M656.952,555.004l-1.506-0.859
                l0.684-1.686l1.506,0.86L656.952,555.004z M658.833,556.078l-1.505-0.859l0.684-1.686l1.505,0.86L658.833,556.078z
                M660.713,557.151l-1.503-0.858l0.684-1.685l1.504,0.859L660.713,557.151z M662.591,558.223l-1.503-0.858l0.685-1.684
                l1.503,0.858L662.591,558.223z M664.468,559.294l-1.502-0.857l0.685-1.683l1.502,0.858L664.468,559.294z M666.344,560.365
                l-1.5-0.856l0.685-1.682l1.501,0.857L666.344,560.365z M668.219,561.435l-1.5-0.856l0.686-1.681l1.5,0.857L668.219,561.435z
                M670.092,562.504l-1.499-0.855l0.686-1.68l1.499,0.856L670.092,562.504z M671.964,563.573l-1.498-0.855l0.686-1.68
                l1.498,0.855L671.964,563.573z M673.835,564.641l-1.497-0.854l0.687-1.679l1.497,0.855L673.835,564.641z M674.693,562.543
                l-1.497-0.855l0.687-1.68l1.497,0.856L674.693,562.543z M675.552,560.444l-1.497-0.856l0.687-1.681l1.498,0.857
                L675.552,560.444z M676.412,558.343l-1.497-0.857l0.688-1.682l1.498,0.858L676.412,558.343z M677.273,556.241l-1.498-0.858
                l0.688-1.683l1.498,0.858L677.273,556.241z M678.133,554.137l-1.498-0.859l0.689-1.685l1.498,0.859L678.133,554.137z
                M678.995,552.032l-1.498-0.859l0.689-1.686l1.499,0.86L678.995,552.032z M679.857,549.925l-1.499-0.86l0.69-1.687l1.499,0.861
                L679.857,549.925z"/>
            </g>
            <g>
              <polygon class="st92" points="660,558.946 660.751,559.375 669.377,538.167 668.624,537.734 					"/>
            </g>
            <g>
              <polygon class="st92" points="667.317,563.12 668.067,563.548 676.707,542.382 675.956,541.95 					"/>
            </g>
            <g>
              <polygon class="st92" points="656.532,544.949 679.21,557.933 679.554,557.092 656.875,544.103 					"/>
            </g>
          </g>
          <polygon class="st95" points="675.049,568.954 675.937,568.823 684.598,547.576 684.176,546.677 			"/>
        </g>
        <g>
          <path class="st46" d="M694.31,585.321c0,0.041,0.012,0.142,0.12,0.201l0.02,0.011c0.175,0.096,0.372,0.147,0.572,0.147
            c0.2,0,0.397-0.051,0.572-0.147l0.032-0.017c0.072-0.04,0.114-0.112,0.118-0.194h0.002v-20.05h-1.435V585.321z"/>
          <g>
            <polygon class="st92" points="679.976,571.808 703.208,585.054 712.335,562.777 689.056,549.391 				"/>
            <polygon class="st93" points="681.146,571.046 703.016,583.521 711.194,563.556 689.283,550.963 				"/>
            <g>
              <path class="st94" d="M709.814,562.763l-0.375-0.216l-0.561,1.37l-1.499-0.861l0.561-1.371l-0.375-0.216l-0.56,1.371
                l-1.5-0.861l0.56-1.371l-0.375-0.216l-0.56,1.372l-1.501-0.862l0.56-1.372l-0.375-0.216l-0.56,1.372l-1.502-0.863l0.56-1.373
                l-0.376-0.216l-0.56,1.373l-1.503-0.863l0.56-1.373l-0.376-0.216l-0.56,1.374l-1.504-0.864l0.559-1.374l-0.376-0.216
                l-0.559,1.374l-1.505-0.865l0.559-1.375l-0.376-0.216l-0.559,1.375l-1.506-0.865l0.559-1.376l-0.377-0.216l-0.559,1.376
                l-1.507-0.866l0.559-1.376l-0.377-0.217l-0.559,1.376l-1.508-0.866l0.558-1.377l-0.377-0.217l-0.558,1.377l-1.359-0.781
                l-0.172,0.424l1.359,0.781l-0.687,1.695l-1.359-0.78l-0.172,0.424l1.359,0.78l-0.687,1.694l-1.359-0.779l-0.172,0.423
                l1.359,0.779l-0.686,1.693l-1.359-0.779l-0.171,0.423l1.359,0.779l-0.686,1.691l-1.358-0.778l-0.171,0.423l1.358,0.778
                l-0.685,1.69l-1.358-0.777l-0.171,0.422l1.358,0.777l-0.685,1.689l-1.358-0.776l-0.171,0.422l1.358,0.776l-0.684,1.688
                l-1.358-0.776l-0.171,0.422l1.358,0.775l-0.684,1.687l-1.357-0.775l-0.171,0.422l1.357,0.775l-0.555,1.369l0.377,0.215
                l0.555-1.369l1.506,0.859l-0.555,1.368l0.376,0.215l0.555-1.368l1.505,0.859l-0.555,1.367l0.376,0.214l0.555-1.367l1.503,0.858
                l-0.556,1.367l0.376,0.214l0.556-1.367l1.503,0.858l-0.556,1.366l0.375,0.214l0.556-1.366l1.502,0.857l-0.556,1.365
                l0.375,0.214l0.556-1.365l1.5,0.856l-0.556,1.365l0.375,0.214l0.557-1.365l1.5,0.856l-0.557,1.364l0.375,0.214l0.557-1.364
                l1.499,0.855l-0.557,1.363l0.374,0.214l0.557-1.363l1.497,0.855l-0.557,1.363l0.374,0.213l0.557-1.362l1.497,0.854
                l-0.557,1.362l0.374,0.213l0.557-1.362l1.378,0.786l0.172-0.419l-1.378-0.786l0.687-1.678l1.378,0.787l0.172-0.419
                l-1.378-0.787l0.687-1.679l1.378,0.788l0.172-0.42l-1.378-0.788l0.688-1.68l1.378,0.789l0.172-0.42l-1.378-0.789l0.688-1.681
                l1.379,0.789l0.172-0.42l-1.379-0.789l0.689-1.683l1.379,0.79l0.172-0.421l-1.379-0.79l0.689-1.684l1.379,0.791l0.172-0.421
                l-1.379-0.791l0.69-1.685l1.379,0.792l0.172-0.421l-1.379-0.792l0.69-1.686l1.38,0.792l0.173-0.421l-1.38-0.793
                L709.814,562.763z M705.332,562.402l1.5,0.861l-0.69,1.687l-1.499-0.861L705.332,562.402z M696.057,566.973l-1.503-0.861
                l0.687-1.688l1.503,0.861L696.057,566.973z M697.12,565.501l1.502,0.861l-0.687,1.686l-1.502-0.86L697.12,565.501z
                M694.179,565.898l-1.504-0.861l0.687-1.689l1.504,0.862L694.179,565.898z M694.007,566.319l-0.686,1.687l-1.503-0.86
                l0.686-1.687L694.007,566.319z M694.383,566.534l1.503,0.86l-0.687,1.686l-1.503-0.86L694.383,566.534z M696.261,567.61
                l1.502,0.86l-0.687,1.685l-1.501-0.859L696.261,567.61z M698.138,568.685l1.501,0.859l-0.687,1.684l-1.5-0.859L698.138,568.685
                z M698.31,568.263l0.688-1.686l1.501,0.86l-0.688,1.685L698.31,568.263z M699.169,566.155l0.688-1.687l1.501,0.861
                l-0.688,1.687L699.169,566.155z M698.794,565.94l-1.502-0.861l0.688-1.688l1.502,0.861L698.794,565.94z M696.917,564.864
                l-1.503-0.861l0.688-1.689l1.503,0.862L696.917,564.864z M695.038,563.787l-1.504-0.862l0.687-1.69l1.504,0.863
                L695.038,563.787z M693.157,562.71l-1.505-0.862l0.687-1.691l1.505,0.863L693.157,562.71z M692.986,563.132l-0.687,1.689
                l-1.505-0.862l0.686-1.69L692.986,563.132z M692.127,565.243l-0.686,1.687l-1.504-0.861l0.686-1.688L692.127,565.243z
                M691.27,567.352l-0.686,1.687l-1.504-0.86l0.685-1.687L691.27,567.352z M691.646,567.568l1.503,0.86l-0.686,1.686
                l-1.503-0.859L691.646,567.568z M693.525,568.643l1.503,0.86l-0.686,1.685l-1.502-0.859L693.525,568.643z M695.403,569.717
                l1.501,0.859l-0.687,1.684l-1.501-0.858L695.403,569.717z M697.279,570.79l1.5,0.858l-0.687,1.683l-1.5-0.858L697.279,570.79z
                M699.155,571.863l1.499,0.858l-0.687,1.682l-1.499-0.857L699.155,571.863z M699.327,571.442l0.687-1.684l1.5,0.859
                l-0.687,1.683L699.327,571.442z M700.186,569.337l0.688-1.685l1.5,0.859l-0.688,1.685L700.186,569.337z M701.046,567.231
                l0.688-1.686l1.5,0.86l-0.689,1.686L701.046,567.231z M701.906,565.122l0.689-1.687l1.501,0.861l-0.689,1.687L701.906,565.122z
                M701.531,564.907l-1.501-0.861l0.688-1.688l1.502,0.862L701.531,564.907z M699.654,563.831l-1.502-0.861l0.688-1.689
                l1.503,0.862L699.654,563.831z M697.776,562.754l-1.503-0.862l0.688-1.69l1.504,0.863L697.776,562.754z M695.897,561.676
                l-1.504-0.863l0.688-1.691l1.505,0.864L695.897,561.676z M694.016,560.597l-1.505-0.863l0.687-1.692l1.505,0.864
                L694.016,560.597z M692.135,559.518l-1.506-0.864l0.687-1.693l1.507,0.865L692.135,559.518z M691.963,559.941l-0.687,1.691
                l-1.506-0.863l0.687-1.692L691.963,559.941z M691.104,562.054l-0.686,1.69l-1.506-0.862l0.686-1.69L691.104,562.054z
                M690.247,564.166l-0.686,1.688l-1.505-0.861l0.686-1.689L690.247,564.166z M689.389,566.277l-0.685,1.687l-1.505-0.86
                l0.685-1.688L689.389,566.277z M688.533,568.386l-0.685,1.686l-1.505-0.86l0.685-1.687L688.533,568.386z M688.909,568.601
                l1.504,0.86l-0.685,1.685l-1.504-0.859L688.909,568.601z M690.789,569.675l1.503,0.859l-0.686,1.685l-1.503-0.858
                L690.789,569.675z M692.667,570.749l1.502,0.859l-0.686,1.684l-1.502-0.858L692.667,570.749z M694.545,571.822l1.501,0.858
                l-0.686,1.683l-1.501-0.857L694.545,571.822z M696.421,572.895l1.5,0.858l-0.686,1.682l-1.5-0.857L696.421,572.895z
                M698.296,573.967l1.499,0.857l-0.687,1.681l-1.499-0.856L698.296,573.967z M700.17,575.038l1.498,0.856l-0.687,1.68
                l-1.498-0.856L700.17,575.038z M700.342,574.617l0.687-1.682l1.498,0.857l-0.688,1.681L700.342,574.617z M701.201,572.515
                l0.688-1.683l1.498,0.858l-0.688,1.682L701.201,572.515z M702.06,570.411l0.688-1.684l1.499,0.859l-0.688,1.684L702.06,570.411
                z M702.921,568.305l0.688-1.686l1.499,0.86l-0.689,1.685L702.921,568.305z M703.781,566.198l0.689-1.687l1.499,0.861
                l-0.689,1.686L703.781,566.198z M703.456,561.324l1.501,0.862l-0.689,1.688l-1.501-0.861L703.456,561.324z M701.579,560.246
                l1.502,0.863l-0.689,1.689l-1.502-0.862L701.579,560.246z M699.701,559.167l1.503,0.863l-0.689,1.69l-1.502-0.862
                L699.701,559.167z M697.821,558.088l1.504,0.864l-0.689,1.691l-1.504-0.863L697.821,558.088z M695.94,557.007l1.505,0.864
                l-0.688,1.691l-1.505-0.864L695.94,557.007z M694.058,555.926l1.506,0.865l-0.688,1.692l-1.505-0.864L694.058,555.926z
                M692.175,554.845l1.507,0.865l-0.688,1.693l-1.507-0.865L692.175,554.845z M690.29,553.762l1.508,0.866l-0.687,1.694
                l-1.508-0.865L690.29,553.762z M689.431,555.88l1.508,0.865l-0.687,1.693l-1.507-0.865L689.431,555.88z M688.573,557.997
                l1.507,0.864l-0.686,1.692l-1.507-0.864L688.573,557.997z M687.715,560.112l1.507,0.864l-0.686,1.69l-1.507-0.863
                L687.715,560.112z M686.858,562.226l1.507,0.862l-0.685,1.689l-1.507-0.862L686.858,562.226z M686.001,564.338l1.506,0.862
                l-0.685,1.688l-1.506-0.861L686.001,564.338z M685.145,566.449l1.506,0.861l-0.685,1.687l-1.506-0.86L685.145,566.449z
                M685.111,571.104l-1.506-0.859l0.684-1.686l1.506,0.86L685.111,571.104z M686.992,572.178l-1.505-0.859l0.684-1.686
                l1.505,0.86L686.992,572.178z M688.872,573.251l-1.503-0.858l0.684-1.685l1.504,0.859L688.872,573.251z M690.75,574.323
                l-1.503-0.858l0.685-1.684l1.503,0.858L690.75,574.323z M692.627,575.395l-1.502-0.857l0.685-1.683l1.502,0.858
                L692.627,575.395z M694.503,576.465l-1.501-0.857l0.685-1.682l1.501,0.857L694.503,576.465z M696.378,577.535l-1.5-0.856
                l0.686-1.681l1.5,0.857L696.378,577.535z M698.251,578.605l-1.499-0.856l0.686-1.68l1.499,0.856L698.251,578.605z
                M700.123,579.673l-1.497-0.855l0.686-1.68l1.498,0.855L700.123,579.673z M701.994,580.741l-1.497-0.854l0.686-1.679
                l1.497,0.855L701.994,580.741z M702.852,578.643l-1.497-0.855l0.687-1.68l1.497,0.856L702.852,578.643z M703.712,576.544
                l-1.497-0.856l0.688-1.681l1.498,0.857L703.712,576.544z M704.571,574.443l-1.497-0.857l0.688-1.682l1.498,0.857
                L704.571,574.443z M705.431,572.341l-1.498-0.858l0.688-1.684l1.498,0.858L705.431,572.341z M706.292,570.237l-1.498-0.859
                l0.689-1.685l1.498,0.859L706.292,570.237z M707.154,568.132l-1.498-0.859l0.689-1.686l1.499,0.86L707.154,568.132z
                M708.016,566.026l-1.499-0.86l0.69-1.687l1.499,0.861L708.016,566.026z"/>
            </g>
            <g>
              <polygon class="st92" points="688.159,575.047 688.91,575.475 697.536,554.267 696.783,553.835 					"/>
            </g>
            <g>
              <polygon class="st92" points="695.476,579.221 696.226,579.648 704.866,558.482 704.115,558.05 					"/>
            </g>
            <g>
              <polygon class="st92" points="684.691,561.049 707.368,574.033 707.713,573.192 685.033,560.204 					"/>
            </g>
          </g>
          <polygon class="st95" points="703.208,585.054 704.095,584.923 712.757,563.676 712.335,562.777 			"/>
        </g>
      </g>
      <g>
        <path class="st42" d="M435.175,216.48l-0.35-0.929c34.4-12.978,35.6-60.258,35.607-60.734l0.993,0.016
          C471.418,155.316,470.21,203.263,435.175,216.48z"/>
      </g>
      <g>
        <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="639.0584" y1="569.4503" x2="679.7325" y2="569.4503">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st97" points="639.694,568.273 639.058,570.627 679.733,570.627 678.595,568.273 		"/>
        <g>
          <path class="st98" d="M638.153,570.05h0.002c0,0.005-0.002,0.009-0.002,0.013c0,0.06,0.023,0.21,0.222,0.298l0.037,0.016
            c0.324,0.142,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026c0.139-0.061,0.222-0.174,0.222-0.301h0v-0.003
            v-0.003v-0.001v-71.006h-2.663V570.05z"/>
          <path class="st99" d="M640.602,498.728c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.747,0.077-1.074,0.224l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.22,0.302c0,0.06,0.023,0.21,0.221,0.298l0.037,0.016c0.324,0.143,0.691,0.218,1.061,0.218
            c0.37,0,0.737-0.075,1.061-0.218l0.06-0.026c0.139-0.061,0.222-0.174,0.222-0.301l0-0.006l-0.001-0.008
            C640.81,498.891,640.732,498.789,640.602,498.728z"/>
        </g>
        <g>
          <polygon class="st99" points="628.81,514.503 629.764,515.054 650.79,502.871 649.873,502.342 			"/>
          <polygon class="st100" points="629.764,515.978 650.79,503.831 650.79,502.871 629.764,515.054 			"/>
          <polygon class="st99" points="628.81,515.381 629.764,515.978 629.764,515.054 628.81,514.503 			"/>
        </g>
        <path class="st101" d="M631.064,512.664l0-0.005c-0.002-0.074-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L631.064,512.664z"/>
        <path class="st101" d="M634.325,510.542c-0.143-0.097-0.307-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.06-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C634.413,510.643,634.38,510.579,634.325,510.542z"/>
        <path class="st101" d="M645.984,504.098l0-0.005c-0.002-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.306-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136s0.315-0.047,0.453-0.136l0.026-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L645.984,504.098z"/>
        <path class="st101" d="M649.245,501.976c-0.143-0.097-0.306-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.06-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C649.333,502.077,649.3,502.013,649.245,501.976z"/>
      </g>
      <g>
        <path class="st42" d="M447.308,233.946l-0.479-0.87c35.745-19.704,28.362-69.663,28.284-70.165l0.982-0.154
          C476.174,163.266,483.657,213.909,447.308,233.946z"/>
      </g>
      <g>
        <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="727.7717" y1="274.4017" x2="768.4459" y2="274.4017">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st102" points="728.407,273.225 727.772,275.579 768.446,275.579 767.308,273.225 		"/>
        <g>
          <path class="st98" d="M729.103,204.246h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.221,0.298
            l0.038,0.016c0.324,0.142,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.06-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301v-0.003l0-0.003l0-0.001V204.246z"/>
          <path class="st99" d="M728.89,203.936c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.746,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.22,0.302c0,0.06,0.023,0.21,0.221,0.298l0.038,0.016c0.324,0.143,0.691,0.218,1.061,0.218
            s0.737-0.075,1.061-0.218l0.06-0.026c0.139-0.061,0.222-0.174,0.222-0.301l0-0.006l0-0.008
            C729.097,204.1,729.02,203.997,728.89,203.936z"/>
        </g>
        <g>
          <polygon class="st99" points="717.097,219.711 718.052,220.262 739.078,208.079 738.161,207.55 			"/>
          <polygon class="st100" points="718.052,221.186 739.078,209.04 739.078,208.079 718.052,220.262 			"/>
          <polygon class="st99" points="717.097,220.589 718.052,221.186 718.052,220.262 717.097,219.711 			"/>
        </g>
        <path class="st101" d="M719.351,217.872l0-0.005c-0.002-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.149-0.472-0.149
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V217.872z"/>
        <path class="st101" d="M722.703,215.931l0-0.005c-0.002-0.075-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V215.931z"/>
        <path class="st101" d="M734.271,209.307l0-0.005c-0.002-0.075-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.149-0.472-0.149
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L734.271,209.307z"/>
        <path class="st101" d="M737.532,207.184c-0.143-0.097-0.307-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.06-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C737.621,207.286,737.588,207.221,737.532,207.184z"/>
      </g>
      <g>
        <path class="st42" d="M870.499,294.072c-9.842,0-25.056-1.084-42.197-6.182c-27.392-8.148-65.453-28.502-91.222-79.198
          c-0.087-0.171-0.019-0.381,0.152-0.467c0.171-0.087,0.379-0.02,0.466,0.152c25.652,50.463,63.521,70.726,90.772,78.838
          c29.543,8.794,53.324,5.608,53.561,5.575c0.197-0.028,0.366,0.106,0.393,0.296c0.026,0.19-0.106,0.366-0.296,0.393
          C882.028,293.491,877.701,294.072,870.499,294.072z"/>
      </g>
      <g>
        <path class="st42" d="M852.491,304.48c-9.865,0-25.264-1.087-42.629-6.271c-27.468-8.2-65.621-28.617-91.39-79.313
          c-0.087-0.171-0.019-0.381,0.152-0.467c0.169-0.087,0.379-0.02,0.467,0.152c25.651,50.463,63.611,70.789,90.94,78.953
          c29.625,8.85,53.49,5.722,53.729,5.689c0.198-0.033,0.366,0.107,0.392,0.297c0.026,0.191-0.107,0.366-0.297,0.392
          C863.756,303.925,859.535,304.48,852.491,304.48z"/>
      </g>
      <g>
        <path class="st42" d="M674.801,590.806c-0.023,0-0.047-0.002-0.07-0.007c-14.949-3.084-24.235-19.27-26.852-46.807
          c-1.97-20.717,0.518-40.781,0.543-40.981c0.024-0.19,0.205-0.311,0.389-0.302c0.19,0.024,0.325,0.199,0.302,0.389
          c-0.101,0.796-9.672,79.709,25.758,87.019c0.188,0.039,0.309,0.223,0.271,0.41C675.108,590.694,674.963,590.806,674.801,590.806z"
          />
      </g>
      <g>
        <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="872.6074" y1="359.6797" x2="913.2816" y2="359.6797">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st103" points="873.242,358.503 872.607,360.857 913.282,360.857 912.144,358.503 		"/>
        <g>
          <path class="st98" d="M874.13,289.299h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.221,0.298
            l0.038,0.016c0.324,0.143,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301h0v-0.003v-0.003v-0.001V289.299z"/>
          <path class="st99" d="M873.916,288.99c-0.335-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.746,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.22,0.302c0,0.06,0.023,0.21,0.221,0.298l0.038,0.016c0.324,0.142,0.691,0.218,1.061,0.218
            s0.737-0.075,1.061-0.218l0.059-0.026c0.139-0.061,0.222-0.174,0.222-0.301v-0.006l-0.001-0.008
            C874.124,289.153,874.046,289.05,873.916,288.99z"/>
        </g>
        <g>
          <polygon class="st99" points="862.124,304.765 863.078,305.316 884.104,293.133 883.187,292.604 			"/>
          <polygon class="st100" points="863.078,306.24 884.104,294.093 884.104,293.133 863.078,305.316 			"/>
          <polygon class="st99" points="862.124,305.643 863.078,306.24 863.078,305.316 862.124,304.765 			"/>
        </g>
        <path class="st101" d="M864.377,302.926l0-0.005c-0.002-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V302.926z"/>
        <path class="st101" d="M867.73,300.985l0-0.005c-0.002-0.074-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L867.73,300.985z"/>
        <path class="st101" d="M879.297,294.36l0-0.005c-0.002-0.074-0.035-0.138-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L879.297,294.36z"/>
        <path class="st101" d="M882.65,292.419l0-0.005c-0.002-0.075-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.038-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.026-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V292.419z"/>
      </g>
      <g>
        <path class="st42" d="M655.079,601.527c-2.23,0-4.349-0.833-6.317-2.487c-7.02-5.899-12.287-22.091-15.652-48.125
          c-2.501-19.345-2.959-37.199-2.963-37.377c-0.005-0.192,0.147-0.351,0.339-0.356c0.173,0.021,0.352,0.146,0.356,0.339
          c0.018,0.71,1.918,71.164,18.368,84.987c2.21,1.856,4.611,2.601,7.146,2.227c0.197-0.03,0.368,0.103,0.395,0.292
          c0.029,0.19-0.102,0.367-0.292,0.395C655.995,601.492,655.534,601.527,655.079,601.527z"/>
      </g>
      <g>
        <path class="st42" d="M983.155,379.552c-7.859,0-19.57-0.955-33.266-5.126c-23.199-7.065-57.015-25.059-86.372-70.671
          c-0.105-0.161-0.057-0.376,0.104-0.481c0.16-0.102,0.376-0.058,0.48,0.104c29.244,45.434,62.904,63.352,85.991,70.382
          c24.995,7.611,43.295,4.455,43.476,4.423c0.196-0.037,0.371,0.091,0.404,0.28c0.034,0.189-0.092,0.37-0.28,0.404
          C993.608,378.884,989.729,379.552,983.155,379.552z"/>
      </g>
      <g>
        <path class="st42" d="M991.069,370.845c-7.261,0-15.989-0.753-25.495-3.117c-24.006-5.972-58.03-23.734-83.803-74.437
          c-0.087-0.171-0.019-0.381,0.152-0.467c0.17-0.086,0.379-0.02,0.466,0.152c14.16,27.857,40.115,63.321,83.352,74.077
          c25.846,6.431,45.866,0.871,46.066,0.815c0.18-0.056,0.377,0.055,0.43,0.239c0.052,0.185-0.054,0.377-0.24,0.43
          C1011.871,368.572,1003.685,370.845,991.069,370.845z"/>
      </g>
      <g>
        <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="1002.1428" y1="434.639" x2="1042.8171" y2="434.639">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st104" points="1002.778,433.462 1002.143,435.816 1042.817,435.816 1041.679,433.462 		"/>
        <g>
          <path class="st98" d="M1003.952,364.259h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.221,0.298
            l0.038,0.016c0.324,0.143,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.06-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301v-0.003v-0.003v-0.001V364.259z"/>
          <path class="st99" d="M1003.738,363.949c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.747,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.22,0.302c0,0.06,0.023,0.21,0.221,0.298l0.038,0.016c0.324,0.143,0.691,0.218,1.061,0.218
            c0.37,0,0.737-0.075,1.061-0.218l0.06-0.026c0.139-0.061,0.222-0.174,0.222-0.301v-0.006l0-0.008
            C1003.946,364.112,1003.868,364.01,1003.738,363.949z"/>
        </g>
        <g>
          <polygon class="st99" points="991.946,379.724 992.9,380.275 1013.926,368.092 1013.009,367.563 			"/>
          <polygon class="st100" points="992.9,381.199 1013.926,369.052 1013.926,368.092 992.9,380.275 			"/>
          <polygon class="st99" points="991.946,380.602 992.9,381.199 992.9,380.275 991.946,379.724 			"/>
        </g>
        <path class="st101" d="M994.199,377.885l0-0.005c-0.002-0.074-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.038-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.026-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V377.885z"/>
        <path class="st101" d="M997.552,375.944l0-0.005c-0.002-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.306-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L997.552,375.944z"/>
        <path class="st101" d="M1009.119,369.319l0-0.005c-0.002-0.074-0.035-0.138-0.091-0.176c-0.143-0.097-0.306-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.026-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V369.319z"/>
        <path class="st101" d="M1012.38,367.197c-0.143-0.097-0.307-0.149-0.472-0.149c-0.16,0-0.319,0.048-0.458,0.139l-0.021,0.014
          c-0.059,0.038-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.06-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C1012.469,367.298,1012.436,367.234,1012.38,367.197z"/>
      </g>
      <g>
        <path class="st42" d="M1130.197,465.079c-9.969,0-26.198-1.095-44.555-6.628c-27.894-8.408-66.553-29.071-92.322-79.768
          c-0.087-0.171-0.019-0.381,0.152-0.467c0.17-0.087,0.379-0.02,0.467,0.152c25.651,50.465,64.119,71.036,91.874,79.409
          c30.085,9.075,54.421,6.174,54.664,6.142c0.212-0.025,0.366,0.112,0.389,0.302c0.023,0.191-0.111,0.365-0.302,0.389
          C1140.469,464.622,1136.64,465.079,1130.197,465.079z"/>
      </g>
      <g>
        <g>
          <path class="st98" d="M666.728,586.586h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.222,0.298
            l0.037,0.016c0.324,0.142,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301l0,0v-0.003l0-0.003l0-0.001V586.586z"/>
          <path class="st99" d="M666.514,586.277c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.746,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.221,0.302c0,0.06,0.023,0.21,0.222,0.298l0.037,0.016c0.324,0.143,0.691,0.218,1.061,0.218
            c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026c0.139-0.061,0.222-0.174,0.222-0.301l0-0.006l0-0.008
            C666.722,586.44,666.644,586.337,666.514,586.277z"/>
        </g>
        <g>
          <polygon class="st99" points="654.722,602.052 655.676,602.603 676.703,590.42 675.785,589.891 			"/>
          <polygon class="st100" points="655.676,603.527 676.703,591.38 676.703,590.42 655.676,602.603 			"/>
          <polygon class="st99" points="654.722,602.93 655.676,603.527 655.676,602.603 654.722,602.052 			"/>
        </g>
        <path class="st101" d="M656.976,600.213l0-0.005c-0.002-0.074-0.036-0.138-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V600.213z"/>
        <path class="st101" d="M660.237,598.09c-0.143-0.097-0.306-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.059-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C660.325,598.192,660.292,598.128,660.237,598.09z"/>
        <path class="st101" d="M671.896,591.647l0-0.005c-0.002-0.074-0.036-0.138-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136s0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L671.896,591.647z"/>
        <path class="st101" d="M675.248,589.706l0-0.005c-0.002-0.074-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.149-0.472-0.149
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L675.248,589.706z"/>
      </g>
      <g>
        <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="665.0462" y1="656.9653" x2="705.7204" y2="656.9653">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st105" points="665.681,655.788 665.046,658.142 705.72,658.142 704.583,655.788 		"/>
        <g>
          <path class="st98" d="M666.728,586.586h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.222,0.298
            l0.037,0.016c0.324,0.142,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301l0,0v-0.003l0-0.003l0-0.001V586.586z"/>
          <path class="st99" d="M666.514,586.277c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.746,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.221,0.302c0,0.06,0.023,0.21,0.222,0.298l0.037,0.016c0.324,0.143,0.691,0.218,1.061,0.218
            c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026c0.139-0.061,0.222-0.174,0.222-0.301l0-0.006l0-0.008
            C666.722,586.44,666.644,586.337,666.514,586.277z"/>
        </g>
        <g>
          <polygon class="st99" points="654.722,602.052 655.676,602.603 676.703,590.42 675.785,589.891 			"/>
          <polygon class="st100" points="655.676,603.527 676.703,591.38 676.703,590.42 655.676,602.603 			"/>
          <polygon class="st99" points="654.722,602.93 655.676,603.527 655.676,602.603 654.722,602.052 			"/>
        </g>
        <path class="st101" d="M656.976,600.213l0-0.005c-0.002-0.074-0.036-0.138-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V600.213z"/>
        <path class="st101" d="M660.237,598.09c-0.143-0.097-0.306-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.059-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C660.325,598.192,660.292,598.128,660.237,598.09z"/>
        <path class="st101" d="M671.896,591.647l0-0.005c-0.002-0.074-0.036-0.138-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136s0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L671.896,591.647z"/>
        <path class="st101" d="M675.248,589.706l0-0.005c-0.002-0.074-0.035-0.139-0.091-0.176c-0.143-0.097-0.307-0.149-0.472-0.149
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.06-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L675.248,589.706z"/>
      </g>
      <g>
        <path class="st42" d="M1148.166,454.374c-10.038,0-26.047-1.092-44.106-6.46c-27.96-8.311-66.698-28.859-92.467-79.555
          c-0.087-0.171-0.019-0.381,0.152-0.467c0.169-0.086,0.379-0.019,0.466,0.152c25.652,50.464,64.198,70.921,92.018,79.196
          c30.159,8.97,54.566,5.963,54.808,5.931c0.207-0.024,0.365,0.11,0.389,0.3c0.025,0.19-0.11,0.365-0.3,0.389
          C1159.029,453.872,1154.967,454.374,1148.166,454.374z"/>
      </g>
      <g>
        <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="1149.2969" y1="520.3531" x2="1189.9709" y2="520.3531">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st106" points="1149.932,519.176 1149.297,521.53 1189.971,521.53 1188.833,519.176 		"/>
        <g>
          <path class="st98" d="M1151.132,449.997h-2.663v71.012h0.002c0,0.004-0.002,0.008-0.002,0.013c0,0.06,0.023,0.21,0.221,0.298
            l0.038,0.016c0.324,0.142,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026
            c0.139-0.061,0.222-0.174,0.222-0.301h0v-0.003v-0.003v-0.001V449.997z"/>
          <path class="st99" d="M1150.918,449.688c-0.336-0.156-0.718-0.238-1.106-0.238c-0.375,0-0.746,0.077-1.074,0.223l-0.049,0.022
            c-0.139,0.062-0.221,0.175-0.22,0.302c0,0.06,0.023,0.21,0.221,0.298l0.038,0.016c0.324,0.142,0.691,0.218,1.061,0.218
            c0.37,0,0.737-0.075,1.061-0.218l0.059-0.026c0.139-0.061,0.222-0.174,0.222-0.301l0-0.006l-0.001-0.008
            C1151.126,449.851,1151.048,449.748,1150.918,449.688z"/>
        </g>
        <g>
          <polygon class="st99" points="1139.126,465.462 1140.08,466.013 1161.107,453.831 1160.189,453.301 			"/>
          <polygon class="st100" points="1140.08,466.937 1161.107,454.791 1161.107,453.831 1140.08,466.013 			"/>
          <polygon class="st99" points="1139.126,466.341 1140.08,466.937 1140.08,466.013 1139.126,465.462 			"/>
        </g>
        <path class="st101" d="M1141.38,463.624l0-0.005c-0.002-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.038-0.094,0.109-0.094,0.188v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.094,0.186l0.016,0.01c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V463.624z"/>
        <path class="st101" d="M1144.641,461.501c-0.143-0.097-0.307-0.148-0.472-0.148c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014
          c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01
          c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016c0.059-0.038,0.095-0.108,0.095-0.188v-0.004
          v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008v-0.004l0-0.005C1144.729,461.603,1144.696,461.539,1144.641,461.501z"/>
        <path class="st101" d="M1156.3,455.058l0-0.005c-0.003-0.074-0.036-0.139-0.091-0.176c-0.143-0.097-0.307-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.094,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.139,0.089,0.295,0.136,0.453,0.136s0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188v-0.004v-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008V455.058z"/>
        <path class="st101" d="M1159.652,453.116l0-0.005c-0.002-0.074-0.036-0.138-0.091-0.176c-0.143-0.097-0.306-0.148-0.472-0.148
          c-0.16,0-0.319,0.048-0.459,0.139l-0.021,0.014c-0.059,0.039-0.095,0.109-0.094,0.189v1.26h0.001c0,0.003-0.001,0.005-0.001,0.008
          c0,0.038,0.01,0.131,0.095,0.186l0.016,0.01c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.025-0.016
          c0.059-0.038,0.095-0.108,0.095-0.188l0-0.004l0-0.001v-1.255h-0.001c0-0.003,0.001-0.005,0.001-0.008L1159.652,453.116z"/>
      </g>
      <g>
        <path class="st42" d="M1115.204,545.669c-2.756,0-5.697-0.975-8.797-2.92c-0.162-0.103-0.211-0.317-0.11-0.48
          c0.104-0.162,0.315-0.211,0.48-0.11c5.022,3.155,9.585,3.655,13.563,1.487c19.111-10.423,20.115-78.476,20.123-79.161
          c0.002-0.191,0.157-0.343,0.347-0.343c0.001,0,0.003,0,0.004,0c0.192,0.002,0.346,0.159,0.343,0.351
          c-0.002,0.173-0.228,17.441-2.838,35.786c-3.516,24.713-9.453,39.509-17.647,43.978
          C1118.945,545.198,1117.119,545.669,1115.204,545.669z"/>
      </g>
      <g>
        <path class="st42" d="M1130.688,554.086c-1.888,0-3.858-0.247-5.906-0.745c-0.187-0.045-0.302-0.233-0.256-0.419
          c0.045-0.186,0.237-0.3,0.419-0.256c6.999,1.697,13.054,0.402,17.993-3.856c22.499-19.387,16.055-94.195,15.988-94.948
          c-0.017-0.191,0.124-0.36,0.315-0.377c0.213-0.021,0.36,0.125,0.377,0.315c0.068,0.757,6.542,75.917-16.226,95.537
          C1139.723,552.498,1135.467,554.086,1130.688,554.086z"/>
      </g>
      <g>
        <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="1115.4672" y1="608.8921" x2="1156.1414" y2="608.8921">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st107" points="1116.102,607.715 1115.467,610.069 1156.141,610.069 1155.004,607.715 		"/>
        <g>
          <path class="st98" d="M1117.206,609.524v-71.012h-2.663v71.005v0.001v0.003v0.003l0,0c0,0.128,0.083,0.24,0.222,0.301
            l0.059,0.026c0.324,0.143,0.691,0.218,1.062,0.218c0.37,0,0.737-0.075,1.061-0.218l0.038-0.016
            c0.198-0.088,0.221-0.237,0.221-0.298c0-0.004-0.002-0.009-0.002-0.013H1117.206z"/>
          <path class="st99" d="M1116.986,538.21l-0.049-0.022c-0.328-0.146-0.699-0.223-1.074-0.223c-0.388,0-0.77,0.082-1.106,0.238
            c-0.13,0.06-0.208,0.163-0.213,0.282l0,0.008v0.006c0,0.127,0.083,0.24,0.222,0.301l0.059,0.026
            c0.324,0.143,0.692,0.218,1.062,0.218s0.737-0.075,1.061-0.218l0.038-0.016c0.198-0.087,0.221-0.237,0.221-0.298
            C1117.207,538.385,1117.125,538.271,1116.986,538.21z"/>
        </g>
        <g>
          <polygon class="st99" points="1104.569,542.345 1125.594,554.528 1126.549,553.977 1105.486,541.816 			"/>
          <polygon class="st100" points="1104.569,543.306 1125.594,555.452 1125.594,554.528 1104.569,542.345 			"/>
          <polygon class="st99" points="1125.594,555.452 1126.549,554.855 1126.549,553.977 1125.594,554.528 			"/>
        </g>
        <path class="st101" d="M1125.318,551.948c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.256v0.001v0.004
          c0,0.08,0.036,0.15,0.095,0.188l0.025,0.016c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L1125.318,551.948z"/>
        <path class="st101" d="M1121.965,550.007c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.026,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.08-0.035-0.15-0.094-0.188
          L1121.965,550.007z"/>
        <path class="st101" d="M1110.398,543.382c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.025,0.016c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.189
          L1110.398,543.382z"/>
        <path class="st101" d="M1107.045,541.441c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005l0,0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255l0,0.001l0,0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.026,0.016c0.138,0.089,0.295,0.136,0.453,0.136s0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.095-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.189
          L1107.045,541.441z"/>
      </g>
      <g>
        <path class="st42" d="M968.252,629.069c-3.482,0-7.032-0.118-10.653-0.366c-0.192-0.014-0.337-0.18-0.324-0.371
          c0.014-0.192,0.177-0.319,0.37-0.324c52.403,3.588,90.023-19.906,112.35-40.239c24.208-22.045,36.169-45.242,36.287-45.474
          c0.086-0.172,0.298-0.239,0.468-0.151c0.171,0.086,0.239,0.296,0.151,0.467c-0.118,0.232-12.125,23.525-36.419,45.656
          C1049.595,607.294,1015.385,629.069,968.252,629.069z"/>
      </g>
      <g>
        <path class="st42" d="M986.51,639.503c-3.478,0-7.021-0.119-10.64-0.366c-0.192-0.014-0.337-0.179-0.324-0.37
          c0.014-0.192,0.18-0.327,0.37-0.324c52.422,3.589,89.916-19.935,112.155-40.292c24.112-22.073,35.975-45.296,36.092-45.528
          c0.086-0.172,0.3-0.24,0.467-0.154c0.172,0.086,0.241,0.296,0.154,0.467c-0.117,0.234-12.027,23.553-36.225,45.711
          C1067.754,617.698,1033.635,639.503,986.51,639.503z"/>
      </g>
      <g>
        <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="966.4304" y1="694.9026" x2="1007.1046" y2="694.9026">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st108" points="967.065,693.726 966.43,696.08 1007.105,696.08 1005.967,693.726 		"/>
        <g>
          <path class="st98" d="M968.232,695.535v-71.012h-2.663v71.006v0.001v0.003v0.003l0,0c0,0.127,0.083,0.24,0.222,0.301l0.059,0.026
            c0.324,0.142,0.691,0.218,1.062,0.218c0.37,0,0.737-0.075,1.061-0.218l0.038-0.016c0.199-0.088,0.221-0.237,0.221-0.298
            c0-0.004-0.002-0.009-0.002-0.013H968.232z"/>
          <path class="st99" d="M968.011,624.22l-0.049-0.022c-0.328-0.146-0.699-0.223-1.074-0.223c-0.388,0-0.77,0.082-1.106,0.238
            c-0.13,0.06-0.208,0.163-0.214,0.283l0,0.008v0.006c0,0.127,0.083,0.24,0.222,0.301l0.059,0.026
            c0.324,0.143,0.692,0.218,1.062,0.218s0.737-0.075,1.061-0.218l0.038-0.016c0.199-0.088,0.221-0.237,0.221-0.298
            C968.232,624.395,968.15,624.282,968.011,624.22z"/>
        </g>
        <g>
          <polygon class="st99" points="955.594,628.356 976.62,640.539 977.574,639.987 956.511,627.826 			"/>
          <polygon class="st100" points="955.594,629.316 976.62,641.463 976.62,640.539 955.594,628.356 			"/>
          <polygon class="st99" points="976.62,641.463 977.574,640.866 977.574,639.987 976.62,640.539 			"/>
        </g>
        <path class="st101" d="M976.343,637.958c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.025,0.016c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L976.343,637.958z"/>
        <path class="st101" d="M972.991,636.017c-0.14-0.091-0.299-0.139-0.459-0.139c-0.165,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.08,0.036,0.15,0.095,0.188l0.026,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L972.991,636.017z"/>
        <path class="st101" d="M961.423,629.393c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.025,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L961.423,629.393z"/>
        <path class="st101" d="M958.071,627.451c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005l0,0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255l0,0.001l0,0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.026,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.095-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L958.071,627.451z"/>
      </g>
      <g>
        <path class="st42" d="M847.318,700.733c-4.598,0-9.244-0.443-13.917-1.41c-0.187-0.039-0.308-0.223-0.27-0.411
          c0.039-0.187,0.217-0.307,0.411-0.27c32.906,6.809,64.733-12.603,85.635-30.088c22.678-18.972,37.742-39.821,37.892-40.029
          c0.112-0.156,0.332-0.193,0.485-0.079c0.156,0.111,0.192,0.329,0.08,0.485c-0.149,0.21-15.266,21.13-38.01,40.156
          C901.565,684.195,875.38,700.733,847.318,700.733z"/>
      </g>
      <g>
        <path class="st42" d="M865.52,711.234c-4.575,0-9.198-0.441-13.847-1.403c-0.187-0.039-0.308-0.223-0.27-0.411
          c0.039-0.187,0.22-0.309,0.411-0.27c32.907,6.812,64.699-12.723,85.573-30.31c22.647-19.081,37.68-40.042,37.829-40.251
          c0.113-0.156,0.33-0.192,0.485-0.081c0.156,0.112,0.193,0.329,0.081,0.485c-0.149,0.21-15.234,21.242-37.948,40.379
          C919.785,694.58,893.601,711.234,865.52,711.234z"/>
      </g>
      <g>
        <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="842.9246" y1="765.6025" x2="883.5988" y2="765.6025">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st109" points="843.56,764.426 842.925,766.78 883.599,766.78 882.461,764.426 		"/>
        <g>
          <path class="st98" d="M844.086,766.235v-71.012h-2.663v71.006v0.001v0.003v0.003h0c0,0.128,0.083,0.24,0.222,0.301l0.059,0.026
            c0.324,0.143,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.037-0.016c0.199-0.088,0.221-0.238,0.221-0.298
            c0-0.005-0.002-0.009-0.002-0.013H844.086z"/>
          <path class="st99" d="M843.866,694.92l-0.049-0.022c-0.328-0.146-0.699-0.223-1.074-0.223c-0.388,0-0.77,0.082-1.106,0.238
            c-0.13,0.06-0.208,0.163-0.213,0.282l-0.001,0.008l0,0.006c0,0.127,0.083,0.24,0.222,0.301l0.059,0.026
            c0.324,0.143,0.691,0.218,1.061,0.218c0.37,0,0.737-0.075,1.061-0.218l0.037-0.016c0.199-0.088,0.221-0.237,0.221-0.298
            C844.087,695.095,844.004,694.982,843.866,694.92z"/>
        </g>
        <g>
          <polygon class="st99" points="831.448,699.056 852.474,711.238 853.429,710.687 832.365,698.526 			"/>
          <polygon class="st100" points="831.448,700.016 852.474,712.163 852.474,711.238 831.448,699.056 			"/>
          <polygon class="st99" points="852.474,712.163 853.429,711.566 853.429,710.687 852.474,711.238 			"/>
        </g>
        <path class="st101" d="M852.197,708.659c-0.14-0.091-0.299-0.14-0.459-0.14c-0.166,0-0.329,0.051-0.472,0.149
          c-0.056,0.037-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.079,0.036,0.15,0.095,0.188l0.025,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.08-0.035-0.15-0.094-0.189
          L852.197,708.659z"/>
        <path class="st101" d="M848.845,706.717c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005l0,0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255l0,0.001l0,0.004
          c0,0.079,0.035,0.15,0.095,0.188l0.025,0.016c0.139,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L848.845,706.717z"/>
        <path class="st101" d="M837.277,700.093c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.08,0.036,0.15,0.095,0.188l0.025,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.095-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.189
          L837.277,700.093z"/>
        <path class="st101" d="M833.925,698.151c-0.14-0.091-0.299-0.139-0.459-0.139c-0.166,0-0.329,0.051-0.472,0.148
          c-0.056,0.038-0.089,0.102-0.091,0.176l0,0.005v0.004c0,0.003,0.001,0.005,0.001,0.008h-0.001v1.255v0.001v0.004
          c0,0.08,0.035,0.15,0.095,0.188l0.025,0.016c0.138,0.089,0.295,0.136,0.453,0.136c0.158,0,0.315-0.047,0.453-0.136l0.016-0.01
          c0.085-0.055,0.094-0.148,0.094-0.186c0-0.003-0.001-0.005-0.001-0.008h0.001v-1.26c0-0.079-0.035-0.15-0.094-0.188
          L833.925,698.151z"/>
      </g>
      <g>
        <g>
          <polygon class="st110" points="716.979,247.38 782.417,285.113 784.232,284.065 718.833,246.241 			"/>
          <g>
            <path class="st111" d="M662.853,311.641h0.025c0.53,8.023,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-52.183h-60.398V311.641z"/>
            <path class="st112" d="M662.853,305.11h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-44.989h-60.398V305.11z"/>
            <path class="st111" d="M662.853,298.58h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-38.458h-60.398V298.58z"/>
            <path class="st112" d="M662.853,292.05h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-32.592h-60.398V292.05z"/>
            <path class="st111" d="M662.853,285.519h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-25.398h-60.398V285.519z"/>
            <path class="st112" d="M662.853,278.989h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-19.532h-60.398V278.989z"/>
            <path class="st111" d="M662.853,272.459h0.025c0.53,8.024,13.831,14.449,30.174,14.449c16.343,0,29.644-6.425,30.174-14.449
              h0.025v-12.337h-60.398V272.459z"/>
            <path class="st113" d="M693.052,242.21c-16.678,0-30.199,8.993-30.199,17.247s13.521,14.944,30.199,14.944
              c16.678,0,30.199-6.691,30.199-14.944S709.73,242.21,693.052,242.21z"/>
          </g>
          <path class="st57" d="M693.052,242.21c-1.093,0-2.171,0.041-3.233,0.115c2.944,3.438,4.762,7.867,4.762,12.385v48.31
            c0,10.605-8.668,18.458-18.917,18.917c-1.47,0.066-2.907-0.08-4.294-0.398c5.487,2.804,13.169,4.55,21.681,4.55
            c16.343,0,29.644-6.425,30.174-14.449h0.025v-52.183C723.251,251.204,709.731,242.21,693.052,242.21z"/>
          <g>
            <rect x="725.038" y="247.396" class="st114" width="22.277" height="103.613"/>
            <path class="st115" d="M736.177,241.035c-6.152,0-11.138,3.317-11.138,6.361c0,3.044,4.987,5.512,11.138,5.512
              c6.151,0,11.138-2.468,11.138-5.512C747.315,244.352,742.328,241.035,736.177,241.035z"/>
            <path class="st116" d="M736.177,328.969c-6.152,0-11.138,18.814-11.138,21.858c0,3.044,4.987,5.512,11.138,5.512
              c6.151,0,11.138-2.468,11.138-5.512C747.315,347.782,742.328,328.969,736.177,328.969z"/>
          </g>
          <path class="st57" d="M736.177,241.035c-0.765,0-1.511,0.052-2.232,0.147c1.559,1.543,2.544,3.665,2.544,5.962v107.679
            c0,0.515-0.056,1.019-0.149,1.512c5.951-0.043,10.762-2.393,10.957-5.325h0.019V247.396
            C747.315,244.352,742.328,241.035,736.177,241.035z"/>
          <g>
            <polygon class="st117" points="716.894,307.157 718.71,308.281 718.71,248.445 716.894,247.396 				"/>
            <polygon class="st118" points="718.71,308.281 720.525,307.157 720.525,247.396 718.71,248.445 				"/>
            <polygon class="st117" points="696.753,292.506 698.568,293.629 698.568,259.943 696.753,258.895 				"/>
            <polygon class="st118" points="698.568,293.629 700.384,292.506 700.384,258.895 698.568,259.943 				"/>
            <polygon class="st67" points="762.191,366.067 764.006,364.944 764.006,295.579 762.191,296.628 				"/>
            <polygon class="st117" points="760.375,364.944 762.191,366.067 762.191,296.628 760.375,295.579 				"/>
            <polygon class="st67" points="782.363,354.59 784.178,353.466 784.178,284.102 782.363,285.15 				"/>
            <polygon class="st117" points="780.547,353.466 782.363,354.59 782.363,285.15 780.547,284.102 				"/>
            <polygon class="st110" points="696.753,258.895 762.191,296.628 764.006,295.579 698.607,257.756 				"/>
          </g>
          <g>
            <path class="st119" d="M669.403,335.112h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-49.821
              h-29.024V335.112z"/>
            <path class="st115" d="M669.403,320.126h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-33.603
              h-29.024V320.126z"/>
            <path class="st119" d="M669.403,303.17h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-16.648
              h-29.024V303.17z"/>
            <path class="st120" d="M683.915,277.003c-8.015,0-14.512,4.322-14.512,8.288c0,3.966,6.497,7.181,14.512,7.181
              c8.015,0,14.512-3.215,14.512-7.181C698.427,281.324,691.93,277.003,683.915,277.003z"/>
          </g>
          <path class="st57" d="M683.915,277.003c-1.629,0-3.194,0.181-4.656,0.499c2.772,1.893,4.651,5.171,4.651,8.542v45.012
            c0,4.898-3.437,8.697-7.896,9.837c0.038,0.012,0.074,0.026,0.112,0.039c0.002,0,0.003,0.001,0.005,0.002
            c0.63,0.199,1.293,0.374,1.985,0.523c0.022,0.005,0.044,0.01,0.065,0.015c0.332,0.071,0.671,0.135,1.015,0.194
            c0.033,0.006,0.066,0.011,0.099,0.016c0.684,0.114,1.39,0.203,2.116,0.265c0.056,0.005,0.112,0.01,0.169,0.015
            c0.344,0.028,0.691,0.049,1.043,0.064c0.064,0.003,0.129,0.005,0.193,0.007c0.363,0.014,0.729,0.023,1.1,0.023
            c7.853,0,14.245-3.088,14.5-6.943h0.012v-49.821C698.427,281.324,691.93,277.003,683.915,277.003z"/>
          <g>
            <path class="st119" d="M687.927,345.954h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-49.821
              h-29.024V345.954z"/>
            <path class="st115" d="M687.927,330.709h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-34.082
              h-29.024V330.709z"/>
            <path class="st119" d="M687.927,315.465h0.012c0.254,3.856,6.646,6.943,14.5,6.943s14.245-3.088,14.5-6.943h0.012v-19.332
              h-29.024V315.465z"/>
            <path class="st120" d="M702.439,287.845c-8.015,0-14.512,4.322-14.512,8.288c0,3.966,6.497,7.182,14.512,7.182
              c8.015,0,14.512-3.215,14.512-7.182C716.951,292.166,710.454,287.845,702.439,287.845z"/>
          </g>
          <path class="st57" d="M702.439,287.845c-0.756,0-1.498,0.039-2.222,0.112c1.665,1.872,2.706,4.317,2.706,6.819v48.504
            c0,4.126-2.442,7.469-5.876,9.105c1.667,0.331,3.486,0.514,5.392,0.514c7.853,0,14.245-3.088,14.5-6.943h0.012v-49.821
            C716.951,292.166,710.454,287.845,702.439,287.845z"/>
          <g>
            <path class="st119" d="M706.451,356.796h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.087,14.5-6.943h0.012
              v-49.821h-29.024V356.796z"/>
            <path class="st115" d="M706.451,341.81h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.088,14.5-6.943h0.012
              v-20.039h-29.024V341.81z"/>
            <path class="st119" d="M706.451,324.365h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.087,14.5-6.943h0.012
              v-17.39h-29.024V324.365z"/>
            <path class="st120" d="M720.963,298.687c-8.015,0-14.512,4.322-14.512,8.288c0,3.966,6.497,7.182,14.512,7.182
              c8.015,0,14.512-3.215,14.512-7.182C735.475,303.009,728.978,298.687,720.963,298.687z"/>
          </g>
          <path class="st57" d="M720.963,298.687c-0.667,0-1.323,0.033-1.966,0.09c1.689,1.878,2.746,4.342,2.746,6.863v47.728
            c0,4.632-3.071,8.288-7.176,9.636c1.93,0.47,4.099,0.736,6.396,0.736c7.853,0,14.245-3.087,14.5-6.943h0.012v-49.821
            C735.475,303.009,728.978,298.687,720.963,298.687z"/>
          <g>
            <path class="st118" d="M713.964,277.871l-9.889,5.327c-1.919,1.034-3.111,3.03-3.111,5.209v4.999
              c0,0.815,0.661,1.475,1.475,1.475c0.815,0,1.475-0.66,1.475-1.475v-4.999c0-1.093,0.598-2.093,1.56-2.612l9.889-5.327
              c0.717-0.386,0.986-1.281,0.599-1.998C715.577,277.753,714.682,277.483,713.964,277.871z"/>
          </g>
          <g>
            <path class="st118" d="M731.901,288.08l-8.837,4.963c-1.919,1.033-3.111,3.03-3.111,5.21v4.999c0,0.815,0.661,1.475,1.475,1.475
              c0.815,0,1.475-0.661,1.475-1.475v-4.999c0-1.093,0.598-2.094,1.583-2.625l8.86-4.975c0.71-0.399,0.963-1.298,0.564-2.009
              C733.511,287.933,732.612,287.68,731.901,288.08z"/>
          </g>
          <g>
            <path class="st118" d="M749.932,246.241l-10.862-6.091c-0.92-0.528-2.018-0.527-2.937,0.005
              c-0.918,0.532-1.467,1.482-1.467,2.544v1.772c0,0.815,0.66,1.475,1.475,1.475s1.475-0.661,1.475-1.475l-0.017-1.762
              l10.862,6.091c1.229,0.706,1.993,2.025,1.993,3.443v79.27c0,1.409-0.757,2.725-1.976,3.433l-8.549,4.966
              c-0.705,0.409-0.944,1.312-0.535,2.017c0.274,0.472,0.769,0.735,1.277,0.735c0.251,0,0.506-0.064,0.739-0.2l8.55-4.966
              c2.125-1.234,3.445-3.528,3.445-5.984v-79.27C753.406,249.772,752.075,247.472,749.932,246.241z"/>
          </g>
          <g>
            <path class="st119" d="M724.975,367.638h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.088,14.5-6.943h0.012
              v-49.821h-29.024V367.638z"/>
            <path class="st115" d="M724.975,352.262h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.088,14.5-6.943h0.012
              v-34.445h-29.024V352.262z"/>
            <path class="st119" d="M724.975,335.522h0.012c0.254,3.856,6.646,6.943,14.5,6.943c7.853,0,14.245-3.088,14.5-6.943h0.012
              v-17.705h-29.024V335.522z"/>
            <path class="st120" d="M739.487,309.529c-8.015,0-14.512,4.322-14.512,8.288c0,3.966,6.497,7.181,14.512,7.181
              c8.015,0,14.512-3.215,14.512-7.181C753.999,313.851,747.502,309.529,739.487,309.529z"/>
          </g>
          <path class="st57" d="M739.487,309.529c-1.49,0-2.928,0.15-4.281,0.419c3.665,1.567,6.327,5.452,6.327,9.467v45.206
            c0,4.538-2.951,8.131-6.931,9.543c1.526,0.27,3.171,0.417,4.885,0.417c7.853,0,14.245-3.088,14.5-6.943h0.012v-49.821
            C753.999,313.851,747.502,309.529,739.487,309.529z"/>
          <g>
            <path class="st118" d="M748.022,296.34l-48.57-28.042c-2.089-1.206-4.605-1.243-6.73-0.099l-7.221,3.89
              c-1.919,1.034-3.111,3.03-3.111,5.21v4.999c0,0.815,0.661,1.475,1.475,1.475c0.815,0,1.475-0.661,1.475-1.475v-4.999
              c0-1.093,0.598-2.094,1.56-2.612l7.221-3.89c1.217-0.655,2.658-0.634,3.855,0.057l48.57,28.042
              c0.38,0.219,0.429,0.583,0.433,0.729c0.004,0.146-0.027,0.511-0.396,0.75l-5.328,3.446c-1.693,1.095-2.704,2.952-2.704,4.969
              v4.602c0,0.815,0.661,1.475,1.475,1.475c0.815,0,1.475-0.661,1.475-1.475v-4.602c0-1.011,0.507-1.942,1.356-2.491l5.328-3.447
              c1.125-0.728,1.777-1.962,1.742-3.302C749.895,298.21,749.183,297.01,748.022,296.34z"/>
          </g>
          <polygon class="st118" points="762.191,299.203 784.136,287.008 784.136,284.128 762.191,296.628 			"/>
          <polygon class="st118" points="762.191,330.629 784.136,318.434 784.136,314.823 762.191,327.323 			"/>
          <polygon class="st118" points="762.191,361 784.136,348.805 784.136,345.925 762.191,358.425 			"/>
          <polygon class="st118" points="764.006,326.47 766.14,325.461 782.63,287.845 780.547,289.002 			"/>
          <polygon class="st118" points="764.006,298.194 780.632,316.871 782.363,316.221 765.391,297.424 			"/>
          <g>
            <polygon class="st118" points="764.006,357.685 766.14,356.676 782.63,319.059 780.547,320.217 				"/>
            <polygon class="st118" points="764.006,329.409 780.632,348.085 782.363,347.436 765.391,328.639 				"/>
          </g>
        </g>
        <polygon class="st110" points="718.785,246.272 696.8,258.862 715.042,269.02 725.038,263.326 724.975,249.757 		"/>
        <polygon class="st110" points="747.662,288.25 762.191,296.628 784.136,284.128 769.299,275.858 		"/>
        <polygon class="st118" points="696.8,261.113 762.191,299.203 762.191,296.628 696.8,258.862 		"/>
      </g>
      <g>
        <g>
          <g>
            <polygon class="st121" points="752.478,375.424 833.602,422.035 833.602,388.022 752.478,342.721 				"/>
            <g>
              <polygon class="st122" points="770.194,404.326 817.087,431.387 817.087,416.849 770.194,389.788 					"/>
              <polygon class="st123" points="817.087,431.387 833.874,421.695 833.874,407.157 817.087,416.849 					"/>
              <polygon class="st124" points="770.194,389.788 817.087,416.849 833.874,407.157 787.059,380.051 					"/>
              <polygon class="st122" points="774.391,387.318 821.269,414.392 825.523,406.946 779.005,380.051 					"/>
              <g>
                <polygon class="st66" points="777.029,388.677 818.316,412.478 822.336,405.442 781.391,381.808 						"/>
                <path class="st56" d="M776.698,388.758l41.705,24.042l4.253-7.445l-41.344-23.863L776.698,388.758z M822.015,405.528
                  l-3.786,6.628l-40.869-23.56l4.109-6.47L822.015,405.528z"/>
              </g>
              <polygon class="st123" points="825.523,406.946 821.163,414.572 833.874,407.157 833.867,402.315 					"/>
              <polygon class="st125" points="779.005,380.051 825.523,406.946 833.867,402.315 786.823,375.159 					"/>
            </g>
            <polygon class="st123" points="833.602,421.853 864.199,404.187 864.199,370.46 833.602,388.022 				"/>
            <polygon class="st126" points="751.557,342.942 833.645,389.613 866.332,371.007 783.069,324.548 				"/>
            <polygon class="st123" points="841.088,379.778 858.146,369.973 858.146,364.337 841.088,374.674 				"/>
            <polygon class="st127" points="767.944,338.114 841.088,379.778 841.088,374.674 767.944,332.787 				"/>
            <polygon class="st100" points="767.944,332.787 841.088,374.674 858.146,364.337 784.64,323.141 				"/>
            <g>
              <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="827.8657" y1="357.5912" x2="845.1055" y2="357.5912">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st128" points="827.866,359.23 845.105,359.23 845.105,355.953 828.603,355.953 					"/>
              <g>
                <g id="XMLID_16_">
                  <g>
                    <path class="st56" d="M832.082,352.961c0.018-0.114,0.022-0.229,0.014-0.344h-0.025L832.082,352.961z"/>
                    <path class="st129" d="M831.933,347.861l-0.051,0.001c-0.037,0.471-0.291,0.926-0.766,1.187l-0.119,0.066
                      c-1.509,0.828-3.335,0.828-4.844,0l-0.073-0.04c-0.53-0.291-0.786-0.826-0.77-1.353l-0.042-0.001l-0.143,4.897l-0.01,0.368
                      c0.067,0.462,0.334,0.897,0.803,1.156l0.078,0.042c1.606,0.882,3.552,0.882,5.158,0l0.126-0.07
                      c0.468-0.256,0.735-0.692,0.803-1.153l-0.01-0.344L831.933,347.861z"/>
                    <path class="st130" d="M831.773,343.059c-0.045,0.444-0.291,0.87-0.738,1.115l-0.115,0.064c-1.46,0.801-3.228,0.801-4.688,0
                      l-0.07-0.039c-0.465-0.254-0.711-0.703-0.743-1.163h-0.015l-0.137,4.685l0.042,0.001c-0.015,0.527,0.24,1.061,0.77,1.353
                      l0.073,0.04c1.509,0.828,3.335,0.828,4.844,0l0.119-0.066c0.475-0.26,0.729-0.716,0.766-1.187l0.051-0.001l-0.141-4.803
                      L831.773,343.059z"/>
                    <path class="st129" d="M830.953,339.51l-0.111,0.061c-1.411,0.774-3.12,0.774-4.53,0l-0.069-0.038
                      c-0.415-0.228-0.65-0.614-0.707-1.024l-0.132,4.525h0.015c0.032,0.46,0.278,0.909,0.743,1.163l0.07,0.039
                      c1.46,0.801,3.228,0.801,4.688,0l0.115-0.064c0.447-0.245,0.693-0.671,0.738-1.115l0.019-0.001l-0.132-4.57
                      C831.602,338.897,831.368,339.283,830.953,339.51z"/>
                    <path class="st56" d="M831.66,338.487c0.003-0.02,0.005-0.04,0.007-0.06h-0.009L831.66,338.487z"/>
                    <path class="st130" d="M831.52,333.669l-0.056,0.002c-0.003,0.443-0.228,0.885-0.674,1.13l-0.104,0.056
                      c-1.313,0.721-2.904,0.721-4.216,0l-0.064-0.035c-0.469-0.257-0.69-0.733-0.67-1.199l-0.057-0.002l-0.136,4.66l-0.006,0.229
                      c0.056,0.409,0.291,0.796,0.707,1.024l0.069,0.038c1.41,0.774,3.119,0.774,4.53,0l0.111-0.061
                      c0.415-0.227,0.649-0.613,0.708-1.023l-0.002-0.06L831.52,333.669z"/>
                    <path class="st129" d="M831.326,329.338c-0.072,0.333-0.277,0.641-0.617,0.829l-0.099,0.054
                      c-1.265,0.694-2.796,0.694-4.061,0l-0.061-0.034c-0.45-0.247-0.664-0.701-0.645-1.148l-0.03-0.001l-0.134,4.584l0.057,0.002
                      c-0.021,0.466,0.201,0.941,0.67,1.199l0.064,0.035c1.313,0.721,2.904,0.721,4.216,0l0.104-0.056
                      c0.446-0.245,0.671-0.687,0.674-1.13l0.056-0.002l-0.126-4.333L831.326,329.338z"/>
                    <path class="st130" d="M831.249,325.578c0.001,0.016,0.001,0.032,0.001,0.047c0,0.413-0.208,0.827-0.624,1.054l-0.095,0.052
                      c-1.215,0.668-2.688,0.668-3.904,0l-0.058-0.032c-0.413-0.228-0.622-0.636-0.624-1.046h-0.034l-0.099,3.385l0.03,0.001
                      c-0.018,0.446,0.195,0.9,0.645,1.148l0.061,0.034c1.265,0.694,2.796,0.694,4.061,0l0.099-0.054
                      c0.341-0.188,0.546-0.495,0.617-0.829l0.068-0.002l-0.11-3.758H831.249z"/>
                    <path class="st131" d="M830.651,324.586c-1.237-0.718-2.76-0.73-4.009-0.034l-0.079,0.044
                      c-0.414,0.231-0.619,0.644-0.617,1.057c0.002,0.41,0.21,0.819,0.624,1.046l0.058,0.032c1.215,0.668,2.688,0.668,3.904,0
                      l0.095-0.052c0.415-0.228,0.624-0.641,0.624-1.054c0-0.015,0-0.032-0.001-0.047
                      C831.235,325.191,831.036,324.808,830.651,324.586z"/>
                    <path class="st132" d="M830.394,325.029c-0.539-0.313-1.153-0.478-1.776-0.478c-0.602,0-1.199,0.155-1.726,0.449
                      l-0.079,0.044c-0.223,0.124-0.355,0.351-0.354,0.607c0.001,0.121,0.037,0.423,0.356,0.598l0.06,0.033
                      c0.521,0.286,1.11,0.437,1.705,0.437c0.594,0,1.184-0.151,1.705-0.438l0.095-0.052c0.224-0.123,0.357-0.349,0.357-0.605
                      l0-0.012l-0.001-0.016C830.728,325.357,830.603,325.15,830.394,325.029z"/>
                    <path class="st56" d="M825.536,338.51l0.006-0.229h-0.02C825.52,338.358,825.525,338.434,825.536,338.51z"/>
                    <path class="st56" d="M825.114,352.986l0.01-0.368h-0.023C825.091,352.741,825.095,352.864,825.114,352.986z"/>
                    <path class="st130" d="M832.082,352.961c-0.068,0.461-0.335,0.896-0.803,1.153l-0.126,0.07
                      c-1.606,0.882-3.552,0.882-5.158,0l-0.078-0.042c-0.469-0.259-0.737-0.694-0.803-1.156l-0.117,3.984h-0.01
                      c0,0.044,0.002,0.089,0.007,0.135l-0.003,0.092h0.012c0.066,0.513,0.344,1.085,0.833,1.353l0.08,0.044
                      c1.654,0.909,3.659,0.909,5.315,0l0.13-0.072c0.477-0.262,0.751-0.834,0.825-1.325h0.019l-0.004-0.128L832.082,352.961z"/>
                  </g>
                </g>
                <path class="st133" d="M832.082,352.961l-0.01-0.344l-0.138-4.757l-0.141-4.803l-0.132-4.57l-0.002-0.06l-0.138-4.759
                  l-0.126-4.333l-0.11-3.758h-0.035c0.001,0.016,0.001,0.032,0.001,0.047c0,0.413-0.208,0.827-0.624,1.054l-0.095,0.052
                  c-0.165,0.09-0.334,0.168-0.507,0.233v31.275c0,0.313-0.055,0.608-0.154,0.88c0.469-0.113,0.927-0.287,1.361-0.525l0.13-0.072
                  c0.477-0.262,0.751-0.834,0.825-1.325h0.019l-0.004-0.128L832.082,352.961z"/>
              </g>
            </g>
            <g>
              <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="778.8679" y1="333.6074" x2="784.2607" y2="333.6074">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st134" points="778.868,334.12 784.261,334.12 784.261,333.095 779.099,333.095 					"/>
              <g>
                <g id="XMLID_15_">
                  <g>
                    <path class="st56" d="M780.187,332.159c0.005-0.035,0.007-0.072,0.005-0.107h-0.008L780.187,332.159z"/>
                    <path class="st135" d="M780.14,330.563l-0.016,0c-0.011,0.147-0.091,0.29-0.24,0.371l-0.037,0.02
                      c-0.472,0.259-1.043,0.259-1.515,0l-0.023-0.012c-0.166-0.091-0.246-0.258-0.241-0.423l-0.013,0l-0.045,1.532l-0.003,0.115
                      c0.021,0.144,0.105,0.281,0.251,0.362l0.024,0.013c0.502,0.276,1.111,0.276,1.614,0l0.039-0.022
                      c0.146-0.08,0.23-0.217,0.251-0.361l-0.003-0.107L780.14,330.563z"/>
                    <path class="st135" d="M780.09,329.061c-0.014,0.139-0.091,0.272-0.231,0.349l-0.036,0.02c-0.457,0.251-1.01,0.251-1.467,0
                      l-0.022-0.012c-0.145-0.079-0.222-0.22-0.232-0.364h-0.005l-0.043,1.465l0.013,0c-0.005,0.165,0.075,0.332,0.241,0.423
                      l0.023,0.012c0.472,0.259,1.043,0.259,1.515,0l0.037-0.02c0.148-0.082,0.228-0.224,0.24-0.371l0.016,0l-0.044-1.502
                      L780.09,329.061z"/>
                    <path class="st135" d="M779.833,327.951l-0.035,0.019c-0.441,0.242-0.976,0.242-1.417,0l-0.021-0.012
                      c-0.13-0.071-0.203-0.192-0.221-0.32l-0.041,1.415h0.005c0.01,0.144,0.087,0.284,0.232,0.364l0.022,0.012
                      c0.457,0.251,1.01,0.251,1.467,0l0.036-0.02c0.14-0.077,0.217-0.21,0.231-0.349l0.006,0l-0.041-1.43
                      C780.037,327.759,779.963,327.88,779.833,327.951z"/>
                    <path class="st56" d="M780.055,327.631c0.001-0.006,0.002-0.013,0.002-0.019h-0.003L780.055,327.631z"/>
                    <path class="st135" d="M780.011,326.124l-0.018,0.001c-0.001,0.138-0.071,0.277-0.211,0.353l-0.032,0.018
                      c-0.411,0.226-0.908,0.226-1.319,0l-0.02-0.011c-0.147-0.08-0.216-0.229-0.209-0.375l-0.018-0.001l-0.043,1.458
                      l-0.002,0.072c0.018,0.128,0.091,0.249,0.221,0.32l0.021,0.012c0.441,0.242,0.976,0.242,1.417,0l0.035-0.019
                      c0.13-0.071,0.203-0.192,0.221-0.32l-0.001-0.019L780.011,326.124z"/>
                    <path class="st135" d="M779.95,324.769c-0.022,0.104-0.087,0.201-0.193,0.259l-0.031,0.017c-0.396,0.217-0.875,0.217-1.27,0
                      l-0.019-0.011c-0.141-0.077-0.207-0.22-0.202-0.359l-0.009,0l-0.042,1.434l0.018,0.001
                      c-0.006,0.146,0.063,0.295,0.209,0.375l0.02,0.011c0.411,0.226,0.908,0.226,1.319,0l0.032-0.018
                      c0.14-0.077,0.21-0.215,0.211-0.353l0.018-0.001l-0.039-1.355L779.95,324.769z"/>
                    <path class="st135" d="M779.926,323.593c0,0.005,0,0.01,0,0.015c0,0.129-0.065,0.259-0.195,0.33l-0.03,0.016
                      c-0.38,0.209-0.841,0.209-1.221,0l-0.018-0.01c-0.129-0.071-0.195-0.199-0.195-0.327h-0.011l-0.031,1.059l0.009,0
                      c-0.006,0.139,0.061,0.282,0.202,0.359l0.019,0.011c0.396,0.217,0.875,0.217,1.27,0l0.031-0.017
                      c0.107-0.059,0.171-0.155,0.193-0.259l0.021-0.001l-0.034-1.176H779.926z"/>
                    <path class="st131" d="M779.739,323.283c-0.387-0.225-0.863-0.228-1.254-0.011l-0.025,0.014
                      c-0.13,0.072-0.194,0.201-0.193,0.331c0.001,0.128,0.066,0.256,0.195,0.327l0.018,0.01c0.38,0.209,0.841,0.209,1.221,0
                      l0.03-0.016c0.13-0.071,0.195-0.201,0.195-0.33c0-0.005,0-0.01,0-0.015C779.922,323.472,779.859,323.352,779.739,323.283z"
                      />
                    <path class="st136" d="M779.659,323.421c-0.169-0.098-0.361-0.15-0.556-0.15c-0.189,0-0.375,0.049-0.54,0.14l-0.025,0.014
                      c-0.07,0.039-0.111,0.11-0.111,0.19c0,0.038,0.011,0.132,0.111,0.187l0.019,0.01c0.163,0.089,0.347,0.137,0.533,0.137
                      c0.186,0,0.37-0.047,0.534-0.137l0.03-0.016c0.07-0.038,0.112-0.109,0.112-0.189v-0.004l0-0.005
                      C779.763,323.524,779.724,323.459,779.659,323.421z"/>
                    <path class="st56" d="M778.139,327.638l0.002-0.072h-0.006C778.134,327.591,778.136,327.615,778.139,327.638z"/>
                    <path class="st56" d="M778.007,332.167l0.003-0.115h-0.007C778,332.09,778.001,332.129,778.007,332.167z"/>
                    <path class="st135" d="M780.187,332.159c-0.021,0.144-0.105,0.28-0.251,0.361l-0.039,0.022
                      c-0.503,0.276-1.111,0.276-1.614,0l-0.024-0.013c-0.147-0.081-0.231-0.217-0.251-0.362l-0.037,1.246h-0.003
                      c0,0.014,0.001,0.028,0.002,0.042l-0.001,0.029h0.004c0.021,0.16,0.107,0.34,0.261,0.423l0.025,0.014
                      c0.517,0.284,1.145,0.284,1.663,0l0.041-0.022c0.149-0.082,0.235-0.261,0.258-0.415h0.006l-0.001-0.04L780.187,332.159z"/>
                  </g>
                </g>
                <path class="st133" d="M780.187,332.159l-0.003-0.107l-0.043-1.488l-0.044-1.502l-0.041-1.43l-0.001-0.019l-0.043-1.489
                  l-0.039-1.355l-0.034-1.176h-0.011c0,0.005,0,0.01,0,0.015c0,0.129-0.065,0.259-0.195,0.33l-0.03,0.016
                  c-0.051,0.028-0.104,0.052-0.159,0.073v9.783c0,0.098-0.017,0.19-0.048,0.275c0.147-0.035,0.29-0.09,0.426-0.164l0.041-0.022
                  c0.149-0.082,0.235-0.261,0.258-0.415h0.006l-0.001-0.04L780.187,332.159z"/>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st66" points="821.269,422.198 843.739,409.214 843.745,406.193 821.269,419.393 						"/>
                <path class="st56" d="M821.034,422.606l22.941-13.255l0.007-3.569l-22.948,13.477V422.606z M821.505,419.528l22.004-12.923
                  l-0.005,2.473l-21.999,12.711V419.528z"/>
              </g>
              <g>
                <g>
                  <rect x="822.817" y="418.445" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="824.413" y="417.52" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="826.008" y="416.595" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="827.603" y="415.67" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="829.199" y="414.745" class="st56" width="0.235" height="2.996"/>
                </g>
                <g>
                  <rect x="830.794" y="413.82" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="832.39" y="412.895" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="833.985" y="411.97" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="835.581" y="411.045" class="st56" width="0.235" height="2.996"/>
                </g>
                <g>
                  <rect x="837.176" y="410.12" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="838.772" y="409.195" class="st56" width="0.236" height="2.996"/>
                </g>
                <g>
                  <rect x="840.367" y="408.271" class="st56" width="0.235" height="2.996"/>
                </g>
                <g>
                  <rect x="841.963" y="407.346" class="st56" width="0.236" height="2.996"/>
                </g>
              </g>
            </g>
            <polygon class="st137" points="751.557,344.496 833.643,391.826 833.645,389.613 751.557,342.942 				"/>
            <polygon class="st138" points="833.645,391.826 866.332,372.959 866.332,371.007 833.647,389.613 				"/>
            <g>
              
                <rect x="779.316" y="387.617" transform="matrix(0.5361 -0.8441 0.8441 0.5361 36.0884 841.3793)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="783.869" y="390.229" transform="matrix(0.5361 -0.8441 0.8441 0.5361 36.0085 846.4551)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="788.421" y="392.841" transform="matrix(0.5361 -0.8442 0.8442 0.5361 35.9417 851.5529)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="792.974" y="395.452" transform="matrix(0.5358 -0.8443 0.8443 0.5358 35.9973 856.8525)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="797.527" y="398.064" transform="matrix(0.5364 -0.8439 0.8439 0.5364 35.5585 861.3356)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="802.079" y="400.676" transform="matrix(0.5363 -0.844 0.844 0.5363 35.5178 866.4764)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="806.632" y="403.288" transform="matrix(0.5362 -0.8441 0.8441 0.5362 35.5084 871.6689)" class="st56" width="8.608" height="0.471"/>
            </g>
            <g>
              
                <rect x="811.185" y="405.9" transform="matrix(0.5362 -0.8441 0.8441 0.5362 35.4152 876.7231)" class="st56" width="8.608" height="0.471"/>
            </g>
            <polygon class="st139" points="759.513,379.441 765.716,383.055 765.716,376.036 759.513,372.631 				"/>
            <polygon class="st139" points="767.093,383.857 773.296,387.472 773.296,380.49 767.093,377.085 				"/>
            <g>
              <g>
                <polygon class="st66" points="757.029,361.571 782.279,376.161 782.279,372.951 757.023,358.118 						"/>
                <path class="st56" d="M756.794,361.708l25.721,14.862v-3.753l-25.729-15.11L756.794,361.708z M782.044,373.086v2.667
                  l-24.779-14.317l-0.006-2.906L782.044,373.086z"/>
              </g>
              <g>
                <g>
                  <rect x="780.265" y="371.903" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="778.477" y="370.866" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="776.688" y="369.829" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="774.899" y="368.793" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="773.11" y="367.756" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="771.322" y="366.719" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="769.533" y="365.681" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="767.744" y="364.644" class="st56" width="0.236" height="3.359"/>
                </g>
                <g>
                  <rect x="765.955" y="363.607" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="764.166" y="362.57" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="762.378" y="361.534" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="760.589" y="360.497" class="st56" width="0.235" height="3.359"/>
                </g>
                <g>
                  <rect x="758.8" y="359.46" class="st56" width="0.236" height="3.359"/>
                </g>
              </g>
            </g>
            <g>
              <polygon class="st66" points="835.993,399.694 862.061,384.749 862.061,378.678 836.001,393.539 					"/>
              <path class="st56" d="M835.756,400.101l26.54-15.216v-6.613l-26.53,15.13L835.756,400.101z M836.237,393.677l25.588-14.593
                v5.529l-25.596,14.675L836.237,393.677z"/>
            </g>
            <g>
              <rect x="839.839" y="391.314" class="st56" width="0.471" height="5.888"/>
            </g>
            <g>
              <rect x="844.337" y="388.718" class="st56" width="0.471" height="6.124"/>
            </g>
            <g>
              <rect x="848.835" y="386.122" class="st56" width="0.471" height="6.124"/>
            </g>
            <g>
              <rect x="853.333" y="383.526" class="st56" width="0.471" height="6.124"/>
            </g>
            <g>
              <rect x="857.83" y="380.93" class="st56" width="0.471" height="6.124"/>
            </g>
            <g>
              <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="840.8262" y1="364.8811" x2="858.066" y2="364.8811">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st140" points="840.826,366.52 858.066,366.52 858.066,363.242 841.564,363.242 					"/>
              <g>
                <g id="XMLID_14_">
                  <g>
                    <path class="st56" d="M845.042,360.251c0.017-0.114,0.022-0.229,0.014-0.344h-0.024L845.042,360.251z"/>
                    <path class="st56" d="M844.621,345.777c0.003-0.019,0.005-0.04,0.007-0.059h-0.009L844.621,345.777z"/>
                    <path class="st131" d="M843.612,331.876c-1.237-0.718-2.76-0.73-4.009-0.034l-0.079,0.044
                      c-0.414,0.231-0.619,0.644-0.617,1.057c0.002,0.41,0.21,0.818,0.624,1.046l0.058,0.032c1.215,0.668,2.688,0.668,3.904,0
                      l0.095-0.052c0.416-0.228,0.624-0.641,0.624-1.054c0-0.015,0-0.032-0.001-0.047
                      C844.195,332.481,843.996,332.098,843.612,331.876z"/>
                    <path class="st132" d="M843.354,332.319c-0.539-0.313-1.153-0.478-1.776-0.478c-0.602,0-1.199,0.155-1.726,0.449
                      l-0.079,0.044c-0.223,0.124-0.356,0.351-0.354,0.607c0.001,0.121,0.037,0.422,0.356,0.598l0.06,0.033
                      c0.521,0.286,1.11,0.438,1.705,0.438s1.184-0.151,1.705-0.438l0.096-0.052c0.224-0.123,0.357-0.349,0.357-0.605l0-0.012
                      l-0.001-0.016C843.688,332.647,843.563,332.44,843.354,332.319z"/>
                    <path class="st56" d="M838.497,345.8l0.006-0.229h-0.02C838.48,345.648,838.485,345.724,838.497,345.8z"/>
                    <path class="st56" d="M838.074,360.276l0.01-0.368h-0.023C838.052,360.031,838.056,360.154,838.074,360.276z"/>
                    <path class="st129" d="M844.894,355.151l-0.051,0.001c-0.037,0.471-0.291,0.926-0.766,1.187l-0.119,0.066
                      c-1.509,0.828-3.335,0.828-4.844,0l-0.073-0.04c-0.53-0.291-0.786-0.826-0.77-1.353l-0.042-0.001l-0.144,4.897l-0.01,0.368
                      c0.067,0.462,0.334,0.898,0.803,1.156l0.078,0.042c1.606,0.882,3.552,0.882,5.158,0l0.126-0.07
                      c0.468-0.257,0.735-0.692,0.803-1.153l-0.01-0.344L844.894,355.151z"/>
                    <path class="st130" d="M844.734,350.349c-0.045,0.444-0.291,0.87-0.739,1.115l-0.115,0.064c-1.46,0.801-3.228,0.801-4.688,0
                      l-0.07-0.039c-0.464-0.254-0.711-0.703-0.742-1.163h-0.015l-0.136,4.685l0.042,0.001c-0.015,0.527,0.24,1.062,0.77,1.353
                      l0.073,0.04c1.509,0.828,3.335,0.828,4.844,0l0.119-0.066c0.475-0.26,0.729-0.716,0.766-1.187l0.051-0.001l-0.141-4.803
                      L844.734,350.349z"/>
                    <path class="st129" d="M843.913,346.8l-0.111,0.062c-1.411,0.774-3.12,0.774-4.53,0l-0.069-0.038
                      c-0.415-0.228-0.65-0.614-0.707-1.024l-0.132,4.525h0.015c0.032,0.46,0.278,0.909,0.742,1.163l0.07,0.039
                      c1.461,0.801,3.228,0.801,4.688,0l0.115-0.064c0.447-0.245,0.693-0.671,0.739-1.115l0.019-0.001l-0.132-4.57
                      C844.562,346.186,844.328,346.573,843.913,346.8z"/>
                    <path class="st130" d="M844.48,340.959l-0.056,0.002c-0.003,0.443-0.228,0.885-0.674,1.13l-0.104,0.056
                      c-1.313,0.721-2.904,0.721-4.216,0l-0.064-0.035c-0.469-0.257-0.69-0.733-0.67-1.199l-0.057-0.002l-0.136,4.659
                      l-0.006,0.229c0.056,0.409,0.291,0.796,0.707,1.024l0.069,0.038c1.41,0.774,3.119,0.774,4.53,0l0.111-0.062
                      c0.415-0.227,0.649-0.613,0.708-1.023l-0.002-0.059L844.48,340.959z"/>
                    <path class="st129" d="M844.286,336.627c-0.072,0.333-0.277,0.641-0.617,0.829l-0.099,0.054
                      c-1.265,0.694-2.796,0.694-4.061,0l-0.06-0.034c-0.45-0.247-0.664-0.702-0.645-1.148l-0.03-0.001l-0.135,4.584l0.057,0.002
                      c-0.021,0.466,0.201,0.942,0.67,1.199l0.064,0.035c1.313,0.721,2.904,0.721,4.216,0l0.104-0.056
                      c0.446-0.245,0.671-0.687,0.674-1.13l0.056-0.002l-0.126-4.333L844.286,336.627z"/>
                    <path class="st130" d="M844.21,332.867c0.001,0.015,0.001,0.032,0.001,0.047c0,0.413-0.208,0.827-0.624,1.054l-0.095,0.052
                      c-1.215,0.668-2.688,0.668-3.904,0l-0.058-0.032c-0.413-0.228-0.622-0.636-0.624-1.046h-0.034l-0.098,3.385l0.03,0.001
                      c-0.019,0.446,0.195,0.901,0.645,1.148l0.06,0.034c1.265,0.694,2.796,0.694,4.061,0l0.099-0.054
                      c0.34-0.188,0.546-0.495,0.617-0.829l0.068-0.002l-0.11-3.758H844.21z"/>
                    <path class="st130" d="M845.042,360.251c-0.068,0.461-0.335,0.896-0.803,1.153l-0.126,0.07
                      c-1.606,0.882-3.552,0.882-5.158,0l-0.078-0.042c-0.469-0.258-0.737-0.694-0.803-1.156l-0.117,3.984h-0.01
                      c0,0.044,0.002,0.089,0.007,0.135l-0.003,0.092h0.012c0.066,0.513,0.344,1.085,0.833,1.353l0.08,0.044
                      c1.654,0.909,3.66,0.909,5.315,0l0.13-0.072c0.477-0.262,0.751-0.834,0.825-1.325h0.02l-0.004-0.128L845.042,360.251z"/>
                  </g>
                </g>
                <path class="st133" d="M845.042,360.251l-0.01-0.344l-0.138-4.757l-0.141-4.803l-0.132-4.57l-0.002-0.059l-0.138-4.759
                  l-0.126-4.333l-0.11-3.758h-0.035c0.001,0.015,0.001,0.032,0.001,0.047c0,0.413-0.208,0.827-0.624,1.054l-0.095,0.052
                  c-0.165,0.09-0.334,0.168-0.507,0.233v31.275c0,0.313-0.055,0.608-0.155,0.88c0.469-0.113,0.927-0.287,1.361-0.525l0.13-0.072
                  c0.477-0.262,0.751-0.834,0.825-1.325h0.02l-0.004-0.128L845.042,360.251z"/>
              </g>
            </g>
          </g>
          <polygon class="st139" points="777.029,408.271 788.914,415.129 788.914,408.271 777.029,401.306 			"/>
          <polygon class="st139" points="795.232,418.757 807.117,425.634 807.117,418.757 795.232,411.793 			"/>
        </g>
        <g>
          <g>
            <g>
              <polygon class="st123" points="860.301,462.025 949.938,409.93 949.938,391.016 860.301,442.47 					"/>
              <polygon class="st121" points="834.911,447.517 860.301,462.025 860.301,442.47 834.911,428.434 					"/>
              <polygon class="st141" points="834.911,428.434 860.301,442.47 949.938,391.016 923.978,375.976 					"/>
              <polygon class="st142" points="867.753,398.651 867.753,408.845 887.772,420.335 879.577,405.749 					"/>
              <polygon class="st143" points="923.926,365.818 923.926,376.012 944.291,387.704 936.023,373.161 					"/>
              <polygon class="st123" points="879.577,405.749 887.772,420.335 944.291,387.704 936.023,373.161 					"/>
              <g>
                <polygon class="st66" points="882.542,404.56 890.214,418.451 942.087,388.455 934.266,374.698 						"/>
                <path class="st56" d="M881.931,404.39l8.11,14.683l52.661-30.452l-8.268-14.543L881.931,404.39z M890.387,417.829
                  l-7.235-13.099l50.946-29.413l7.375,12.971L890.387,417.829z"/>
              </g>
              <polygon class="st141" points="867.753,398.651 879.577,405.749 936.023,373.161 923.926,365.818 					"/>
              <g>
                <line class="st66" x1="928.152" y1="378.196" x2="936.023" y2="392.294"/>
                
                  <rect x="931.636" y="377.171" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -69.5512 503.2289)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="921.54" y1="381.945" x2="929.41" y2="396.043"/>
                
                  <rect x="925.023" y="380.921" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -72.217 500.4896)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="914.927" y1="385.695" x2="922.798" y2="399.793"/>
                
                  <rect x="918.41" y="384.671" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -74.8847 497.7334)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="908.314" y1="389.445" x2="916.185" y2="403.543"/>
                
                  <rect x="911.798" y="388.421" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -77.5506 494.9946)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="901.702" y1="393.195" x2="909.572" y2="407.293"/>
                
                  <rect x="905.185" y="392.171" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -80.2173 492.2468)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="895.089" y1="396.945" x2="902.96" y2="411.042"/>
                
                  <rect x="898.572" y="395.92" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -82.8854 489.4848)" class="st56" width="0.904" height="16.146"/>
              </g>
              <g>
                <line class="st66" x1="888.476" y1="400.694" x2="896.347" y2="414.792"/>
                
                  <rect x="891.959" y="399.67" transform="matrix(0.8731 -0.4875 0.4875 0.8731 -85.551 486.7512)" class="st56" width="0.904" height="16.146"/>
              </g>
            </g>
            <polygon class="st139" points="927.336,423.066 943.776,413.511 943.779,401.053 927.336,410.546 				"/>
            <polygon class="st139" points="872.033,455.244 888.473,445.69 888.476,433.231 872.033,442.724 				"/>
          </g>
          <g>
            <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="868.5059" y1="423.955" x2="896.6194" y2="423.955">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st144" points="868.506,426.627 896.619,426.627 896.619,421.283 869.709,421.283 				"/>
            <g>
              <g id="XMLID_13_">
                <g>
                  <path class="st56" d="M875.381,416.405c0.028-0.186,0.035-0.373,0.023-0.56h-0.04L875.381,416.405z"/>
                  <path class="st145" d="M875.139,408.087l-0.084,0.002c-0.06,0.768-0.475,1.51-1.249,1.935l-0.194,0.107
                    c-2.46,1.35-5.439,1.35-7.9,0l-0.119-0.065c-0.865-0.475-1.281-1.346-1.256-2.206l-0.069-0.002l-0.234,7.986l-0.017,0.6
                    c0.109,0.753,0.545,1.463,1.31,1.885l0.127,0.069c2.619,1.438,5.792,1.438,8.411,0l0.206-0.114
                    c0.763-0.418,1.199-1.129,1.31-1.88l-0.017-0.56L875.139,408.087z"/>
                  <path class="st146" d="M874.878,400.256c-0.074,0.724-0.475,1.418-1.204,1.818l-0.187,0.104
                    c-2.382,1.306-5.264,1.306-7.645,0l-0.114-0.064c-0.758-0.415-1.159-1.146-1.211-1.897h-0.025l-0.222,7.64l0.069,0.002
                    c-0.025,0.86,0.391,1.731,1.256,2.206l0.119,0.065c2.46,1.35,5.439,1.35,7.9,0l0.194-0.107
                    c0.774-0.425,1.189-1.168,1.249-1.935l0.084-0.002l-0.229-7.833L874.878,400.256z"/>
                  <path class="st145" d="M873.54,394.469l-0.181,0.1c-2.301,1.263-5.088,1.263-7.388,0l-0.112-0.062
                    c-0.677-0.371-1.06-1.002-1.152-1.669l-0.216,7.379h0.025c0.052,0.751,0.453,1.482,1.211,1.897l0.114,0.064
                    c2.382,1.306,5.264,1.306,7.645,0l0.187-0.104c0.729-0.4,1.131-1.094,1.204-1.818l0.032-0.002l-0.216-7.453
                    C874.599,393.469,874.217,394.1,873.54,394.469z"/>
                  <path class="st56" d="M874.694,392.802c0.005-0.032,0.008-0.065,0.012-0.097h-0.015L874.694,392.802z"/>
                  <path class="st146" d="M874.465,384.944l-0.092,0.003c-0.005,0.722-0.371,1.443-1.099,1.843l-0.169,0.092
                    c-2.141,1.176-4.735,1.176-6.876,0l-0.104-0.057c-0.764-0.42-1.126-1.196-1.092-1.955l-0.094-0.003l-0.222,7.598l-0.01,0.373
                    c0.092,0.667,0.475,1.298,1.152,1.669l0.112,0.062c2.3,1.263,5.086,1.263,7.388,0l0.181-0.1c0.677-0.37,1.059-1,1.154-1.668
                    l-0.003-0.097L874.465,384.944z"/>
                  <path class="st145" d="M874.149,377.881c-0.117,0.544-0.452,1.045-1.007,1.352l-0.162,0.089c-2.062,1.132-4.56,1.132-6.622,0
                    l-0.099-0.055c-0.734-0.403-1.082-1.144-1.052-1.871l-0.049-0.002l-0.219,7.475l0.094,0.003
                    c-0.033,0.759,0.328,1.535,1.092,1.955l0.104,0.057c2.141,1.176,4.735,1.176,6.876,0l0.169-0.092
                    c0.728-0.4,1.094-1.121,1.099-1.843l0.092-0.003l-0.206-7.067L874.149,377.881z"/>
                  <path class="st146" d="M874.023,371.749c0.002,0.025,0.002,0.052,0.002,0.077c0,0.674-0.339,1.348-1.017,1.719l-0.156,0.085
                    c-1.982,1.089-4.384,1.089-6.366,0l-0.095-0.052c-0.674-0.371-1.013-1.037-1.017-1.706h-0.055l-0.161,5.519l0.049,0.002
                    c-0.03,0.728,0.318,1.469,1.052,1.871l0.099,0.055c2.062,1.132,4.559,1.132,6.622,0l0.162-0.089
                    c0.555-0.306,0.89-0.808,1.007-1.352l0.11-0.003l-0.179-6.128H874.023z"/>
                  <path class="st147" d="M873.048,370.132c-2.017-1.171-4.501-1.191-6.538-0.055l-0.129,0.072
                    c-0.676,0.376-1.01,1.051-1.007,1.724c0.003,0.669,0.343,1.335,1.017,1.706l0.095,0.052c1.982,1.089,4.384,1.089,6.366,0
                    l0.156-0.085c0.677-0.371,1.017-1.045,1.017-1.719c0-0.025,0-0.052-0.002-0.077C874,371.119,873.675,370.495,873.048,370.132
                    z"/>
                  <path class="st136" d="M872.628,370.855c-0.879-0.51-1.881-0.78-2.897-0.78c-0.982,0-1.956,0.253-2.814,0.732l-0.129,0.072
                    c-0.364,0.202-0.58,0.572-0.578,0.99c0.001,0.198,0.06,0.689,0.58,0.976l0.098,0.053c0.849,0.467,1.811,0.713,2.78,0.713
                    c0.969,0,1.931-0.247,2.781-0.714l0.156-0.085c0.365-0.2,0.583-0.569,0.583-0.986l0-0.02l-0.001-0.026
                    C873.173,371.39,872.969,371.053,872.628,370.855z"/>
                  <path class="st56" d="M864.707,392.838l0.01-0.373h-0.034C864.68,392.591,864.689,392.715,864.707,392.838z"/>
                  <path class="st56" d="M864.018,416.445l0.017-0.6h-0.037C863.981,416.045,863.988,416.246,864.018,416.445z"/>
                  <path class="st146" d="M875.381,416.405c-0.11,0.751-0.547,1.462-1.31,1.88l-0.206,0.114c-2.619,1.438-5.792,1.438-8.411,0
                    l-0.127-0.069c-0.764-0.421-1.201-1.132-1.31-1.885l-0.191,6.496h-0.017c0,0.072,0.003,0.146,0.012,0.221l-0.005,0.151h0.02
                    c0.107,0.836,0.56,1.77,1.358,2.206l0.131,0.072c2.698,1.482,5.968,1.482,8.667,0l0.212-0.117
                    c0.778-0.426,1.224-1.36,1.345-2.161h0.032l-0.007-0.209L875.381,416.405z"/>
                </g>
              </g>
              <path class="st133" d="M875.381,416.405l-0.017-0.56l-0.226-7.757l-0.229-7.833l-0.216-7.453l-0.003-0.097l-0.226-7.761
                l-0.206-7.067l-0.179-6.128h-0.057c0.002,0.025,0.002,0.052,0.002,0.077c0,0.674-0.339,1.348-1.017,1.719l-0.156,0.085
                c-0.268,0.147-0.545,0.273-0.827,0.381v51.001c0,0.51-0.09,0.991-0.252,1.435c0.765-0.185,1.512-0.469,2.219-0.857l0.212-0.117
                c0.778-0.426,1.224-1.36,1.345-2.161h0.032l-0.007-0.209L875.381,416.405z"/>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="853.452" y1="431.7337" x2="881.5654" y2="431.7337">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st148" points="853.452,434.406 881.565,434.406 881.565,429.061 854.655,429.061 				"/>
            <g>
              <g id="XMLID_12_">
                <g>
                  <path class="st56" d="M860.327,424.183c0.028-0.186,0.035-0.373,0.023-0.56h-0.04L860.327,424.183z"/>
                  <path class="st145" d="M860.085,415.866l-0.083,0.002c-0.06,0.768-0.475,1.51-1.249,1.935l-0.194,0.107
                    c-2.46,1.35-5.439,1.35-7.899,0l-0.119-0.065c-0.865-0.475-1.281-1.346-1.256-2.206l-0.068-0.002l-0.234,7.987l-0.017,0.6
                    c0.109,0.753,0.545,1.464,1.31,1.885l0.127,0.068c2.619,1.438,5.792,1.438,8.411,0l0.206-0.114
                    c0.763-0.418,1.199-1.129,1.31-1.88l-0.017-0.56L860.085,415.866z"/>
                  <path class="st146" d="M859.824,408.035c-0.074,0.724-0.475,1.418-1.204,1.818l-0.187,0.104
                    c-2.382,1.306-5.263,1.306-7.645,0l-0.114-0.064c-0.758-0.415-1.159-1.146-1.211-1.897h-0.025l-0.222,7.64l0.068,0.002
                    c-0.025,0.86,0.391,1.731,1.256,2.206l0.119,0.065c2.46,1.35,5.439,1.35,7.899,0l0.194-0.107
                    c0.774-0.425,1.189-1.167,1.249-1.935l0.083-0.002l-0.229-7.833L859.824,408.035z"/>
                  <path class="st145" d="M858.486,402.248l-0.181,0.1c-2.301,1.263-5.088,1.263-7.388,0l-0.112-0.062
                    c-0.677-0.371-1.06-1.002-1.152-1.669l-0.216,7.379h0.025c0.052,0.751,0.453,1.482,1.211,1.897l0.114,0.064
                    c2.382,1.306,5.264,1.306,7.645,0l0.187-0.104c0.729-0.4,1.131-1.094,1.204-1.818l0.032-0.002l-0.216-7.453
                    C859.545,401.248,859.163,401.878,858.486,402.248z"/>
                  <path class="st56" d="M859.64,400.58c0.005-0.032,0.008-0.065,0.012-0.097h-0.015L859.64,400.58z"/>
                  <path class="st146" d="M859.411,392.723l-0.092,0.003c-0.005,0.723-0.371,1.443-1.099,1.843l-0.169,0.092
                    c-2.141,1.176-4.735,1.176-6.876,0l-0.104-0.057c-0.764-0.42-1.126-1.196-1.092-1.955l-0.094-0.003l-0.223,7.598l-0.01,0.373
                    c0.092,0.667,0.475,1.298,1.152,1.669l0.112,0.062c2.3,1.263,5.086,1.263,7.388,0l0.181-0.1c0.677-0.37,1.059-1,1.154-1.668
                    l-0.003-0.097L859.411,392.723z"/>
                  <path class="st145" d="M859.095,385.659c-0.117,0.544-0.452,1.045-1.007,1.352l-0.162,0.089c-2.062,1.132-4.56,1.132-6.622,0
                    l-0.099-0.055c-0.734-0.403-1.082-1.144-1.052-1.872l-0.049-0.002l-0.219,7.475l0.094,0.003
                    c-0.034,0.759,0.328,1.535,1.092,1.955l0.104,0.057c2.141,1.176,4.735,1.176,6.876,0l0.169-0.092
                    c0.728-0.4,1.094-1.121,1.099-1.843l0.092-0.003l-0.206-7.066L859.095,385.659z"/>
                  <path class="st146" d="M858.969,379.528c0.002,0.025,0.002,0.052,0.002,0.077c0,0.674-0.339,1.348-1.017,1.719l-0.155,0.085
                    c-1.982,1.089-4.384,1.089-6.366,0l-0.095-0.052c-0.674-0.371-1.014-1.037-1.017-1.706h-0.055l-0.161,5.519l0.049,0.002
                    c-0.03,0.728,0.318,1.469,1.052,1.872l0.099,0.055c2.062,1.132,4.56,1.132,6.622,0l0.162-0.089
                    c0.555-0.306,0.89-0.808,1.007-1.352l0.11-0.003l-0.179-6.128H858.969z"/>
                  <path class="st147" d="M857.994,377.91c-2.017-1.171-4.501-1.191-6.538-0.055l-0.129,0.072
                    c-0.676,0.376-1.01,1.05-1.007,1.724c0.003,0.669,0.343,1.335,1.017,1.706l0.095,0.052c1.982,1.089,4.384,1.089,6.366,0
                    l0.155-0.085c0.677-0.371,1.017-1.045,1.017-1.719c0-0.025,0-0.052-0.002-0.077
                    C858.946,378.897,858.621,378.273,857.994,377.91z"/>
                  <path class="st136" d="M857.574,378.634c-0.879-0.51-1.881-0.78-2.897-0.78c-0.982,0-1.956,0.253-2.814,0.732l-0.129,0.072
                    c-0.364,0.203-0.58,0.573-0.578,0.99c0.001,0.198,0.06,0.689,0.58,0.976l0.098,0.053c0.849,0.467,1.811,0.713,2.78,0.713
                    c0.97,0,1.931-0.247,2.781-0.714l0.156-0.085c0.365-0.2,0.583-0.569,0.583-0.986l0-0.02l-0.001-0.026
                    C858.119,379.168,857.915,378.831,857.574,378.634z"/>
                  <path class="st56" d="M849.653,400.617l0.01-0.373h-0.033C849.626,400.37,849.635,400.493,849.653,400.617z"/>
                  <path class="st56" d="M848.964,424.223l0.017-0.6h-0.037C848.927,423.824,848.934,424.024,848.964,424.223z"/>
                  <path class="st146" d="M860.327,424.183c-0.11,0.751-0.547,1.462-1.31,1.88l-0.206,0.114c-2.619,1.438-5.792,1.438-8.411,0
                    l-0.127-0.068c-0.764-0.422-1.201-1.132-1.31-1.885l-0.191,6.496h-0.017c0,0.072,0.003,0.145,0.012,0.221l-0.005,0.15h0.02
                    c0.107,0.836,0.56,1.77,1.358,2.206l0.13,0.072c2.698,1.482,5.968,1.482,8.667,0l0.212-0.117
                    c0.778-0.427,1.224-1.36,1.345-2.161h0.032l-0.007-0.209L860.327,424.183z"/>
                </g>
              </g>
              <path class="st133" d="M860.327,424.183l-0.017-0.56l-0.226-7.757l-0.229-7.833l-0.216-7.453l-0.003-0.097l-0.226-7.761
                l-0.206-7.066l-0.179-6.128h-0.057c0.002,0.025,0.002,0.052,0.002,0.077c0,0.674-0.339,1.348-1.017,1.719l-0.156,0.085
                c-0.268,0.147-0.545,0.273-0.827,0.381v51.001c0,0.51-0.09,0.991-0.252,1.435c0.765-0.185,1.512-0.469,2.219-0.857l0.212-0.117
                c0.778-0.427,1.224-1.36,1.345-2.161h0.032l-0.007-0.209L860.327,424.183z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path class="st149" d="M860.379,366.804c0,3.871-3.138,7.008-7.008,7.008c-3.871,0-7.008-3.138-7.008-7.008
              c0-3.871,3.138-7.008,7.008-7.008C857.242,359.796,860.379,362.933,860.379,366.804z"/>
            <path class="st149" d="M861.832,361.311c0,3.871-3.138,7.008-7.008,7.008s-7.008-3.138-7.008-7.008s3.138-7.008,7.008-7.008
              S861.832,357.44,861.832,361.311z"/>
            
              <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 306.3438 1114.0059)" class="st149" cx="856.975" cy="363.462" rx="9.16" ry="9.16"/>
            <path class="st149" d="M870.172,368.839c0,3.871-3.138,7.008-7.008,7.008s-7.008-3.138-7.008-7.008
              c0-3.871,3.138-7.008,7.008-7.008S870.172,364.968,870.172,368.839z"/>
            <circle class="st150" cx="851.263" cy="356.347" r="8.223"/>
            
              <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 375.9466 1157.0226)" class="st149" cx="867.932" cy="357.575" rx="8.223" ry="8.223"/>
            <circle class="st149" cx="865.177" cy="351.559" r="7.155"/>
            
              <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 325.0021 1125.4622)" class="st149" cx="873.543" cy="357.402" rx="11.517" ry="11.517"/>
            <circle class="st150" cx="872.364" cy="344.954" r="6.733"/>
            <path class="st149" d="M866.331,363.683c0,3.719-3.015,6.733-6.733,6.733s-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733S866.331,359.964,866.331,363.683z"/>
            <path class="st149" d="M887.942,348.539c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C884.927,341.806,887.942,344.821,887.942,348.539z"/>
            <path class="st149" d="M883.999,357.19c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C880.984,350.457,883.999,353.471,883.999,357.19z"/>
            <path class="st149" d="M872.364,364.155c0,3.719-3.015,6.733-6.733,6.733s-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733S872.364,360.436,872.364,364.155z"/>
            <path class="st149" d="M867.909,357.19c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C864.895,350.457,867.909,353.471,867.909,357.19z"/>
            <path class="st149" d="M875.782,351.243c0,3.719-3.015,6.733-6.733,6.733s-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733S875.782,347.525,875.782,351.243z"/>
            <path class="st149" d="M885.83,351.906c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C882.816,345.172,885.83,348.187,885.83,351.906z"/>
            <path class="st149" d="M880.276,360.164c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C877.262,353.431,880.276,356.445,880.276,360.164z"/>
            <path class="st149" d="M870.995,366.737c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C867.98,360.004,870.995,363.019,870.995,366.737z"/>
            <circle class="st149" cx="854.443" cy="376.645" r="5.224"/>
            <path class="st149" d="M861.71,371.421c0,2.885-2.339,5.224-5.224,5.224c-2.885,0-5.224-2.339-5.224-5.224
              c0-2.885,2.339-5.224,5.224-5.224C859.372,366.197,861.71,368.536,861.71,371.421z"/>
            <path class="st150" d="M892.961,355.771c0,3.349-2.715,6.063-6.063,6.063c-3.349,0-6.063-2.715-6.063-6.063
              c0-3.349,2.715-6.063,6.063-6.063C890.246,349.708,892.961,352.422,892.961,355.771z"/>
            <path class="st149" d="M884.45,346.434c1.612-2.393,4.858-3.026,7.251-1.414c2.393,1.612,3.026,4.858,1.414,7.251
              c-1.612,2.393-4.858,3.026-7.251,1.414C883.472,352.073,882.839,348.827,884.45,346.434z"/>
            <path class="st149" d="M866.679,336.682c2.554-3.791,7.697-4.794,11.488-2.24c3.791,2.554,4.794,7.697,2.24,11.488
              c-2.554,3.791-7.697,4.794-11.488,2.24C865.128,345.617,864.125,340.473,866.679,336.682z"/>
          </g>
          <g class="st151">
            <circle class="st150" cx="883.94" cy="343.336" r="8.223"/>
            <circle class="st149" cx="892.696" cy="344.322" r="8.223"/>
            <path class="st149" d="M898.251,341.108c0,3.952-3.204,7.155-7.155,7.155c-3.952,0-7.155-3.204-7.155-7.155
              c0-3.952,3.203-7.155,7.155-7.155C895.047,333.953,898.251,337.156,898.251,341.108z"/>
            <path class="st150" d="M903.861,331.701c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C900.847,324.967,903.861,327.982,903.861,331.701z"/>
            <circle class="st149" cx="884.362" cy="350.43" r="6.733"/>
            <circle class="st149" cx="905.973" cy="335.286" r="6.733"/>
            <path class="st149" d="M903.278,345.885c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C900.263,339.152,903.278,342.166,903.278,345.885z"/>
            <circle class="st149" cx="893.813" cy="337.99" r="6.733"/>
            <circle class="st149" cx="903.861" cy="338.652" r="6.733"/>
            <circle class="st149" cx="869.049" cy="368.919" r="5.224"/>
            <circle class="st149" cx="885.847" cy="357.402" r="5.224"/>
            <path class="st149" d="M905.043,340.511c1.612-2.393,4.858-3.026,7.251-1.414c2.393,1.612,3.026,4.858,1.414,7.251
              c-1.612,2.393-4.858,3.026-7.251,1.414C904.064,346.15,903.431,342.904,905.043,340.511z"/>
          </g>
        </g>
        <g class="st152">
          <g>
            <path class="st149" d="M830.933,320.372c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444s1.542-3.444,3.444-3.444
              S830.933,318.469,830.933,320.372z"/>
            <path class="st149" d="M831.647,317.672c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444s1.542-3.444,3.444-3.444
              S831.647,315.77,831.647,317.672z"/>
            <path class="st149" d="M833.761,318.729c0,2.486-2.015,4.501-4.501,4.501c-2.486,0-4.501-2.015-4.501-4.501
              c0-2.486,2.015-4.501,4.501-4.501C831.746,314.228,833.761,316.243,833.761,318.729z"/>
            <path class="st149" d="M835.746,321.371c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444s1.542-3.444,3.444-3.444
              S835.746,319.469,835.746,321.371z"/>
            <circle class="st150" cx="826.453" cy="315.233" r="4.041"/>
            <path class="st149" d="M838.686,315.836c0,2.232-1.809,4.041-4.041,4.041s-4.041-1.809-4.041-4.041
              c0-2.232,1.809-4.041,4.041-4.041S838.686,313.605,838.686,315.836z"/>
            <path class="st149" d="M836.807,312.88c0,1.942-1.574,3.516-3.516,3.516c-1.942,0-3.516-1.574-3.516-3.516
              c0-1.942,1.574-3.516,3.516-3.516C835.233,309.364,836.807,310.938,836.807,312.88z"/>
            <path class="st149" d="M843.062,315.751c0,3.126-2.534,5.66-5.66,5.66c-3.126,0-5.66-2.534-5.66-5.66
              c0-3.126,2.534-5.66,5.66-5.66C840.528,310.092,843.062,312.626,843.062,315.751z"/>
            <path class="st150" d="M840.132,309.634c0,1.827-1.481,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.828,1.482-3.309,3.309-3.309C838.65,306.325,840.132,307.807,840.132,309.634z"/>
            <path class="st149" d="M833.858,318.838c0,1.827-1.481,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.482-3.309,3.309-3.309S833.858,317.011,833.858,318.838z"/>
            <path class="st149" d="M844.478,311.396c0,1.828-1.482,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.827,1.482-3.309,3.309-3.309C842.997,308.087,844.478,309.568,844.478,311.396z"/>
            <path class="st149" d="M842.54,315.647c0,1.827-1.481,3.309-3.309,3.309c-1.828,0-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309C841.059,312.338,842.54,313.82,842.54,315.647z"/>
            <circle class="st149" cx="833.514" cy="319.07" r="3.309"/>
            <path class="st149" d="M834.634,315.647c0,1.827-1.481,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309S834.634,313.82,834.634,315.647z"/>
            <path class="st149" d="M838.503,312.725c0,1.827-1.482,3.309-3.309,3.309c-1.828,0-3.309-1.482-3.309-3.309
              c0-1.827,1.482-3.309,3.309-3.309C837.021,309.416,838.503,310.897,838.503,312.725z"/>
            <path class="st149" d="M843.441,313.05c0,1.827-1.481,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.828,1.482-3.309,3.309-3.309C841.959,309.741,843.441,311.223,843.441,313.05z"/>
            <path class="st149" d="M840.711,317.109c0,1.827-1.481,3.309-3.309,3.309c-1.827,0-3.309-1.481-3.309-3.309
              c0-1.828,1.481-3.309,3.309-3.309C839.23,313.799,840.711,315.281,840.711,317.109z"/>
            <path class="st149" d="M836.15,320.339c0,1.827-1.481,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.828,1.482-3.309,3.309-3.309C834.669,317.03,836.15,318.511,836.15,320.339z"/>
            <circle class="st149" cx="828.203" cy="324.459" r="2.567"/>
            <circle class="st149" cx="829.02" cy="322.64" r="2.567"/>
            <path class="st150" d="M846.945,314.95c0,1.646-1.334,2.98-2.98,2.98c-1.646,0-2.98-1.334-2.98-2.98s1.334-2.98,2.98-2.98
              C845.611,311.97,846.945,313.304,846.945,314.95z"/>
            <path class="st149" d="M842.762,310.361c0.792-1.176,2.388-1.487,3.563-0.695c1.176,0.792,1.487,2.387,0.695,3.563
              c-0.792,1.176-2.387,1.487-3.563,0.695C842.282,313.132,841.97,311.537,842.762,310.361z"/>
          </g>
          <g class="st151">
            <path class="st149" d="M846.028,312.325c0,1.827-1.482,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.482-3.309,3.309-3.309S846.028,310.498,846.028,312.325z"/>
            <circle class="st149" cx="837.131" cy="319.877" r="2.567"/>
            <circle class="st149" cx="843.449" cy="315.751" r="2.567"/>
          </g>
        </g>
        <g class="st152">
          <g>
            <path class="st149" d="M843.802,326.906c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444s1.542-3.444,3.444-3.444
              S843.802,325.004,843.802,326.906z"/>
            <path class="st149" d="M844.516,324.207c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444
              c0-1.902,1.542-3.444,3.444-3.444S844.516,322.304,844.516,324.207z"/>
            <path class="st149" d="M846.63,325.264c0,2.486-2.015,4.501-4.501,4.501c-2.486,0-4.501-2.015-4.501-4.501
              c0-2.486,2.015-4.501,4.501-4.501C844.615,320.763,846.63,322.778,846.63,325.264z"/>
            <path class="st149" d="M848.615,327.906c0,1.902-1.542,3.444-3.444,3.444s-3.444-1.542-3.444-3.444
              c0-1.902,1.542-3.444,3.444-3.444S848.615,326.004,848.615,327.906z"/>
            <circle class="st150" cx="839.322" cy="321.767" r="4.041"/>
            <path class="st149" d="M851.555,322.371c0,2.232-1.809,4.041-4.041,4.041c-2.232,0-4.041-1.809-4.041-4.041
              c0-2.232,1.809-4.041,4.041-4.041C849.746,318.33,851.555,320.139,851.555,322.371z"/>
            <path class="st149" d="M849.676,319.414c0,1.942-1.574,3.516-3.516,3.516c-1.942,0-3.516-1.574-3.516-3.516
              c0-1.942,1.574-3.516,3.516-3.516C848.102,315.898,849.676,317.472,849.676,319.414z"/>
            <path class="st149" d="M855.931,322.286c0,3.126-2.534,5.66-5.66,5.66c-3.126,0-5.66-2.534-5.66-5.66
              c0-3.126,2.534-5.66,5.66-5.66C853.397,316.626,855.931,319.16,855.931,322.286z"/>
            <path class="st150" d="M853.001,316.169c0,1.827-1.482,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.828,1.482-3.309,3.309-3.309S853.001,314.341,853.001,316.169z"/>
            <path class="st149" d="M846.727,325.372c0,1.827-1.481,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309S846.727,323.545,846.727,325.372z"/>
            <path class="st149" d="M857.347,317.93c0,1.827-1.482,3.309-3.309,3.309c-1.828,0-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309C855.866,314.621,857.347,316.103,857.347,317.93z"/>
            <path class="st149" d="M855.409,322.182c0,1.827-1.482,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.827,1.482-3.309,3.309-3.309C853.928,318.873,855.409,320.354,855.409,322.182z"/>
            <path class="st149" d="M849.692,325.604c0,1.828-1.481,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309S849.692,323.777,849.692,325.604z"/>
            <path class="st149" d="M847.503,322.182c0,1.827-1.482,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309C846.021,318.873,847.503,320.354,847.503,322.182z"/>
            <path class="st149" d="M851.372,319.259c0,1.827-1.481,3.309-3.309,3.309s-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309S851.372,317.432,851.372,319.259z"/>
            <path class="st149" d="M856.31,319.585c0,1.827-1.481,3.309-3.309,3.309c-1.828,0-3.309-1.482-3.309-3.309
              c0-1.828,1.481-3.309,3.309-3.309C854.828,316.276,856.31,317.757,856.31,319.585z"/>
            <path class="st149" d="M853.58,323.643c0,1.828-1.482,3.309-3.309,3.309c-1.827,0-3.309-1.481-3.309-3.309
              s1.482-3.309,3.309-3.309C852.099,320.334,853.58,321.815,853.58,323.643z"/>
            <path class="st149" d="M849.019,326.873c0,1.827-1.481,3.309-3.309,3.309c-1.827,0-3.309-1.482-3.309-3.309
              c0-1.828,1.481-3.309,3.309-3.309C847.538,323.564,849.019,325.046,849.019,326.873z"/>
            <circle class="st149" cx="841.072" cy="330.994" r="2.567"/>
            <circle class="st149" cx="841.889" cy="329.175" r="2.567"/>
            <path class="st150" d="M859.814,321.484c0,1.646-1.334,2.98-2.98,2.98c-1.646,0-2.98-1.334-2.98-2.98s1.334-2.98,2.98-2.98
              C858.48,318.504,859.814,319.838,859.814,321.484z"/>
            <path class="st149" d="M855.631,316.896c0.792-1.176,2.387-1.487,3.563-0.695c1.176,0.792,1.487,2.388,0.695,3.563
              c-0.792,1.176-2.387,1.487-3.563,0.695C855.15,319.667,854.839,318.072,855.631,316.896z"/>
            <path class="st149" d="M846.898,312.103c1.255-1.863,3.782-2.356,5.646-1.101c1.863,1.255,2.356,3.783,1.101,5.646
              c-1.255,1.863-3.783,2.356-5.646,1.101S845.643,313.966,846.898,312.103z"/>
          </g>
          <g class="st151">
            <path class="st150" d="M859.421,315.373c0,2.232-1.809,4.041-4.041,4.041s-4.041-1.809-4.041-4.041
              c0-2.232,1.809-4.041,4.041-4.041S859.421,313.142,859.421,315.373z"/>
            <path class="st149" d="M863.724,315.858c0,2.232-1.809,4.041-4.041,4.041s-4.041-1.809-4.041-4.041
              c0-2.232,1.809-4.041,4.041-4.041S863.724,313.626,863.724,315.858z"/>
            <path class="st149" d="M862.413,314.278c0,1.942-1.574,3.516-3.516,3.516s-3.516-1.574-3.516-3.516
              c0-1.942,1.574-3.516,3.516-3.516S862.413,312.336,862.413,314.278z"/>
            <path class="st149" d="M858.897,318.859c0,1.827-1.482,3.309-3.309,3.309c-1.828,0-3.309-1.482-3.309-3.309
              c0-1.827,1.481-3.309,3.309-3.309C857.415,315.55,858.897,317.032,858.897,318.859z"/>
            <circle class="st149" cx="850" cy="326.412" r="2.567"/>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path class="st95" d="M715.899,640.426c-0.458-0.328-1.053-0.372-1.555-0.115c-0.501,0.257-0.812,0.767-0.812,1.33v16.612
              c0,0.133,0.108,0.241,0.242,0.241c0.133,0,0.241-0.108,0.241-0.241v-16.612c0-0.387,0.206-0.724,0.55-0.901
              c0.344-0.176,0.737-0.147,1.052,0.078l3.294,2.302c0.109,0.079,0.259,0.052,0.337-0.055c0.078-0.108,0.053-0.259-0.055-0.337
              L715.899,640.426z"/>
          </g>
          <path class="st95" d="M717.971,641.86l-0.333,0.382l0.356,0.796l0.895,0.667c0,0,0.528,0.267,0.883,0
            c0.356-0.267,0-0.672,0-0.672L717.971,641.86z"/>
          <path class="st95" d="M713.791,655.949c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            c0.213,0,0.386-0.173,0.386-0.386v-2.348C714.176,656.122,714.004,655.949,713.791,655.949z"/>
        </g>
        <g>
          <g>
            <path class="st95" d="M719.306,638.323c-0.458-0.328-1.054-0.372-1.555-0.115c-0.501,0.257-0.812,0.767-0.812,1.33v16.612
              c0,0.133,0.108,0.241,0.241,0.241s0.241-0.108,0.241-0.241v-16.612c0-0.387,0.206-0.724,0.55-0.901
              c0.344-0.176,0.737-0.147,1.052,0.077l3.294,2.302c0.109,0.079,0.259,0.052,0.337-0.055c0.078-0.109,0.053-0.259-0.055-0.337
              L719.306,638.323z"/>
          </g>
          <path class="st95" d="M721.377,639.757l-0.333,0.382l0.356,0.796l0.895,0.667c0,0,0.528,0.267,0.883,0
            c0.356-0.267,0-0.672,0-0.672L721.377,639.757z"/>
          <path class="st95" d="M717.197,653.846c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            s0.386-0.173,0.386-0.386v-2.348C717.583,654.018,717.41,653.846,717.197,653.846z"/>
        </g>
        <g>
          <g>
            <path class="st95" d="M722.712,636.219c-0.458-0.328-1.053-0.372-1.555-0.115c-0.501,0.257-0.812,0.767-0.812,1.33v16.612
              c0,0.133,0.108,0.241,0.241,0.241s0.241-0.108,0.241-0.241v-16.612c0-0.387,0.206-0.724,0.55-0.901
              c0.344-0.176,0.737-0.147,1.052,0.077l3.294,2.302c0.109,0.079,0.259,0.052,0.337-0.055c0.078-0.108,0.053-0.259-0.055-0.337
              L722.712,636.219z"/>
          </g>
          <path class="st95" d="M724.783,637.654l-0.333,0.382l0.356,0.796l0.895,0.667c0,0,0.527,0.267,0.883,0
            c0.356-0.267,0-0.672,0-0.672L724.783,637.654z"/>
          <path class="st95" d="M720.603,651.742c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            c0.213,0,0.386-0.173,0.386-0.386v-2.348C720.989,651.915,720.816,651.742,720.603,651.742z"/>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path class="st95" d="M911.539,652.655c-0.501-0.258-1.097-0.214-1.555,0.115l-3.294,2.302
              c-0.108,0.077-0.133,0.228-0.055,0.337c0.077,0.108,0.228,0.134,0.337,0.055l3.294-2.302c0.315-0.225,0.708-0.253,1.052-0.077
              c0.345,0.177,0.55,0.513,0.55,0.901v16.612c0,0.133,0.108,0.241,0.241,0.241c0.133,0,0.241-0.108,0.241-0.241v-16.612
              C912.351,653.422,912.04,652.912,911.539,652.655z"/>
          </g>
          <path class="st95" d="M906.113,655.377c0,0-0.356,0.405,0,0.672c0.356,0.267,0.883,0,0.883,0l0.895-0.667l0.356-0.796
            l-0.333-0.382L906.113,655.377z"/>
          <path class="st95" d="M912.093,668.293c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            c0.213,0,0.386-0.173,0.386-0.386v-2.348C912.479,668.465,912.306,668.293,912.093,668.293z"/>
        </g>
        <g>
          <g>
            <path class="st95" d="M908.133,650.551c-0.501-0.258-1.097-0.214-1.555,0.115l-3.294,2.302
              c-0.109,0.078-0.133,0.228-0.055,0.337c0.077,0.108,0.228,0.134,0.337,0.055l3.294-2.302c0.315-0.225,0.708-0.254,1.052-0.077
              c0.345,0.177,0.55,0.513,0.55,0.901v16.612c0,0.133,0.108,0.241,0.242,0.241c0.133,0,0.241-0.108,0.241-0.241v-16.612
              C908.945,651.318,908.634,650.808,908.133,650.551z"/>
          </g>
          <path class="st95" d="M902.706,653.273c0,0-0.356,0.405,0,0.672c0.356,0.267,0.883,0,0.883,0l0.895-0.667l0.356-0.796
            l-0.333-0.382L902.706,653.273z"/>
          <path class="st95" d="M908.687,666.189c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            c0.213,0,0.386-0.173,0.386-0.386v-2.348C909.073,666.362,908.9,666.189,908.687,666.189z"/>
        </g>
        <g>
          <g>
            <path class="st95" d="M904.727,648.448c-0.501-0.257-1.097-0.213-1.555,0.115l-3.294,2.302
              c-0.108,0.077-0.133,0.228-0.055,0.337c0.078,0.108,0.228,0.134,0.337,0.055l3.294-2.302c0.315-0.225,0.708-0.254,1.052-0.078
              c0.345,0.177,0.55,0.513,0.55,0.901v16.612c0,0.133,0.108,0.241,0.241,0.241s0.241-0.108,0.241-0.241v-16.612
              C905.539,649.215,905.228,648.705,904.727,648.448z"/>
          </g>
          <path class="st95" d="M899.3,651.17c0,0-0.356,0.405,0,0.672c0.356,0.267,0.883,0,0.883,0l0.895-0.667l0.356-0.796l-0.333-0.382
            L899.3,651.17z"/>
          <path class="st95" d="M905.281,664.086c-0.213,0-0.386,0.173-0.386,0.386v2.348c0,0.213,0.173,0.386,0.386,0.386
            c0.213,0,0.386-0.173,0.386-0.386v-2.348C905.666,664.259,905.494,664.086,905.281,664.086z"/>
        </g>
      </g>
      <g>
        <path class="st42" d="M842.013,710.24c-11.425,0-31.951-1.353-56.642-9.01c-22.312-6.919-43.616-17.556-63.321-31.614
          c-24.625-17.569-46.807-40.563-65.928-68.34c-0.109-0.158-0.069-0.374,0.089-0.483c0.159-0.109,0.375-0.068,0.483,0.089
          c19.076,27.711,41.2,50.646,65.76,68.168c19.644,14.015,40.882,24.619,63.123,31.515c37.861,11.742,65.845,8.613,66.126,8.58
          c0.186-0.027,0.363,0.115,0.386,0.305c0.022,0.19-0.114,0.364-0.305,0.386C851.686,709.847,848.171,710.24,842.013,710.24z"/>
      </g>
      <g>
        <path class="st42" d="M833.472,699.33c-0.007,0-0.014,0-0.02,0c-0.257-0.015-26.101-1.639-58.449-15.801
          c-29.854-13.07-71.524-39.797-100.506-92.903c-0.091-0.169-0.029-0.38,0.139-0.472c0.17-0.09,0.38-0.029,0.472,0.139
          c28.887,52.934,70.42,79.573,100.174,92.6c32.236,14.113,57.956,15.729,58.212,15.743c0.191,0.012,0.338,0.176,0.327,0.368
          C833.808,699.187,833.655,699.33,833.472,699.33z"/>
      </g>
      <g>
        <g>
          <polygon class="st30" points="512.151,518.429 561.751,547.555 599.954,525.331 549.732,496.438 			"/>
          <polygon class="st153" points="512.151,520.694 561.616,549.905 561.616,547.555 512.151,518.429 			"/>
          <polygon class="st154" points="561.616,549.905 599.954,527.424 599.954,525.331 561.616,547.555 			"/>
        </g>
        <g>
          <g>
            <g>
              <polygon class="st155" points="533.4,497.872 533.4,523.672 562.327,540.779 562.359,514.537 548.013,497.501 					"/>
              <polygon class="st156" points="532.1,498.132 546.847,498.147 570.474,485.151 552.63,486.656 					"/>
              <g>
                <polygon class="st157" points="549.554,491.111 550.896,491.881 552.264,491.109 550.911,490.328 						"/>
                <polygon class="st158" points="550.896,491.696 552.264,490.905 552.264,490.44 550.896,491.213 						"/>
                <polygon class="st159" points="549.554,490.88 550.896,491.696 550.896,491.213 549.554,490.442 						"/>
                <polygon class="st160" points="550.896,494.878 552.264,494.22 552.264,491.094 550.896,491.867 						"/>
                <polygon class="st161" points="549.554,495.079 550.896,494.878 550.896,491.867 549.554,491.097 						"/>
                <polygon class="st162" points="549.554,490.442 550.896,491.213 552.264,490.44 550.911,489.659 						"/>
              </g>
              <polygon class="st163" points="562.327,540.779 585.868,526.862 585.868,503.055 562.359,514.537 					"/>
              <polygon class="st164" points="570.066,485.23 570.017,485.214 546.847,498.147 565.238,517.987 588.771,504.824 
                570.474,485.151 					"/>
              <g>
                <g>
                  <polygon class="st165" points="543.371,529.626 548.939,532.916 549.043,520.445 543.447,517.424 							"/>
                  <path class="st56" d="M543.956,517.401l-0.768-0.415l-0.005,0.873l-0.073,11.615l-0.002,0.301l0.26,0.153l5.046,2.981
                    l0.783,0.463l0.008-0.91l0.099-11.857l0.003-0.316l-0.278-0.15L543.956,517.401z M548.681,532.458l-5.046-2.981l0.073-11.615
                    l5.073,2.739L548.681,532.458z"/>
                </g>
                <polygon class="st56" points="544.315,519.172 544.29,523.02 544.301,523.026 544.259,529.505 548.083,531.728 
                  548.138,524.399 548.129,524.394 548.155,521.181 						"/>
                <polygon class="st67" points="544.628,520.026 544.618,522.703 547.642,524.312 547.826,524.399 547.819,524.395 
                  547.84,521.707 						"/>
                <polygon class="st166" points="541.657,529.292 548.806,533.382 549.82,532.73 542.747,528.646 						"/>
                <g>
                  <polygon class="st167" points="541.657,529.877 548.832,533.988 548.843,533.382 541.658,529.307 							"/>
                  <polygon class="st168" points="548.837,533.971 549.793,533.363 549.793,532.73 548.843,533.382 							"/>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <polygon class="st67" points="536.334,503.277 536.397,509.244 539.291,510.905 539.264,504.915 									"/>
                      <path class="st56" d="M536.087,502.825l0.073,6.589l3.375,1.94l-0.027-6.619L536.087,502.825z M536.634,509.073
                        l-0.054-5.345l2.44,1.365l0.027,5.362L536.634,509.073z"/>
                    </g>
                    <polygon class="st169" points="535.649,502.181 535.658,502.884 539.952,505.284 539.95,504.577 								"/>
                    <polygon class="st169" points="535.729,508.826 535.737,509.51 539.973,511.948 539.971,511.261 								"/>
                  </g>
                  <g>
                    <g>
                      <polygon class="st67" points="539.37,510.95 542.308,512.637 542.319,506.622 539.344,504.959 									"/>
                      <path class="st56" d="M539.128,511.12l3.427,1.97l0.016-6.646l-3.475-1.939L539.128,511.12z M542.069,506.8l-0.007,5.384
                        l-2.449-1.404l-0.021-5.366L542.069,506.8z"/>
                    </g>
                    <polygon class="st169" points="538.658,504.561 543.018,506.998 543.021,506.288 538.654,503.855 								"/>
                    <polygon class="st169" points="538.696,511.213 542.996,513.688 542.999,512.998 538.692,510.527 								"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon class="st67" points="539.318,504.95 539.382,510.917 542.276,512.578 542.249,506.588 									"/>
                      <path class="st56" d="M539.072,504.499l0.073,6.589l3.376,1.94l-0.027-6.619L539.072,504.499z M539.618,510.747
                        l-0.054-5.345l2.439,1.365l0.027,5.362L539.618,510.747z"/>
                    </g>
                    <polygon class="st169" points="538.634,503.855 538.642,504.558 542.937,506.958 542.934,506.251 								"/>
                    <polygon class="st169" points="538.714,510.5 538.722,511.183 542.957,513.622 542.955,512.934 								"/>
                  </g>
                  <g>
                    <g>
                      <polygon class="st67" points="542.354,512.624 545.293,514.31 545.304,508.296 542.328,506.633 									"/>
                      <path class="st56" d="M542.113,512.793l3.427,1.97l0.015-6.646l-3.475-1.939L542.113,512.793z M545.053,508.474
                        l-0.007,5.384l-2.449-1.404l-0.021-5.366L545.053,508.474z"/>
                    </g>
                    <polygon class="st169" points="541.642,506.234 546.003,508.671 546.005,507.961 541.638,505.528 								"/>
                    <polygon class="st169" points="541.681,512.887 545.981,515.362 545.983,514.672 541.677,512.2 								"/>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <polygon class="st67" points="549.672,510.666 549.736,516.633 552.63,518.294 552.603,512.304 									"/>
                      <path class="st56" d="M549.426,510.214l0.073,6.589l3.375,1.94l-0.027-6.619L549.426,510.214z M549.972,516.462
                        l-0.054-5.345l2.439,1.365l0.027,5.362L549.972,516.462z"/>
                    </g>
                    <polygon class="st169" points="548.988,509.571 548.996,510.273 553.291,512.673 553.289,511.966 								"/>
                    <polygon class="st169" points="549.068,516.215 549.076,516.899 553.312,519.337 553.309,518.65 								"/>
                  </g>
                  <g>
                    <g>
                      <polygon class="st67" points="552.709,518.339 555.647,520.026 555.658,514.011 552.682,512.348 									"/>
                      <path class="st56" d="M552.467,518.509l3.427,1.97l0.015-6.646l-3.475-1.939L552.467,518.509z M555.407,514.19
                        l-0.007,5.384l-2.449-1.404l-0.021-5.366L555.407,514.19z"/>
                    </g>
                    <polygon class="st169" points="551.996,511.95 556.357,514.387 556.359,513.677 551.992,511.244 								"/>
                    <polygon class="st169" points="552.035,518.602 556.335,521.078 556.337,520.387 552.031,517.916 								"/>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon class="st67" points="552.657,512.34 552.72,518.306 555.614,519.968 555.587,513.977 									"/>
                      <path class="st56" d="M552.41,511.888l0.073,6.589l3.375,1.94l-0.027-6.619L552.41,511.888z M552.957,518.136l-0.054-5.345
                        l2.439,1.365l0.027,5.362L552.957,518.136z"/>
                    </g>
                    <polygon class="st169" points="551.972,511.244 551.981,511.947 556.275,514.347 556.273,513.64 								"/>
                    <polygon class="st169" points="552.052,517.889 552.06,518.573 556.296,521.011 556.294,520.323 								"/>
                  </g>
                  <g>
                    <g>
                      <polygon class="st67" points="555.693,520.013 558.632,521.699 558.642,515.685 555.667,514.022 									"/>
                      <path class="st56" d="M555.451,520.182l3.427,1.97l0.016-6.646l-3.475-1.939L555.451,520.182z M558.392,515.863
                        l-0.007,5.384l-2.449-1.404l-0.021-5.366L558.392,515.863z"/>
                    </g>
                    <polygon class="st169" points="554.981,513.623 559.341,516.06 559.344,515.35 554.977,512.918 								"/>
                    <polygon class="st169" points="555.019,520.276 559.319,522.751 559.322,522.061 555.015,519.59 								"/>
                  </g>
                </g>
              </g>
              <path class="st170" d="M547.486,524.824c-0.217,0-0.393,0.222-0.393,0.496c0,0.274,0.176,0.496,0.393,0.496
                s0.393-0.222,0.393-0.496C547.879,525.046,547.703,524.824,547.486,524.824z"/>
              <g>
                <polygon class="st67" points="569.029,536.202 580.641,529.243 580.641,518.524 569.029,525.178 						"/>
                <path class="st56" d="M568.504,537.127l12.661-7.445V517.62l-12.661,7.254V537.127z M569.553,525.482l10.564-6.052v9.517
                  l-10.564,6.33V525.482z"/>
              </g>
              <g>
                <rect x="572.526" y="522.751" class="st56" width="0.524" height="11.523"/>
              </g>
              <g>
                <rect x="576.973" y="520.095" class="st56" width="0.524" height="11.524"/>
              </g>
              <polygon class="st171" points="532.1,498.132 533.4,498.977 546.847,499.168 563.869,517.845 565.238,517.987 546.847,498.147 
                          "/>
            </g>
            <g>
              <path class="st172" d="M549.692,499.38c-0.051-0.09-0.165-0.122-0.255-0.07l-0.991,0.563l0.301,0.325l0.903-0.513
                c0.09-0.051,0.121-0.166,0.07-0.256L549.692,499.38z"/>
              <path class="st172" d="M553.08,497.454c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.666c-0.09,0.051-0.121,0.165-0.07,0.256
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L553.08,497.454z"/>
              <path class="st172" d="M556.468,495.528c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.256,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L556.468,495.528z"/>
              <path class="st172" d="M559.855,493.602c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L559.855,493.602z"/>
              <path class="st172" d="M563.243,491.677c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L563.243,491.677z"/>
              <path class="st172" d="M566.631,489.751c-0.051-0.09-0.165-0.122-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.256L566.631,489.751z"/>
              <path class="st172" d="M570.018,487.825c-0.051-0.09-0.165-0.122-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L570.018,487.825z"/>
              <path class="st172" d="M570.219,487.495c-0.09,0.051-0.121,0.165-0.07,0.255l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07
                l1.681-0.956l-0.302-0.324L570.219,487.495z"/>
              <path class="st172" d="M552.965,500.891c-0.051-0.09-0.166-0.121-0.255-0.07l-2.217,1.26l0.301,0.325l2.129-1.21
                c0.09-0.051,0.121-0.166,0.07-0.255L552.965,500.891z"/>
              <path class="st172" d="M556.352,498.965c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L556.352,498.965z"/>
              <path class="st172" d="M559.74,497.039c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.666c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L559.74,497.039z"/>
              <path class="st172" d="M563.128,495.113c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L563.128,495.113z"/>
              <path class="st172" d="M566.516,493.187c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L566.516,493.187z"/>
              <path class="st172" d="M569.903,491.262c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.255L569.903,491.262z"/>
              <path class="st172" d="M573.291,489.336c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.666c0.09-0.051,0.121-0.166,0.07-0.256L573.291,489.336z"/>
              <path class="st172" d="M573.491,489.006c-0.09,0.051-0.121,0.165-0.07,0.255l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07
                l0.46-0.261l-0.302-0.324L573.491,489.006z"/>
              <path class="st172" d="M555.977,502.549c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L555.977,502.549z"/>
              <path class="st172" d="M559.364,500.624c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.256
                l0.028,0.049c0.051,0.09,0.166,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L559.364,500.624z"/>
              <path class="st172" d="M562.752,498.698c-0.051-0.09-0.166-0.122-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.256L562.752,498.698z"/>
              <path class="st172" d="M566.14,496.772c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L566.14,496.772z"/>
              <path class="st172" d="M569.527,494.846c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.256L569.527,494.846z"/>
              <path class="st172" d="M572.915,492.92c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L572.915,492.92z"/>
              <path class="st172" d="M573.116,492.591c-0.09,0.051-0.121,0.166-0.07,0.255l0.028,0.049c0.051,0.09,0.166,0.122,0.256,0.07
                l2.886-1.641L575.913,491L573.116,492.591z"/>
              <path class="st172" d="M555.861,505.986c-0.051-0.09-0.166-0.121-0.255-0.07l-1.02,0.58l0.301,0.325l0.932-0.53
                c0.09-0.051,0.121-0.165,0.07-0.255L555.861,505.986z"/>
              <path class="st172" d="M559.249,504.06c-0.051-0.09-0.166-0.122-0.255-0.07l-2.932,1.666c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L559.249,504.06z"/>
              <path class="st172" d="M562.637,502.134c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L562.637,502.134z"/>
              <path class="st172" d="M566.024,500.208c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L566.024,500.208z"/>
              <path class="st172" d="M569.412,498.283c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.256,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L569.412,498.283z"/>
              <path class="st172" d="M572.8,496.357c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.255L572.8,496.357z"/>
              <path class="st172" d="M576.187,494.431c-0.051-0.09-0.166-0.121-0.255-0.07L573,496.027c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L576.187,494.431z"/>
              <path class="st172" d="M576.388,494.101c-0.09,0.051-0.121,0.165-0.07,0.255l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07
                l1.665-0.947l-0.302-0.324L576.388,494.101z"/>
              <path class="st172" d="M579.474,495.682c-0.09,0.051-0.121,0.165-0.07,0.255l0.028,0.049c0.051,0.09,0.165,0.122,0.255,0.07
                l0.608-0.345l-0.292-0.33L579.474,495.682z"/>
              <path class="st172" d="M582.463,500.725c-0.09,0.051-0.121,0.165-0.07,0.256l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07
                l1.67-1.024l-0.302-0.324L582.463,500.725z"/>
              <path class="st172" d="M558.873,507.645c-0.051-0.09-0.165-0.121-0.255-0.07l-1.986,1.129l0.301,0.325l1.898-1.079
                c0.09-0.051,0.122-0.166,0.07-0.255L558.873,507.645z"/>
              <path class="st172" d="M562.261,505.719c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.666c0.09-0.051,0.122-0.166,0.07-0.256L562.261,505.719z"/>
              <path class="st172" d="M565.648,503.793c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L565.648,503.793z"/>
              <path class="st172" d="M569.036,501.867c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.666c0.09-0.051,0.121-0.166,0.07-0.256L569.036,501.867z"/>
              <path class="st172" d="M572.424,499.941c-0.051-0.09-0.166-0.121-0.256-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L572.424,499.941z"/>
              <path class="st172" d="M575.812,498.016c-0.051-0.09-0.166-0.121-0.256-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L575.812,498.016z"/>
              <path class="st172" d="M579.199,496.09c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L579.199,496.09z"/>
              <path class="st172" d="M562.145,509.155c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.166-0.07,0.256
                l0.028,0.049c0.051,0.09,0.166,0.121,0.256,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.255L562.145,509.155z"/>
              <path class="st172" d="M565.533,507.23c-0.051-0.09-0.166-0.122-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.256L565.533,507.23z"/>
              <path class="st172" d="M568.921,505.304c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L568.921,505.304z"/>
              <path class="st172" d="M572.309,503.378c-0.051-0.09-0.166-0.121-0.256-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L572.309,503.378z"/>
              <path class="st172" d="M575.696,501.452c-0.051-0.09-0.166-0.121-0.256-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L575.696,501.452z"/>
              <path class="st172" d="M579.084,499.526c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.122,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.255L579.084,499.526z"/>
              <path class="st172" d="M579.285,499.197c-0.09,0.051-0.121,0.166-0.07,0.255l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07
                l2.87-1.632l-0.302-0.324L579.285,499.197z"/>
              <path class="st172" d="M561.77,512.74c-0.051-0.09-0.165-0.121-0.255-0.07l-0.789,0.449l0.301,0.325l0.701-0.399
                c0.09-0.051,0.121-0.166,0.07-0.255L561.77,512.74z"/>
              <path class="st172" d="M565.157,510.814c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.666c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.256,0.07l2.932-1.666c0.09-0.051,0.122-0.165,0.07-0.256L565.157,510.814z"/>
              <path class="st172" d="M568.545,508.888c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L568.545,508.888z"/>
              <path class="st172" d="M571.933,506.963c-0.051-0.09-0.166-0.122-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L571.933,506.963z"/>
              <path class="st172" d="M575.32,505.037c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L575.32,505.037z"/>
              <path class="st172" d="M578.708,503.111c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L578.708,503.111z"/>
              <path class="st172" d="M582.096,501.185c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.256,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.256L582.096,501.185z"/>
              <path class="st172" d="M565.042,514.25c-0.051-0.09-0.165-0.121-0.255-0.07l-2.015,1.146l0.301,0.325l1.927-1.096
                c0.09-0.051,0.121-0.166,0.07-0.255L565.042,514.25z"/>
              <path class="st172" d="M568.43,512.325c-0.051-0.09-0.166-0.122-0.256-0.07l-2.932,1.667c-0.09,0.051-0.122,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.166,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L568.43,512.325z"/>
              <path class="st172" d="M571.817,510.399c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.122,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L571.817,510.399z"/>
              <path class="st172" d="M575.205,508.473c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.166,0.07-0.255L575.205,508.473z"/>
              <path class="st172" d="M578.593,506.547c-0.051-0.09-0.165-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.121-0.165,0.07-0.255L578.593,506.547z"/>
              <path class="st172" d="M581.981,504.622c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.166-0.07,0.256
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.166,0.07-0.255L581.981,504.622z"/>
              <path class="st172" d="M585.368,502.696c-0.051-0.09-0.166-0.121-0.255-0.07l-2.932,1.667c-0.09,0.051-0.121,0.165-0.07,0.255
                l0.028,0.049c0.051,0.09,0.165,0.121,0.255,0.07l2.932-1.667c0.09-0.051,0.122-0.165,0.07-0.255L585.368,502.696z"/>
            </g>
            <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="562.5409" y1="532.9156" x2="588.9564" y2="532.9156">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="0.794" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st173" points="584.739,540.801 588.956,525.03 562.541,540.801 				"/>
          </g>
          <path class="st174" d="M533.659,576.051l50.111,1.056l-40.403-47.178C543.367,529.929,534.46,574.566,533.659,576.051z"/>
          <polygon class="st174" points="580.481,564.74 621.299,551.15 546.392,530.745 			"/>
          <path class="st30" d="M558.445,566.892c-14.277,0-25.85,5.727-25.85,12.793c0,7.065,11.573,12.792,25.85,12.792
            c14.277,0,25.85-5.727,25.85-12.792C584.295,572.619,572.722,566.892,558.445,566.892z"/>
          <polygon class="st174" points="523.551,547.969 546.392,530.745 499.765,524.901 			"/>
          <ellipse class="st30" cx="603.923" cy="560.621" rx="25.85" ry="12.792"/>
          <g>
            <polygon class="st175" points="544.06,577.453 559.023,586.093 559.023,567.528 544.06,558.889 				"/>
            <polygon class="st176" points="544.06,574.461 559.023,583.1 559.023,582.917 544.06,574.278 				"/>
            <g>
              <path class="st177" d="M552.204,566.836c-2.289-1.322-5.151,0.33-5.151,2.974l0,0c0,2.734,1.459,5.261,3.827,6.628l0,0
                c2.29,1.322,5.151-0.33,5.151-2.974l0,0C556.031,570.73,554.572,568.204,552.204,566.836z"/>
              <path class="st178" d="M549.097,574.718c-0.088,0-0.17-0.01-0.256-0.012c0.568,0.681,1.247,1.276,2.039,1.733l0,0
                c2.29,1.322,5.151-0.33,5.151-2.974l0,0c0-1.261-0.322-2.471-0.889-3.55C554.548,572.506,552.534,574.718,549.097,574.718z"/>
              <path class="st56" d="M552.503,566.318c-1.263-0.729-2.77-0.729-4.033,0c-1.263,0.729-2.016,2.035-2.016,3.492
                c0,2.938,1.581,5.677,4.126,7.146c0.631,0.365,1.324,0.547,2.016,0.547c0.692,0,1.385-0.182,2.016-0.547
                c1.263-0.729,2.017-2.035,2.017-3.493C556.629,570.526,555.048,567.787,552.503,566.318z M554.015,575.92
                c-0.889,0.513-1.949,0.512-2.836,0c-2.176-1.256-3.527-3.597-3.527-6.109c0-1.025,0.53-1.943,1.418-2.456
                c0.444-0.256,0.931-0.384,1.418-0.384c0.487,0,0.974,0.128,1.418,0.384c2.176,1.256,3.527,3.597,3.527,6.11
                C555.432,574.49,554.902,575.408,554.015,575.92z"/>
            </g>
            <polygon class="st179" points="559.023,586.093 569.691,579.934 569.691,561.159 559.023,567.318 				"/>
            <polygon class="st180" points="544.06,558.889 559.023,567.318 569.691,561.159 554.634,552.574 				"/>
            <g>
              <polygon class="st181" points="544.06,558.916 559.023,567.555 559.921,564.577 544.958,555.938 					"/>
              <polygon class="st182" points="544.593,558.889 558.491,566.913 559.211,564.577 545.313,556.553 					"/>
              <polygon class="st183" points="545.368,558.931 549.54,561.298 550.027,559.653 545.855,557.286 					"/>
              <polygon class="st183" points="553.561,563.719 557.733,566.086 558.219,564.441 554.047,562.074 					"/>
              <polygon class="st179" points="559.023,567.555 569.691,561.396 569.691,558.862 559.921,564.577 					"/>
              <polygon class="st184" points="544.958,555.938 559.921,564.577 569.691,558.862 554.634,550.276 					"/>
            </g>
            <path class="st183" d="M551.485,561.232L551.485,561.232c-0.161,0.373-0.111,0.804,0.132,1.131
              c0.235,0.315,0.723,0.258,0.879-0.102c0.161-0.373,0.111-0.804-0.132-1.131C552.129,560.814,551.641,560.871,551.485,561.232z"
              />
            <g>
              <line class="st177" x1="554.235" y1="573.33" x2="555.881" y2="574.278"/>
              <path class="st56" d="M556.105,573.889l-1.646-0.948c-0.214-0.124-0.489-0.05-0.613,0.165c-0.124,0.215-0.05,0.489,0.165,0.613
                l1.646,0.948c0.07,0.041,0.147,0.06,0.224,0.06c0.155,0,0.306-0.081,0.389-0.225
                C556.394,574.287,556.32,574.013,556.105,573.889z"/>
            </g>
            <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="559.0234" y1="578.3446" x2="585.3629" y2="578.3446">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="0.77" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st185" points="569.691,579.934 559.023,586.093 581.145,586.306 585.363,570.534 569.691,570.383 				"/>
          </g>
          <g>
            <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="604.0814" y1="556.5027" x2="630.4209" y2="556.5027">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="0.77" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st186" points="614.749,558.092 604.081,564.25 626.203,564.464 630.421,548.693 614.749,548.541 				"/>
            <g>
              <path class="st179" d="M608.051,536.481c-2.496-1.673-5.862-2.025-8.843-0.327l-1.287,0.734
                c-5.303,3.022-8.577,8.656-8.577,14.76v0.01c0,3.253,1.773,5.891,4.247,7.299l0,0l6.358,3.72l2.481-4.029l0.84-0.485
                c5.243-3.027,8.477-8.616,8.493-14.668l2.195-3.564L608.051,536.481L608.051,536.481z"/>
              <path class="st187" d="M605.341,539.75l-1.287,0.734c-5.303,3.022-8.577,8.656-8.577,14.76v0.01
                c0,6.465,6.998,10.505,12.597,7.273l1.329-0.767c5.256-3.035,8.494-8.643,8.494-14.712
                C617.897,540.603,610.94,536.56,605.341,539.75z"/>
              <g>
                <path class="st188" d="M614.043,539.227c-2.818-1.637-6.19-1.651-9.021-0.038l-1.287,0.734
                  c-5.491,3.129-8.903,8.999-8.903,15.32c0,3.279,1.69,6.207,4.522,7.842c1.415,0.817,2.969,1.226,4.522,1.226
                  c1.553,0,3.106-0.409,4.522-1.226l1.329-0.767c5.438-3.14,8.817-8.992,8.817-15.271
                  C618.542,543.788,616.86,540.865,614.043,539.227z M609.08,561.2l-1.329,0.767c-2.427,1.401-5.325,1.401-7.752,0
                  c-2.427-1.401-3.876-3.911-3.876-6.714c0-5.868,3.161-11.309,8.251-14.208l1.287-0.734c1.204-0.686,2.522-1.028,3.839-1.028
                  c1.339,0,2.677,0.354,3.894,1.061c2.415,1.404,3.857,3.909,3.857,6.703C617.251,552.866,614.12,558.289,609.08,561.2z"/>
              </g>
              <g>
                <path class="st189" d="M611.991,542.7l-10.157,5.864c-0.113,0.065-0.183,0.186-0.183,0.317v5.442
                  c0,0.282,0.305,0.458,0.549,0.317l10.157-5.864c0.113-0.065,0.183-0.186,0.183-0.317v-5.442
                  C612.54,542.736,612.235,542.56,611.991,542.7z"/>
                <g>
                  <path class="st190" d="M609.678,546.931l0.016-0.877c-0.012-0.012-0.044-0.03-0.097-0.053
                    c-0.054,0.088-0.088,0.145-0.1,0.172l-0.015,0.875c0.012,0.012,0.044,0.03,0.096,0.053
                    C609.632,547.015,609.666,546.958,609.678,546.931z"/>
                  <path class="st190" d="M610.556,545.422c0.02-0.024,0.057-0.081,0.112-0.17c-0.053-0.023-0.089-0.035-0.108-0.036
                    l-0.856,0.519c-0.019,0.024-0.056,0.08-0.11,0.168c0.052,0.023,0.088,0.035,0.107,0.036L610.556,545.422z"/>
                  <path class="st190" d="M609.655,548.228l0.016-0.87c-0.012-0.012-0.044-0.03-0.096-0.053c-0.054,0.087-0.087,0.143-0.1,0.17
                    l-0.015,0.869c0.012,0.012,0.044,0.03,0.096,0.053C609.609,548.31,609.642,548.254,609.655,548.228z"/>
                  <path class="st190" d="M610.564,545.529l-0.018,0.884c0.012,0.012,0.045,0.03,0.098,0.053
                    c0.055-0.088,0.089-0.145,0.102-0.173l0.019-0.886c-0.012-0.012-0.045-0.03-0.098-0.053
                    C610.611,545.444,610.577,545.502,610.564,545.529z"/>
                  <path class="st190" d="M610.537,546.843l-0.018,0.878c0.012,0.012,0.044,0.03,0.097,0.054
                    c0.055-0.087,0.089-0.144,0.102-0.17l0.019-0.879c-0.012-0.012-0.045-0.03-0.098-0.053
                    C610.584,546.759,610.55,546.816,610.537,546.843z"/>
                  <path class="st190" d="M609.653,548.339c-0.019,0.024-0.056,0.078-0.11,0.164c0.052,0.023,0.087,0.036,0.106,0.038
                    l0.848-0.496c0.019-0.024,0.056-0.079,0.111-0.166c-0.053-0.023-0.088-0.036-0.107-0.038L609.653,548.339z"/>
                  <path class="st190" d="M607.911,547.987l0.011-0.862c-0.012-0.012-0.043-0.029-0.094-0.053
                    c-0.052,0.086-0.085,0.142-0.097,0.168l-0.011,0.86c0.012,0.012,0.043,0.03,0.094,0.053
                    C607.866,548.069,607.899,548.013,607.911,547.987z"/>
                  <path class="st190" d="M608.759,546.509c0.019-0.024,0.055-0.079,0.109-0.166c-0.052-0.023-0.087-0.035-0.106-0.037
                    l-0.831,0.504c-0.019,0.024-0.054,0.079-0.107,0.165c0.051,0.023,0.086,0.035,0.104,0.037L608.759,546.509z"/>
                  <path class="st190" d="M607.894,549.261l0.011-0.855c-0.012-0.012-0.043-0.03-0.094-0.053
                    c-0.052,0.085-0.085,0.14-0.097,0.166l-0.011,0.854c0.012,0.012,0.043,0.03,0.094,0.053
                    C607.85,549.342,607.882,549.287,607.894,549.261z"/>
                  <path class="st190" d="M608.747,547.906l-0.013,0.862c0.012,0.012,0.043,0.03,0.095,0.053
                    c0.053-0.085,0.086-0.141,0.099-0.167l0.014-0.864c-0.012-0.012-0.044-0.03-0.095-0.053
                    C608.793,547.823,608.76,547.88,608.747,547.906z"/>
                  <path class="st190" d="M607.893,549.37c-0.018,0.023-0.054,0.077-0.106,0.16c0.051,0.024,0.085,0.036,0.103,0.038
                    l0.824-0.482c0.019-0.023,0.055-0.077,0.108-0.162c-0.052-0.023-0.086-0.036-0.105-0.038L607.893,549.37z"/>
                  <path class="st190" d="M608.767,546.614l-0.013,0.869c0.012,0.012,0.044,0.03,0.095,0.053
                    c0.053-0.086,0.086-0.143,0.099-0.169l0.014-0.87c-0.012-0.012-0.044-0.03-0.096-0.053
                    C608.813,546.531,608.78,546.588,608.767,546.614z"/>
                  <path class="st190" d="M606.193,549.013l0.007-0.847c-0.011-0.012-0.042-0.029-0.092-0.052
                    c-0.051,0.084-0.082,0.139-0.094,0.165l-0.006,0.846c0.011,0.012,0.042,0.029,0.092,0.053
                    C606.15,549.093,606.181,549.038,606.193,549.013z"/>
                  <path class="st190" d="M606.183,550.266l0.007-0.841c-0.011-0.012-0.042-0.029-0.092-0.053
                    c-0.051,0.083-0.082,0.137-0.094,0.163l-0.006,0.839c0.011,0.012,0.042,0.029,0.092,0.053
                    C606.14,550.345,606.171,550.291,606.183,550.266z"/>
                  <path class="st190" d="M607.013,547.565c0.018-0.023,0.053-0.078,0.105-0.163c-0.051-0.023-0.085-0.035-0.103-0.037
                    l-0.808,0.49c-0.018,0.023-0.052,0.077-0.103,0.161c0.05,0.023,0.084,0.035,0.102,0.037L607.013,547.565z"/>
                  <path class="st190" d="M607.021,547.669l-0.009,0.854c0.012,0.012,0.043,0.029,0.093,0.053
                    c0.052-0.084,0.084-0.139,0.096-0.165l0.009-0.856c-0.012-0.012-0.043-0.029-0.094-0.052
                    C607.065,547.588,607.033,547.643,607.021,547.669z"/>
                  <path class="st190" d="M607.008,548.939l-0.009,0.848c0.012,0.012,0.043,0.03,0.093,0.053
                    c0.052-0.083,0.083-0.138,0.095-0.164l0.009-0.849c-0.012-0.012-0.043-0.03-0.093-0.053
                    C607.052,548.858,607.02,548.913,607.008,548.939z"/>
                  <path class="st190" d="M606.182,550.373c-0.018,0.023-0.052,0.075-0.102,0.157c0.05,0.023,0.083,0.036,0.101,0.038
                    l0.801-0.468c0.018-0.023,0.053-0.076,0.104-0.159c-0.05-0.023-0.084-0.036-0.102-0.038L606.182,550.373z"/>
                  <path class="st190" d="M604.522,550.01l0.003-0.833c-0.011-0.012-0.042-0.029-0.09-0.052
                    c-0.049,0.082-0.079,0.136-0.091,0.161l-0.002,0.831c0.011,0.012,0.041,0.029,0.09,0.052
                    C604.481,550.089,604.511,550.036,604.522,550.01z"/>
                  <path class="st190" d="M605.315,548.593c0.018-0.023,0.052-0.076,0.102-0.159c-0.049-0.023-0.083-0.035-0.101-0.037
                    l-0.786,0.477c-0.018,0.023-0.051,0.075-0.1,0.158c0.049,0.023,0.082,0.035,0.099,0.037L605.315,548.593z"/>
                  <path class="st190" d="M604.518,551.242l0.003-0.827c-0.011-0.012-0.041-0.029-0.09-0.052
                    c-0.049,0.081-0.079,0.134-0.091,0.159l-0.002,0.825c0.011,0.012,0.041,0.029,0.09,0.053
                    C604.477,551.32,604.507,551.268,604.518,551.242z"/>
                  <path class="st190" d="M605.324,548.695l-0.005,0.84c0.011,0.012,0.042,0.029,0.091,0.052
                    c0.05-0.082,0.081-0.136,0.092-0.162l0.005-0.841c-0.012-0.012-0.042-0.029-0.092-0.052
                    C605.366,548.615,605.335,548.669,605.324,548.695z"/>
                  <path class="st190" d="M605.317,549.943l-0.005,0.834c0.011,0.012,0.042,0.029,0.091,0.053
                    c0.05-0.082,0.081-0.135,0.092-0.16l0.005-0.835c-0.011-0.012-0.042-0.029-0.091-0.052
                    C605.359,549.864,605.328,549.918,605.317,549.943z"/>
                  <path class="st190" d="M604.518,551.348c-0.017,0.022-0.05,0.073-0.099,0.154c0.049,0.023,0.081,0.036,0.099,0.038
                    l0.779-0.456c0.018-0.022,0.051-0.074,0.101-0.155c-0.049-0.023-0.082-0.036-0.1-0.038L604.518,551.348z"/>
                  <path class="st190" d="M603.426,551.065v1.007c0.011,0.012,0.04,0.029,0.089,0.053c0.048-0.08,0.078-0.132,0.089-0.156
                    l0-1.009c-0.057-0.033-0.087-0.049-0.089-0.047C603.513,550.914,603.483,550.965,603.426,551.065z"/>
                  <path class="st190" d="M603.426,549.654v1.016c0.011,0.012,0.041,0.029,0.089,0.052c0.048-0.081,0.078-0.133,0.089-0.158
                    l0-1.018c-0.011-0.012-0.041-0.029-0.089-0.052C603.467,549.575,603.437,549.629,603.426,549.654z"/>
                </g>
              </g>
              <g>
                <path class="st56" d="M599.526,559.744c1.172,1.123,2.762,1.768,4.36,1.768h0c1.088,0,2.135-0.286,3.111-0.849l1.329-0.767
                  c3.952-2.281,6.606-6.266,7.257-10.713l-16.799,9.699C599.005,559.187,599.249,559.478,599.526,559.744z"/>
              </g>
            </g>
          </g>
          <ellipse class="st30" cx="499.765" cy="539.228" rx="28.951" ry="14.327"/>
          <g>
            <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="492.1653" y1="540.7639" x2="517.2571" y2="540.7639">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="0.794" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st191" points="513.251,548.254 517.257,533.273 492.165,548.254 				"/>
            <g>
              <g>
                <path class="st192" d="M521.057,527.323l-2.138,1.234c-0.117,0.067-0.157,0.217-0.089,0.333
                  c0.045,0.078,0.127,0.122,0.211,0.122c0.041,0,0.083-0.01,0.122-0.033l2.138-1.234c0.117-0.067,0.157-0.216,0.089-0.333
                  C521.323,527.296,521.174,527.256,521.057,527.323z"/>
              </g>
              <g>
                <path class="st192" d="M522.167,527.969l-2.04,1.177c-0.117,0.067-0.157,0.217-0.089,0.333
                  c0.045,0.078,0.127,0.122,0.211,0.122c0.042,0,0.083-0.01,0.122-0.033l2.04-1.177c0.117-0.067,0.157-0.217,0.089-0.333
                  C522.433,527.942,522.284,527.902,522.167,527.969z"/>
              </g>
            </g>
            <path class="st193" d="M520.605,527.635c-0.591-0.341-1.33,0.085-1.33,0.768c0,0.706,0.377,1.358,0.988,1.711l0,0
              c0.591,0.341,1.33-0.085,1.33-0.768C521.594,528.641,521.217,527.988,520.605,527.635z"/>
            <polygon class="st193" points="518.956,528.075 520.323,530.569 521.257,530.042 519.788,527.6 				"/>
            <path class="st194" d="M519.955,527.998c-0.591-0.341-1.33,0.085-1.33,0.768v0c0,0.706,0.377,1.358,0.988,1.711
              c0.591,0.341,1.33-0.085,1.33-0.768C520.943,529.004,520.566,528.351,519.955,527.998z"/>
            <g>
              <path class="st194" d="M517.949,529.704c0,0-0.481,0.5-0.117,0.763c0.546,0.394,1.562,0.004,1.562,0.004l0.993-0.568
                l-1.47-0.849L517.949,529.704z"/>
              <polygon class="st194" points="517.699,530.249 520.388,529.903 520.388,529.471 517.699,529.693 					"/>
              <path class="st193" d="M518.143,528.925c-0.189,0.127-0.341,0.309-0.413,0.525c-0.056,0.167-0.061,0.35,0.101,0.467
                c0.546,0.394,1.563-0.095,1.563-0.095l0.993-0.568l-1.47-0.849L518.143,528.925z"/>
            </g>
            <g>
              <polygon class="st195" points="509.731,534.997 511.263,537.051 511.778,537.375 512.178,535.716 510.998,534.851 					"/>
              <g>
                <g id="XMLID_9_">
                  <g>
                    <path class="st195" d="M496.054,543.546c-1.415,0.686-2.852,2.863-2.253,3.727c0.437,0.631,1.241,0.805,1.241,0.805
                      c-0.117-0.164-0.739-0.753-0.737-0.981l0.002-0.036c0.013-1.409,0.792-2.699,2.034-3.366l0.218-0.117
                      c0.154-0.083,0.319-0.122,0.477-0.122c0.529,0,1.01,0.423,1.01,1.01c0,0.198-0.015,0.395-0.046,0.587l0.223,0.119
                      C498.331,544.09,498.004,542.601,496.054,543.546z"/>
                    <path class="st195" d="M510.147,535.316c-0.416-0.119-0.823,0.106-1.837,0.955c-0.006,0.006-0.458,0.422-0.601,0.62
                      c-0.329,0.294-0.839,2.342-0.839,2.342s-0.008,0.156,0.641,0.518c0.331,0.185,0.802,0.44,0.802,0.44
                      c-0.117-0.164-0.605-0.705-0.603-0.933v-0.037c0.013-1.407,0.794-2.697,2.034-3.364l0.22-0.117
                      c0.155-0.083,0.317-0.122,0.477-0.122c0.298,0,0.581,0.135,0.771,0.358C511.21,535.976,511.015,535.565,510.147,535.316z"/>
                    <path class="st196" d="M512.445,532.861c-0.812-0.901-1.744-1.682-2.775-2.32c-2.089-1.292-5.819-3.589-6.246-3.783
                      c-0.597-0.272-1.708-0.335-1.708-0.335l-0.893-0.016v0.016l0.511,0.443l7.546,4.031c0,0,1.269,0.28,0.89,1.69
                      c-0.57,1.017-3.493,2.829-3.493,2.829l-0.407,0.225l0.002,0.002c0,0,0.384,0.779,0.469,1.294c0,0-0.807,1.806-0.672,2.875
                      l-6.335,3.425l-0.317-2.071l-0.667-1.334c-0.018-0.005-0.036-0.011-0.054-0.018c0,0,0,0-0.002,0l-0.181,0.099
                      c-1.354,0.229-2.847,0.343-2.847,0.343s-0.46,0.808-1.952,1.755c-0.937,0.592-3.82,1.754-3.82,1.754
                      s-1.031,0.921-3.35-0.499c-1.255-0.769-1.698-1.534-1.85-2.047l-0.446,0.038c0.046,0.156,0.075,0.25,0.075,0.25
                      c-0.127,0.573-0.908,0.044-1.37-0.566h-0.018c-0.01,1.077,0.07,2.606,0.07,2.606s1.736,3.381,9.003,4.754
                      c0,0,1.572-0.199,2.169-0.415c0,0,0.024-0.255,0.101-0.646c0.2-1.032,0.761-3.007,2.177-3.693
                      c1.951-0.945,2.278,0.543,2.169,1.627l7.752-4.328l1.022-0.379c0,0-0.025-2.131,1.113-3.709
                      c0.002,0,0.002-0.001,0.002-0.003c0.145-0.213,0.306-0.408,0.488-0.563c0.088-0.086,0.182-0.168,0.28-0.246
                      c1.663-1.314,2.372-0.701,2.675,0.089c0.002,0,0.002,0.002,0.002,0.002c0.234,0.617,0.223,1.339,0.223,1.339l0.704-0.434
                      c0,0,1.573-2.278,0.218-3.796C512.611,533.045,512.525,532.95,512.445,532.861z M488.461,546.638
                      c0,0-3.474-1.464-4.744-2.873v-1.085c0,0,1.22,1.411,4.744,2.928V546.638z M491.386,545.308
                      c-0.758,0.088-2.44-0.02-2.115-0.508c0,0,0.591-0.249,1.407-0.721c0.817-0.472,1.793-0.828,1.956-0.488
                      C492.797,543.932,492.146,545.219,491.386,545.308z"/>
                    <path class="st197" d="M512.445,532.861c-0.812-0.901-1.744-1.682-2.775-2.32c-2.089-1.292-5.819-3.589-6.246-3.783
                      c-0.597-0.272-1.708-0.335-1.708-0.335l-0.893-0.016v0.016l0.511,0.443l7.546,4.031c0,0,1.269,0.28,0.89,1.69
                      c-0.569,1.017-3.493,2.829-3.493,2.829l-0.407,0.225l0.002,0.002c0,0,0.384,0.779,0.469,1.294c0,0-0.807,1.806-0.672,2.875
                      l-6.335,3.425l-0.317-2.071l-0.667-1.334c-0.018-0.005-0.036-0.011-0.054-0.018c0,0,0,0-0.002,0l-0.181,0.099
                      c-0.898,0.152-1.856,0.253-2.4,0.304c-0.013,0.063-2.452,3.449-2.888,4.181c-0.717,1.204-0.971,2.514-0.96,3.869
                      c0.471-0.066,1.465-0.218,1.912-0.38c0,0,0.024-0.255,0.101-0.646c0.2-1.032,0.761-3.007,2.177-3.693
                      c1.951-0.945,2.278,0.543,2.169,1.627l7.752-4.328l1.022-0.379c0,0-0.025-2.131,1.113-3.709
                      c0.002,0,0.002-0.001,0.002-0.003c0.145-0.213,0.306-0.408,0.488-0.563c0.088-0.086,0.182-0.168,0.28-0.246
                      c1.663-1.314,2.372-0.701,2.675,0.089c0.002,0,0.002,0.002,0.002,0.002c0.234,0.617,0.223,1.339,0.223,1.339l0.704-0.434
                      c0,0,1.573-2.278,0.218-3.796C512.611,533.045,512.525,532.95,512.445,532.861z"/>
                    <path class="st192" d="M510.439,535.618c-0.159,0-0.322,0.039-0.477,0.122l-0.22,0.117c-1.24,0.667-2.021,1.957-2.034,3.364
                      v0.037c-0.002,0.228,0.068,0.43,0.185,0.594c0.285,0.398,0.849,0.566,1.327,0.289l0.296-0.171
                      c1.194-0.69,1.929-1.964,1.929-3.343c0-0.255-0.091-0.48-0.237-0.653C511.02,535.753,510.737,535.618,510.439,535.618z
                      M509.547,539.006l-0.155,0.089c-0.353,0.203-0.794-0.054-0.791-0.462v-0.019c0.008-0.735,0.415-1.409,1.062-1.757
                      l0.114-0.06c0.081-0.044,0.166-0.063,0.249-0.063c0.276,0,0.527,0.221,0.527,0.527
                      C510.554,537.981,510.17,538.646,509.547,539.006z"/>
                    <path class="st198" d="M510.027,536.733c-0.083,0-0.168,0.02-0.249,0.063l-0.114,0.06c-0.647,0.348-1.054,1.022-1.062,1.757
                      v0.019c-0.003,0.408,0.438,0.665,0.791,0.462l0.155-0.089c0.623-0.359,1.007-1.025,1.007-1.746
                      C510.554,536.954,510.304,536.733,510.027,536.733z"/>
                    <polygon class="st199" points="499.507,526.707 506.741,530.897 508.879,530.897 501.447,526.618 								"/>
                    <path class="st199" d="M508.082,532.413l-2.133-0.737l-0.566,0.404l0.73,2.849c0.934-0.508,1.495-0.929,2.165-1.466
                      C508.656,533.16,508.543,532.558,508.082,532.413z"/>
                    <path class="st193" d="M499.706,526.866l-0.921-0.443c-0.399,0.063-0.786,0.187-1.15,0.369
                      c-2.045,1.018-4.842,2.213-6.085,3.289c0,0-0.125,0.47,0.115,0.818l-0.063,0.047c0.457,0.524,2.766,2.969,6.726,3.903
                      l6.275-3.48c0.542-0.311,1.155-0.474,1.78-0.474h0.358l-7.033-4.031H499.706z"/>
                    <g>
                      <path class="st197" d="M499.586,539.1c0.049,0.057-1.121,0.769-1.238,0.73l0.667,1.334l0.317,2.071l6.335-3.425
                        c-0.135-1.069,1.073-3.106,1.073-3.106c-0.085-0.514-0.655-1.183-0.655-1.183L499.586,539.1z"/>
                      <path class="st95" d="M506.117,535.495l-0.022-0.025l-6.529,3.594l-0.044,0.024l0.016,0.019
                        c-0.124,0.138-1.074,0.691-1.178,0.685l-0.093-0.031l0.708,1.416l0.325,2.122l6.386-3.452l0.025-0.013l-0.004-0.028
                        c-0.132-1.043,1.056-3.06,1.068-3.08l0.007-0.013l-0.003-0.015C506.695,536.179,506.14,535.523,506.117,535.495z
                        M505.624,539.788l-6.26,3.384l-0.309-2.014l-0.646-1.296c0.286-0.077,1.143-0.629,1.206-0.723
                        c0.003-0.005,0.006-0.009,0.008-0.014l6.452-3.552c0.102,0.125,0.542,0.684,0.622,1.123
                        C506.587,536.886,505.515,538.745,505.624,539.788z"/>
                    </g>
                    <path class="st199" d="M499.169,535.397l-0.841,1.83l-0.018,1.351c0.185-0.016,0.43,0.006,0.724,0.127
                      c0.083,0.034,0.158,0.073,0.225,0.115l6.408-3.553l-0.651-3.044L499.169,535.397z"/>
                    <path class="st192" d="M501.23,526.407l-1.438-0.019c-0.339-0.031-0.677-0.02-1.007,0.036l0.921,0.443l-0.126-0.116
                      l2.138-0.016L501.23,526.407z"/>
                    <path class="st200" d="M499.586,539.1c-0.084-0.098-0.192-0.195-0.327-0.28c-0.067-0.042-0.142-0.081-0.225-0.115
                      c-0.294-0.12-0.538-0.143-0.724-0.127c-0.27,0.023-0.418,0.127-0.418,0.127l-0.54-0.379c0,0-0.569,0.037-0.325,0.75
                      c0,0,0.68,0.526,1.266,0.737c0.002,0,0.002,0,0.002,0l0.034-0.02l0.019,0.037c0.117,0.039,0.228,0.063,0.327,0.068
                      c0.296,0.011,0.591-0.037,0.818-0.091c0.241-0.057,0.355-0.333,0.22-0.54C499.677,539.212,499.635,539.157,499.586,539.1z"
                      />
                    <path class="st201" d="M497.06,538.959c-0.026-0.005-0.049-0.013-0.072-0.021c0.009,0.043,0.022,0.089,0.039,0.138
                      c0,0,0.68,0.526,1.266,0.737c0.002,0,0.002,0,0.002,0l0.034-0.02l0.019,0.037c0.117,0.039,0.228,0.063,0.327,0.068
                      c0.296,0.011,0.591-0.037,0.818-0.091c0.229-0.054,0.341-0.307,0.235-0.509C498.85,539.635,497.988,539.133,497.06,538.959z
                      "/>
                    <path class="st202" d="M498.294,539.813c0.018,0.006,0.036,0.013,0.054,0.018l-0.019-0.037L498.294,539.813z"/>
                    <path class="st110" d="M491.603,530.948c-0.057-0.063-0.085-0.099-0.085-0.099l-3.493,4.002
                      c1.952,3.939,7.755,4.738,7.755,4.738l2.548-4.738C494.368,533.917,492.06,531.471,491.603,530.948z"/>
                    <path class="st203" d="M490.001,532.587l-1.976,2.264c0.23,0.463,0.514,0.882,0.835,1.262
                      c0.552-0.134,1.116-0.221,1.679-0.332c1.024-0.201,2.042-0.435,3.063-0.652c1.093-0.233,2.186-0.469,3.278-0.707
                      c-2.067-0.741-3.556-1.847-4.433-2.632C491.626,532.038,490.811,532.304,490.001,532.587z"/>
                    <path class="st192" d="M497.035,543.457c-0.158,0-0.322,0.039-0.477,0.122l-0.218,0.117
                      c-1.241,0.667-2.021,1.957-2.034,3.366l-0.002,0.036c-0.002,0.228,0.068,0.43,0.186,0.594
                      c0.285,0.398,0.849,0.566,1.329,0.29l0.294-0.169c1.022-0.591,1.71-1.611,1.885-2.758c0.031-0.192,0.046-0.389,0.046-0.587
                      C498.045,543.88,497.564,543.457,497.035,543.457z M496.143,546.844l-0.155,0.089c-0.353,0.203-0.794-0.054-0.791-0.462
                      l0.002-0.018c0.007-0.737,0.413-1.411,1.061-1.759l0.114-0.06c0.081-0.044,0.166-0.063,0.249-0.063
                      c0.277,0,0.527,0.221,0.527,0.527C497.15,545.819,496.766,546.484,496.143,546.844z"/>
                    <path class="st198" d="M496.623,544.571c-0.083,0-0.168,0.019-0.249,0.063l-0.114,0.06
                      c-0.648,0.348-1.054,1.022-1.061,1.759l-0.002,0.018c-0.003,0.408,0.438,0.665,0.791,0.462l0.155-0.089
                      c0.623-0.36,1.007-1.025,1.007-1.746C497.15,544.793,496.9,544.571,496.623,544.571z"/>
                    <path class="st193" d="M487.7,535.088l-0.028,0.011c0,0-0.363,0.361-0.501,0.46c-1.196,0.859-2.94,5.035-2.94,5.035
                      s2.061,2.03,5.261,3.169c0,0,2.883-1.162,3.82-1.754c1.492-0.947,1.952-1.755,1.952-1.755s-2.439-0.529-5.206-2.336
                      C488.575,536.949,487.937,535.695,487.7,535.088z"/>
                    <path class="st56" d="M490.679,544.08c-0.817,0.472-1.407,0.721-1.407,0.721c-0.325,0.488,1.357,0.596,2.115,0.508
                      c0.76-0.09,1.41-1.376,1.248-1.716C492.471,543.252,491.495,543.608,490.679,544.08z"/>
                    <path class="st204" d="M489.319,544.897c-0.03-0.004-0.056-0.012-0.082-0.02c-0.109,0.423,1.433,0.515,2.15,0.432
                      c0.586-0.069,1.106-0.85,1.239-1.36C491.669,544.658,490.551,545.075,489.319,544.897z"/>
                    <path class="st192" d="M508.879,530.897h-2.138h-0.358c-0.625,0-1.238,0.163-1.78,0.474l-6.275,3.48l-2.548,4.738
                      c0,0-5.803-0.799-7.755-4.738l3.493-4.002c0,0,0.028,0.036,0.085,0.099l0.063-0.047c-0.241-0.348-0.115-0.818-0.115-0.818
                      c-0.639,0.555-1.742,1.861-2.641,2.966c-0.299,0.371-0.578,0.719-0.805,1.009c-0.238,0.299-0.421,0.535-0.526,0.67
                      c0.005,0.018,0.039,0.151,0.12,0.361c0.238,0.607,0.875,1.861,2.359,2.831c2.767,1.807,5.206,2.336,5.206,2.336
                      s1.493-0.114,2.847-0.343l0.181-0.099c-0.586-0.211-1.266-0.737-1.266-0.737c-0.244-0.713,0.325-0.75,0.325-0.75l0.54,0.379
                      c0,0,0.148-0.104,0.418-0.127l0.018-1.351l0.841-1.83l5.847-3.174l0.651,3.044l-6.408,3.553
                      c0.135,0.085,0.243,0.182,0.327,0.28l6.283-3.459l0.407-0.225c0,0,2.923-1.812,3.493-2.829
                      C510.148,531.177,508.879,530.897,508.879,530.897z M508.278,533.463c-0.67,0.537-1.232,0.958-2.165,1.466l-0.73-2.849
                      l0.566-0.404l2.133,0.737C508.543,532.558,508.656,533.16,508.278,533.463z"/>
                    <path class="st195" d="M508.879,530.897h-2.496c-0.218,0-0.435,0.021-0.648,0.06c-0.444,0.213-0.88,0.441-1.303,0.715
                      c-0.506,0.327-3.448,1.873-5.014,2.788c-0.563,0.328-0.979,0.837-1.272,1.42c-0.878,1.75-1.772,3.286-2.434,4.336
                      c-0.01,0.016,1.437-0.141,2.4-0.304l0.181-0.099c-0.586-0.211-1.266-0.737-1.266-0.737c-0.244-0.713,0.325-0.75,0.325-0.75
                      l0.54,0.379c0,0,0.148-0.104,0.418-0.127l0.018-1.351l0.841-1.83l5.847-3.174l0.651,3.044l-6.408,3.553
                      c0.135,0.085,0.243,0.182,0.327,0.28l6.283-3.459l0.407-0.225c0,0,2.923-1.812,3.493-2.829
                      C510.148,531.177,508.879,530.897,508.879,530.897z M508.278,533.463c-0.67,0.537-1.232,0.958-2.165,1.466l-0.73-2.849
                      l0.566-0.404l2.133,0.737C508.543,532.558,508.656,533.16,508.278,533.463z"/>
                    <path class="st198" d="M487.968,532.589c-0.247-0.023-0.439,0.207-0.377,0.447c0.059,0.224,0.154,0.509,0.302,0.766
                      c0.05,0.084,0.125,0.169,0.212,0.254c0.228-0.29,0.506-0.638,0.805-1.009c-0.037-0.041-0.081-0.081-0.129-0.12
                      C488.5,532.693,488.204,532.61,487.968,532.589z"/>
                    <path class="st201" d="M487.586,532.887c-0.008,0.048-0.008,0.098,0.005,0.149c0.059,0.224,0.154,0.509,0.302,0.766
                      c0.05,0.084,0.125,0.169,0.212,0.254c0.142-0.181,0.305-0.386,0.479-0.603C488.177,533.431,487.782,533.242,487.586,532.887
                      z"/>
                    <path class="st192" d="M484.293,541.218c0.151,0.513,0.595,1.277,1.85,2.047c2.318,1.42,3.35,0.499,3.35,0.499
                      c-3.2-1.139-5.261-3.169-5.261-3.169S484.181,540.838,484.293,541.218z"/>
                    <path class="st192" d="M483.717,542.679v1.085c1.271,1.409,4.744,2.873,4.744,2.873v-1.03
                      C484.937,544.09,483.717,542.679,483.717,542.679z"/>
                    <path class="st196" d="M487.483,534.851c0,0-2.019,1.023-3.252,2.676c-0.538,0.724-0.887,1.189-1.121,1.523l0.132,0.026
                      c0.169,0.687,0.467,1.71,0.605,2.18l0.446-0.038c-0.112-0.381-0.062-0.623-0.062-0.623s1.744-4.176,2.94-5.035
                      c0.138-0.099,0.501-0.46,0.501-0.46l0.028-0.011c-0.081-0.21-0.115-0.343-0.12-0.361
                      C487.517,534.807,487.483,534.851,487.483,534.851z"/>
                    <path class="st56" d="M483.242,539.076l-0.132-0.026c-0.301,0.429-0.415,0.643-0.506,0.918
                      c-0.047,0.145-0.067,0.519-0.07,0.973h0.018c0.462,0.61,1.243,1.139,1.37,0.566c0,0-0.029-0.094-0.075-0.25
                      C483.709,540.786,483.411,539.762,483.242,539.076z"/>
                    <path class="st204" d="M483.807,541.118c-0.043,0.012-0.087,0.019-0.134,0.014c-0.487-0.047-0.799-0.278-1.13-0.604
                      c-0.005,0.126-0.008,0.264-0.009,0.411h0.018c0.462,0.61,1.243,1.139,1.37,0.566c0,0-0.029-0.094-0.075-0.25
                      C483.835,541.214,483.821,541.167,483.807,541.118z"/>
                  </g>
                </g>
              </g>
              <path class="st192" d="M493.739,532.588c-1.362-0.912-2.046-1.787-2.221-2.051c-0.175-0.263,0,0.1,0,0.1v0.211
                c0,0,2.039,2.798,7.021,4.08l0.012-0.202c0,0-0.541-0.103-1.354-0.387C496.383,534.055,495.102,533.5,493.739,532.588z"/>
              <g>
                
                  <rect x="503.077" y="524.261" transform="matrix(0.4932 -0.8699 0.8699 0.4932 -204.9897 705.6954)" class="st192" width="0.163" height="9.032"/>
              </g>
              <g>
                
                  <rect x="505.007" y="524.323" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -205.3604 701.9307)" class="st192" width="0.163" height="8.909"/>
              </g>
              <g>
                <path class="st192" d="M505.827,536.38l-0.753,0.419c-0.086,0.048-0.135,0.158-0.108,0.247
                  c0.018,0.062,0.068,0.097,0.125,0.097c0.026,0,0.054-0.007,0.081-0.022l0.763-0.431c0.084-0.047,0.127-0.155,0.096-0.24
                  C506.001,536.364,505.91,536.333,505.827,536.38z"/>
              </g>
            </g>
            <path class="st192" d="M510.331,533.307c-0.044,0-0.089,0.01-0.132,0.034l-0.06,0.032c-0.342,0.184-0.557,0.54-0.561,0.928v0.01
              c-0.002,0.216,0.231,0.352,0.418,0.244l0.082-0.047c0.329-0.19,0.532-0.542,0.532-0.923
              C510.61,533.424,510.478,533.307,510.331,533.307z"/>
            <g>
              <path class="st192" d="M517.838,530.016l-3.219,1.858c-0.498,0.288-0.808,0.824-0.808,1.399v1.739
                c0,0.192-0.099,0.364-0.266,0.46c-0.167,0.096-0.365,0.095-0.531-0.001l-2.798-1.626c-0.117-0.068-0.266-0.028-0.334,0.088
                c-0.068,0.117-0.028,0.266,0.088,0.334l2.798,1.626c0.16,0.093,0.336,0.139,0.511,0.139c0.174,0,0.349-0.046,0.508-0.137
                c0.319-0.184,0.51-0.514,0.51-0.883v-1.739c0-0.402,0.216-0.776,0.564-0.977l3.219-1.858c0.117-0.067,0.157-0.216,0.089-0.333
                C518.104,529.988,517.955,529.948,517.838,530.016z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="478.3234" y1="357.1864" x2="577.4764" y2="357.1864">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <path class="st205" d="M508.356,339.932l-14.762-9.528l0.575,20.791l-15.845-11.044l0.852,30.785h11.275l26.919,15.119
          l-0.052,6.689h60.159V366.57L507.85,321.63L508.356,339.932z M532.64,376.308l-0.013,1.691l-12.552-8.749L532.64,376.308z"/>
        <g>
          <path class="st46" d="M511.314,352.314c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.505,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003v-27.159h-1.944V352.314z"/>
          <g>
            <polygon class="st92" points="491.899,334.01 523.368,351.953 535.73,321.778 504.197,303.646 				"/>
            <polygon class="st93" points="493.483,332.979 523.108,349.877 534.184,322.833 504.506,305.775 				"/>
            <g>
              <path class="st94" d="M532.315,321.758l-0.507-0.292l-0.759,1.856l-2.031-1.166l0.759-1.857l-0.508-0.292l-0.759,1.857
                l-2.032-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.509-0.292l-0.758,1.859l-2.034-1.169
                l0.758-1.859l-0.509-0.293l-0.758,1.86l-2.036-1.169l0.758-1.86l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.862
                l-0.51-0.293l-0.758,1.862l-2.038-1.171l0.757-1.862l-0.51-0.293l-0.757,1.863l-2.039-1.171l0.757-1.863l-0.51-0.293
                l-0.757,1.864l-2.041-1.173l0.757-1.864l-0.51-0.294l-0.757,1.865l-2.042-1.173l0.756-1.865l-0.511-0.294l-0.756,1.865
                l-1.841-1.058l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056
                l-0.232,0.574l1.841,1.055l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.055l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.053
                l-0.928,2.289l-1.84-1.053l-0.232,0.572l1.84,1.052l-0.927,2.288l-1.839-1.052l-0.232,0.572l1.839,1.051l-0.927,2.286
                l-1.839-1.051l-0.231,0.572l1.839,1.05l-0.926,2.285l-1.839-1.049l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291
                l0.752-1.854l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.29l0.752-1.852l2.036,1.162
                l-0.753,1.851l0.509,0.29l0.753-1.851l2.035,1.162l-0.753,1.85l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.849l0.508,0.29
                l0.753-1.849l2.032,1.16l-0.754,1.849l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.289l0.754-1.847l2.03,1.158
                l-0.754,1.847l0.507,0.289l0.754-1.846l2.028,1.158l-0.755,1.846l0.507,0.289l0.755-1.845l2.027,1.157l-0.755,1.845
                l0.507,0.289l0.755-1.844l1.866,1.065l0.233-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275
                l1.867,1.067l0.233-0.569l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.277l1.867,1.069l0.233-0.57
                l-1.867-1.069l0.933-2.279l1.868,1.07l0.233-0.57l-1.868-1.07l0.934-2.281l1.868,1.071l0.234-0.57l-1.868-1.071l0.934-2.282
                l1.868,1.072l0.234-0.571l-1.869-1.072l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.074L532.315,321.758z M526.245,321.269
                l2.031,1.167l-0.934,2.285l-2.031-1.166L526.245,321.269z M513.682,327.462l-2.035-1.166l0.931-2.286l2.036,1.166
                L513.682,327.462z M515.121,325.468l2.034,1.166l-0.931,2.284l-2.034-1.165L515.121,325.468z M511.137,326.005l-2.037-1.166
                l0.93-2.287l2.037,1.167L511.137,326.005z M510.905,326.576l-0.93,2.285l-2.037-1.165l0.929-2.285L510.905,326.576z
                M511.414,326.867l2.036,1.165l-0.93,2.283l-2.035-1.164L511.414,326.867z M513.958,328.324l2.034,1.164l-0.93,2.282
                l-2.034-1.163L513.958,328.324z M516.5,329.779l2.033,1.164l-0.931,2.281l-2.032-1.163L516.5,329.779z M516.733,329.209
                l0.931-2.284l2.033,1.165l-0.932,2.283L516.733,329.209z M517.897,326.354l0.932-2.286l2.034,1.166l-0.932,2.285
                L517.897,326.354z M517.388,326.062l-2.034-1.166l0.932-2.287l2.035,1.167L517.388,326.062z M514.845,324.605l-2.036-1.167
                l0.931-2.288l2.036,1.168L514.845,324.605z M512.3,323.146l-2.037-1.167l0.931-2.289l2.038,1.169L512.3,323.146z
                M509.753,321.687l-2.038-1.168l0.93-2.29l2.039,1.169L509.753,321.687z M509.521,322.259l-0.93,2.287l-2.038-1.167l0.93-2.289
                L509.521,322.259z M508.358,325.118l-0.93,2.286l-2.038-1.166l0.929-2.287L508.358,325.118z M507.197,327.975l-0.929,2.285
                l-2.037-1.165l0.928-2.285L507.197,327.975z M507.706,328.267l2.037,1.165l-0.929,2.283l-2.036-1.164L507.706,328.267z
                M510.251,329.723l2.035,1.164l-0.93,2.282l-2.035-1.163L510.251,329.723z M512.795,331.178l2.034,1.163l-0.93,2.281
                l-2.033-1.162L512.795,331.178z M515.337,332.632l2.032,1.163l-0.93,2.28l-2.032-1.162L515.337,332.632z M517.877,334.085
                l2.031,1.162l-0.931,2.279l-2.031-1.161L517.877,334.085z M518.11,333.515l0.931-2.281l2.031,1.163l-0.931,2.28L518.11,333.515
                z M519.274,330.664l0.932-2.283l2.032,1.164l-0.932,2.282L519.274,330.664z M520.438,327.81l0.932-2.284l2.032,1.165
                l-0.933,2.283L520.438,327.81z M521.604,324.955l0.933-2.286l2.033,1.167l-0.933,2.285L521.604,324.955z M521.096,324.663
                l-2.034-1.166l0.932-2.287l2.034,1.167L521.096,324.663z M518.554,323.205l-2.035-1.167l0.932-2.288l2.035,1.168
                L518.554,323.205z M516.01,321.746l-2.036-1.168l0.932-2.289l2.037,1.169L516.01,321.746z M513.464,320.286l-2.038-1.169
                l0.931-2.29l2.038,1.17L513.464,320.286z M510.917,318.825l-2.039-1.169l0.931-2.292l2.039,1.17L510.917,318.825z
                M508.368,317.363l-2.04-1.17l0.93-2.292l2.041,1.171L508.368,317.363z M508.135,317.936l-0.93,2.29l-2.04-1.169l0.93-2.291
                L508.135,317.936z M506.973,320.799l-0.93,2.289l-2.04-1.168l0.929-2.29L506.973,320.799z M505.811,323.66l-0.929,2.287
                l-2.039-1.167l0.929-2.288L505.811,323.66z M504.65,326.518l-0.928,2.286l-2.039-1.166l0.928-2.286L504.65,326.518z
                M503.489,329.375l-0.928,2.284l-2.038-1.164l0.927-2.285L503.489,329.375z M503.999,329.666l2.037,1.164l-0.928,2.283
                l-2.037-1.164L503.999,329.666z M506.545,331.122l2.036,1.164l-0.929,2.282l-2.036-1.163L506.545,331.122z M509.09,332.576
                l2.035,1.163l-0.929,2.281l-2.034-1.162L509.09,332.576z M511.633,334.03l2.033,1.162l-0.929,2.279l-2.033-1.161
                L511.633,334.03z M514.174,335.483l2.032,1.162l-0.93,2.278l-2.032-1.16L514.174,335.483z M516.714,336.935l2.031,1.161
                l-0.93,2.277l-2.03-1.16L516.714,336.935z M519.253,338.385l2.029,1.16l-0.931,2.276l-2.029-1.159L519.253,338.385z
                M519.485,337.816l0.931-2.278l2.029,1.161l-0.931,2.278L519.485,337.816z M520.649,334.968l0.932-2.28l2.03,1.162
                l-0.932,2.279L520.649,334.968z M521.813,332.118l0.932-2.282l2.03,1.163l-0.932,2.281L521.813,332.118z M522.978,329.266
                l0.933-2.283l2.031,1.165l-0.933,2.282L522.978,329.266z M524.144,326.412l0.933-2.284l2.031,1.166l-0.934,2.284
                L524.144,326.412z M523.704,319.81l2.033,1.168l-0.934,2.286l-2.033-1.167L523.704,319.81z M521.161,318.35l2.034,1.168
                l-0.933,2.288l-2.034-1.167L521.161,318.35z M518.617,316.888l2.035,1.169l-0.933,2.289l-2.035-1.168L518.617,316.888z
                M516.071,315.426l2.037,1.17l-0.933,2.29l-2.037-1.169L516.071,315.426z M513.523,313.962l2.038,1.171l-0.932,2.291
                l-2.038-1.17L513.523,313.962z M510.973,312.498l2.04,1.171l-0.932,2.292l-2.039-1.171L510.973,312.498z M508.422,311.033
                l2.041,1.172l-0.932,2.294l-2.041-1.171L508.422,311.033z M505.869,309.566l2.042,1.173l-0.931,2.295l-2.042-1.172
                L505.869,309.566z M504.706,312.436l2.042,1.172l-0.93,2.293l-2.042-1.171L504.706,312.436z M503.543,315.303l2.042,1.171
                l-0.93,2.292l-2.041-1.17L503.543,315.303z M502.381,318.168l2.041,1.17l-0.929,2.29l-2.041-1.169L502.381,318.168z
                M501.22,321.031l2.041,1.169l-0.929,2.288l-2.04-1.167L501.22,321.031z M500.06,323.892l2.041,1.167l-0.928,2.287l-2.04-1.166
                L500.06,323.892z M498.9,326.752l2.04,1.166l-0.927,2.285l-2.04-1.165L498.9,326.752z M498.855,333.058l-2.039-1.164
                l0.926-2.284l2.04,1.165L498.855,333.058z M501.402,334.512l-2.038-1.163l0.927-2.283l2.038,1.164L501.402,334.512z
                M503.948,335.965l-2.036-1.162l0.927-2.282l2.037,1.163L503.948,335.965z M506.493,337.417l-2.035-1.162l0.928-2.281
                l2.036,1.163L506.493,337.417z M509.035,338.869l-2.034-1.161l0.928-2.28l2.034,1.162L509.035,338.869z M511.576,340.319
                l-2.032-1.16l0.928-2.279l2.033,1.161L511.576,340.319z M514.115,341.768l-2.031-1.159l0.929-2.277l2.032,1.16L514.115,341.768
                z M516.653,343.217l-2.03-1.159l0.929-2.276l2.03,1.16L516.653,343.217z M519.189,344.664l-2.028-1.158l0.929-2.275
                l2.029,1.159L519.189,344.664z M521.723,346.111l-2.027-1.157l0.93-2.274l2.028,1.158L521.723,346.111z M522.886,343.269
                l-2.028-1.158l0.931-2.276l2.028,1.159L522.886,343.269z M524.049,340.426l-2.028-1.16l0.931-2.277l2.028,1.16L524.049,340.426
                z M525.214,337.58l-2.029-1.161l0.932-2.279l2.029,1.161L525.214,337.58z M526.379,334.733l-2.029-1.162l0.932-2.28
                l2.029,1.163L526.379,334.733z M527.545,331.883l-2.029-1.163l0.933-2.282l2.03,1.164L527.545,331.883z M528.712,329.031
                l-2.03-1.164l0.934-2.283l2.03,1.165L528.712,329.031z M529.88,326.178l-2.03-1.165l0.935-2.285l2.03,1.166L529.88,326.178z"/>
            </g>
            <g>
              <polygon class="st92" points="502.983,338.397 504,338.978 515.685,310.251 514.665,309.665 					"/>
            </g>
            <g>
              <polygon class="st92" points="512.895,344.051 513.91,344.63 525.613,315.96 524.596,315.375 					"/>
            </g>
            <g>
              <polygon class="st92" points="498.285,319.438 529.003,337.024 529.469,335.885 498.749,318.292 					"/>
            </g>
          </g>
          <polygon class="st95" points="523.368,351.953 524.569,351.775 536.302,322.995 535.73,321.778 			"/>
        </g>
        <polygon class="st96" points="492.594,332.29 520.596,350.112 528.461,329.663 500.23,313.43 		"/>
        <g>
          <path class="st46" d="M547.65,373.968c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.505,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003V346.81h-1.944V373.968z"/>
          <g>
            <polygon class="st92" points="528.234,355.664 559.703,373.607 572.066,343.432 540.533,325.3 				"/>
            <polygon class="st93" points="529.819,354.633 559.443,371.531 570.519,344.487 540.841,327.429 				"/>
            <g>
              <path class="st94" d="M568.651,343.412l-0.508-0.292l-0.759,1.856l-2.03-1.166l0.759-1.857l-0.508-0.292l-0.759,1.857
                l-2.031-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.508-0.292l-0.758,1.859l-2.035-1.169
                l0.758-1.86l-0.509-0.293l-0.758,1.86l-2.036-1.169l0.758-1.861l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.861
                l-0.509-0.293l-0.758,1.862l-2.039-1.171l0.757-1.862l-0.51-0.293l-0.757,1.863l-2.04-1.172l0.757-1.863l-0.51-0.293
                l-0.757,1.864l-2.041-1.173l0.757-1.864l-0.511-0.293l-0.757,1.864l-2.043-1.173l0.757-1.865l-0.511-0.294l-0.756,1.865
                l-1.842-1.058l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056
                l-0.233,0.574l1.841,1.056l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.054l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.054
                l-0.928,2.289l-1.84-1.053l-0.232,0.572l1.84,1.052l-0.928,2.288l-1.839-1.052l-0.232,0.572l1.839,1.051l-0.927,2.286
                l-1.839-1.051l-0.231,0.572l1.839,1.05l-0.926,2.285l-1.839-1.049l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291
                l0.752-1.854l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.291l0.752-1.852l2.036,1.162
                l-0.753,1.851l0.509,0.29l0.753-1.851l2.035,1.162l-0.753,1.85l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.849l0.508,0.29
                l0.753-1.849l2.032,1.16l-0.754,1.848l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.29l0.754-1.847l2.03,1.158
                l-0.754,1.847l0.507,0.289l0.754-1.846l2.028,1.158l-0.755,1.846l0.507,0.289l0.755-1.845l2.027,1.157l-0.755,1.845
                l0.507,0.289l0.755-1.845l1.866,1.065l0.232-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275
                l1.867,1.067l0.233-0.569l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.277l1.867,1.069l0.233-0.569
                l-1.867-1.069l0.933-2.279l1.868,1.07l0.233-0.57l-1.868-1.07l0.933-2.281l1.868,1.071l0.233-0.57l-1.868-1.071l0.934-2.282
                l1.868,1.072l0.234-0.571l-1.868-1.073l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.074L568.651,343.412z M562.58,342.923
                l2.031,1.167l-0.934,2.285l-2.031-1.166L562.58,342.923z M550.017,349.115l-2.035-1.166l0.93-2.286l2.036,1.167
                L550.017,349.115z M551.457,347.122l2.034,1.166l-0.931,2.284l-2.034-1.165L551.457,347.122z M547.472,347.658l-2.037-1.166
                l0.93-2.287l2.037,1.167L547.472,347.658z M547.24,348.23l-0.93,2.285l-2.037-1.165l0.93-2.286L547.24,348.23z
                M547.749,348.521l2.035,1.165l-0.93,2.283l-2.035-1.164L547.749,348.521z M550.293,349.978l2.034,1.164l-0.93,2.282
                l-2.034-1.164L550.293,349.978z M552.835,351.433l2.033,1.164l-0.931,2.281l-2.032-1.163L552.835,351.433z M553.068,350.863
                l0.931-2.284l2.033,1.165l-0.931,2.283L553.068,350.863z M554.232,348.008l0.932-2.285l2.034,1.166l-0.932,2.284
                L554.232,348.008z M553.724,347.716l-2.034-1.166l0.932-2.287l2.035,1.167L553.724,347.716z M551.181,346.259l-2.036-1.167
                l0.931-2.288l2.036,1.168L551.181,346.259z M548.635,344.8l-2.037-1.167l0.931-2.289l2.038,1.168L548.635,344.8z
                M546.089,343.341l-2.038-1.168l0.93-2.29l2.039,1.169L546.089,343.341z M545.856,343.913l-0.93,2.287l-2.038-1.167l0.93-2.289
                L545.856,343.913z M544.694,346.772l-0.929,2.286l-2.038-1.166l0.929-2.287L544.694,346.772z M543.532,349.629l-0.929,2.284
                l-2.037-1.165l0.929-2.285L543.532,349.629z M544.041,349.92l2.036,1.165l-0.929,2.283l-2.036-1.164L544.041,349.92z
                M546.587,351.377l2.035,1.164l-0.93,2.282l-2.035-1.163L546.587,351.377z M549.13,352.832l2.033,1.163l-0.93,2.281
                l-2.033-1.162L549.13,352.832z M551.672,354.286l2.032,1.163l-0.93,2.28l-2.032-1.162L551.672,354.286z M554.213,355.739
                l2.031,1.162l-0.931,2.279l-2.031-1.161L554.213,355.739z M554.445,355.169l0.931-2.281l2.031,1.163l-0.931,2.28
                L554.445,355.169z M555.609,352.318l0.932-2.283l2.032,1.164l-0.932,2.282L555.609,352.318z M556.774,349.464l0.932-2.284
                l2.032,1.165l-0.933,2.283L556.774,349.464z M557.939,346.609l0.933-2.286l2.033,1.167l-0.933,2.285L557.939,346.609z
                M557.431,346.317l-2.034-1.166l0.932-2.287l2.034,1.167L557.431,346.317z M554.889,344.859l-2.035-1.167l0.932-2.288
                l2.035,1.168L554.889,344.859z M552.345,343.4l-2.036-1.168l0.932-2.289l2.037,1.169L552.345,343.4z M549.8,341.94
                l-2.038-1.169l0.931-2.29l2.038,1.17L549.8,341.94z M547.252,340.479l-2.039-1.169l0.931-2.291l2.039,1.17L547.252,340.479z
                M544.703,339.017l-2.04-1.17l0.93-2.293l2.041,1.171L544.703,339.017z M544.471,339.59l-0.93,2.29l-2.04-1.169l0.93-2.291
                L544.471,339.59z M543.308,342.453l-0.93,2.289l-2.039-1.168l0.929-2.29L543.308,342.453z M542.146,345.313l-0.929,2.287
                l-2.039-1.167l0.929-2.288L542.146,345.313z M540.985,348.172l-0.928,2.286l-2.039-1.166l0.928-2.287L540.985,348.172z
                M539.824,351.029l-0.928,2.284l-2.038-1.164l0.927-2.285L539.824,351.029z M540.334,351.32l2.037,1.164l-0.928,2.283
                l-2.037-1.164L540.334,351.32z M542.88,352.776l2.036,1.164l-0.929,2.282l-2.036-1.163L542.88,352.776z M545.425,354.23
                l2.035,1.163l-0.929,2.281l-2.035-1.162L545.425,354.23z M547.968,355.684l2.033,1.162l-0.929,2.279l-2.033-1.161
                L547.968,355.684z M550.51,357.137l2.032,1.162l-0.93,2.278l-2.032-1.161L550.51,357.137z M553.05,358.588l2.031,1.161
                l-0.93,2.277l-2.03-1.16L553.05,358.588z M555.588,360.039l2.029,1.16l-0.931,2.276l-2.029-1.159L555.588,360.039z
                M555.82,359.47l0.931-2.279l2.029,1.161l-0.931,2.278L555.82,359.47z M556.984,356.622l0.931-2.28l2.03,1.162l-0.932,2.279
                L556.984,356.622z M558.148,353.772l0.932-2.282l2.03,1.163l-0.932,2.281L558.148,353.772z M559.314,350.919l0.933-2.283
                l2.031,1.165l-0.933,2.282L559.314,350.919z M560.48,348.065l0.933-2.284l2.031,1.166l-0.934,2.284L560.48,348.065z
                M560.039,341.464l2.033,1.168l-0.934,2.287l-2.033-1.167L560.039,341.464z M557.496,340.003l2.034,1.168l-0.934,2.288
                l-2.034-1.168L557.496,340.003z M554.952,338.542l2.035,1.169L556.054,342l-2.035-1.168L554.952,338.542z M552.406,337.08
                l2.037,1.17l-0.933,2.29l-2.037-1.169L552.406,337.08z M549.858,335.616l2.038,1.171l-0.932,2.291l-2.038-1.17L549.858,335.616
                z M547.309,334.152l2.039,1.171l-0.932,2.292l-2.039-1.17L547.309,334.152z M544.758,332.687l2.041,1.172l-0.932,2.294
                l-2.041-1.171L544.758,332.687z M542.205,331.22l2.042,1.173l-0.931,2.295l-2.042-1.172L542.205,331.22z M541.041,334.09
                l2.042,1.172l-0.93,2.293l-2.042-1.171L541.041,334.09z M539.879,336.957l2.042,1.171l-0.93,2.292l-2.041-1.17L539.879,336.957
                z M538.717,339.822l2.041,1.17l-0.929,2.29l-2.041-1.169L538.717,339.822z M537.555,342.685l2.041,1.169l-0.929,2.288
                l-2.04-1.168L537.555,342.685z M536.395,345.546l2.041,1.167L537.508,349l-2.04-1.166L536.395,345.546z M535.236,348.406
                l2.04,1.166l-0.927,2.285l-2.04-1.165L535.236,348.406z M535.19,354.712l-2.039-1.164l0.926-2.284l2.04,1.165L535.19,354.712z
                M537.738,356.166l-2.038-1.163l0.927-2.283l2.038,1.164L537.738,356.166z M540.283,357.619l-2.036-1.162l0.927-2.282
                l2.037,1.163L540.283,357.619z M542.828,359.071l-2.035-1.162l0.927-2.281l2.036,1.163L542.828,359.071z M545.371,360.523
                l-2.034-1.161l0.928-2.28l2.034,1.162L545.371,360.523z M547.911,361.973l-2.032-1.16l0.928-2.279l2.033,1.161L547.911,361.973
                z M550.451,363.422l-2.031-1.159l0.929-2.277l2.032,1.16L550.451,363.422z M552.988,364.871l-2.03-1.159l0.929-2.276
                l2.03,1.159L552.988,364.871z M555.524,366.318l-2.028-1.158l0.93-2.275l2.029,1.159L555.524,366.318z M558.058,367.765
                l-2.027-1.157l0.93-2.274l2.028,1.158L558.058,367.765z M559.221,364.923l-2.028-1.158l0.93-2.276l2.028,1.159L559.221,364.923
                z M560.385,362.079l-2.028-1.159l0.931-2.277l2.028,1.16L560.385,362.079z M561.549,359.234l-2.028-1.161l0.932-2.279
                l2.029,1.162L561.549,359.234z M562.714,356.386l-2.029-1.162l0.932-2.28l2.029,1.163L562.714,356.386z M563.881,353.537
                l-2.029-1.163l0.933-2.282l2.03,1.164L563.881,353.537z M565.047,350.685l-2.029-1.164l0.934-2.283l2.03,1.165L565.047,350.685
                z M566.215,347.832l-2.03-1.165l0.934-2.285l2.03,1.166L566.215,347.832z"/>
            </g>
            <g>
              <polygon class="st92" points="539.318,360.051 540.336,360.632 552.02,331.905 551,331.318 					"/>
            </g>
            <g>
              <polygon class="st92" points="549.23,365.705 550.245,366.284 561.948,337.614 560.931,337.029 					"/>
            </g>
            <g>
              <polygon class="st92" points="534.62,341.092 565.338,358.678 565.805,357.539 535.085,339.946 					"/>
            </g>
          </g>
          <polygon class="st95" points="559.703,373.607 560.905,373.429 572.638,344.649 572.066,343.432 			"/>
        </g>
        <g>
          <path class="st46" d="M494.014,360.843c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.504,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003v-27.159h-1.944V360.843z"/>
          <g>
            <polygon class="st92" points="474.598,342.539 506.067,360.482 518.43,330.307 486.897,312.175 				"/>
            <polygon class="st93" points="476.183,341.507 505.807,358.406 516.883,331.362 487.205,314.304 				"/>
            <g>
              <path class="st94" d="M515.014,330.287l-0.508-0.292l-0.759,1.856l-2.03-1.166l0.759-1.857l-0.508-0.292l-0.759,1.857
                l-2.032-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.508-0.292l-0.759,1.859l-2.034-1.169
                l0.758-1.86l-0.509-0.292l-0.758,1.86l-2.036-1.169l0.758-1.86l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.861l-0.51-0.293
                l-0.758,1.862l-2.039-1.171l0.757-1.862l-0.51-0.293l-0.757,1.863l-2.04-1.172l0.757-1.863l-0.51-0.293l-0.757,1.863
                l-2.041-1.173l0.757-1.864l-0.511-0.293l-0.757,1.864l-2.043-1.173l0.757-1.865l-0.511-0.294l-0.756,1.865l-1.841-1.058
                l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056l-0.232,0.574
                l1.841,1.055l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.055l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.053l-0.928,2.289
                l-1.84-1.053l-0.232,0.572l1.84,1.052l-0.928,2.288l-1.839-1.052l-0.232,0.572l1.839,1.051l-0.927,2.286l-1.839-1.051
                l-0.232,0.572l1.839,1.05l-0.926,2.284l-1.839-1.049l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291l0.752-1.854
                l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.29l0.752-1.852l2.036,1.162l-0.753,1.851
                l0.509,0.29l0.753-1.851l2.035,1.162l-0.753,1.85l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.849l0.508,0.29l0.753-1.849
                l2.032,1.16l-0.754,1.848l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.289l0.754-1.847l2.03,1.158l-0.754,1.847
                l0.507,0.289l0.754-1.846l2.028,1.158l-0.755,1.846l0.507,0.289l0.755-1.845l2.027,1.157l-0.755,1.845l0.507,0.289l0.755-1.845
                l1.866,1.065l0.232-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275l1.866,1.067l0.233-0.568
                l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.278l1.867,1.069l0.233-0.569l-1.867-1.069
                l0.933-2.279l1.868,1.07l0.233-0.57l-1.868-1.07l0.934-2.281l1.868,1.071l0.234-0.57l-1.868-1.072l0.934-2.282l1.868,1.072
                l0.234-0.571l-1.868-1.073l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.074L515.014,330.287z M508.944,329.798l2.031,1.167
                l-0.934,2.285l-2.031-1.166L508.944,329.798z M496.381,335.99l-2.036-1.166l0.931-2.286l2.036,1.166L496.381,335.99z
                M497.821,333.997l2.034,1.166l-0.931,2.284l-2.034-1.165L497.821,333.997z M493.836,334.533l-2.037-1.166l0.93-2.287
                l2.037,1.167L493.836,334.533z M493.604,335.105l-0.93,2.285l-2.036-1.165l0.929-2.285L493.604,335.105z M494.113,335.396
                l2.035,1.165l-0.93,2.283l-2.035-1.164L494.113,335.396z M496.657,336.853l2.034,1.164l-0.93,2.282l-2.034-1.164
                L496.657,336.853z M499.199,338.308l2.033,1.164l-0.931,2.281l-2.032-1.163L499.199,338.308z M499.432,337.738l0.931-2.284
                l2.033,1.165l-0.932,2.283L499.432,337.738z M500.596,334.882l0.932-2.286l2.034,1.166l-0.932,2.285L500.596,334.882z
                M500.088,334.591l-2.034-1.166l0.932-2.287l2.035,1.167L500.088,334.591z M497.545,333.134l-2.036-1.167l0.931-2.288
                l2.036,1.168L497.545,333.134z M494.999,331.675l-2.037-1.167l0.931-2.289l2.038,1.169L494.999,331.675z M492.453,330.216
                l-2.038-1.168l0.93-2.29l2.039,1.169L492.453,330.216z M492.22,330.788l-0.93,2.288l-2.038-1.167l0.93-2.288L492.22,330.788z
                M491.058,333.647l-0.929,2.286l-2.038-1.166l0.929-2.287L491.058,333.647z M489.896,336.504l-0.929,2.285l-2.037-1.165
                l0.928-2.285L489.896,336.504z M490.405,336.795l2.036,1.165l-0.929,2.283l-2.036-1.164L490.405,336.795z M492.951,338.251
                l2.035,1.164l-0.93,2.282l-2.035-1.163L492.951,338.251z M495.494,339.707l2.034,1.163l-0.93,2.281l-2.033-1.162
                L495.494,339.707z M498.036,341.161l2.032,1.163l-0.93,2.28l-2.032-1.162L498.036,341.161z M500.577,342.614l2.031,1.162
                l-0.931,2.279l-2.031-1.161L500.577,342.614z M500.809,342.044l0.931-2.281l2.031,1.163l-0.931,2.28L500.809,342.044z
                M501.973,339.192l0.932-2.283l2.032,1.164l-0.932,2.282L501.973,339.192z M503.138,336.339l0.932-2.284l2.032,1.165
                l-0.933,2.283L503.138,336.339z M504.303,333.483l0.933-2.286l2.033,1.166l-0.933,2.285L504.303,333.483z M503.795,333.192
                l-2.034-1.166l0.933-2.287l2.034,1.167L503.795,333.192z M501.253,331.734l-2.035-1.167l0.932-2.288l2.035,1.168
                L501.253,331.734z M498.709,330.275l-2.036-1.168l0.932-2.289l2.037,1.169L498.709,330.275z M496.164,328.815l-2.038-1.169
                l0.931-2.29l2.038,1.17L496.164,328.815z M493.616,327.354l-2.039-1.169l0.931-2.291l2.039,1.17L493.616,327.354z
                M491.067,325.892l-2.04-1.17l0.931-2.293l2.041,1.171L491.067,325.892z M490.835,326.465l-0.93,2.29l-2.04-1.169l0.93-2.291
                L490.835,326.465z M489.672,329.327l-0.93,2.289l-2.04-1.168l0.929-2.29L489.672,329.327z M488.51,332.188l-0.929,2.287
                l-2.039-1.167l0.928-2.288L488.51,332.188z M487.349,335.047l-0.928,2.286l-2.039-1.166l0.928-2.286L487.349,335.047z
                M486.188,337.904l-0.928,2.284l-2.039-1.164l0.927-2.285L486.188,337.904z M486.698,338.195l2.037,1.164l-0.928,2.283
                l-2.037-1.164L486.698,338.195z M489.244,339.65l2.036,1.164l-0.928,2.282l-2.036-1.163L489.244,339.65z M491.789,341.105
                l2.035,1.163l-0.929,2.28l-2.035-1.162L491.789,341.105z M494.332,342.559l2.033,1.162L495.436,346l-2.033-1.161
                L494.332,342.559z M496.874,344.012l2.032,1.162l-0.93,2.278l-2.032-1.161L496.874,344.012z M499.414,345.463l2.031,1.161
                l-0.93,2.277l-2.03-1.16L499.414,345.463z M501.952,346.914l2.029,1.16l-0.931,2.276l-2.029-1.159L501.952,346.914z
                M502.184,346.345l0.931-2.279l2.03,1.161l-0.931,2.278L502.184,346.345z M503.348,343.496l0.931-2.28l2.03,1.162l-0.932,2.279
                L503.348,343.496z M504.512,340.647l0.932-2.282l2.03,1.163l-0.933,2.281L504.512,340.647z M505.677,337.794l0.933-2.283
                l2.031,1.164l-0.933,2.282L505.677,337.794z M506.843,334.94l0.933-2.285l2.031,1.166l-0.934,2.284L506.843,334.94z
                M506.403,328.339l2.033,1.168l-0.934,2.287l-2.033-1.167L506.403,328.339z M503.86,326.878l2.034,1.169l-0.934,2.288
                l-2.034-1.168L503.86,326.878z M501.316,325.417l2.035,1.169l-0.933,2.289l-2.035-1.168L501.316,325.417z M498.77,323.954
                l2.037,1.17l-0.933,2.29l-2.037-1.169L498.77,323.954z M496.222,322.491l2.039,1.171l-0.932,2.291l-2.038-1.17L496.222,322.491
                z M493.673,321.027l2.04,1.171l-0.932,2.292l-2.039-1.171L493.673,321.027z M491.122,319.561l2.041,1.172l-0.931,2.293
                l-2.041-1.171L491.122,319.561z M488.569,318.095l2.042,1.173l-0.931,2.295l-2.042-1.172L488.569,318.095z M487.405,320.964
                l2.042,1.172l-0.93,2.293l-2.042-1.171L487.405,320.964z M486.243,323.831l2.042,1.171l-0.93,2.291l-2.041-1.17
                L486.243,323.831z M485.08,326.697l2.041,1.17l-0.929,2.29l-2.041-1.169L485.08,326.697z M483.919,329.56l2.041,1.169
                l-0.929,2.288l-2.04-1.168L483.919,329.56z M482.759,332.421l2.04,1.167l-0.928,2.287l-2.04-1.166L482.759,332.421z
                M481.6,335.281l2.04,1.166l-0.927,2.285l-2.04-1.165L481.6,335.281z M481.554,341.586l-2.039-1.164l0.926-2.284l2.04,1.165
                L481.554,341.586z M484.102,343.041l-2.038-1.163l0.927-2.283l2.038,1.164L484.102,343.041z M486.647,344.494l-2.036-1.162
                l0.927-2.282l2.037,1.163L486.647,344.494z M489.192,345.946l-2.035-1.162l0.927-2.281l2.036,1.163L489.192,345.946z
                M491.734,347.397l-2.034-1.161l0.928-2.28l2.034,1.162L491.734,347.397z M494.275,348.848l-2.032-1.16l0.928-2.278
                l2.033,1.161L494.275,348.848z M496.815,350.297l-2.031-1.159l0.929-2.277l2.031,1.16L496.815,350.297z M499.352,351.746
                l-2.03-1.159l0.929-2.276l2.03,1.16L499.352,351.746z M501.888,353.193l-2.028-1.158l0.929-2.275l2.029,1.159L501.888,353.193z
                M504.422,354.64l-2.027-1.157l0.93-2.274l2.028,1.158L504.422,354.64z M505.585,351.798l-2.028-1.158l0.931-2.276l2.028,1.159
                L505.585,351.798z M506.749,348.954l-2.028-1.159l0.931-2.277l2.028,1.16L506.749,348.954z M507.913,346.109l-2.028-1.161
                l0.932-2.279l2.029,1.162L507.913,346.109z M509.078,343.261l-2.029-1.162l0.933-2.28l2.029,1.163L509.078,343.261z
                M510.244,340.412l-2.029-1.163l0.933-2.282l2.029,1.164L510.244,340.412z M511.411,337.56l-2.03-1.164l0.934-2.283l2.03,1.165
                L511.411,337.56z M512.579,334.707l-2.03-1.165l0.934-2.285l2.03,1.166L512.579,334.707z"/>
            </g>
            <g>
              <polygon class="st92" points="485.682,346.926 486.7,347.507 498.384,318.78 497.364,318.193 					"/>
            </g>
            <g>
              <polygon class="st92" points="495.594,352.58 496.609,353.159 508.312,324.489 507.295,323.904 					"/>
            </g>
            <g>
              <polygon class="st92" points="480.984,327.966 511.702,345.553 512.169,344.414 481.448,326.821 					"/>
            </g>
          </g>
          <polygon class="st95" points="506.067,360.482 507.269,360.304 519.001,331.524 518.43,330.307 			"/>
        </g>
        <polygon class="st96" points="528.878,353.948 556.881,371.771 564.745,351.321 536.515,335.088 		"/>
        <g>
          <path class="st46" d="M532.156,382.652c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.504,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003v-27.158h-1.944V382.652z"/>
          <g>
            <polygon class="st92" points="512.74,364.348 544.21,382.29 556.572,352.115 525.039,333.983 				"/>
            <polygon class="st93" points="514.325,363.316 543.949,380.214 555.026,353.17 525.347,336.112 				"/>
            <g>
              <path class="st94" d="M553.157,352.096l-0.507-0.292l-0.759,1.856l-2.03-1.166l0.759-1.857l-0.508-0.292l-0.759,1.857
                l-2.032-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.509-0.292l-0.758,1.859l-2.034-1.169
                l0.758-1.86l-0.509-0.293l-0.758,1.86l-2.036-1.169l0.758-1.861l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.862
                l-0.51-0.293l-0.758,1.862l-2.038-1.171l0.757-1.862l-0.51-0.293l-0.757,1.863l-2.039-1.172l0.757-1.863l-0.51-0.293
                l-0.757,1.863l-2.041-1.173l0.757-1.864l-0.511-0.293l-0.756,1.865l-2.043-1.174l0.756-1.865l-0.511-0.293l-0.756,1.865
                l-1.841-1.058l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056
                l-0.232,0.574l1.841,1.056l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.055l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.054
                l-0.928,2.289l-1.84-1.053l-0.232,0.573l1.84,1.052l-0.927,2.288l-1.84-1.052l-0.232,0.572l1.839,1.051l-0.927,2.286
                l-1.839-1.05l-0.232,0.571l1.839,1.05l-0.926,2.285l-1.839-1.049l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291
                l0.752-1.854l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.29l0.752-1.852l2.036,1.162
                l-0.753,1.851l0.509,0.29l0.753-1.851l2.035,1.162l-0.753,1.85l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.849l0.508,0.29
                l0.753-1.849l2.032,1.16l-0.754,1.848l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.29l0.754-1.847l2.03,1.159
                l-0.754,1.847l0.507,0.289l0.754-1.846l2.028,1.158l-0.754,1.846l0.507,0.289l0.755-1.846l2.027,1.157l-0.755,1.845
                l0.507,0.289l0.755-1.845l1.866,1.065l0.233-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275
                l1.867,1.067l0.233-0.569l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.277l1.867,1.069l0.233-0.569
                l-1.867-1.069l0.933-2.279l1.868,1.07l0.233-0.57l-1.868-1.07l0.934-2.281l1.868,1.071l0.234-0.57l-1.868-1.071l0.934-2.282
                l1.869,1.072l0.234-0.571l-1.868-1.072l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.074L553.157,352.096z M547.086,351.607
                l2.032,1.167l-0.934,2.285l-2.031-1.166L547.086,351.607z M534.523,357.799l-2.036-1.166l0.931-2.286l2.036,1.167
                L534.523,357.799z M535.963,355.805l2.034,1.166l-0.931,2.284l-2.034-1.165L535.963,355.805z M531.979,356.342l-2.037-1.166
                l0.93-2.287l2.037,1.167L531.979,356.342z M531.746,356.913l-0.93,2.285l-2.036-1.165l0.929-2.286L531.746,356.913z
                M532.255,357.205l2.035,1.165l-0.93,2.283l-2.035-1.165L532.255,357.205z M534.799,358.661l2.034,1.165l-0.93,2.282
                l-2.034-1.164L534.799,358.661z M537.342,360.117l2.033,1.164l-0.931,2.281l-2.032-1.163L537.342,360.117z M537.574,359.546
                l0.931-2.284l2.033,1.165l-0.932,2.283L537.574,359.546z M538.739,356.691l0.932-2.285l2.034,1.166l-0.932,2.285
                L538.739,356.691z M538.23,356.4l-2.034-1.166l0.931-2.287l2.035,1.167L538.23,356.4z M535.687,354.942l-2.036-1.167
                l0.931-2.288l2.036,1.168L535.687,354.942z M533.142,353.484l-2.037-1.168l0.931-2.289l2.038,1.169L533.142,353.484z
                M530.595,352.024l-2.038-1.168l0.93-2.29l2.039,1.169L530.595,352.024z M530.362,352.596l-0.93,2.288l-2.038-1.167l0.93-2.289
                L530.362,352.596z M529.2,355.455l-0.929,2.286l-2.038-1.166l0.929-2.287L529.2,355.455z M528.038,358.312l-0.929,2.285
                l-2.037-1.165l0.929-2.285L528.038,358.312z M528.548,358.604l2.037,1.165l-0.929,2.283l-2.036-1.164L528.548,358.604z
                M531.093,360.06l2.035,1.164l-0.93,2.282l-2.035-1.163L531.093,360.06z M533.637,361.515l2.034,1.163l-0.93,2.281
                l-2.033-1.162L533.637,361.515z M536.179,362.969l2.032,1.163l-0.93,2.28l-2.032-1.162L536.179,362.969z M538.719,364.423
                l2.031,1.162l-0.931,2.279l-2.031-1.161L538.719,364.423z M538.951,363.852l0.931-2.281l2.032,1.163l-0.931,2.28
                L538.951,363.852z M540.115,361.001l0.932-2.283l2.032,1.164l-0.932,2.282L540.115,361.001z M541.28,358.147l0.932-2.284
                l2.032,1.165l-0.933,2.283L541.28,358.147z M542.446,355.292l0.933-2.286l2.033,1.167l-0.933,2.285L542.446,355.292z
                M541.937,355l-2.034-1.166l0.932-2.287l2.034,1.167L541.937,355z M539.395,353.542l-2.035-1.167l0.932-2.288l2.035,1.168
                L539.395,353.542z M536.852,352.083l-2.036-1.168l0.932-2.289l2.037,1.169L536.852,352.083z M534.306,350.623l-2.038-1.169
                l0.931-2.29l2.038,1.17L534.306,350.623z M531.758,349.162l-2.039-1.169l0.931-2.292l2.039,1.17L531.758,349.162z
                M529.21,347.701l-2.041-1.17l0.931-2.292l2.041,1.171L529.21,347.701z M528.977,348.273l-0.93,2.29l-2.04-1.169l0.93-2.291
                L528.977,348.273z M527.814,351.136l-0.93,2.289l-2.04-1.168l0.929-2.29L527.814,351.136z M526.652,353.997l-0.929,2.287
                l-2.039-1.167l0.929-2.288L526.652,353.997z M525.491,356.855l-0.928,2.286l-2.039-1.166l0.928-2.287L525.491,356.855z
                M524.331,359.712l-0.928,2.284l-2.039-1.164l0.927-2.285L524.331,359.712z M524.84,360.003l2.037,1.164l-0.928,2.283
                l-2.037-1.163L524.84,360.003z M527.387,361.459l2.036,1.164l-0.928,2.282l-2.036-1.163L527.387,361.459z M529.931,362.914
                l2.035,1.163l-0.929,2.281l-2.034-1.162L529.931,362.914z M532.475,364.367l2.033,1.162l-0.929,2.279l-2.033-1.161
                L532.475,364.367z M535.016,365.82l2.032,1.162l-0.93,2.278l-2.032-1.161L535.016,365.82z M537.556,367.272l2.031,1.161
                l-0.93,2.277l-2.03-1.16L537.556,367.272z M540.094,368.723l2.029,1.16l-0.931,2.276L539.164,371L540.094,368.723z
                M540.327,368.153l0.931-2.278l2.03,1.161l-0.931,2.277L540.327,368.153z M541.49,365.305l0.931-2.28l2.03,1.162l-0.932,2.279
                L541.49,365.305z M542.655,362.455l0.932-2.282l2.03,1.163l-0.932,2.281L542.655,362.455z M543.82,359.603l0.933-2.283
                l2.031,1.164l-0.933,2.282L543.82,359.603z M544.986,356.749l0.933-2.284l2.031,1.166l-0.934,2.283L544.986,356.749z
                M544.545,350.147l2.033,1.168l-0.934,2.287l-2.033-1.167L544.545,350.147z M542.003,348.687l2.034,1.168l-0.934,2.288
                l-2.034-1.167L542.003,348.687z M539.458,347.225l2.035,1.169l-0.933,2.289l-2.035-1.168L539.458,347.225z M536.912,345.763
                l2.037,1.17l-0.933,2.29l-2.037-1.169L536.912,345.763z M534.365,344.3l2.038,1.171l-0.932,2.291l-2.038-1.17L534.365,344.3z
                M531.815,342.835l2.04,1.171l-0.932,2.292l-2.039-1.171L531.815,342.835z M529.264,341.37l2.041,1.172l-0.932,2.293
                l-2.041-1.171L529.264,341.37z M526.711,339.904l2.042,1.173l-0.931,2.295l-2.042-1.172L526.711,339.904z M525.547,342.773
                l2.042,1.172l-0.93,2.293l-2.042-1.171L525.547,342.773z M524.385,345.64l2.042,1.171l-0.93,2.291l-2.041-1.17L524.385,345.64z
                M523.223,348.505l2.041,1.17l-0.929,2.29l-2.041-1.169L523.223,348.505z M522.062,351.369l2.041,1.169l-0.929,2.288
                l-2.04-1.167L522.062,351.369z M520.902,354.23l2.04,1.167l-0.928,2.287l-2.04-1.166L520.902,354.23z M519.742,357.089
                l2.04,1.166l-0.927,2.285l-2.04-1.165L519.742,357.089z M519.696,363.395l-2.039-1.164l0.926-2.284l2.04,1.165L519.696,363.395
                z M522.244,364.849l-2.038-1.163l0.927-2.283l2.038,1.164L522.244,364.849z M524.79,366.302l-2.036-1.163l0.927-2.282
                l2.037,1.163L524.79,366.302z M527.334,367.755l-2.035-1.162l0.928-2.281l2.035,1.163L527.334,367.755z M529.877,369.206
                l-2.034-1.161l0.928-2.28l2.034,1.162L529.877,369.206z M532.418,370.656l-2.032-1.16l0.928-2.279l2.033,1.161L532.418,370.656
                z M534.957,372.106l-2.031-1.159l0.929-2.277l2.032,1.16L534.957,372.106z M537.495,373.554l-2.03-1.159l0.929-2.276l2.03,1.16
                L537.495,373.554z M540.03,375.002l-2.028-1.158l0.929-2.275l2.029,1.159L540.03,375.002z M542.565,376.448l-2.027-1.157
                l0.93-2.274l2.028,1.158L542.565,376.448z M543.727,373.607l-2.028-1.158l0.931-2.276l2.028,1.159L543.727,373.607z
                M544.891,370.763l-2.028-1.159l0.931-2.277l2.028,1.16L544.891,370.763z M546.056,367.917l-2.029-1.161l0.932-2.279
                l2.029,1.162L546.056,367.917z M547.221,365.07l-2.029-1.162l0.932-2.281l2.029,1.163L547.221,365.07z M548.387,362.22
                l-2.029-1.163l0.933-2.282l2.03,1.164L548.387,362.22z M549.554,359.369l-2.03-1.164l0.934-2.283l2.03,1.165L549.554,359.369z
                M550.721,356.515l-2.03-1.165l0.934-2.285l2.03,1.166L550.721,356.515z"/>
            </g>
            <g>
              <polygon class="st92" points="523.824,368.734 524.842,369.315 536.526,340.588 535.506,340.002 					"/>
            </g>
            <g>
              <polygon class="st92" points="533.736,374.388 534.752,374.967 546.455,346.297 545.438,345.712 					"/>
            </g>
            <g>
              <polygon class="st92" points="519.127,349.775 549.844,367.362 550.311,366.223 519.591,348.629 					"/>
            </g>
          </g>
          <polygon class="st95" points="544.21,382.29 545.411,382.113 557.144,353.333 556.572,352.115 			"/>
        </g>
        <polygon class="st96" points="474.948,341.713 502.951,359.536 510.815,339.087 482.585,322.854 		"/>
        <polygon class="st96" points="513.047,363.812 541.05,381.634 548.914,361.185 520.684,344.952 		"/>
        <g>
          <path class="st46" d="M478.211,370.449c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.504,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003v-27.159h-1.944V370.449z"/>
          <g>
            <polygon class="st92" points="458.796,352.145 490.265,370.088 502.627,339.913 471.094,321.781 				"/>
            <polygon class="st93" points="460.38,351.114 490.005,368.012 501.081,340.968 471.403,323.91 				"/>
            <g>
              <path class="st94" d="M499.212,339.893l-0.507-0.292l-0.759,1.856l-2.03-1.166l0.759-1.857l-0.508-0.292L495.407,340
                l-2.031-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.509-0.292l-0.759,1.859l-2.034-1.169
                l0.758-1.859l-0.509-0.293l-0.758,1.86l-2.036-1.169l0.758-1.861l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.861
                l-0.51-0.293l-0.758,1.862l-2.038-1.171l0.757-1.862l-0.51-0.293l-0.757,1.863l-2.039-1.172l0.757-1.863l-0.51-0.293
                l-0.757,1.864l-2.041-1.173l0.757-1.864l-0.511-0.293l-0.757,1.864l-2.043-1.173l0.756-1.865l-0.511-0.294l-0.756,1.865
                l-1.841-1.058l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056
                l-0.232,0.574l1.841,1.056l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.055l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.054
                l-0.928,2.289l-1.84-1.053l-0.232,0.572l1.84,1.052l-0.928,2.288l-1.839-1.051l-0.232,0.572l1.839,1.051l-0.927,2.286
                l-1.839-1.051l-0.232,0.572l1.839,1.05l-0.926,2.285l-1.839-1.05l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291
                l0.752-1.854l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.291l0.753-1.852l2.036,1.162
                l-0.753,1.851l0.509,0.29l0.753-1.851l2.035,1.161l-0.753,1.851l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.85l0.508,0.29
                l0.753-1.849l2.032,1.16l-0.754,1.849l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.289l0.754-1.847l2.03,1.158
                l-0.754,1.847l0.507,0.289l0.754-1.846l2.028,1.158l-0.755,1.846l0.507,0.289l0.755-1.845l2.027,1.157l-0.755,1.845
                l0.507,0.289l0.755-1.845l1.866,1.065l0.233-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275
                l1.867,1.067l0.233-0.569l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.277l1.867,1.069l0.233-0.569
                l-1.867-1.069l0.933-2.279l1.868,1.07l0.233-0.57l-1.868-1.07l0.933-2.281l1.868,1.071l0.234-0.57l-1.868-1.071l0.934-2.282
                l1.868,1.072l0.234-0.571l-1.868-1.073l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.074L499.212,339.893z M493.142,339.404
                l2.032,1.167l-0.934,2.285l-2.031-1.166L493.142,339.404z M480.579,345.597l-2.036-1.166l0.931-2.286l2.036,1.167
                L480.579,345.597z M482.018,343.603l2.034,1.166l-0.931,2.284l-2.034-1.165L482.018,343.603z M478.034,344.139l-2.037-1.166
                l0.93-2.287l2.037,1.167L478.034,344.139z M477.801,344.711l-0.93,2.285l-2.036-1.165l0.929-2.285L477.801,344.711z
                M478.31,345.002l2.035,1.166l-0.93,2.283l-2.035-1.164L478.31,345.002z M480.855,346.459l2.034,1.164l-0.93,2.282
                l-2.034-1.163L480.855,346.459z M483.397,347.914l2.033,1.164l-0.931,2.281l-2.032-1.163L483.397,347.914z M483.63,347.344
                l0.931-2.284l2.033,1.165l-0.932,2.283L483.63,347.344z M484.794,344.489l0.932-2.286l2.034,1.166l-0.932,2.285
                L484.794,344.489z M484.285,344.197l-2.034-1.166l0.931-2.287l2.035,1.167L484.285,344.197z M481.742,342.74l-2.036-1.167
                l0.931-2.288l2.036,1.168L481.742,342.74z M479.197,341.281l-2.037-1.168l0.931-2.289l2.038,1.168L479.197,341.281z
                M476.65,339.822l-2.038-1.168l0.93-2.29l2.039,1.169L476.65,339.822z M476.418,340.394l-0.93,2.288l-2.038-1.167l0.93-2.289
                L476.418,340.394z M475.255,343.253l-0.929,2.286l-2.038-1.166l0.929-2.287L475.255,343.253z M474.094,346.11l-0.929,2.284
                l-2.037-1.165l0.929-2.285L474.094,346.11z M474.603,346.402l2.036,1.165l-0.929,2.283l-2.036-1.164L474.603,346.402z
                M477.148,347.858l2.035,1.164l-0.93,2.282l-2.035-1.163L477.148,347.858z M479.692,349.313l2.034,1.163l-0.93,2.281
                l-2.033-1.162L479.692,349.313z M482.234,350.767l2.032,1.163l-0.93,2.28l-2.032-1.162L482.234,350.767z M484.774,352.22
                l2.031,1.162l-0.931,2.279l-2.031-1.161L484.774,352.22z M485.007,351.65l0.931-2.281l2.032,1.163l-0.931,2.28L485.007,351.65z
                M486.171,348.799l0.932-2.283l2.032,1.164l-0.932,2.282L486.171,348.799z M487.335,345.945l0.932-2.284l2.032,1.165
                l-0.933,2.283L487.335,345.945z M488.501,343.09l0.933-2.286l2.033,1.167l-0.933,2.285L488.501,343.09z M487.993,342.798
                l-2.034-1.166l0.932-2.287l2.034,1.167L487.993,342.798z M485.45,341.34l-2.035-1.167l0.932-2.288l2.035,1.168L485.45,341.34z
                M482.907,339.881l-2.036-1.168l0.932-2.289l2.037,1.169L482.907,339.881z M480.361,338.421l-2.038-1.169l0.931-2.29
                l2.038,1.17L480.361,338.421z M477.814,336.96l-2.039-1.169l0.931-2.291l2.039,1.17L477.814,336.96z M475.265,335.498
                l-2.041-1.17l0.931-2.293l2.041,1.171L475.265,335.498z M475.032,336.071l-0.93,2.29l-2.04-1.169l0.93-2.291L475.032,336.071z
                M473.87,338.934l-0.93,2.289l-2.04-1.168l0.929-2.29L473.87,338.934z M472.708,341.795l-0.929,2.287l-2.039-1.167l0.929-2.288
                L472.708,341.795z M471.546,344.653l-0.928,2.286l-2.039-1.166l0.928-2.287L471.546,344.653z M470.386,347.51l-0.928,2.284
                l-2.039-1.165l0.928-2.285L470.386,347.51z M470.895,347.801l2.037,1.165l-0.928,2.283l-2.037-1.164L470.895,347.801z
                M473.442,349.257l2.036,1.164l-0.928,2.282l-2.036-1.163L473.442,349.257z M475.987,350.711l2.035,1.163l-0.929,2.281
                l-2.034-1.162L475.987,350.711z M478.53,352.165l2.033,1.162l-0.929,2.279l-2.033-1.161L478.53,352.165z M481.071,353.618
                l2.032,1.162l-0.93,2.278l-2.032-1.161L481.071,353.618z M483.611,355.069l2.031,1.161l-0.93,2.277l-2.031-1.16
                L483.611,355.069z M486.149,356.52l2.029,1.16l-0.931,2.276l-2.029-1.159L486.149,356.52z M486.382,355.951l0.931-2.278
                l2.029,1.161l-0.931,2.278L486.382,355.951z M487.546,353.103l0.931-2.28l2.03,1.162l-0.932,2.279L487.546,353.103z
                M488.71,350.253l0.932-2.282l2.03,1.163l-0.932,2.281L488.71,350.253z M489.875,347.401l0.933-2.283l2.031,1.164l-0.933,2.282
                L489.875,347.401z M491.041,344.546l0.933-2.284l2.031,1.166l-0.934,2.283L491.041,344.546z M490.601,337.945l2.033,1.168
                l-0.934,2.286l-2.033-1.167L490.601,337.945z M488.058,336.484l2.034,1.168l-0.934,2.288l-2.034-1.167L488.058,336.484z
                M485.514,335.023l2.035,1.169l-0.933,2.289l-2.035-1.168L485.514,335.023z M482.968,333.561l2.037,1.17l-0.933,2.29
                l-2.037-1.169L482.968,333.561z M480.42,332.097l2.038,1.171l-0.932,2.291l-2.038-1.17L480.42,332.097z M477.87,330.633
                l2.04,1.171l-0.932,2.292l-2.039-1.171L477.87,330.633z M475.319,329.168l2.041,1.172l-0.932,2.293l-2.041-1.171
                L475.319,329.168z M472.766,327.701l2.042,1.173l-0.931,2.294l-2.042-1.172L472.766,327.701z M471.603,330.571l2.042,1.172
                l-0.93,2.293l-2.042-1.171L471.603,330.571z M470.44,333.438l2.042,1.171l-0.93,2.292l-2.041-1.17L470.44,333.438z
                M469.278,336.303l2.041,1.17l-0.929,2.29l-2.041-1.169L469.278,336.303z M468.117,339.166l2.041,1.169l-0.929,2.288
                l-2.04-1.167L468.117,339.166z M466.957,342.027l2.04,1.167l-0.928,2.287l-2.04-1.166L466.957,342.027z M465.797,344.887
                l2.04,1.166l-0.927,2.285l-2.04-1.165L465.797,344.887z M465.752,351.193l-2.039-1.164l0.926-2.284l2.04,1.165L465.752,351.193
                z M468.299,352.647l-2.038-1.163l0.927-2.283l2.038,1.164L468.299,352.647z M470.845,354.1l-2.036-1.162l0.927-2.282
                l2.037,1.163L470.845,354.1z M473.389,355.552l-2.035-1.162l0.928-2.281l2.035,1.163L473.389,355.552z M475.932,357.004
                l-2.034-1.161l0.928-2.28l2.034,1.162L475.932,357.004z M478.473,358.454l-2.032-1.16l0.928-2.279l2.033,1.161L478.473,358.454
                z M481.012,359.903l-2.031-1.159l0.929-2.277l2.032,1.16L481.012,359.903z M483.55,361.352l-2.03-1.159l0.929-2.276l2.03,1.16
                L483.55,361.352z M486.086,362.799l-2.028-1.158l0.929-2.275l2.029,1.159L486.086,362.799z M488.62,364.246l-2.027-1.157
                l0.93-2.274l2.028,1.158L488.62,364.246z M489.783,361.404l-2.028-1.158l0.931-2.276l2.028,1.159L489.783,361.404z
                M490.946,358.561l-2.028-1.16l0.931-2.277l2.028,1.16L490.946,358.561z M492.111,355.715l-2.028-1.161l0.932-2.279
                l2.029,1.162L492.111,355.715z M493.276,352.868l-2.029-1.162l0.933-2.28l2.029,1.163L493.276,352.868z M494.442,350.018
                l-2.029-1.163l0.933-2.282l2.029,1.164L494.442,350.018z M495.609,347.166l-2.03-1.164l0.934-2.283l2.03,1.165L495.609,347.166
                z M496.777,344.313l-2.03-1.165l0.934-2.285l2.03,1.166L496.777,344.313z"/>
            </g>
            <g>
              <polygon class="st92" points="469.879,356.532 470.897,357.113 482.581,328.386 481.562,327.8 					"/>
            </g>
            <g>
              <polygon class="st92" points="479.792,362.186 480.807,362.765 492.51,334.095 491.493,333.51 					"/>
            </g>
            <g>
              <polygon class="st92" points="465.182,337.573 495.9,355.159 496.366,354.021 465.646,336.427 					"/>
            </g>
          </g>
          <polygon class="st95" points="490.265,370.088 491.466,369.91 503.199,341.13 502.627,339.913 			"/>
        </g>
        <g>
          <path class="st46" d="M516.354,392.258c0,0.055,0.017,0.192,0.162,0.272l0.027,0.015c0.237,0.13,0.504,0.199,0.775,0.199
            c0.27,0,0.538-0.069,0.775-0.199l0.043-0.024c0.098-0.054,0.155-0.152,0.159-0.263h0.003v-27.159h-1.944V392.258z"/>
          <g>
            <polygon class="st92" points="496.938,373.954 528.407,391.896 540.77,361.721 509.237,343.589 				"/>
            <polygon class="st93" points="498.523,372.922 528.147,389.82 539.223,362.776 509.545,345.718 				"/>
            <g>
              <path class="st94" d="M537.354,361.702l-0.508-0.292l-0.759,1.856l-2.03-1.166l0.759-1.857l-0.508-0.292l-0.759,1.857
                l-2.031-1.167l0.759-1.858l-0.508-0.292l-0.759,1.858l-2.033-1.168l0.759-1.859l-0.508-0.292l-0.759,1.859l-2.034-1.169
                l0.758-1.859l-0.509-0.292l-0.758,1.86l-2.036-1.169l0.758-1.86l-0.509-0.293l-0.758,1.861l-2.037-1.17l0.758-1.861
                l-0.509-0.293l-0.758,1.862l-2.039-1.171l0.757-1.862l-0.51-0.293l-0.757,1.862l-2.039-1.172l0.757-1.863l-0.51-0.293
                l-0.757,1.863l-2.041-1.173l0.757-1.864l-0.511-0.293l-0.757,1.864l-2.043-1.173l0.757-1.865l-0.511-0.294l-0.756,1.865
                l-1.842-1.058l-0.233,0.574l1.841,1.058l-0.931,2.296l-1.841-1.057l-0.233,0.574l1.841,1.057l-0.93,2.294l-1.841-1.056
                l-0.232,0.574l1.841,1.055l-0.93,2.293l-1.84-1.055l-0.232,0.573l1.84,1.054l-0.929,2.291l-1.84-1.054l-0.232,0.573l1.84,1.053
                l-0.928,2.289l-1.84-1.053l-0.232,0.572l1.84,1.052l-0.928,2.288l-1.839-1.052l-0.232,0.572l1.839,1.051l-0.927,2.286
                l-1.839-1.051l-0.232,0.572l1.839,1.05l-0.926,2.285l-1.839-1.05l-0.231,0.571l1.839,1.049l-0.752,1.854l0.51,0.291
                l0.752-1.854l2.039,1.164l-0.752,1.853l0.51,0.291l0.752-1.853l2.038,1.163l-0.752,1.852l0.509,0.29l0.752-1.852l2.036,1.162
                l-0.753,1.851l0.509,0.29l0.753-1.851l2.035,1.162l-0.753,1.85l0.509,0.29l0.753-1.85l2.034,1.161l-0.753,1.849l0.508,0.29
                l0.753-1.849l2.032,1.16l-0.754,1.849l0.508,0.29l0.754-1.848l2.031,1.159l-0.754,1.848l0.508,0.289l0.754-1.847l2.03,1.158
                l-0.754,1.847l0.507,0.289l0.754-1.846l2.028,1.158l-0.755,1.846l0.507,0.289l0.755-1.845l2.027,1.157l-0.755,1.845
                l0.507,0.289l0.755-1.845l1.866,1.065l0.233-0.568l-1.866-1.065l0.93-2.273l1.866,1.066l0.233-0.568l-1.866-1.066l0.931-2.275
                l1.867,1.067l0.233-0.568l-1.867-1.067l0.932-2.276l1.867,1.068l0.233-0.569l-1.867-1.068l0.932-2.278l1.867,1.069l0.233-0.569
                l-1.867-1.069l0.933-2.279l1.867,1.07l0.233-0.57l-1.868-1.07l0.934-2.281l1.868,1.071l0.234-0.57l-1.868-1.071l0.934-2.282
                l1.868,1.072l0.234-0.57l-1.869-1.073l0.935-2.284l1.869,1.073l0.234-0.571l-1.869-1.073L537.354,361.702z M531.284,361.213
                l2.031,1.167l-0.934,2.285l-2.031-1.166L531.284,361.213z M518.721,367.405l-2.036-1.166l0.931-2.286l2.036,1.167
                L518.721,367.405z M520.161,365.411l2.034,1.166l-0.931,2.284l-2.034-1.165L520.161,365.411z M516.176,365.948l-2.037-1.166
                l0.93-2.287l2.037,1.167L516.176,365.948z M515.944,366.519l-0.93,2.285l-2.036-1.165l0.929-2.286L515.944,366.519z
                M516.453,366.811l2.035,1.165l-0.93,2.283l-2.035-1.164L516.453,366.811z M518.997,368.268l2.034,1.164l-0.93,2.282
                l-2.034-1.164L518.997,368.268z M521.539,369.723l2.033,1.164l-0.931,2.281l-2.032-1.163L521.539,369.723z M521.772,369.152
                l0.931-2.284l2.033,1.165l-0.932,2.283L521.772,369.152z M522.936,366.297l0.932-2.286l2.034,1.166l-0.932,2.285
                L522.936,366.297z M522.428,366.006l-2.034-1.166l0.931-2.287l2.035,1.167L522.428,366.006z M519.885,364.548l-2.036-1.167
                l0.931-2.288l2.036,1.168L519.885,364.548z M517.339,363.09l-2.037-1.168l0.931-2.289l2.038,1.168L517.339,363.09z
                M514.793,361.63l-2.038-1.168l0.93-2.29l2.039,1.169L514.793,361.63z M514.56,362.202l-0.93,2.287l-2.038-1.167l0.93-2.289
                L514.56,362.202z M513.398,365.062l-0.929,2.286l-2.038-1.166l0.929-2.287L513.398,365.062z M512.236,367.919l-0.929,2.284
                l-2.037-1.165l0.929-2.285L512.236,367.919z M512.745,368.21l2.036,1.165l-0.929,2.283l-2.036-1.164L512.745,368.21z
                M515.291,369.666l2.035,1.164l-0.93,2.282l-2.035-1.163L515.291,369.666z M517.834,371.121l2.034,1.163l-0.93,2.281
                l-2.033-1.162L517.834,371.121z M520.376,372.575l2.032,1.163l-0.93,2.28l-2.032-1.162L520.376,372.575z M522.917,374.029
                l2.031,1.162l-0.931,2.279l-2.031-1.161L522.917,374.029z M523.149,373.459l0.931-2.281l2.031,1.163l-0.931,2.28
                L523.149,373.459z M524.313,370.607l0.932-2.283l2.032,1.164l-0.932,2.282L524.313,370.607z M525.478,367.754l0.932-2.284
                l2.032,1.165l-0.933,2.283L525.478,367.754z M526.643,364.898l0.933-2.286l2.033,1.167l-0.933,2.285L526.643,364.898z
                M526.135,364.607l-2.034-1.166l0.932-2.287l2.034,1.167L526.135,364.607z M523.593,363.149l-2.035-1.167l0.932-2.288
                l2.035,1.168L523.593,363.149z M521.049,361.69l-2.036-1.168l0.932-2.289l2.037,1.169L521.049,361.69z M518.503,360.23
                l-2.038-1.169l0.931-2.29l2.038,1.17L518.503,360.23z M515.956,358.769l-2.039-1.169l0.931-2.291l2.039,1.17L515.956,358.769z
                M513.407,357.307l-2.04-1.17l0.931-2.293l2.041,1.171L513.407,357.307z M513.175,357.88l-0.93,2.29l-2.04-1.169l0.93-2.291
                L513.175,357.88z M512.012,360.742l-0.93,2.289l-2.04-1.168l0.93-2.29L512.012,360.742z M510.85,363.603l-0.929,2.287
                l-2.039-1.167l0.928-2.288L510.85,363.603z M509.689,366.461l-0.928,2.286l-2.039-1.166l0.928-2.287L509.689,366.461z
                M508.528,369.318l-0.928,2.284l-2.038-1.164l0.927-2.285L508.528,369.318z M509.038,369.61l2.037,1.164l-0.928,2.283
                l-2.037-1.163L509.038,369.61z M511.584,371.065l2.036,1.164l-0.929,2.282l-2.036-1.163L511.584,371.065z M514.129,372.52
                l2.035,1.163l-0.929,2.28l-2.035-1.162L514.129,372.52z M516.672,373.974l2.033,1.162l-0.929,2.279l-2.033-1.161
                L516.672,373.974z M519.214,375.426l2.032,1.162l-0.93,2.278l-2.032-1.161L519.214,375.426z M521.754,376.878l2.031,1.161
                l-0.93,2.277l-2.03-1.16L521.754,376.878z M524.292,378.329l2.029,1.16l-0.931,2.276l-2.029-1.159L524.292,378.329z
                M524.524,377.76l0.931-2.279l2.03,1.161l-0.931,2.278L524.524,377.76z M525.688,374.911l0.931-2.28l2.03,1.162l-0.932,2.279
                L525.688,374.911z M526.852,372.061l0.932-2.282l2.03,1.163l-0.932,2.28L526.852,372.061z M528.017,369.209l0.933-2.283
                l2.031,1.164l-0.933,2.282L528.017,369.209z M529.184,366.355l0.933-2.285l2.031,1.166l-0.934,2.284L529.184,366.355z
                M528.743,359.753l2.033,1.168l-0.934,2.287l-2.033-1.167L528.743,359.753z M526.2,358.293l2.034,1.169l-0.934,2.288
                l-2.034-1.168L526.2,358.293z M523.656,356.832l2.036,1.169l-0.933,2.289l-2.035-1.168L523.656,356.832z M521.11,355.369
                l2.037,1.17l-0.933,2.29l-2.037-1.169L521.11,355.369z M518.562,353.906l2.038,1.171l-0.932,2.291l-2.038-1.17L518.562,353.906
                z M516.013,352.441l2.04,1.171l-0.932,2.292l-2.039-1.17L516.013,352.441z M513.462,350.976l2.041,1.172l-0.932,2.293
                l-2.041-1.171L513.462,350.976z M510.909,349.51l2.042,1.173l-0.931,2.295l-2.042-1.172L510.909,349.51z M509.745,352.379
                l2.042,1.172l-0.93,2.293l-2.042-1.171L509.745,352.379z M508.583,355.246l2.042,1.171l-0.93,2.292l-2.041-1.17
                L508.583,355.246z M507.421,358.111l2.041,1.17l-0.929,2.29l-2.041-1.169L507.421,358.111z M506.259,360.975l2.041,1.169
                l-0.929,2.288l-2.04-1.168L506.259,360.975z M505.099,363.836l2.04,1.167l-0.928,2.287l-2.04-1.166L505.099,363.836z
                M503.94,366.695l2.04,1.166l-0.927,2.285l-2.04-1.165L503.94,366.695z M503.894,373.001l-2.039-1.164l0.926-2.284l2.04,1.165
                L503.894,373.001z M506.442,374.455l-2.038-1.163l0.927-2.283l2.038,1.164L506.442,374.455z M508.987,375.908l-2.037-1.162
                l0.927-2.282l2.037,1.163L508.987,375.908z M511.532,377.361l-2.035-1.162l0.928-2.281l2.036,1.163L511.532,377.361z
                M514.075,378.812l-2.034-1.161l0.928-2.28l2.034,1.162L514.075,378.812z M516.615,380.262l-2.032-1.16l0.928-2.278
                l2.033,1.161L516.615,380.262z M519.155,381.712l-2.031-1.159l0.929-2.277l2.032,1.16L519.155,381.712z M521.692,383.16
                l-2.03-1.159l0.929-2.276l2.03,1.16L521.692,383.16z M524.228,384.608l-2.029-1.158l0.929-2.275l2.029,1.159L524.228,384.608z
                M526.762,386.054l-2.027-1.157l0.93-2.274l2.028,1.158L526.762,386.054z M527.925,383.213l-2.028-1.158l0.931-2.276
                l2.028,1.159L527.925,383.213z M529.089,380.369l-2.028-1.16l0.931-2.277l2.028,1.16L529.089,380.369z M530.253,377.523
                l-2.029-1.161l0.932-2.279l2.029,1.162L530.253,377.523z M531.418,374.676l-2.029-1.162l0.933-2.28l2.029,1.163
                L531.418,374.676z M532.584,371.826l-2.029-1.163l0.933-2.282l2.029,1.164L532.584,371.826z M533.751,368.975l-2.03-1.164
                l0.934-2.283l2.03,1.165L533.751,368.975z M534.919,366.122l-2.03-1.165l0.934-2.285l2.03,1.166L534.919,366.122z"/>
            </g>
            <g>
              <polygon class="st92" points="508.022,378.341 509.04,378.921 520.724,350.195 519.704,349.608 					"/>
            </g>
            <g>
              <polygon class="st92" points="517.934,383.995 518.949,384.574 530.652,355.904 529.635,355.319 					"/>
            </g>
            <g>
              <polygon class="st92" points="503.324,359.381 534.042,376.968 534.509,375.829 503.789,358.236 					"/>
            </g>
          </g>
          <polygon class="st95" points="528.407,391.896 529.609,391.719 541.341,362.939 540.77,361.721 			"/>
        </g>
      </g>
      <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="634.0923" y1="223.2303" x2="661.6342" y2="223.2303">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st206" points="634.092,234.188 661.191,234.188 661.634,212.273 636.308,212.273 	"/>
      <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="536.1042" y1="195.7116" x2="502.451" y2="182.4274">
        <stop  offset="0" style="stop-color:#FFFFFF"/>
        <stop  offset="1" style="stop-color:#000000"/>
      </linearGradient>
      <polygon class="st207" points="492.752,176.53 484.749,186.714 473.131,193.868 470.869,197.619 492.547,198.446 492.526,211.005 
        558.469,211.005 568.567,176.031 502.855,176.031 	"/>
      <g>
        <polygon class="st208" points="599.092,213.966 634.092,234.332 634.092,211.599 599.092,191.417 		"/>
        <polygon class="st209" points="634.092,234.332 653.337,222.966 653.337,200.488 634.092,211.599 		"/>
        <polygon class="st210" points="598.242,195.256 634.942,216.723 634.942,212.279 598.242,190.996 		"/>
        <polygon class="st211" points="634.942,216.723 654.31,205.726 654.31,201.221 634.942,212.279 		"/>
        <polygon class="st212" points="598.242,190.996 634.942,212.279 654.31,201.221 617.796,179.739 		"/>
        <g>
          <path class="st213" d="M620.626,183.408h-5.218v3.143h0.004c0,0.009-0.004,0.017-0.004,0.025
            c0.001,0.118,0.045,0.412,0.434,0.584l0.073,0.032c0.635,0.279,1.354,0.427,2.079,0.427c0.725,0,1.444-0.148,2.079-0.427
            l0.117-0.051c0.273-0.12,0.436-0.34,0.436-0.59h0v-0.006l0-0.006l0-0.001V183.408z"/>
          <path class="st214" d="M620.207,182.802c-0.657-0.305-1.406-0.466-2.166-0.466c-0.735,0-1.462,0.151-2.104,0.438l-0.097,0.043
            c-0.272,0.121-0.433,0.342-0.432,0.592c0.001,0.118,0.045,0.412,0.434,0.584l0.073,0.032c0.635,0.279,1.354,0.427,2.079,0.427
            c0.725,0,1.444-0.148,2.08-0.427l0.116-0.051c0.273-0.12,0.436-0.34,0.436-0.59l0-0.012l-0.001-0.016
            C620.615,183.121,620.462,182.92,620.207,182.802z"/>
        </g>
        <g>
          <path class="st215" d="M645.469,197.899h-5.218v3.143h0.004c0,0.009-0.004,0.017-0.004,0.026
            c0.001,0.118,0.045,0.412,0.434,0.583l0.073,0.032c0.635,0.279,1.354,0.427,2.079,0.427c0.725,0,1.444-0.148,2.079-0.427
            l0.117-0.051c0.273-0.12,0.436-0.34,0.436-0.59h0v-0.006l0-0.006l0-0.002V197.899z"/>
          <path class="st146" d="M645.049,197.293c-0.657-0.305-1.406-0.466-2.166-0.466c-0.735,0-1.462,0.151-2.104,0.438l-0.097,0.043
            c-0.272,0.121-0.433,0.342-0.432,0.592c0.001,0.118,0.045,0.412,0.434,0.584l0.073,0.032c0.635,0.279,1.354,0.427,2.079,0.427
            c0.725,0,1.444-0.148,2.079-0.427l0.117-0.051c0.273-0.12,0.436-0.34,0.436-0.59l0-0.012l-0.001-0.016
            C645.457,197.612,645.305,197.411,645.049,197.293z"/>
        </g>
        <polygon class="st50" points="623.047,220.752 629.198,224.681 629.198,221.618 623.047,217.873 		"/>
        <polygon class="st50" points="602.878,208.447 609.029,212.375 609.029,209.313 602.878,205.568 		"/>
        <polygon class="st50" points="624.865,224.471 627.379,226.035 627.379,217.697 624.865,216.317 		"/>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="430.9112" y1="382.2261" x2="458.0098" y2="382.2261">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st216" points="431.798,380.257 430.911,384.195 458.01,384.195 457.123,380.257 			"/>
          <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="415.364" y1="375.6539" x2="442.4626" y2="375.6539">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st217" points="416.25,373.685 415.364,377.623 442.463,377.623 441.576,373.685 			"/>
          <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="438.3734" y1="363.7976" x2="465.4719" y2="363.7976">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st218" points="439.26,361.828 438.373,365.767 465.472,365.767 464.586,361.828 			"/>
          <g>
            <g>
              <path class="st156" d="M414.562,366.673l-0.973,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.594-10.507L414.562,366.673z"/>
              <path class="st219" d="M415.781,346.02c-5.025,0-9.487,8.929-9.487,13.954c0,5.025,4.074,9.099,9.099,9.099
                s9.099-4.074,9.099-9.099C424.492,354.949,420.806,346.02,415.781,346.02z"/>
            </g>
            <g>
              <path class="st156" d="M437.613,357.932l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L437.613,357.932z"/>
              <path class="st219" d="M438.23,342.601c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
                c3.731,0,6.756-3.025,6.756-6.756C444.986,349.228,441.961,342.601,438.23,342.601z"/>
            </g>
            <g>
              <path class="st156" d="M430.857,375.97l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L430.857,375.97z"/>
              <path class="st219" d="M431.474,360.639c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
                c3.731,0,6.756-3.025,6.756-6.756C438.23,367.266,435.205,360.639,431.474,360.639z"/>
              <path class="st220" d="M430.041,362.556c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
                c2.021,0,3.66-1.638,3.66-3.66C433.7,366.145,432.062,362.556,430.041,362.556z"/>
            </g>
          </g>
        </g>
        <path class="st220" d="M413.733,350.143c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.522,4.164,3.752,4.321
          c2.229,0.157,4.164-1.523,4.321-3.752C417.49,354.387,415.962,350.3,413.733,350.143z"/>
        <path class="st220" d="M436.941,345.151c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
          c2.021,0,3.66-1.639,3.66-3.66C440.6,348.74,438.962,345.151,436.941,345.151z"/>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="503.3545" y1="435.634" x2="530.4531" y2="435.634">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st221" points="504.241,433.665 503.355,437.603 530.453,437.603 529.567,433.665 			"/>
          <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="487.8073" y1="429.0618" x2="514.9059" y2="429.0618">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st222" points="488.694,427.093 487.807,431.031 514.906,431.031 514.02,427.093 			"/>
          <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="510.8167" y1="417.2055" x2="537.9152" y2="417.2055">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st223" points="511.703,415.236 510.817,419.175 537.915,419.175 537.029,415.236 			"/>
          <g>
            <g>
              <path class="st156" d="M487.005,420.08l-0.973,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.594-10.507L487.005,420.08z"/>
              <path class="st219" d="M488.224,399.428c-5.025,0-9.487,8.929-9.487,13.954c0,5.025,4.074,9.099,9.099,9.099
                s9.099-4.074,9.099-9.099C496.935,408.357,493.249,399.428,488.224,399.428z"/>
            </g>
            <g>
              <path class="st156" d="M510.056,411.34l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L510.056,411.34z"/>
              <path class="st219" d="M510.673,396.009c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
                c3.731,0,6.756-3.025,6.756-6.756C517.429,402.635,514.404,396.009,510.673,396.009z"/>
            </g>
            <g>
              <path class="st156" d="M503.301,429.378l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L503.301,429.378z"/>
              <path class="st219" d="M503.918,414.047c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
                c3.731,0,6.756-3.025,6.756-6.756C510.673,420.674,507.649,414.047,503.918,414.047z"/>
              <path class="st220" d="M502.484,415.964c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
                c2.021,0,3.66-1.639,3.66-3.66C506.144,419.553,504.505,415.964,502.484,415.964z"/>
            </g>
          </g>
        </g>
        <path class="st220" d="M486.176,403.551c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.522,4.164,3.752,4.321
          c2.229,0.157,4.164-1.522,4.321-3.752C489.933,407.795,488.405,403.708,486.176,403.551z"/>
        <path class="st220" d="M509.384,398.559c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
          c2.021,0,3.66-1.638,3.66-3.66C513.044,402.148,511.405,398.559,509.384,398.559z"/>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="557.9243" y1="476.0972" x2="585.023" y2="476.0972">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st224" points="558.811,474.128 557.924,478.066 585.023,478.066 584.137,474.128 			"/>
          <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="542.3772" y1="469.525" x2="569.4757" y2="469.525">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st225" points="543.264,467.556 542.377,471.494 569.476,471.494 568.589,467.556 			"/>
          <g>
            <g>
              <path class="st156" d="M541.575,460.544l-0.972,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.593-10.507L541.575,460.544z"/>
              <path class="st219" d="M542.794,439.891c-5.025,0-9.487,8.929-9.487,13.955c0,5.025,4.074,9.099,9.099,9.099
                c5.025,0,9.099-4.074,9.099-9.099C551.505,448.82,547.819,439.891,542.794,439.891z"/>
            </g>
            <g>
              <path class="st156" d="M557.871,469.841l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L557.871,469.841z"/>
              <path class="st219" d="M558.487,454.511c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
                s6.756-3.025,6.756-6.756C565.243,461.137,562.218,454.511,558.487,454.511z"/>
              <path class="st220" d="M557.054,456.427c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
                c2.021,0,3.66-1.638,3.66-3.66C560.713,460.016,559.075,456.427,557.054,456.427z"/>
            </g>
          </g>
        </g>
        <path class="st220" d="M540.746,444.014c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.522,4.164,3.752,4.321
          c2.229,0.157,4.164-1.523,4.321-3.752C544.503,448.258,542.975,444.172,540.746,444.014z"/>
      </g>
      <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="955.6033" y1="550.344" x2="1019.5582" y2="550.344">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st226" points="971.968,534.048 955.603,566.782 1019.558,566.641 1011.629,533.906 	"/>
      <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="960.947" y1="487.0856" x2="1041.5756" y2="487.0856">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st227" points="960.947,470.789 977.621,503.523 1041.576,503.382 1033.647,470.648 	"/>
      <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="1050.5588" y1="519.1761" x2="1142.9923" y2="519.1761">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st228" points="1050.559,494.556 1079.037,543.938 1142.992,543.796 1127.051,494.415 	"/>
      <g>
        <polygon class="st229" points="937.642,556.643 955.322,566.842 955.322,529.28 937.642,519.111 		"/>
        <polygon class="st230" points="939.58,554.453 952.806,562.418 952.806,531.622 939.58,523.987 		"/>
        <polygon class="st231" points="939.58,554.453 952.806,562.418 952.806,558.331 939.58,550.695 		"/>
        <polygon class="st231" points="939.58,548.591 952.806,556.557 952.806,552.469 939.58,544.834 		"/>
        <polygon class="st231" points="939.58,542.73 952.806,550.695 952.806,546.608 939.58,538.972 		"/>
        <polygon class="st231" points="939.58,536.869 952.806,544.834 952.806,540.747 939.58,533.111 		"/>
        <polygon class="st232" points="941.858,532.46 950.153,537.471 950.153,531.432 941.858,526.75 		"/>
        <polygon class="st233" points="937.642,519.111 955.322,529.28 988.279,510.47 970.645,500.096 		"/>
        <polygon class="st231" points="955.322,566.842 988.279,548.307 988.279,510.47 955.322,529.28 		"/>
      </g>
      <g>
        <polygon class="st234" points="1027.236,480.869 1029.245,482.029 1050.957,469.548 1048.741,468.411 		"/>
        <polygon class="st235" points="1029.245,512.919 1050.957,500.401 1050.957,469.548 1029.245,482.029 		"/>
        <path class="st236" d="M1044.028,479.564c-1.241,0-2.435,0.327-3.548,0.969l-1.547,0.894c-5.282,3.048-8.563,8.731-8.563,14.83
          c0,4.411,3.706,7.141,7.141,7.141c1.227,0,2.409-0.32,3.514-0.949l1.498-0.854c5.333-3.039,8.646-8.74,8.646-14.89
          C1051.169,482.294,1047.462,479.564,1044.028,479.564z"/>
        <g>
          <path class="st234" d="M1056.839,477.058l-7.21-4.163c-0.597-0.345-1.361-0.14-1.705,0.457c-0.345,0.597-0.14,1.361,0.457,1.705
            l6.175,3.565l-8.571,16.446c-0.319,0.611-0.081,1.365,0.53,1.684c0.184,0.096,0.381,0.142,0.576,0.142
            c0.451,0,0.885-0.245,1.108-0.672l9.124-17.506C1057.631,478.123,1057.418,477.392,1056.839,477.058z"/>
        </g>
        <g>
          <path class="st237" d="M1060.961,494.11c-1.352,0.846-2.159,2.303-2.159,3.898v0.807l-2.323-1.259v-5.736
            c0-1.699-0.875-3.228-2.341-4.088c-1.466-0.86-3.227-0.879-4.711-0.051l-3.293,1.839l1.913,3.426l3.293-1.839
            c0.356-0.198,0.661-0.08,0.812,0.009c0.15,0.089,0.403,0.298,0.403,0.705v6.904c0,0.72,0.394,1.382,1.027,1.725l6.247,3.385
            c0.292,0.158,0.613,0.237,0.935,0.237c0.348,0,0.695-0.092,1.004-0.276c0.594-0.354,0.958-0.994,0.958-1.686v-4.102
            c0-0.325,0.198-0.498,0.317-0.572c0.118-0.073,0.361-0.176,0.653-0.035l4.954,2.408l1.715-3.529l-4.953-2.408
            C1063.977,493.175,1062.313,493.264,1060.961,494.11z"/>
        </g>
        <g>
          <path class="st238" d="M1063.618,494.775c-0.144,0.019-0.529,0.107-0.747,0.167c-0.131,0.035-0.858,0.632-0.858,2.776
            c0,2.871-4.282,2.978-4.614,0.337l-0.919-0.498v-5.736c0-1.699-0.875-3.228-2.341-4.088c-1.466-0.86-3.227-0.879-4.711-0.051
            l-3.293,1.839l1.913,3.426l3.293-1.839c0.356-0.198,0.661-0.08,0.812,0.009c0.15,0.089,0.403,0.298,0.403,0.705v6.904
            c0,0.72,0.394,1.382,1.027,1.725l6.247,3.385c0.292,0.158,0.613,0.237,0.935,0.237c0.348,0,0.695-0.092,1.004-0.276
            c0.594-0.354,0.958-0.994,0.958-1.686v-4.102c0-0.325,0.198-0.498,0.317-0.572c0.118-0.073,0.361-0.176,0.653-0.035l4.954,2.408
            l1.715-3.529l-4.941-2.402C1064.992,494.379,1064.333,494.681,1063.618,494.775z"/>
        </g>
        <polygon class="st232" points="1099.716,533.029 1099.716,530.627 1096.776,531.22 		"/>
        <polygon class="st236" points="1027.236,511.944 1029.169,513.051 1079.133,541.774 1101.961,529.396 1048.88,499.221 		"/>
        <polygon class="st234" points="1027.236,514.133 1079.133,544.176 1079.133,541.774 1027.236,511.944 		"/>
        <polygon class="st235" points="1079.133,544.176 1081.379,542.923 1081.379,540.556 1079.133,541.774 		"/>
        <polygon class="st235" points="1099.716,533.029 1101.961,531.777 1101.961,529.41 1099.716,530.627 		"/>
        <polygon class="st234" points="1027.236,511.944 1029.245,512.919 1029.245,482.029 1027.236,480.869 		"/>
        <path class="st239" d="M1076.302,509.957l-26.312-15.499c-0.435-0.353-0.921-0.64-1.441-0.849l-0.013-0.008l-0.001,0.002
          c-1.464-0.584-3.185-0.555-4.747,0.335l-0.843,0.481c-3.474,1.98-5.618,5.67-5.618,9.669v0.006c0,2.17,1.204,3.92,2.871,4.829
          l28.91,16.62c1.375,0.35,2.881-0.042,3.702-0.578l0.871-0.503c3.443-1.988,5.564-5.662,5.564-9.637v0
          C1079.243,512.624,1078.005,510.854,1076.302,509.957z"/>
        <g>
          <path class="st234" d="M1042.003,502.581c-0.689,0-1.248,0.559-1.248,1.249v3.306c0,0.749-0.508,1.111-0.727,1.231
            c-0.219,0.122-0.795,0.358-1.429-0.041l-4.753-2.993c-0.583-0.366-1.354-0.192-1.721,0.392c-0.368,0.583-0.192,1.354,0.391,1.721
            l4.753,2.992c0.643,0.405,1.358,0.608,2.077,0.608c0.645,0,1.292-0.164,1.889-0.494c1.263-0.697,2.017-1.975,2.017-3.417v-3.306
            C1043.251,503.14,1042.692,502.581,1042.003,502.581z"/>
        </g>
        <g>
          <path class="st234" d="M1041.236,487.682c-0.105-0.229-0.277-0.422-0.494-0.552l-7.991-4.781c-0.59-0.354-1.358-0.162-1.712,0.43
            c-0.354,0.592-0.162,1.358,0.43,1.712l7.658,4.583l2.682,5.863c0.21,0.459,0.662,0.729,1.136,0.729
            c0.174,0,0.35-0.037,0.519-0.114c0.627-0.287,0.903-1.027,0.616-1.654L1041.236,487.682z"/>
        </g>
        <g>
          <path class="st239" d="M1043.802,494.415c0,0.64,1.212,1.159,2.707,1.159c1.495,0,2.707-0.519,2.707-1.159v-6.17h-5.415V494.415z
            "/>
          <ellipse class="st240" cx="1046.509" cy="488.244" rx="2.707" ry="1.159"/>
        </g>
        <g class="st57">
          <path d="M1060.534,501.017l-4.856-1.526c-1.826-1.039-4.097-0.616-5.399,1.006c-0.492,0.613-0.895,1.296-1.198,2.03
            c-0.215,0.52-0.621,0.723-0.845,0.797c-0.21,0.071-0.631,0.149-1.077-0.115l-3.748-2.217v-5.297l2.767-1.899
            c0.934-0.549,1.247-1.751,0.699-2.685c-0.549-0.935-1.751-1.247-2.685-0.699l-2.875,1.962c-1.13,0.616-1.829,1.792-1.829,3.083
            v5.77c0,1.235,0.66,2.393,1.724,3.022l3.951,2.337c0.815,0.483,1.736,0.729,2.662,0.729c0.557,0,1.116-0.089,1.656-0.269
            c1.46-0.488,2.636-1.589,3.228-3.02c0.161-0.389,0.374-0.75,0.632-1.072c0.085-0.107,0.262-0.13,0.4-0.051l3.863,2.196
            c0.941,0.535,2.139,0.206,2.675-0.736C1060.813,503.42,1061.476,501.552,1060.534,501.017z"/>
        </g>
        <g>
          <path class="st237" d="M1058.181,501.017l-4.856-1.526c-1.826-1.039-4.097-0.616-5.399,1.006
            c-0.492,0.613-0.895,1.296-1.198,2.03c-0.215,0.52-0.621,0.723-0.845,0.797c-0.211,0.071-0.631,0.149-1.077-0.115l-3.748-2.217
            v-5.297l4.292-2.52c0.934-0.548,1.247-1.751,0.699-2.685c-0.549-0.935-1.751-1.247-2.685-0.699l-4.4,2.583
            c-1.13,0.616-1.829,1.792-1.829,3.083v5.77c0,1.235,0.66,2.393,1.723,3.022l3.951,2.337c0.815,0.483,1.736,0.729,2.662,0.729
            c0.557,0,1.116-0.089,1.656-0.269c1.459-0.488,2.636-1.589,3.227-3.02c0.161-0.389,0.374-0.75,0.632-1.072
            c0.085-0.107,0.262-0.13,0.4-0.051l3.863,2.196c0.941,0.535,2.139,0.206,2.675-0.736
            C1058.459,503.42,1059.122,501.552,1058.181,501.017z"/>
        </g>
        <g>
          <path class="st238" d="M1058.181,501.017l-4.856-1.526c-1.826-1.039-4.097-0.616-5.399,1.006
            c-0.492,0.613-0.895,1.296-1.198,2.03c-0.215,0.52-0.621,0.723-0.845,0.797c-0.211,0.071-0.631,0.149-1.077-0.115l-3.748-2.217
            v-5.297l4.292-2.52c0.898-0.527,1.213-1.656,0.75-2.572c-0.146,0.471-0.46,0.91-0.99,1.241c-1.691,1.055-4.987,1.745-5.362,3.937
            c-0.306,1.784,0.005,3.66,0.075,5.466c1.343,0.957,4.629,2.747,5.246,2.899c1.004,0.248,2.92-0.03,3.64-0.772
            c0.004-0.004,0.008-0.008,0.012-0.012c0.759-0.783,1.675-0.842,2.434-0.5c0.075-0.012,0.158,0,0.23,0.041l3.863,2.196
            c0.941,0.535,2.139,0.206,2.675-0.736C1058.459,503.42,1059.122,501.552,1058.181,501.017z"/>
        </g>
        <g>
          <g>
            <path class="st241" d="M1055.501,498.363l-0.625,0.361c-2.473,1.428-3.997,4.067-3.997,6.923c0,1.58,0.889,2.851,2.113,3.496
              l3.468,2.578l7.001-10.675l-5.3-3.098C1057.172,497.697,1056.091,497.978,1055.501,498.363z"/>
            <path class="st240" d="M1059.14,501.036l-0.625,0.361c-2.473,1.428-3.997,4.067-3.997,6.923l0,0
              c0,3.032,3.274,4.934,5.908,3.433l0.606-0.345c2.495-1.422,4.036-4.073,4.036-6.945v-0.005
              C1065.067,501.417,1061.774,499.515,1059.14,501.036z"/>
            <path class="st235" d="M1061.109,501.571c-0.501,0-0.984,0.132-1.434,0.392l-0.626,0.362c-2.135,1.232-3.462,3.53-3.462,5.996
              c0,1.783,1.498,2.887,2.887,2.887c0.496,0,0.974-0.129,1.421-0.384l0.606-0.345c2.156-1.228,3.495-3.534,3.495-6.02
              C1063.996,502.675,1062.498,501.571,1061.109,501.571z"/>
          </g>
          <g>
            <path class="st241" d="M1067.122,491.936l-0.625,0.361c-2.473,1.428-3.966,3.674-3.966,6.529v0c0,1.579,0.242,3.109,1.466,3.754
              l0.647-0.258l3.123,2.35l7.4-10.174l-5.386-2.978C1069.781,491.521,1068.373,491.233,1067.122,491.936z"/>
            <path class="st240" d="M1071.118,494.33l-0.625,0.361c-2.473,1.428-3.997,4.067-3.997,6.923c0,3.032,3.274,4.934,5.908,3.433
              l0.606-0.345c2.495-1.422,4.036-4.073,4.036-6.945v-0.005C1077.046,494.711,1073.753,492.809,1071.118,494.33z"/>
            <path class="st235" d="M1073.088,494.865c-0.502,0-0.984,0.132-1.435,0.392l-0.625,0.362c-2.135,1.232-3.462,3.53-3.462,5.996
              c0,1.783,1.498,2.887,2.887,2.887c0.496,0,0.974-0.129,1.421-0.384l0.606-0.345c2.156-1.229,3.495-3.534,3.495-6.02
              C1075.975,495.969,1074.477,494.865,1073.088,494.865z"/>
          </g>
        </g>
        <polygon class="st239" points="1050.539,519.089 1053.777,521.229 1057.015,511.995 1054.004,510.156 		"/>
        <polygon class="st240" points="1043.802,494.415 1041.979,497.925 1046.702,500.501 1046.509,495.574 		"/>
        <polygon class="st242" points="1046.509,495.574 1046.702,500.501 1053.987,496.219 1049.445,493.597 		"/>
        <g>
          <polygon class="st240" points="1079.133,541.774 1101.961,529.41 1101.961,499.411 1079.133,512.591 			"/>
          <polygon class="st243" points="1060.641,531.133 1079.133,541.774 1079.133,512.591 1060.641,502.029 			"/>
          <polygon class="st244" points="1060.641,502.029 1079.133,512.591 1101.961,499.411 1082.9,489.178 			"/>
        </g>
      </g>
      <g>
        <polygon class="st208" points="945.121,484.324 978.114,503.522 978.114,482.093 945.121,463.068 		"/>
        <polygon class="st209" points="978.114,503.522 996.255,492.807 996.255,471.619 978.114,482.093 		"/>
        <polygon class="st210" points="944.32,466.687 978.915,486.922 978.915,482.734 944.32,462.671 		"/>
        <polygon class="st211" points="978.915,486.922 997.173,476.556 997.173,472.309 978.915,482.734 		"/>
        <polygon class="st212" points="944.32,462.671 978.915,482.734 997.173,472.309 962.752,452.059 		"/>
        <g>
          <path class="st213" d="M965.42,455.518h-4.919v2.963h0.004c0,0.008-0.004,0.016-0.004,0.024c0.001,0.112,0.042,0.388,0.409,0.55
            l0.069,0.03c0.599,0.263,1.277,0.402,1.96,0.402c0.684,0,1.361-0.139,1.96-0.402l0.11-0.048c0.257-0.113,0.411-0.321,0.411-0.556
            h0v-0.006v-0.006v-0.001V455.518z"/>
          <path class="st214" d="M965.025,454.946c-0.62-0.288-1.326-0.44-2.042-0.44c-0.692,0-1.378,0.143-1.984,0.413l-0.091,0.041
            c-0.256,0.114-0.409,0.323-0.407,0.558c0.001,0.111,0.042,0.388,0.409,0.55l0.069,0.03c0.599,0.263,1.277,0.402,1.96,0.402
            c0.683,0,1.361-0.139,1.96-0.402l0.11-0.048c0.257-0.113,0.411-0.321,0.411-0.556l0-0.011l-0.001-0.015
            C965.409,455.248,965.266,455.058,965.025,454.946z"/>
        </g>
        <g>
          <path class="st215" d="M988.838,469.178h-4.919v2.963h0.004c0,0.008-0.004,0.016-0.004,0.024c0.001,0.111,0.042,0.388,0.409,0.55
            l0.069,0.03c0.599,0.263,1.276,0.402,1.96,0.402s1.361-0.139,1.96-0.402l0.11-0.048c0.257-0.113,0.411-0.321,0.411-0.556h0
            v-0.006l0-0.006l0-0.001V469.178z"/>
          <path class="st146" d="M988.443,468.607c-0.62-0.288-1.326-0.44-2.042-0.44c-0.693,0-1.379,0.143-1.984,0.413l-0.091,0.041
            c-0.256,0.114-0.409,0.323-0.407,0.558c0.001,0.112,0.042,0.389,0.409,0.55l0.069,0.03c0.599,0.263,1.277,0.402,1.96,0.402
            c0.683,0,1.361-0.139,1.96-0.402l0.11-0.048c0.257-0.113,0.411-0.321,0.411-0.556l0-0.011l-0.001-0.015
            C988.827,468.908,988.683,468.718,988.443,468.607z"/>
        </g>
        <polygon class="st50" points="967.702,490.721 973.5,494.424 973.5,491.537 967.702,488.007 		"/>
        <polygon class="st50" points="948.69,479.121 954.488,482.824 954.488,479.937 948.69,476.407 		"/>
        <polygon class="st50" points="969.416,494.226 971.786,495.701 971.786,487.841 969.416,486.54 		"/>
      </g>
      <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="372.458" y1="484.2461" x2="386.9476" y2="484.2461">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st245" points="372.458,488.441 386.948,488.441 386.935,480.051 	"/>
      <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="404.9184" y1="503.058" x2="418.9467" y2="503.058">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="1" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st246" points="404.918,507.119 418.947,507.119 418.934,498.997 	"/>
      <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="436.946" y1="516.5057" x2="473.0872" y2="516.5057">
        <stop  offset="0" style="stop-color:#000000"/>
        <stop  offset="0.8702" style="stop-color:#FFFFFF"/>
      </linearGradient>
      <polygon class="st247" points="459.235,512.892 436.946,525.892 455.323,525.892 473.087,514.96 459.235,507.119 	"/>
      <g>
        <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="564.194" y1="426.4916" x2="591.2926" y2="426.4916">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st248" points="565.08,424.522 564.194,428.461 591.293,428.461 590.406,424.522 		"/>
        <g>
          <path class="st156" d="M563.434,420.626l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L563.434,420.626z"/>
          <path class="st219" d="M564.051,405.295c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
            s6.756-3.025,6.756-6.756C570.806,411.921,567.782,405.295,564.051,405.295z"/>
        </g>
        <g>
          <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="551.7476" y1="407.1345" x2="578.8462" y2="407.1345">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st249" points="552.634,405.165 551.748,409.104 578.846,409.104 577.96,405.165 			"/>
          <g>
            <path class="st156" d="M551.694,400.879l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L551.694,400.879z"/>
            <path class="st219" d="M552.311,385.548c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
              s6.756-3.025,6.756-6.756C559.066,392.174,556.042,385.548,552.311,385.548z"/>
            <path class="st220" d="M550.877,387.464c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66s3.66-1.638,3.66-3.66
              C554.537,391.054,552.898,387.464,550.877,387.464z"/>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="585.6771" y1="353.8136" x2="612.7757" y2="353.8136">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st250" points="586.563,351.844 585.677,355.783 612.776,355.783 611.889,351.844 			"/>
          <g>
            <path class="st156" d="M584.875,344.832l-0.972,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.594-10.507L584.875,344.832z"/>
            <path class="st251" d="M586.094,324.179c-5.025,0-9.487,8.929-9.487,13.954s4.074,9.099,9.099,9.099
              c5.025,0,9.099-4.074,9.099-9.099S591.119,324.179,586.094,324.179z"/>
          </g>
          <path class="st252" d="M584.046,328.303c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.522,4.164,3.752,4.321
            c2.229,0.157,4.164-1.522,4.321-3.752C587.803,332.547,586.275,328.46,584.046,328.303z"/>
        </g>
        <path class="st252" d="M562.761,407.845c-2.021,0-3.66,3.59-3.66,5.611s1.638,3.66,3.66,3.66s3.66-1.639,3.66-3.66
          S564.783,407.845,562.761,407.845z"/>
      </g>
      <g>
        <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="588.9517" y1="469.8362" x2="616.0502" y2="469.8362">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st253" points="589.838,467.867 588.952,471.805 616.05,471.805 615.164,467.867 		"/>
        <g>
          <path class="st156" d="M588.191,463.97l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L588.191,463.97z"/>
          <path class="st219" d="M588.808,448.639c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
            c3.731,0,6.756-3.025,6.756-6.756C595.564,455.266,592.539,448.639,588.808,448.639z"/>
        </g>
        <g>
          <linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse" x1="576.5052" y1="450.4791" x2="603.6038" y2="450.4791">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st254" points="577.392,448.51 576.505,452.448 603.604,452.448 602.717,448.51 			"/>
          <g>
            <path class="st156" d="M576.451,444.223l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L576.451,444.223z"/>
            <path class="st219" d="M577.068,428.892c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
              c3.731,0,6.756-3.025,6.756-6.756C583.824,435.519,580.799,428.892,577.068,428.892z"/>
            <path class="st220" d="M575.635,430.809c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
              c2.021,0,3.66-1.638,3.66-3.66C579.294,434.398,577.656,430.809,575.635,430.809z"/>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_59_" gradientUnits="userSpaceOnUse" x1="610.4347" y1="397.1582" x2="637.5333" y2="397.1582">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st255" points="611.321,395.189 610.435,399.127 637.533,399.127 636.647,395.189 			"/>
          <g>
            <path class="st156" d="M609.632,388.177l-0.973,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.594-10.507L609.632,388.177z"/>
            <path class="st251" d="M610.851,367.524c-5.025,0-9.487,8.929-9.487,13.954s4.074,9.099,9.099,9.099
              c5.025,0,9.099-4.074,9.099-9.099S615.876,367.524,610.851,367.524z"/>
          </g>
          <path class="st252" d="M608.803,371.648c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.523,4.164,3.752,4.321
            c2.229,0.157,4.164-1.522,4.321-3.752C612.56,375.892,611.032,371.805,608.803,371.648z"/>
        </g>
        <path class="st252" d="M587.519,451.189c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
          c2.021,0,3.66-1.639,3.66-3.66C591.179,454.779,589.54,451.189,587.519,451.189z"/>
      </g>
      <g>
        <linearGradient id="SVGID_60_" gradientUnits="userSpaceOnUse" x1="584.9823" y1="419.3183" x2="612.0809" y2="419.3183">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st256" points="585.869,417.349 584.982,421.287 612.081,421.287 611.194,417.349 		"/>
        <g>
          <path class="st156" d="M584.222,413.452l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L584.222,413.452z"/>
          <path class="st219" d="M584.839,398.121c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
            s6.756-3.025,6.756-6.756C591.595,404.748,588.57,398.121,584.839,398.121z"/>
        </g>
        <g>
          <linearGradient id="SVGID_61_" gradientUnits="userSpaceOnUse" x1="572.5359" y1="399.9612" x2="599.6345" y2="399.9612">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st257" points="573.422,397.992 572.536,401.93 599.634,401.93 598.748,397.992 			"/>
          <g>
            <path class="st156" d="M572.482,393.705l-0.722,7.482c1.997,1.788,3.254-0.126,3.254-0.126l-1.183-7.801L572.482,393.705z"/>
            <path class="st219" d="M573.099,378.374c-3.731,0-6.756,6.627-6.756,10.358c0,3.731,3.025,6.756,6.756,6.756
              c3.731,0,6.756-3.025,6.756-6.756C579.855,385.001,576.83,378.374,573.099,378.374z"/>
            <path class="st220" d="M571.665,380.291c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66
              c2.021,0,3.66-1.638,3.66-3.66C575.325,383.88,573.686,380.291,571.665,380.291z"/>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_62_" gradientUnits="userSpaceOnUse" x1="606.4654" y1="346.6403" x2="633.564" y2="346.6403">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st258" points="607.352,344.671 606.465,348.609 633.564,348.609 632.678,344.671 			"/>
          <g>
            <path class="st156" d="M605.663,337.659l-0.972,10.078c2.69,2.408,4.383-0.17,4.383-0.17l-1.594-10.507L605.663,337.659z"/>
            <path class="st251" d="M606.882,317.006c-5.025,0-9.487,8.929-9.487,13.954s4.074,9.099,9.099,9.099
              c5.025,0,9.099-4.074,9.099-9.099S611.907,317.006,606.882,317.006z"/>
          </g>
          <path class="st252" d="M604.834,321.13c-2.229-0.157-4.316,3.675-4.473,5.904c-0.157,2.229,1.522,4.164,3.752,4.321
            c2.229,0.157,4.164-1.523,4.321-3.752C608.591,325.374,607.063,321.287,604.834,321.13z"/>
        </g>
        <path class="st252" d="M583.55,400.671c-2.021,0-3.66,3.59-3.66,5.611c0,2.021,1.638,3.66,3.66,3.66c2.021,0,3.66-1.639,3.66-3.66
          C587.209,404.261,585.571,400.671,583.55,400.671z"/>
      </g>
      <g>
        <linearGradient id="SVGID_63_" gradientUnits="userSpaceOnUse" x1="344.9633" y1="480.981" x2="358.0425" y2="480.981">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.6931" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st259" points="358.043,482.183 356.647,480.693 355.482,480.693 354.626,479.779 344.963,479.779 
          346.772,481.368 347.361,481.362 347.823,482.183 		"/>
        <path class="st260" d="M347.952,474.996l-0.024,0.313l0.075,0.317l0.067,0.17c0,0,0.091-0.008,0.079-0.135
          c-0.012-0.127-0.008-0.202,0.004-0.242c0.012-0.039,0.115,0.151,0.115,0.151l0.063,0.269c0,0,0.036,0.075,0.075,0.067
          c0,0,0.04,0.067,0.111,0.028l0.103-0.448c0,0-0.119-0.352-0.186-0.456c-0.031-0.048-0.151-0.159-0.162-0.163
          C348.261,474.865,347.952,474.996,347.952,474.996z"/>
        <path class="st56" d="M347.192,470.684c0,0,0.356,1.063,0.128,1.081c-0.069,0.005-0.136,0.002-0.202-0.006l0.106,1.523
          l0.692,1.818c0,0,0.315,0.003,0.424-0.247l-0.424-1.733c0,0,0.202-1.779-0.003-2.081
          C347.704,470.732,347.192,470.684,347.192,470.684z"/>
        <path class="st261" d="M347.829,474.897l0.085,0.203c0,0,0.324,0,0.434-0.25l-0.052-0.206
          C348.291,474.65,348.138,474.881,347.829,474.897z"/>
        <path class="st42" d="M346.067,480.161c-0.008,0.008-0.258-0.504-0.258-0.504l-0.686-0.229l-0.134,0.263
          c-0.039,0.076-0.033,0.167,0.014,0.238l0.355,0.366l0,0c0.035,0.064,0.088,0.117,0.152,0.152c0.146,0.081,0.539,0.278,0.659,0.242
          C346.365,480.629,346.491,480.42,346.067,480.161z"/>
        <path class="st42" d="M346.772,481.368l-0.028,0.312c0.009,0.085,0.064,0.158,0.142,0.191l1.022,0.418
          c0,0,0.415,0.067,0.527-0.254l-0.006-0.02c-0.04-0.133-0.343-0.269-0.451-0.357l-0.568-0.456L346.772,481.368z"/>
        <path class="st262" d="M347.805,474.58c0,0-0.004,0.965-0.077,1.587c-0.052,0.447-0.326,1.765-0.326,1.765l-1.584,1.81
          c0,0-0.518,0.205-0.738-0.358l1.406-2.074l0.11-2.399L347.805,474.58z"/>
        <path class="st263" d="M347.342,475.665c-0.365,0.179-0.685,0.041-0.779-0.03l-0.077,1.674l-1.406,2.074
          c0.22,0.564,0.738,0.358,0.738,0.358l1.584-1.81c0,0,0.06-0.289,0.129-0.642c-0.077-0.204-0.249-0.922-0.348-1.292
          C347.105,475.706,347.36,475.739,347.342,475.665z"/>
        <path class="st262" d="M345.516,474.781c0,0-0.053,0.75,0.268,1.447c0.322,0.696,0.728,2.204,0.728,2.204l0.21,3.037
          c0,0,0.505,0.4,0.964,0l-0.106-3.158l-0.51-2.566v-1.072L345.516,474.781z"/>
        <path class="st193" d="M345.488,474.905c0,0,0.132,0.385,1.197,0.242c1.018-0.137,1.148-0.479,1.148-0.479l-0.048-1.474
          c0,0,0.255-1.745,0.156-2.024c-0.118-0.336-0.27-0.58-0.824-0.595h-0.398l-0.396,0.069l-0.639,0.417
          c-0.305,0.167-0.461,0.42-0.443,0.768l0.309,1.982L345.488,474.905z"/>
        <path class="st56" d="M345.135,471.978c0,0-0.175,1.036,0.054,1.639c0.069,0.181,0.527,0.313,0.527,0.313l0.245-1.745
          c0,0,0.005-1.001-0.289-1.015C345.17,471.146,345.135,471.978,345.135,471.978z"/>
        <path class="st260" d="M346.749,471.118L346.749,471.118c-0.234,0-0.425-0.19-0.425-0.425l0.13-0.718l0.719,0.023v0.695
          C347.173,470.927,346.983,471.118,346.749,471.118z"/>
        <path class="st56" d="M346.345,470.464c0,0,0.028,0.503,0.625,0.59l-0.105,0.329c0,0-0.485-0.08-0.707-0.631L346.345,470.464z"/>
        <path class="st56" d="M346.97,471.055c0,0,0.207,0.145,0.259,0.231c0,0,0.111-0.52-0.055-0.685
          C347.173,470.601,347.223,470.892,346.97,471.055z"/>
        <path class="st260" d="M346.839,470.68c0,0-0.582-0.322-0.51-0.67l0.063-0.659l1.251,0.034l-0.184,0.893
          C347.384,470.632,347.175,470.75,346.839,470.68z"/>
        <path class="st264" d="M347.564,468.995c-0.416-0.399-0.865-0.31-1.224-0.009c-0.175,0.147-0.152,0.519-0.145,0.693
          c0.016,0.394,0.191,0.644,0.191,0.644l0.159-0.587l0.046,0.133l0.115-0.052c0,0,0.008-0.261,0.019-0.303
          c0.037-0.141,0.888,0.013,0.888,0.013S348.018,469.43,347.564,468.995z"/>
        <path class="st260" d="M346.545,469.736c0,0-0.143-0.214-0.212-0.012c-0.051,0.149,0.016,0.338,0.151,0.307L346.545,469.736z"/>
        <path class="st265" d="M348.228,473.248l0.321-0.257c0,0,0.078-0.083,0.205-0.072c0.127,0.011,0.317,0.038,0.317,0.038
          s0.057,0.1,0.054,0.103c-0.149,0.161-0.638,0.59-0.638,0.59L348.228,473.248z"/>
        <path class="st266" d="M348.228,473.248l0.321-0.257c0,0,0.078-0.083,0.205-0.072c0.127,0.011,0.317,0.038,0.317,0.038
          s0.031,0,0.038,0.022c0,0.001,0.021,0.056,0.021,0.056s0.009,0.021-0.019,0.04c0,0,0.016-0.024-0.015-0.025
          c-0.064-0.003-0.212-0.024-0.308-0.031c-0.073-0.005-0.142,0.017-0.19,0.046c-0.013,0.008-0.33,0.273-0.33,0.273L348.228,473.248z
          "/>
        <path class="st260" d="M347.495,473.329l0.074-0.016l0.336-0.131l0.235-0.093c0.03-0.012,0.063-0.019,0.095-0.02l0.227-0.008
          c0,0-0.07,0.057-0.098,0.089c-0.003,0.004-0.042,0.047-0.044,0.051c-0.008,0.02-0.003,0.077,0.013,0.103
          c0,0,0.047,0.048,0.205,0.063c0.142,0.014,0.273,0.004,0.273,0.004s0.004,0.131-0.19,0.162c0,0-0.016,0.101-0.13,0.134
          c0,0-0.058,0.104-0.168,0.112c0,0-0.098,0.09-0.213,0.09c0,0-0.295-0.016-0.525-0.148l-0.147,0.025L347.495,473.329z"/>
        <path class="st56" d="M347.595,473.258l-1.746,0.008l-0.763-0.172c-0.004,0.134,0.016,0.368,0.017,0.474
          c0.001,0.265,0.221,0.477,0.486,0.469l0.41-0.012l1.628-0.261C347.626,473.764,347.595,473.52,347.595,473.258z"/>
      </g>
      <g>
        <linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse" x1="392.0538" y1="345.6176" x2="402.7804" y2="345.6176">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st267" points="392.938,344.872 392.054,345.208 392.561,346.363 402.78,346.363 401.385,344.872 		"/>
        <g>
          <path class="st260" d="M394.044,340.772l-0.01-0.319l-0.168-0.386l-0.271,0.13c0,0,0.005,0.189,0.018,0.251
            c0.013,0.063,0.083,0.241,0.083,0.241l-0.043,0.163c0,0-0.008,0.096,0.07,0.075l0.039-0.117l-0.007,0.096
            c0,0-0.063,0.124,0.044,0.134l0.019-0.032c0.019,0.048,0.072,0.036,0.072,0.036s0.065-0.127,0.086-0.309
            C394.006,340.768,394.044,340.772,394.044,340.772z"/>
          <path class="st262" d="M392.974,336.593c0,0,0.311,0.888,0.12,0.906c-0.057,0.005-0.114,0.003-0.17-0.003l0.176,1.226l0.43,1.545
            c0,0,0.26,0.045,0.385-0.147l-0.235-1.541c0,0-0.009-1.43-0.184-1.681C393.317,336.642,392.974,336.593,392.974,336.593z"/>
          <path class="st268" d="M393.869,339.939c-0.052,0.042-0.11,0.068-0.171,0.083c-0.071,0.032-0.143,0.055-0.22,0.058l0.052,0.188
            c0,0,0.26,0.045,0.385-0.147l-0.029-0.193C393.88,339.931,393.874,339.935,393.869,339.939z"/>
          <g>
            <g>
              <path class="st269" d="M391.626,345.475v0.25c0,0.072,0.039,0.139,0.102,0.174l0.764,0.425c0,0,0.344,0.095,0.468-0.166
                l-0.003-0.017c-0.021-0.116-0.205-0.237-0.288-0.321l-0.437-0.437L391.626,345.475z"/>
              <path class="st269" d="M392.833,344.82v0.25c0,0.072,0.039,0.139,0.102,0.174l0.764,0.425c0,0,0.344,0.095,0.468-0.166
                l-0.003-0.017c-0.021-0.116-0.205-0.237-0.288-0.321l-0.436-0.437L392.833,344.82z"/>
              <path class="st262" d="M391.419,340.522l0.26,0.125c0.389,0.187,0.842,0.186,1.231-0.003l0.699-0.333l0.03-2.478
                c0,0,0.026-0.632-0.179-0.986c-0.182-0.316-0.477-0.512-0.941-0.369l-0.384,0.176l-0.509,0.334
                c-0.259,0.141-0.391,0.357-0.376,0.651L391.419,340.522z"/>
              <path class="st270" d="M393.595,339.636c-0.491,0.439-1.23,0.701-1.851,0.667c-0.138-0.007-0.251-0.051-0.342-0.115
                l0.142,5.293c0.657,0.35,0.826-0.083,0.826-0.083l0.207-3.576l0.205,3.079c0.616,0.19,0.74-0.16,0.74-0.16
                S393.595,339.666,393.595,339.636z"/>
              <path class="st262" d="M393.46,336.846c-0.093-0.162-0.217-0.291-0.376-0.361c-0.019,0.008-0.037,0.022-0.059,0.024
                c-0.848,0.074-1.483,0.506-1.601,1.248c-0.023,0.143-0.006,0.597-0.098,0.648l0.093,2.117l0.26,0.125
                c0.389,0.187,0.842,0.186,1.231-0.003l0.699-0.333l0.03-2.478C393.639,337.832,393.665,337.201,393.46,336.846z"/>
              <path class="st271" d="M391.758,339.882c0.015-0.48,0.009-0.881,0.074-1.357c0.056-0.411-0.003-0.864-0.252-1.216
                c-0.074,0.134-0.13,0.282-0.156,0.448c-0.023,0.143-0.006,0.597-0.098,0.648l0.093,2.117l0.26,0.125
                c0.023,0.011,0.049,0.016,0.073,0.025C391.741,340.412,391.749,340.15,391.758,339.882z"/>
              <path class="st56" d="M393.021,337.192c0,0-0.05-0.576-0.073-0.574c-0.476,0.041-0.607,0.02-0.895,0.279
                c0.06,0.033,0.254,0.177,0.31,0.237c0.266,0.289,0.294,0.247,0.613,0.474L393.021,337.192z"/>
              <path class="st272" d="M392.508,337.027L392.508,337.027c-0.206,0-0.373-0.167-0.373-0.373v-0.61h0.746v0.61
                C392.881,336.859,392.714,337.027,392.508,337.027z"/>
              <path class="st260" d="M392.57,336.748L392.57,336.748c-0.314,0-0.568-0.268-0.568-0.599v-0.654l1.146-0.204v0.848
                C393.148,336.475,392.889,336.748,392.57,336.748z"/>
              <path class="st264" d="M392.132,336.529c0,0-0.267-0.284-0.311-0.77v-0.291l1.344-0.044v0.11h-0.63
                c-0.228,0-0.412,0.199-0.409,0.442L392.132,336.529z"/>
            </g>
          </g>
          <g>
            <path class="st260" d="M391.31,341.294c0.019,0.003,0.039,0.005,0.059,0.005c0.048-0.001,0.096,0.005,0.144,0.016l0-0.002
              l0.038-0.622l-0.398,0.096l-0.032,0.465C391.205,341.265,391.228,341.273,391.31,341.294z"/>
            <path class="st260" d="M391.536,340.647l0.109,0.466l-0.044,0.496c0,0-0.106,0.005-0.099-0.145l0.014-0.171l-0.098-0.077
              L391.536,340.647z"/>
            <path class="st262" d="M391.145,337.812l-0.173,1.442l0.172,1.503c0,0,0.123,0.235,0.425,0.027l0.052-1.575l0.21-1.192
              c0,0,0.042-0.84-0.204-0.863C391.205,337.115,391.145,337.812,391.145,337.812z"/>
            <path class="st268" d="M391.171,340.593c-0.012-0.002-0.025-0.004-0.037-0.006l-0.001,0l0.01,0.17c0,0,0.123,0.235,0.425,0.027
              l0.017-0.21C391.466,340.65,391.302,340.653,391.171,340.593z"/>
            <path class="st260" d="M391.136,340.931l-0.056,0.411l0.061,0.2l0.141,0.113c0,0,0.077,0.128,0.086,0.142
              c0.03,0.05,0.107,0.019,0.099-0.038l-0.009-0.06l-0.004-0.186l0.063-0.221L391.136,340.931z"/>
          </g>
        </g>
        <g>
          <path class="st273" d="M392.843,336.196c-0.036,0-0.072-0.007-0.105-0.02l-0.479-0.184c-0.097-0.023-0.189-0.06-0.274-0.109
            l-0.094-0.056c-0.154-0.089-0.246-0.248-0.246-0.427c0-0.178,0.092-0.338,0.246-0.427l0.005-0.003
            c0.157-0.09,0.335-0.138,0.516-0.138s0.359,0.048,0.516,0.138l0.094,0.056c0.098,0.056,0.171,0.141,0.211,0.244l0.243,0.376
            c0.028,0.043,0.036,0.094,0.024,0.144c-0.012,0.05-0.043,0.091-0.087,0.117l-0.422,0.248
            C392.947,336.182,392.895,336.196,392.843,336.196L392.843,336.196z"/>
          <path class="st274" d="M393.446,335.666l-0.245-0.379l0,0c-0.035-0.092-0.101-0.174-0.198-0.23l-0.094-0.056
            c-0.308-0.178-0.688-0.178-0.996,0l-0.005,0.003c-0.305,0.176-0.305,0.616,0,0.792l0.094,0.056
            c0.086,0.05,0.177,0.085,0.271,0.107l0,0l0.478,0.184c0.073,0.028,0.156,0.022,0.224-0.018l0.422-0.248
            C393.469,335.833,393.492,335.738,393.446,335.666z"/>
          <path class="st275" d="M393.158,335.423c0-0.136,0.005-0.802-0.726-0.802c-0.473,0-0.624,0.471-0.663,0.643
            c-0.005,0.02-0.009,0.04-0.012,0.061l0,0.001l0,0c-0.018,0.155,0.051,0.318,0.204,0.407l0.082,0.047
            c0.283,0.163,0.631,0.161,0.912-0.005l0,0C393.089,335.696,393.157,335.559,393.158,335.423L393.158,335.423z"/>
          <path class="st276" d="M391.825,335.085c0,0-0.014-0.067,0.014-0.07c0.074-0.008,0.224-0.127,0.534,0.055
            c0.31,0.182,0.309,0.425,0.336,0.531c0.027,0.106,0.03,0.288,0.03,0.288l-0.038-0.011c0,0,0-0.145-0.099-0.426
            C392.534,335.259,392.244,334.928,391.825,335.085z"/>
          <path class="st273" d="M391.825,335.027c0,0,0.142-0.063,0.213-0.05c0.235,0.044,0.417,0.107,0.55,0.355
            c0.157,0.291,0.151,0.556,0.151,0.556s0.367-0.074,0.458-0.285c0,0-0.021-0.323-0.198-0.581c-0.075-0.11-0.352-0.378-0.694-0.389
            c-0.064-0.002-0.204,0.058-0.287,0.138C391.847,334.934,391.825,335.027,391.825,335.027z"/>
        </g>
        <path class="st277" d="M392.135,336.574l-0.187,0.202c0,0,0.021,0.348,0.354,0.523l0.076-0.077v0.141l0.598,0.245
          c0,0-0.383-0.356-0.532-0.469C392.334,337.056,392.117,336.897,392.135,336.574z"/>
        <path class="st277" d="M392.881,336.653c0,0,0.169,0.517,0.095,0.956c0,0,0.112-0.331,0.138-0.502L393.048,337l0.079-0.013
          c0,0-0.055-0.309-0.14-0.427C392.987,336.56,392.929,336.623,392.881,336.653z"/>
        <path class="st277" d="M392.977,337.609c0,0,0.094,0.259,0.125,0.824c0.031,0.565,0,2.119,0,2.119l0.026-0.012
          c0,0,0.021-1.099,0.021-1.55c0-0.451-0.021-0.975-0.154-1.431L392.977,337.609z"/>
      </g>
      <g>
        <linearGradient id="SVGID_65_" gradientUnits="userSpaceOnUse" x1="412.4956" y1="523.3654" x2="423.1645" y2="523.3654">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st278" points="412.496,522.62 412.945,524.111 423.165,524.111 421.769,522.62 		"/>
        <path class="st279" d="M415.172,513.424l0.57,2.028c0,0,0.082,0.336-0.15,0.507c-0.156,0.115-0.372,0.248-0.737,0.452
          l-0.285-0.166l0.665-0.602l-0.444-0.824L415.172,513.424z"/>
        <path class="st280" d="M411.679,523.82l0.867,0.266c0,0,0.333,0.096,0.456-0.07l-0.061-0.312l-0.351-0.225
          c0,0-0.586,0.035-0.744,0c-0.158-0.035-0.42-0.035-0.42,0.088C411.425,523.688,411.679,523.82,411.679,523.82z"/>
        <path class="st279" d="M412.94,523.703l-0.438-0.233c0,0-0.337,0.035-0.53,0.02c0,0-0.188-0.017-0.166,0.177
          c0.091,0.063,1.03,0.34,1.164,0.173L412.94,523.703z"/>
        <path class="st279" d="M413.381,517.07l1.149,3.76l1.243,2.482c0,0,0.096,0.191,0.317-0.148c0,0-0.308-1.402-0.465-1.838
          c-0.065-0.182-0.349-0.655-0.349-0.655l-0.155-2.642l0,0c0.081-0.454,0.044-0.921-0.108-1.357l-0.063-0.181L413.381,517.07z"/>
        <path class="st279" d="M414.172,518.482c0,0-1.183,1.809-1.145,1.967c0.013,0.053,0.107,0.54,0.084,1.025
          c-0.021,0.465-0.156,2.24-0.19,2.261c-0.052,0.031-0.322,0.169-0.433-0.211l-0.261-3.084c-0.004-0.13,0.017-0.259,0.061-0.381
          l0.921-3.161l0.611,0.383l1.283,0.889L414.172,518.482z"/>
        <path class="st281" d="M412.521,518.959c0,0,0.845,0.549,1.5,0.423c0.655-0.127,1.225-0.359,1.225-0.359l-0.066-1.606
          l-0.085-0.507l-1.888-0.095L412.521,518.959z"/>
        <path class="st282" d="M415.119,517.026l-0.19-0.919c0,0,0.377-2.052,0.389-2.167c0.022-0.209-0.073-0.728-0.548-0.875
          c0,0-0.401-0.053-0.982,0.116c-0.38,0.111-0.466,0.584-0.561,0.711c-0.22,0.294-0.301,0.444-0.269,0.57
          c0.069,0.278,0.349,0.555,0.349,0.555l-0.067,1.111l-0.042,0.708c0,0,0.406,0.54,1.24,0.357
          C415.271,517.01,415.119,517.026,415.119,517.026z"/>
        <g>
          <path class="st279" d="M412.128,517.146c0,0-0.229,0.368-0.224,0.4c0.005,0.031,0.041,0.305,0.046,0.336
            c0.005,0.032,0.102,0.12,0.175,0.141c0.073,0.021,0.238,0.047,0.238,0.047s0.017-0.033,0.011-0.064l0.054-0.014
            c0,0,0.118-0.01,0.028-0.097l0.03-0.029c0,0-0.001-0.068-0.06-0.086l0.039-0.236c0,0,0.072-0.249-0.005-0.279L412.128,517.146z"
            />
          <path class="st279" d="M413.565,513.512c-0.129,0.154-0.456,2.058-0.456,2.058s-0.98,1.546-0.999,1.609
            c-0.018,0.063,0.013,0.291,0.374,0.122l1.201-1.413c0,0,0.373-1.274,0.489-1.7C414.303,513.718,414.121,512.848,413.565,513.512z
            "/>
        </g>
        <g>
          <path class="st279" d="M414.644,513.044l-0.042-0.887l-0.837-0.011l0.203,0.983C414.105,513.234,414.641,513.294,414.644,513.044
            z"/>
          <path class="st279" d="M414.084,512.807c0,0,0.679-0.201,0.684-0.58l0.085-0.699l-1.381-0.251l-0.012,0.969
            C413.455,512.631,413.719,512.802,414.084,512.807z"/>
          <path class="st283" d="M413.469,511.553c0,0,0.186,0.049,0.182,0.343c-0.004,0.295,0.401,0.501,0.641,0.671
            c0.19,0.135,0.42,0,0.42,0s0.549-0.254,0.169-1.342c-0.179-0.513-0.872-0.512-1.085-0.423
            C413.494,510.931,413.016,511.23,413.469,511.553z"/>
          <path class="st279" d="M413.609,511.911c0,0,0.197-0.189,0.223,0.036c0.019,0.166-0.093,0.348-0.227,0.284L413.609,511.911z"/>
          <path class="st283" d="M415.153,511.146c0.096,0.3-0.043,0.613-0.31,0.698c-0.267,0.085-0.561-0.089-0.656-0.39
            c-0.096-0.3,0.043-0.612,0.31-0.697C414.763,510.672,415.057,510.846,415.153,511.146z"/>
        </g>
        <path class="st280" d="M415.952,523.406c-0.154,0.088-0.314,0.14-0.482,0.167c-0.119,0.071-0.305,0.096-0.305,0.096
          c-0.274,0.017-0.326,0.189-0.326,0.189s-0.017,0.112,0.412,0.154c0.211,0.021,0.446-0.12,0.446-0.12l0.583-0.326
          c0.051-0.097-0.032-0.226-0.107-0.315C416.102,523.308,416.03,523.362,415.952,523.406z"/>
        <path class="st279" d="M415.591,523.651c0.263-0.106,0.301-0.121,0.49-0.225c0.052-0.028,0.101-0.062,0.151-0.094
          c-0.065-0.101-0.149-0.203-0.149-0.203l-0.333,0.143c0,0-0.122,0.158-0.225,0.261c-0.103,0.103-0.36,0.137-0.36,0.137
          C415.109,523.673,415.29,523.773,415.591,523.651z"/>
      </g>
      <g>
        <linearGradient id="SVGID_66_" gradientUnits="userSpaceOnUse" x1="737.3864" y1="656.9399" x2="748.4444" y2="656.9399">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st284" points="737.386,656.195 738.225,657.685 748.444,657.685 747.049,656.195 		"/>
        <linearGradient id="SVGID_67_" gradientUnits="userSpaceOnUse" x1="742.9153" y1="660.2045" x2="753.9735" y2="660.2045">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st285" points="742.915,659.459 743.754,660.95 753.974,660.95 752.578,659.459 		"/>
        <path class="st260" d="M740.577,649.919l0.054,0.204c0,0,0.25,0.346,0.26,0.403c0.01,0.057-0.085,0.652-0.229,0.683
          c-0.144,0.031-0.153-0.027-0.103-0.092l-0.089,0.047c0,0-0.064-0.057-0.019-0.118l0.078-0.013l-0.157,0.018
          c0,0-0.028-0.05,0.036-0.096l0.013-0.359l-0.063-0.118c0,0-0.08-0.123-0.071-0.175l-0.14-0.212
          C740.146,650.093,740.173,649.806,740.577,649.919z"/>
        <path class="st286" d="M740.239,650.28c0,0,0.184-0.164,0.418-0.156l-0.04-0.157c0,0-0.278-0.089-0.474,0.151L740.239,650.28z"/>
        <path class="st42" d="M742.246,660.038c-0.233,0-0.398,0.267-0.198,0.44l1.258,0.375c0,0,0.442,0.124,0.565-0.177l-0.029-0.457
          l-1.01-0.182C742.832,660.038,742.588,660.038,742.246,660.038z"/>
        <path class="st287" d="M738.708,646.033c0,0,0.344,1.095,0.109,1.109c-0.07,0.004-0.139-0.001-0.206-0.01l0.527,0.774l0.435,0.938
          l0.577,1.333c0,0,0.248-0.222,0.507-0.185l-0.59-1.898c0,0-0.503-1.363-0.701-1.68
          C739.118,646.017,738.708,646.033,738.708,646.033z"/>
        <path class="st42" d="M737.202,656.651v0.302c0,0.087,0.047,0.168,0.123,0.21l0.922,0.514c0,0,0.415,0.115,0.565-0.2l-0.004-0.021
          c-0.026-0.14-0.247-0.286-0.347-0.387l-0.527-0.528L737.202,656.651z"/>
        <path class="st42" d="M738.592,655.966v0.302c0,0.087,0.047,0.168,0.123,0.21l0.494,0.169c0,0,0.53,0.36,0.763,0.282
          c0.204-0.069,0.377-0.282-0.121-0.546l-0.527-0.527L738.592,655.966z"/>
        <path class="st287" d="M738.81,650.27c-0.609,0.299-2.04,0.142-2.055,0.141l0.347,6.247c0.794,0.422,0.997-0.101,0.997-0.101
          l0.176-4.701l0.253,4.206c0.744,0.229,0.894-0.193,0.894-0.193s0.002-3.209-0.05-4.349c-0.013-0.287,0-1.359,0-1.359
          C739.135,650.297,739.036,650.159,738.81,650.27z"/>
        <path class="st261" d="M739.116,648.556c0.024-0.747,0.07-1.441-0.068-2.168c-0.194-0.062-0.384-0.132-0.552-0.251
          c-0.051-0.036-0.081-0.082-0.097-0.13h-0.347l-0.405,0.071l-0.654,0.427c-0.313,0.171-0.473,0.431-0.454,0.787l0.421,3.073
          c0,0,0.058,0.495,1.148,0.348c0.599-0.081,0.927-0.251,1.097-0.38C739.123,649.744,739.097,649.151,739.116,648.556z"/>
        <path class="st288" d="M739.267,648.689c0,0,0.018-1.74-0.088-2.073c-0.109-0.343-0.222-0.61-0.697-0.608
          c0.357,0.631,0.306,1.822,0.286,2.233c-0.02,0.412-0.172-0.208-0.17,0.205c0.003,0.551,0.002,1.103-0.005,1.654
          c0.147,0.073,0.201,0.488,0.344,0.64c0.269,0.285,0.486-0.199,0.486-0.199L739.267,648.689z"/>
        <path class="st288" d="M738.722,648.097c0,0.023-0.001,0.036-0.001,0.036s-0.693-1.259-0.797-1.416
          c-0.126-0.191-0.248-0.384-0.369-0.578l-0.561,0.367c-0.313,0.171-0.473,0.431-0.454,0.787l0.296,3.645
          c0,0,0.111,0.28,0.997,0.368c0.412,0.041,1.084-0.307,1.104-0.565c-0.11-0.784-0.158-1.832-0.205-2.623
          C738.728,648.11,738.725,648.103,738.722,648.097z"/>
        <path class="st260" d="M736.77,651.737c0.024,0.002,0.048,0.003,0.072,0.003c0.059-0.005,0.117,0.001,0.176,0.011l0-0.002
          l0.012-0.76l-0.479,0.139l-0.013,0.567C736.641,651.707,736.669,651.716,736.77,651.737z"/>
        <path class="st260" d="M737.011,650.937l0.158,0.561l-0.027,0.606c0,0-0.129,0.012-0.128-0.171l0.008-0.21l-0.123-0.089
          L737.011,650.937z"/>
        <path class="st261" d="M736.564,650.891c-0.015-0.002-0.031-0.003-0.046-0.005l-0.001,0l0.021,0.206c0,0,0.163,0.279,0.519,0.01
          l0.009-0.257C736.925,650.945,736.725,650.957,736.564,650.891z"/>
        <path class="st287" d="M736.43,647.443l-0.089,1.746l0.113,1.8c0,0,0.289,0.216,0.645-0.053l0.07-1.815l0.159-1.511
          c0,0,0.044-0.995-0.349-0.995C736.465,646.617,736.43,647.443,736.43,647.443z"/>
        <path class="st260" d="M738.082,646.562L738.082,646.562c-0.24,0-0.435-0.195-0.435-0.435l0.064-0.711h0.806v0.711
          C738.517,646.368,738.323,646.562,738.082,646.562z"/>
        <path class="st260" d="M736.538,651.305l-0.046,0.503l0.085,0.24l0.178,0.13c0,0,0.101,0.151,0.112,0.169
          c0.039,0.059,0.132,0.017,0.118-0.052l-0.014-0.073l-0.015-0.227l0.065-0.272L736.538,651.305z"/>
        <path class="st56" d="M737.669,645.893c0,0,0.137,0.491,0.641,0.605l-0.107,0.337c0,0-0.497-0.082-0.724-0.646L737.669,645.893z"
          />
        <path class="st56" d="M738.309,646.498c0,0,0.212,0.148,0.265,0.237c0,0,0.114-0.532-0.057-0.702
          C738.517,646.033,738.561,646.304,738.309,646.498z"/>
        <path class="st287" d="M737.607,645.989l-0.277,0.296l0.165,0.698h0.153l-0.186,0.08c0,0,0.421,0.323,0.758,0.71
          c0.271,0.31,0.437,0.468,0.538,0.742c0,0,0.051-0.237,0.019-0.333C738.585,647.622,737.975,646.582,737.607,645.989z"/>
        <path class="st287" d="M738.497,645.953c0,0,0.349,0.231,0.412,0.609l-0.124,0.054h0.166c0,0,0.006,1.493-0.195,1.9
          c0,0,0.095-1.362-0.26-2.25V645.953z"/>
        <path class="st260" d="M738.202,646.099c0,0-0.651-0.202-0.651-0.566l-0.073-0.674l1.261-0.225v0.933
          C738.739,645.937,738.553,646.099,738.202,646.099z"/>
        <path class="st289" d="M738.58,644.258c-0.5-0.314-0.932-0.132-1.229,0.243c-0.145,0.183-0.045,0.552-0.002,0.726
          c0.098,0.392,0.325,0.606,0.325,0.606l0.038-0.621l0.073,0.123l0.105-0.076c0,0-0.046-0.263-0.044-0.307
          c0.007-0.149,0.893-0.171,0.893-0.171S739.126,644.601,738.58,644.258z"/>
        <path class="st260" d="M737.712,645.212c0,0-0.187-0.185-0.215,0.032c-0.02,0.16,0.086,0.336,0.215,0.276V645.212z"/>
        <polygon class="st282" points="738.285,646.573 738.219,646.78 738.362,646.955 738.542,646.925 738.574,646.735 738.482,646.634 
          738.371,646.543 		"/>
        <path class="st282" d="M738.362,646.955l0.062,0.453l0.344,0.754c0,0,0.012-0.357-0.029-0.7c-0.014-0.123-0.198-0.536-0.198-0.536
          L738.362,646.955z"/>
        <path class="st290" d="M740.854,651.418l-0.122-0.192c0,0-0.366-0.199-0.399-0.291c-0.034-0.092-0.262-0.607-0.046-0.63
          c0,0-0.002,0.054,0.043,0.078c0.11,0.059,0.323,0.198,0.375,0.195c0.111-0.007,0.04-0.187,0.062-0.269
          c0.022-0.082,0.104-0.078,0.104-0.078s0.026,0.117,0.048,0.18c0.022,0.063,0.096,0.213,0.096,0.213l0.02,0.303l0.179,0.183
          L740.854,651.418z"/>
        <path class="st56" d="M745.392,650.13c0,0,0.212,1.425,0.056,2.862c-0.037,0.345-0.758,0.185-0.758,0.185l0.225-0.762
          L745.392,650.13z"/>
        <path class="st42" d="M743.64,659.403c-0.222,0-0.395,0.337-0.189,0.42l1.199,0.358c0,0,0.421,0.118,0.539-0.168l-0.028-0.436
          l-0.865-0.174C744.296,659.403,743.965,659.403,743.64,659.403z"/>
        <path class="st262" d="M742.794,653.561l0.04,6.571c0.005,0.102,0.056,0.197,0.14,0.255c0.405,0.279,0.869-0.105,0.869-0.105
          l0.259-4.313l0.152,3.476c0.007,0.135,0.105,0.248,0.237,0.274c0.551,0.109,0.675-0.124,0.675-0.124s0.052-4.093,0-5.233
          c-0.013-0.287-0.183-1.036-0.183-1.036C744.745,653.46,742.949,653.749,742.794,653.561z"/>
        <path class="st155" d="M742.924,650.28c0,0,0.383-0.318,0.842-0.459c0.46-0.141,0.85-0.188,0.85-0.188s0.625,0,0.776,0.498
          c0.113,0.373-0.331,2.584-0.349,2.919c-0.018,0.336,0.017,0.641,0.017,0.641s-1.324,0.519-1.683,0.484
          c-0.495-0.047-0.565-0.254-0.565-0.254l-0.124-2.144L742.924,650.28z"/>
        <path class="st56" d="M740.734,651.332c0,0,0.965,1.294,1.247,1.294c0.283,0,1.171-0.939,1.541-1.42
          c0.273-0.355-0.076-1.35-0.599-0.926c-0.398,0.323-0.925,1.322-0.925,1.322l-0.947-0.757
          C741.052,650.845,740.681,650.827,740.734,651.332z"/>
        <path class="st261" d="M741.149,650.922l-0.096-0.077c0,0-0.371-0.018-0.318,0.487c0,0,0.054,0.072,0.139,0.182
          C740.872,651.282,740.939,651.071,741.149,650.922z"/>
        <path class="st260" d="M744.436,649.782l-0.064-0.711h-0.806l0.05,0.801C743.617,650.112,744.436,650.022,744.436,649.782z"/>
        <path class="st56" d="M743.613,649.814l-0.091,0.095c0.155,0.221,0.685,0.256,0.915,0.008c0.054-0.058,0.062-0.145,0.03-0.216
          l-0.031-0.068C744.367,649.861,743.866,649.908,743.613,649.814z"/>
        <path class="st260" d="M743.882,649.703c0,0,0.651-0.202,0.651-0.566l0.073-0.674l-1.332-0.225v0.933
          C743.274,649.541,743.531,649.703,743.882,649.703z"/>
        <path class="st264" d="M743.274,648.504c0,0,0.18,0.045,0.18,0.328c0,0.284,0.299,0.568,0.521,0.743
          c0.162,0.127,0.439-0.084,0.439-0.084s0.429-0.487,0.215-1.319c-0.131-0.507-0.846-0.483-1.049-0.393
          C743.291,647.905,742.835,648.198,743.274,648.504z"/>
        <path class="st260" d="M743.414,648.846c0,0,0.187-0.185,0.215,0.032c0.02,0.16-0.086,0.336-0.215,0.276V648.846z"/>
        <path class="st260" d="M740.286,650.305c0,0,0.023,0.134,0.232,0.216c0.023,0.009,0.041,0.028,0.042,0.053l-0.014,0.127
          c0,0-0.013,0.146,0.137,0.141l0.038-0.215c0-0.011,0.034-0.161,0.007-0.182C740.621,650.357,740.286,650.305,740.286,650.305z"/>
      </g>
      <g>
        <linearGradient id="SVGID_68_" gradientUnits="userSpaceOnUse" x1="866.3591" y1="688.0613" x2="877.4127" y2="688.0613">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st291" points="866.359,687.316 866.503,688.24 867.194,688.807 877.413,688.807 876.017,687.316 		"/>
        <path class="st292" d="M866.63,688.188c0,0,0.127,0.261,0.396,0.333c0.102,0.027,0.317,0.032,0.396,0.143
          c0.079,0.111-0.159,0.158-0.159,0.158s-0.454,0.024-0.613-0.01c-0.14-0.03-0.687-0.37-0.687-0.37s-0.047-0.158,0.127-0.38
          L866.63,688.188z"/>
        <path class="st260" d="M867.063,678.084c0,0-0.127,0.871-0.362,2.354c-0.056,0.356,0.651,0.582,0.651,0.582l-0.014-0.956
          L867.063,678.084z"/>
        <path class="st292" d="M870.33,688.774c0,0-0.69,0.229-0.755,0.234c-0.066,0.005-0.336,0.037-0.391-0.161l0.045-0.296l0.563-0.117
          c0,0,0.426-0.082,0.475-0.086c0.041-0.003,0.437,0.016,0.291,0.266C870.528,688.666,870.434,688.723,870.33,688.774z"/>
        <path class="st293" d="M868.875,681.989l-1.15,3.76l-1.095,2.44c0,0-0.318,0.213-0.539-0.127l0.887-2.472l0.155-2.642l0,0
          c-0.081-0.454-0.044-0.921,0.108-1.357l0.063-0.181L868.875,681.989z"/>
        <path class="st294" d="M868.725,682.479l-0.786,2.57c-0.006-0.124,0.182-1.46-0.658-1.888
          C867.881,683.161,868.328,682.739,868.725,682.479z"/>
        <path class="st293" d="M868.084,683.401c0,0,1.183,1.809,1.145,1.967c-0.038,0.158-0.075,3.291-0.024,3.322
          c0.052,0.031,0.451,0.132,0.562-0.248l0.261-3.084c0.003-0.13-0.017-0.259-0.061-0.381l-0.921-3.161l-0.611,0.383l-1.283,0.889
          L868.084,683.401z"/>
        <path class="st280" d="M867.137,681.945l0.165-0.821c0,0-0.386-2.151-0.365-2.266c0.022-0.115-0.063-0.803,0.411-0.95
          c0,0,0.545,0.052,1.126,0.221c0.38,0.111,0.46,0.555,0.555,0.681c0.22,0.294,0.301,0.444,0.269,0.57
          c-0.07,0.278-0.349,0.555-0.349,0.555l0.067,1.111l0.075,0.996c0,0-0.439,0.252-1.273,0.069
          C866.986,681.929,867.137,681.945,867.137,681.945z"/>
        <path class="st260" d="M869.913,682.604c0,0,0.182,0.414,0.172,0.446c-0.01,0.032-0.088,0.309-0.098,0.341
          c-0.01,0.032-0.123,0.109-0.201,0.12c-0.078,0.011-0.253,0.013-0.253,0.013s-0.012-0.036-0.002-0.068l-0.054-0.023
          c0,0-0.12-0.028-0.015-0.104l-0.027-0.034c0,0,0.011-0.071,0.075-0.08l-0.005-0.249c0,0-0.038-0.268,0.047-0.288L869.913,682.604z
          "/>
        <path class="st260" d="M868.966,678.637c0.11,0.178,0.166,2.194,0.166,2.194s0.784,1.743,0.793,1.811
          c0.01,0.068-0.056,0.299-0.404,0.071l-1.031-1.638c0,0-0.196-1.372-0.253-1.829C868.174,678.74,868.491,677.868,868.966,678.637z"
          />
        <path class="st260" d="M867.493,677.924l0.05-0.816l0.837-0.01l-0.174,0.922C868.209,678.268,867.496,678.173,867.493,677.924z"/>
        <path class="st260" d="M868.061,677.758c0,0-0.679-0.201-0.683-0.58l-0.085-0.699l1.381-0.251l0.012,0.969
          C868.69,677.582,868.426,677.753,868.061,677.758z"/>
        <path class="st289" d="M868.676,676.505c0,0-0.186,0.049-0.183,0.343c0.004,0.295-0.329,1.461-0.557,1.646
          c-0.166,0.134-0.808-0.048-0.808-0.048s-0.301-0.317,0.136-2.269c0.119-0.53,0.872-0.512,1.085-0.422
          C868.651,675.882,869.128,676.181,868.676,676.505z"/>
        <path class="st260" d="M868.536,676.862c0,0-0.197-0.189-0.222,0.036c-0.019,0.166,0.093,0.347,0.227,0.284L868.536,676.862z"/>
      </g>
      <g>
        <linearGradient id="SVGID_69_" gradientUnits="userSpaceOnUse" x1="870.7584" y1="689.6734" x2="882.1279" y2="689.6734">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st295" points="870.758,688.928 871.909,690.419 882.128,690.419 880.733,688.928 		"/>
        <path class="st282" d="M871.346,679.682c0,0-0.127,0.871-0.362,2.354c-0.056,0.356,0.642,0.823,0.642,0.823l-0.005-1.197
          L871.346,679.682z"/>
        <path class="st42" d="M871.942,690.28c0,0-0.513,0.02-0.777-0.073c-0.264-0.093-0.523-0.053-0.636-0.078
          c-0.221-0.05-0.276-0.207-0.276-0.207l0.218-0.47l0.897,0.08l0.411,0.418C871.78,689.949,872.212,690.01,871.942,690.28z"/>
        <path class="st42" d="M875.152,690.188c0,0-0.938,0.274-1.003,0.279c-0.066,0.005-0.336,0.037-0.391-0.161l0.045-0.296l0.84-0.258
          c0,0,0.397,0.014,0.446,0.011c0.041-0.003,0.437,0.016,0.291,0.266C875.35,690.08,875.256,690.136,875.152,690.188z"/>
        <path class="st296" d="M873.449,683.447l-1.149,3.76l-0.828,2.437c0,0-0.772,0.141-0.992-0.198l0.849-2.719l0.125-2.321l0,0
          c-0.081-0.453-0.044-0.922,0.108-1.358l0.063-0.179L873.449,683.447z"/>
        <path class="st297" d="M873.299,683.938l-0.786,2.57c-0.006-0.124,0.182-1.46-0.658-1.888
          C872.455,684.62,872.902,684.198,873.299,683.938z"/>
        <path class="st296" d="M872.658,684.859c0,0,0.844,1.881,0.806,2.038c-0.038,0.158,0.263,3.22,0.315,3.251
          c0.052,0.031,0.559,0.192,0.88-0.382l-0.008-3.072c0.003-0.13-0.017-0.259-0.061-0.381l-0.849-3.065l-0.611,0.383l-1.403,0.914
          L872.658,684.859z"/>
        <path class="st298" d="M871.461,683.303l0.099-1.336c0,0-0.263-1.65-0.241-1.764c0.022-0.115-0.17-0.818,0.615-1.077
          c0,0,0.466-0.169,1.032,0.046c0.735,0.279,0.826,0.694,0.851,1.119c0.035,0.596-0.23,1.284-0.23,1.284l0.016,0.69l0.066,1.237
          c0,0-0.439,0.252-1.273,0.069C871.56,683.387,871.461,683.303,871.461,683.303z"/>
        <g>
          <path class="st279" d="M874.493,684.098c0,0,0.208,0.474,0.196,0.51c-0.012,0.036-0.1,0.353-0.112,0.389
            c-0.012,0.036-0.141,0.124-0.23,0.137c-0.089,0.013-0.289,0.015-0.289,0.015s-0.014-0.041-0.003-0.078l-0.062-0.026
            c0,0-0.138-0.032-0.017-0.119l-0.03-0.039c0,0,0.013-0.081,0.086-0.091l-0.006-0.285c0,0-0.043-0.306,0.054-0.329
            L874.493,684.098z"/>
          <path class="st282" d="M873.642,679.756c0.133,0.162,0.266,2.209,0.266,2.209s0.633,2.005,0.643,2.073
            c0.01,0.068-0.18,0.373-0.528,0.145l-0.948-1.89c0,0-0.271-1.357-0.337-1.814C872.679,680.077,872.967,678.933,873.642,679.756z"
            />
          <path class="st298" d="M874.457,683.698c0.12,0.387,0.09,0.313,0.094,0.34c0.01,0.068-0.18,0.373-0.528,0.145l-0.136-0.265
            C874.215,684.017,874.391,683.907,874.457,683.698z"/>
        </g>
        <g>
          <path class="st279" d="M872.069,679.065l0.057-0.74l0.837-0.01l-0.042,0.833C872.925,679.397,872.072,679.315,872.069,679.065z"
            />
          <path class="st282" d="M872.925,679.048l0.084,0.118c-0.159,0.232-0.697,0.293-0.94,0.038c-0.056-0.059-0.066-0.149-0.033-0.224
            l0.039-0.074C872.15,679.143,872.664,679.15,872.925,679.048z"/>
          <path class="st279" d="M872.644,678.975c0,0-0.679-0.201-0.683-0.58l-0.085-0.699l1.381-0.251l0.012,0.969
            C873.273,678.8,873.009,678.971,872.644,678.975z"/>
          <path class="st283" d="M873.259,677.722c0,0-0.186,0.048-0.182,0.343c0.004,0.295-0.303,0.594-0.532,0.779
            c-0.166,0.135-0.457-0.082-0.457-0.082s-0.452-0.501-0.24-1.367c0.129-0.528,0.872-0.512,1.085-0.423
            C873.234,677.1,873.711,677.399,873.259,677.722z"/>
          <path class="st279" d="M873.119,678.08c0,0-0.197-0.189-0.223,0.036c-0.019,0.166,0.093,0.348,0.227,0.284L873.119,678.08z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse" x1="754.1494" y1="388.8685" x2="764.876" y2="388.8685">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st299" points="755.034,388.123 754.149,388.459 754.657,389.614 764.876,389.614 763.481,388.123 		"/>
        <g>
          <path class="st260" d="M756.212,383.588l0.16,0.404v0.329c0,0-0.073,0.013-0.093-0.067c-0.021-0.08-0.074-0.2-0.074-0.2
            L756.212,383.588z"/>
          <path class="st260" d="M755.967,384.048l0.119,0.251l-0.016,0.15c0,0-0.069,0.125,0.041,0.14l0.062-0.096v-0.29L755.967,384.048z
            "/>
          <path class="st260" d="M755.971,383.973l-0.036,0.286l0.032,0.131c0,0-0.011,0.099,0.069,0.079l0.09-0.24L755.971,383.973z"/>
          <path class="st260" d="M756.052,384.066c0.02,0.002,0.04,0.003,0.06,0.003c0.05-0.004,0.099,0.001,0.149,0.01l0-0.002
            l-0.056-0.607l-0.339,0.081l-0.011,0.48C755.942,384.04,755.966,384.048,756.052,384.066z"/>
          <path class="st260" d="M756.238,383.756c0,0,0.094,0.274,0.073,0.484c-0.021,0.211-0.106,0.357-0.106,0.357
            s-0.096,0.02-0.076-0.104l0.025-0.199l-0.186-0.274L756.238,383.756z"/>
          <path class="st56" d="M755.41,379.976c0,0,0.291,0.926,0.093,0.939c-0.06,0.004-0.118-0.001-0.175-0.009l0.14,1.269l0.392,1.608
            c0,0,0.267,0.055,0.402-0.139l-0.191-1.597c0,0,0.039-1.475-0.134-1.74C755.761,380.038,755.41,379.976,755.41,379.976z"/>
          <path class="st261" d="M756.22,383.456c-0.055,0.041-0.115,0.066-0.179,0.08c-0.074,0.03-0.149,0.052-0.229,0.052l0.048,0.195
            c0,0,0.267,0.055,0.402-0.139l-0.024-0.2C756.232,383.448,756.226,383.452,756.22,383.456z"/>
          <path class="st42" d="M754.135,388.961v0.256c0,0.074,0.04,0.142,0.104,0.178l0.781,0.435c0,0,0.351,0.097,0.478-0.169
            l-0.003-0.018c-0.022-0.119-0.209-0.242-0.294-0.328l-0.446-0.446L754.135,388.961z"/>
          <path class="st42" d="M755.312,388.381v0.256c0,0.074,0.04,0.142,0.104,0.178l0.418,0.143c0,0,0.448,0.305,0.645,0.238
            c0.173-0.058,0.319-0.238-0.102-0.462l-0.446-0.446L755.312,388.381z"/>
          <path class="st262" d="M755.496,383.562c-0.515,0.253-1.726,0.12-1.739,0.12l0.294,5.286c0.672,0.357,0.844-0.085,0.844-0.085
            l0.149-3.978l0.214,3.559c0.629,0.194,0.757-0.163,0.757-0.163s0.002-2.716-0.043-3.68c-0.011-0.243,0-1.15,0-1.15
            C755.771,383.585,755.687,383.468,755.496,383.562z"/>
          <path class="st261" d="M753.931,383.641c0,0,0.049,0.419,0.972,0.294c0.882-0.119,1.069-0.466,1.069-0.466l-0.089-1.246
            c0,0,0.117-1.512,0.032-1.754c-0.103-0.291-0.234-0.503-0.714-0.515h-0.345l-0.343,0.06l-0.554,0.361
            c-0.265,0.145-0.4,0.364-0.384,0.666L753.931,383.641z"/>
          <path class="st260" d="M753.77,384.803c0.02,0.002,0.04,0.003,0.061,0.003c0.05-0.004,0.099,0.001,0.149,0.01l0-0.002l0.01-0.643
            l-0.405,0.117l-0.011,0.48C753.66,384.778,753.684,384.786,753.77,384.803z"/>
          <path class="st260" d="M753.973,384.126l0.134,0.475l-0.023,0.513c0,0-0.109,0.01-0.109-0.145l0.007-0.177l-0.104-0.075
            L753.973,384.126z"/>
          <path class="st56" d="M753.482,381.17l-0.076,1.478l0.167,1.609c0,0,0.138,0.236,0.439,0.008l0.06-1.694l0.124-1.222
            c0,0,0.005-0.867-0.25-0.879C753.512,380.449,753.482,381.17,753.482,381.17z"/>
          <path class="st261" d="M753.595,384.087c-0.013-0.001-0.026-0.003-0.039-0.005l-0.001,0l0.018,0.175c0,0,0.138,0.236,0.439,0.008
            l0.008-0.217C753.901,384.132,753.732,384.143,753.595,384.087z"/>
          <path class="st260" d="M754.981,380.032c0,0-0.551-0.171-0.551-0.479l-0.062-0.571l1.067-0.19v0.79
            C755.436,379.895,755.279,380.032,754.981,380.032z"/>
          <path class="st260" d="M754.88,380.424L754.88,380.424c-0.203,0-0.368-0.165-0.368-0.368l0.054-0.602h0.682v0.602
            C755.248,380.259,755.084,380.424,754.88,380.424z"/>
          <path class="st264" d="M755.302,378.474c-0.423-0.266-0.788-0.112-1.04,0.206c-0.123,0.155-0.038,0.467-0.002,0.614
            c0.083,0.332,0.275,0.513,0.275,0.513l0.032-0.526l0.062,0.104l0.088-0.065c0,0-0.039-0.223-0.037-0.26
            c0.006-0.126,0.756-0.145,0.756-0.145S755.763,378.764,755.302,378.474z"/>
          <path class="st260" d="M754.567,379.282c0,0-0.159-0.156-0.182,0.027c-0.017,0.135,0.073,0.284,0.182,0.234V379.282z"/>
          <path class="st260" d="M753.573,384.437l-0.039,0.426l0.072,0.203l0.15,0.11c0,0,0.085,0.128,0.095,0.143
            c0.033,0.05,0.112,0.015,0.1-0.044l-0.012-0.062l-0.012-0.192l0.055-0.23L753.573,384.437z"/>
          <path class="st56" d="M754.53,379.858c0,0,0.024,0.436,0.542,0.512l-0.091,0.285c0,0-0.42-0.069-0.613-0.547L754.53,379.858z"/>
          <path class="st56" d="M755.072,380.37c0,0,0.179,0.126,0.224,0.2c0,0,0.096-0.45-0.048-0.594
            C755.248,379.976,755.291,380.229,755.072,380.37z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_71_" gradientUnits="userSpaceOnUse" x1="760.257" y1="390.0031" x2="771.413" y2="390.0031">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st300" points="761.571,389.258 760.257,389.756 761.194,390.748 771.413,390.748 770.018,389.258 		"/>
        <g>
          <path class="st260" d="M759.808,385.157l0.01-0.319l0.168-0.386l0.271,0.13c0,0-0.005,0.189-0.018,0.25
            c-0.013,0.063-0.083,0.241-0.083,0.241l0.043,0.163c0,0,0.008,0.096-0.07,0.075l-0.039-0.117l0.007,0.096
            c0,0,0.063,0.123-0.044,0.134l-0.019-0.032c-0.018,0.048-0.072,0.036-0.072,0.036s-0.065-0.127-0.086-0.309
            C759.846,385.154,759.808,385.157,759.808,385.157z"/>
          <path class="st262" d="M760.878,380.979c0,0-0.311,0.888-0.119,0.906c0.057,0.005,0.114,0.003,0.17-0.003l-0.176,1.226
            l-0.43,1.545c0,0-0.26,0.045-0.385-0.147l0.235-1.541c0,0,0.009-1.43,0.184-1.681
            C760.535,381.027,760.878,380.979,760.878,380.979z"/>
          <path class="st268" d="M759.983,384.324c0.052,0.042,0.11,0.068,0.171,0.083c0.071,0.032,0.143,0.055,0.22,0.058l-0.052,0.188
            c0,0-0.26,0.045-0.385-0.147l0.029-0.193C759.972,384.317,759.978,384.32,759.983,384.324z"/>
          <g>
            <g>
              <path class="st269" d="M762.226,389.861v0.25c0,0.072-0.039,0.139-0.102,0.174l-0.764,0.425c0,0-0.344,0.095-0.468-0.166
                l0.003-0.017c0.021-0.116,0.205-0.237,0.288-0.321l0.436-0.437L762.226,389.861z"/>
              <path class="st269" d="M761.019,389.206v0.25c0,0.072-0.039,0.139-0.102,0.174l-0.764,0.425c0,0-0.344,0.095-0.468-0.166
                l0.003-0.017c0.021-0.116,0.205-0.237,0.288-0.321l0.437-0.437L761.019,389.206z"/>
              <path class="st262" d="M762.433,384.908l-0.26,0.125c-0.389,0.187-0.843,0.185-1.231-0.004l-0.7-0.333l-0.03-2.478
                c0,0-0.026-0.631,0.179-0.986c0.182-0.316,0.477-0.512,0.941-0.368l0.384,0.176l0.509,0.334
                c0.259,0.141,0.391,0.357,0.376,0.651L762.433,384.908z"/>
              <path class="st270" d="M760.257,384.021c0.492,0.439,1.23,0.701,1.851,0.667c0.138-0.007,0.251-0.051,0.342-0.115l-0.142,5.293
                c-0.657,0.35-0.826-0.083-0.826-0.083l-0.207-3.576l-0.205,3.079c-0.616,0.19-0.74-0.16-0.74-0.16
                S760.257,384.052,760.257,384.021z"/>
              <path class="st262" d="M760.392,381.231c0.093-0.162,0.217-0.291,0.377-0.361c0.019,0.008,0.037,0.022,0.059,0.024
                c0.848,0.074,1.483,0.506,1.6,1.248c0.023,0.143,0.006,0.597,0.098,0.648l-0.093,2.117l-0.26,0.125
                c-0.389,0.187-0.843,0.185-1.231-0.004l-0.7-0.333l-0.03-2.478C760.213,382.218,760.187,381.586,760.392,381.231z"/>
              <path class="st271" d="M762.094,384.268c-0.015-0.48-0.009-0.881-0.074-1.357c-0.056-0.411,0.003-0.864,0.252-1.216
                c0.075,0.134,0.13,0.282,0.156,0.448c0.023,0.143,0.006,0.597,0.098,0.648l-0.093,2.117l-0.26,0.125
                c-0.023,0.011-0.049,0.016-0.072,0.025C762.11,384.797,762.103,384.536,762.094,384.268z"/>
              <path class="st56" d="M760.831,381.578c0,0,0.05-0.576,0.073-0.574c0.476,0.041,0.607,0.02,0.895,0.279
                c-0.06,0.033-0.254,0.177-0.31,0.237c-0.266,0.289-0.294,0.247-0.613,0.474L760.831,381.578z"/>
              <path class="st272" d="M761.344,381.412L761.344,381.412c0.206,0,0.373-0.167,0.373-0.373v-0.61h-0.746v0.61
                C760.971,381.245,761.138,381.412,761.344,381.412z"/>
              <path class="st260" d="M761.282,381.134L761.282,381.134c0.313,0,0.568-0.268,0.568-0.599v-0.654l-1.146-0.204v0.848
                C760.704,380.861,760.963,381.134,761.282,381.134z"/>
              <path class="st264" d="M761.72,380.914c0,0,0.266-0.284,0.311-0.77v-0.291l-1.344-0.044v0.11h0.63
                c0.228,0,0.412,0.199,0.409,0.442L761.72,380.914z"/>
            </g>
          </g>
          <g>
            <path class="st260" d="M762.542,385.679c-0.019,0.003-0.039,0.005-0.058,0.005c-0.048-0.001-0.096,0.005-0.144,0.016l0-0.002
              l-0.038-0.622l0.398,0.096l0.032,0.465C762.647,385.65,762.624,385.659,762.542,385.679z"/>
            <path class="st260" d="M762.316,385.033l-0.109,0.466l0.044,0.496c0,0,0.106,0.005,0.099-0.145l-0.014-0.171l0.098-0.077
              L762.316,385.033z"/>
            <path class="st262" d="M762.707,382.197l0.174,1.442l-0.172,1.503c0,0-0.123,0.235-0.425,0.027l-0.051-1.575l-0.21-1.192
              c0,0-0.042-0.84,0.204-0.863C762.647,381.5,762.707,382.197,762.707,382.197z"/>
            <path class="st268" d="M762.681,384.978c0.012-0.002,0.025-0.004,0.037-0.006l0,0l-0.01,0.17c0,0-0.123,0.235-0.425,0.027
              l-0.017-0.21C762.386,385.035,762.55,385.039,762.681,384.978z"/>
            <path class="st260" d="M762.716,385.316l0.056,0.411l-0.061,0.2l-0.141,0.113c0,0-0.077,0.128-0.086,0.142
              c-0.03,0.05-0.107,0.019-0.099-0.038l0.009-0.06l0.003-0.186l-0.063-0.221L762.716,385.316z"/>
          </g>
        </g>
        <g>
          <path class="st273" d="M761.009,380.581c0.036,0,0.072-0.007,0.106-0.02l0.479-0.184c0.097-0.023,0.189-0.06,0.274-0.109
            l0.094-0.056c0.154-0.089,0.246-0.248,0.246-0.426c0-0.178-0.092-0.338-0.246-0.427l-0.005-0.003
            c-0.157-0.09-0.335-0.138-0.516-0.138c-0.181,0-0.359,0.048-0.516,0.138l-0.094,0.056c-0.097,0.056-0.171,0.141-0.211,0.244
            l-0.243,0.376c-0.028,0.043-0.036,0.094-0.024,0.144c0.012,0.05,0.043,0.091,0.087,0.117l0.422,0.248
            C760.905,380.567,760.957,380.581,761.009,380.581L761.009,380.581z"/>
          <path class="st274" d="M760.405,380.051l0.245-0.379v0c0.036-0.092,0.101-0.174,0.198-0.23l0.094-0.056
            c0.308-0.178,0.688-0.178,0.996,0l0.005,0.003c0.305,0.176,0.305,0.616,0,0.792l-0.094,0.056
            c-0.086,0.049-0.177,0.085-0.271,0.107l0,0l-0.478,0.184c-0.073,0.028-0.156,0.021-0.224-0.018l-0.422-0.248
            C760.383,380.219,760.359,380.123,760.405,380.051z"/>
          <path class="st275" d="M760.694,379.808c0-0.136-0.005-0.802,0.727-0.802c0.473,0,0.624,0.471,0.663,0.643
            c0.005,0.02,0.01,0.04,0.012,0.061c0,0.001,0,0.001,0,0.001h0c0.018,0.155-0.05,0.318-0.204,0.407l-0.082,0.047
            c-0.283,0.163-0.631,0.161-0.912-0.005l0,0C760.763,380.081,760.695,379.945,760.694,379.808L760.694,379.808z"/>
          <path class="st276" d="M762.027,379.47c0,0,0.014-0.067-0.014-0.07c-0.074-0.008-0.224-0.127-0.534,0.055
            c-0.31,0.182-0.309,0.425-0.336,0.531c-0.027,0.106-0.03,0.288-0.03,0.288l0.038-0.011c0,0,0-0.145,0.099-0.426
            C761.318,379.644,761.608,379.314,762.027,379.47z"/>
          <path class="st273" d="M762.027,379.413c0,0-0.142-0.063-0.213-0.05c-0.235,0.044-0.417,0.107-0.55,0.355
            c-0.157,0.291-0.151,0.556-0.151,0.556s-0.367-0.074-0.458-0.285c0,0,0.021-0.323,0.198-0.581
            c0.075-0.11,0.352-0.378,0.693-0.389c0.065-0.002,0.204,0.058,0.287,0.138C762.005,379.32,762.027,379.413,762.027,379.413z"/>
        </g>
        <path class="st277" d="M761.717,380.96l0.187,0.202c0,0-0.021,0.348-0.354,0.523l-0.076-0.077v0.141l-0.598,0.245
          c0,0,0.383-0.356,0.532-0.469C761.518,381.441,761.735,381.282,761.717,380.96z"/>
        <path class="st277" d="M760.971,381.039c0,0-0.169,0.517-0.095,0.956c0,0-0.112-0.332-0.138-0.502l0.066-0.107l-0.079-0.013
          c0,0,0.055-0.309,0.14-0.427C760.864,380.945,760.923,381.008,760.971,381.039z"/>
        <path class="st277" d="M760.875,381.995c0,0-0.094,0.259-0.125,0.823c-0.031,0.565,0,2.119,0,2.119l-0.026-0.012
          c0,0-0.021-1.099-0.021-1.55c0-0.451,0.021-0.975,0.154-1.431L760.875,381.995z"/>
      </g>
      <g>
        <linearGradient id="SVGID_72_" gradientUnits="userSpaceOnUse" x1="405.41" y1="524.4048" x2="416.4681" y2="524.4048">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st301" points="405.41,523.66 406.249,525.15 416.468,525.15 415.073,523.66 		"/>
        <path class="st282" d="M408.724,514.413c0,0,0.127,0.871,0.362,2.354c0.056,0.356-0.642,0.823-0.642,0.823l0.005-1.198
          L408.724,514.413z"/>
        <path class="st42" d="M408.127,525.011c0,0,0.513,0.019,0.777-0.074c0.264-0.093,0.523-0.053,0.636-0.078
          c0.221-0.05,0.276-0.207,0.276-0.207l-0.218-0.47l-0.897,0.079l-0.411,0.418C408.29,524.681,407.858,524.742,408.127,525.011z"/>
        <path class="st42" d="M404.918,524.92c0,0,0.938,0.274,1.003,0.279c0.066,0.005,0.336,0.037,0.391-0.161l-0.045-0.296l-0.84-0.258
          c0,0-0.397,0.014-0.446,0.011c-0.041-0.003-0.437,0.016-0.291,0.266C404.72,524.811,404.814,524.868,404.918,524.92z"/>
        <path class="st296" d="M406.62,518.179l1.15,3.76l0.828,2.437c0,0,0.772,0.141,0.992-0.198l-0.849-2.719l-0.125-2.321l0,0
          c0.081-0.453,0.044-0.922-0.108-1.358l-0.063-0.179L406.62,518.179z"/>
        <path class="st297" d="M406.77,518.669l0.786,2.57c0.006-0.124-0.182-1.46,0.658-1.888
          C407.615,519.352,407.168,518.929,406.77,518.669z"/>
        <path class="st296" d="M407.412,519.591c0,0-0.844,1.88-0.806,2.038c0.038,0.158-0.263,3.22-0.315,3.251
          c-0.052,0.031-0.558,0.192-0.88-0.382l0.008-3.071c-0.004-0.13,0.017-0.259,0.061-0.381l0.849-3.065l0.611,0.383l1.403,0.914
          L407.412,519.591z"/>
        <path class="st298" d="M408.609,518.035l-0.099-1.336c0,0,0.263-1.649,0.241-1.764c-0.022-0.115,0.17-0.817-0.615-1.077
          c0,0-0.466-0.169-1.032,0.046c-0.735,0.279-0.826,0.694-0.85,1.119c-0.035,0.596,0.23,1.284,0.23,1.284l-0.016,0.69l-0.066,1.237
          c0,0,0.439,0.252,1.273,0.069C408.51,518.119,408.609,518.035,408.609,518.035z"/>
        <g>
          <path class="st279" d="M405.577,518.83c0,0-0.208,0.474-0.196,0.51c0.012,0.036,0.1,0.353,0.112,0.389
            c0.011,0.036,0.14,0.124,0.23,0.137c0.09,0.013,0.289,0.015,0.289,0.015s0.014-0.041,0.003-0.078l0.062-0.026
            c0,0,0.138-0.032,0.017-0.119l0.03-0.039c0,0-0.013-0.081-0.086-0.091l0.006-0.285c0,0,0.043-0.306-0.054-0.329L405.577,518.83z"
            />
          <path class="st282" d="M406.428,514.487c-0.133,0.162-0.266,2.209-0.266,2.209s-0.633,2.006-0.642,2.074
            c-0.01,0.068,0.18,0.373,0.528,0.145l0.948-1.89c0,0,0.271-1.357,0.337-1.814C407.391,514.808,407.103,513.664,406.428,514.487z"
            />
          <path class="st298" d="M405.613,518.43c-0.12,0.387-0.09,0.313-0.094,0.34c-0.01,0.068,0.18,0.373,0.528,0.145l0.136-0.265
            C405.854,518.748,405.679,518.638,405.613,518.43z"/>
        </g>
        <g>
          <path class="st279" d="M408.001,513.797l-0.057-0.74l-0.837-0.011l0.042,0.833C407.145,514.129,407.998,514.046,408.001,513.797z
            "/>
          <path class="st282" d="M407.145,513.78l-0.084,0.118c0.159,0.232,0.697,0.293,0.939,0.038c0.056-0.059,0.066-0.149,0.033-0.224
            l-0.039-0.074C407.919,513.875,407.406,513.881,407.145,513.78z"/>
          <path class="st279" d="M407.426,513.707c0,0,0.679-0.202,0.684-0.58l0.085-0.699l-1.381-0.251l-0.012,0.969
            C406.797,513.531,407.061,513.702,407.426,513.707z"/>
          <path class="st283" d="M406.81,512.454c0,0,0.186,0.049,0.182,0.343c-0.004,0.295,0.303,0.594,0.532,0.779
            c0.166,0.135,0.457-0.082,0.457-0.082s0.452-0.501,0.24-1.367c-0.129-0.528-0.872-0.512-1.085-0.423
            C406.836,511.832,406.358,512.13,406.81,512.454z"/>
          <path class="st279" d="M406.951,512.811c0,0,0.197-0.189,0.223,0.036c0.019,0.166-0.093,0.348-0.227,0.284L406.951,512.811z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_73_" gradientUnits="userSpaceOnUse" x1="474.3193" y1="494.8586" x2="485.3775" y2="494.8586">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st302" points="474.319,494.113 475.158,495.604 485.378,495.604 483.982,494.113 		"/>
        <g>
          <path class="st279" d="M474.136,489.34l-0.16,0.404v0.329c0,0,0.073,0.013,0.093-0.067c0.021-0.08,0.074-0.2,0.074-0.2
            L474.136,489.34z"/>
          <path class="st279" d="M474.381,489.8l-0.119,0.251l0.016,0.15c0,0,0.069,0.125-0.041,0.14l-0.062-0.096v-0.29L474.381,489.8z"/>
          <path class="st279" d="M474.376,489.725l0.036,0.286l-0.032,0.131c0,0,0.011,0.099-0.069,0.079l-0.09-0.24L474.376,489.725z"/>
          <path class="st279" d="M474.296,489.817c-0.02,0.002-0.04,0.003-0.06,0.003c-0.05-0.004-0.099,0.001-0.149,0.01l0-0.002
            l0.056-0.607l0.339,0.081l0.011,0.48C474.406,489.792,474.382,489.8,474.296,489.817z"/>
          <path class="st279" d="M474.11,489.508c0,0-0.094,0.273-0.073,0.484c0.021,0.21,0.106,0.357,0.106,0.357s0.096,0.02,0.076-0.104
            l-0.025-0.199l0.186-0.274L474.11,489.508z"/>
          <path class="st281" d="M474.938,485.728c0,0-0.291,0.926-0.093,0.939c0.059,0.004,0.118-0.001,0.175-0.009l-0.14,1.27
            l-0.392,1.607c0,0-0.267,0.055-0.402-0.139l0.191-1.597c0,0-0.039-1.475,0.134-1.74
            C474.587,485.789,474.938,485.728,474.938,485.728z"/>
          <path class="st261" d="M474.128,489.207c0.055,0.041,0.115,0.066,0.179,0.08c0.074,0.03,0.149,0.052,0.229,0.052l-0.048,0.195
            c0,0-0.267,0.055-0.402-0.139l0.024-0.2C474.117,489.2,474.122,489.203,474.128,489.207z"/>
          <path class="st42" d="M476.213,494.713v0.256c0,0.074-0.04,0.142-0.104,0.178l-0.781,0.435c0,0-0.351,0.097-0.478-0.169
            l0.003-0.018c0.022-0.119,0.209-0.242,0.294-0.328l0.446-0.446L476.213,494.713z"/>
          <path class="st42" d="M475.036,494.133v0.256c0,0.074-0.04,0.142-0.104,0.178l-0.418,0.143c0,0-0.448,0.305-0.645,0.238
            c-0.173-0.058-0.319-0.238,0.102-0.462l0.446-0.446L475.036,494.133z"/>
          <path class="st262" d="M474.852,489.314c0.515,0.253,1.726,0.12,1.739,0.12l-0.294,5.286c-0.672,0.357-0.844-0.085-0.844-0.085
            l-0.149-3.978l-0.215,3.559c-0.629,0.194-0.756-0.164-0.756-0.164s-0.002-2.715,0.043-3.68c0.011-0.243,0-1.15,0-1.15
            C474.577,489.336,474.661,489.219,474.852,489.314z"/>
          <path class="st303" d="M476.417,489.393c0,0-0.049,0.419-0.972,0.294c-0.882-0.119-1.069-0.466-1.069-0.466l0.089-1.246
            c0,0-0.117-1.512-0.032-1.754c0.103-0.291,0.234-0.503,0.715-0.515h0.345l0.343,0.06l0.553,0.361
            c0.265,0.145,0.4,0.364,0.384,0.666L476.417,489.393z"/>
          <path class="st279" d="M476.578,490.555c-0.02,0.002-0.04,0.003-0.06,0.003c-0.05-0.004-0.099,0.001-0.149,0.01l0-0.002
            l-0.011-0.643l0.406,0.117l0.011,0.48C476.688,490.53,476.664,490.538,476.578,490.555z"/>
          <path class="st279" d="M476.375,489.878l-0.134,0.475l0.022,0.513c0,0,0.109,0.01,0.108-0.145l-0.007-0.177l0.104-0.075
            L476.375,489.878z"/>
          <path class="st281" d="M476.866,486.921l0.076,1.478l-0.167,1.609c0,0-0.138,0.236-0.439,0.008l-0.06-1.694l-0.124-1.222
            c0,0-0.005-0.867,0.25-0.88C476.836,486.2,476.866,486.921,476.866,486.921z"/>
          <path class="st261" d="M476.753,489.839c0.013-0.001,0.026-0.003,0.039-0.004l0.001,0l-0.018,0.175c0,0-0.138,0.236-0.439,0.008
            l-0.008-0.217C476.447,489.884,476.616,489.895,476.753,489.839z"/>
          <path class="st279" d="M475.367,485.784c0,0,0.551-0.171,0.551-0.479l0.062-0.571l-1.067-0.19v0.79
            C474.912,485.647,475.069,485.784,475.367,485.784z"/>
          <path class="st279" d="M475.468,486.176L475.468,486.176c0.203,0,0.368-0.165,0.368-0.368l-0.054-0.602H475.1v0.602
            C475.1,486.011,475.265,486.176,475.468,486.176z"/>
          <path class="st264" d="M475.046,484.226c0.423-0.266,0.788-0.112,1.04,0.206c0.123,0.155,0.038,0.467,0.002,0.614
            c-0.083,0.332-0.275,0.513-0.275,0.513l-0.032-0.526l-0.062,0.104l-0.089-0.065c0,0,0.039-0.223,0.037-0.26
            c-0.006-0.126-0.756-0.145-0.756-0.145S474.585,484.516,475.046,484.226z"/>
          <path class="st279" d="M475.781,485.034c0,0,0.159-0.156,0.182,0.027c0.017,0.135-0.073,0.284-0.182,0.234V485.034z"/>
          <path class="st279" d="M476.775,490.189l0.039,0.426l-0.072,0.203l-0.15,0.109c0,0-0.085,0.128-0.095,0.143
            c-0.033,0.05-0.111,0.015-0.1-0.044l0.012-0.062l0.012-0.192l-0.055-0.23L476.775,490.189z"/>
          <path class="st56" d="M475.818,485.61c0,0-0.024,0.436-0.542,0.512l0.091,0.285c0,0,0.42-0.069,0.613-0.547L475.818,485.61z"/>
          <path class="st56" d="M475.276,486.122c0,0-0.179,0.126-0.224,0.2c0,0-0.096-0.45,0.048-0.594
            C475.1,485.728,475.057,485.98,475.276,486.122z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_74_" gradientUnits="userSpaceOnUse" x1="585.0245" y1="260.259" x2="596.394" y2="260.259">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st304" points="585.025,259.514 586.175,261.004 596.394,261.004 594.999,259.514 		"/>
        <path class="st305" d="M583.767,252.538c0,0-0.039,0.063,0.097,0.226c0.136,0.163,0.187,0.201,0.256,0.184
          c0.069-0.017,0.141-0.158,0-0.264C583.979,252.578,583.944,252.508,583.767,252.538z"/>
        <path class="st260" d="M583.856,252.611c0,0-0.133-0.073-0.301,0.016c-0.168,0.088-0.531,0.133-0.531,0.133
          s-0.018,0.092,0.088,0.104h0.15c0,0-0.292,0.186-0.46,0.233c-0.043,0.012-0.017,0.137,0.106,0.128c0,0,0.141,0.113,0.389,0.082
          c0.305-0.038,0.601-0.257,0.601-0.257s0.097-0.134,0.25-0.271C584.15,252.777,584.297,252.541,583.856,252.611z"/>
        <path class="st261" d="M583.767,252.586c0,0,0.265,0.068,0.234,0.341l0.222-0.11c0,0-0.102-0.323-0.217-0.323L583.767,252.586z"/>
        <path class="st262" d="M586.185,249.352c0,0-0.344,1.095-0.11,1.109c0.07,0.004,0.139-0.001,0.206-0.01l-0.527,0.775l-0.149,1.358
          l-1.484,0.365c0,0,0.039-0.059,0.009-0.136c-0.05-0.128-0.201-0.298-0.363-0.274l1.22-0.743c0,0,0.341-1.745,0.539-2.062
          C585.775,249.336,586.185,249.352,586.185,249.352z"/>
        <path class="st270" d="M585.709,250.392c-0.006,0.006-0.012,0.012-0.018,0.018c-0.025,0.025-0.054,0.031-0.08,0.053
          c-0.071,0.182-0.171,0.999-0.289,1.23c-0.062,0.279-0.352,0.505-0.631,0.63c-0.1,0.07-0.542,0.322-0.64,0.364
          c0.034,0.041,0.063,0.085,0.079,0.126c0.03,0.077-0.009,0.136-0.009,0.136l1.484-0.365l0.149-1.358
          C585.755,251.225,586.014,250.087,585.709,250.392z"/>
        <path class="st42" d="M587.691,259.97v0.302c0,0.087-0.047,0.168-0.123,0.21l-0.923,0.514c0,0-0.415,0.115-0.565-0.2l0.004-0.021
          c0.025-0.14,0.247-0.286,0.347-0.387l0.527-0.528L587.691,259.97z"/>
        <path class="st42" d="M586.301,259.285v0.302c0,0.087-0.047,0.168-0.123,0.21l-0.494,0.169c0,0-0.53,0.36-0.763,0.282
          c-0.204-0.069-0.377-0.282,0.121-0.546l0.527-0.527L586.301,259.285z"/>
        <path class="st306" d="M586.083,253.589c0.609,0.299,2.04,0.142,2.055,0.141l-0.347,6.247c-0.794,0.422-0.997-0.101-0.997-0.101
          l-0.176-4.701l-0.253,4.206c-0.744,0.229-0.894-0.193-0.894-0.193s-0.002-3.209,0.05-4.349c0.013-0.287,0.086-1.276,0.086-1.276
          C585.843,253.7,585.857,253.478,586.083,253.589z"/>
        <path class="st260" d="M588.341,254.448l0.06,0.68l-0.085,0.24l-0.178,0.13c0,0-0.101,0.152-0.112,0.169
          c-0.039,0.059-0.132,0.017-0.118-0.052l0.014-0.073l0.014-0.227l-0.065-0.272L588.341,254.448z"/>
        <path class="st261" d="M585.776,251.876c-0.024-0.747-0.07-1.441,0.068-2.168c0.195-0.062,0.384-0.132,0.552-0.251
          c0.051-0.036,0.081-0.082,0.097-0.131h0.347l0.405,0.071l0.654,0.427c0.313,0.171,0.473,0.43,0.454,0.787l-0.421,3.073
          c0,0-0.058,0.495-1.148,0.348c-0.599-0.081-0.927-0.251-1.097-0.38C585.77,253.063,585.796,252.47,585.776,251.876z"/>
        <path class="st262" d="M585.626,252.008c0,0-0.018-1.74,0.088-2.073c0.109-0.343,0.229-0.585,0.703-0.583
          c-0.358,0.631,0.094,1.231,0.114,1.641c0.02,0.412-0.103,0.376-0.105,0.789c-0.003,0.551-0.133,1.086-0.126,1.637
          c-0.147,0.073,0.382,0.904,0.177,0.939c-0.269,0.047-0.956-0.516-0.956-0.516L585.626,252.008z"/>
        <path class="st262" d="M586.376,250.894c0,0,0.618-0.772,0.722-0.929c0.126-0.191,0.209-0.284,0.257-0.535l0.544,0.394
          c0.313,0.171,0.473,0.43,0.454,0.787l-0.296,3.645c0,0-0.093,0.106-0.979,0.193c-0.412,0.041-1.102-0.132-1.122-0.391
          C586.067,253.274,586.376,250.894,586.376,250.894z"/>
        <path class="st270" d="M587.689,251.156c-0.17,0.03-0.203,0.869-0.246,1.403c-0.111,1.35-0.364,1.891-0.364,1.891
          s0.81,0.04,0.979-0.141c0.635-0.676,0.296-3.698,0.296-3.698c0.002-0.037-0.006-0.069-0.008-0.104
          C588.269,250.799,588.079,251.088,587.689,251.156z"/>
        <path class="st260" d="M588.123,255.056c-0.024,0.002-0.047,0.003-0.071,0.003c-0.059-0.005-0.117,0.001-0.176,0.011l0-0.002
          l-0.012-0.76l0.479,0.139l0.013,0.568C588.252,255.026,588.224,255.036,588.123,255.056z"/>
        <path class="st260" d="M587.882,254.256l-0.158,0.561l0.026,0.606c0,0,0.129,0.012,0.128-0.171l-0.008-0.209l0.123-0.089
          L587.882,254.256z"/>
        <path class="st261" d="M588.329,254.21c0.015-0.002,0.031-0.003,0.046-0.005l0.001,0l-0.021,0.259c0,0-0.163,0.227-0.519-0.043
          l-0.009-0.257C587.967,254.264,588.168,254.277,588.329,254.21z"/>
        <path class="st262" d="M588.463,250.762l0.089,1.746l-0.113,1.8c0,0-0.289,0.217-0.645-0.053l-0.07-1.815l-0.159-1.51
          c0,0-0.044-0.996,0.349-0.995C588.428,249.936,588.463,250.762,588.463,250.762z"/>
        <path class="st260" d="M586.564,249.965c0,0,0.682-0.129,0.682-0.519l-0.064-0.711h-0.806v0.711
          C586.376,249.687,586.418,249.896,586.564,249.965z"/>
        <path class="st260" d="M586.691,249.418c0,0,0.651-0.202,0.651-0.566l0.073-0.674l-1.261-0.225v0.933
          C586.154,249.256,586.34,249.418,586.691,249.418z"/>
        <path class="st289" d="M586.312,247.577c0.5-0.314,0.932-0.132,1.229,0.243c0.145,0.183,0.045,0.552,0.002,0.725
          c-0.098,0.392-0.325,0.606-0.325,0.606l-0.038-0.621l-0.073,0.123l-0.105-0.076c0,0,0.046-0.263,0.044-0.307
          c-0.007-0.149-0.893-0.171-0.893-0.171S585.767,247.92,586.312,247.577z"/>
        <path class="st260" d="M587.181,248.531c0,0,0.188-0.185,0.215,0.032c0.02,0.16-0.086,0.336-0.215,0.276V248.531z"/>
        <path class="st277" d="M587.227,249.24l0.251,0.266c0,0-0.002,0.353-0.188,0.552l-0.109-0.03l0.064,0.24l-0.768,0.498
          c0,0,0.315-0.459,0.405-0.597C587.308,249.515,587.227,249.24,587.227,249.24z"/>
        <path class="st277" d="M586.376,249.437c0,0-0.221,0.331,0.124,1.33c0,0-0.301-0.323-0.394-0.656l0.076-0.169l-0.111-0.099
          c0,0-0.037-0.32,0.115-0.49l0.118-0.044l0.072,0.044V249.437z"/>
        <g>
          <path class="st273" d="M586.436,248.823c0.039,0,0.077-0.007,0.114-0.021l0.516-0.199c0.105-0.025,0.204-0.065,0.295-0.117
            l0.102-0.06c0.166-0.096,0.265-0.268,0.265-0.459c0-0.192-0.099-0.364-0.266-0.46l-0.006-0.003
            c-0.169-0.097-0.361-0.149-0.556-0.149c-0.195,0-0.387,0.052-0.556,0.149l-0.102,0.06c-0.105,0.061-0.184,0.152-0.228,0.263
            l-0.262,0.405c-0.03,0.047-0.039,0.102-0.026,0.155c0.013,0.053,0.046,0.098,0.094,0.126l0.455,0.267
            C586.324,248.808,586.379,248.823,586.436,248.823L586.436,248.823z"/>
          <path class="st274" d="M585.785,248.252l0.264-0.408l0,0c0.038-0.099,0.109-0.188,0.213-0.248l0.101-0.06
            c0.332-0.192,0.741-0.192,1.073,0l0.006,0.003c0.328,0.189,0.328,0.663,0,0.853l-0.101,0.06
            c-0.092,0.053-0.191,0.091-0.292,0.115l0.001,0l-0.515,0.198c-0.079,0.03-0.168,0.023-0.241-0.02l-0.455-0.267
            C585.761,248.432,585.736,248.329,585.785,248.252z"/>
          <path class="st275" d="M586.096,247.99c0-0.146-0.005-0.863,0.783-0.863c0.509,0,0.672,0.507,0.714,0.693
            c0.006,0.022,0.01,0.044,0.013,0.066l0,0.001h0c0.019,0.167-0.054,0.342-0.22,0.438l-0.088,0.051
            c-0.304,0.176-0.68,0.174-0.982-0.006l0,0C586.17,248.284,586.098,248.137,586.096,247.99L586.096,247.99z"/>
          <path class="st276" d="M587.532,247.626c0,0,0.015-0.072-0.015-0.075c-0.079-0.008-0.241-0.137-0.576,0.059
            c-0.334,0.196-0.333,0.458-0.362,0.572c-0.029,0.114-0.032,0.311-0.032,0.311l0.041-0.012c0,0,0-0.156,0.107-0.459
            C586.768,247.813,587.081,247.458,587.532,247.626z"/>
          <path class="st273" d="M587.532,247.564c0,0-0.153-0.068-0.229-0.054c-0.253,0.048-0.449,0.115-0.593,0.383
            c-0.169,0.314-0.163,0.599-0.163,0.599s-0.395-0.08-0.493-0.307c0,0,0.023-0.348,0.214-0.626
            c0.081-0.118,0.379-0.408,0.747-0.419c0.07-0.002,0.22,0.063,0.309,0.149C587.509,247.464,587.532,247.564,587.532,247.564z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_75_" gradientUnits="userSpaceOnUse" x1="578.4576" y1="263.9697" x2="589.8271" y2="263.9697">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st307" points="578.458,263.225 579.608,264.715 589.827,264.715 588.432,263.225 		"/>
        <path class="st42" d="M581.128,263.97c0.233,0.001,0.398,0.268,0.198,0.44l-1.258,0.375c0,0-0.442,0.124-0.565-0.177l0.029-0.457
          l1.01-0.182C580.542,263.97,580.787,263.969,581.128,263.97z"/>
        <path class="st308" d="M577.983,254.061c0,0-0.212,1.425-0.056,2.862c0.037,0.345,0.758,0.185,0.758,0.185l-0.225-0.762
          L577.983,254.061z"/>
        <path class="st42" d="M579.735,263.335c0.222,0,0.395,0.337,0.189,0.42l-1.199,0.358c0,0-0.421,0.118-0.539-0.169l0.028-0.435
          l0.865-0.173C579.079,263.335,579.41,263.334,579.735,263.335z"/>
        <path class="st262" d="M580.58,257.493l-0.039,6.571c-0.005,0.102-0.056,0.197-0.14,0.255c-0.405,0.279-0.869-0.105-0.869-0.105
          l-0.259-4.313l-0.152,3.475c-0.007,0.135-0.105,0.248-0.237,0.274c-0.551,0.109-0.675-0.124-0.675-0.124s-0.052-4.093,0-5.233
          c0.013-0.287,0.183-1.036,0.183-1.036C578.629,257.392,580.426,257.681,580.58,257.493z"/>
        <path class="st212" d="M580.461,254.278c0,0-0.226-0.316-0.853-0.526c-0.456-0.153-0.85-0.188-0.85-0.188s-0.625,0-0.776,0.497
          c-0.113,0.374,0.331,2.584,0.349,2.92c0.018,0.336-0.017,0.641-0.017,0.641s1.324,0.519,1.683,0.484
          c0.495-0.047,0.565-0.254,0.565-0.254l0.124-2.144L580.461,254.278z"/>
        <path class="st260" d="M578.938,253.714l0.064-0.711h0.806l-0.05,0.801C579.758,254.044,578.938,253.954,578.938,253.714z"/>
        <path class="st56" d="M579.762,253.745l0.091,0.095c-0.156,0.221-0.685,0.256-0.915,0.008c-0.054-0.058-0.062-0.145-0.029-0.216
          l0.031-0.068C579.008,253.792,579.509,253.84,579.762,253.745z"/>
        <path class="st260" d="M579.487,253.564c0,0-0.677-0.074-0.747-0.432l-0.2-0.648l1.265-0.475l0.178,0.916
          C580.053,253.289,579.832,253.497,579.487,253.564z"/>
        <path class="st264" d="M579.855,252.271c0,0-0.168,0.078-0.114,0.357c0.054,0.279-0.185,0.615-0.37,0.829
          c-0.134,0.156-0.447,0.001-0.447,0.001s-0.514-0.397-0.462-1.254c0.032-0.522,0.738-0.635,0.955-0.586
          C579.724,251.686,580.228,251.887,579.855,252.271z"/>
        <path class="st260" d="M579.783,252.634c0,0-0.219-0.145-0.205,0.072c0.011,0.161,0.148,0.313,0.263,0.23L579.783,252.634z"/>
        <path class="st308" d="M579.841,255.072l0.173,1.781l0.487,1.896c0,0,0.207,0.258,0.527-0.068l-0.23-2.037l-0.069-1.484
          c0,0-0.149-1.038-0.456-1.008C579.749,254.204,579.841,255.072,579.841,255.072z"/>
        <path class="st260" d="M580.58,258.786c0,0-0.017,0.266,0.057,0.489v0.298l-0.071,0.142c0,0-0.004,0.067,0.071,0.082
          c0.074,0.015,0.049,0,0.049,0l0.289-0.221l0.087-0.435l-0.085-0.473L580.58,258.786z"/>
        <path class="st212" d="M580.496,258.542c-0.016,0.001-0.031,0.001-0.047,0.001c0,0,0,0,0,0l0.052,0.206
          c0,0,0.207,0.258,0.527-0.068l-0.029-0.261C580.871,258.542,580.67,258.585,580.496,258.542z"/>
      </g>
      <g>
        <linearGradient id="SVGID_76_" gradientUnits="userSpaceOnUse" x1="425.4778" y1="346.4318" x2="435.6972" y2="346.4318">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st309" points="425.855,345.687 425.478,347.177 435.697,347.177 434.302,345.687 		"/>
        <path class="st282" d="M428.063,337.873c0,0,0.111,0.76,0.316,2.054c0.049,0.311-0.56,0.718-0.56,0.718l0.004-1.045
          L428.063,337.873z"/>
        <path class="st42" d="M427.542,347.118c0,0,0.448,0.017,0.678-0.064c0.23-0.081,0.457-0.046,0.555-0.068
          c0.193-0.044,0.241-0.18,0.241-0.18l-0.19-0.41l-0.783,0.069l-0.359,0.365C427.684,346.83,427.307,346.883,427.542,347.118z"/>
        <path class="st42" d="M424.742,347.038c0,0,0.818,0.239,0.875,0.243c0.057,0.004,0.293,0.032,0.341-0.141l-0.039-0.258
          l-0.733-0.225c0,0-0.346,0.012-0.389,0.009c-0.036-0.003-0.381,0.014-0.254,0.232
          C424.569,346.944,424.652,346.993,424.742,347.038z"/>
        <path class="st219" d="M426.227,341.158l1.003,3.28l0.722,2.126c0,0,0.673,0.123,0.866-0.173l-0.741-2.372l-0.109-2.025l0,0
          c0.071-0.396,0.038-0.805-0.095-1.185l-0.054-0.156L426.227,341.158z"/>
        <path class="st310" d="M426.358,341.586l0.685,2.242c0.005-0.109-0.159-1.273,0.574-1.647
          C427.095,342.181,426.705,341.813,426.358,341.586z"/>
        <path class="st219" d="M426.918,342.39c0,0-0.737,1.641-0.703,1.778c0.033,0.138-0.23,2.809-0.275,2.836
          c-0.045,0.027-0.487,0.168-0.768-0.333l0.007-2.68c-0.003-0.113,0.015-0.226,0.053-0.332l0.741-2.674l0.533,0.334l1.224,0.798
          L426.918,342.39z"/>
        <path class="st298" d="M427.962,341.032l-0.086-1.166c0,0,0.23-1.439,0.211-1.539c-0.019-0.1,0.148-0.713-0.536-0.939
          c0,0-0.406-0.147-0.9,0.04c-0.642,0.244-0.721,0.605-0.742,0.976c-0.03,0.52,0.201,1.12,0.201,1.12l-0.014,0.602l-0.057,1.079
          c0,0,0.383,0.22,1.111,0.06C427.876,341.105,427.962,341.032,427.962,341.032z"/>
        <g>
          <path class="st279" d="M425.317,341.726c0,0-0.181,0.413-0.171,0.445c0.01,0.032,0.087,0.308,0.097,0.339
            c0.01,0.032,0.123,0.109,0.201,0.12c0.078,0.011,0.252,0.013,0.252,0.013s0.012-0.036,0.002-0.068l0.054-0.023
            c0,0,0.12-0.028,0.015-0.104l0.026-0.034c0,0-0.011-0.07-0.075-0.08l0.005-0.249c0,0,0.038-0.267-0.047-0.287L425.317,341.726z"
            />
          <path class="st282" d="M426.059,337.937c-0.116,0.141-0.232,1.927-0.232,1.927s-0.552,1.75-0.561,1.809
            c-0.008,0.059,0.157,0.325,0.461,0.127l0.827-1.649c0,0,0.237-1.184,0.294-1.582C426.9,338.217,426.648,337.219,426.059,337.937z
            "/>
          <path class="st298" d="M425.349,341.377c-0.105,0.338-0.079,0.273-0.082,0.296c-0.008,0.059,0.157,0.325,0.461,0.127l0.119-0.232
            C425.559,341.654,425.406,341.559,425.349,341.377z"/>
        </g>
        <g>
          <path class="st279" d="M427.432,337.335l-0.05-0.645l-0.73-0.009l0.036,0.726C426.685,337.624,427.429,337.553,427.432,337.335z"
            />
          <path class="st282" d="M426.685,337.32l-0.073,0.103c0.138,0.202,0.608,0.256,0.82,0.033c0.049-0.052,0.058-0.13,0.029-0.196
            l-0.034-0.064C427.361,337.403,426.913,337.409,426.685,337.32z"/>
          <path class="st279" d="M426.93,337.256c0,0,0.592-0.176,0.596-0.506l0.074-0.61l-1.204-0.219l-0.011,0.846
            C426.381,337.103,426.612,337.252,426.93,337.256z"/>
          <path class="st283" d="M426.393,336.163c0,0,0.162,0.042,0.159,0.299c-0.003,0.257,0.265,0.518,0.464,0.679
            c0.145,0.117,0.399-0.071,0.399-0.071s0.395-0.437,0.21-1.193c-0.113-0.461-0.761-0.447-0.946-0.369
            C426.415,335.62,425.999,335.881,426.393,336.163z"/>
          <path class="st279" d="M426.516,336.475c0,0,0.172-0.165,0.194,0.031c0.016,0.145-0.081,0.303-0.198,0.248L426.516,336.475z"/>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_77_" gradientUnits="userSpaceOnUse" x1="980.3228" y1="659.4409" x2="1006.3367" y2="659.4409">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.794" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st311" points="1002.183,667.207 1006.337,651.675 980.323,667.207 		"/>
        <g>
          <polygon class="st195" points="998.534,653.462 1000.122,655.592 1000.656,655.928 1001.071,654.207 999.848,653.31 			"/>
          <g>
            <g id="XMLID_7_">
              <g>
                <path class="st195" d="M984.354,662.326c-1.467,0.712-2.956,2.969-2.336,3.864c0.453,0.654,1.287,0.835,1.287,0.835
                  c-0.121-0.17-0.766-0.781-0.764-1.017l0.002-0.037c0.013-1.461,0.821-2.798,2.108-3.49l0.226-0.121
                  c0.16-0.086,0.331-0.127,0.494-0.127c0.548,0,1.047,0.438,1.047,1.047c0,0.206-0.015,0.41-0.047,0.609l0.231,0.123
                  C986.715,662.889,986.376,661.346,984.354,662.326z"/>
                <path class="st195" d="M998.966,653.793c-0.431-0.124-0.854,0.11-1.905,0.99c-0.007,0.007-0.475,0.437-0.623,0.643
                  c-0.341,0.305-0.87,2.428-0.87,2.428s-0.008,0.162,0.664,0.537c0.343,0.192,0.831,0.456,0.831,0.456
                  c-0.122-0.17-0.627-0.731-0.626-0.967v-0.039c0.014-1.459,0.823-2.796,2.108-3.488l0.228-0.122
                  c0.16-0.086,0.329-0.127,0.494-0.127c0.309,0,0.602,0.14,0.799,0.371C1000.067,654.477,999.865,654.051,998.966,653.793z"/>
                <path class="st196" d="M1001.347,651.247c-0.842-0.935-1.808-1.744-2.877-2.405c-2.166-1.339-6.033-3.721-6.475-3.922
                  c-0.619-0.281-1.771-0.347-1.771-0.347l-0.926-0.017v0.017l0.53,0.459l7.823,4.179c0,0,1.316,0.29,0.923,1.753
                  c-0.59,1.054-3.621,2.933-3.621,2.933l-0.422,0.233l0.002,0.002c0,0,0.398,0.808,0.486,1.341c0,0-0.837,1.872-0.697,2.98
                  l-6.568,3.551l-0.329-2.147l-0.692-1.383c-0.018-0.005-0.037-0.012-0.055-0.019c0,0,0,0-0.002,0l-0.187,0.103
                  c-1.403,0.238-2.952,0.356-2.952,0.356s-0.477,0.838-2.024,1.82c-0.971,0.614-3.96,1.818-3.96,1.818s-1.069,0.955-3.473-0.518
                  c-1.3-0.798-1.761-1.591-1.918-2.122l-0.462,0.039c0.047,0.162,0.078,0.26,0.078,0.26c-0.132,0.594-0.941,0.045-1.42-0.587
                  h-0.019c-0.01,1.117,0.073,2.702,0.073,2.702s1.8,3.505,9.334,4.929c0,0,1.63-0.206,2.248-0.43c0,0,0.025-0.265,0.105-0.67
                  c0.207-1.07,0.789-3.117,2.257-3.829c2.022-0.98,2.361,0.563,2.248,1.687l8.037-4.487l1.059-0.393
                  c0,0-0.025-2.209,1.154-3.846c0.002,0,0.002-0.002,0.002-0.003c0.15-0.221,0.317-0.423,0.506-0.584
                  c0.091-0.089,0.189-0.174,0.29-0.255c1.724-1.363,2.459-0.727,2.773,0.093c0.002,0,0.002,0.002,0.002,0.002
                  c0.243,0.639,0.231,1.388,0.231,1.388l0.73-0.45c0,0,1.631-2.361,0.226-3.935
                  C1001.52,651.438,1001.43,651.34,1001.347,651.247z M976.482,665.53c0,0-3.601-1.518-4.919-2.979v-1.125
                  c0,0,1.265,1.462,4.919,3.036V665.53z M979.515,664.152c-0.786,0.091-2.53-0.02-2.193-0.526c0,0,0.612-0.258,1.459-0.747
                  c0.847-0.489,1.859-0.858,2.027-0.506C980.977,662.725,980.303,664.06,979.515,664.152z"/>
                <path class="st197" d="M1001.347,651.247c-0.842-0.935-1.808-1.744-2.877-2.405c-2.166-1.339-6.033-3.721-6.475-3.922
                  c-0.619-0.281-1.771-0.347-1.771-0.347l-0.926-0.017v0.017l0.53,0.459l7.823,4.179c0,0,1.316,0.29,0.923,1.753
                  c-0.59,1.054-3.621,2.933-3.621,2.933l-0.422,0.233l0.002,0.002c0,0,0.398,0.808,0.486,1.341c0,0-0.837,1.872-0.697,2.98
                  l-6.568,3.551l-0.329-2.147l-0.692-1.383c-0.018-0.005-0.037-0.012-0.055-0.019c0,0,0,0-0.002,0l-0.187,0.103
                  c-0.931,0.158-1.924,0.262-2.488,0.315c-0.014,0.066-2.542,3.576-2.994,4.334c-0.743,1.248-1.006,2.607-0.996,4.011
                  c0.488-0.068,1.519-0.226,1.982-0.394c0,0,0.025-0.265,0.105-0.67c0.207-1.07,0.789-3.117,2.257-3.829
                  c2.022-0.98,2.361,0.563,2.248,1.687l8.037-4.487l1.059-0.393c0,0-0.025-2.209,1.154-3.846c0.002,0,0.002-0.002,0.002-0.003
                  c0.15-0.221,0.317-0.423,0.506-0.584c0.091-0.089,0.189-0.174,0.29-0.255c1.724-1.363,2.459-0.727,2.773,0.093
                  c0.002,0,0.002,0.002,0.002,0.002c0.243,0.639,0.231,1.388,0.231,1.388l0.73-0.45c0,0,1.631-2.361,0.226-3.935
                  C1001.52,651.438,1001.43,651.34,1001.347,651.247z"/>
                <path class="st192" d="M999.268,654.106c-0.165,0-0.334,0.041-0.494,0.127l-0.228,0.122c-1.285,0.692-2.095,2.029-2.108,3.488
                  v0.039c-0.002,0.236,0.071,0.445,0.192,0.616c0.295,0.413,0.88,0.587,1.376,0.3l0.307-0.177c1.238-0.715,2-2.036,2-3.466
                  c0-0.265-0.094-0.498-0.246-0.676C999.87,654.246,999.576,654.106,999.268,654.106z M998.343,657.618l-0.16,0.093
                  c-0.366,0.211-0.823-0.055-0.82-0.479v-0.02c0.008-0.762,0.43-1.461,1.101-1.822l0.118-0.062
                  c0.084-0.046,0.172-0.066,0.258-0.066c0.287,0,0.546,0.229,0.546,0.546C999.387,656.555,998.989,657.245,998.343,657.618z"/>
                <path class="st198" d="M998.841,655.262c-0.086,0-0.174,0.02-0.258,0.066l-0.118,0.062c-0.671,0.361-1.093,1.059-1.101,1.822
                  v0.02c-0.003,0.424,0.454,0.69,0.82,0.479l0.16-0.093c0.646-0.373,1.044-1.063,1.044-1.81
                  C999.387,655.491,999.128,655.262,998.841,655.262z"/>
                <polygon class="st199" points="987.935,644.868 995.434,649.211 997.65,649.211 989.945,644.775 						"/>
                <path class="st199" d="M996.824,650.784l-2.211-0.764l-0.587,0.418l0.757,2.953c0.968-0.526,1.55-0.963,2.245-1.52
                  C997.419,651.558,997.303,650.934,996.824,650.784z"/>
                <path class="st193" d="M988.141,645.032l-0.955-0.459c-0.413,0.066-0.815,0.194-1.193,0.383
                  c-2.12,1.056-5.019,2.294-6.308,3.411c0,0-0.13,0.487,0.12,0.849l-0.066,0.049c0.474,0.543,2.867,3.078,6.973,4.047
                  l6.505-3.608c0.562-0.322,1.198-0.491,1.845-0.491h0.371l-7.292-4.179H988.141z"/>
                <g>
                  <path class="st197" d="M988.016,657.716c0.051,0.059-1.162,0.798-1.284,0.757l0.692,1.383l0.329,2.147l6.568-3.551
                    c-0.14-1.108,1.113-3.221,1.113-3.221c-0.088-0.533-0.679-1.226-0.679-1.226L988.016,657.716z"/>
                  <path class="st95" d="M994.787,653.979l-0.023-0.026l-6.768,3.727l-0.046,0.025l0.017,0.02
                    c-0.129,0.143-1.114,0.717-1.221,0.71l-0.097-0.032l0.734,1.468l0.337,2.2l6.621-3.579l0.025-0.014l-0.003-0.028
                    c-0.137-1.081,1.095-3.173,1.107-3.194l0.008-0.013l-0.003-0.015C995.387,654.687,994.811,654.007,994.787,653.979z
                    M994.276,658.43l-6.49,3.508l-0.32-2.088l-0.669-1.343c0.296-0.08,1.185-0.652,1.251-0.75
                    c0.003-0.005,0.006-0.01,0.008-0.014l6.689-3.683c0.106,0.13,0.562,0.709,0.645,1.165
                    C995.274,655.421,994.163,657.348,994.276,658.43z"/>
                </g>
                <path class="st199" d="M987.584,653.877l-0.872,1.898l-0.018,1.4c0.192-0.017,0.445,0.007,0.75,0.131
                  c0.086,0.035,0.164,0.076,0.233,0.12l6.644-3.684l-0.675-3.156L987.584,653.877z"/>
                <path class="st192" d="M989.721,644.556l-1.491-0.021c-0.351-0.032-0.702-0.02-1.044,0.037l0.955,0.459l-0.131-0.121
                  l2.217-0.016L989.721,644.556z"/>
                <path class="st200" d="M988.016,657.716c-0.088-0.101-0.199-0.203-0.339-0.29c-0.069-0.044-0.147-0.084-0.233-0.12
                  c-0.305-0.125-0.558-0.149-0.75-0.131c-0.28,0.023-0.434,0.131-0.434,0.131l-0.56-0.393c0,0-0.59,0.039-0.337,0.778
                  c0,0,0.705,0.545,1.312,0.764c0.002,0,0.002,0,0.002,0l0.035-0.02l0.02,0.039c0.122,0.04,0.236,0.066,0.339,0.071
                  c0.307,0.012,0.612-0.039,0.848-0.094c0.25-0.059,0.368-0.346,0.228-0.56C988.11,657.832,988.066,657.775,988.016,657.716z"/>
                <path class="st201" d="M985.397,657.57c-0.027-0.005-0.05-0.014-0.075-0.022c0.01,0.045,0.022,0.092,0.04,0.143
                  c0,0,0.705,0.545,1.312,0.764c0.002,0,0.002,0,0.002,0l0.035-0.02l0.02,0.039c0.122,0.04,0.236,0.066,0.339,0.071
                  c0.307,0.012,0.612-0.039,0.848-0.094c0.238-0.056,0.353-0.318,0.244-0.528C987.252,658.271,986.36,657.75,985.397,657.57z"/>
                <path class="st202" d="M986.677,658.455c0.019,0.007,0.037,0.014,0.055,0.019l-0.02-0.039L986.677,658.455z"/>
                <path class="st110" d="M979.739,649.264c-0.059-0.066-0.088-0.103-0.088-0.103l-3.621,4.149
                  c2.024,4.083,8.041,4.912,8.041,4.912l2.641-4.912C982.607,652.342,980.213,649.807,979.739,649.264z"/>
                <path class="st203" d="M978.079,650.963l-2.049,2.347c0.238,0.481,0.533,0.914,0.865,1.308
                  c0.573-0.139,1.156-0.23,1.741-0.344c1.061-0.208,2.118-0.451,3.175-0.676c1.134-0.242,2.266-0.487,3.399-0.733
                  c-2.143-0.769-3.687-1.915-4.596-2.729C979.764,650.395,978.918,650.67,978.079,650.963z"/>
                <path class="st192" d="M985.371,662.233c-0.164,0-0.334,0.041-0.494,0.127l-0.226,0.121c-1.287,0.692-2.095,2.029-2.108,3.49
                  l-0.002,0.037c-0.002,0.236,0.071,0.445,0.192,0.616c0.295,0.413,0.88,0.587,1.378,0.3l0.305-0.175
                  c1.059-0.612,1.773-1.67,1.955-2.859c0.032-0.199,0.047-0.403,0.047-0.609C986.418,662.671,985.919,662.233,985.371,662.233z
                  M984.447,665.745l-0.16,0.093c-0.366,0.211-0.823-0.056-0.82-0.479l0.002-0.019c0.006-0.764,0.428-1.462,1.1-1.824
                  l0.118-0.062c0.084-0.046,0.172-0.066,0.258-0.066c0.287,0,0.546,0.23,0.546,0.547
                  C985.491,664.682,985.093,665.372,984.447,665.745z"/>
                <path class="st198" d="M984.944,663.388c-0.086,0-0.174,0.02-0.258,0.066l-0.118,0.062c-0.671,0.361-1.093,1.059-1.1,1.824
                  l-0.002,0.019c-0.004,0.423,0.454,0.69,0.82,0.479l0.16-0.093c0.646-0.373,1.044-1.063,1.044-1.81
                  C985.491,663.618,985.231,663.388,984.944,663.388z"/>
                <path class="st193" d="M975.693,653.557l-0.029,0.012c0,0-0.376,0.375-0.519,0.477c-1.24,0.891-3.048,5.22-3.048,5.22
                  s2.137,2.105,5.455,3.286c0,0,2.989-1.204,3.96-1.818c1.547-0.982,2.024-1.82,2.024-1.82s-2.529-0.548-5.397-2.422
                  C976.6,655.486,975.939,654.186,975.693,653.557z"/>
                <path class="st56" d="M978.781,662.879c-0.847,0.489-1.459,0.747-1.459,0.747c-0.337,0.506,1.406,0.617,2.193,0.526
                  c0.788-0.093,1.462-1.427,1.294-1.78C980.64,662.02,979.628,662.39,978.781,662.879z"/>
                <path class="st204" d="M977.372,663.726c-0.031-0.004-0.058-0.013-0.085-0.021c-0.114,0.438,1.485,0.534,2.229,0.447
                  c0.608-0.072,1.147-0.881,1.284-1.41C979.807,663.478,978.649,663.911,977.372,663.726z"/>
                <path class="st192" d="M997.65,649.211h-2.216h-0.371c-0.648,0-1.284,0.169-1.845,0.491l-6.505,3.608l-2.641,4.912
                  c0,0-6.016-0.828-8.041-4.912l3.621-4.149c0,0,0.029,0.037,0.088,0.103l0.066-0.049c-0.25-0.361-0.12-0.849-0.12-0.849
                  c-0.663,0.575-1.807,1.93-2.738,3.075c-0.31,0.385-0.599,0.745-0.835,1.046c-0.246,0.31-0.437,0.555-0.545,0.695
                  c0.005,0.019,0.041,0.157,0.125,0.375c0.246,0.629,0.907,1.93,2.446,2.935c2.869,1.874,5.397,2.422,5.397,2.422
                  s1.548-0.118,2.952-0.356l0.187-0.103c-0.607-0.219-1.312-0.764-1.312-0.764c-0.253-0.739,0.337-0.778,0.337-0.778l0.56,0.393
                  c0,0,0.153-0.108,0.434-0.131l0.018-1.4l0.872-1.898l6.062-3.291l0.675,3.156l-6.644,3.684c0.14,0.088,0.251,0.189,0.339,0.29
                  l6.514-3.586l0.422-0.233c0,0,3.031-1.879,3.621-2.933C998.966,649.502,997.65,649.211,997.65,649.211z M997.028,651.871
                  c-0.695,0.557-1.277,0.994-2.245,1.52l-0.757-2.953l0.587-0.418l2.211,0.764C997.303,650.934,997.419,651.558,997.028,651.871
                  z"/>
                <path class="st195" d="M997.65,649.211h-2.587c-0.227,0-0.451,0.022-0.672,0.063c-0.46,0.221-0.912,0.458-1.351,0.742
                  c-0.525,0.339-3.574,1.942-5.199,2.891c-0.583,0.34-1.016,0.868-1.318,1.472c-0.91,1.815-1.837,3.407-2.523,4.495
                  c-0.01,0.016,1.489-0.146,2.488-0.315l0.187-0.103c-0.607-0.219-1.312-0.764-1.312-0.764
                  c-0.253-0.739,0.337-0.778,0.337-0.778l0.56,0.393c0,0,0.153-0.108,0.434-0.131l0.018-1.4l0.872-1.898l6.062-3.291
                  l0.675,3.156l-6.644,3.684c0.14,0.088,0.251,0.189,0.339,0.29l6.514-3.586l0.422-0.233c0,0,3.031-1.879,3.621-2.933
                  C998.966,649.502,997.65,649.211,997.65,649.211z M997.028,651.871c-0.695,0.557-1.277,0.994-2.245,1.52l-0.757-2.953
                  l0.587-0.418l2.211,0.764C997.303,650.934,997.419,651.558,997.028,651.871z"/>
                <path class="st198" d="M975.971,650.966c-0.256-0.024-0.455,0.214-0.391,0.464c0.061,0.233,0.16,0.528,0.314,0.794
                  c0.052,0.088,0.13,0.175,0.219,0.263c0.236-0.3,0.525-0.661,0.835-1.046c-0.039-0.042-0.084-0.084-0.133-0.125
                  C976.523,651.074,976.216,650.988,975.971,650.966z"/>
                <path class="st201" d="M975.575,651.275c-0.008,0.05-0.009,0.102,0.005,0.155c0.061,0.233,0.16,0.528,0.314,0.794
                  c0.052,0.088,0.13,0.175,0.219,0.263c0.147-0.187,0.316-0.4,0.497-0.625C976.187,651.838,975.778,651.643,975.575,651.275z"/>
                <path class="st192" d="M972.161,659.912c0.157,0.531,0.617,1.324,1.918,2.122c2.404,1.473,3.473,0.518,3.473,0.518
                  c-3.318-1.181-5.455-3.286-5.455-3.286S972.045,659.517,972.161,659.912z"/>
                <path class="st192" d="M971.564,661.427v1.125c1.317,1.461,4.919,2.979,4.919,2.979v-1.068
                  C972.829,662.889,971.564,661.427,971.564,661.427z"/>
                <path class="st196" d="M975.468,653.31c0,0-2.093,1.061-3.372,2.775c-0.558,0.751-0.919,1.233-1.162,1.579l0.137,0.027
                  c0.176,0.712,0.484,1.773,0.628,2.26l0.462-0.039c-0.116-0.395-0.064-0.646-0.064-0.646s1.808-4.33,3.048-5.22
                  c0.143-0.103,0.519-0.477,0.519-0.477l0.029-0.012c-0.084-0.218-0.12-0.356-0.125-0.375
                  C975.504,653.265,975.468,653.31,975.468,653.31z"/>
                <path class="st56" d="M971.071,657.691l-0.137-0.027c-0.312,0.445-0.43,0.666-0.525,0.951
                  c-0.049,0.15-0.069,0.538-0.073,1.009h0.019c0.479,0.633,1.289,1.181,1.42,0.587c0,0-0.031-0.098-0.078-0.26
                  C971.555,659.463,971.247,658.403,971.071,657.691z"/>
                <path class="st204" d="M971.657,659.808c-0.044,0.012-0.09,0.02-0.138,0.015c-0.505-0.049-0.828-0.288-1.171-0.625
                  c-0.005,0.13-0.008,0.273-0.009,0.426h0.019c0.479,0.633,1.289,1.181,1.42,0.587c0,0-0.031-0.098-0.078-0.26
                  C971.686,659.908,971.672,659.859,971.657,659.808z"/>
              </g>
            </g>
          </g>
          <path class="st192" d="M981.955,650.964c-1.413-0.945-2.121-1.853-2.303-2.126c-0.182-0.273,0,0.104,0,0.104v0.219
            c0,0,2.114,2.901,7.279,4.23l0.013-0.209c0,0-0.561-0.106-1.404-0.401C984.696,652.486,983.367,651.91,981.955,650.964z"/>
          <g>
            
              <rect x="991.636" y="642.332" transform="matrix(0.4932 -0.8699 0.8699 0.4932 -60.2303 1190.6461)" class="st192" width="0.169" height="9.364"/>
          </g>
          <g>
            
              <rect x="993.637" y="642.396" transform="matrix(0.4999 -0.8661 0.8661 0.4999 -63.3836 1184.2642)" class="st192" width="0.168" height="9.236"/>
          </g>
          <g>
            <path class="st192" d="M994.486,654.896l-0.781,0.435c-0.089,0.049-0.139,0.164-0.112,0.256c0.019,0.064,0.07,0.1,0.13,0.1
              c0.027,0,0.056-0.008,0.084-0.023l0.791-0.447c0.087-0.049,0.131-0.16,0.1-0.249
              C994.666,654.88,994.572,654.848,994.486,654.896z"/>
          </g>
        </g>
        <path class="st192" d="M999.156,651.711c-0.045,0-0.092,0.011-0.136,0.035l-0.062,0.033c-0.355,0.191-0.578,0.56-0.582,0.963
          v0.011c-0.002,0.224,0.24,0.365,0.433,0.253l0.085-0.049c0.341-0.197,0.552-0.562,0.552-0.957
          C999.445,651.832,999.308,651.711,999.156,651.711z"/>
      </g>
      <g>
        <linearGradient id="SVGID_78_" gradientUnits="userSpaceOnUse" x1="148.4375" y1="336.7123" x2="173.0062" y2="336.7123">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.794" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st312" points="169.083,344.047 173.006,329.378 148.438,344.047 		"/>
        <g>
          <polygon class="st195" points="165.637,331.066 167.137,333.077 167.641,333.394 168.033,331.77 166.878,330.922 			"/>
          <g>
            <g id="XMLID_4_">
              <g>
                <path class="st195" d="M152.245,339.437c-1.386,0.672-2.792,2.804-2.206,3.65c0.428,0.618,1.216,0.788,1.216,0.788
                  c-0.115-0.161-0.723-0.737-0.722-0.96l0.002-0.035c0.013-1.38,0.776-2.643,1.991-3.296l0.213-0.115
                  c0.151-0.081,0.312-0.119,0.467-0.119c0.518,0,0.989,0.414,0.989,0.989c0,0.194-0.014,0.387-0.045,0.575l0.218,0.116
                  C154.475,339.969,154.155,338.511,152.245,339.437z"/>
                <path class="st195" d="M166.045,331.378c-0.407-0.117-0.806,0.104-1.799,0.935c-0.006,0.007-0.449,0.413-0.589,0.607
                  c-0.322,0.288-0.822,2.293-0.822,2.293s-0.008,0.153,0.627,0.508c0.324,0.181,0.785,0.431,0.785,0.431
                  c-0.115-0.161-0.592-0.69-0.591-0.914v-0.036c0.013-1.378,0.777-2.641,1.991-3.294l0.215-0.115
                  c0.151-0.081,0.311-0.119,0.467-0.119c0.291,0,0.569,0.132,0.755,0.35C167.085,332.025,166.895,331.622,166.045,331.378z"/>
                <path class="st196" d="M168.294,328.974c-0.795-0.882-1.708-1.647-2.718-2.272c-2.045-1.265-5.698-3.514-6.115-3.704
                  c-0.585-0.266-1.673-0.328-1.673-0.328l-0.875-0.016v0.016l0.5,0.433l7.388,3.947c0,0,1.242,0.274,0.871,1.655
                  c-0.558,0.995-3.42,2.77-3.42,2.77l-0.398,0.22l0.002,0.002c0,0,0.376,0.763,0.459,1.266c0,0-0.79,1.768-0.658,2.815
                  l-6.203,3.353l-0.311-2.028l-0.653-1.306c-0.018-0.005-0.035-0.011-0.053-0.017c0,0,0,0-0.002,0l-0.177,0.097
                  c-1.325,0.225-2.788,0.336-2.788,0.336s-0.451,0.792-1.911,1.719c-0.918,0.58-3.74,1.717-3.74,1.717s-1.01,0.902-3.28-0.489
                  c-1.228-0.754-1.663-1.502-1.811-2.004l-0.437,0.037c0.045,0.153,0.074,0.245,0.074,0.245
                  c-0.124,0.561-0.889,0.043-1.341-0.555h-0.018c-0.01,1.055,0.069,2.552,0.069,2.552s1.7,3.31,8.816,4.655
                  c0,0,1.539-0.194,2.123-0.406c0,0,0.024-0.25,0.099-0.632c0.196-1.01,0.745-2.944,2.131-3.616
                  c1.91-0.926,2.23,0.532,2.124,1.593l7.591-4.237l1-0.371c0,0-0.024-2.087,1.09-3.632c0.002,0,0.002-0.001,0.002-0.003
                  c0.142-0.209,0.299-0.4,0.478-0.551c0.086-0.085,0.178-0.164,0.274-0.241c1.628-1.287,2.322-0.687,2.619,0.088
                  c0.002,0,0.002,0.002,0.002,0.002c0.229,0.604,0.218,1.311,0.218,1.311l0.689-0.425c0,0,1.541-2.23,0.214-3.716
                  C168.457,329.154,168.372,329.062,168.294,328.974z M144.81,342.464c0,0-3.401-1.434-4.645-2.813v-1.063
                  c0,0,1.195,1.381,4.645,2.867V342.464z M147.674,341.162c-0.742,0.086-2.389-0.019-2.071-0.497c0,0,0.578-0.243,1.378-0.706
                  c0.799-0.462,1.755-0.811,1.915-0.478C149.056,339.814,148.419,341.074,147.674,341.162z"/>
                <path class="st197" d="M168.294,328.974c-0.795-0.882-1.708-1.647-2.718-2.272c-2.045-1.265-5.698-3.514-6.115-3.704
                  c-0.585-0.266-1.673-0.328-1.673-0.328l-0.875-0.016v0.016l0.5,0.433l7.388,3.947c0,0,1.242,0.274,0.871,1.655
                  c-0.557,0.995-3.42,2.77-3.42,2.77l-0.398,0.22l0.002,0.002c0,0,0.376,0.763,0.459,1.266c0,0-0.79,1.768-0.658,2.815
                  l-6.203,3.353l-0.311-2.028l-0.653-1.306c-0.018-0.005-0.035-0.011-0.053-0.017c0,0,0,0-0.002,0l-0.177,0.097
                  c-0.88,0.149-1.817,0.248-2.35,0.298c-0.013,0.062-2.401,3.377-2.827,4.094c-0.702,1.179-0.95,2.462-0.94,3.788
                  c0.461-0.065,1.434-0.214,1.872-0.372c0,0,0.024-0.25,0.099-0.632c0.196-1.01,0.745-2.944,2.131-3.616
                  c1.91-0.926,2.23,0.532,2.124,1.593l7.591-4.237l1-0.371c0,0-0.024-2.087,1.09-3.632c0.002,0,0.002-0.001,0.002-0.003
                  c0.142-0.209,0.299-0.4,0.478-0.551c0.086-0.085,0.178-0.164,0.274-0.241c1.628-1.287,2.322-0.687,2.619,0.088
                  c0.002,0,0.002,0.002,0.002,0.002c0.229,0.604,0.218,1.311,0.218,1.311l0.689-0.425c0,0,1.541-2.23,0.214-3.716
                  C168.457,329.154,168.372,329.062,168.294,328.974z"/>
                <path class="st192" d="M166.33,331.674c-0.156,0-0.316,0.038-0.467,0.119l-0.215,0.115c-1.214,0.653-1.979,1.916-1.991,3.294
                  v0.036c-0.001,0.223,0.067,0.421,0.182,0.582c0.279,0.39,0.831,0.554,1.3,0.284l0.29-0.167
                  c1.169-0.676,1.889-1.923,1.889-3.274c0-0.25-0.089-0.47-0.232-0.639C166.899,331.806,166.622,331.674,166.33,331.674z
                  M165.457,334.991l-0.151,0.088c-0.346,0.199-0.777-0.053-0.774-0.452v-0.019c0.008-0.72,0.406-1.379,1.04-1.72l0.111-0.059
                  c0.08-0.043,0.163-0.062,0.244-0.062c0.271,0,0.516,0.217,0.516,0.516C166.443,333.987,166.067,334.639,165.457,334.991z"/>
                <path class="st198" d="M165.927,332.765c-0.081,0-0.164,0.019-0.244,0.062l-0.111,0.059c-0.634,0.341-1.032,1-1.04,1.72v0.019
                  c-0.003,0.4,0.428,0.652,0.774,0.452l0.151-0.088c0.61-0.352,0.986-1.004,0.986-1.709
                  C166.443,332.982,166.198,332.765,165.927,332.765z"/>
                <polygon class="st199" points="155.627,322.949 162.709,327.051 164.803,327.051 157.526,322.861 						"/>
                <path class="st199" d="M164.022,328.536l-2.089-0.722l-0.554,0.395l0.715,2.789c0.914-0.497,1.464-0.909,2.12-1.435
                  C164.584,329.267,164.474,328.678,164.022,328.536z"/>
                <path class="st193" d="M155.821,323.104l-0.902-0.433c-0.39,0.062-0.769,0.183-1.126,0.362
                  c-2.002,0.997-4.741,2.167-5.958,3.221c0,0-0.122,0.46,0.113,0.801l-0.062,0.046c0.448,0.513,2.708,2.907,6.586,3.822
                  l6.144-3.407c0.531-0.304,1.131-0.464,1.743-0.464h0.35l-6.887-3.947H155.821z"/>
                <g>
                  <path class="st197" d="M155.703,335.083c0.048,0.056-1.098,0.753-1.212,0.715l0.653,1.306l0.311,2.028l6.203-3.353
                    c-0.132-1.047,1.051-3.042,1.051-3.042c-0.083-0.504-0.641-1.158-0.641-1.158L155.703,335.083z"/>
                  <path class="st95" d="M162.098,331.554l-0.021-0.025l-6.393,3.52l-0.043,0.024l0.016,0.018
                    c-0.122,0.136-1.052,0.677-1.153,0.671l-0.091-0.03l0.693,1.387l0.318,2.077l6.253-3.38l0.024-0.013l-0.003-0.027
                    c-0.129-1.021,1.034-2.997,1.046-3.016l0.007-0.013l-0.002-0.014C162.665,332.223,162.121,331.581,162.098,331.554z
                    M161.615,335.757l-6.13,3.313l-0.302-1.972l-0.632-1.269c0.28-0.076,1.119-0.616,1.181-0.708
                    c0.003-0.005,0.006-0.009,0.008-0.014l6.318-3.478c0.1,0.122,0.531,0.67,0.609,1.1
                    C162.558,332.915,161.509,334.736,161.615,335.757z"/>
                </g>
                <path class="st199" d="M155.295,331.457l-0.823,1.792l-0.018,1.322c0.182-0.016,0.42,0.006,0.709,0.124
                  c0.081,0.034,0.154,0.072,0.22,0.113l6.275-3.479l-0.637-2.981L155.295,331.457z"/>
                <path class="st192" d="M157.314,322.654l-1.408-0.019c-0.332-0.03-0.663-0.019-0.986,0.035l0.902,0.433l-0.124-0.114
                  l2.094-0.015L157.314,322.654z"/>
                <path class="st200" d="M155.703,335.083c-0.083-0.096-0.188-0.191-0.32-0.274c-0.065-0.041-0.138-0.08-0.22-0.113
                  c-0.289-0.118-0.527-0.14-0.709-0.124c-0.265,0.022-0.409,0.124-0.409,0.124l-0.529-0.371c0,0-0.558,0.037-0.318,0.735
                  c0,0,0.666,0.514,1.239,0.722c0.002,0,0.002,0,0.002,0l0.034-0.019l0.019,0.037c0.115,0.038,0.223,0.062,0.32,0.067
                  c0.29,0.011,0.578-0.037,0.801-0.089c0.236-0.056,0.347-0.327,0.215-0.529C155.792,335.193,155.751,335.139,155.703,335.083z"
                  />
                <path class="st201" d="M153.23,334.945c-0.026-0.005-0.048-0.013-0.071-0.021c0.009,0.042,0.021,0.087,0.038,0.135
                  c0,0,0.666,0.514,1.239,0.722c0.002,0,0.002,0,0.002,0l0.034-0.019l0.019,0.037c0.115,0.038,0.223,0.062,0.32,0.067
                  c0.29,0.011,0.578-0.037,0.801-0.089c0.224-0.053,0.333-0.3,0.23-0.498C154.982,335.607,154.139,335.115,153.23,334.945z"/>
                <path class="st202" d="M154.438,335.781c0.017,0.006,0.035,0.013,0.053,0.017l-0.019-0.037L154.438,335.781z"/>
                <path class="st110" d="M147.886,327.101c-0.056-0.062-0.083-0.097-0.083-0.097l-3.42,3.919
                  c1.912,3.857,7.594,4.639,7.594,4.639l2.495-4.639C150.595,330.008,148.334,327.614,147.886,327.101z"/>
                <path class="st203" d="M146.318,328.706l-1.935,2.217c0.225,0.454,0.503,0.863,0.817,1.236
                  c0.541-0.132,1.092-0.217,1.644-0.325c1.002-0.196,2-0.426,2.999-0.638c1.07-0.228,2.14-0.46,3.21-0.693
                  c-2.024-0.726-3.482-1.809-4.341-2.577C147.91,328.169,147.111,328.429,146.318,328.706z"/>
                <path class="st192" d="M153.205,339.349c-0.154,0-0.315,0.038-0.467,0.119l-0.213,0.115c-1.216,0.653-1.979,1.916-1.991,3.296
                  l-0.002,0.035c-0.002,0.223,0.067,0.421,0.181,0.581c0.279,0.39,0.832,0.554,1.301,0.284l0.289-0.166
                  c1-0.578,1.674-1.577,1.846-2.7c0.03-0.188,0.045-0.381,0.045-0.575C154.195,339.763,153.723,339.349,153.205,339.349z
                  M152.332,342.666l-0.151,0.088c-0.346,0.199-0.777-0.053-0.774-0.452l0.002-0.018c0.006-0.721,0.404-1.381,1.039-1.722
                  l0.111-0.059c0.08-0.043,0.162-0.062,0.244-0.062c0.271,0,0.516,0.217,0.516,0.516
                  C153.319,341.662,152.943,342.314,152.332,342.666z"/>
                <path class="st198" d="M152.802,340.44c-0.081,0-0.164,0.019-0.244,0.062l-0.111,0.059c-0.634,0.341-1.032,1.001-1.039,1.722
                  l-0.002,0.018c-0.003,0.4,0.428,0.651,0.774,0.452l0.151-0.088c0.61-0.352,0.986-1.004,0.986-1.709
                  C153.319,340.657,153.073,340.44,152.802,340.44z"/>
                <path class="st193" d="M144.065,331.155l-0.027,0.011c0,0-0.355,0.354-0.491,0.451c-1.171,0.841-2.879,4.93-2.879,4.93
                  s2.019,1.988,5.152,3.103c0,0,2.823-1.137,3.74-1.717c1.461-0.927,1.911-1.719,1.911-1.719s-2.388-0.518-5.097-2.288
                  C144.922,332.977,144.297,331.749,144.065,331.155z"/>
                <path class="st56" d="M146.982,339.959c-0.8,0.462-1.378,0.706-1.378,0.706c-0.319,0.478,1.328,0.583,2.071,0.497
                  c0.744-0.088,1.381-1.348,1.222-1.681C148.737,339.148,147.781,339.497,146.982,339.959z"/>
                <path class="st204" d="M145.65,340.759c-0.029-0.004-0.055-0.012-0.081-0.02c-0.107,0.414,1.403,0.504,2.105,0.422
                  c0.574-0.068,1.083-0.832,1.213-1.331C147.951,340.525,146.857,340.934,145.65,340.759z"/>
                <path class="st192" d="M164.803,327.051h-2.093h-0.35c-0.612,0-1.212,0.159-1.743,0.464l-6.144,3.407l-2.495,4.639
                  c0,0-5.682-0.782-7.594-4.639l3.42-3.919c0,0,0.027,0.035,0.083,0.097l0.062-0.046c-0.236-0.341-0.113-0.801-0.113-0.801
                  c-0.626,0.543-1.706,1.822-2.585,2.904c-0.293,0.363-0.565,0.704-0.789,0.987c-0.232,0.293-0.413,0.524-0.514,0.656
                  c0.005,0.018,0.038,0.148,0.118,0.354c0.233,0.594,0.857,1.822,2.31,2.772c2.71,1.77,5.097,2.288,5.097,2.288
                  s1.462-0.111,2.788-0.336l0.177-0.097c-0.573-0.207-1.239-0.722-1.239-0.722c-0.239-0.698,0.318-0.735,0.318-0.735
                  l0.529,0.371c0,0,0.145-0.102,0.409-0.124l0.018-1.322l0.823-1.792l5.725-3.108l0.637,2.981l-6.275,3.479
                  c0.132,0.083,0.238,0.178,0.32,0.274l6.152-3.387l0.398-0.22c0,0,2.863-1.775,3.42-2.77
                  C166.045,327.325,164.803,327.051,164.803,327.051z M164.215,329.563c-0.656,0.526-1.206,0.938-2.12,1.435l-0.715-2.789
                  l0.554-0.395l2.089,0.722C164.474,328.678,164.584,329.267,164.215,329.563z"/>
                <path class="st195" d="M164.803,327.051h-2.444c-0.214,0-0.426,0.021-0.635,0.059c-0.435,0.209-0.861,0.432-1.276,0.7
                  c-0.495,0.32-3.376,1.834-4.91,2.73c-0.551,0.322-0.959,0.82-1.245,1.39c-0.86,1.714-1.735,3.217-2.383,4.245
                  c-0.009,0.015,1.407-0.138,2.35-0.298l0.177-0.097c-0.573-0.207-1.239-0.722-1.239-0.722
                  c-0.239-0.698,0.318-0.735,0.318-0.735l0.529,0.371c0,0,0.145-0.102,0.409-0.124l0.018-1.322l0.823-1.792l5.725-3.108
                  l0.637,2.981l-6.275,3.479c0.132,0.083,0.238,0.178,0.32,0.274l6.152-3.387l0.398-0.22c0,0,2.863-1.775,3.42-2.77
                  C166.045,327.325,164.803,327.051,164.803,327.051z M164.215,329.563c-0.656,0.526-1.206,0.938-2.12,1.435l-0.715-2.789
                  l0.554-0.395l2.089,0.722C164.474,328.678,164.584,329.267,164.215,329.563z"/>
                <path class="st198" d="M144.328,328.708c-0.242-0.022-0.43,0.202-0.369,0.438c0.057,0.22,0.151,0.499,0.296,0.751
                  c0.049,0.083,0.123,0.166,0.207,0.248c0.223-0.283,0.496-0.624,0.789-0.987c-0.037-0.04-0.079-0.08-0.126-0.118
                  C144.849,328.81,144.559,328.729,144.328,328.708z"/>
                <path class="st201" d="M143.954,329c-0.008,0.047-0.008,0.096,0.005,0.146c0.057,0.22,0.151,0.499,0.296,0.751
                  c0.049,0.083,0.123,0.165,0.207,0.248c0.139-0.177,0.299-0.378,0.469-0.59C144.532,329.532,144.145,329.347,143.954,329z"/>
                <path class="st192" d="M140.729,337.157c0.148,0.502,0.583,1.251,1.811,2.004c2.27,1.391,3.28,0.489,3.28,0.489
                  c-3.133-1.115-5.152-3.103-5.152-3.103S140.619,336.785,140.729,337.157z"/>
                <path class="st192" d="M140.165,338.588v1.063c1.244,1.38,4.645,2.813,4.645,2.813v-1.008
                  C141.36,339.969,140.165,338.588,140.165,338.588z"/>
                <path class="st196" d="M143.853,330.922c0,0-1.977,1.002-3.185,2.62c-0.527,0.709-0.868,1.165-1.097,1.491l0.129,0.025
                  c0.166,0.672,0.457,1.674,0.592,2.135l0.437-0.037c-0.11-0.373-0.061-0.61-0.061-0.61s1.708-4.089,2.879-4.93
                  c0.136-0.097,0.491-0.451,0.491-0.451l0.027-0.011c-0.08-0.206-0.113-0.336-0.118-0.354
                  C143.886,330.879,143.853,330.922,143.853,330.922z"/>
                <path class="st56" d="M139.7,335.059l-0.129-0.025c-0.295,0.42-0.406,0.629-0.495,0.898c-0.046,0.142-0.065,0.508-0.069,0.952
                  h0.018c0.452,0.598,1.217,1.115,1.341,0.555c0,0-0.029-0.092-0.074-0.245C140.157,336.733,139.866,335.732,139.7,335.059z"/>
                <path class="st204" d="M140.253,337.059c-0.042,0.011-0.085,0.018-0.131,0.014c-0.477-0.046-0.782-0.272-1.106-0.591
                  c-0.005,0.123-0.008,0.258-0.009,0.403h0.018c0.452,0.597,1.217,1.115,1.341,0.554c0,0-0.029-0.092-0.074-0.245
                  C140.281,337.153,140.267,337.107,140.253,337.059z"/>
              </g>
            </g>
          </g>
          <path class="st192" d="M149.979,328.706c-1.334-0.893-2.003-1.75-2.175-2.008c-0.172-0.258,0,0.098,0,0.098v0.207
            c0,0,1.996,2.74,6.875,3.995l0.012-0.197c0,0-0.53-0.1-1.326-0.379C152.568,330.143,151.313,329.599,149.979,328.706z"/>
          <g>
            
              <rect x="159.122" y="320.554" transform="matrix(0.4932 -0.8699 0.8699 0.4932 -202.0182 303.1905)" class="st192" width="0.159" height="8.844"/>
          </g>
          <g>
            <polygon class="st192" points="157.274,322.864 164.829,327.224 164.909,327.087 157.354,322.727 				"/>
          </g>
          <g>
            <path class="st192" d="M161.814,332.419l-0.737,0.411c-0.084,0.047-0.132,0.155-0.106,0.242c0.018,0.06,0.066,0.095,0.122,0.095
              c0.025,0,0.052-0.007,0.079-0.022l0.747-0.422c0.082-0.046,0.124-0.151,0.094-0.235
              C161.985,332.405,161.895,332.374,161.814,332.419z"/>
          </g>
        </g>
        <path class="st192" d="M166.225,329.411c-0.043,0-0.087,0.01-0.129,0.033l-0.059,0.031c-0.335,0.18-0.545,0.529-0.55,0.909v0.01
          c-0.001,0.211,0.227,0.344,0.409,0.239l0.08-0.046c0.323-0.186,0.521-0.531,0.521-0.904
          C166.498,329.526,166.368,329.411,166.225,329.411z"/>
      </g>
      <g>
        
          <linearGradient id="SVGID_79_" gradientUnits="userSpaceOnUse" x1="584.6165" y1="478.7776" x2="614.8348" y2="478.7776" gradientTransform="matrix(1 0 0 1 -267.9402 0)">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.794" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st313" points="346.583,487.763 346.895,469.793 316.676,469.793 324.454,487.763 		"/>
        <g>
          <polygon class="st195" points="307.589,474.096 305.981,476.252 305.441,476.592 305.021,474.85 306.259,473.942 			"/>
          <g>
            <g id="XMLID_3_">
              <g>
                <path class="st195" d="M321.943,483.068c1.485,0.721,2.993,3.005,2.365,3.912c-0.459,0.663-1.303,0.845-1.303,0.845
                  c0.123-0.172,0.776-0.79,0.774-1.029l-0.002-0.037c-0.014-1.479-0.831-2.833-2.134-3.533l-0.229-0.123
                  c-0.162-0.087-0.335-0.128-0.5-0.128c-0.555,0-1.06,0.444-1.06,1.06c0,0.208,0.016,0.415,0.048,0.616l-0.234,0.125
                  C319.553,483.638,319.896,482.076,321.943,483.068z"/>
                <path class="st195" d="M307.152,474.431c0.437-0.125,0.864,0.111,1.928,1.002c0.007,0.007,0.481,0.443,0.631,0.651
                  c0.345,0.308,0.88,2.458,0.88,2.458s0.008,0.164-0.672,0.544c-0.347,0.194-0.842,0.462-0.842,0.462
                  c0.123-0.172,0.635-0.74,0.633-0.979v-0.039c-0.014-1.477-0.833-2.831-2.134-3.531l-0.231-0.123
                  c-0.162-0.087-0.333-0.128-0.5-0.128c-0.312,0-0.61,0.142-0.809,0.376C306.036,475.123,306.241,474.692,307.152,474.431z"/>
                <path class="st196" d="M304.472,472.153c-1.422,1.593,0.229,3.983,0.229,3.983l0.739,0.456c0,0-0.012-0.758,0.234-1.405
                  c0,0,0-0.002,0.002-0.002c0.318-0.83,1.062-1.474,2.807-0.094c0.102,0.082,0.201,0.167,0.293,0.258
                  c0.191,0.162,0.36,0.367,0.513,0.591c0,0.002,0,0.003,0.002,0.003c1.193,1.656,1.168,3.893,1.168,3.893l1.072,0.398
                  l8.136,4.542c-0.114-1.137,0.229-2.699,2.276-1.707c1.485,0.721,2.075,2.793,2.284,3.876c0.08,0.41,0.106,0.678,0.106,0.678
                  c0.627,0.227,2.276,0.435,2.276,0.435c7.627-1.441,9.449-4.989,9.449-4.989s0.084-1.605,0.073-2.735h-0.019
                  c-0.485,0.64-1.304,1.195-1.437,0.594c0,0,0.031-0.099,0.079-0.263l-0.468-0.039c-0.159,0.538-0.625,1.34-1.941,2.148
                  c-2.433,1.491-3.516,0.524-3.516,0.524s-3.026-1.219-4.009-1.841c-1.566-0.994-2.049-1.842-2.049-1.842
                  s-1.567-0.119-2.988-0.36l-0.189-0.104c-0.002,0-0.002,0-0.002,0c-0.019,0.007-0.037,0.013-0.056,0.019l-0.7,1.4l-0.333,2.173
                  l-6.649-3.594c0.142-1.122-0.705-3.017-0.705-3.017c0.089-0.54,0.492-1.357,0.492-1.357l0.002-0.002l-0.427-0.236
                  c0,0-3.068-1.902-3.666-2.969c-0.398-1.48,0.934-1.774,0.934-1.774l7.919-4.231l0.536-0.465v-0.017l-0.938,0.017
                  c0,0-1.166,0.066-1.793,0.352c-0.447,0.203-4.363,2.614-6.555,3.97c-1.083,0.669-2.061,1.489-2.913,2.435
                  C304.657,471.948,304.566,472.047,304.472,472.153z M329.912,485.232c3.698-1.593,4.979-3.074,4.979-3.074v1.139
                  c-1.334,1.478-4.979,3.015-4.979,3.015V485.232z M325.532,483.116c0.171-0.357,1.195,0.017,2.052,0.512
                  c0.857,0.495,1.477,0.756,1.477,0.756c0.341,0.512-1.424,0.625-2.219,0.533C326.044,484.823,325.361,483.473,325.532,483.116z
                  "/>
                <path class="st197" d="M304.74,471.854c0.852-0.946,1.831-1.765,2.913-2.435c2.192-1.355,6.107-3.767,6.555-3.97
                  c0.626-0.285,1.793-0.352,1.793-0.352l0.938-0.017v0.017l-0.536,0.465l-7.919,4.231c0,0-1.332,0.294-0.934,1.774
                  c0.598,1.067,3.666,2.969,3.666,2.969l0.427,0.236l-0.002,0.002c0,0-0.403,0.818-0.492,1.357c0,0,0.847,1.895,0.705,3.017
                  l6.649,3.594l0.333-2.173l0.7-1.4c0.019-0.005,0.038-0.012,0.056-0.019c0,0,0,0,0.002,0l0.189,0.104
                  c0.943,0.16,1.948,0.266,2.519,0.319c0.014,0.066,2.573,3.62,3.03,4.388c0.752,1.263,1.019,2.639,1.008,4.06
                  c-0.494-0.069-1.537-0.229-2.007-0.399c0,0-0.026-0.268-0.106-0.678c-0.21-1.082-0.799-3.155-2.284-3.876
                  c-2.047-0.992-2.39,0.57-2.276,1.707l-8.136-4.542l-1.072-0.398c0,0,0.026-2.237-1.168-3.893
                  c-0.002,0-0.002-0.002-0.002-0.003c-0.152-0.224-0.321-0.428-0.513-0.591c-0.092-0.09-0.191-0.176-0.293-0.258
                  c-1.745-1.38-2.49-0.736-2.807,0.094c-0.002,0-0.002,0.002-0.002,0.002c-0.246,0.647-0.234,1.405-0.234,1.405l-0.739-0.456
                  c0,0-1.651-2.39-0.229-3.983C304.566,472.047,304.657,471.948,304.74,471.854z"/>
                <path class="st192" d="M306.036,475.123c-0.154,0.181-0.249,0.417-0.249,0.685c0,1.448,0.772,2.785,2.025,3.509l0.311,0.179
                  c0.502,0.29,1.095,0.114,1.393-0.304c0.123-0.173,0.196-0.384,0.195-0.623v-0.039c-0.014-1.477-0.833-2.831-2.134-3.531
                  l-0.231-0.123c-0.162-0.087-0.333-0.128-0.5-0.128C306.533,474.748,306.236,474.89,306.036,475.123z M306.725,476.471
                  c0-0.321,0.263-0.553,0.553-0.553c0.087,0,0.176,0.021,0.261,0.067l0.12,0.063c0.68,0.365,1.106,1.072,1.115,1.844v0.021
                  c0.003,0.429-0.459,0.698-0.83,0.485l-0.162-0.094C307.128,477.925,306.725,477.227,306.725,476.471z"/>
                <path class="st198" d="M307.278,475.917c0.087,0,0.176,0.021,0.261,0.067l0.12,0.063c0.68,0.365,1.106,1.072,1.115,1.844
                  v0.021c0.003,0.429-0.459,0.698-0.83,0.485l-0.162-0.094c-0.654-0.377-1.057-1.076-1.057-1.832
                  C306.725,476.15,306.987,475.917,307.278,475.917z"/>
                <polygon class="st314" points="318.318,465.396 310.727,469.793 308.483,469.793 316.283,465.302 						"/>
                <path class="st314" d="M309.32,471.384l2.239-0.774l0.594,0.424l-0.767,2.99c-0.98-0.533-1.569-0.975-2.273-1.538
                  C308.717,472.168,308.835,471.536,309.32,471.384z"/>
                <path class="st193" d="M318.11,465.562l0.966-0.465c0.418,0.066,0.825,0.196,1.207,0.388c2.146,1.069,5.081,2.322,6.386,3.452
                  c0,0,0.132,0.493-0.121,0.859l0.067,0.049c-0.48,0.55-2.903,3.116-7.059,4.096l-6.585-3.652
                  c-0.569-0.326-1.212-0.497-1.868-0.497h-0.376l7.381-4.231H318.11z"/>
                <g>
                  <path class="st197" d="M318.236,478.402c-0.051,0.06,1.176,0.808,1.299,0.767l-0.7,1.4l-0.333,2.173l-6.649-3.594
                    c0.142-1.122-1.127-3.26-1.127-3.26c0.089-0.54,0.688-1.242,0.688-1.242L318.236,478.402z"/>
                  <path class="st95" d="M310.685,475.881l-0.003,0.015l0.008,0.014c0.013,0.021,1.259,2.138,1.121,3.233l-0.004,0.029
                    l0.026,0.014l6.702,3.623l0.341-2.227l0.743-1.487l-0.098,0.033c-0.108,0.007-1.106-0.574-1.236-0.719l0.017-0.02
                    l-0.046-0.025l-6.852-3.772l-0.023,0.027C311.357,474.648,310.775,475.336,310.685,475.881z M310.772,475.88
                    c0.084-0.461,0.546-1.048,0.653-1.179l6.772,3.728c0.002,0.005,0.005,0.009,0.008,0.014c0.067,0.099,0.966,0.678,1.266,0.759
                    l-0.677,1.36l-0.324,2.113l-6.57-3.551C312.013,478.029,310.889,476.078,310.772,475.88z"/>
                </g>
                <path class="st314" d="M318.673,474.516l0.883,1.921l0.019,1.417c-0.195-0.017-0.451,0.007-0.76,0.133
                  c-0.087,0.036-0.166,0.077-0.236,0.121l-6.726-3.729l0.683-3.195L318.673,474.516z"/>
                <path class="st192" d="M316.51,465.08l1.509-0.02c0.355-0.032,0.71-0.021,1.057,0.038l-0.966,0.465l0.133-0.122l-2.244-0.016
                  L316.51,465.08z"/>
                <path class="st200" d="M318.236,478.402c0.089-0.103,0.201-0.205,0.343-0.294c0.07-0.044,0.148-0.085,0.236-0.121
                  c0.309-0.126,0.565-0.15,0.76-0.133c0.283,0.024,0.439,0.133,0.439,0.133l0.567-0.398c0,0,0.598,0.039,0.341,0.787
                  c0,0-0.714,0.551-1.328,0.773c-0.002,0-0.002,0-0.002,0l-0.036-0.021l-0.02,0.039c-0.123,0.041-0.239,0.067-0.343,0.072
                  c-0.311,0.012-0.62-0.039-0.859-0.096c-0.253-0.06-0.372-0.35-0.231-0.567C318.141,478.52,318.185,478.462,318.236,478.402z"
                  />
                <path class="st201" d="M320.887,478.254c0.028-0.005,0.051-0.014,0.076-0.022c-0.01,0.045-0.023,0.093-0.041,0.145
                  c0,0-0.714,0.551-1.328,0.773c-0.002,0-0.002,0-0.002,0l-0.036-0.021l-0.02,0.039c-0.123,0.041-0.239,0.067-0.343,0.072
                  c-0.311,0.012-0.62-0.039-0.859-0.096c-0.24-0.057-0.357-0.322-0.246-0.534C319.009,478.963,319.913,478.436,320.887,478.254z
                  "/>
                <path class="st202" d="M319.592,479.15c-0.019,0.007-0.037,0.013-0.056,0.019l0.02-0.039L319.592,479.15z"/>
                <path class="st110" d="M326.615,469.846c0.06-0.066,0.089-0.104,0.089-0.104l3.666,4.2c-2.049,4.134-8.139,4.972-8.139,4.972
                  l-2.674-4.972C323.712,472.962,326.135,470.396,326.615,469.846z"/>
                <path class="st110" d="M328.296,471.566l2.074,2.376c-0.241,0.486-0.54,0.925-0.876,1.325
                  c-0.58-0.141-1.171-0.232-1.762-0.348c-1.074-0.211-2.143-0.456-3.214-0.684c-1.147-0.245-2.294-0.493-3.44-0.742
                  c2.169-0.778,3.732-1.939,4.653-2.763C326.59,470.991,327.446,471.269,328.296,471.566z"/>
                <path class="st192" d="M319.853,484.035c0,0.208,0.016,0.415,0.048,0.616c0.185,1.204,0.907,2.274,1.979,2.894l0.309,0.178
                  c0.504,0.29,1.096,0.114,1.395-0.304c0.123-0.172,0.196-0.384,0.195-0.623l-0.002-0.037c-0.014-1.479-0.831-2.833-2.134-3.533
                  l-0.229-0.123c-0.162-0.087-0.335-0.128-0.5-0.128C320.358,482.974,319.853,483.418,319.853,484.035z M320.792,484.697
                  c0-0.321,0.263-0.553,0.553-0.553c0.087,0,0.176,0.02,0.261,0.067l0.119,0.063c0.68,0.365,1.106,1.072,1.113,1.846
                  l0.002,0.019c0.003,0.428-0.459,0.698-0.83,0.485l-0.162-0.094C321.195,486.152,320.792,485.453,320.792,484.697z"/>
                <path class="st198" d="M321.345,484.144c0.087,0,0.176,0.02,0.261,0.067l0.119,0.063c0.68,0.365,1.106,1.072,1.113,1.846
                  l0.002,0.019c0.003,0.428-0.459,0.698-0.83,0.485l-0.162-0.094c-0.654-0.377-1.057-1.076-1.057-1.832
                  C320.792,484.376,321.055,484.144,321.345,484.144z"/>
                <path class="st193" d="M330.711,474.191l0.029,0.012c0,0,0.381,0.379,0.526,0.483c1.255,0.901,3.085,5.284,3.085,5.284
                  s-2.163,2.131-5.522,3.326c0,0-3.026-1.219-4.009-1.841c-1.566-0.994-2.049-1.842-2.049-1.842s2.559-0.555,5.464-2.452
                  C329.792,476.145,330.461,474.828,330.711,474.191z"/>
                <path class="st56" d="M327.584,483.628c0.857,0.495,1.477,0.756,1.477,0.756c0.341,0.512-1.424,0.625-2.219,0.533
                  c-0.798-0.094-1.48-1.445-1.31-1.801C325.703,482.759,326.727,483.133,327.584,483.628z"/>
                <path class="st204" d="M329.011,484.486c0.031-0.004,0.059-0.013,0.086-0.021c0.115,0.444-1.504,0.54-2.256,0.453
                  c-0.616-0.073-1.161-0.892-1.3-1.427C326.545,484.235,327.718,484.673,329.011,484.486z"/>
                <path class="st192" d="M307.549,471.567c0.598,1.067,3.666,2.969,3.666,2.969l0.427,0.236l6.594,3.63
                  c0.089-0.103,0.201-0.205,0.343-0.294l-6.726-3.729l0.683-3.195l6.137,3.331l0.883,1.921l0.019,1.417
                  c0.283,0.024,0.439,0.133,0.439,0.133l0.567-0.398c0,0,0.598,0.039,0.341,0.787c0,0-0.714,0.551-1.328,0.773l0.189,0.104
                  c1.421,0.241,2.988,0.36,2.988,0.36s2.559-0.555,5.464-2.452c1.557-1.018,2.227-2.334,2.476-2.971
                  c0.085-0.22,0.121-0.36,0.126-0.379c-0.109-0.142-0.302-0.389-0.552-0.704c-0.239-0.304-0.531-0.669-0.845-1.059
                  c-0.943-1.159-2.1-2.53-2.771-3.113c0,0,0.132,0.493-0.121,0.859l0.067,0.049c0.06-0.066,0.089-0.104,0.089-0.104l3.666,4.2
                  c-2.049,4.134-8.139,4.972-8.139,4.972l-2.674-4.972l-6.585-3.652c-0.569-0.326-1.212-0.497-1.868-0.497h-0.376h-2.243
                  C308.483,469.793,307.151,470.087,307.549,471.567z M309.32,471.384l2.239-0.774l0.594,0.424l-0.767,2.99
                  c-0.98-0.533-1.569-0.975-2.273-1.538C308.717,472.168,308.835,471.536,309.32,471.384z"/>
                <path class="st195" d="M307.549,471.567c0.598,1.067,3.666,2.969,3.666,2.969l0.427,0.236l6.594,3.63
                  c0.089-0.103,0.201-0.205,0.343-0.294l-6.726-3.729l0.683-3.195l6.137,3.331l0.883,1.921l0.019,1.417
                  c0.283,0.024,0.439,0.133,0.439,0.133l0.567-0.398c0,0,0.598,0.039,0.341,0.787c0,0-0.714,0.551-1.328,0.773l0.189,0.104
                  c1.011,0.171,2.529,0.336,2.519,0.319c-0.694-1.102-1.633-2.714-2.554-4.55c-0.307-0.611-0.744-1.145-1.335-1.49
                  c-1.644-0.96-4.732-2.583-5.263-2.926c-0.445-0.288-0.902-0.527-1.368-0.751c-0.224-0.041-0.451-0.063-0.681-0.063h-2.619
                  C308.483,469.793,307.151,470.087,307.549,471.567z M309.32,471.384l2.239-0.774l0.594,0.424l-0.767,2.99
                  c-0.98-0.533-1.569-0.975-2.273-1.538C308.717,472.168,308.835,471.536,309.32,471.384z"/>
                <path class="st198" d="M330.429,471.569c0.26-0.024,0.461,0.217,0.396,0.47c-0.062,0.236-0.162,0.534-0.318,0.804
                  c-0.053,0.089-0.131,0.178-0.222,0.266c-0.239-0.304-0.531-0.669-0.845-1.059c0.039-0.043,0.085-0.085,0.135-0.126
                  C329.87,471.678,330.181,471.591,330.429,471.569z"/>
                <path class="st201" d="M330.83,471.882c0.008,0.05,0.009,0.103-0.005,0.156c-0.062,0.236-0.162,0.534-0.318,0.804
                  c-0.053,0.089-0.131,0.178-0.222,0.266c-0.149-0.189-0.32-0.405-0.503-0.633C330.21,472.452,330.625,472.254,330.83,471.882z"
                  />
                <path class="st192" d="M334.286,480.625c-0.159,0.538-0.625,1.34-1.941,2.148c-2.433,1.491-3.516,0.524-3.516,0.524
                  c3.358-1.195,5.522-3.326,5.522-3.326S334.404,480.225,334.286,480.625z"/>
                <path class="st192" d="M334.89,482.158v1.139c-1.334,1.478-4.979,3.015-4.979,3.015v-1.081
                  C333.61,483.638,334.89,482.158,334.89,482.158z"/>
                <path class="st196" d="M330.938,473.942c0,0,2.119,1.074,3.413,2.809c0.565,0.76,0.93,1.248,1.176,1.598l-0.138,0.027
                  c-0.178,0.72-0.49,1.794-0.635,2.288l-0.468-0.039c0.118-0.399,0.065-0.654,0.065-0.654s-1.83-4.383-3.085-5.284
                  c-0.145-0.104-0.526-0.483-0.526-0.483l-0.029-0.012c0.085-0.22,0.121-0.36,0.126-0.379
                  C330.902,473.896,330.938,473.942,330.938,473.942z"/>
                <path class="st56" d="M335.389,478.376l0.138-0.027c0.316,0.451,0.435,0.674,0.531,0.963c0.049,0.152,0.07,0.544,0.073,1.021
                  h-0.019c-0.485,0.64-1.304,1.195-1.437,0.594c0,0,0.031-0.099,0.079-0.263C334.899,480.171,335.211,479.097,335.389,478.376z"
                  />
                <path class="st204" d="M334.796,480.52c0.045,0.012,0.091,0.02,0.14,0.015c0.511-0.049,0.838-0.292,1.186-0.633
                  c0.005,0.132,0.008,0.277,0.009,0.432h-0.019c-0.485,0.64-1.304,1.195-1.437,0.594c0,0,0.031-0.099,0.079-0.263
                  C334.767,480.621,334.781,480.571,334.796,480.52z"/>
              </g>
            </g>
          </g>
          <path class="st192" d="M324.372,471.567c1.43-0.957,2.147-1.876,2.332-2.152c0.184-0.276,0,0.105,0,0.105v0.222
            c0,0-2.139,2.937-7.369,4.282l-0.013-0.212c0,0,0.568-0.108,1.421-0.406C321.597,473.107,322.942,472.524,324.372,471.567z"/>
          <g>
            
              <rect x="309.747" y="467.483" transform="matrix(0.8699 -0.4932 0.4932 0.8699 -189.6957 215.9295)" class="st192" width="9.479" height="0.171"/>
          </g>
          <g>
            
              <rect x="307.786" y="467.483" transform="matrix(0.8661 -0.4998 0.4998 0.8661 -191.8788 218.7848)" class="st192" width="9.35" height="0.17"/>
          </g>
          <g>
            <path class="st192" d="M311.686,475.547l0.79,0.44c0.09,0.05,0.141,0.166,0.114,0.26c-0.019,0.065-0.071,0.101-0.131,0.101
              c-0.027,0-0.056-0.008-0.085-0.023l-0.801-0.453c-0.088-0.049-0.133-0.162-0.101-0.252
              C311.504,475.531,311.599,475.498,311.686,475.547z"/>
          </g>
        </g>
        <path class="st192" d="M306.959,472.323c0.046,0,0.093,0.011,0.138,0.035l0.063,0.033c0.359,0.193,0.585,0.567,0.589,0.975v0.011
          c0.002,0.227-0.243,0.369-0.439,0.256l-0.086-0.049c-0.346-0.199-0.559-0.569-0.559-0.969
          C306.666,472.445,306.805,472.323,306.959,472.323z"/>
      </g>
      <g>
        <g>
          <polygon class="st56" points="145.545,357.398 236.022,291.596 344.186,357.217 244.541,416.11 			"/>
          <path class="st315" d="M236.008,291.666l108.073,65.54l-0.028,0.017l-99.515,58.814c0.004-0.002-98.891-58.642-98.891-58.642
            L236.008,291.666z"/>
          <path class="st316" d="M235.995,291.737l107.982,65.459l-0.027,0.017l-99.413,58.751c0.009-0.005-98.787-58.571-98.787-58.571
            L235.995,291.737z"/>
          <path class="st317" d="M235.982,291.807l107.891,65.378l-0.027,0.018l-99.311,58.689c0.013-0.008-98.682-58.501-98.682-58.501
            L235.982,291.807z"/>
          <path class="st318" d="M235.969,291.878l107.8,65.296l-0.027,0.018l-99.21,58.627c0.017-0.01-98.577-58.431-98.577-58.431
            L235.969,291.878z"/>
          <path class="st319" d="M235.955,291.949l107.709,65.215l-0.027,0.018l-99.108,58.565c0.021-0.012-98.473-58.361-98.473-58.361
            L235.955,291.949z"/>
          <path class="st320" d="M235.942,292.019l107.618,65.134l-0.027,0.019l-99.006,58.502c0.026-0.015-98.368-58.29-98.368-58.29
            L235.942,292.019z"/>
          <path class="st321" d="M235.929,292.09l107.527,65.052l-0.026,0.019l-98.904,58.44c0.03-0.017-98.264-58.22-98.264-58.22
            L235.929,292.09z"/>
          <path class="st322" d="M235.916,292.161l107.436,64.971l-0.026,0.02l-98.802,58.378c0.034-0.02-98.159-58.15-98.159-58.15
            L235.916,292.161z"/>
          <path class="st323" d="M235.902,292.231l107.345,64.89l-0.026,0.02l-98.701,58.316c0.038-0.022-98.054-58.079-98.054-58.079
            L235.902,292.231z"/>
          <path class="st324" d="M235.889,292.302l107.254,64.808l-0.026,0.02l-98.599,58.253c0.043-0.025-97.95-58.009-97.95-58.009
            L235.889,292.302z"/>
          <path class="st325" d="M235.876,292.372L343.039,357.1l-0.026,0.021l-98.497,58.191c0.047-0.027-97.845-57.939-97.845-57.939
            L235.876,292.372z"/>
          <path class="st326" d="M235.863,292.443l107.072,64.646l-0.025,0.021l-98.395,58.129c0.051-0.03-97.741-57.869-97.741-57.869
            L235.863,292.443z"/>
          <path class="st327" d="M235.849,292.514l106.981,64.564l-0.025,0.022l-98.293,58.067c0.056-0.032-97.636-57.798-97.636-57.798
            L235.849,292.514z"/>
          <path class="st328" d="M235.836,292.584l106.89,64.483l-0.025,0.022l-98.192,58.004c0.06-0.035-97.531-57.728-97.531-57.728
            L235.836,292.584z"/>
          <path class="st329" d="M235.823,292.655l106.799,64.402l-0.025,0.022l-98.09,57.942c0.064-0.037-97.427-57.658-97.427-57.658
            L235.823,292.655z"/>
          <path class="st330" d="M235.81,292.726l106.708,64.32l-0.025,0.023l-97.988,57.88c0.068-0.04-97.322-57.588-97.322-57.588
            L235.81,292.726z"/>
          <path class="st331" d="M235.796,292.796l106.617,64.239l-0.024,0.023l-97.886,57.818c0.073-0.042-97.217-57.517-97.217-57.517
            L235.796,292.796z"/>
          <path class="st332" d="M235.783,292.867l106.526,64.158l-0.024,0.024l-97.784,57.755c0.077-0.045-97.113-57.447-97.113-57.447
            L235.783,292.867z"/>
          <path class="st333" d="M235.77,292.938l106.435,64.076l-0.024,0.024l-97.682,57.693c0.081-0.047-97.008-57.377-97.008-57.377
            L235.77,292.938z"/>
          <path class="st334" d="M235.757,293.008L342.1,357.003l-0.024,0.024l-97.581,57.631c0.085-0.05-96.904-57.307-96.904-57.307
            L235.757,293.008z"/>
          <path class="st335" d="M235.743,293.079l106.253,63.914l-0.024,0.025l-97.479,57.569c0.09-0.052-96.799-57.236-96.799-57.236
            L235.743,293.079z"/>
          <path class="st336" d="M235.73,293.149l106.162,63.832l-0.023,0.025l-97.377,57.506c0.094-0.055-96.694-57.166-96.694-57.166
            L235.73,293.149z"/>
          <path class="st337" d="M235.717,293.22l106.071,63.751l-0.023,0.026l-97.275,57.444c0.098-0.057-96.59-57.096-96.59-57.096
            L235.717,293.22z"/>
          <path class="st338" d="M235.704,293.291l105.98,63.67l-0.023,0.026l-97.173,57.382c0.103-0.06-96.485-57.025-96.485-57.025
            L235.704,293.291z"/>
          <path class="st339" d="M235.69,293.361l105.889,63.588l-0.023,0.026l-97.072,57.32c0.107-0.062-96.381-56.955-96.381-56.955
            L235.69,293.361z"/>
          <path class="st340" d="M235.677,293.432l105.798,63.507l-0.023,0.027l-96.97,57.257c0.111-0.065-96.276-56.885-96.276-56.885
            L235.677,293.432z"/>
          <path class="st341" d="M235.664,293.503l105.707,63.426l-0.022,0.027l-96.868,57.195c0.115-0.067-96.171-56.815-96.171-56.815
            L235.664,293.503z"/>
          <path class="st342" d="M235.651,293.573l105.616,63.344l-0.022,0.028l-96.766,57.133c0.12-0.07-96.067-56.744-96.067-56.744
            L235.651,293.573z"/>
          <path class="st343" d="M235.637,293.644l105.525,63.263l-0.022,0.028l-96.664,57.071c0.124-0.072-95.962-56.674-95.962-56.674
            L235.637,293.644z"/>
          <path class="st344" d="M235.624,293.714l105.434,63.182l-0.022,0.028l-96.562,57.008c0.128-0.075-95.858-56.604-95.858-56.604
            L235.624,293.714z"/>
          <path class="st345" d="M235.611,293.785l105.343,63.1l-0.022,0.029l-96.461,56.946c0.133-0.077-95.753-56.534-95.753-56.534
            L235.611,293.785z"/>
          <path class="st346" d="M235.598,293.856l105.252,63.019l-0.021,0.029l-96.359,56.884c0.137-0.08-95.648-56.463-95.648-56.463
            L235.598,293.856z"/>
          <path class="st347" d="M235.584,293.926l105.161,62.938l-0.021,0.03l-96.257,56.822c0.141-0.082-95.544-56.393-95.544-56.393
            L235.584,293.926z"/>
          <path class="st348" d="M235.571,293.997l105.07,62.856l-0.021,0.03l-96.155,56.759c0.145-0.085-95.439-56.323-95.439-56.323
            L235.571,293.997z"/>
          <path class="st349" d="M235.558,294.068l104.979,62.775l-0.021,0.03l-96.053,56.697c0.15-0.087-95.335-56.252-95.335-56.252
            L235.558,294.068z"/>
          <path class="st350" d="M235.545,294.138l104.887,62.694l-0.021,0.031l-95.952,56.635c0.154-0.09-95.23-56.182-95.23-56.182
            L235.545,294.138z"/>
          <path class="st351" d="M235.531,294.209l104.796,62.612l-0.02,0.031l-95.85,56.573c0.158-0.092-95.125-56.112-95.125-56.112
            L235.531,294.209z"/>
          <path class="st352" d="M235.518,294.28l104.705,62.531l-0.02,0.031l-95.748,56.51c0.162-0.095-95.021-56.042-95.021-56.042
            L235.518,294.28z"/>
          <path class="st353" d="M235.505,294.35l104.614,62.45l-0.02,0.032l-95.646,56.448c0.167-0.097-94.916-55.971-94.916-55.971
            L235.505,294.35z"/>
          <path class="st354" d="M235.492,294.421l104.523,62.368l-0.02,0.032l-95.544,56.386c0.171-0.1-94.812-55.901-94.812-55.901
            L235.492,294.421z"/>
          <path class="st355" d="M235.478,294.491l104.432,62.287l-0.019,0.033l-95.442,56.324c0.175-0.102-94.707-55.831-94.707-55.831
            L235.478,294.491z"/>
          <path class="st356" d="M235.465,294.562l104.341,62.206l-0.019,0.033l-95.341,56.261c0.18-0.105-94.602-55.761-94.602-55.761
            L235.465,294.562z"/>
          <path class="st357" d="M235.452,294.633l104.25,62.124l-0.019,0.033l-95.239,56.199c0.184-0.107-94.498-55.69-94.498-55.69
            L235.452,294.633z"/>
          <path class="st358" d="M235.439,294.703l104.159,62.043l-0.019,0.034l-95.137,56.137c0.188-0.11-94.393-55.62-94.393-55.62
            L235.439,294.703z"/>
          <path class="st359" d="M235.425,294.774l104.068,61.962l-0.019,0.034l-95.035,56.075c0.192-0.112-94.289-55.55-94.289-55.55
            L235.425,294.774z"/>
          <path class="st360" d="M235.412,294.845l103.977,61.88l-0.018,0.035l-94.933,56.012c0.197-0.115-94.184-55.479-94.184-55.479
            L235.412,294.845z"/>
          <path class="st361" d="M235.399,294.915l103.886,61.799l-0.018,0.035l-94.832,55.95c0.201-0.117-94.079-55.409-94.079-55.409
            L235.399,294.915z"/>
          <path class="st362" d="M235.386,294.986l103.795,61.717l-0.018,0.035l-94.73,55.888c0.205-0.12-93.975-55.339-93.975-55.339
            L235.386,294.986z"/>
          <path class="st363" d="M235.372,295.056l103.704,61.636l-0.018,0.036l-94.628,55.826c0.209-0.122-93.87-55.269-93.87-55.269
            L235.372,295.056z"/>
          <path class="st364" d="M235.359,295.127l103.613,61.555l-0.018,0.036l-94.526,55.763c0.214-0.125-93.765-55.198-93.765-55.198
            L235.359,295.127z"/>
          <path class="st365" d="M235.346,295.198l103.522,61.473l-0.017,0.037l-94.424,55.701c0.218-0.127-93.661-55.128-93.661-55.128
            L235.346,295.198z"/>
          <path class="st366" d="M235.333,295.268l103.431,61.392l-0.017,0.037l-94.322,55.639c0.222-0.13-93.556-55.058-93.556-55.058
            L235.333,295.268z"/>
          <path class="st367" d="M235.319,295.339l103.34,61.311l-0.017,0.037l-94.221,55.577c0.227-0.132-93.452-54.988-93.452-54.988
            L235.319,295.339z"/>
          <path class="st368" d="M235.306,295.41l103.249,61.229l-0.017,0.038l-94.119,55.514c0.231-0.135-93.347-54.917-93.347-54.917
            L235.306,295.41z"/>
          <path class="st369" d="M235.293,295.48l103.158,61.148l-0.017,0.038l-94.017,55.452c0.235-0.137-93.242-54.847-93.242-54.847
            L235.293,295.48z"/>
          <path class="st370" d="M235.28,295.551l103.067,61.067l-0.016,0.039l-93.915,55.39c0.239-0.14-93.138-54.777-93.138-54.777
            L235.28,295.551z"/>
          <path class="st371" d="M235.266,295.621l102.976,60.985l-0.016,0.039l-93.813,55.328c0.244-0.142-93.033-54.706-93.033-54.706
            L235.266,295.621z"/>
          <path class="st372" d="M235.253,295.692l102.885,60.904l-0.016,0.039l-93.712,55.265c0.248-0.145-92.929-54.636-92.929-54.636
            L235.253,295.692z"/>
          <path class="st373" d="M235.24,295.763l102.794,60.823l-0.016,0.04l-93.61,55.203c0.252-0.147-92.824-54.566-92.824-54.566
            L235.24,295.763z"/>
          <path class="st374" d="M235.227,295.833l102.703,60.741l-0.016,0.04l-93.508,55.141c0.256-0.15-92.719-54.496-92.719-54.496
            L235.227,295.833z"/>
          <path class="st375" d="M235.213,295.904l102.612,60.66l-0.015,0.041l-93.406,55.079c0.261-0.152-92.615-54.425-92.615-54.425
            L235.213,295.904z"/>
          <path class="st376" d="M235.2,295.975l102.521,60.579l-0.015,0.041l-93.304,55.016c0.265-0.155-92.51-54.355-92.51-54.355
            L235.2,295.975z"/>
          <path class="st377" d="M235.187,296.045l102.43,60.497l-0.015,0.041l-93.202,54.954c0.269-0.157-92.406-54.285-92.406-54.285
            L235.187,296.045z"/>
          <path class="st378" d="M235.173,296.116l102.339,60.416l-0.015,0.042l-93.101,54.892c0.274-0.16-92.301-54.215-92.301-54.215
            L235.173,296.116z"/>
          <path class="st379" d="M235.16,296.187l102.248,60.335l-0.015,0.042l-92.999,54.83c0.278-0.162-92.196-54.144-92.196-54.144
            L235.16,296.187z"/>
          <path class="st380" d="M235.147,296.257l102.157,60.253l-0.014,0.043l-92.897,54.767c0.282-0.165-92.092-54.074-92.092-54.074
            L235.147,296.257z"/>
          <path class="st381" d="M235.134,296.328L337.2,356.5l-0.014,0.043l-92.795,54.705c0.286-0.167-91.987-54.004-91.987-54.004
            L235.134,296.328z"/>
          <path class="st382" d="M235.12,296.398l101.975,60.091l-0.014,0.043l-92.693,54.643c0.291-0.17-91.883-53.934-91.883-53.934
            L235.12,296.398z"/>
          <path class="st383" d="M235.107,296.469l101.884,60.009l-0.014,0.044l-92.592,54.581c0.295-0.172-91.778-53.863-91.778-53.863
            L235.107,296.469z"/>
          <path class="st384" d="M235.094,296.54l101.793,59.928l-0.014,0.044l-92.49,54.518c0.299-0.175-91.673-53.793-91.673-53.793
            L235.094,296.54z"/>
          <path class="st385" d="M235.081,296.61l101.702,59.847l-0.013,0.044l-92.388,54.456c0.303-0.177-91.569-53.723-91.569-53.723
            L235.081,296.61z"/>
          <path class="st386" d="M235.067,296.681l101.611,59.765l-0.013,0.045l-92.286,54.394c0.308-0.18-91.464-53.652-91.464-53.652
            L235.067,296.681z"/>
          <path class="st387" d="M235.054,296.752l101.52,59.684l-0.013,0.045l-92.184,54.332c0.312-0.182-91.36-53.582-91.36-53.582
            L235.054,296.752z"/>
          <path class="st388" d="M235.041,296.822l101.429,59.603l-0.013,0.046l-92.083,54.269c0.316-0.185-91.255-53.512-91.255-53.512
            L235.041,296.822z"/>
          <path class="st389" d="M235.028,296.893l101.338,59.521l-0.012,0.046l-91.981,54.207c0.321-0.187-91.15-53.442-91.15-53.442
            L235.028,296.893z"/>
          <path class="st390" d="M235.014,296.963l101.247,59.44l-0.012,0.046l-91.879,54.145c0.325-0.19-91.046-53.371-91.046-53.371
            L235.014,296.963z"/>
          <path class="st391" d="M235.001,297.034l101.156,59.359l-0.012,0.047l-91.777,54.083c0.329-0.192-90.941-53.301-90.941-53.301
            L235.001,297.034z"/>
          <path class="st392" d="M234.988,297.105l101.065,59.277l-0.012,0.047l-91.675,54.02c0.333-0.195-90.836-53.231-90.836-53.231
            L234.988,297.105z"/>
          <path class="st393" d="M234.975,297.175l100.974,59.196l-0.012,0.048l-91.573,53.958c0.338-0.197-90.732-53.161-90.732-53.161
            L234.975,297.175z"/>
          <path class="st394" d="M234.961,297.246l100.883,59.115l-0.011,0.048l-91.472,53.896c0.342-0.2-90.627-53.09-90.627-53.09
            L234.961,297.246z"/>
          <path class="st395" d="M234.948,297.317L335.74,356.35l-0.011,0.048l-91.37,53.834c0.346-0.202-90.523-53.02-90.523-53.02
            L234.948,297.317z"/>
          <path class="st396" d="M234.935,297.387l100.701,58.952l-0.011,0.049l-91.268,53.771c0.35-0.205-90.418-52.95-90.418-52.95
            L234.935,297.387z"/>
          <path class="st397" d="M234.922,297.458l100.61,58.871l-0.011,0.049l-91.166,53.709c0.355-0.207-90.313-52.879-90.313-52.879
            L234.922,297.458z"/>
          <path class="st398" d="M234.908,297.529l100.519,58.789l-0.011,0.05l-91.064,53.647c0.359-0.21-90.209-52.809-90.209-52.809
            L234.908,297.529z"/>
          <path class="st399" d="M234.895,297.599l100.428,58.708l-0.01,0.05l-90.962,53.585c0.363-0.212-90.104-52.739-90.104-52.739
            L234.895,297.599z"/>
          <path class="st400" d="M234.882,297.67l100.337,58.627l-0.01,0.05l-90.861,53.522c0.368-0.215-90-52.669-90-52.669
            L234.882,297.67z"/>
          <path class="st401" d="M234.869,297.74l100.246,58.545l-0.01,0.051l-90.759,53.46c0.372-0.217-89.895-52.598-89.895-52.598
            L234.869,297.74z"/>
          <path class="st402" d="M234.855,297.811l100.155,58.464l-0.01,0.051l-90.657,53.398c0.376-0.22-89.79-52.528-89.79-52.528
            L234.855,297.811z"/>
          <path class="st403" d="M234.842,297.882l100.064,58.383l-0.01,0.052l-90.555,53.336c0.38-0.222-89.686-52.458-89.686-52.458
            L234.842,297.882z"/>
          <path class="st404" d="M234.829,297.952l99.973,58.301l-0.009,0.052l-90.453,53.273c0.385-0.225-89.581-52.388-89.581-52.388
            L234.829,297.952z"/>
          <path class="st405" d="M234.816,298.023l99.882,58.22l-0.009,0.052l-90.352,53.211c0.389-0.227-89.477-52.317-89.477-52.317
            L234.816,298.023z"/>
          <path class="st406" d="M234.802,298.094l99.791,58.139l-0.009,0.053l-90.25,53.149c0.393-0.23-89.372-52.247-89.372-52.247
            L234.802,298.094z"/>
          <path class="st407" d="M234.789,298.164l99.7,58.057l-0.009,0.053l-90.148,53.087c0.398-0.232-89.267-52.177-89.267-52.177
            L234.789,298.164z"/>
          <path class="st408" d="M234.776,298.235l99.608,57.976l-0.009,0.054l-90.046,53.024c0.402-0.235-89.163-52.106-89.163-52.106
            L234.776,298.235z"/>
          <path class="st409" d="M234.763,298.305L334.28,356.2l-0.008,0.054l-89.944,52.962c0.406-0.237-89.058-52.036-89.058-52.036
            L234.763,298.305z"/>
          <path class="st410" d="M234.749,298.376l99.426,57.813l-0.008,0.054l-89.843,52.9c0.41-0.24-88.954-51.966-88.954-51.966
            L234.749,298.376z"/>
          <path class="st411" d="M234.736,298.447l99.335,57.732l-0.008,0.055l-89.741,52.838c0.415-0.242-88.849-51.896-88.849-51.896
            L234.736,298.447z"/>
          <path class="st412" d="M234.723,298.517l99.244,57.65l-0.008,0.055l-89.639,52.775c0.419-0.245-88.744-51.825-88.744-51.825
            L234.723,298.517z"/>
          <path class="st413" d="M234.71,298.588l99.153,57.569l-0.008,0.056l-89.537,52.713c0.423-0.247-88.64-51.755-88.64-51.755
            L234.71,298.588z"/>
          <path class="st414" d="M234.696,298.659l99.062,57.488l-0.007,0.056l-89.435,52.651c0.427-0.25-88.535-51.685-88.535-51.685
            L234.696,298.659z"/>
          <path class="st415" d="M234.683,298.729l98.971,57.406l-0.007,0.056l-89.333,52.589c0.432-0.252-88.431-51.615-88.431-51.615
            L234.683,298.729z"/>
          <path class="st416" d="M234.67,298.8l98.88,57.325l-0.007,0.057l-89.232,52.526c0.436-0.255-88.326-51.544-88.326-51.544
            L234.67,298.8z"/>
          <path class="st417" d="M234.657,298.87l98.789,57.244l-0.007,0.057l-89.13,52.464c0.44-0.257-88.221-51.474-88.221-51.474
            L234.657,298.87z"/>
          <path class="st418" d="M234.643,298.941l98.698,57.162l-0.007,0.058l-89.028,52.402c0.445-0.26-88.117-51.404-88.117-51.404
            L234.643,298.941z"/>
          <path class="st419" d="M234.63,299.012l98.607,57.081l-0.006,0.058l-88.926,52.34c0.449-0.262-88.012-51.333-88.012-51.333
            L234.63,299.012z"/>
          <path class="st420" d="M234.617,299.082l98.516,57l-0.006,0.058l-88.824,52.277c0.453-0.265-87.908-51.263-87.908-51.263
            L234.617,299.082z"/>
          <path class="st421" d="M234.604,299.153l98.425,56.918l-0.006,0.059L244.3,408.345c0.457-0.267-87.803-51.193-87.803-51.193
            L234.604,299.153z"/>
          <path class="st422" d="M234.59,299.224l98.334,56.837l-0.006,0.059l-88.621,52.153c0.462-0.27-87.698-51.123-87.698-51.123
            L234.59,299.224z"/>
          <path class="st423" d="M234.577,299.294l98.243,56.756l-0.006,0.06L244.296,408.2c0.466-0.272-87.594-51.052-87.594-51.052
            L234.577,299.294z"/>
          <path class="st424" d="M234.564,299.365l98.152,56.674l-0.005,0.06l-88.417,52.028c0.47-0.275-87.489-50.982-87.489-50.982
            L234.564,299.365z"/>
          <path class="st425" d="M234.551,299.436l98.061,56.593l-0.005,0.06l-88.315,51.966c0.474-0.277-87.384-50.912-87.384-50.912
            L234.551,299.436z"/>
          <path class="st426" d="M234.537,299.506l97.97,56.512l-0.005,0.061l-88.213,51.904c0.479-0.28-87.28-50.842-87.28-50.842
            L234.537,299.506z"/>
          <path class="st427" d="M234.524,299.577l97.879,56.43l-0.005,0.061l-88.112,51.842c0.483-0.282-87.175-50.771-87.175-50.771
            L234.524,299.577z"/>
          <path class="st428" d="M234.511,299.647l97.788,56.349l-0.004,0.061l-88.01,51.779c0.487-0.285-87.071-50.701-87.071-50.701
            L234.511,299.647z"/>
          <path class="st429" d="M234.498,299.718l97.697,56.268l-0.004,0.062l-87.908,51.717c0.492-0.287-86.966-50.631-86.966-50.631
            L234.498,299.718z"/>
          <path class="st430" d="M234.484,299.789l97.606,56.186l-0.004,0.062l-87.806,51.655c0.496-0.29-86.861-50.561-86.861-50.561
            L234.484,299.789z"/>
          <path class="st431" d="M234.471,299.859l97.515,56.105l-0.004,0.063l-87.704,51.593c0.5-0.292-86.757-50.49-86.757-50.49
            L234.471,299.859z"/>
          <path class="st432" d="M234.458,299.93l97.424,56.024l-0.004,0.063l-87.603,51.53c0.504-0.295-86.652-50.42-86.652-50.42
            L234.458,299.93z"/>
          <path class="st433" d="M234.445,300.001l97.333,55.942l-0.003,0.063l-87.501,51.468c0.509-0.297-86.548-50.35-86.548-50.35
            L234.445,300.001z"/>
          <path class="st434" d="M234.431,300.071l97.242,55.861l-0.003,0.064l-87.399,51.406c0.513-0.3-86.443-50.279-86.443-50.279
            L234.431,300.071z"/>
          <path class="st435" d="M234.418,300.142l97.151,55.78l-0.003,0.064l-87.297,51.344c0.517-0.302-86.338-50.209-86.338-50.209
            L234.418,300.142z"/>
          <path class="st436" d="M234.405,300.212l97.06,55.698l-0.003,0.065l-87.195,51.281c0.521-0.305-86.234-50.139-86.234-50.139
            L234.405,300.212z"/>
          <path class="st437" d="M234.392,300.283l96.969,55.617l-0.003,0.065l-87.093,51.219c0.526-0.307-86.129-50.069-86.129-50.069
            L234.392,300.283z"/>
          <path class="st438" d="M234.378,300.354l96.878,55.536l-0.002,0.065l-86.992,51.157c0.53-0.31-86.025-49.998-86.025-49.998
            L234.378,300.354z"/>
          <path class="st439" d="M234.365,300.424l96.787,55.454l-0.002,0.066l-86.89,51.095c0.534-0.312-85.92-49.928-85.92-49.928
            L234.365,300.424z"/>
          <path class="st440" d="M234.352,300.495l96.696,55.373l-0.002,0.066l-86.788,51.032c0.539-0.315-85.815-49.858-85.815-49.858
            L234.352,300.495z"/>
          <path class="st441" d="M234.339,300.566l96.605,55.292l-0.002,0.067l-86.686,50.97c0.543-0.317-85.711-49.788-85.711-49.788
            L234.339,300.566z"/>
          <path class="st442" d="M234.325,300.636l96.514,55.21l-0.002,0.067l-86.584,50.908c0.547-0.32-85.606-49.717-85.606-49.717
            L234.325,300.636z"/>
          <path class="st443" d="M234.312,300.707l96.423,55.129l-0.001,0.067l-86.483,50.846c0.551-0.322-85.502-49.647-85.502-49.647
            L234.312,300.707z"/>
          <path class="st444" d="M234.299,300.777l96.332,55.048l-0.001,0.068l-86.381,50.783c0.556-0.325-85.397-49.577-85.397-49.577
            L234.299,300.777z"/>
          <path class="st445" d="M234.286,300.848l96.241,54.966l-0.001,0.068l-86.279,50.721c0.56-0.327-85.292-49.506-85.292-49.506
            L234.286,300.848z"/>
          <path class="st446" d="M234.272,300.919l96.15,54.885l-0.001,0.069l-86.177,50.659c0.564-0.33-85.188-49.436-85.188-49.436
            L234.272,300.919z"/>
          <path class="st447" d="M234.259,300.989l96.059,54.804l-0.001,0.069l-86.075,50.597c0.568-0.332-85.083-49.366-85.083-49.366
            L234.259,300.989z"/>
          <path class="st448" d="M234.246,301.06l95.968,54.722l0,0.069l-85.973,50.534c0.573-0.335-84.979-49.296-84.979-49.296
            L234.246,301.06z"/>
          <path class="st449" d="M234.233,301.131l95.877,54.641l0,0.07l-85.872,50.472c0.577-0.337-84.874-49.225-84.874-49.225
            L234.233,301.131z"/>
          <path class="st450" d="M234.219,301.201l-74.753,55.884c0,0,85.351,48.815,84.769,49.155l85.77-50.41v-0.07L234.219,301.201z"/>
        </g>
        <polygon class="st451" points="235.018,397.639 235.024,402.413 290.783,367.677 290.783,361.364 		"/>
        <polygon class="st452" points="155.439,350.935 155.439,356.094 160.834,359.43 164.047,353.808 		"/>
        <polygon class="st453" points="250.025,276.595 244.012,273.599 196.647,300.871 155.439,350.935 160.834,354.271 
          204.178,328.997 207.856,301.872 		"/>
        <polygon class="st454" points="207.434,301.045 202.014,304.151 160.834,354.271 207.434,327.096 		"/>
        <polygon class="st455" points="196.647,301.002 196.647,300.871 196.647,300.871 155.439,350.935 160.834,354.271 
          202.014,304.151 		"/>
        <g>
          <g>
            <polygon class="st456" points="160.834,354.271 160.834,359.43 235.024,403.417 235.018,397.639 				"/>
            <g>
              <g>
                <polygon class="st457" points="314.196,330.762 252.816,296.984 207.112,299.931 206.194,326.022 160.834,354.271 
                  235.018,397.639 						"/>
                <polygon class="st458" points="231.64,299.711 240.89,295.88 240.887,299.711 251.475,299.711 251.475,275.132 
                  212.893,296.835 212.893,299.711 						"/>
                <polygon class="st459" points="248.972,273.712 210.745,295.127 212.896,296.835 251.475,275.132 						"/>
                <polygon class="st457" points="327,320.551 251.445,277.969 209.588,301.642 284.176,344.089 						"/>
                <polygon class="st460" points="281.309,339.817 209.622,300.207 209.588,309.62 284.176,352.07 						"/>
                <g>
                  <g>
                    <polygon class="st461" points="206.387,312.964 203.884,311.545 197.574,319.042 197.537,332.911 200.126,334.441 								
                      "/>
                    <polygon class="st459" points="203.884,311.545 197.574,319.042 200.077,320.612 206.387,313.115 								"/>
                    <polygon class="st461" points="210.733,299.803 208.23,298.383 203.884,300.908 203.884,311.545 206.387,313.115 
                      210.733,328.317 								"/>
                    <polygon class="st458" points="210.733,299.803 206.387,302.477 206.387,313.115 200.147,320.551 200.138,329.379 
                      191.796,339.258 210.442,328.654 210.733,328.317 								"/>
                    <polygon class="st459" points="208.23,298.383 203.884,300.908 206.387,302.477 210.733,299.803 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="218.772,320.168 216.268,318.748 209.962,326.242 209.922,340.111 212.513,341.641 								
                      "/>
                    <polygon class="st459" points="216.268,318.748 209.962,326.242 212.459,327.812 218.772,320.315 								"/>
                    <polygon class="st461" points="223.114,307.003 220.614,305.584 216.268,308.108 216.268,318.748 218.772,320.315 
                      223.114,335.518 								"/>
                    <polygon class="st458" points="223.114,307.003 218.772,309.678 218.772,320.315 212.532,327.751 212.513,341.641 
                      222.826,335.854 223.114,335.518 								"/>
                    <polygon class="st459" points="220.614,305.584 216.268,308.108 218.772,309.678 223.114,307.003 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="231.156,327.369 228.653,325.949 222.343,333.446 222.306,347.315 224.895,348.845 								
                      "/>
                    <polygon class="st459" points="228.653,325.949 222.343,333.446 224.846,335.016 231.156,327.519 								"/>
                    <polygon class="st461" points="235.502,314.207 232.998,312.787 228.653,315.312 228.653,325.949 231.156,327.519 
                      235.502,342.718 								"/>
                    <polygon class="st458" points="235.502,314.207 231.156,316.878 231.156,327.519 224.913,334.955 224.895,348.845 
                      235.208,343.058 235.502,342.718 								"/>
                    <polygon class="st459" points="232.998,312.787 228.653,315.312 231.156,316.878 235.502,314.207 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="243.54,334.569 241.04,333.149 234.73,340.647 234.691,354.515 237.282,356.042 								"/>
                    <polygon class="st459" points="241.04,333.149 234.73,340.647 237.228,342.213 243.54,334.719 								"/>
                    <polygon class="st461" points="247.883,321.408 245.383,319.987 241.04,322.512 241.04,333.149 243.54,334.719 
                      247.883,349.922 								"/>
                    <polygon class="st458" points="247.883,321.408 243.54,324.082 243.54,334.719 237.301,342.155 237.282,356.042 
                      247.595,350.259 247.883,349.922 								"/>
                    <polygon class="st459" points="245.383,319.987 241.04,322.512 243.54,324.082 247.883,321.408 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="255.925,341.773 253.422,340.353 247.112,347.85 247.075,361.719 249.664,363.246 								
                      "/>
                    <polygon class="st459" points="253.422,340.353 247.112,347.85 249.615,349.414 255.925,341.92 								"/>
                    <polygon class="st461" points="260.267,328.611 257.767,327.191 253.422,329.713 253.422,340.353 255.925,341.92 
                      260.267,357.122 								"/>
                    <polygon class="st458" points="260.267,328.611 255.925,331.283 255.925,341.92 249.682,349.356 249.664,363.246 
                      259.976,357.462 260.267,357.122 								"/>
                    <polygon class="st459" points="257.767,327.191 253.422,329.713 255.925,331.283 260.267,328.611 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="268.306,348.973 265.806,347.553 259.496,355.048 259.456,368.919 262.048,370.446 								
                      "/>
                    <polygon class="st459" points="265.806,347.553 259.496,355.048 261.996,356.618 268.306,349.123 								"/>
                    <polygon class="st461" points="272.652,335.811 270.152,334.392 265.806,336.916 265.806,347.553 268.306,349.123 
                      272.652,364.32 								"/>
                    <polygon class="st458" points="272.652,335.811 268.306,338.486 268.306,349.123 262.07,356.556 262.048,370.446 
                      272.361,364.663 272.652,364.32 								"/>
                    <polygon class="st459" points="270.152,334.392 265.806,336.916 268.306,338.486 272.652,335.811 								"/>
                  </g>
                  <g>
                    <polygon class="st461" points="280.694,356.177 278.19,354.757 271.881,362.248 271.844,376.12 274.433,377.647 								"/>
                    <polygon class="st459" points="278.19,354.757 271.881,362.248 274.381,363.818 280.694,356.324 								"/>
                    <polygon class="st459" points="271.798,371.71 263.581,381.438 266.084,383.008 274.427,373.13 								"/>
                    <polygon class="st459" points="197.556,327.987 189.293,337.691 191.796,339.258 200.138,329.379 								"/>
                    <polygon class="st461" points="285.036,343.012 282.536,341.589 278.19,344.117 278.19,354.757 280.694,356.324 
                      285.036,371.523 								"/>
                    <polygon class="st458" points="285.036,343.012 280.694,345.687 280.694,356.324 274.451,363.757 274.427,373.13 
                      266.084,383.008 284.745,371.86 285.036,371.523 								"/>
                    <polygon class="st459" points="282.536,341.589 278.19,344.117 280.694,345.687 285.036,343.012 								"/>
                  </g>
                </g>
                <g>
                  <polygon class="st461" points="206.4,297.652 206.4,299.515 283.206,344.147 287.549,341.473 287.549,339.606 							"/>
                  <polygon class="st458" points="283.206,342.281 283.206,344.147 287.549,341.473 287.549,339.606 							"/>
                  <polygon class="st459" points="210.745,295.127 206.4,297.652 283.206,342.281 287.549,339.606 							"/>
                </g>
                <g>
                  <path class="st456" d="M216.268,313.418l-5.536-3.241c-4.333,2.381-8.939,9.407-11.234,17.709
                    c-0.762,2.76-1.19,5.383-1.313,7.739l9.921,5.734c0,0,0.367-5.126,1.836-10.04l0.019-5.077l6.307-7.494V313.418z"/>
                  <path class="st457" d="M201.246,329.254c2.289-8.287,6.806-15.347,11.145-18.052l-1.389-0.811
                    c-4.33,2.381-8.935,9.41-11.231,17.712c-0.762,2.76-1.19,5.38-1.313,7.739l1.451,1.056
                    C200.059,334.557,200.493,331.974,201.246,329.254z"/>
                  <path class="st457" d="M204.19,331.457c2.338-8.461,6.947-15.668,11.377-18.428l-1.42-0.829
                    c-4.419,2.433-9.119,9.606-11.463,18.079c-0.777,2.818-1.218,5.493-1.34,7.898l1.481,1.08
                    C202.978,336.864,203.425,334.23,204.19,331.457z"/>
                  <path class="st457" d="M216.268,314.58c-4.131,2.95-8.541,9.835-10.692,17.623c-0.781,2.822-1.218,5.496-1.343,7.901
                    l1.481,1.08c0.156-2.393,0.6-5.031,1.365-7.803c1.906-6.889,5.52-13.104,9.19-16.66V314.58z"/>
                </g>
                <g>
                  <path class="st456" d="M228.653,320.621l-5.539-3.244c-4.33,2.381-8.936,9.41-11.231,17.709
                    c-0.765,2.763-1.194,5.383-1.316,7.739l9.924,5.735c0,0,0.37-5.126,1.839-10.037l0.012-5.077l6.31-7.497V320.621z"/>
                  <path class="st457" d="M213.631,336.454c2.292-8.287,6.806-15.346,11.145-18.049l-1.386-0.814
                    c-4.333,2.381-8.939,9.41-11.23,17.712c-0.765,2.76-1.194,5.38-1.316,7.736l1.451,1.059
                    C212.446,341.757,212.881,339.172,213.631,336.454z"/>
                  <path class="st457" d="M216.574,338.657c2.341-8.458,6.95-15.665,11.378-18.425l-1.417-0.833
                    c-4.422,2.433-9.125,9.609-11.463,18.08c-0.78,2.822-1.221,5.496-1.346,7.898l1.484,1.08
                    C215.363,344.065,215.806,341.43,216.574,338.657z"/>
                  <path class="st457" d="M228.653,321.784c-4.131,2.947-8.541,9.832-10.692,17.623c-0.78,2.818-1.218,5.493-1.343,7.901
                    l1.484,1.08c0.153-2.393,0.597-5.031,1.365-7.806c1.903-6.888,5.517-13.101,9.186-16.663V321.784z"/>
                </g>
                <g>
                  <path class="st456" d="M241.05,327.613l-5.539-3.296c-4.333,2.418-8.939,9.569-11.234,18.012
                    c-0.759,2.812-1.19,5.475-1.313,7.874l9.924,5.833c0,0,0.367-5.214,1.836-10.209l0.015-5.169l6.31-7.623V327.613z"/>
                  <path class="st457" d="M226.027,343.725c2.292-8.431,6.806-15.613,11.145-18.364l-1.389-0.826
                    c-4.333,2.424-8.935,9.572-11.231,18.015c-0.762,2.809-1.193,5.475-1.316,7.871l1.454,1.077
                    C224.84,349.114,225.274,346.485,226.027,343.725z"/>
                  <path class="st457" d="M228.971,345.962c2.338-8.605,6.947-15.934,11.378-18.74l-1.42-0.848
                    c-4.422,2.473-9.119,9.774-11.463,18.392c-0.78,2.867-1.218,5.588-1.34,8.033l1.481,1.102
                    C227.759,351.464,228.206,348.787,228.971,345.962z"/>
                  <path class="st457" d="M241.05,328.795c-4.134,3.002-8.541,10.007-10.695,17.929c-0.777,2.867-1.215,5.591-1.34,8.039
                    l1.481,1.096c0.156-2.433,0.6-5.113,1.365-7.938c1.903-7.008,5.52-13.327,9.19-16.944V328.795z"/>
                </g>
                <path class="st456" d="M272.618,345.488c-4.33,2.424-8.939,9.572-11.234,18.015c-0.759,2.812-1.19,5.478-1.313,7.874
                  l8.024,4.719l2.372-2.809c0.281-1.824,0.716-4.076,1.365-6.285l0.015-4.856l6.31-7.494v-5.863L272.618,345.488z"/>
                <g>
                  <path class="st456" d="M253.409,334.655l-5.542-3.296c-4.33,2.421-8.939,9.572-11.231,18.015
                    c-0.765,2.809-1.19,5.472-1.316,7.871l9.924,5.833c0,0,0.367-5.214,1.836-10.209l0.015-5.165l6.313-7.623V334.655z"/>
                  <path class="st457" d="M238.384,350.767c2.289-8.431,6.806-15.61,11.145-18.364l-1.389-0.826
                    c-4.33,2.423-8.936,9.572-11.231,18.015c-0.765,2.809-1.194,5.478-1.316,7.874l1.453,1.077
                    C237.197,356.155,237.631,353.53,238.384,350.767z"/>
                  <path class="st457" d="M241.328,353.004c2.338-8.602,6.946-15.934,11.377-18.74l-1.42-0.845
                    c-4.419,2.472-9.119,9.771-11.463,18.388c-0.777,2.87-1.218,5.591-1.341,8.036l1.481,1.099
                    C240.116,358.509,240.563,355.825,241.328,353.004z"/>
                  <path class="st457" d="M253.409,335.839c-4.137,2.999-8.544,10.004-10.695,17.929c-0.78,2.867-1.221,5.588-1.343,8.033
                    l1.481,1.099c0.153-2.433,0.6-5.114,1.365-7.938c1.903-7.005,5.52-13.324,9.193-16.947V335.839z"/>
                </g>
                <g>
                  <path class="st456" d="M265.846,341.693l-5.539-3.296c-4.333,2.421-8.936,9.569-11.231,18.015
                    c-0.765,2.809-1.194,5.474-1.316,7.871l9.921,5.833c0,0,0.37-5.215,1.839-10.209l0.015-4.86l6.31-7.494V341.693z"/>
                  <path class="st457" d="M250.824,357.805c2.292-8.431,6.803-15.613,11.145-18.361l-1.386-0.829
                    c-4.333,2.423-8.939,9.572-11.234,18.015c-0.762,2.809-1.193,5.475-1.313,7.874l1.451,1.074
                    C249.636,363.194,250.071,360.568,250.824,357.805z"/>
                  <path class="st457" d="M253.768,360.042c2.335-8.605,6.943-15.934,11.375-18.74l-1.414-0.848
                    c-4.425,2.472-9.125,9.774-11.47,18.391c-0.777,2.868-1.215,5.591-1.34,8.036l1.484,1.099
                    C252.556,365.544,252.999,362.866,253.768,360.042z"/>
                  <path class="st457" d="M265.846,342.874c-4.131,3.002-8.541,10.007-10.695,17.929c-0.78,2.868-1.215,5.591-1.343,8.039
                    l1.484,1.096c0.156-2.43,0.597-5.114,1.365-7.935c1.906-7.011,5.52-13.33,9.19-16.947V342.874z"/>
                </g>
                <g>
                  <path class="st457" d="M263.134,364.895c2.289-8.428,6.806-15.61,11.145-18.358l-1.389-0.826
                    c-4.33,2.421-8.935,9.569-11.231,18.015c-0.765,2.809-1.193,5.475-1.316,7.871l1.454,1.077
                    C261.947,370.29,262.382,367.662,263.134,364.895z"/>
                  <path class="st457" d="M266.078,367.138c2.338-8.605,6.947-15.934,11.377-18.743l-1.42-0.845
                    c-4.419,2.473-9.122,9.771-11.463,18.392c-0.777,2.867-1.218,5.585-1.343,8.033l1.484,1.101
                    C264.867,372.641,265.31,369.957,266.078,367.138z"/>
                  <path class="st457" d="M267.462,367.9c-0.777,2.867-1.218,5.588-1.34,8.033l0.04,0.03h1.533
                    c0.202-2.139,0.615-4.449,1.273-6.867c1.903-7.008,5.52-13.327,9.19-16.95v-2.176
                    C274.022,352.97,269.616,359.975,267.462,367.9z"/>
                </g>
                <polygon class="st459" points="325.176,315.541 283.295,338.908 287.549,339.606 327.673,316.961 						"/>
              </g>
              <polygon class="st458" points="327.673,321.001 327.673,316.961 287.549,339.606 283.206,344.147 284.525,345.574 					"/>
              <polygon class="st453" points="333.885,323.843 327.728,320.529 279.362,348.508 235.018,397.639 244.321,403.591 
                287.402,376.407 291.083,349.282 					"/>
              <polygon class="st454" points="334.032,323.816 285.241,351.562 244.321,403.591 244.214,408.723 334.032,355.929 					"/>
            </g>
            <polygon class="st452" points="235.018,397.639 235.024,403.417 244.214,408.723 244.388,403.478 				"/>
          </g>
          <g>
            <path class="st462" d="M178.785,353.631c1.04,0.707,1.282,2.136,2.359,2.827c1.031,0.661,2.084,0.196,3.189,0.018
              c3.571-0.566,2.675,2.179,4.379,4.272c0.802,0.982,2.148,1.08,3.256,1.582c1.561,0.71,2.638,1.818,3.761,3.085
              c1.334,1.509,2.843,3.568,4.902,4.101c1.24,0.321,2.476-0.236,3.694,0.165c1.408,0.468,2.105,1.867,3.268,2.665
              c1.322,0.912,3.115,1.145,4.642,1.542c2.026,0.53,4.25,0.93,6.071,2.011c1.582,0.939,2.763,2.402,4.11,3.629
              c1.399,1.282,3.969,1.539,4.86,3.173c0.52,0.958,0.489,2.063,1.123,3.014c0.698,1.053,1.732,1.806,2.736,2.537
              c1.184,0.869,3.485,1.971,3.522,3.681c-1.671,0.315-2.528-1.998-3.568-2.864c-1.09-0.909-2.543-1.218-3.783-1.854
              c-1.845-0.952-3.277-2.451-4.976-3.617c-2.565-1.759-5.805-1.095-7.996-3.244c-1.46-1.435-2.604-3.1-4.41-4.153
              c-1.579-0.924-3.317-1.154-5.074-1.53c-3.501-0.759-4.315-2.874-6.919-4.896c-2.016-1.561-4.673-0.952-6.487-2.797
              c-2.151-2.185-2.892-4.226-6.35-4.11c-2.157,0.071-4.526,1.108-5.985-0.958c-1.662-2.353-1.603-6.114-4.56-7.35
              c-0.796-0.333-6.699-1.392-7.225-0.624c0.811-1.184,5.187-1.101,6.607-1.236c1.414-0.135,3.403,0.049,4.688,0.823
              C178.674,353.56,178.73,353.597,178.785,353.631z M185.609,348.747c-0.679-0.447-1.735-0.557-2.485-0.478
              c-0.753,0.076-3.072,0.031-3.507,0.713c0.281-0.441,3.415,0.171,3.837,0.361c1.567,0.716,1.533,2.889,2.418,4.248
              c0.771,1.19,2.029,0.594,3.17,0.551c1.836-0.065,2.231,1.114,3.372,2.375c0.961,1.065,2.372,0.716,3.439,1.616
              c1.383,1.169,1.812,2.39,3.672,2.831c0.93,0.217,1.849,0.349,2.687,0.881c0.961,0.609,1.567,1.57,2.344,2.399
              c1.16,1.245,2.876,0.857,4.241,1.873c0.9,0.677,1.659,1.54,2.638,2.09c0.655,0.37,1.426,0.548,2.007,1.071
              c0.551,0.502,1.004,1.839,1.891,1.655c-0.018-0.988-1.242-1.625-1.87-2.124c-0.529-0.425-1.08-0.863-1.45-1.469
              c-0.34-0.548-0.322-1.187-0.597-1.738c-0.471-0.946-1.836-1.096-2.576-1.836c-0.713-0.707-1.341-1.551-2.179-2.096
              c-0.967-0.624-2.148-0.854-3.223-1.16c-0.808-0.23-1.76-0.364-2.463-0.891c-0.612-0.465-0.986-1.273-1.732-1.539
              c-0.646-0.236-1.304,0.089-1.958-0.098c-1.093-0.306-1.891-1.496-2.601-2.365c-0.594-0.738-1.166-1.374-1.995-1.784
              c-0.588-0.291-1.298-0.346-1.726-0.915c-0.906-1.209-0.428-2.791-2.323-2.466c-0.584,0.101-1.148,0.37-1.692-0.012
              c-0.569-0.398-0.698-1.224-1.252-1.631C185.67,348.787,185.639,348.765,185.609,348.747z M216.92,360.853
              c-0.679-0.447-1.732-0.554-2.485-0.478c-0.753,0.076-3.072,0.031-3.504,0.713c0.278-0.437,3.412,0.171,3.834,0.361
              c1.567,0.716,1.533,2.889,2.418,4.248c0.774,1.19,2.029,0.594,3.17,0.554c1.836-0.067,2.231,1.114,3.372,2.371
              c0.961,1.068,2.372,0.716,3.439,1.616c1.383,1.169,1.812,2.393,3.672,2.831c0.93,0.217,1.851,0.352,2.687,0.884
              c0.961,0.609,1.567,1.57,2.344,2.399c1.163,1.242,2.876,0.857,4.241,1.873c0.9,0.673,1.659,1.539,2.638,2.087
              c0.655,0.37,1.426,0.548,2.007,1.074c0.551,0.499,1.004,1.836,1.891,1.655c-0.018-0.991-1.239-1.625-1.87-2.127
              c-0.529-0.425-1.08-0.86-1.451-1.466c-0.337-0.551-0.321-1.187-0.597-1.741c-0.471-0.946-1.836-1.093-2.576-1.833
              c-0.713-0.71-1.341-1.555-2.179-2.099c-0.967-0.624-2.145-0.854-3.219-1.16c-0.811-0.23-1.763-0.361-2.466-0.891
              c-0.612-0.462-0.986-1.27-1.732-1.539c-0.646-0.233-1.301,0.089-1.958-0.095c-1.09-0.306-1.891-1.499-2.601-2.369
              c-0.594-0.734-1.163-1.374-1.995-1.781c-0.585-0.291-1.298-0.346-1.726-0.915c-0.903-1.212-0.428-2.794-2.323-2.47
              c-0.585,0.104-1.145,0.37-1.692-0.009c-0.569-0.398-0.698-1.224-1.252-1.634C216.981,360.892,216.951,360.874,216.92,360.853z
              M215.057,349.527c-0.679-0.447-1.735-0.551-2.488-0.474c-0.753,0.076-3.073,0.031-3.501,0.713
              c0.275-0.441,3.409,0.168,3.831,0.361c1.567,0.713,1.536,2.886,2.418,4.248c0.777,1.19,2.032,0.594,3.173,0.554
              c1.833-0.07,2.231,1.111,3.369,2.372c0.964,1.068,2.375,0.713,3.443,1.616c1.38,1.169,1.812,2.39,3.669,2.827
              c0.93,0.22,1.854,0.352,2.69,0.885c0.958,0.609,1.567,1.573,2.341,2.399c1.163,1.242,2.883,0.86,4.241,1.876
              c0.9,0.67,1.659,1.539,2.638,2.087c0.661,0.367,1.429,0.545,2.007,1.071c0.551,0.502,1.007,1.836,1.891,1.655
              c-0.018-0.988-1.236-1.622-1.867-2.127c-0.532-0.422-1.08-0.857-1.451-1.463c-0.337-0.554-0.318-1.19-0.594-1.741
              c-0.474-0.949-1.839-1.095-2.583-1.836c-0.71-0.71-1.34-1.555-2.176-2.096c-0.967-0.624-2.145-0.857-3.222-1.16
              c-0.808-0.23-1.763-0.364-2.463-0.894c-0.615-0.459-0.982-1.267-1.732-1.539c-0.646-0.229-1.3,0.089-1.962-0.095
              c-1.089-0.306-1.891-1.496-2.598-2.368c-0.593-0.731-1.166-1.371-1.995-1.781c-0.584-0.288-1.3-0.346-1.726-0.912
              c-0.902-1.212-0.431-2.797-2.323-2.47c-0.584,0.101-1.144,0.37-1.692-0.012c-0.572-0.398-0.701-1.224-1.252-1.634
              C215.115,349.57,215.087,349.549,215.057,349.527z"/>
          </g>
          <g>
            <path class="st462" d="M233.115,295.552c1.04,0.707,1.285,2.136,2.362,2.825c1.031,0.661,2.087,0.196,3.188,0.021
              c3.568-0.569,2.675,2.179,4.382,4.269c0.802,0.985,2.148,1.08,3.25,1.585c1.564,0.71,2.644,1.815,3.764,3.082
              c1.334,1.509,2.84,3.568,4.902,4.1c1.236,0.322,2.473-0.233,3.694,0.169c1.408,0.465,2.109,1.864,3.265,2.665
              c1.322,0.915,3.121,1.145,4.645,1.542c2.023,0.526,4.254,0.927,6.071,2.008c1.582,0.939,2.767,2.405,4.107,3.629
              c1.402,1.282,3.972,1.539,4.862,3.176c0.52,0.955,0.487,2.06,1.123,3.014c0.698,1.05,1.732,1.802,2.736,2.537
              c1.187,0.869,3.489,1.968,3.522,3.678c-1.668,0.315-2.525-1.995-3.568-2.864c-1.089-0.909-2.543-1.215-3.785-1.854
              c-1.842-0.949-3.274-2.451-4.973-3.617c-2.567-1.76-5.805-1.09-7.996-3.244c-1.457-1.432-2.607-3.1-4.413-4.153
              c-1.579-0.921-3.317-1.148-5.068-1.53c-3.507-0.756-4.315-2.874-6.922-4.896c-2.014-1.561-4.676-0.949-6.488-2.797
              c-2.148-2.185-2.892-4.226-6.353-4.11c-2.157,0.07-4.523,1.108-5.98-0.955c-1.665-2.356-1.607-6.117-4.56-7.354
              c-0.796-0.331-6.702-1.389-7.228-0.624c0.814-1.185,5.19-1.099,6.604-1.237c1.417-0.132,3.409,0.052,4.688,0.823
              C233.007,295.479,233.063,295.516,233.115,295.552z M239.942,290.665c-0.679-0.447-1.735-0.554-2.488-0.477
              c-0.75,0.076-3.072,0.034-3.501,0.716c0.279-0.444,3.409,0.168,3.831,0.358c1.57,0.716,1.536,2.889,2.421,4.248
              c0.774,1.19,2.029,0.594,3.173,0.554c1.833-0.067,2.228,1.111,3.366,2.375c0.964,1.065,2.375,0.713,3.439,1.613
              c1.383,1.169,1.818,2.393,3.672,2.831c0.93,0.217,1.854,0.352,2.693,0.884c0.955,0.609,1.567,1.57,2.338,2.399
              c1.163,1.242,2.883,0.857,4.241,1.873c0.9,0.673,1.662,1.539,2.638,2.087c0.661,0.37,1.432,0.548,2.007,1.074
              c0.554,0.499,1.01,1.836,1.894,1.656c-0.021-0.991-1.239-1.625-1.87-2.127c-0.532-0.426-1.08-0.86-1.451-1.466
              c-0.337-0.551-0.318-1.187-0.594-1.741c-0.474-0.945-1.836-1.096-2.583-1.836c-0.71-0.707-1.337-1.551-2.176-2.096
              c-0.967-0.624-2.148-0.854-3.222-1.16c-0.808-0.229-1.76-0.361-2.463-0.891c-0.615-0.465-0.986-1.27-1.729-1.539
              c-0.649-0.236-1.307,0.089-1.962-0.095c-1.092-0.309-1.891-1.5-2.601-2.369c-0.594-0.734-1.166-1.374-1.995-1.781
              c-0.584-0.29-1.3-0.346-1.726-0.915c-0.903-1.212-0.432-2.794-2.323-2.469c-0.588,0.104-1.144,0.37-1.692-0.009
              c-0.572-0.398-0.701-1.224-1.252-1.634C240,290.705,239.972,290.687,239.942,290.665z M271.256,302.774
              c-0.679-0.447-1.738-0.557-2.488-0.478c-0.753,0.076-3.076,0.031-3.504,0.713c0.279-0.441,3.412,0.168,3.831,0.361
              c1.57,0.716,1.536,2.886,2.42,4.248c0.774,1.19,2.029,0.594,3.173,0.551c1.836-0.067,2.228,1.114,3.366,2.377
              c0.964,1.062,2.375,0.713,3.443,1.613c1.383,1.169,1.815,2.393,3.669,2.827c0.933,0.22,1.854,0.352,2.693,0.885
              c0.958,0.609,1.567,1.57,2.341,2.399c1.16,1.242,2.88,0.857,4.239,1.873c0.9,0.677,1.661,1.54,2.641,2.09
              c0.658,0.37,1.429,0.548,2.005,1.071c0.554,0.502,1.01,1.839,1.894,1.656c-0.018-0.989-1.239-1.625-1.87-2.124
              c-0.529-0.425-1.08-0.863-1.451-1.469c-0.337-0.548-0.318-1.187-0.594-1.741c-0.474-0.943-1.836-1.092-2.583-1.833
              c-0.71-0.707-1.337-1.555-2.176-2.096c-0.964-0.627-2.148-0.854-3.222-1.163c-0.808-0.229-1.76-0.361-2.461-0.887
              c-0.618-0.465-0.988-1.273-1.732-1.539c-0.649-0.236-1.307,0.089-1.962-0.098c-1.092-0.306-1.891-1.496-2.598-2.368
              c-0.597-0.735-1.169-1.371-1.995-1.781c-0.588-0.291-1.304-0.346-1.726-0.915c-0.906-1.209-0.435-2.794-2.326-2.466
              c-0.584,0.101-1.144,0.37-1.692-0.012c-0.572-0.398-0.701-1.224-1.252-1.631C271.311,302.811,271.284,302.793,271.256,302.774z
              M269.389,291.449c-0.679-0.447-1.738-0.554-2.488-0.474c-0.75,0.077-3.072,0.028-3.504,0.71
              c0.282-0.441,3.415,0.171,3.835,0.364c1.57,0.713,1.536,2.883,2.42,4.244c0.771,1.194,2.026,0.597,3.17,0.554
              c1.836-0.07,2.228,1.111,3.369,2.375c0.964,1.065,2.372,0.713,3.443,1.616c1.383,1.166,1.812,2.39,3.669,2.828
              c0.933,0.22,1.851,0.352,2.69,0.881c0.961,0.609,1.567,1.573,2.344,2.399c1.16,1.242,2.877,0.86,4.238,1.876
              c0.9,0.673,1.662,1.539,2.641,2.09c0.655,0.37,1.426,0.548,2.005,1.068c0.554,0.502,1.007,1.839,1.894,1.655
              c-0.018-0.986-1.242-1.622-1.873-2.124c-0.526-0.422-1.077-0.86-1.451-1.466c-0.337-0.551-0.318-1.19-0.594-1.741
              c-0.471-0.943-1.836-1.092-2.58-1.833c-0.713-0.71-1.337-1.555-2.176-2.096c-0.967-0.627-2.148-0.857-3.222-1.163
              c-0.808-0.229-1.76-0.361-2.464-0.891c-0.618-0.462-0.985-1.27-1.732-1.539c-0.649-0.232-1.304,0.089-1.958-0.095
              c-1.095-0.309-1.891-1.497-2.601-2.372c-0.594-0.731-1.169-1.368-1.995-1.778c-0.588-0.29-1.3-0.349-1.726-0.915
              c-0.906-1.209-0.431-2.797-2.326-2.466c-0.581,0.098-1.144,0.37-1.689-0.012c-0.573-0.401-0.701-1.224-1.255-1.634
              C269.448,291.488,269.42,291.467,269.389,291.449z"/>
          </g>
          <path class="st459" d="M196.387,345.454c-0.129-3.556-3.311-6.475-5.915-5.12C187.867,341.684,195.114,341.806,196.387,345.454z"
            />
          <g>
            <path class="st463" d="M196.803,340.732c-1.521-0.098-2.8-0.578-4.177-1.169c-1.178-0.508-2.702-0.912-3.767,0.058
              c-0.82,0.744-0.514,1.677,0.438,2.001c-1.472,0.526-2.442,2.185-1.35,3.62c1.267,1.665,3.531-0.125,5.135,0.422
              c1.646,0.563,1.738,3.406,3.55,3.519c0.887,0.058,1.769-0.572,2.576-0.851c1.01-0.352,1.995-0.352,3.011-0.024
              c2.001,0.649,3.666,1.674,5.395-0.132c0.627-0.655,1.111-1.438,1.619-2.182c0.278-0.407,0.642-0.811,0.848-1.264
              c0.37-0.814,0.064-1.806-0.046-2.647c-0.141-1.111-0.93-1.013-1.977-1.102C204.313,340.668,200.548,340.971,196.803,340.732z"/>
            <path class="st463" d="M209.937,333.281l-10.325-5.661c-2.326,2.656-5.111,6.497-6.72,10.775c0,0-1.744,3.599,1.181,5.383
              c5.71,3.476,12.804,0.854,12.804,0.854s2.981-2.604,3.054-2.721L209.937,333.281z"/>
            <path class="st457" d="M201.332,343.006c2.35-3.403,4.774-7.338,8.063-10.022l-1.818-0.998c-2.971,3.4-5.236,7.996-6.904,12.014
              C200.885,343.667,201.102,343.336,201.332,343.006z"/>
            <path class="st457" d="M205.754,330.986l-1.049-0.578c-0.729,1.481-1.698,2.901-2.399,4.355
              c-0.943,1.962-1.775,3.987-2.439,6.062C201.482,337.433,203.352,333.893,205.754,330.986z"/>
            <path class="st457" d="M202.758,329.342l-1.585-0.869c-2.522,3.143-4.887,6.674-6.322,10.34
              c-0.073,0.187-0.141,0.37-0.208,0.557C196.784,335.744,199.587,332.256,202.758,329.342z"/>
            <path class="st457" d="M197.418,341.797c1.551-4.168,3.51-8.226,5.897-11.993c-4.079,3.954-7.345,8.272-9.744,13.612
              c0.15,0.125,0.315,0.248,0.502,0.361c0.771,0.471,1.573,0.829,2.378,1.096C196.723,343.835,197.041,342.807,197.418,341.797z"/>
            <path class="st457" d="M204.291,341.947c0.557-1.992,1.304-3.895,2.268-5.719c-0.202,0.248-0.404,0.496-0.6,0.759
              c-2.038,2.724-3.862,5.53-5.312,8.587c1.059,0.021,2.05-0.058,2.916-0.178C203.737,344.233,203.976,343.08,204.291,341.947z"/>
            <path class="st457" d="M209.554,335.484c-1.38,2.231-2.671,4.49-3.326,7.192c-0.184,0.759-0.324,1.521-0.453,2.283
              c0.692-0.18,1.102-0.327,1.102-0.327s0.918-0.805,1.75-1.536C208.633,340.573,209.028,338.033,209.554,335.484z"/>
            <path class="st456" d="M191.815,342.688c-2.118-4.639-7.375-6.123-9.603-2.366C179.984,344.08,188.338,338.792,191.815,342.688z
              "/>
            <path class="st459" d="M204.478,346.308c-1.004-4.639-3.495-6.126-4.553-2.369C198.868,347.697,202.831,342.409,204.478,346.308
              z"/>
            <path class="st457" d="M192.286,339.57c-0.159-2.822-2.577-4.648-4.483-3.155C185.896,337.908,191.276,336.858,192.286,339.57z"
              />
            <path class="st457" d="M193.492,344.016c-0.817-2.522-3.439-3.602-4.813-1.796C187.304,344.025,191.928,341.794,193.492,344.016
              z"/>
            <path class="st456" d="M200.304,345.825c-1.114-2.154-3.874-2.075-5.046,0.333C194.089,348.56,198.474,344.3,200.304,345.825z"
              />
          </g>
          <g>
            <path class="st463" d="M209.233,347.679c-1.524-0.095-2.803-0.575-4.18-1.169c-1.175-0.508-2.705-0.912-3.767,0.058
              c-0.82,0.746-0.511,1.677,0.438,2.001c-1.472,0.53-2.442,2.185-1.347,3.623c1.267,1.659,3.525-0.128,5.132,0.419
              c1.65,0.563,1.738,3.403,3.553,3.519c0.881,0.058,1.766-0.572,2.57-0.854c1.016-0.349,1.995-0.349,3.014-0.018
              c2.005,0.646,3.663,1.671,5.395-0.132c0.631-0.655,1.108-1.438,1.619-2.185c0.279-0.407,0.643-0.811,0.848-1.264
              c0.367-0.814,0.067-1.802-0.043-2.644c-0.147-1.111-0.933-1.016-1.98-1.105C216.743,347.618,212.979,347.917,209.233,347.679z"
              />
            <path class="st463" d="M222.367,340.23l-10.325-5.667c-2.329,2.659-5.117,6.5-6.726,10.778c0,0-1.738,3.602,1.181,5.38
              c5.716,3.482,12.804,0.857,12.804,0.857s2.987-2.601,3.057-2.721L222.367,340.23z"/>
            <path class="st457" d="M213.759,349.956c2.353-3.406,4.777-7.344,8.063-10.022l-1.817-1.001
              c-2.972,3.4-5.233,7.996-6.904,12.017C213.309,350.617,213.53,350.286,213.759,349.956z"/>
            <path class="st457" d="M218.181,337.935l-1.053-0.578c-0.728,1.481-1.695,2.895-2.396,4.352
              c-0.939,1.964-1.772,3.987-2.439,6.065C213.909,344.383,215.782,340.839,218.181,337.935z"/>
            <path class="st457" d="M215.188,336.292l-1.592-0.875c-2.515,3.146-4.884,6.68-6.319,10.343
              c-0.073,0.184-0.141,0.374-0.211,0.557C209.215,342.691,212.015,339.199,215.188,336.292z"/>
            <path class="st457" d="M209.845,348.747c1.555-4.171,3.507-8.226,5.894-11.993c-4.076,3.954-7.341,8.269-9.74,13.609
              c0.15,0.128,0.315,0.248,0.499,0.358c0.777,0.474,1.579,0.829,2.381,1.098C209.154,350.779,209.469,349.754,209.845,348.747z"/>
            <path class="st457" d="M216.718,348.894c0.557-1.992,1.304-3.895,2.271-5.719c-0.202,0.251-0.407,0.499-0.603,0.762
              c-2.041,2.721-3.859,5.53-5.313,8.581c1.059,0.028,2.047-0.055,2.916-0.175C216.168,351.18,216.4,350.029,216.718,348.894z"/>
            <path class="st457" d="M221.982,342.434c-1.383,2.228-2.671,4.489-3.323,7.188c-0.184,0.759-0.327,1.521-0.456,2.283
              c0.694-0.181,1.099-0.327,1.099-0.327s0.924-0.802,1.753-1.536C221.061,347.52,221.456,344.977,221.982,342.434z"/>
            <path class="st456" d="M204.245,349.634c-2.118-4.639-7.378-6.123-9.603-2.366C192.415,351.027,200.763,345.739,204.245,349.634
              z"/>
            <path class="st459" d="M216.908,353.254c-1.004-4.639-3.501-6.123-4.557-2.365C211.296,354.647,215.259,349.356,216.908,353.254
              z"/>
            <path class="st457" d="M204.716,346.519c-0.159-2.824-2.577-4.648-4.486-3.158C198.324,344.854,203.703,343.805,204.716,346.519
              z"/>
            <path class="st457" d="M205.916,350.962c-0.814-2.518-3.437-3.599-4.811-1.796C199.731,350.971,204.355,348.744,205.916,350.962
              z"/>
            <path class="st456" d="M212.734,352.771c-1.114-2.154-3.878-2.075-5.049,0.333C206.516,355.51,210.904,351.247,212.734,352.771z
              "/>
          </g>
          <g>
            <path class="st463" d="M221.602,354.521c-1.521-0.098-2.803-0.575-4.174-1.169c-1.181-0.505-2.705-0.912-3.77,0.058
              c-0.82,0.746-0.511,1.677,0.434,2.004c-1.469,0.526-2.436,2.185-1.346,3.617c1.267,1.665,3.528-0.122,5.135,0.425
              c1.646,0.563,1.738,3.403,3.55,3.519c0.885,0.058,1.769-0.575,2.577-0.854c1.01-0.352,1.992-0.352,3.011-0.021
              c2.001,0.646,3.663,1.674,5.395-0.132c0.627-0.658,1.108-1.435,1.619-2.182c0.279-0.41,0.643-0.814,0.848-1.267
              c0.367-0.811,0.064-1.802-0.046-2.647c-0.141-1.108-0.93-1.013-1.977-1.102C229.112,354.46,225.348,354.763,221.602,354.521z"/>
            <path class="st463" d="M234.739,347.076l-10.325-5.667c-2.329,2.656-5.117,6.497-6.723,10.775c0,0-1.744,3.602,1.181,5.383
              c5.71,3.483,12.801,0.854,12.801,0.854s2.983-2.604,3.057-2.717L234.739,347.076z"/>
            <path class="st457" d="M226.131,356.798c2.35-3.403,4.774-7.341,8.061-10.025l-1.818-0.998c-2.968,3.403-5.23,7.996-6.9,12.017
              C225.685,357.459,225.899,357.126,226.131,356.798z"/>
            <path class="st457" d="M230.553,344.778l-1.053-0.578c-0.725,1.481-1.695,2.898-2.396,4.354
              c-0.943,1.962-1.775,3.987-2.439,6.065C226.278,351.229,228.151,347.682,230.553,344.778z"/>
            <path class="st457" d="M227.557,343.132l-1.585-0.869c-2.519,3.143-4.887,6.677-6.322,10.34c-0.074,0.187-0.141,0.37-0.208,0.56
              C221.584,349.533,224.387,346.045,227.557,343.132z"/>
            <path class="st457" d="M222.214,355.589c1.555-4.171,3.513-8.223,5.897-11.993c-4.073,3.954-7.341,8.269-9.74,13.612
              c0.147,0.125,0.315,0.245,0.502,0.358c0.771,0.471,1.573,0.829,2.375,1.095C221.523,357.624,221.841,356.596,222.214,355.589z"
              />
            <path class="st457" d="M229.087,355.736c0.56-1.992,1.307-3.895,2.274-5.719c-0.205,0.251-0.407,0.502-0.603,0.762
              c-2.038,2.723-3.862,5.53-5.312,8.584c1.059,0.021,2.047-0.055,2.916-0.178C228.536,358.022,228.772,356.871,229.087,355.736z"
              />
            <path class="st457" d="M234.354,349.276c-1.383,2.228-2.672,4.492-3.327,7.191c-0.184,0.756-0.327,1.518-0.453,2.28
              c0.692-0.178,1.099-0.327,1.099-0.327s0.921-0.805,1.754-1.536C233.433,354.362,233.828,351.822,234.354,349.276z"/>
            <path class="st456" d="M216.614,356.477c-2.117-4.639-7.375-6.123-9.603-2.366C204.787,357.869,213.138,352.581,216.614,356.477
              z"/>
            <path class="st459" d="M229.277,360.097c-1.004-4.639-3.498-6.123-4.553-2.365C223.665,361.489,227.628,356.201,229.277,360.097
              z"/>
            <path class="st457" d="M217.085,353.365c-0.159-2.825-2.576-4.652-4.483-3.158C210.693,351.697,216.076,350.647,217.085,353.365
              z"/>
            <path class="st457" d="M218.288,357.808c-0.814-2.522-3.437-3.605-4.814-1.796C212.104,357.814,216.728,355.586,218.288,357.808
              z"/>
            <path class="st456" d="M225.103,359.613c-1.114-2.151-3.877-2.072-5.046,0.334C218.885,362.355,223.273,358.089,225.103,359.613
              z"/>
          </g>
          <g>
            <path class="st463" d="M233.91,361.505c-1.524-0.095-2.803-0.575-4.177-1.166c-1.178-0.511-2.705-0.915-3.767,0.055
              c-0.82,0.746-0.514,1.677,0.435,2.001c-1.469,0.53-2.439,2.185-1.347,3.623c1.267,1.662,3.525-0.128,5.132,0.419
              c1.65,0.563,1.738,3.403,3.553,3.519c0.881,0.058,1.766-0.572,2.574-0.854c1.013-0.349,1.992-0.349,3.014-0.018
              c2.001,0.646,3.663,1.671,5.392-0.132c0.63-0.655,1.111-1.438,1.622-2.185c0.275-0.407,0.642-0.811,0.844-1.264
              c0.367-0.811,0.067-1.802-0.043-2.644c-0.144-1.111-0.93-1.016-1.977-1.105C241.42,361.443,237.656,361.743,233.91,361.505z"/>
            <path class="st463" d="M247.044,354.056l-10.178-5.585c-2.329,2.656-5.26,6.417-6.87,10.695c0,0-1.741,3.602,1.181,5.383
              c5.713,3.48,12.804,0.854,12.804,0.854s2.984-2.601,3.057-2.717L247.044,354.056z"/>
            <path class="st457" d="M238.436,363.781c2.353-3.406,4.777-7.341,8.063-10.022l-1.817-1.001
              c-2.972,3.4-5.233,7.996-6.904,12.017C237.989,364.442,238.207,364.112,238.436,363.781z"/>
            <path class="st457" d="M242.858,351.758l-1.053-0.575c-0.728,1.481-1.695,2.898-2.393,4.352
              c-0.943,1.962-1.775,3.987-2.442,6.065C238.586,358.209,240.459,354.665,242.858,351.758z"/>
            <path class="st457" d="M239.865,350.118l-1.588-0.875c-2.518,3.146-4.887,6.68-6.322,10.343
              c-0.07,0.183-0.138,0.373-0.208,0.557C233.892,356.52,236.695,353.028,239.865,350.118z"/>
            <path class="st457" d="M234.522,362.573c1.555-4.171,3.513-8.226,5.894-11.993c-4.076,3.954-7.338,8.269-9.737,13.609
              c0.147,0.128,0.312,0.248,0.499,0.361c0.774,0.471,1.576,0.826,2.378,1.095C233.831,364.608,234.149,363.582,234.522,362.573z"
              />
            <path class="st457" d="M241.395,362.72c0.557-1.992,1.304-3.896,2.271-5.719c-0.202,0.251-0.407,0.499-0.6,0.762
              c-2.041,2.721-3.862,5.53-5.316,8.581c1.059,0.028,2.05-0.055,2.916-0.175C240.845,365.005,241.077,363.855,241.395,362.72z"/>
            <path class="st457" d="M246.659,356.259c-1.383,2.231-2.671,4.489-3.323,7.188c-0.184,0.759-0.327,1.521-0.456,2.283
              c0.694-0.181,1.101-0.327,1.101-0.327s0.921-0.802,1.754-1.536C245.741,361.346,246.133,358.803,246.659,356.259z"/>
            <path class="st456" d="M228.922,363.46c-2.118-4.636-7.375-6.123-9.603-2.366C217.092,364.852,225.443,359.564,228.922,363.46z"
              />
            <path class="st459" d="M241.585,367.08c-1.004-4.639-3.501-6.123-4.553-2.366C235.973,368.473,239.936,363.185,241.585,367.08z"
              />
            <path class="st457" d="M229.394,360.345c-0.159-2.824-2.577-4.648-4.486-3.158C223.001,358.68,228.38,357.63,229.394,360.345z"
              />
            <path class="st457" d="M230.593,364.788c-0.814-2.518-3.437-3.599-4.811-1.796C224.408,364.797,229.032,362.57,230.593,364.788z
              "/>
            <path class="st456" d="M237.411,366.597c-1.114-2.154-3.878-2.075-5.049,0.333C231.193,369.336,235.581,365.073,237.411,366.597
              z"/>
          </g>
          <g>
            <path class="st463" d="M246.414,368.17c-1.524-0.095-2.803-0.575-4.18-1.169c-1.175-0.508-2.705-0.912-3.767,0.058
              c-0.82,0.747-0.511,1.677,0.437,2.005c-1.469,0.523-2.442,2.185-1.347,3.62c1.267,1.662,3.525-0.128,5.129,0.422
              c1.65,0.56,1.738,3.403,3.556,3.519c0.881,0.055,1.766-0.575,2.57-0.854c1.016-0.352,1.995-0.352,3.017-0.021
              c1.998,0.646,3.66,1.671,5.392-0.132c0.63-0.655,1.108-1.438,1.619-2.185c0.279-0.404,0.646-0.811,0.848-1.261
              c0.367-0.817,0.067-1.806-0.043-2.647c-0.144-1.111-0.933-1.019-1.98-1.105C253.924,368.108,250.16,368.408,246.414,368.17z"/>
            <path class="st463" d="M259.548,360.721l-10.178-5.585c-2.329,2.656-5.261,6.42-6.873,10.695c0,0-1.738,3.602,1.184,5.383
              c5.71,3.479,12.801,0.854,12.801,0.854s2.987-2.601,3.057-2.717L259.548,360.721z"/>
            <path class="st457" d="M250.937,370.443c2.356-3.403,4.78-7.338,8.066-10.019l-1.818-0.997c-2.971,3.4-5.233,7.993-6.904,12.011
              C250.493,371.107,250.71,370.777,250.937,370.443z"/>
            <path class="st457" d="M255.362,358.426l-1.053-0.578c-0.728,1.481-1.695,2.895-2.393,4.351
              c-0.946,1.965-1.775,3.991-2.442,6.065C251.09,364.874,252.963,361.33,255.362,358.426z"/>
            <path class="st457" d="M252.366,356.783l-1.585-0.872c-2.519,3.146-4.887,6.677-6.325,10.34
              c-0.07,0.184-0.135,0.373-0.205,0.557C246.395,363.185,249.196,359.693,252.366,356.783z"/>
            <path class="st457" d="M247.026,369.238c1.551-4.171,3.51-8.226,5.894-11.996c-4.076,3.954-7.341,8.275-9.738,13.615
              c0.147,0.123,0.312,0.245,0.499,0.358c0.774,0.474,1.573,0.826,2.378,1.099C246.331,371.272,246.653,370.244,247.026,369.238z"
              />
            <path class="st457" d="M253.899,369.388c0.557-1.992,1.304-3.899,2.271-5.719c-0.202,0.248-0.41,0.496-0.606,0.759
              c-2.038,2.72-3.859,5.53-5.309,8.584c1.056,0.022,2.047-0.058,2.916-0.177C253.348,371.67,253.581,370.52,253.899,369.388z"/>
            <path class="st457" d="M259.163,362.925c-1.383,2.228-2.672,4.489-3.324,7.188c-0.184,0.759-0.331,1.521-0.456,2.286
              c0.695-0.181,1.099-0.33,1.099-0.33s0.924-0.802,1.754-1.54C258.241,368.014,258.636,365.471,259.163,362.925z"/>
            <path class="st456" d="M241.423,370.128c-2.115-4.639-7.372-6.123-9.6-2.366C229.595,371.52,237.944,366.23,241.423,370.128z"/>
            <path class="st459" d="M254.089,373.742c-1.004-4.639-3.501-6.12-4.557-2.365C248.477,375.135,252.436,369.847,254.089,373.742z
              "/>
            <path class="st457" d="M241.897,367.01c-0.162-2.825-2.577-4.651-4.486-3.158C235.505,365.345,240.881,364.296,241.897,367.01z"
              />
            <path class="st457" d="M243.097,371.453c-0.814-2.522-3.437-3.602-4.811-1.797C236.909,371.462,241.536,369.235,243.097,371.453
              z"/>
            <path class="st456" d="M249.915,373.262c-1.114-2.151-3.88-2.075-5.049,0.334C243.696,376,248.085,371.741,249.915,373.262z"/>
          </g>
        </g>
        <g>
          <polygon class="st455" points="279.362,348.64 279.362,348.508 279.362,348.508 235.018,397.639 244.321,403.591 
            285.241,351.562 			"/>
        </g>
      </g>
      <g>
        <polygon class="st29" points="609.605,444.744 729.182,513.763 814,464.702 693.715,395.638 		"/>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_80_" gradientUnits="userSpaceOnUse" x1="705.7412" y1="453.6241" x2="709.671" y2="480.0098">
            <stop  offset="0.161" style="stop-color:#82C6CF"/>
            <stop  offset="1" style="stop-color:#559CA7"/>
          </linearGradient>
          <path class="st464" d="M745.508,452.996c-6.192-0.818-12.211-2.89-17.615-6.022c-2.365-1.371-3.045-4.356-1.701-6.737
            c0.183-0.324,0.322-0.671,0.407-1.033c0.55-2.35-0.76-4.838-2.68-6.3c-1.92-1.462-4.345-2.08-6.723-2.491
            c-20.223-3.493-40.804-2.357-59.793,4.666c-2.714,1.004-8.807,5.88-8.76,9.039c0.019,1.27,0.635,2.469,1.546,3.353
            c1.988,1.927,4.704,2.34,7.321,2.652c6.451,0.769,13.794,1.609,17.597,6.876c2.224,3.081,2.756,7.163,5.101,10.153
            c3.733,4.76,22.998,13.52,29.01,14.192c19.085,2.133,26.793,2.863,45.369-6.01c3.308-1.58,6.627-6.695,7.886-10.725
            c0.784-2.51-0.156-5.21-2.038-7.046C756.352,453.58,750.744,453.687,745.508,452.996z"/>
          <linearGradient id="SVGID_81_" gradientUnits="userSpaceOnUse" x1="743.9659" y1="462.5516" x2="744.2764" y2="441.7484">
            <stop  offset="0" style="stop-color:#82C6CF"/>
            <stop  offset="1" style="stop-color:#CBDCAF"/>
          </linearGradient>
          <path class="st465" d="M759.609,456.681c-7.561-4.724-15.447-3.003-22.891-5.758c-7.444-2.754-11.108-7.158-11.108-7.158v11.739
            c0,3.226,1.986,6.118,4.999,7.27c4.486,1.714,10.921,3.957,14.657,4.284c6.16,0.54,12.792,3.316,12.788,5.421
            C758.054,472.48,767.17,461.405,759.609,456.681z"/>
          <linearGradient id="SVGID_82_" gradientUnits="userSpaceOnUse" x1="687.3257" y1="442.6084" x2="690.1497" y2="418.4275">
            <stop  offset="0.1392" style="stop-color:#82C6CF"/>
            <stop  offset="1" style="stop-color:#CBDCAF"/>
          </linearGradient>
          <path class="st466" d="M716.2,430.247c-11.461-1.385-23.4-4.294-48.039,1.515c-24.64,5.809-21.075,15.932-16.916,16.25
            l19.589,1.423l52.332-1.897l3.435-8.334C726.6,439.204,727.661,431.632,716.2,430.247z"/>
        </g>
        <g>
          <g>
            <path class="st4" d="M653.696,455.761c-1.088,0.064-0.85-1.343-0.85-1.343h-0.813c0,0,0.155,1.537,0.813,1.76
              c0,0-0.541,0.72-1.565-0.273c-1.023-0.992-0.679-1.856-0.679-1.856h-0.281c0,0-0.264,1.729,0.912,2.671
              c0.706,0.566,1.613,0.768,1.613,0.768s0.529,3.764-0.792,5.277c0,0,0.704,1.008,1.801,0.517c0.639-0.286,0.484,0.135,1.2-0.33
              c0,0-0.4-0.411-0.656-1.562c-0.256-1.151-0.704-4.861,0.064-5.213c0.768-0.353,1.731-1.12,1.825-1.76h-0.673
              C655.615,454.418,654.783,455.697,653.696,455.761z"/>
            <g>
              <path class="st5" d="M658.965,447.484c0,0-0.704-1.797-1.329-2.245c-0.625-0.447-1.365-0.702-2.103-0.917
                c-1.263-0.37-2.646-0.638-3.87-0.154c-0.403,0.159-0.783,0.399-1.213,0.452c-0.244,0.03-0.494-0.001-0.734,0.053
                c-0.371,0.083-0.958,0.725-1.201,1.024c-0.385,0.472-0.607,1.082-0.59,1.694c-0.752-0.069-2.427,1.126-2.633,3.007
                c-0.075,0.687,0.203,1.425,0.762,1.832c0.469,0.342,1.085,0.415,1.664,0.376c-0.432,0.15-0.599,0.706-0.49,1.151
                c0.109,0.445,0.645,2.005,1.917,2.269c3.017,0.626,4.384-0.878,4.384-0.878s0.986,1.082,3.541-0.278
                c0.528-0.281,0.913-1.062,0.652-1.6c0.582-0.033,2.888-0.488,3.13-1.934C661.332,448.459,658.965,447.484,658.965,447.484z"/>
              <path class="st6" d="M657.272,451.285c-0.443,1.798-2.391,2.849-4.087,2.509c-0.739,0.31-1.611,0.384-2.542,0.143
                c-1.261-0.326-2.014-1.239-2.439-2.338c-0.877,0.039-1.743-0.103-2.644-0.567c-0.098-0.05-0.175-0.111-0.259-0.168
                c0.062,0.535,0.321,1.049,0.754,1.364c0.469,0.342,1.085,0.415,1.664,0.376c-0.432,0.15-0.599,0.706-0.49,1.151
                c0.109,0.444,0.645,2.005,1.916,2.269c3.017,0.626,4.384-0.878,4.384-0.878s0.986,1.082,3.541-0.278
                c0.528-0.281,0.913-1.062,0.652-1.6c0.582-0.033,2.888-0.488,3.129-1.934c0.224-1.342-0.177-2.256-0.663-2.864
                C659.696,449.718,658.629,450.604,657.272,451.285z"/>
              <g>
                <path class="st7" d="M658.557,444.498c0,0,0.844-1.001-0.514-2.939c-0.582-0.831-2.582-1.192-2.582-1.192
                  s-0.729-0.766-2.615-0.76c-1.349,0.004-1.902,1.14-1.902,1.14s-3.826,0.217-3.474,3.47c-0.712,0.826-1.32,1.477-1.493,2.554
                  c-0.173,1.076,0.662,2.321,1.671,2.734c1.162,0.477,3.296,0.189,3.296,0.189s0.807,0.929,2.389,0.934
                  c1.93,0.007,2.193-1.49,2.453-1.67c0.099-0.068,2.149,0.077,2.987-0.58c0.763-0.598,1.35-1.418,0.662-2.747
                  C659.177,445.135,658.557,444.498,658.557,444.498z"/>
                <path class="st8" d="M652.845,439.606c-1.349,0.004-1.902,1.14-1.902,1.14s-3.826,0.217-3.474,3.47
                  c-0.177,0.206-0.348,0.4-0.507,0.594c0.089,0.065,0.179,0.123,0.264,0.204c0.449,0.423,1.124,0.638,1.706,0.799
                  c0.191,0.053,0.392,0.072,0.577,0.084c0.387-0.54,1.005-0.921,1.872-0.921c0.316,0,0.592,0.06,0.844,0.149
                  c0.396-0.005,0.802,0.085,1.193,0.318c0.121,0.072,0.264,0.092,0.41,0.102c0.153-0.719,0.592-1.362,1.321-1.665
                  c-0.512-1.177-0.144-2.766,1.138-3.305c-0.46-0.139-0.827-0.209-0.827-0.209S654.732,439.6,652.845,439.606z"/>
                <path class="st9" d="M658.557,444.498c0,0,0.403-0.505,0.179-1.471c-0.25,0.71-0.746,1.296-1.367,1.785
                  c0.046,0.54-0.046,1.105-0.372,1.693c-0.511,0.921-1.37,1.453-2.32,1.638c-0.753,0.663-1.851,0.939-2.997,0.664
                  c-0.343-0.082-0.611-0.228-0.846-0.398c-1.274,0.799-2.795,1.135-4.047,0.495c0.255,0.258,0.546,0.473,0.859,0.601
                  c1.162,0.477,3.296,0.189,3.296,0.189s0.807,0.929,2.389,0.934c1.93,0.007,2.193-1.49,2.453-1.67
                  c0.099-0.068,2.149,0.077,2.987-0.58c0.763-0.598,1.35-1.418,0.662-2.747C659.177,445.135,658.557,444.498,658.557,444.498z"
                  />
              </g>
            </g>
          </g>
          <g>
            <path class="st4" d="M728.544,493.101c-1.088,0.064-0.85-1.343-0.85-1.343h-0.813c0,0,0.155,1.537,0.813,1.76
              c0,0-0.541,0.72-1.565-0.273c-1.023-0.992-0.679-1.856-0.679-1.856h-0.28c0,0-0.264,1.729,0.911,2.671
              c0.706,0.566,1.613,0.768,1.613,0.768s0.529,3.764-0.792,5.277c0,0,0.704,1.008,1.801,0.518c0.639-0.286,0.485,0.135,1.201-0.33
              c0,0-0.4-0.411-0.656-1.562c-0.256-1.152-0.704-4.861,0.064-5.213c0.768-0.353,1.731-1.12,1.825-1.76h-0.674
              C730.463,491.757,729.632,493.037,728.544,493.101z"/>
            <g>
              <path class="st5" d="M733.813,484.824c0,0-0.704-1.797-1.329-2.245c-0.625-0.447-1.365-0.702-2.103-0.917
                c-1.263-0.369-2.646-0.638-3.87-0.154c-0.403,0.159-0.783,0.399-1.213,0.452c-0.244,0.03-0.494-0.001-0.734,0.053
                c-0.371,0.083-0.958,0.725-1.202,1.024c-0.385,0.472-0.607,1.082-0.59,1.694c-0.752-0.069-2.427,1.126-2.633,3.007
                c-0.075,0.687,0.203,1.425,0.762,1.832c0.469,0.342,1.085,0.415,1.664,0.376c-0.432,0.15-0.599,0.706-0.49,1.151
                c0.109,0.445,0.645,2.005,1.916,2.269c3.017,0.626,4.384-0.878,4.384-0.878s0.986,1.082,3.541-0.278
                c0.528-0.281,0.913-1.063,0.652-1.6c0.582-0.033,2.888-0.488,3.13-1.934C736.181,485.799,733.813,484.824,733.813,484.824z"/>
              <path class="st6" d="M732.121,488.625c-0.443,1.798-2.391,2.849-4.087,2.509c-0.739,0.31-1.611,0.384-2.542,0.143
                c-1.26-0.326-2.014-1.239-2.439-2.338c-0.877,0.039-1.743-0.103-2.644-0.567c-0.098-0.05-0.175-0.111-0.259-0.168
                c0.062,0.535,0.321,1.049,0.754,1.364c0.469,0.342,1.085,0.415,1.664,0.376c-0.432,0.15-0.599,0.706-0.49,1.151
                c0.109,0.445,0.645,2.005,1.916,2.269c3.017,0.626,4.384-0.878,4.384-0.878s0.986,1.082,3.541-0.278
                c0.528-0.281,0.913-1.062,0.652-1.6c0.582-0.033,2.888-0.488,3.129-1.934c0.224-1.342-0.177-2.256-0.663-2.864
                C734.544,487.059,733.477,487.944,732.121,488.625z"/>
              <g>
                <path class="st7" d="M733.406,481.837c0,0,0.844-1.001-0.515-2.939c-0.582-0.831-2.582-1.192-2.582-1.192
                  s-0.729-0.766-2.615-0.76c-1.349,0.004-1.902,1.14-1.902,1.14s-3.826,0.217-3.474,3.47c-0.712,0.826-1.32,1.477-1.493,2.554
                  s0.662,2.321,1.671,2.734c1.162,0.477,3.296,0.189,3.296,0.189s0.807,0.929,2.389,0.934c1.93,0.007,2.193-1.49,2.453-1.67
                  c0.099-0.068,2.149,0.077,2.987-0.58c0.763-0.598,1.35-1.418,0.662-2.747C734.025,482.475,733.406,481.837,733.406,481.837z"
                  />
                <path class="st8" d="M727.694,476.946c-1.349,0.004-1.902,1.14-1.902,1.14s-3.826,0.217-3.474,3.47
                  c-0.177,0.206-0.348,0.4-0.507,0.594c0.089,0.065,0.179,0.123,0.265,0.204c0.449,0.423,1.124,0.639,1.706,0.799
                  c0.191,0.053,0.392,0.072,0.577,0.084c0.387-0.54,1.005-0.92,1.872-0.92c0.316,0,0.592,0.06,0.844,0.149
                  c0.397-0.005,0.802,0.085,1.193,0.318c0.121,0.072,0.264,0.092,0.41,0.102c0.153-0.719,0.592-1.362,1.321-1.665
                  c-0.512-1.177-0.144-2.766,1.138-3.305c-0.46-0.139-0.827-0.209-0.827-0.209S729.581,476.94,727.694,476.946z"/>
                <path class="st9" d="M733.406,481.837c0,0,0.403-0.505,0.179-1.471c-0.25,0.71-0.746,1.296-1.367,1.785
                  c0.046,0.539-0.046,1.105-0.372,1.693c-0.511,0.921-1.37,1.453-2.32,1.638c-0.753,0.663-1.851,0.939-2.997,0.664
                  c-0.343-0.082-0.611-0.228-0.846-0.398c-1.274,0.799-2.795,1.135-4.047,0.495c0.255,0.258,0.546,0.473,0.859,0.601
                  c1.162,0.477,3.296,0.189,3.296,0.189s0.807,0.929,2.389,0.934c1.93,0.007,2.193-1.49,2.453-1.67
                  c0.099-0.068,2.149,0.077,2.987-0.58c0.763-0.598,1.35-1.418,0.662-2.747C734.025,482.475,733.406,481.837,733.406,481.837z"
                  />
              </g>
            </g>
          </g>
          <g>
            <path class="st4" d="M741.824,495.721c-0.8,0.047-0.625-0.988-0.625-0.988H740.6c0,0,0.114,1.131,0.599,1.295
              c0,0-0.398,0.529-1.152-0.201c-0.753-0.73-0.5-1.365-0.5-1.365h-0.206c0,0-0.195,1.272,0.671,1.966
              c0.52,0.416,1.187,0.565,1.187,0.565s0.389,2.769-0.583,3.883c0,0,0.518,0.742,1.326,0.381c0.47-0.21,0.356,0.099,0.883-0.243
              c0,0-0.294-0.302-0.483-1.149c-0.188-0.847-0.518-3.576,0.047-3.836c0.565-0.259,1.273-0.824,1.343-1.295h-0.496
              C743.236,494.733,742.624,495.674,741.824,495.721z"/>
            <g>
              <path class="st5" d="M745.701,489.631c0,0-0.518-1.323-0.978-1.652c-0.46-0.329-1.004-0.516-1.547-0.675
                c-0.929-0.272-1.947-0.469-2.847-0.113c-0.296,0.117-0.576,0.293-0.892,0.333c-0.179,0.022-0.364-0.001-0.54,0.039
                c-0.273,0.061-0.705,0.534-0.884,0.753c-0.284,0.347-0.447,0.796-0.434,1.246c-0.553-0.051-1.786,0.828-1.937,2.212
                c-0.055,0.505,0.15,1.049,0.56,1.348c0.345,0.252,0.798,0.306,1.224,0.276c-0.318,0.111-0.441,0.52-0.36,0.847
                c0.08,0.327,0.475,1.476,1.41,1.67c2.22,0.461,3.226-0.646,3.226-0.646s0.726,0.796,2.605-0.205
                c0.388-0.207,0.672-0.782,0.48-1.177c0.428-0.024,2.125-0.359,2.303-1.423C747.443,490.348,745.701,489.631,745.701,489.631z"
                />
              <path class="st6" d="M744.455,492.428c-0.326,1.323-1.759,2.096-3.007,1.846c-0.544,0.228-1.186,0.282-1.871,0.105
                c-0.928-0.24-1.482-0.911-1.794-1.72c-0.646,0.029-1.282-0.075-1.946-0.417c-0.072-0.037-0.129-0.081-0.191-0.123
                c0.046,0.393,0.236,0.772,0.555,1.004c0.345,0.252,0.798,0.306,1.224,0.276c-0.318,0.111-0.441,0.52-0.36,0.847
                c0.08,0.327,0.475,1.476,1.41,1.67c2.22,0.461,3.226-0.646,3.226-0.646s0.726,0.796,2.605-0.205
                c0.388-0.207,0.672-0.782,0.48-1.177c0.428-0.024,2.125-0.359,2.303-1.423c0.165-0.987-0.13-1.66-0.488-2.108
                C746.239,491.275,745.454,491.927,744.455,492.428z"/>
              <g>
                <path class="st7" d="M745.401,487.433c0,0,0.621-0.736-0.379-2.163c-0.428-0.611-1.9-0.877-1.9-0.877
                  s-0.536-0.564-1.924-0.559c-0.992,0.003-1.4,0.838-1.4,0.838s-2.815,0.159-2.556,2.554c-0.524,0.608-0.971,1.087-1.099,1.879
                  c-0.127,0.792,0.487,1.708,1.23,2.012c0.855,0.351,2.425,0.139,2.425,0.139s0.594,0.684,1.758,0.688
                  c1.42,0.005,1.613-1.096,1.805-1.229c0.073-0.05,1.581,0.057,2.198-0.427c0.561-0.44,0.993-1.043,0.487-2.021
                  C745.857,487.902,745.401,487.433,745.401,487.433z"/>
                <path class="st8" d="M741.198,483.834c-0.992,0.003-1.4,0.838-1.4,0.838s-2.815,0.159-2.556,2.553
                  c-0.13,0.151-0.256,0.295-0.373,0.437c0.065,0.048,0.132,0.091,0.195,0.15c0.33,0.311,0.827,0.47,1.255,0.588
                  c0.141,0.039,0.288,0.053,0.424,0.062c0.285-0.398,0.74-0.677,1.378-0.677c0.233,0,0.435,0.044,0.621,0.11
                  c0.292-0.003,0.59,0.063,0.878,0.234c0.089,0.053,0.194,0.068,0.302,0.075c0.113-0.529,0.435-1.003,0.972-1.225
                  c-0.377-0.866-0.106-2.035,0.837-2.432c-0.339-0.102-0.609-0.154-0.609-0.154S742.586,483.83,741.198,483.834z"/>
                <path class="st9" d="M745.401,487.433c0,0,0.296-0.371,0.132-1.083c-0.184,0.522-0.549,0.954-1.006,1.313
                  c0.034,0.397-0.034,0.813-0.274,1.245c-0.376,0.678-1.008,1.069-1.707,1.205c-0.554,0.488-1.362,0.691-2.205,0.489
                  c-0.252-0.061-0.45-0.168-0.623-0.293c-0.938,0.588-2.057,0.835-2.978,0.364c0.187,0.19,0.402,0.348,0.632,0.442
                  c0.855,0.351,2.425,0.139,2.425,0.139s0.594,0.684,1.758,0.688c1.42,0.005,1.613-1.096,1.805-1.229
                  c0.073-0.05,1.581,0.057,2.198-0.427c0.561-0.44,0.993-1.043,0.487-2.021C745.857,487.902,745.401,487.433,745.401,487.433z"
                  />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path class="st17" d="M644.024,428.343c-0.017-0.133-0.053-0.264-0.12-0.387c-0.31-0.566-1.061-0.751-1.677-0.414
                c-0.114,0.062-0.21,0.142-0.298,0.227c-0.341-0.331-0.881-0.421-1.347-0.165c-0.31,0.17-0.504,0.452-0.58,0.758
                c-0.268-0.023-0.55,0.029-0.809,0.171c-0.662,0.363-0.924,1.158-0.585,1.776c-0.616,0.337-0.863,1.07-0.553,1.636
                c0.31,0.566,1.061,0.751,1.677,0.414c0.013-0.007,0.023-0.017,0.036-0.024c0.396,0.419,1.065,0.526,1.629,0.217
                c0.315-0.172,0.536-0.444,0.648-0.746c0.37,0.04,0.761-0.026,1.12-0.223c0.548-0.3,0.89-0.822,0.964-1.372
                c0.107-0.021,0.215-0.048,0.317-0.104c0.494-0.271,0.692-0.858,0.443-1.311C644.718,428.484,644.379,428.329,644.024,428.343z"
                />
              <path class="st18" d="M644.024,428.343c-0.004-0.035-0.025-0.068-0.032-0.103c-0.07,1.035-0.886,2.029-1.829,2.195
                c-0.009,0.001-0.015-0.002-0.024-0.001c-0.025,0.017-0.051,0.039-0.076,0.054c0.035,0.928-1.282,1.394-2.194,1.136
                c-0.301,0.469-0.724,0.765-1.331,0.739c-0.038-0.002-0.07-0.012-0.103-0.021c0.366,0.23,0.863,0.256,1.296,0.019
                c0.013-0.007,0.023-0.017,0.036-0.024c0.396,0.419,1.065,0.526,1.629,0.217c0.315-0.172,0.536-0.444,0.648-0.746
                c0.37,0.04,0.761-0.026,1.12-0.223c0.548-0.3,0.89-0.822,0.964-1.372c0.107-0.021,0.215-0.048,0.317-0.104
                c0.494-0.271,0.692-0.858,0.443-1.311C644.718,428.484,644.379,428.329,644.024,428.343z"/>
            </g>
            <g>
              <path class="st15" d="M649.446,426.869c0.024-0.132,0.029-0.267,0.002-0.405c-0.125-0.633-0.785-1.036-1.474-0.9
                c-0.127,0.025-0.243,0.072-0.352,0.127c-0.226-0.418-0.713-0.667-1.235-0.564c-0.346,0.068-0.617,0.279-0.782,0.548
                c-0.248-0.103-0.533-0.138-0.823-0.081c-0.741,0.147-1.23,0.826-1.094,1.517c-0.689,0.136-1.146,0.76-1.021,1.393
                c0.125,0.633,0.785,1.036,1.474,0.9c0.014-0.003,0.027-0.009,0.041-0.012c0.252,0.519,0.857,0.823,1.488,0.698
                c0.352-0.07,0.644-0.262,0.843-0.517c0.34,0.15,0.733,0.205,1.135,0.125c0.613-0.121,1.096-0.516,1.333-1.018
                c0.109,0.012,0.22,0.019,0.334-0.004c0.552-0.109,0.918-0.609,0.818-1.117C650.065,427.213,649.788,426.963,649.446,426.869z"
                />
              <path class="st16" d="M649.446,426.869c0.006-0.035-0.003-0.072,0-0.108c-0.378,0.966-1.456,1.668-2.405,1.541
                c-0.009-0.001-0.014-0.007-0.023-0.008c-0.029,0.008-0.06,0.021-0.089,0.029c-0.246,0.896-1.643,0.943-2.434,0.422
                c-0.428,0.357-0.921,0.512-1.491,0.303c-0.036-0.013-0.063-0.033-0.092-0.051c0.28,0.33,0.746,0.504,1.23,0.408
                c0.014-0.003,0.027-0.009,0.041-0.012c0.252,0.519,0.857,0.823,1.488,0.698c0.352-0.07,0.644-0.262,0.843-0.517
                c0.34,0.15,0.733,0.205,1.135,0.125c0.613-0.121,1.096-0.516,1.333-1.018c0.109,0.012,0.22,0.019,0.334-0.004
                c0.552-0.109,0.918-0.609,0.818-1.117C650.065,427.213,649.788,426.963,649.446,426.869z"/>
            </g>
            <g>
              <path class="st17" d="M655.167,425.265c0.01-0.133,0-0.269-0.041-0.403c-0.192-0.616-0.89-0.947-1.561-0.739
                c-0.124,0.039-0.234,0.097-0.337,0.164c-0.269-0.392-0.78-0.587-1.288-0.43c-0.337,0.105-0.584,0.343-0.719,0.628
                c-0.258-0.076-0.545-0.081-0.827,0.007c-0.721,0.224-1.136,0.952-0.926,1.625c-0.67,0.208-1.059,0.877-0.867,1.493
                c0.192,0.616,0.89,0.947,1.561,0.738c0.014-0.004,0.026-0.012,0.04-0.016c0.305,0.49,0.94,0.727,1.554,0.537
                c0.343-0.107,0.613-0.329,0.783-0.603c0.354,0.113,0.751,0.126,1.142,0.004c0.597-0.185,1.035-0.629,1.218-1.154
                c0.109,0.001,0.221-0.005,0.332-0.039c0.538-0.167,0.849-0.703,0.695-1.197C655.819,425.541,655.517,425.323,655.167,425.265z"
                />
              <path class="st18" d="M655.167,425.265c0.003-0.036-0.011-0.072-0.011-0.108c-0.274,1.001-1.271,1.813-2.228,1.788
                c-0.009,0-0.015-0.005-0.023-0.006c-0.028,0.011-0.058,0.028-0.086,0.038c-0.15,0.917-1.534,1.112-2.376,0.677
                c-0.388,0.4-0.862,0.606-1.451,0.46c-0.037-0.009-0.066-0.026-0.097-0.041c0.313,0.298,0.795,0.422,1.266,0.276
                c0.014-0.004,0.026-0.012,0.04-0.016c0.305,0.49,0.94,0.727,1.554,0.537c0.343-0.107,0.613-0.329,0.783-0.603
                c0.354,0.113,0.751,0.126,1.142,0.004c0.597-0.185,1.035-0.629,1.218-1.154c0.109,0.001,0.221-0.005,0.332-0.039
                c0.538-0.167,0.849-0.703,0.695-1.197C655.819,425.541,655.517,425.323,655.167,425.265z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st17" d="M755.921,483.297c-0.017-0.133-0.053-0.264-0.12-0.387c-0.31-0.566-1.061-0.751-1.677-0.414
                c-0.114,0.062-0.21,0.142-0.298,0.227c-0.341-0.331-0.881-0.421-1.347-0.165c-0.31,0.17-0.504,0.452-0.58,0.758
                c-0.268-0.023-0.55,0.029-0.809,0.171c-0.662,0.363-0.924,1.158-0.585,1.776c-0.616,0.338-0.863,1.07-0.553,1.636
                c0.31,0.566,1.061,0.751,1.677,0.414c0.013-0.007,0.023-0.017,0.036-0.024c0.396,0.419,1.066,0.526,1.629,0.217
                c0.315-0.173,0.536-0.444,0.648-0.747c0.37,0.04,0.761-0.026,1.12-0.223c0.548-0.3,0.889-0.823,0.964-1.373
                c0.107-0.021,0.215-0.048,0.317-0.104c0.494-0.271,0.692-0.858,0.443-1.311C756.615,483.437,756.275,483.283,755.921,483.297z"
                />
              <path class="st18" d="M755.921,483.297c-0.005-0.035-0.025-0.068-0.032-0.103c-0.07,1.035-0.886,2.029-1.829,2.195
                c-0.009,0.002-0.015-0.002-0.024-0.001c-0.025,0.017-0.051,0.039-0.076,0.054c0.035,0.928-1.282,1.394-2.194,1.136
                c-0.301,0.469-0.724,0.765-1.331,0.739c-0.038-0.002-0.07-0.012-0.103-0.021c0.366,0.23,0.863,0.256,1.296,0.019
                c0.013-0.007,0.023-0.017,0.036-0.024c0.396,0.419,1.066,0.526,1.629,0.217c0.315-0.173,0.536-0.444,0.648-0.747
                c0.37,0.04,0.761-0.026,1.12-0.223c0.548-0.3,0.889-0.823,0.964-1.373c0.107-0.021,0.215-0.048,0.317-0.104
                c0.494-0.271,0.692-0.858,0.443-1.311C756.615,483.437,756.275,483.283,755.921,483.297z"/>
            </g>
            <g>
              <path class="st15" d="M761.342,481.823c0.024-0.132,0.029-0.267,0.002-0.405c-0.125-0.633-0.785-1.036-1.474-0.9
                c-0.127,0.025-0.243,0.072-0.352,0.127c-0.226-0.418-0.713-0.667-1.235-0.564c-0.346,0.069-0.617,0.279-0.782,0.548
                c-0.249-0.103-0.533-0.138-0.823-0.081c-0.741,0.146-1.23,0.826-1.094,1.517c-0.689,0.136-1.146,0.76-1.02,1.393
                c0.125,0.633,0.785,1.036,1.474,0.9c0.014-0.003,0.027-0.009,0.041-0.012c0.252,0.519,0.857,0.823,1.488,0.698
                c0.352-0.07,0.645-0.262,0.843-0.516c0.341,0.149,0.733,0.205,1.135,0.125c0.613-0.121,1.096-0.516,1.333-1.018
                c0.109,0.012,0.22,0.019,0.334-0.004c0.552-0.109,0.918-0.609,0.818-1.117C761.961,482.166,761.684,481.917,761.342,481.823z"
                />
              <path class="st16" d="M761.342,481.823c0.006-0.035-0.003-0.072,0-0.108c-0.378,0.966-1.456,1.668-2.405,1.541
                c-0.009-0.001-0.014-0.007-0.023-0.008c-0.029,0.008-0.06,0.021-0.089,0.029c-0.247,0.896-1.643,0.943-2.434,0.422
                c-0.428,0.357-0.922,0.512-1.491,0.303c-0.036-0.013-0.063-0.033-0.092-0.051c0.28,0.33,0.746,0.504,1.23,0.408
                c0.014-0.003,0.027-0.009,0.041-0.012c0.252,0.519,0.857,0.823,1.488,0.698c0.352-0.07,0.645-0.262,0.843-0.516
                c0.341,0.149,0.733,0.205,1.135,0.125c0.613-0.121,1.096-0.516,1.333-1.018c0.109,0.012,0.22,0.019,0.334-0.004
                c0.552-0.109,0.918-0.609,0.818-1.117C761.961,482.166,761.684,481.917,761.342,481.823z"/>
            </g>
            <g>
              <path class="st17" d="M767.064,480.219c0.01-0.133,0-0.269-0.041-0.403c-0.192-0.616-0.891-0.947-1.561-0.739
                c-0.124,0.039-0.234,0.097-0.337,0.164c-0.269-0.392-0.78-0.587-1.288-0.43c-0.337,0.105-0.584,0.343-0.719,0.628
                c-0.258-0.076-0.545-0.081-0.827,0.007c-0.721,0.224-1.136,0.952-0.926,1.625c-0.671,0.208-1.059,0.877-0.867,1.493
                c0.192,0.616,0.891,0.947,1.561,0.738c0.014-0.004,0.026-0.012,0.04-0.017c0.305,0.49,0.94,0.727,1.553,0.537
                c0.343-0.106,0.613-0.329,0.783-0.603c0.354,0.113,0.751,0.126,1.142,0.004c0.597-0.186,1.035-0.629,1.218-1.154
                c0.109,0.001,0.221-0.005,0.332-0.039c0.537-0.167,0.849-0.703,0.695-1.197C767.716,480.495,767.414,480.277,767.064,480.219z"
                />
              <path class="st18" d="M767.064,480.219c0.003-0.036-0.011-0.072-0.011-0.107c-0.274,1-1.271,1.813-2.228,1.788
                c-0.009,0-0.015-0.005-0.023-0.006c-0.028,0.011-0.058,0.028-0.086,0.038c-0.15,0.917-1.534,1.112-2.376,0.677
                c-0.388,0.4-0.862,0.606-1.451,0.46c-0.037-0.009-0.066-0.026-0.097-0.041c0.313,0.298,0.795,0.422,1.266,0.276
                c0.014-0.004,0.026-0.012,0.04-0.017c0.305,0.49,0.94,0.727,1.553,0.537c0.343-0.106,0.613-0.329,0.783-0.603
                c0.354,0.113,0.751,0.126,1.142,0.004c0.597-0.186,1.035-0.629,1.218-1.154c0.109,0.001,0.221-0.005,0.332-0.039
                c0.537-0.167,0.849-0.703,0.695-1.197C767.716,480.495,767.414,480.277,767.064,480.219z"/>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_83_" gradientUnits="userSpaceOnUse" x1="784.5887" y1="473.4719" x2="801.5618" y2="473.4719">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st467" points="785.144,472.239 784.589,474.705 801.562,474.705 801.006,472.239 				"/>
            <linearGradient id="SVGID_84_" gradientUnits="userSpaceOnUse" x1="791.3378" y1="469.2271" x2="808.3109" y2="469.2271">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st468" points="791.893,467.994 791.338,470.46 808.311,470.46 807.756,467.994 				"/>
            <g>
              <linearGradient id="SVGID_85_" gradientUnits="userSpaceOnUse" x1="781.7903" y1="465.6752" x2="795.4468" y2="465.6752">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st469" points="782.237,464.683 781.79,466.668 795.447,466.668 795,464.683 					"/>
              <g>
                <path class="st4" d="M783.906,458.91c0,0,0.287,0.719-0.566,1.545c-0.852,0.826-1.303,0.227-1.303,0.227
                  c0.548-0.186,0.677-1.465,0.677-1.465h-0.677c0,0,0.198,1.172-0.708,1.118c-0.905-0.053-1.598-1.118-1.598-1.118h-0.561
                  c0.079,0.533,0.88,1.172,1.519,1.465c0.639,0.294,0.266,3.382,0.053,4.34c-0.213,0.959-0.546,1.301-0.546,1.301
                  c0.596,0.387,0.467,0.037,0.999,0.275c0.914,0.409,1.5-0.431,1.5-0.431c-1.1-1.26-0.66-4.394-0.66-4.394
                  s0.755-0.168,1.343-0.639c0.979-0.784,0.759-2.224,0.759-2.224H783.906z"/>
                <g>
                  <path class="st5" d="M786.134,453.366c0.014-0.509-0.17-1.017-0.491-1.41c-0.203-0.248-0.692-0.783-1-0.852
                    c-0.199-0.045-0.408-0.019-0.611-0.044c-0.358-0.044-0.675-0.244-1.01-0.377c-1.019-0.403-2.171-0.18-3.222,0.128
                    c-0.614,0.18-1.23,0.391-1.75,0.764c-0.52,0.372-1.106,1.869-1.106,1.869s-1.971,0.811-1.572,3.206
                    c0.201,1.204,2.121,1.583,2.606,1.61c-0.218,0.448,0.104,1.098,0.543,1.332c2.127,1.133,2.948,0.232,2.948,0.232
                    s1.138,1.252,3.65,0.731c1.058-0.22,1.505-1.519,1.596-1.889c0.091-0.37-0.048-0.833-0.408-0.958
                    c0.482,0.033,0.995-0.028,1.385-0.313c0.465-0.339,0.697-0.954,0.634-1.525C788.154,454.304,786.76,453.309,786.134,453.366z
                    "/>
                  <path class="st6" d="M785.902,456.871c-0.354,0.916-0.981,1.676-2.031,1.947c-0.775,0.2-1.501,0.14-2.117-0.119
                    c-1.411,0.283-3.034-0.592-3.403-2.089c-1.129-0.567-2.018-1.304-2.429-2.344c-0.405,0.506-0.738,1.268-0.552,2.385
                    c0.201,1.204,2.121,1.583,2.606,1.61c-0.218,0.448,0.103,1.098,0.543,1.332c2.127,1.133,2.948,0.232,2.948,0.232
                    s1.138,1.252,3.65,0.731c1.058-0.22,1.505-1.519,1.596-1.889c0.091-0.37-0.048-0.833-0.408-0.958
                    c0.482,0.033,0.995-0.028,1.385-0.313c0.36-0.263,0.576-0.691,0.627-1.136c-0.07,0.047-0.134,0.098-0.216,0.14
                    C787.353,456.785,786.633,456.903,785.902,456.871z"/>
                  <g>
                    <path class="st7" d="M786.514,450.723c0.293-2.709-2.892-2.889-2.892-2.889s-0.461-0.945-1.584-0.949
                      c-1.571-0.005-2.177,0.633-2.177,0.633s-1.665,0.301-2.15,0.992c-1.131,1.614-0.428,2.447-0.428,2.447
                      s-0.516,0.531-0.729,0.943c-0.573,1.107-0.083,1.789,0.551,2.287c0.698,0.547,2.404,0.426,2.487,0.483
                      c0.216,0.149,0.435,1.396,2.042,1.39c1.318-0.005,1.989-0.778,1.989-0.778s1.776,0.24,2.744-0.157
                      c0.84-0.344,1.536-1.38,1.392-2.277C787.613,451.953,787.107,451.411,786.514,450.723z"/>
                    <path class="st8" d="M783.622,447.834c0,0-0.461-0.945-1.584-0.949c-1.571-0.005-2.177,0.633-2.177,0.633
                      s-0.306,0.058-0.689,0.174c1.067,0.449,1.374,1.772,0.947,2.752c0.608,0.252,0.973,0.788,1.1,1.387
                      c0.122-0.009,0.241-0.026,0.341-0.085c0.326-0.193,0.663-0.268,0.994-0.264c0.21-0.074,0.439-0.124,0.703-0.124
                      c0.722,0,1.237,0.316,1.559,0.766c0.154-0.01,0.321-0.026,0.48-0.07c0.485-0.134,1.047-0.314,1.421-0.666
                      c0.071-0.067,0.146-0.115,0.22-0.17c-0.133-0.161-0.275-0.323-0.422-0.494C786.807,448.014,783.622,447.834,783.622,447.834
                      z"/>
                    <path class="st9" d="M783.712,454.214c-0.196,0.141-0.419,0.263-0.705,0.331c-0.954,0.229-1.868-0.001-2.495-0.553
                      c-0.791-0.154-1.506-0.597-1.932-1.364c-0.271-0.489-0.348-0.96-0.31-1.409c-0.517-0.407-0.929-0.895-1.138-1.486
                      c-0.186,0.805,0.149,1.225,0.149,1.225s-0.516,0.531-0.729,0.943c-0.573,1.107-0.084,1.789,0.551,2.287
                      c0.698,0.547,2.404,0.426,2.487,0.483c0.216,0.149,0.435,1.396,2.042,1.39c1.318-0.005,1.989-0.778,1.989-0.778
                      s1.776,0.239,2.744-0.157c0.26-0.107,0.503-0.285,0.715-0.5C786.039,455.159,784.773,454.879,783.712,454.214z"/>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M792.018,463.436c-1.007,0.059-0.787-1.244-0.787-1.244h-0.753c0,0,0.144,1.423,0.753,1.63
                c0,0-0.501,0.667-1.45-0.252c-0.948-0.919-0.629-1.719-0.629-1.719h-0.26c0,0-0.245,1.602,0.844,2.475
                c0.654,0.524,1.494,0.711,1.494,0.711s0.49,3.486-0.734,4.888c0,0,0.396,1.037,1.412,0.583
                c0.592-0.265,1.029-0.197,1.709-0.434c0,0-0.711-0.355-0.948-1.422c-0.237-1.067-0.652-4.502,0.059-4.829
                c0.711-0.326,1.603-1.037,1.69-1.63h-0.624C793.796,462.191,793.025,463.376,792.018,463.436z"/>
              <g>
                <path class="st10" d="M796.899,455.769c0,0-0.652-1.665-1.231-2.079c-0.579-0.415-1.265-0.65-1.948-0.85
                  c-1.17-0.342-2.451-0.591-3.585-0.142c-0.373,0.148-0.725,0.37-1.123,0.419c-0.226,0.028-0.458-0.001-0.68,0.049
                  c-0.343,0.077-0.888,0.672-1.113,0.948c-0.357,0.437-0.562,1.003-0.546,1.569c-0.697-0.064-2.248,1.043-2.439,2.785
                  c-0.07,0.636,0.188,1.32,0.705,1.697c0.435,0.317,1.005,0.385,1.541,0.348c-0.401,0.139-0.555,0.654-0.454,1.066
                  c0.101,0.412,0.598,1.858,1.775,2.102c2.795,0.58,4.061-0.813,4.061-0.813s0.913,1.003,3.28-0.258
                  c0.489-0.26,0.846-0.984,0.604-1.483c0.539-0.03,2.675-0.452,2.899-1.791C799.092,456.671,796.899,455.769,796.899,455.769z"
                  />
                <path class="st11" d="M795.331,459.29c-0.41,1.666-2.215,2.639-3.786,2.324c-0.685,0.288-1.492,0.355-2.355,0.132
                  c-1.168-0.302-1.866-1.147-2.259-2.166c-0.813,0.036-1.614-0.095-2.449-0.525c-0.09-0.047-0.162-0.103-0.24-0.155
                  c0.058,0.495,0.298,0.972,0.698,1.264c0.435,0.317,1.005,0.385,1.541,0.348c-0.401,0.139-0.555,0.654-0.454,1.066
                  c0.101,0.412,0.598,1.858,1.775,2.102c2.795,0.58,4.061-0.813,4.061-0.813s0.914,1.002,3.28-0.258
                  c0.489-0.26,0.846-0.984,0.604-1.482c0.539-0.031,2.675-0.452,2.899-1.792c0.207-1.243-0.164-2.09-0.614-2.653
                  C797.576,457.839,796.588,458.659,795.331,459.29z"/>
                <g>
                  <path class="st12" d="M796.521,453.002c0,0,0.782-0.927-0.477-2.723c-0.539-0.77-2.392-1.104-2.392-1.104
                    s-0.675-0.71-2.423-0.704c-1.249,0.004-1.762,1.056-1.762,1.056s-3.544,0.201-3.218,3.215
                    c-0.659,0.765-1.223,1.368-1.383,2.365c-0.16,0.997,0.614,2.15,1.548,2.533c1.077,0.442,3.053,0.175,3.053,0.175
                    s0.747,0.861,2.213,0.865c1.788,0.006,2.031-1.38,2.272-1.546c0.092-0.063,1.99,0.071,2.767-0.538
                    c0.706-0.554,1.25-1.313,0.613-2.545C797.095,453.593,796.521,453.002,796.521,453.002z"/>
                  <path class="st13" d="M791.23,448.471c-1.249,0.004-1.762,1.056-1.762,1.056s-3.544,0.201-3.218,3.214
                    c-0.164,0.191-0.322,0.371-0.47,0.55c0.082,0.06,0.165,0.114,0.245,0.189c0.415,0.391,1.041,0.591,1.58,0.74
                    c0.177,0.049,0.363,0.067,0.534,0.078c0.358-0.5,0.931-0.853,1.734-0.853c0.293,0,0.548,0.056,0.782,0.138
                    c0.367-0.004,0.743,0.079,1.105,0.294c0.112,0.066,0.245,0.085,0.38,0.095c0.142-0.666,0.548-1.262,1.224-1.543
                    c-0.475-1.09-0.134-2.562,1.054-3.062c-0.426-0.129-0.766-0.194-0.766-0.194S792.978,448.465,791.23,448.471z"/>
                  <path class="st14" d="M796.522,453.002c0,0,0.373-0.468,0.166-1.363c-0.232,0.657-0.691,1.201-1.266,1.653
                    c0.043,0.5-0.043,1.024-0.345,1.568c-0.474,0.853-1.269,1.346-2.149,1.518c-0.698,0.614-1.715,0.87-2.776,0.615
                    c-0.318-0.076-0.566-0.211-0.784-0.368c-1.18,0.74-2.589,1.051-3.748,0.458c0.236,0.239,0.506,0.438,0.795,0.557
                    c0.696,0.285,1.768,0.275,2.439,0.232c0.376-0.024,0.737,0.097,1.054,0.303c0.366,0.237,0.959,0.503,1.773,0.506
                    c1.788,0.006,2.031-1.38,2.272-1.546c0.092-0.063,1.99,0.071,2.767-0.538c0.706-0.554,1.25-1.313,0.613-2.545
                    C797.095,453.593,796.522,453.002,796.522,453.002z"/>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M785.288,468.262c-0.913,0.054-0.714-1.128-0.714-1.128h-0.683c0,0,0.13,1.29,0.683,1.478
                c0,0-0.455,0.604-1.314-0.229c-0.86-0.833-0.57-1.558-0.57-1.558h-0.236c0,0-0.222,1.452,0.766,2.243
                c0.593,0.475,1.355,0.645,1.355,0.645s0.444,3.161-0.665,4.432c0,0,0.358,0.941,1.28,0.528c0.536-0.24,0.933-0.179,1.55-0.394
                c0,0-0.645-0.322-0.86-1.289c-0.215-0.967-0.591-4.082,0.054-4.378c0.645-0.296,1.453-0.941,1.533-1.478h-0.565
                C786.899,467.134,786.201,468.208,785.288,468.262z"/>
              <g>
                <path class="st21" d="M789.713,461.31c0,0-0.591-1.509-1.116-1.885c-0.525-0.376-1.146-0.589-1.766-0.771
                  c-1.061-0.31-2.223-0.536-3.25-0.129c-0.338,0.134-0.657,0.335-1.018,0.38c-0.205,0.025-0.415-0.001-0.616,0.044
                  c-0.311,0.07-0.805,0.609-1.009,0.86c-0.324,0.397-0.51,0.909-0.495,1.422c-0.632-0.058-2.038,0.946-2.211,2.525
                  c-0.063,0.577,0.171,1.197,0.64,1.539c0.394,0.287,0.911,0.349,1.397,0.315c-0.363,0.126-0.503,0.593-0.411,0.967
                  c0.092,0.373,0.542,1.684,1.609,1.906c2.534,0.526,3.682-0.737,3.682-0.737s0.828,0.909,2.974-0.234
                  c0.443-0.236,0.767-0.892,0.548-1.344c0.489-0.028,2.426-0.41,2.628-1.624C791.701,462.129,789.713,461.31,789.713,461.31z"/>
                <path class="st22" d="M788.292,464.503c-0.372,1.51-2.008,2.393-3.432,2.108c-0.621,0.261-1.353,0.322-2.135,0.12
                  c-1.059-0.274-1.691-1.04-2.048-1.964c-0.737,0.033-1.464-0.086-2.221-0.476c-0.082-0.042-0.147-0.093-0.217-0.141
                  c0.052,0.449,0.27,0.881,0.633,1.146c0.394,0.287,0.911,0.349,1.397,0.315c-0.363,0.126-0.503,0.593-0.411,0.967
                  c0.092,0.373,0.542,1.684,1.609,1.906c2.534,0.526,3.682-0.737,3.682-0.737s0.828,0.909,2.974-0.234
                  c0.443-0.236,0.767-0.892,0.548-1.344c0.489-0.028,2.425-0.41,2.628-1.624c0.188-1.127-0.148-1.895-0.557-2.406
                  C790.327,463.187,789.431,463.931,788.292,464.503z"/>
                <g>
                  <path class="st23" d="M789.371,458.802c0,0,0.709-0.841-0.432-2.469c-0.489-0.698-2.169-1.001-2.169-1.001
                    s-0.612-0.644-2.197-0.638c-1.133,0.004-1.597,0.957-1.597,0.957s-3.213,0.182-2.918,2.915
                    c-0.598,0.694-1.109,1.241-1.254,2.145c-0.146,0.904,0.556,1.949,1.404,2.296c0.976,0.4,2.768,0.159,2.768,0.159
                    s0.677,0.78,2.007,0.785c1.621,0.006,1.842-1.251,2.06-1.402c0.083-0.057,1.805,0.065,2.508-0.487
                    c0.64-0.502,1.134-1.191,0.556-2.307C789.891,459.337,789.371,458.802,789.371,458.802z"/>
                  <path class="st24" d="M784.573,454.694c-1.133,0.004-1.598,0.957-1.598,0.957s-3.213,0.182-2.918,2.915
                    c-0.149,0.173-0.292,0.336-0.426,0.498c0.074,0.055,0.15,0.103,0.222,0.171c0.377,0.355,0.944,0.536,1.433,0.671
                    c0.161,0.045,0.329,0.061,0.484,0.071c0.325-0.454,0.844-0.773,1.573-0.773c0.265,0,0.497,0.051,0.709,0.125
                    c0.333-0.004,0.673,0.072,1.002,0.267c0.102,0.06,0.221,0.077,0.344,0.086c0.129-0.604,0.497-1.144,1.11-1.399
                    c-0.43-0.988-0.121-2.323,0.956-2.776c-0.386-0.117-0.695-0.175-0.695-0.175S786.158,454.689,784.573,454.694z"/>
                  <path class="st25" d="M789.371,458.802c0,0,0.338-0.424,0.151-1.236c-0.211,0.596-0.627,1.088-1.148,1.499
                    c0.039,0.453-0.039,0.928-0.312,1.421c-0.429,0.774-1.151,1.221-1.948,1.376c-0.633,0.557-1.555,0.789-2.517,0.558
                    c-0.288-0.069-0.513-0.191-0.711-0.334c-1.07,0.671-2.348,0.953-3.399,0.416c0.214,0.217,0.459,0.397,0.721,0.505
                    c0.631,0.259,1.603,0.249,2.211,0.21c0.341-0.022,0.668,0.088,0.956,0.274c0.332,0.215,0.869,0.456,1.607,0.459
                    c1.621,0.006,1.842-1.251,2.06-1.402c0.083-0.057,1.805,0.065,2.508-0.487c0.64-0.502,1.134-1.191,0.556-2.307
                    C789.891,459.337,789.371,458.802,789.371,458.802z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_86_" gradientUnits="userSpaceOnUse" x1="697.5596" y1="417.0913" x2="714.5327" y2="417.0913">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st470" points="698.115,415.858 697.56,418.325 714.533,418.325 713.977,415.858 				"/>
            <linearGradient id="SVGID_87_" gradientUnits="userSpaceOnUse" x1="704.3088" y1="412.8465" x2="721.2819" y2="412.8465">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st471" points="704.864,411.613 704.309,414.08 721.282,414.08 720.727,411.613 				"/>
            <g>
              <linearGradient id="SVGID_88_" gradientUnits="userSpaceOnUse" x1="694.7612" y1="409.2946" x2="708.4176" y2="409.2946">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st472" points="695.208,408.302 694.761,410.287 708.418,410.287 707.971,408.302 					"/>
              <g>
                <path class="st4" d="M696.877,402.53c0,0,0.287,0.719-0.566,1.545c-0.852,0.826-1.303,0.227-1.303,0.227
                  c0.548-0.186,0.677-1.465,0.677-1.465h-0.677c0,0,0.198,1.172-0.708,1.118c-0.906-0.053-1.598-1.118-1.598-1.118h-0.561
                  c0.078,0.533,0.88,1.172,1.519,1.465c0.639,0.293,0.266,3.381,0.053,4.34c-0.213,0.959-0.546,1.301-0.546,1.301
                  c0.596,0.387,0.468,0.037,1,0.275c0.914,0.409,1.5-0.431,1.5-0.431c-1.1-1.26-0.66-4.394-0.66-4.394s0.755-0.168,1.343-0.639
                  c0.979-0.785,0.759-2.224,0.759-2.224H696.877z"/>
                <g>
                  <path class="st5" d="M699.105,396.986c0.014-0.509-0.17-1.017-0.491-1.41c-0.203-0.248-0.692-0.783-1-0.852
                    c-0.2-0.045-0.408-0.019-0.611-0.044c-0.358-0.044-0.675-0.244-1.01-0.377c-1.019-0.403-2.17-0.18-3.222,0.128
                    c-0.614,0.18-1.23,0.391-1.751,0.764c-0.52,0.372-1.106,1.869-1.106,1.869s-1.971,0.811-1.572,3.206
                    c0.201,1.204,2.121,1.583,2.606,1.61c-0.218,0.448,0.104,1.098,0.543,1.332c2.127,1.133,2.948,0.232,2.948,0.232
                    s1.138,1.252,3.65,0.731c1.059-0.22,1.505-1.519,1.596-1.889c0.091-0.37-0.048-0.833-0.408-0.958
                    c0.482,0.033,0.995-0.028,1.385-0.313c0.465-0.339,0.697-0.954,0.634-1.525C701.125,397.923,699.731,396.928,699.105,396.986
                    z"/>
                  <path class="st6" d="M698.873,400.49c-0.354,0.916-0.981,1.676-2.031,1.947c-0.775,0.2-1.501,0.14-2.116-0.119
                    c-1.412,0.283-3.034-0.592-3.403-2.089c-1.129-0.567-2.018-1.304-2.429-2.344c-0.405,0.506-0.738,1.268-0.552,2.385
                    c0.201,1.204,2.121,1.583,2.606,1.61c-0.218,0.448,0.103,1.098,0.543,1.332c2.127,1.133,2.948,0.232,2.948,0.232
                    s1.138,1.252,3.65,0.731c1.059-0.22,1.505-1.519,1.596-1.889c0.091-0.37-0.048-0.833-0.408-0.958
                    c0.482,0.033,0.995-0.028,1.385-0.313c0.36-0.263,0.575-0.691,0.627-1.136c-0.07,0.047-0.134,0.098-0.216,0.139
                    C700.324,400.405,699.603,400.522,698.873,400.49z"/>
                  <g>
                    <path class="st7" d="M699.485,394.343c0.293-2.709-2.893-2.889-2.893-2.889s-0.461-0.945-1.584-0.949
                      c-1.571-0.005-2.177,0.633-2.177,0.633s-1.665,0.301-2.15,0.992c-1.131,1.614-0.428,2.447-0.428,2.447
                      s-0.516,0.531-0.729,0.943c-0.573,1.107-0.083,1.789,0.551,2.287c0.698,0.547,2.404,0.426,2.487,0.483
                      c0.216,0.149,0.435,1.396,2.042,1.39c1.318-0.005,1.989-0.778,1.989-0.778s1.776,0.24,2.744-0.157
                      c0.84-0.344,1.536-1.38,1.392-2.277C700.584,395.573,700.078,395.031,699.485,394.343z"/>
                    <path class="st8" d="M696.593,391.453c0,0-0.461-0.945-1.584-0.949c-1.571-0.005-2.178,0.633-2.178,0.633
                      s-0.306,0.058-0.689,0.174c1.067,0.449,1.374,1.772,0.947,2.752c0.608,0.252,0.973,0.788,1.1,1.387
                      c0.122-0.009,0.24-0.026,0.341-0.085c0.326-0.193,0.663-0.268,0.993-0.264c0.21-0.074,0.439-0.124,0.703-0.124
                      c0.722,0,1.237,0.316,1.559,0.766c0.154-0.01,0.321-0.026,0.48-0.07c0.485-0.134,1.047-0.314,1.421-0.666
                      c0.072-0.067,0.146-0.115,0.22-0.17c-0.133-0.161-0.275-0.323-0.422-0.494C699.778,391.634,696.593,391.453,696.593,391.453
                      z"/>
                    <path class="st9" d="M696.683,397.833c-0.196,0.141-0.419,0.262-0.705,0.331c-0.954,0.229-1.868-0.001-2.495-0.553
                      c-0.791-0.154-1.506-0.597-1.932-1.364c-0.271-0.489-0.348-0.96-0.31-1.409c-0.517-0.407-0.929-0.895-1.138-1.486
                      c-0.186,0.805,0.149,1.225,0.149,1.225s-0.516,0.531-0.729,0.943c-0.573,1.107-0.084,1.789,0.551,2.287
                      c0.698,0.547,2.404,0.426,2.487,0.483c0.216,0.149,0.435,1.396,2.042,1.39c1.318-0.005,1.989-0.778,1.989-0.778
                      s1.776,0.239,2.744-0.157c0.26-0.107,0.503-0.285,0.715-0.5C699.01,398.778,697.744,398.498,696.683,397.833z"/>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M704.989,407.055c-1.007,0.059-0.787-1.244-0.787-1.244h-0.753c0,0,0.144,1.423,0.753,1.63
                c0,0-0.501,0.667-1.449-0.252c-0.948-0.919-0.629-1.719-0.629-1.719h-0.26c0,0-0.245,1.602,0.844,2.475
                c0.654,0.524,1.494,0.711,1.494,0.711s0.49,3.486-0.734,4.888c0,0,0.396,1.037,1.412,0.583
                c0.592-0.265,1.029-0.197,1.709-0.434c0,0-0.711-0.355-0.948-1.422c-0.237-1.067-0.652-4.502,0.059-4.829
                c0.711-0.326,1.603-1.037,1.69-1.63h-0.624C706.766,405.811,705.996,406.996,704.989,407.055z"/>
              <g>
                <path class="st10" d="M709.87,399.388c0,0-0.652-1.665-1.231-2.079c-0.579-0.415-1.265-0.65-1.948-0.85
                  c-1.17-0.342-2.451-0.591-3.585-0.142c-0.373,0.148-0.725,0.369-1.123,0.419c-0.226,0.028-0.458-0.001-0.68,0.049
                  c-0.343,0.077-0.888,0.672-1.113,0.948c-0.357,0.437-0.562,1.003-0.546,1.569c-0.697-0.064-2.248,1.043-2.439,2.785
                  c-0.07,0.636,0.188,1.32,0.705,1.697c0.435,0.317,1.005,0.385,1.541,0.348c-0.401,0.139-0.555,0.654-0.454,1.066
                  c0.101,0.412,0.598,1.858,1.775,2.102c2.795,0.58,4.061-0.813,4.061-0.813s0.913,1.003,3.28-0.258
                  c0.489-0.26,0.846-0.984,0.604-1.483c0.539-0.03,2.675-0.452,2.899-1.791C712.063,400.291,709.87,399.388,709.87,399.388z"/>
                <path class="st11" d="M708.302,402.909c-0.41,1.666-2.215,2.639-3.786,2.324c-0.684,0.287-1.492,0.355-2.354,0.132
                  c-1.168-0.302-1.866-1.147-2.259-2.166c-0.813,0.036-1.614-0.095-2.449-0.525c-0.09-0.046-0.162-0.103-0.24-0.155
                  c0.058,0.495,0.298,0.972,0.698,1.264c0.435,0.317,1.005,0.385,1.541,0.348c-0.401,0.139-0.555,0.654-0.454,1.066
                  c0.101,0.412,0.597,1.858,1.775,2.102c2.795,0.58,4.061-0.813,4.061-0.813s0.914,1.002,3.28-0.258
                  c0.489-0.26,0.846-0.984,0.604-1.482c0.539-0.031,2.675-0.452,2.899-1.792c0.208-1.243-0.164-2.09-0.614-2.653
                  C710.547,401.458,709.559,402.278,708.302,402.909z"/>
                <g>
                  <path class="st12" d="M709.492,396.622c0,0,0.782-0.927-0.477-2.723c-0.539-0.769-2.392-1.104-2.392-1.104
                    s-0.675-0.71-2.422-0.704c-1.249,0.004-1.762,1.055-1.762,1.055s-3.544,0.201-3.218,3.215
                    c-0.659,0.765-1.223,1.368-1.383,2.365c-0.16,0.997,0.614,2.15,1.548,2.533c1.077,0.442,3.053,0.175,3.053,0.175
                    s0.747,0.86,2.213,0.865c1.788,0.006,2.031-1.38,2.272-1.546c0.092-0.063,1.99,0.071,2.766-0.538
                    c0.706-0.554,1.251-1.313,0.613-2.545C710.066,397.212,709.492,396.622,709.492,396.622z"/>
                  <path class="st13" d="M704.201,392.091c-1.249,0.004-1.762,1.055-1.762,1.055s-3.544,0.201-3.218,3.214
                    c-0.164,0.191-0.322,0.371-0.47,0.55c0.082,0.06,0.166,0.114,0.245,0.189c0.415,0.391,1.041,0.591,1.58,0.74
                    c0.177,0.049,0.363,0.067,0.534,0.078c0.358-0.5,0.931-0.853,1.735-0.853c0.293,0,0.548,0.056,0.782,0.138
                    c0.367-0.004,0.743,0.079,1.105,0.294c0.112,0.066,0.244,0.085,0.38,0.095c0.142-0.666,0.548-1.262,1.224-1.543
                    c-0.474-1.09-0.133-2.562,1.054-3.062c-0.426-0.129-0.767-0.193-0.767-0.193S705.949,392.085,704.201,392.091z"/>
                  <path class="st14" d="M709.492,396.622c0,0,0.373-0.468,0.166-1.363c-0.232,0.657-0.691,1.201-1.266,1.653
                    c0.043,0.5-0.043,1.024-0.344,1.568c-0.474,0.853-1.269,1.346-2.149,1.518c-0.698,0.614-1.715,0.87-2.776,0.615
                    c-0.318-0.076-0.566-0.211-0.784-0.368c-1.181,0.74-2.589,1.051-3.748,0.458c0.236,0.239,0.506,0.438,0.795,0.557
                    c0.696,0.285,1.768,0.275,2.439,0.232c0.376-0.024,0.737,0.097,1.054,0.302c0.366,0.237,0.959,0.503,1.773,0.506
                    c1.788,0.006,2.031-1.38,2.272-1.546c0.091-0.063,1.99,0.071,2.766-0.538c0.706-0.554,1.251-1.313,0.613-2.545
                    C710.066,397.212,709.492,396.622,709.492,396.622z"/>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M698.259,411.881c-0.913,0.054-0.714-1.128-0.714-1.128h-0.683c0,0,0.13,1.29,0.683,1.478
                c0,0-0.455,0.604-1.314-0.229c-0.86-0.833-0.57-1.558-0.57-1.558h-0.235c0,0-0.222,1.452,0.766,2.243
                c0.593,0.475,1.355,0.645,1.355,0.645s0.444,3.161-0.666,4.432c0,0,0.359,0.941,1.28,0.528c0.537-0.24,0.933-0.179,1.55-0.394
                c0,0-0.645-0.322-0.86-1.289c-0.215-0.967-0.591-4.082,0.054-4.378c0.645-0.296,1.453-0.941,1.533-1.478h-0.565
                C699.87,410.753,699.172,411.828,698.259,411.881z"/>
              <g>
                <path class="st21" d="M702.684,404.93c0,0-0.591-1.509-1.116-1.885c-0.525-0.376-1.146-0.589-1.766-0.771
                  c-1.061-0.31-2.223-0.536-3.25-0.129c-0.338,0.134-0.658,0.335-1.018,0.38c-0.205,0.025-0.415-0.001-0.616,0.044
                  c-0.311,0.07-0.805,0.609-1.009,0.86c-0.324,0.397-0.51,0.909-0.495,1.422c-0.632-0.058-2.038,0.946-2.211,2.525
                  c-0.063,0.577,0.171,1.197,0.64,1.539c0.394,0.287,0.911,0.349,1.397,0.316c-0.363,0.126-0.503,0.593-0.411,0.966
                  c0.092,0.373,0.542,1.684,1.609,1.906c2.534,0.526,3.682-0.737,3.682-0.737s0.828,0.909,2.974-0.234
                  c0.443-0.236,0.767-0.892,0.548-1.344c0.489-0.028,2.426-0.41,2.628-1.624C704.672,405.748,702.684,404.93,702.684,404.93z"/>
                <path class="st22" d="M701.263,408.122c-0.372,1.51-2.009,2.393-3.432,2.107c-0.621,0.261-1.353,0.322-2.135,0.12
                  c-1.059-0.274-1.691-1.04-2.048-1.964c-0.737,0.033-1.464-0.086-2.221-0.476c-0.082-0.042-0.147-0.093-0.217-0.141
                  c0.052,0.449,0.27,0.881,0.633,1.146c0.394,0.287,0.911,0.349,1.397,0.315c-0.363,0.126-0.503,0.593-0.411,0.967
                  c0.092,0.373,0.542,1.684,1.609,1.906c2.534,0.526,3.682-0.737,3.682-0.737s0.828,0.909,2.974-0.234
                  c0.443-0.236,0.767-0.892,0.548-1.344c0.489-0.028,2.426-0.41,2.628-1.624c0.188-1.127-0.148-1.895-0.557-2.406
                  C703.298,406.806,702.402,407.55,701.263,408.122z"/>
                <g>
                  <path class="st23" d="M702.342,402.422c0,0,0.709-0.841-0.432-2.469c-0.489-0.698-2.169-1.001-2.169-1.001
                    s-0.612-0.644-2.197-0.638c-1.133,0.003-1.597,0.957-1.597,0.957s-3.213,0.182-2.918,2.915
                    c-0.598,0.694-1.109,1.241-1.254,2.145c-0.145,0.904,0.556,1.949,1.404,2.297c0.976,0.4,2.768,0.159,2.768,0.159
                    s0.677,0.78,2.007,0.785c1.621,0.006,1.842-1.251,2.06-1.402c0.083-0.057,1.805,0.065,2.508-0.487
                    c0.64-0.502,1.134-1.191,0.556-2.307C702.862,402.957,702.342,402.422,702.342,402.422z"/>
                  <path class="st24" d="M697.544,398.314c-1.133,0.004-1.597,0.957-1.597,0.957s-3.213,0.182-2.918,2.915
                    c-0.149,0.173-0.292,0.336-0.426,0.498c0.075,0.055,0.15,0.103,0.222,0.171c0.377,0.355,0.944,0.536,1.433,0.671
                    c0.161,0.045,0.329,0.061,0.484,0.071c0.325-0.454,0.844-0.773,1.573-0.773c0.265,0,0.497,0.05,0.709,0.125
                    c0.333-0.004,0.673,0.072,1.002,0.267c0.102,0.06,0.221,0.077,0.344,0.086c0.129-0.604,0.497-1.144,1.11-1.399
                    c-0.43-0.988-0.121-2.323,0.955-2.776c-0.386-0.117-0.695-0.175-0.695-0.175S699.129,398.308,697.544,398.314z"/>
                  <path class="st25" d="M702.342,402.422c0,0,0.338-0.424,0.151-1.236c-0.21,0.596-0.627,1.088-1.148,1.499
                    c0.039,0.453-0.039,0.928-0.312,1.421c-0.429,0.774-1.151,1.221-1.948,1.376c-0.633,0.557-1.555,0.789-2.517,0.558
                    c-0.288-0.069-0.513-0.191-0.711-0.334c-1.07,0.671-2.348,0.953-3.399,0.416c0.214,0.217,0.459,0.397,0.721,0.505
                    c0.631,0.259,1.603,0.249,2.211,0.21c0.341-0.022,0.668,0.088,0.955,0.274c0.332,0.215,0.869,0.456,1.608,0.459
                    c1.621,0.006,1.842-1.251,2.06-1.402c0.083-0.057,1.805,0.065,2.508-0.487c0.64-0.502,1.134-1.191,0.556-2.307
                    C702.862,402.957,702.342,402.422,702.342,402.422z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_89_" gradientUnits="userSpaceOnUse" x1="680.0591" y1="416.0703" x2="695.796" y2="416.0703">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st473" points="680.574,414.927 680.059,417.214 695.796,417.214 695.281,414.927 				"/>
            <g>
              <linearGradient id="SVGID_90_" gradientUnits="userSpaceOnUse" x1="671.226" y1="428.3822" x2="683.8878" y2="428.3822">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st474" points="671.64,427.462 671.226,429.302 683.888,429.302 683.474,427.462 					"/>
              <g>
                <path class="st4" d="M673.188,422.11c0,0,0.266,0.667-0.524,1.433c-0.79,0.766-1.208,0.21-1.208,0.21
                  c0.508-0.172,0.628-1.358,0.628-1.358h-0.628c0,0,0.183,1.086-0.656,1.037c-0.84-0.049-1.481-1.037-1.481-1.037h-0.52
                  c0.073,0.494,0.816,1.086,1.409,1.358c0.593,0.272,0.247,3.135,0.049,4.024c-0.198,0.889-0.506,1.206-0.506,1.206
                  c0.553,0.359,0.433,0.034,0.927,0.255c0.847,0.379,1.391-0.4,1.391-0.4c-1.02-1.168-0.612-4.074-0.612-4.074
                  s0.7-0.156,1.245-0.593c0.908-0.727,0.704-2.062,0.704-2.062H673.188z"/>
                <g>
                  <path class="st5" d="M675.253,416.97c0.013-0.472-0.158-0.943-0.455-1.307c-0.188-0.23-0.641-0.726-0.927-0.79
                    c-0.185-0.042-0.378-0.017-0.566-0.041c-0.332-0.041-0.625-0.226-0.936-0.349c-0.944-0.374-2.012-0.167-2.987,0.118
                    c-0.569,0.167-1.141,0.363-1.623,0.708c-0.482,0.345-1.026,1.733-1.026,1.733s-1.828,0.752-1.457,2.972
                    c0.186,1.116,1.966,1.468,2.416,1.493c-0.202,0.415,0.096,1.018,0.503,1.235c1.972,1.05,2.733,0.215,2.733,0.215
                    s1.055,1.161,3.384,0.678c0.982-0.204,1.395-1.409,1.479-1.752c0.084-0.343-0.044-0.772-0.378-0.888
                    c0.447,0.031,0.922-0.026,1.284-0.29c0.431-0.314,0.646-0.884,0.588-1.414C677.126,417.839,675.834,416.916,675.253,416.97z"
                    />
                  <path class="st6" d="M675.038,420.219c-0.328,0.849-0.91,1.554-1.883,1.805c-0.718,0.186-1.392,0.129-1.962-0.11
                    c-1.309,0.262-2.813-0.549-3.155-1.937c-1.047-0.526-1.871-1.209-2.252-2.173c-0.375,0.469-0.685,1.175-0.512,2.211
                    c0.186,1.116,1.966,1.467,2.416,1.493c-0.202,0.415,0.096,1.018,0.503,1.235c1.972,1.05,2.733,0.215,2.733,0.215
                    s1.055,1.161,3.384,0.678c0.982-0.204,1.395-1.409,1.479-1.752c0.084-0.343-0.044-0.772-0.378-0.888
                    c0.447,0.031,0.922-0.026,1.284-0.29c0.334-0.243,0.534-0.64,0.582-1.053c-0.065,0.044-0.125,0.091-0.2,0.129
                    C676.383,420.14,675.716,420.249,675.038,420.219z"/>
                  <g>
                    <path class="st7" d="M675.606,414.519c0.271-2.512-2.682-2.679-2.682-2.679s-0.427-0.876-1.468-0.88
                      c-1.457-0.005-2.019,0.587-2.019,0.587s-1.544,0.279-1.993,0.92c-1.049,1.496-0.397,2.269-0.397,2.269
                      s-0.478,0.492-0.676,0.874c-0.531,1.026-0.077,1.659,0.511,2.121c0.647,0.507,2.229,0.395,2.305,0.448
                      c0.201,0.138,0.404,1.294,1.893,1.289c1.222-0.004,1.844-0.721,1.844-0.721s1.647,0.222,2.544-0.146
                      c0.779-0.319,1.424-1.28,1.29-2.111C676.625,415.66,676.155,415.157,675.606,414.519z"/>
                    <path class="st8" d="M672.924,411.84c0,0-0.427-0.876-1.468-0.88c-1.457-0.005-2.019,0.587-2.019,0.587
                      s-0.284,0.054-0.639,0.161c0.99,0.416,1.274,1.643,0.878,2.552c0.563,0.234,0.902,0.731,1.02,1.286
                      c0.113-0.008,0.223-0.024,0.316-0.079c0.302-0.179,0.615-0.249,0.921-0.245c0.195-0.069,0.407-0.115,0.651-0.115
                      c0.67,0,1.147,0.293,1.445,0.71c0.143-0.009,0.297-0.024,0.445-0.065c0.449-0.124,0.971-0.291,1.317-0.617
                      c0.066-0.062,0.136-0.107,0.204-0.157c-0.123-0.149-0.255-0.299-0.391-0.458C675.877,412.008,672.924,411.84,672.924,411.84
                      z"/>
                    <path class="st9" d="M673.008,417.756c-0.182,0.131-0.388,0.243-0.653,0.307c-0.884,0.212-1.732-0.001-2.313-0.513
                      c-0.733-0.143-1.396-0.553-1.791-1.265c-0.251-0.453-0.323-0.89-0.287-1.307c-0.479-0.377-0.862-0.83-1.055-1.378
                      c-0.173,0.746,0.138,1.136,0.138,1.136s-0.478,0.492-0.676,0.874c-0.531,1.026-0.077,1.659,0.511,2.121
                      c0.647,0.507,2.229,0.395,2.306,0.448c0.201,0.138,0.404,1.294,1.893,1.289c1.222-0.004,1.844-0.721,1.844-0.721
                      s1.647,0.222,2.544-0.146c0.242-0.099,0.466-0.265,0.663-0.464C675.166,418.632,673.991,418.372,673.008,417.756z"/>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M680.864,411.152c-0.87,0.051-0.68-1.074-0.68-1.074h-0.651c0,0,0.124,1.229,0.651,1.408
                c0,0-0.433,0.576-1.252-0.218c-0.819-0.794-0.543-1.484-0.543-1.484h-0.224c0,0-0.212,1.383,0.729,2.137
                c0.565,0.452,1.29,0.614,1.29,0.614s0.423,3.01-0.634,4.221c0,0,0.342,0.896,1.219,0.503c0.511-0.229,0.889-0.17,1.476-0.375
                c0,0-0.614-0.307-0.819-1.228c-0.205-0.921-0.563-3.888,0.051-4.17c0.614-0.282,1.384-0.896,1.46-1.408h-0.539
                C682.399,410.078,681.734,411.101,680.864,411.152z"/>
              <g>
                <path class="st10" d="M685.078,404.532c0,0-0.563-1.438-1.063-1.795c-0.5-0.358-1.092-0.561-1.682-0.734
                  c-1.01-0.296-2.117-0.51-3.095-0.123c-0.322,0.127-0.626,0.319-0.97,0.362c-0.195,0.024-0.395-0.001-0.587,0.042
                  c-0.296,0.067-0.766,0.58-0.961,0.819c-0.308,0.378-0.485,0.866-0.472,1.355c-0.602-0.055-1.941,0.901-2.106,2.405
                  c-0.06,0.549,0.163,1.14,0.609,1.465c0.375,0.273,0.868,0.332,1.331,0.3c-0.346,0.12-0.479,0.565-0.392,0.92
                  c0.087,0.355,0.516,1.604,1.533,1.815c2.413,0.501,3.507-0.702,3.507-0.702s0.789,0.866,2.832-0.223
                  c0.422-0.225,0.73-0.85,0.522-1.28c0.466-0.026,2.31-0.39,2.503-1.547C686.972,405.311,685.078,404.532,685.078,404.532z"/>
                <path class="st11" d="M683.725,407.572c-0.354,1.438-1.913,2.279-3.269,2.007c-0.591,0.248-1.289,0.307-2.033,0.114
                  c-1.008-0.261-1.611-0.991-1.951-1.87c-0.702,0.031-1.394-0.082-2.115-0.453c-0.078-0.04-0.14-0.089-0.207-0.134
                  c0.05,0.428,0.257,0.839,0.603,1.091c0.375,0.273,0.868,0.332,1.331,0.3c-0.346,0.12-0.479,0.565-0.392,0.921
                  c0.087,0.355,0.516,1.604,1.533,1.815c2.413,0.501,3.507-0.702,3.507-0.702s0.789,0.866,2.832-0.223
                  c0.422-0.225,0.73-0.85,0.522-1.28c0.466-0.026,2.31-0.39,2.503-1.547c0.179-1.073-0.141-1.805-0.53-2.291
                  C685.663,406.319,684.81,407.027,683.725,407.572z"/>
                <g>
                  <path class="st12" d="M684.753,402.143c0,0,0.675-0.801-0.412-2.351c-0.466-0.664-2.065-0.953-2.065-0.953
                    s-0.583-0.613-2.092-0.608c-1.079,0.003-1.521,0.911-1.521,0.911s-3.06,0.173-2.779,2.776
                    c-0.569,0.661-1.056,1.181-1.194,2.043c-0.139,0.861,0.53,1.856,1.337,2.187c0.93,0.381,2.636,0.151,2.636,0.151
                    s0.645,0.743,1.911,0.747c1.544,0.005,1.754-1.192,1.962-1.335c0.079-0.055,1.718,0.062,2.389-0.464
                    c0.61-0.478,1.08-1.134,0.53-2.197C685.248,402.653,684.753,402.143,684.753,402.143z"/>
                  <path class="st13" d="M680.184,398.231c-1.079,0.003-1.521,0.911-1.521,0.911s-3.06,0.173-2.779,2.776
                    c-0.142,0.164-0.278,0.32-0.406,0.475c0.071,0.052,0.143,0.099,0.212,0.163c0.359,0.338,0.899,0.511,1.365,0.639
                    c0.153,0.042,0.313,0.058,0.461,0.067c0.31-0.432,0.804-0.736,1.498-0.736c0.253,0,0.473,0.048,0.675,0.119
                    c0.317-0.004,0.641,0.068,0.954,0.254c0.097,0.057,0.211,0.074,0.328,0.082c0.122-0.575,0.473-1.09,1.057-1.332
                    c-0.41-0.941-0.115-2.213,0.91-2.644c-0.368-0.111-0.662-0.167-0.662-0.167S681.693,398.226,680.184,398.231z"/>
                  <path class="st14" d="M684.753,402.143c0,0,0.322-0.404,0.143-1.177c-0.2,0.568-0.597,1.037-1.093,1.428
                    c0.037,0.432-0.037,0.884-0.298,1.354c-0.409,0.737-1.096,1.163-1.856,1.31c-0.602,0.53-1.481,0.751-2.397,0.531
                    c-0.274-0.066-0.489-0.182-0.677-0.318c-1.019,0.639-2.236,0.908-3.237,0.396c0.204,0.207,0.437,0.378,0.687,0.481
                    c0.601,0.246,1.527,0.237,2.106,0.2c0.325-0.021,0.636,0.084,0.91,0.261c0.316,0.204,0.828,0.435,1.531,0.437
                    c1.544,0.005,1.754-1.192,1.962-1.335c0.079-0.055,1.719,0.062,2.389-0.464c0.61-0.478,1.08-1.134,0.529-2.197
                    C685.248,402.653,684.753,402.143,684.753,402.143z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_91_" gradientUnits="userSpaceOnUse" x1="633.2686" y1="456.087" x2="649.0053" y2="456.087">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st475" points="633.783,454.943 633.269,457.231 649.005,457.231 648.49,454.943 				"/>
            <g>
              <path class="st4" d="M634.182,450.728c-0.945,0.056-0.738-1.167-0.738-1.167h-0.706c0,0,0.135,1.335,0.706,1.529
                c0,0-0.47,0.625-1.359-0.237c-0.889-0.862-0.59-1.612-0.59-1.612h-0.244c0,0-0.23,1.502,0.792,2.321
                c0.613,0.491,1.401,0.667,1.401,0.667s0.459,3.269-0.688,4.584c0,0,0.371,0.973,1.324,0.547
                c0.555-0.248,0.965-0.185,1.603-0.407c0,0-0.667-0.334-0.889-1.334c-0.222-1-0.611-4.222,0.056-4.528
                c0.667-0.306,1.503-0.973,1.585-1.529h-0.585C635.849,449.561,635.126,450.673,634.182,450.728z"/>
              <g>
                <path class="st27" d="M638.759,443.538c0,0-0.611-1.561-1.154-1.95c-0.543-0.389-1.186-0.61-1.827-0.797
                  c-1.097-0.321-2.299-0.554-3.362-0.133c-0.35,0.138-0.68,0.346-1.053,0.393c-0.212,0.026-0.429-0.001-0.637,0.046
                  c-0.322,0.072-0.832,0.63-1.044,0.889c-0.335,0.41-0.527,0.94-0.512,1.471c-0.654-0.06-2.108,0.978-2.287,2.612
                  c-0.065,0.597,0.177,1.238,0.662,1.592c0.407,0.297,0.942,0.361,1.445,0.326c-0.376,0.13-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.857,0.94,3.076-0.242
                  c0.458-0.244,0.794-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68C640.816,444.385,638.759,443.538,638.759,443.538z"
                  />
                <path class="st22" d="M637.289,446.84c-0.385,1.562-2.077,2.475-3.55,2.18c-0.642,0.27-1.4,0.333-2.208,0.124
                  c-1.095-0.283-1.749-1.076-2.119-2.031c-0.762,0.034-1.514-0.089-2.297-0.492c-0.085-0.044-0.152-0.096-0.225-0.146
                  c0.054,0.465,0.279,0.911,0.655,1.185c0.407,0.297,0.942,0.361,1.445,0.326c-0.376,0.131-0.52,0.613-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.857,0.94,3.076-0.242
                  c0.459-0.244,0.793-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68c0.195-1.166-0.154-1.96-0.576-2.488
                  C639.394,445.479,638.467,446.249,637.289,446.84z"/>
                <g>
                  <path class="st28" d="M638.405,440.944c0,0,0.733-0.87-0.447-2.553c-0.506-0.722-2.243-1.035-2.243-1.035
                    s-0.633-0.666-2.272-0.66c-1.172,0.004-1.652,0.99-1.652,0.99s-3.324,0.188-3.018,3.015
                    c-0.618,0.718-1.147,1.283-1.297,2.218c-0.15,0.935,0.576,2.016,1.452,2.375c1.01,0.414,2.863,0.164,2.863,0.164
                    s0.701,0.807,2.076,0.812c1.677,0.006,1.905-1.294,2.131-1.45c0.086-0.059,1.866,0.067,2.594-0.504
                    c0.662-0.52,1.173-1.232,0.575-2.386C638.943,441.498,638.405,440.944,638.405,440.944z"/>
                  <path class="st24" d="M633.443,436.695c-1.172,0.004-1.652,0.99-1.652,0.99s-3.324,0.188-3.018,3.015
                    c-0.154,0.179-0.302,0.348-0.44,0.516c0.077,0.057,0.155,0.107,0.23,0.177c0.39,0.367,0.976,0.555,1.482,0.694
                    c0.166,0.046,0.34,0.063,0.501,0.073c0.336-0.469,0.873-0.8,1.627-0.8c0.275,0,0.514,0.052,0.733,0.13
                    c0.344-0.004,0.697,0.074,1.037,0.276c0.105,0.062,0.229,0.08,0.356,0.089c0.133-0.625,0.514-1.184,1.148-1.447
                    c-0.445-1.022-0.125-2.403,0.988-2.871c-0.4-0.121-0.719-0.181-0.719-0.181S635.082,436.69,633.443,436.695z"/>
                  <path class="st25" d="M638.405,440.944c0,0,0.35-0.439,0.156-1.278c-0.218,0.617-0.648,1.126-1.187,1.55
                    c0.04,0.469-0.04,0.96-0.323,1.47c-0.444,0.8-1.19,1.262-2.015,1.423c-0.654,0.576-1.608,0.816-2.603,0.577
                    c-0.298-0.072-0.531-0.198-0.735-0.345c-1.107,0.694-2.428,0.986-3.515,0.43c0.221,0.224,0.474,0.411,0.746,0.522
                    c1.01,0.414,2.863,0.164,2.863,0.164s0.701,0.807,2.076,0.812c1.677,0.006,1.905-1.294,2.131-1.45
                    c0.086-0.059,1.867,0.067,2.594-0.504c0.662-0.52,1.173-1.232,0.575-2.386C638.943,441.498,638.405,440.944,638.405,440.944z
                    "/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_92_" gradientUnits="userSpaceOnUse" x1="762.6462" y1="446.3483" x2="778.383" y2="446.3483">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st476" points="763.161,445.205 762.646,447.492 778.383,447.492 777.868,445.205 				"/>
            <g>
              <path class="st4" d="M763.559,440.99c-0.945,0.056-0.738-1.167-0.738-1.167h-0.706c0,0,0.135,1.335,0.706,1.529
                c0,0-0.47,0.625-1.359-0.237c-0.889-0.862-0.59-1.612-0.59-1.612h-0.244c0,0-0.23,1.502,0.792,2.321
                c0.613,0.492,1.401,0.667,1.401,0.667s0.459,3.269-0.688,4.584c0,0,0.371,0.973,1.324,0.547
                c0.555-0.248,0.965-0.185,1.603-0.407c0,0-0.667-0.333-0.889-1.334c-0.222-1-0.611-4.222,0.056-4.528
                c0.667-0.306,1.503-0.973,1.585-1.529h-0.585C765.227,439.823,764.504,440.934,763.559,440.99z"/>
              <g>
                <path class="st27" d="M768.137,433.8c0,0-0.611-1.561-1.154-1.95c-0.543-0.389-1.186-0.61-1.827-0.797
                  c-1.097-0.321-2.299-0.554-3.362-0.133c-0.35,0.138-0.68,0.346-1.053,0.393c-0.212,0.026-0.429-0.001-0.637,0.046
                  c-0.322,0.072-0.833,0.63-1.044,0.889c-0.335,0.41-0.527,0.94-0.512,1.471c-0.654-0.06-2.108,0.978-2.287,2.612
                  c-0.065,0.596,0.177,1.238,0.662,1.591c0.407,0.297,0.942,0.361,1.445,0.326c-0.376,0.13-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.856,0.94,3.076-0.242
                  c0.458-0.244,0.794-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68C770.193,434.646,768.137,433.8,768.137,433.8z"/>
                <path class="st22" d="M766.666,437.102c-0.385,1.562-2.077,2.475-3.55,2.18c-0.642,0.27-1.4,0.333-2.208,0.124
                  c-1.095-0.283-1.749-1.076-2.119-2.031c-0.762,0.034-1.514-0.089-2.297-0.492c-0.085-0.044-0.152-0.096-0.225-0.146
                  c0.054,0.465,0.279,0.911,0.655,1.185c0.408,0.297,0.942,0.361,1.445,0.326c-0.376,0.131-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.856,0.94,3.076-0.242
                  c0.459-0.244,0.793-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68c0.195-1.166-0.154-1.96-0.576-2.488
                  C768.772,435.741,767.845,436.51,766.666,437.102z"/>
                <g>
                  <path class="st28" d="M767.783,431.205c0,0,0.733-0.87-0.447-2.554c-0.506-0.722-2.243-1.035-2.243-1.035
                    s-0.633-0.666-2.272-0.66c-1.172,0.004-1.652,0.99-1.652,0.99s-3.323,0.188-3.018,3.015
                    c-0.618,0.718-1.147,1.283-1.297,2.218s0.576,2.016,1.452,2.375c1.01,0.414,2.863,0.164,2.863,0.164s0.701,0.807,2.076,0.812
                    c1.677,0.006,1.905-1.294,2.131-1.45c0.086-0.059,1.866,0.067,2.594-0.504c0.662-0.52,1.173-1.232,0.575-2.386
                    C768.321,431.759,767.783,431.205,767.783,431.205z"/>
                  <path class="st24" d="M762.821,426.956c-1.172,0.004-1.652,0.99-1.652,0.99s-3.323,0.188-3.018,3.015
                    c-0.154,0.179-0.302,0.348-0.44,0.516c0.077,0.057,0.155,0.107,0.23,0.177c0.39,0.367,0.976,0.555,1.482,0.694
                    c0.166,0.046,0.34,0.063,0.501,0.073c0.336-0.469,0.873-0.8,1.627-0.8c0.275,0,0.514,0.052,0.733,0.13
                    c0.345-0.004,0.697,0.074,1.037,0.276c0.105,0.062,0.229,0.08,0.356,0.089c0.133-0.624,0.514-1.184,1.148-1.447
                    c-0.445-1.022-0.125-2.403,0.988-2.871c-0.4-0.121-0.719-0.181-0.719-0.181S764.46,426.951,762.821,426.956z"/>
                  <path class="st25" d="M767.783,431.205c0,0,0.35-0.439,0.156-1.278c-0.218,0.616-0.648,1.126-1.188,1.55
                    c0.04,0.469-0.04,0.96-0.323,1.47c-0.444,0.8-1.19,1.262-2.015,1.423c-0.654,0.576-1.608,0.816-2.603,0.577
                    c-0.298-0.072-0.531-0.198-0.735-0.345c-1.107,0.694-2.428,0.986-3.515,0.43c0.221,0.224,0.474,0.41,0.746,0.522
                    c1.01,0.414,2.863,0.164,2.863,0.164s0.701,0.807,2.076,0.812c1.677,0.006,1.905-1.294,2.131-1.45
                    c0.086-0.059,1.867,0.067,2.595-0.504c0.662-0.52,1.173-1.232,0.575-2.386C768.321,431.759,767.783,431.205,767.783,431.205z
                    "/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_93_" gradientUnits="userSpaceOnUse" x1="774.658" y1="480.2998" x2="790.3947" y2="480.2998">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st477" points="775.173,479.156 774.658,481.443 790.395,481.443 789.88,479.156 				"/>
            <g>
              <path class="st4" d="M775.571,474.941c-0.945,0.056-0.738-1.167-0.738-1.167h-0.706c0,0,0.135,1.335,0.706,1.529
                c0,0-0.47,0.625-1.359-0.237c-0.889-0.862-0.59-1.612-0.59-1.612h-0.244c0,0-0.23,1.502,0.792,2.321
                c0.613,0.491,1.401,0.667,1.401,0.667s0.459,3.269-0.688,4.584c0,0,0.371,0.973,1.324,0.547
                c0.555-0.248,0.965-0.185,1.603-0.407c0,0-0.667-0.333-0.889-1.334c-0.222-1-0.611-4.222,0.056-4.528
                c0.667-0.306,1.504-0.973,1.585-1.529h-0.585C777.238,473.774,776.516,474.886,775.571,474.941z"/>
              <g>
                <path class="st27" d="M780.148,467.751c0,0-0.611-1.561-1.154-1.95c-0.543-0.389-1.186-0.61-1.827-0.797
                  c-1.097-0.321-2.299-0.554-3.362-0.133c-0.35,0.138-0.68,0.347-1.053,0.393c-0.212,0.026-0.429-0.001-0.637,0.046
                  c-0.322,0.072-0.833,0.63-1.044,0.889c-0.335,0.41-0.527,0.94-0.512,1.471c-0.654-0.06-2.108,0.978-2.287,2.612
                  c-0.065,0.597,0.177,1.238,0.662,1.592c0.407,0.297,0.942,0.361,1.445,0.326c-0.376,0.13-0.52,0.613-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.857,0.94,3.076-0.242
                  c0.458-0.244,0.794-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68C782.205,468.598,780.148,467.751,780.148,467.751z"
                  />
                <path class="st22" d="M778.678,471.053c-0.385,1.562-2.077,2.475-3.55,2.18c-0.642,0.27-1.4,0.333-2.208,0.124
                  c-1.095-0.283-1.749-1.076-2.118-2.031c-0.762,0.034-1.514-0.089-2.297-0.492c-0.085-0.044-0.152-0.096-0.225-0.146
                  c0.054,0.465,0.279,0.911,0.655,1.185c0.408,0.297,0.942,0.361,1.445,0.326c-0.376,0.131-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.857,0.94,3.076-0.242
                  c0.459-0.244,0.793-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.719-1.68c0.195-1.166-0.154-1.96-0.576-2.488
                  C780.783,469.692,779.856,470.462,778.678,471.053z"/>
                <g>
                  <path class="st28" d="M779.794,465.157c0,0,0.733-0.87-0.447-2.554c-0.506-0.721-2.243-1.035-2.243-1.035
                    s-0.633-0.666-2.272-0.66c-1.172,0.004-1.652,0.99-1.652,0.99s-3.324,0.188-3.018,3.015
                    c-0.618,0.718-1.147,1.283-1.297,2.218c-0.15,0.935,0.576,2.016,1.452,2.375c1.01,0.414,2.863,0.164,2.863,0.164
                    s0.701,0.807,2.076,0.812c1.676,0.006,1.905-1.294,2.131-1.45c0.086-0.059,1.866,0.067,2.594-0.504
                    c0.662-0.52,1.173-1.232,0.575-2.386C780.333,465.71,779.794,465.157,779.794,465.157z"/>
                  <path class="st24" d="M774.832,460.908c-1.172,0.004-1.652,0.99-1.652,0.99s-3.324,0.188-3.018,3.015
                    c-0.154,0.179-0.302,0.348-0.44,0.516c0.077,0.057,0.155,0.107,0.23,0.177c0.39,0.367,0.977,0.555,1.482,0.694
                    c0.166,0.046,0.34,0.063,0.501,0.073c0.336-0.469,0.873-0.799,1.627-0.799c0.275,0,0.514,0.052,0.733,0.13
                    c0.344-0.004,0.697,0.074,1.037,0.276c0.105,0.062,0.229,0.08,0.356,0.089c0.133-0.625,0.514-1.184,1.148-1.447
                    c-0.445-1.022-0.125-2.403,0.988-2.871c-0.399-0.121-0.719-0.181-0.719-0.181S776.472,460.902,774.832,460.908z"/>
                  <path class="st25" d="M779.795,465.157c0,0,0.35-0.439,0.156-1.278c-0.218,0.617-0.648,1.126-1.187,1.55
                    c0.04,0.469-0.04,0.96-0.323,1.47c-0.444,0.8-1.19,1.262-2.015,1.423c-0.654,0.576-1.608,0.816-2.603,0.577
                    c-0.298-0.072-0.531-0.198-0.735-0.345c-1.107,0.694-2.428,0.986-3.515,0.43c0.221,0.224,0.474,0.411,0.746,0.522
                    c1.01,0.414,2.863,0.164,2.863,0.164s0.701,0.807,2.076,0.812c1.676,0.006,1.905-1.294,2.131-1.45
                    c0.086-0.059,1.867,0.067,2.594-0.504c0.662-0.52,1.173-1.232,0.575-2.386C780.333,465.71,779.795,465.157,779.795,465.157z"
                    />
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_94_" gradientUnits="userSpaceOnUse" x1="689.4696" y1="484.9333" x2="705.2064" y2="484.9333">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st478" points="689.984,483.79 689.47,486.077 705.206,486.077 704.692,483.79 				"/>
            <g>
              <path class="st4" d="M690.383,479.575c-0.945,0.056-0.738-1.167-0.738-1.167h-0.706c0,0,0.135,1.335,0.706,1.529
                c0,0-0.47,0.625-1.359-0.237c-0.889-0.862-0.59-1.612-0.59-1.612h-0.244c0,0-0.23,1.502,0.792,2.321
                c0.613,0.491,1.401,0.667,1.401,0.667s0.459,3.269-0.688,4.584c0,0,0.371,0.973,1.324,0.547
                c0.555-0.248,0.965-0.185,1.603-0.407c0,0-0.667-0.333-0.889-1.334c-0.222-1-0.611-4.222,0.056-4.528
                c0.667-0.306,1.503-0.973,1.585-1.529h-0.585C692.05,478.408,691.327,479.519,690.383,479.575z"/>
              <g>
                <path class="st27" d="M694.96,472.385c0,0-0.611-1.561-1.154-1.95c-0.543-0.389-1.186-0.61-1.827-0.797
                  c-1.097-0.321-2.299-0.554-3.362-0.133c-0.35,0.138-0.68,0.347-1.054,0.393c-0.212,0.026-0.429-0.001-0.637,0.046
                  c-0.322,0.072-0.832,0.63-1.044,0.889c-0.335,0.41-0.527,0.94-0.512,1.471c-0.654-0.06-2.108,0.978-2.287,2.612
                  c-0.065,0.597,0.177,1.238,0.662,1.592c0.408,0.297,0.942,0.361,1.445,0.326c-0.376,0.13-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.856,0.94,3.076-0.242
                  c0.458-0.244,0.793-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.718-1.68C697.017,473.231,694.96,472.385,694.96,472.385z"/>
                <path class="st22" d="M693.49,475.687c-0.385,1.562-2.077,2.475-3.55,2.18c-0.642,0.27-1.4,0.333-2.208,0.124
                  c-1.095-0.283-1.75-1.076-2.119-2.031c-0.762,0.034-1.514-0.089-2.297-0.492c-0.085-0.044-0.152-0.096-0.225-0.146
                  c0.054,0.464,0.279,0.911,0.655,1.185c0.408,0.297,0.942,0.361,1.445,0.326c-0.376,0.131-0.52,0.614-0.425,1
                  c0.095,0.386,0.56,1.742,1.665,1.971c2.621,0.544,3.809-0.763,3.809-0.763s0.856,0.94,3.076-0.242
                  c0.458-0.244,0.793-0.923,0.567-1.39c0.506-0.029,2.509-0.424,2.718-1.68c0.195-1.166-0.154-1.96-0.576-2.488
                  C695.595,474.326,694.668,475.095,693.49,475.687z"/>
                <g>
                  <path class="st28" d="M694.606,469.79c0,0,0.733-0.87-0.447-2.553c-0.506-0.722-2.243-1.035-2.243-1.035
                    s-0.633-0.666-2.272-0.66c-1.172,0.004-1.652,0.99-1.652,0.99s-3.323,0.188-3.018,3.015
                    c-0.618,0.718-1.147,1.283-1.297,2.218c-0.151,0.935,0.575,2.016,1.452,2.375c1.01,0.414,2.863,0.164,2.863,0.164
                    s0.701,0.807,2.076,0.812c1.677,0.006,1.905-1.294,2.131-1.45c0.086-0.059,1.866,0.067,2.594-0.504
                    c0.662-0.52,1.173-1.232,0.575-2.386C695.144,470.344,694.606,469.79,694.606,469.79z"/>
                  <path class="st24" d="M689.644,465.541c-1.172,0.004-1.652,0.99-1.652,0.99s-3.323,0.188-3.018,3.015
                    c-0.154,0.179-0.302,0.348-0.44,0.516c0.077,0.057,0.155,0.107,0.23,0.177c0.39,0.367,0.976,0.555,1.482,0.694
                    c0.166,0.046,0.34,0.063,0.501,0.073c0.336-0.469,0.873-0.8,1.627-0.8c0.275,0,0.514,0.052,0.733,0.13
                    c0.344-0.004,0.697,0.074,1.037,0.276c0.105,0.062,0.229,0.08,0.356,0.089c0.133-0.624,0.514-1.183,1.148-1.447
                    c-0.445-1.022-0.125-2.403,0.988-2.871c-0.4-0.121-0.719-0.181-0.719-0.181S691.283,465.536,689.644,465.541z"/>
                  <path class="st25" d="M694.606,469.79c0,0,0.35-0.439,0.156-1.278c-0.218,0.616-0.648,1.126-1.187,1.55
                    c0.04,0.469-0.04,0.96-0.323,1.47c-0.444,0.8-1.19,1.263-2.015,1.423c-0.654,0.576-1.608,0.816-2.603,0.577
                    c-0.298-0.072-0.531-0.198-0.735-0.345c-1.107,0.694-2.428,0.986-3.515,0.43c0.221,0.224,0.474,0.41,0.746,0.522
                    c1.01,0.414,2.863,0.164,2.863,0.164s0.701,0.807,2.076,0.812c1.677,0.006,1.905-1.294,2.131-1.45
                    c0.086-0.059,1.867,0.067,2.595-0.504c0.662-0.52,1.173-1.232,0.575-2.386C695.144,470.344,694.606,469.79,694.606,469.79z"
                    />
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_95_" gradientUnits="userSpaceOnUse" x1="619.961" y1="447.329" x2="635.6977" y2="447.329">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st479" points="620.476,446.186 619.961,448.473 635.698,448.473 635.183,446.186 				"/>
            <g>
              <path class="st4" d="M620.703,442.554c-0.847,0.05-0.662-1.046-0.662-1.046h-0.633c0,0,0.121,1.196,0.633,1.37
                c0,0-0.422,0.56-1.219-0.212c-0.797-0.772-0.529-1.445-0.529-1.445h-0.218c0,0-0.206,1.346,0.71,2.08
                c0.55,0.44,1.256,0.598,1.256,0.598s0.412,2.931-0.617,4.109c0,0,0.332,0.872,1.187,0.49c0.497-0.223,0.865-0.166,1.437-0.365
                c0,0-0.598-0.299-0.797-1.196c-0.199-0.896-0.548-3.785,0.05-4.059c0.598-0.275,1.348-0.872,1.421-1.37h-0.524
                C622.197,441.508,621.549,442.505,620.703,442.554z"/>
              <g>
                <path class="st21" d="M624.805,436.109c0,0-0.548-1.399-1.034-1.748c-0.487-0.348-1.063-0.546-1.637-0.714
                  c-0.983-0.288-2.061-0.497-3.013-0.12c-0.314,0.124-0.61,0.311-0.944,0.352c-0.19,0.024-0.385-0.001-0.571,0.041
                  c-0.288,0.065-0.746,0.565-0.935,0.797c-0.3,0.368-0.473,0.843-0.459,1.319c-0.586-0.054-1.89,0.877-2.05,2.341
                  c-0.059,0.535,0.158,1.11,0.593,1.427c0.365,0.266,0.845,0.323,1.296,0.292c-0.337,0.117-0.466,0.55-0.381,0.896
                  c0.085,0.346,0.502,1.562,1.492,1.767c2.349,0.488,3.414-0.684,3.414-0.684s0.768,0.843,2.757-0.217
                  c0.411-0.219,0.711-0.827,0.508-1.246c0.453-0.026,2.249-0.38,2.437-1.506C626.649,436.868,624.805,436.109,624.805,436.109z"
                  />
                <path class="st22" d="M623.488,439.069c-0.345,1.4-1.862,2.218-3.182,1.954c-0.575,0.242-1.255,0.299-1.979,0.111
                  c-0.982-0.254-1.568-0.965-1.899-1.821c-0.683,0.03-1.357-0.08-2.059-0.441c-0.076-0.039-0.136-0.086-0.202-0.13
                  c0.049,0.416,0.25,0.817,0.587,1.062c0.365,0.266,0.845,0.323,1.296,0.292c-0.337,0.117-0.466,0.55-0.381,0.896
                  c0.085,0.346,0.502,1.562,1.492,1.767c2.349,0.488,3.414-0.684,3.414-0.684s0.768,0.843,2.757-0.217
                  c0.411-0.219,0.711-0.827,0.508-1.246c0.453-0.026,2.249-0.38,2.437-1.506c0.174-1.045-0.138-1.757-0.516-2.23
                  C625.375,437.849,624.544,438.539,623.488,439.069z"/>
                <g>
                  <path class="st23" d="M624.488,433.784c0,0,0.657-0.779-0.401-2.289c-0.453-0.647-2.011-0.928-2.011-0.928
                    s-0.567-0.597-2.037-0.592c-1.05,0.003-1.481,0.887-1.481,0.887s-2.979,0.169-2.705,2.702
                    c-0.554,0.643-1.028,1.15-1.163,1.988c-0.135,0.838,0.516,1.807,1.301,2.129c0.905,0.371,2.566,0.147,2.566,0.147
                    s0.628,0.723,1.861,0.727c1.503,0.005,1.708-1.16,1.91-1.3c0.077-0.053,1.673,0.06,2.326-0.452
                    c0.594-0.466,1.051-1.104,0.516-2.139C624.971,434.28,624.488,433.784,624.488,433.784z"/>
                  <path class="st24" d="M620.04,429.975c-1.05,0.003-1.481,0.887-1.481,0.887s-2.979,0.169-2.705,2.702
                    c-0.138,0.16-0.271,0.312-0.395,0.462c0.069,0.051,0.139,0.096,0.206,0.159c0.349,0.329,0.875,0.497,1.329,0.622
                    c0.149,0.041,0.305,0.056,0.449,0.066c0.301-0.421,0.783-0.717,1.458-0.717c0.246,0,0.461,0.047,0.657,0.116
                    c0.309-0.004,0.624,0.066,0.929,0.247c0.094,0.056,0.205,0.072,0.319,0.08c0.119-0.56,0.461-1.061,1.029-1.297
                    c-0.399-0.916-0.112-2.154,0.886-2.574c-0.358-0.108-0.644-0.163-0.644-0.163S621.51,429.97,620.04,429.975z"/>
                  <path class="st25" d="M624.488,433.784c0,0,0.314-0.393,0.14-1.146c-0.195,0.553-0.581,1.009-1.064,1.39
                    c0.036,0.42-0.036,0.861-0.29,1.318c-0.398,0.718-1.067,1.132-1.807,1.276c-0.587,0.516-1.442,0.731-2.333,0.517
                    c-0.267-0.064-0.476-0.178-0.659-0.31c-0.992,0.622-2.177,0.884-3.151,0.385c0.198,0.201,0.425,0.368,0.669,0.468
                    c0.585,0.24,1.486,0.231,2.05,0.195c0.316-0.02,0.62,0.082,0.886,0.254c0.308,0.199,0.806,0.423,1.491,0.426
                    c1.503,0.005,1.707-1.16,1.91-1.3c0.077-0.053,1.673,0.06,2.326-0.452c0.594-0.466,1.051-1.104,0.516-2.139
                    C624.971,434.28,624.488,433.784,624.488,433.784z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_96_" gradientUnits="userSpaceOnUse" x1="709.5087" y1="492.5674" x2="725.2453" y2="492.5674">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st480" points="710.023,491.424 709.509,493.711 725.245,493.711 724.731,491.424 				"/>
            <g>
              <path class="st4" d="M710.25,487.793c-0.847,0.05-0.662-1.046-0.662-1.046h-0.633c0,0,0.121,1.196,0.633,1.37
                c0,0-0.421,0.56-1.219-0.212c-0.797-0.772-0.529-1.445-0.529-1.445h-0.218c0,0-0.206,1.346,0.71,2.08
                c0.55,0.44,1.256,0.598,1.256,0.598s0.412,2.931-0.617,4.109c0,0,0.332,0.872,1.187,0.49c0.498-0.223,0.865-0.166,1.437-0.365
                c0,0-0.598-0.299-0.797-1.195c-0.199-0.896-0.548-3.785,0.05-4.059c0.598-0.275,1.348-0.872,1.421-1.37h-0.524
                C711.745,486.747,711.097,487.743,710.25,487.793z"/>
              <g>
                <path class="st21" d="M714.353,481.347c0,0-0.548-1.4-1.034-1.748c-0.487-0.348-1.063-0.546-1.637-0.714
                  c-0.983-0.288-2.061-0.497-3.013-0.12c-0.314,0.124-0.61,0.311-0.944,0.352c-0.19,0.023-0.385-0.001-0.571,0.041
                  c-0.288,0.065-0.746,0.565-0.936,0.797c-0.3,0.368-0.473,0.843-0.459,1.319c-0.586-0.054-1.89,0.877-2.05,2.341
                  c-0.059,0.535,0.158,1.11,0.593,1.427c0.365,0.266,0.845,0.323,1.295,0.293c-0.337,0.117-0.466,0.55-0.381,0.896
                  c0.085,0.346,0.502,1.562,1.492,1.767c2.349,0.488,3.414-0.683,3.414-0.683s0.768,0.843,2.757-0.217
                  c0.411-0.219,0.711-0.827,0.508-1.246c0.453-0.026,2.249-0.38,2.437-1.506C716.197,482.106,714.353,481.347,714.353,481.347z"
                  />
                <path class="st22" d="M713.035,484.307c-0.345,1.4-1.862,2.218-3.182,1.954c-0.575,0.242-1.255,0.299-1.979,0.111
                  c-0.982-0.254-1.568-0.965-1.899-1.821c-0.683,0.031-1.357-0.08-2.059-0.441c-0.076-0.039-0.136-0.086-0.202-0.13
                  c0.049,0.416,0.25,0.817,0.587,1.062c0.365,0.266,0.845,0.323,1.295,0.293c-0.337,0.117-0.466,0.55-0.381,0.896
                  c0.085,0.346,0.502,1.562,1.492,1.767c2.349,0.488,3.414-0.683,3.414-0.683s0.768,0.843,2.757-0.217
                  c0.411-0.219,0.711-0.827,0.508-1.246c0.453-0.026,2.249-0.38,2.437-1.506c0.174-1.045-0.138-1.757-0.516-2.23
                  C714.922,483.088,714.091,483.777,713.035,484.307z"/>
                <g>
                  <path class="st23" d="M714.036,479.022c0,0,0.657-0.779-0.401-2.289c-0.453-0.647-2.011-0.928-2.011-0.928
                    s-0.567-0.597-2.037-0.592c-1.05,0.003-1.481,0.887-1.481,0.887s-2.979,0.169-2.705,2.702
                    c-0.554,0.643-1.028,1.15-1.163,1.988c-0.135,0.838,0.516,1.807,1.301,2.129c0.905,0.371,2.566,0.147,2.566,0.147
                    s0.628,0.723,1.86,0.727c1.503,0.005,1.708-1.16,1.91-1.3c0.077-0.053,1.673,0.06,2.326-0.452
                    c0.594-0.466,1.051-1.104,0.516-2.139C714.518,479.518,714.036,479.022,714.036,479.022z"/>
                  <path class="st24" d="M709.588,475.213c-1.05,0.003-1.481,0.887-1.481,0.887s-2.979,0.169-2.705,2.702
                    c-0.138,0.16-0.271,0.312-0.395,0.462c0.069,0.051,0.139,0.096,0.206,0.159c0.349,0.329,0.875,0.497,1.328,0.622
                    c0.149,0.041,0.305,0.056,0.449,0.066c0.301-0.421,0.783-0.717,1.458-0.717c0.246,0,0.461,0.047,0.657,0.116
                    c0.309-0.004,0.624,0.066,0.929,0.247c0.094,0.056,0.205,0.072,0.319,0.08c0.119-0.56,0.461-1.061,1.029-1.297
                    c-0.399-0.916-0.112-2.154,0.886-2.574c-0.358-0.108-0.644-0.163-0.644-0.163S711.057,475.208,709.588,475.213z"/>
                  <path class="st25" d="M714.036,479.022c0,0,0.314-0.393,0.14-1.146c-0.195,0.553-0.581,1.009-1.064,1.39
                    c0.036,0.42-0.036,0.861-0.29,1.318c-0.398,0.718-1.067,1.132-1.807,1.276c-0.586,0.516-1.442,0.731-2.333,0.517
                    c-0.267-0.064-0.476-0.178-0.659-0.31c-0.992,0.622-2.177,0.884-3.151,0.385c0.198,0.201,0.425,0.368,0.669,0.468
                    c0.585,0.24,1.486,0.231,2.051,0.195c0.316-0.02,0.62,0.082,0.886,0.254c0.308,0.199,0.806,0.423,1.49,0.426
                    c1.503,0.005,1.707-1.16,1.91-1.3c0.077-0.053,1.673,0.06,2.326-0.452c0.594-0.466,1.051-1.104,0.516-2.139
                    C714.518,479.518,714.036,479.022,714.036,479.022z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path class="st1" d="M933.257,769.342l-66.837,38.588c-5.495,3.173-14.212,2.548-19.469-1.395v0
        c-5.257-3.943-5.064-9.711,0.431-12.884l66.837-38.588c5.495-3.173,14.212-2.548,19.469,1.395l0,0
        C938.945,760.401,938.752,766.17,933.257,769.342z"/>
      <path class="st1" d="M1165.045,632.409l-181.856,105.63c-5.495,3.173-14.212,2.548-19.469-1.395l0,0
        c-5.257-3.943-5.064-9.711,0.431-12.884l181.856-105.63c5.495-3.173,14.212-2.548,19.469,1.395l0,0
        C1170.734,623.467,1170.541,629.236,1165.045,632.409z"/>
      <path class="st1" d="M1267.76,573.055l-46.875,27.478c-5.495,3.173-14.212,2.548-19.469-1.395h0
        c-5.257-3.943-5.064-9.711,0.431-12.884l46.875-27.478c5.495-3.173,14.212-2.548,19.469,1.395l0,0
        C1273.448,564.114,1273.255,569.883,1267.76,573.055z"/>
      <g>
        <g>
          <g>
            <linearGradient id="SVGID_97_" gradientUnits="userSpaceOnUse" x1="1052.9568" y1="680.4055" x2="1082.1279" y2="680.4055">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st481" points="1053.911,678.286 1052.957,682.525 1082.128,682.525 1081.173,678.286 				"/>
            <linearGradient id="SVGID_98_" gradientUnits="userSpaceOnUse" x1="1035.8949" y1="690.9777" x2="1065.066" y2="690.9777">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st482" points="1036.849,688.858 1035.895,693.097 1065.066,693.097 1064.112,688.858 				"/>
            <linearGradient id="SVGID_99_" gradientUnits="userSpaceOnUse" x1="1018.3653" y1="701.2603" x2="1047.5361" y2="701.2603">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st483" points="1019.319,699.141 1018.365,703.38 1047.536,703.38 1046.582,699.141 				"/>
            <linearGradient id="SVGID_100_" gradientUnits="userSpaceOnUse" x1="1000.8214" y1="711.3127" x2="1029.9924" y2="711.3127">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st484" points="1001.776,709.193 1000.821,713.433 1029.992,713.433 1029.038,709.193 				"/>
            <linearGradient id="SVGID_101_" gradientUnits="userSpaceOnUse" x1="983.7879" y1="721.9852" x2="1012.959" y2="721.9852">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st485" points="984.742,719.866 983.788,724.105 1012.959,724.105 1012.005,719.866 				"/>
            <g>
              <g>
                <g>
                  <path class="st4" d="M1051.79,673.996c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L1051.79,673.996z"/>
                </g>
                <g>
                  <path class="st17" d="M1057.198,667.574c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.614-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.607-6.65-2.936,3.08c0.67,9.73,2.628,16.928,6.741,17.859
                    c0,0,5.967-1.569,6.255-9.864C1059.865,658.981,1057.198,667.574,1057.198,667.574z"/>
                </g>
                <g class="st57">
                  <path d="M1053.69,665.699c-0.422,0.021-0.866-0.049-1.323-0.266c-2.534-1.206-4.52-2.9-5.702-5.068
                    c0.74,9.172,2.698,15.877,6.656,16.774c0,0,4.794-1.281,5.988-7.537c-0.064-0.001-0.12,0.011-0.187,0.006
                    C1055.93,669.351,1054.922,668.428,1053.69,665.699z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1037.889,684.555l-2.329-0.187c0,0-0.313,7.27-0.753,8.994c0,0,1.319,1.432,3.277,0
                    C1038.084,693.362,1037.416,686.263,1037.889,684.555z"/>
                </g>
                <g>
                  <path class="st486" d="M1040.163,666.758c0.774-5.074-0.297-7.39-0.297-7.39c-0.342-12.292-2.955-7.447-2.955-7.447
                    c-1.776-6.212-3.344,6.658-3.344,6.658c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                    c0.288,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                    C1043.769,660.108,1040.163,666.758,1040.163,666.758z"/>
                </g>
                <g class="st487">
                  <path d="M1038.42,679.711c-2.444-2.68-2.653-5.321-2.647-8.324c-1.958-3.519-2.651-7.621-2.659-11.804
                    c-3.582,8.523-0.634,18.55-0.634,18.55s-2.667-8.592-2.378-0.3c0.288,8.295,6.255,9.864,6.255,9.864
                    c2.492-0.564,4.188-3.438,5.291-7.77C1040.731,680.646,1039.21,680.578,1038.42,679.711z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1017.492,694.528c0.474,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L1017.492,694.528z"/>
                </g>
                <g>
                  <path class="st17" d="M1022.9,688.105c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.613-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08s2.628,16.927,6.741,17.859
                    c0,0,5.967-1.569,6.255-9.864C1025.567,679.513,1022.9,688.105,1022.9,688.105z"/>
                </g>
                <g class="st57">
                  <path d="M1019.837,682.389c-0.182-3.509-3.069-6.418-3.618-10.225c-0.511-3.54-0.192-7.12,0.412-10.631
                    c-0.522,0.917-0.985,3.108-1.116,7.808c0,0-1.072,2.316-0.298,7.39c0,0-3.606-6.65-2.936,3.08s2.628,16.927,6.741,17.859
                    c0,0,5.039-1.343,6.072-8.015c-0.496,0.019-1.037-0.065-1.632-0.29C1020.502,688.249,1019.978,685.111,1019.837,682.389z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1002.712,704.5l-2.329-0.187c0,0-0.313,7.27-0.753,8.994c0,0,1.319,1.431,3.277,0
                    C1002.907,713.307,1002.238,706.208,1002.712,704.5z"/>
                </g>
                <g>
                  <path class="st486" d="M1004.986,686.703c0.775-5.074-0.297-7.39-0.297-7.39c-0.341-12.292-2.955-7.447-2.955-7.447
                    c-1.776-6.211-3.344,6.658-3.344,6.658c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                    c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                    C1008.592,680.053,1004.986,686.703,1004.986,686.703z"/>
                </g>
                <g class="st487">
                  <path d="M1000.36,691.978c-0.861-1.627-1.618-3.386-2.124-5.108c-0.648-2.203-0.717-4.432-0.571-6.668
                    c-3.159,8.368-0.362,17.875-0.362,17.875s-2.666-8.592-2.378-0.299c0.289,8.295,6.255,9.864,6.255,9.864
                    c1.378-0.312,2.509-1.341,3.436-2.919c-2.54-2.287-4.106-7.135-4.336-9.49C1000.172,694.133,1000.232,693.05,1000.36,691.978
                    z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M982.315,715.06c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L982.315,715.06z"/>
                </g>
                <g>
                  <path class="st17" d="M987.723,708.637c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.614-4.844-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.927,6.741,17.859
                    c0,0,5.966-1.569,6.255-9.864C990.39,700.045,987.723,708.637,987.723,708.637z"/>
                </g>
                <g class="st57">
                  <path d="M980.674,685.079c0.508-1.216,1.041-2.416,1.553-3.592C981.685,681.45,981.061,682.172,980.674,685.079z"/>
                  <path d="M985.75,709.889c-1.806-1.178-1.158-2.09-2.032-3.624c-0.917-1.612-2.914-2.351-3.934-4.066
                    c-1.379-2.32-1.852-4.724-1.766-7.15c-0.683-0.083-1.207,1.041-0.914,5.293c0.67,9.73,2.628,16.927,6.741,17.859
                    c0,0,4.823-1.289,5.998-7.596C988.474,710.89,987.062,710.745,985.75,709.889z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path class="st4" d="M1148.375,618.883c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.276,0,3.276,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L1148.375,618.883z"/>
                </g>
                <g>
                  <path class="st17" d="M1153.784,612.46c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.614-4.844-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.671,9.73,2.628,16.927,6.741,17.859
                    c0,0,5.967-1.569,6.255-9.864C1156.45,603.868,1153.784,612.46,1153.784,612.46z"/>
                </g>
                <g class="st57">
                  <path d="M1150.275,610.586c-0.422,0.021-0.866-0.049-1.323-0.266c-2.534-1.205-4.52-2.9-5.702-5.068
                    c0.74,9.172,2.698,15.877,6.656,16.774c0,0,4.794-1.281,5.988-7.537c-0.064-0.001-0.12,0.011-0.187,0.006
                    C1152.515,614.238,1151.507,613.315,1150.275,610.586z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1134.474,629.442l-2.328-0.186c0,0-0.313,7.271-0.753,8.994c0,0,1.319,1.432,3.276,0
                    C1134.669,638.249,1134.001,631.15,1134.474,629.442z"/>
                </g>
                <g>
                  <path class="st486" d="M1136.748,611.644c0.774-5.073-0.297-7.389-0.297-7.389c-0.342-12.292-2.955-7.448-2.955-7.448
                    c-1.776-6.211-3.344,6.659-3.344,6.659c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                    c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                    C1140.354,604.995,1136.748,611.644,1136.748,611.644z"/>
                </g>
                <g class="st487">
                  <path d="M1135.005,624.598c-2.443-2.68-2.653-5.321-2.647-8.324c-1.958-3.519-2.651-7.621-2.659-11.805
                    c-3.582,8.523-0.634,18.55-0.634,18.55s-2.667-8.593-2.378-0.3c0.289,8.295,6.255,9.864,6.255,9.864
                    c2.492-0.564,4.188-3.437,5.291-7.77C1137.316,625.533,1135.796,625.465,1135.005,624.598z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1114.077,639.414c0.474,1.708-0.195,8.807-0.195,8.807c1.958,1.431,3.277,0,3.277,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L1114.077,639.414z"/>
                </g>
                <g>
                  <path class="st17" d="M1119.486,632.992c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.613-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.39c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.928,6.741,17.859
                    c0,0,5.967-1.569,6.255-9.864C1122.152,624.399,1119.486,632.992,1119.486,632.992z"/>
                </g>
                <g class="st57">
                  <path d="M1116.422,627.276c-0.182-3.508-3.069-6.418-3.617-10.225c-0.511-3.54-0.192-7.12,0.412-10.632
                    c-0.522,0.918-0.985,3.108-1.116,7.809c0,0-1.072,2.316-0.298,7.39c0,0-3.606-6.65-2.936,3.08
                    c0.67,9.73,2.628,16.927,6.741,17.859c0,0,5.039-1.343,6.072-8.015c-0.496,0.019-1.037-0.065-1.632-0.29
                    C1117.087,633.136,1116.563,629.998,1116.422,627.276z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1099.297,649.387l-2.329-0.187c0,0-0.313,7.271-0.753,8.994c0,0,1.319,1.432,3.277,0
                    C1099.492,658.194,1098.824,651.095,1099.297,649.387z"/>
                </g>
                <g>
                  <path class="st486" d="M1101.571,631.589c0.775-5.073-0.297-7.389-0.297-7.389c-0.342-12.292-2.955-7.448-2.955-7.448
                    c-1.776-6.211-3.344,6.659-3.344,6.659c-4.276,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                    c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.931,6.071-8.129,6.741-17.859
                    C1105.177,624.94,1101.571,631.589,1101.571,631.589z"/>
                </g>
                <g class="st487">
                  <path d="M1096.945,636.865c-0.86-1.627-1.618-3.386-2.124-5.108c-0.648-2.203-0.717-4.432-0.571-6.668
                    c-3.159,8.368-0.362,17.875-0.362,17.875s-2.667-8.592-2.378-0.299c0.289,8.295,6.255,9.864,6.255,9.864
                    c1.378-0.312,2.509-1.341,3.436-2.919c-2.54-2.286-4.106-7.135-4.337-9.49
                    C1096.757,639.02,1096.817,637.937,1096.945,636.865z"/>
                </g>
              </g>
              <g>
                <g>
                  <path class="st4" d="M1078.899,659.946c0.474,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.276,0,3.276,0
                    c-0.44-1.723-0.753-8.994-0.753-8.994L1078.899,659.946z"/>
                </g>
                <g>
                  <path class="st17" d="M1084.308,653.524c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                    c0,0-2.614-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.607-6.65-2.936,3.08c0.67,9.73,2.628,16.927,6.741,17.859
                    c0,0,5.967-1.569,6.255-9.864C1086.975,644.931,1084.308,653.524,1084.308,653.524z"/>
                </g>
                <g class="st57">
                  <path d="M1077.259,629.966c0.508-1.216,1.041-2.416,1.553-3.592C1078.27,626.337,1077.646,627.058,1077.259,629.966z"/>
                  <path d="M1082.335,654.775c-1.806-1.178-1.158-2.09-2.031-3.625c-0.917-1.612-2.914-2.351-3.934-4.066
                    c-1.379-2.32-1.852-4.723-1.766-7.15c-0.683-0.083-1.207,1.041-0.914,5.293c0.67,9.73,2.628,16.927,6.741,17.859
                    c0,0,4.823-1.289,5.998-7.596C1085.059,655.777,1083.647,655.631,1082.335,654.775z"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <linearGradient id="SVGID_102_" gradientUnits="userSpaceOnUse" x1="1246.104" y1="577.5351" x2="1275.2749" y2="577.5351">
              <stop  offset="0" style="stop-color:#000000"/>
              <stop  offset="1" style="stop-color:#FFFFFF"/>
            </linearGradient>
            <polygon class="st488" points="1247.058,575.415 1246.104,579.655 1275.275,579.655 1274.321,575.415 				"/>
            <g>
              <path class="st4" d="M1221.211,578.088c-1.751,0.103-1.369-2.163-1.369-2.163h-1.31c0,0,0.25,2.474,1.31,2.834
                c0,0-0.872,1.159-2.52-0.439c-1.648-1.598-1.094-2.988-1.094-2.988h-0.452c0,0-0.426,2.784,1.468,4.302
                c1.137,0.911,2.597,1.236,2.597,1.236s0.851,6.06-1.276,8.498c0,0,0.688,1.803,2.454,1.013c1.029-0.46,1.79-0.343,2.971-0.755
                c0,0-1.236-0.618-1.648-2.472c-0.412-1.854-1.133-7.827,0.103-8.394c1.236-0.568,2.787-1.804,2.938-2.834h-1.084
                C1224.301,575.925,1222.962,577.985,1221.211,578.088z"/>
              <g>
                <path class="st5" d="M1229.695,564.76c0,0-1.133-2.894-2.139-3.614c-1.006-0.72-2.198-1.13-3.386-1.477
                  c-2.033-0.595-4.261-1.027-6.231-0.247c-0.648,0.257-1.261,0.642-1.953,0.728c-0.392,0.049-0.796-0.002-1.181,0.085
                  c-0.597,0.134-1.543,1.168-1.935,1.648c-0.62,0.76-0.977,1.743-0.949,2.727c-1.211-0.112-3.908,1.813-4.239,4.841
                  c-0.121,1.106,0.327,2.295,1.226,2.95c0.755,0.55,1.746,0.669,2.679,0.605c-0.696,0.242-0.964,1.137-0.789,1.853
                  c0.176,0.716,1.039,3.229,3.086,3.654c4.859,1.008,7.06-1.413,7.06-1.413s1.588,1.743,5.701-0.448
                  c0.85-0.453,1.471-1.711,1.05-2.577c0.937-0.053,4.651-0.786,5.039-3.114C1233.508,566.33,1229.695,564.76,1229.695,564.76z"
                  />
                <path class="st6" d="M1226.97,570.881c-0.713,2.896-3.851,4.587-6.581,4.041c-1.19,0.5-2.595,0.618-4.094,0.23
                  c-2.03-0.525-3.243-1.995-3.927-3.766c-1.413,0.063-2.806-0.165-4.258-0.912c-0.157-0.081-0.281-0.178-0.417-0.27
                  c0.1,0.861,0.517,1.689,1.213,2.197c0.755,0.55,1.747,0.669,2.679,0.605c-0.696,0.242-0.964,1.137-0.788,1.853
                  c0.176,0.716,1.038,3.229,3.086,3.654c4.859,1.008,7.06-1.414,7.06-1.414s1.588,1.743,5.701-0.448
                  c0.85-0.453,1.471-1.711,1.05-2.577c0.937-0.053,4.651-0.786,5.039-3.114c0.361-2.161-0.285-3.633-1.067-4.612
                  C1230.873,568.358,1229.154,569.785,1226.97,570.881z"/>
                <g>
                  <path class="st7" d="M1229.039,559.951c0,0,1.359-1.612-0.828-4.733c-0.938-1.337-4.158-1.919-4.158-1.919
                    s-1.173-1.234-4.211-1.224c-2.172,0.007-3.063,1.835-3.063,1.835s-6.161,0.349-5.594,5.588
                    c-1.146,1.33-2.126,2.378-2.405,4.112c-0.279,1.734,1.067,3.737,2.691,4.403c1.871,0.768,5.307,0.304,5.307,0.304
                    s1.299,1.496,3.847,1.505c3.108,0.011,3.531-2.399,3.95-2.688c0.159-0.11,3.46,0.124,4.809-0.934
                    c1.228-0.963,2.174-2.283,1.066-4.423C1230.037,560.977,1229.039,559.951,1229.039,559.951z"/>
                  <path class="st8" d="M1219.841,552.075c-2.172,0.007-3.063,1.835-3.063,1.835s-6.161,0.349-5.594,5.588
                    c-0.285,0.331-0.56,0.644-0.817,0.956c0.143,0.105,0.288,0.198,0.426,0.328c0.722,0.681,1.81,1.028,2.747,1.287
                    c0.308,0.085,0.631,0.117,0.929,0.136c0.623-0.87,1.619-1.482,3.015-1.482c0.509,0,0.953,0.097,1.359,0.24
                    c0.639-0.008,1.291,0.138,1.922,0.511c0.195,0.116,0.425,0.148,0.66,0.165c0.247-1.158,0.953-2.194,2.128-2.682
                    c-0.825-1.895-0.232-4.454,1.832-5.322c-0.741-0.224-1.332-0.336-1.332-0.336S1222.88,552.065,1219.841,552.075z"/>
                  <path class="st9" d="M1229.04,559.951c0,0,0.649-0.813,0.289-2.37c-0.404,1.143-1.201,2.087-2.201,2.874
                    c0.074,0.869-0.074,1.78-0.599,2.725c-0.823,1.484-2.206,2.34-3.736,2.638c-1.213,1.067-2.981,1.512-4.825,1.07
                    c-0.552-0.133-0.984-0.367-1.363-0.64c-2.052,1.286-4.501,1.827-6.516,0.797c0.41,0.416,0.879,0.761,1.383,0.968
                    c1.871,0.767,5.307,0.304,5.307,0.304s1.299,1.496,3.847,1.505c3.108,0.011,3.531-2.399,3.95-2.688
                    c0.159-0.11,3.46,0.124,4.809-0.934c1.228-0.963,2.174-2.283,1.066-4.424C1230.037,560.977,1229.04,559.951,1229.04,559.951z
                    "/>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M1236.172,545.736c-1.751,0.103-1.369-2.163-1.369-2.163h-1.31c0,0,0.25,2.474,1.31,2.834
                c0,0-0.872,1.159-2.52-0.439c-1.648-1.598-1.094-2.988-1.094-2.988h-0.452c0,0-0.426,2.784,1.468,4.302
                c1.137,0.911,2.597,1.236,2.597,1.236s0.852,6.06-1.276,8.498c0,0,0.688,1.803,2.454,1.013c1.029-0.46,1.79-0.343,2.971-0.755
                c0,0-1.236-0.618-1.648-2.472c-0.412-1.854-1.133-7.827,0.103-8.394c1.236-0.568,2.787-1.804,2.938-2.834h-1.084
                C1239.262,543.572,1237.923,545.632,1236.172,545.736z"/>
              <g>
                <path class="st5" d="M1244.657,532.407c0,0-1.133-2.894-2.139-3.615c-1.006-0.72-2.198-1.13-3.386-1.477
                  c-2.033-0.595-4.261-1.027-6.231-0.247c-0.648,0.257-1.261,0.642-1.953,0.728c-0.392,0.049-0.796-0.002-1.181,0.085
                  c-0.597,0.134-1.543,1.168-1.935,1.648c-0.62,0.761-0.977,1.743-0.95,2.727c-1.211-0.112-3.908,1.813-4.239,4.841
                  c-0.121,1.106,0.328,2.295,1.226,2.95c0.755,0.55,1.747,0.669,2.679,0.605c-0.696,0.242-0.964,1.137-0.788,1.853
                  c0.176,0.716,1.039,3.229,3.086,3.654c4.859,1.008,7.06-1.413,7.06-1.413s1.587,1.743,5.701-0.448
                  c0.85-0.452,1.471-1.711,1.05-2.577c0.937-0.053,4.651-0.786,5.039-3.114C1248.469,533.977,1244.657,532.407,1244.657,532.407
                  z"/>
                <path class="st6" d="M1241.932,538.528c-0.713,2.896-3.851,4.588-6.581,4.041c-1.19,0.5-2.595,0.617-4.093,0.23
                  c-2.03-0.525-3.243-1.995-3.927-3.766c-1.413,0.063-2.806-0.165-4.258-0.912c-0.157-0.081-0.281-0.178-0.417-0.27
                  c0.1,0.861,0.517,1.689,1.214,2.197c0.755,0.55,1.747,0.669,2.679,0.605c-0.696,0.242-0.964,1.137-0.788,1.853
                  c0.176,0.716,1.039,3.229,3.086,3.654c4.859,1.008,7.06-1.413,7.06-1.413s1.588,1.743,5.701-0.448
                  c0.85-0.453,1.471-1.711,1.05-2.577c0.937-0.053,4.65-0.786,5.039-3.114c0.361-2.161-0.284-3.633-1.067-4.612
                  C1245.834,536.006,1244.116,537.432,1241.932,538.528z"/>
                <g>
                  <path class="st7" d="M1244.001,527.599c0,0,1.359-1.612-0.829-4.733c-0.938-1.337-4.158-1.919-4.158-1.919
                    s-1.173-1.234-4.211-1.224c-2.172,0.007-3.063,1.835-3.063,1.835s-6.16,0.349-5.594,5.588
                    c-1.146,1.33-2.126,2.378-2.405,4.112c-0.279,1.733,1.067,3.737,2.691,4.403c1.871,0.768,5.307,0.304,5.307,0.304
                    s1.299,1.496,3.848,1.505c3.108,0.011,3.531-2.4,3.949-2.688c0.159-0.11,3.46,0.124,4.809-0.934
                    c1.228-0.963,2.174-2.283,1.066-4.424C1244.999,528.625,1244.001,527.599,1244.001,527.599z"/>
                  <path class="st8" d="M1234.803,519.722c-2.172,0.007-3.063,1.835-3.063,1.835s-6.16,0.349-5.594,5.588
                    c-0.285,0.331-0.56,0.644-0.816,0.955c0.143,0.105,0.288,0.198,0.426,0.329c0.722,0.681,1.81,1.028,2.748,1.287
                    c0.308,0.085,0.631,0.117,0.929,0.135c0.623-0.87,1.619-1.482,3.015-1.482c0.509,0,0.953,0.097,1.359,0.24
                    c0.639-0.008,1.291,0.137,1.921,0.511c0.195,0.116,0.425,0.148,0.66,0.165c0.246-1.158,0.953-2.194,2.128-2.682
                    c-0.825-1.895-0.232-4.454,1.832-5.322c-0.741-0.224-1.333-0.336-1.333-0.336S1237.841,519.712,1234.803,519.722z"/>
                  <path class="st9" d="M1244.001,527.599c0,0,0.649-0.813,0.289-2.369c-0.404,1.143-1.201,2.087-2.201,2.874
                    c0.074,0.869-0.075,1.78-0.599,2.725c-0.823,1.484-2.206,2.34-3.736,2.638c-1.213,1.067-2.981,1.512-4.825,1.07
                    c-0.552-0.133-0.984-0.367-1.363-0.64c-2.052,1.286-4.501,1.827-6.516,0.797c0.41,0.416,0.879,0.761,1.383,0.968
                    c1.871,0.767,5.307,0.304,5.307,0.304s1.299,1.496,3.848,1.505c3.108,0.011,3.531-2.4,3.949-2.688
                    c0.159-0.11,3.46,0.124,4.809-0.934c1.228-0.963,2.174-2.283,1.066-4.424
                    C1244.999,528.625,1244.001,527.599,1244.001,527.599z"/>
                </g>
              </g>
            </g>
            <g>
              <path class="st4" d="M1247.078,568.94c-1.57,0.092-1.227-1.939-1.227-1.939h-1.174c0,0,0.224,2.218,1.174,2.54
                c0,0-0.781,1.039-2.259-0.393c-1.477-1.432-0.98-2.679-0.98-2.679h-0.405c0,0-0.382,2.496,1.316,3.856
                c1.019,0.817,2.328,1.108,2.328,1.108s0.763,5.433-1.144,7.617c0,0,0.616,1.617,2.2,0.908c0.922-0.412,1.604-0.307,2.663-0.677
                c0,0-1.108-0.554-1.477-2.216c-0.37-1.662-1.016-7.016,0.092-7.524c1.108-0.509,2.498-1.617,2.634-2.54h-0.972
                C1249.849,567.001,1248.648,568.848,1247.078,568.94z"/>
              <g>
                <path class="st10" d="M1254.684,556.993c0,0-1.016-2.594-1.918-3.24c-0.902-0.646-1.971-1.013-3.035-1.324
                  c-1.823-0.533-3.82-0.921-5.586-0.222c-0.581,0.23-1.13,0.576-1.75,0.653c-0.352,0.044-0.713-0.002-1.059,0.076
                  c-0.535,0.12-1.383,1.047-1.734,1.477c-0.556,0.682-0.876,1.562-0.852,2.445c-1.085-0.1-3.503,1.625-3.8,4.34
                  c-0.109,0.991,0.293,2.057,1.099,2.645c0.677,0.494,1.566,0.599,2.401,0.542c-0.624,0.217-0.864,1.019-0.707,1.661
                  c0.157,0.642,0.931,2.895,2.766,3.276c4.355,0.904,6.328-1.267,6.328-1.267s1.423,1.562,5.111-0.402
                  c0.762-0.406,1.319-1.534,0.941-2.31c0.84-0.048,4.169-0.704,4.517-2.792C1258.101,558.4,1254.684,556.993,1254.684,556.993z"
                  />
                <path class="st11" d="M1252.241,562.48c-0.639,2.596-3.452,4.112-5.899,3.622c-1.067,0.448-2.326,0.554-3.669,0.206
                  c-1.82-0.47-2.907-1.788-3.521-3.375c-1.267,0.057-2.516-0.148-3.817-0.818c-0.141-0.072-0.252-0.16-0.374-0.242
                  c0.09,0.772,0.464,1.514,1.088,1.969c0.677,0.494,1.566,0.599,2.401,0.542c-0.624,0.217-0.864,1.02-0.707,1.661
                  c0.157,0.642,0.931,2.895,2.766,3.276c4.355,0.904,6.328-1.267,6.328-1.267s1.423,1.562,5.111-0.402
                  c0.762-0.406,1.319-1.534,0.942-2.31c0.84-0.048,4.169-0.704,4.517-2.792c0.323-1.937-0.255-3.257-0.957-4.135
                  C1255.739,560.218,1254.199,561.497,1252.241,562.48z"/>
                <g>
                  <path class="st12" d="M1254.096,552.682c0,0,1.219-1.445-0.743-4.243c-0.84-1.199-3.727-1.72-3.727-1.72
                    s-1.051-1.106-3.775-1.097c-1.947,0.006-2.745,1.645-2.745,1.645s-5.522,0.313-5.015,5.009
                    c-1.027,1.192-1.905,2.132-2.155,3.686c-0.25,1.554,0.956,3.35,2.412,3.947c1.678,0.688,4.757,0.273,4.757,0.273
                    s1.165,1.341,3.449,1.349c2.786,0.01,3.165-2.151,3.54-2.41c0.143-0.099,3.101,0.111,4.311-0.838
                    c1.101-0.863,1.949-2.047,0.956-3.965C1254.99,553.602,1254.096,552.682,1254.096,552.682z"/>
                  <path class="st13" d="M1245.851,545.622c-1.947,0.006-2.746,1.645-2.746,1.645s-5.522,0.313-5.015,5.009
                    c-0.255,0.297-0.502,0.578-0.732,0.857c0.128,0.094,0.258,0.178,0.382,0.294c0.647,0.61,1.622,0.921,2.463,1.154
                    c0.276,0.076,0.566,0.104,0.833,0.122c0.558-0.78,1.451-1.328,2.703-1.328c0.456,0,0.854,0.087,1.218,0.215
                    c0.572-0.007,1.157,0.123,1.722,0.458c0.175,0.104,0.38,0.133,0.591,0.148c0.221-1.038,0.854-1.967,1.907-2.404
                    c-0.74-1.698-0.208-3.993,1.642-4.771c-0.664-0.201-1.194-0.301-1.194-0.301S1248.575,545.613,1245.851,545.622z"/>
                  <path class="st14" d="M1254.096,552.682c0,0,0.581-0.729,0.259-2.124c-0.362,1.024-1.077,1.871-1.973,2.576
                    c0.067,0.779-0.067,1.596-0.537,2.443c-0.738,1.33-1.978,2.098-3.349,2.365c-1.088,0.957-2.672,1.356-4.326,0.959
                    c-0.495-0.119-0.882-0.329-1.221-0.574c-1.84,1.153-4.035,1.638-5.841,0.714c0.368,0.373,0.788,0.682,1.24,0.868
                    c1.085,0.445,2.755,0.428,3.801,0.361c0.587-0.038,1.149,0.152,1.642,0.471c0.57,0.369,1.493,0.784,2.763,0.789
                    c2.786,0.01,3.165-2.151,3.541-2.41c0.143-0.099,3.101,0.111,4.311-0.838c1.101-0.863,1.949-2.047,0.956-3.965
                    C1254.99,553.602,1254.096,552.682,1254.096,552.682z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_103_" gradientUnits="userSpaceOnUse" x1="914.4884" y1="759.5124" x2="943.6595" y2="759.5124">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st489" points="915.443,757.393 914.488,761.632 943.659,761.632 942.705,757.393 		"/>
        <linearGradient id="SVGID_104_" gradientUnits="userSpaceOnUse" x1="899.3232" y1="769.0721" x2="928.4945" y2="769.0721">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st490" points="900.277,766.953 899.323,771.192 928.495,771.192 927.54,766.953 		"/>
        <linearGradient id="SVGID_105_" gradientUnits="userSpaceOnUse" x1="882.626" y1="778.1052" x2="911.7969" y2="778.1052">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st491" points="883.58,775.986 882.626,780.225 911.797,780.225 910.843,775.986 		"/>
        <linearGradient id="SVGID_106_" gradientUnits="userSpaceOnUse" x1="866.8867" y1="787.1384" x2="896.0576" y2="787.1384">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st492" points="867.841,785.019 866.887,789.258 896.058,789.258 895.103,785.019 		"/>
        <linearGradient id="SVGID_107_" gradientUnits="userSpaceOnUse" x1="850.6447" y1="797.79" x2="879.8156" y2="797.79">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st493" points="851.599,795.67 850.645,799.91 879.816,799.91 878.861,795.67 		"/>
        <g>
          <g>
            <g>
              <path class="st4" d="M913.971,753.547c0.436,1.575-0.18,8.119-0.18,8.119c1.804,1.32,3.021,0,3.021,0
                c-0.405-1.589-0.694-8.291-0.694-8.291L913.971,753.547z"/>
            </g>
            <g>
              <path class="st17" d="M918.957,747.626c0,0,2.94-9.99-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.724,6.866
                c0,0-0.988,2.135-0.274,6.812c0,0-3.325-6.13-2.706,2.84c0.618,8.97,2.422,15.605,6.214,16.464c0,0,5.501-1.447,5.767-9.093
                C921.416,739.705,918.957,747.626,918.957,747.626z"/>
            </g>
            <g class="st57">
              <path d="M915.723,745.898c-0.389,0.019-0.799-0.045-1.22-0.246c-2.336-1.111-4.167-2.674-5.256-4.672
                c0.682,8.456,2.488,14.637,6.136,15.464c0,0,4.42-1.181,5.52-6.949c-0.059-0.001-0.111,0.01-0.173,0.005
                C917.787,749.265,916.859,748.414,915.723,745.898z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st4" d="M901.156,763.281l-2.147-0.172c0,0-0.288,6.703-0.694,8.292c0,0,1.216,1.32,3.021,0
                C901.336,771.401,900.719,764.856,901.156,763.281z"/>
            </g>
            <g>
              <path class="st486" d="M903.252,746.874c0.714-4.677-0.274-6.812-0.274-6.812c-0.315-11.333-2.725-6.866-2.725-6.866
                c-1.637-5.726-3.083,6.139-3.083,6.139c-3.942,8.036-1.001,18.026-1.001,18.026s-2.458-7.921-2.192-0.276
                c0.266,7.647,5.767,9.094,5.767,9.094c3.792-0.859,5.597-7.494,6.215-16.464C906.577,740.743,903.252,746.874,903.252,746.874z
                "/>
            </g>
            <g class="st487">
              <path d="M901.645,758.816c-2.253-2.471-2.446-4.905-2.441-7.674c-1.805-3.244-2.444-7.026-2.451-10.883
                c-3.302,7.858-0.584,17.102-0.584,17.102s-2.458-7.921-2.192-0.276c0.266,7.647,5.767,9.094,5.767,9.094
                c2.297-0.52,3.861-3.169,4.877-7.164C903.776,759.677,902.374,759.615,901.645,758.816z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st4" d="M882.351,772.475c0.437,1.575-0.18,8.119-0.18,8.119c1.805,1.32,3.021,0,3.021,0
                c-0.405-1.589-0.694-8.292-0.694-8.292L882.351,772.475z"/>
            </g>
            <g>
              <path class="st17" d="M887.338,766.554c0,0,2.94-9.99-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.725,6.866
                c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84c0.618,8.97,2.423,15.605,6.215,16.464c0,0,5.501-1.447,5.767-9.094
                C889.796,758.633,887.338,766.554,887.338,766.554z"/>
            </g>
            <g class="st57">
              <path d="M884.513,761.285c-0.168-3.235-2.829-5.917-3.335-9.427c-0.47-3.263-0.177-6.564,0.38-9.802
                c-0.481,0.846-0.908,2.865-1.029,7.199c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84
                c0.618,8.97,2.423,15.605,6.215,16.464c0,0,4.646-1.238,5.598-7.389c-0.457,0.018-0.956-0.06-1.505-0.267
                C885.127,766.687,884.643,763.794,884.513,761.285z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st4" d="M868.726,781.669l-2.147-0.172c0,0-0.288,6.703-0.694,8.291c0,0,1.216,1.32,3.021,0
                C868.906,789.788,868.289,783.244,868.726,781.669z"/>
            </g>
            <g>
              <path class="st486" d="M870.822,765.261c0.714-4.677-0.274-6.812-0.274-6.812c-0.315-11.332-2.724-6.866-2.724-6.866
                c-1.637-5.726-3.083,6.139-3.083,6.139c-3.941,8.036-1.001,18.026-1.001,18.026s-2.458-7.921-2.192-0.276
                c0.266,7.647,5.767,9.093,5.767,9.093c3.792-0.859,5.597-7.494,6.215-16.464C874.147,759.131,870.822,765.261,870.822,765.261z
                "/>
            </g>
            <g class="st487">
              <path d="M866.557,770.125c-0.793-1.5-1.491-3.121-1.958-4.709c-0.597-2.031-0.661-4.086-0.526-6.147
                c-2.912,7.715-0.333,16.479-0.333,16.479s-2.458-7.921-2.192-0.276c0.266,7.647,5.767,9.093,5.767,9.093
                c1.27-0.288,2.313-1.236,3.167-2.691c-2.342-2.108-3.785-6.578-3.998-8.749C866.384,772.112,866.439,771.113,866.557,770.125z"
                />
            </g>
          </g>
          <g>
            <g>
              <path class="st4" d="M849.921,791.403c0.437,1.575-0.18,8.119-0.18,8.119c1.804,1.32,3.021,0,3.021,0
                c-0.406-1.588-0.694-8.292-0.694-8.292L849.921,791.403z"/>
            </g>
            <g>
              <path class="st17" d="M854.907,785.482c0,0,2.94-9.989-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.725,6.866
                c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84c0.618,8.97,2.423,15.605,6.215,16.464c0,0,5.501-1.446,5.767-9.094
                C857.366,777.561,854.907,785.482,854.907,785.482z"/>
            </g>
            <g class="st57">
              <path d="M848.409,763.765c0.468-1.121,0.959-2.228,1.432-3.312C849.34,760.419,848.766,761.084,848.409,763.765z"/>
              <path d="M853.088,786.637c-1.665-1.086-1.068-1.927-1.873-3.342c-0.846-1.486-2.687-2.168-3.627-3.748
                c-1.271-2.139-1.707-4.355-1.628-6.591c-0.629-0.076-1.113,0.959-0.843,4.88c0.618,8.97,2.423,15.605,6.215,16.464
                c0,0,4.446-1.189,5.53-7.002C855.6,787.56,854.298,787.426,853.088,786.637z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path class="st1" d="M439.248,141.706l-181.856,105.63c-5.495,3.173-14.212,2.548-19.469-1.395l0,0
          c-5.257-3.943-5.064-9.711,0.431-12.884l181.856-105.63c5.495-3.173,14.212-2.548,19.469,1.395l0,0
          C444.936,132.765,444.743,138.533,439.248,141.706z"/>
        <path class="st1" d="M198.187,285.348l-66.837,38.588c-5.495,3.173-14.212,2.548-19.469-1.395h0
          c-5.257-3.943-5.064-9.711,0.431-12.884l66.837-38.588c5.495-3.173,14.212-2.548,19.469,1.395l0,0
          C203.875,276.406,203.682,282.175,198.187,285.348z"/>
        <g>
          <g>
            <g>
              <linearGradient id="SVGID_108_" gradientUnits="userSpaceOnUse" x1="328.2778" y1="189.776" x2="357.4489" y2="189.776">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st494" points="329.232,187.656 328.278,191.896 357.449,191.896 356.494,187.656 					"/>
              <linearGradient id="SVGID_109_" gradientUnits="userSpaceOnUse" x1="311.2159" y1="200.3483" x2="340.387" y2="200.3483">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st495" points="312.17,198.229 311.216,202.468 340.387,202.468 339.433,198.229 					"/>
              <linearGradient id="SVGID_110_" gradientUnits="userSpaceOnUse" x1="293.6863" y1="210.6309" x2="322.8571" y2="210.6309">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st496" points="294.64,208.511 293.686,212.75 322.857,212.75 321.903,208.511 					"/>
              <linearGradient id="SVGID_111_" gradientUnits="userSpaceOnUse" x1="276.1423" y1="220.6833" x2="305.3134" y2="220.6833">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st497" points="277.097,218.563 276.142,222.803 305.313,222.803 304.359,218.563 					"/>
              <linearGradient id="SVGID_112_" gradientUnits="userSpaceOnUse" x1="259.1089" y1="231.3558" x2="288.28" y2="231.3558">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st498" points="260.063,229.236 259.109,233.476 288.28,233.476 287.326,229.236 					"/>
              <g>
                <g>
                  <g>
                    <path class="st4" d="M327.111,183.367c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L327.111,183.367z"/>
                  </g>
                  <g>
                    <path class="st17" d="M332.519,176.944c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.614-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.928,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C335.186,168.352,332.519,176.944,332.519,176.944z"/>
                  </g>
                  <g class="st57">
                    <path d="M329.011,175.07c-0.422,0.021-0.866-0.049-1.323-0.266c-2.534-1.205-4.52-2.9-5.702-5.068
                      c0.74,9.172,2.698,15.877,6.656,16.774c0,0,4.794-1.281,5.988-7.537c-0.064-0.001-0.12,0.011-0.187,0.006
                      C331.25,178.722,330.243,177.798,329.011,175.07z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M313.21,193.926l-2.329-0.187c0,0-0.313,7.27-0.753,8.994c0,0,1.319,1.432,3.277,0
                      C313.405,202.733,312.737,195.634,313.21,193.926z"/>
                  </g>
                  <g>
                    <path class="st486" d="M315.484,176.129c0.774-5.074-0.297-7.39-0.297-7.39c-0.342-12.292-2.955-7.447-2.955-7.447
                      c-1.776-6.212-3.344,6.658-3.344,6.658c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                      c0.288,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                      C319.09,169.479,315.484,176.129,315.484,176.129z"/>
                  </g>
                  <g class="st487">
                    <path d="M313.741,189.082c-2.444-2.68-2.653-5.321-2.647-8.324c-1.958-3.519-2.651-7.621-2.659-11.804
                      c-3.582,8.523-0.634,18.55-0.634,18.55s-2.667-8.592-2.378-0.3c0.288,8.295,6.255,9.864,6.255,9.864
                      c2.492-0.564,4.188-3.438,5.291-7.77C316.052,190.016,314.531,189.949,313.741,189.082z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M292.813,203.898c0.474,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L292.813,203.898z"/>
                  </g>
                  <g>
                    <path class="st17" d="M298.221,197.476c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.613-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.671,9.73,2.628,16.927,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C300.888,188.883,298.221,197.476,298.221,197.476z"/>
                  </g>
                  <g class="st57">
                    <path d="M295.158,191.76c-0.182-3.509-3.069-6.418-3.618-10.225c-0.511-3.54-0.192-7.12,0.412-10.631
                      c-0.522,0.917-0.985,3.108-1.116,7.808c0,0-1.072,2.316-0.298,7.39c0,0-3.606-6.65-2.936,3.08
                      c0.671,9.73,2.628,16.927,6.741,17.859c0,0,5.039-1.343,6.072-8.015c-0.496,0.019-1.037-0.065-1.632-0.29
                      C295.823,197.62,295.299,194.481,295.158,191.76z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M278.033,213.871l-2.329-0.187c0,0-0.313,7.27-0.753,8.994c0,0,1.319,1.431,3.277,0
                      C278.228,222.678,277.559,215.579,278.033,213.871z"/>
                  </g>
                  <g>
                    <path class="st486" d="M280.307,196.074c0.775-5.074-0.297-7.39-0.297-7.39c-0.342-12.292-2.955-7.447-2.955-7.447
                      c-1.776-6.211-3.344,6.658-3.344,6.658c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                      c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                      C283.913,189.424,280.307,196.074,280.307,196.074z"/>
                  </g>
                  <g class="st487">
                    <path d="M275.681,201.349c-0.86-1.627-1.618-3.386-2.124-5.108c-0.648-2.203-0.717-4.432-0.571-6.668
                      c-3.159,8.368-0.362,17.875-0.362,17.875s-2.667-8.592-2.378-0.299c0.289,8.295,6.255,9.864,6.255,9.864
                      c1.378-0.312,2.509-1.341,3.436-2.919c-2.54-2.287-4.106-7.135-4.336-9.49C275.493,203.504,275.552,202.421,275.681,201.349
                      z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M257.635,224.43c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.277,0,3.277,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L257.635,224.43z"/>
                  </g>
                  <g>
                    <path class="st17" d="M263.044,218.007c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.614-4.844-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.927,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C265.711,209.415,263.044,218.007,263.044,218.007z"/>
                  </g>
                  <g class="st57">
                    <path d="M255.995,194.45c0.508-1.216,1.041-2.416,1.553-3.592C257.006,190.821,256.382,191.542,255.995,194.45z"/>
                    <path d="M261.071,219.259c-1.806-1.178-1.158-2.09-2.032-3.624c-0.917-1.612-2.914-2.351-3.934-4.066
                      c-1.379-2.32-1.852-4.724-1.766-7.15c-0.683-0.083-1.207,1.041-0.914,5.293c0.67,9.73,2.628,16.927,6.741,17.859
                      c0,0,4.823-1.289,5.998-7.596C263.795,220.26,262.383,220.115,261.071,219.259z"/>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path class="st4" d="M423.696,128.254c0.473,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.276,0,3.276,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L423.696,128.254z"/>
                  </g>
                  <g>
                    <path class="st17" d="M429.104,121.831c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.614-4.844-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.927,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C431.771,113.239,429.104,121.831,429.104,121.831z"/>
                  </g>
                  <g class="st57">
                    <path d="M425.596,119.956c-0.422,0.021-0.866-0.049-1.323-0.266c-2.534-1.205-4.52-2.9-5.702-5.068
                      c0.74,9.172,2.698,15.877,6.656,16.774c0,0,4.794-1.281,5.988-7.537c-0.064-0.001-0.12,0.011-0.187,0.006
                      C427.836,123.608,426.828,122.685,425.596,119.956z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M409.795,138.813l-2.329-0.186c0,0-0.313,7.271-0.753,8.994c0,0,1.319,1.432,3.277,0
                      C409.99,147.62,409.322,140.521,409.795,138.813z"/>
                  </g>
                  <g>
                    <path class="st486" d="M412.069,121.015c0.774-5.073-0.297-7.389-0.297-7.389c-0.341-12.292-2.955-7.448-2.955-7.448
                      c-1.776-6.211-3.344,6.659-3.344,6.659c-4.275,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                      c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.932,6.071-8.129,6.741-17.859
                      C415.675,114.365,412.069,121.015,412.069,121.015z"/>
                  </g>
                  <g class="st487">
                    <path d="M410.326,133.968c-2.444-2.68-2.653-5.321-2.647-8.324c-1.958-3.519-2.651-7.621-2.659-11.805
                      c-3.582,8.523-0.634,18.55-0.634,18.55s-2.667-8.593-2.378-0.3c0.289,8.295,6.255,9.864,6.255,9.864
                      c2.492-0.564,4.188-3.437,5.291-7.77C412.637,134.903,411.117,134.835,410.326,133.968z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M389.398,148.785c0.474,1.708-0.195,8.807-0.195,8.807c1.957,1.431,3.277,0,3.277,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L389.398,148.785z"/>
                  </g>
                  <g>
                    <path class="st17" d="M394.807,142.362c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.613-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.39c0,0-3.606-6.65-2.936,3.08c0.67,9.73,2.628,16.928,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C397.473,133.77,394.807,142.362,394.807,142.362z"/>
                  </g>
                  <g class="st57">
                    <path d="M391.743,136.646c-0.182-3.508-3.069-6.418-3.617-10.225c-0.511-3.54-0.192-7.12,0.412-10.632
                      c-0.522,0.918-0.985,3.108-1.116,7.809c0,0-1.072,2.316-0.298,7.39c0,0-3.606-6.65-2.936,3.08
                      c0.67,9.73,2.628,16.927,6.741,17.859c0,0,5.039-1.343,6.072-8.015c-0.496,0.019-1.037-0.065-1.632-0.29
                      C392.408,142.506,391.884,139.368,391.743,136.646z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M374.618,158.758l-2.329-0.187c0,0-0.313,7.271-0.753,8.994c0,0,1.319,1.432,3.277,0
                      C374.813,167.564,374.145,160.466,374.618,158.758z"/>
                  </g>
                  <g>
                    <path class="st486" d="M376.892,140.96c0.775-5.073-0.297-7.389-0.297-7.389c-0.342-12.292-2.955-7.448-2.955-7.448
                      c-1.776-6.211-3.344,6.659-3.344,6.659c-4.276,8.717-1.086,19.553-1.086,19.553s-2.667-8.592-2.378-0.299
                      c0.289,8.295,6.255,9.864,6.255,9.864c4.113-0.931,6.071-8.129,6.741-17.859C380.498,134.31,376.892,140.96,376.892,140.96z
                      "/>
                  </g>
                  <g class="st487">
                    <path d="M372.266,146.236c-0.861-1.627-1.618-3.386-2.124-5.108c-0.648-2.203-0.717-4.432-0.571-6.668
                      c-3.159,8.368-0.362,17.875-0.362,17.875s-2.666-8.592-2.378-0.299c0.289,8.295,6.255,9.864,6.255,9.864
                      c1.378-0.312,2.509-1.341,3.436-2.919c-2.54-2.286-4.106-7.135-4.336-9.49C372.078,148.391,372.138,147.308,372.266,146.236
                      z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st4" d="M354.22,169.317c0.474,1.708-0.195,8.807-0.195,8.807c1.957,1.432,3.276,0,3.276,0
                      c-0.44-1.723-0.753-8.994-0.753-8.994L354.22,169.317z"/>
                  </g>
                  <g>
                    <path class="st17" d="M359.629,162.894c0,0,3.189-10.836-1.086-19.553c0,0-1.568-12.87-3.344-6.659
                      c0,0-2.614-4.845-2.955,7.448c0,0-1.072,2.316-0.297,7.389c0,0-3.607-6.65-2.936,3.08c0.67,9.73,2.628,16.927,6.741,17.859
                      c0,0,5.967-1.569,6.255-9.864C362.296,154.302,359.629,162.894,359.629,162.894z"/>
                  </g>
                  <g class="st57">
                    <path d="M352.58,139.337c0.508-1.216,1.041-2.416,1.553-3.592C353.591,135.707,352.967,136.429,352.58,139.337z"/>
                    <path d="M357.656,164.146c-1.806-1.178-1.158-2.09-2.031-3.625c-0.917-1.612-2.914-2.351-3.934-4.066
                      c-1.379-2.32-1.852-4.723-1.766-7.15c-0.683-0.083-1.207,1.041-0.914,5.293c0.67,9.73,2.628,16.927,6.741,17.859
                      c0,0,4.823-1.289,5.998-7.596C360.38,165.147,358.968,165.002,357.656,164.146z"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_113_" gradientUnits="userSpaceOnUse" x1="182.8239" y1="273.7968" x2="211.995" y2="273.7968">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st499" points="183.778,271.677 182.824,275.917 211.995,275.917 211.041,271.677 			"/>
          <linearGradient id="SVGID_114_" gradientUnits="userSpaceOnUse" x1="167.6588" y1="283.3565" x2="196.83" y2="283.3565">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st500" points="168.613,281.237 167.659,285.476 196.83,285.476 195.876,281.237 			"/>
          <linearGradient id="SVGID_115_" gradientUnits="userSpaceOnUse" x1="150.9615" y1="292.3896" x2="180.1324" y2="292.3896">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st501" points="151.916,290.27 150.962,294.509 180.132,294.509 179.178,290.27 			"/>
          <linearGradient id="SVGID_116_" gradientUnits="userSpaceOnUse" x1="135.2222" y1="301.4229" x2="164.3931" y2="301.4229">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st502" points="136.176,299.303 135.222,303.543 164.393,303.543 163.439,299.303 			"/>
          <linearGradient id="SVGID_117_" gradientUnits="userSpaceOnUse" x1="118.9801" y1="312.0745" x2="148.151" y2="312.0745">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st503" points="119.934,309.955 118.98,314.194 148.151,314.194 147.197,309.955 			"/>
          <g>
            <g>
              <g>
                <path class="st4" d="M182.306,267.831c0.436,1.575-0.18,8.119-0.18,8.119c1.804,1.32,3.021,0,3.021,0
                  c-0.405-1.589-0.694-8.291-0.694-8.291L182.306,267.831z"/>
              </g>
              <g>
                <path class="st17" d="M187.293,261.91c0,0,2.94-9.99-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.724,6.866
                  c0,0-0.988,2.135-0.274,6.812c0,0-3.325-6.13-2.706,2.84c0.618,8.97,2.422,15.605,6.214,16.464c0,0,5.501-1.447,5.767-9.093
                  C189.751,253.989,187.293,261.91,187.293,261.91z"/>
              </g>
              <g class="st57">
                <path d="M184.058,260.182c-0.389,0.019-0.799-0.045-1.22-0.246c-2.336-1.111-4.167-2.674-5.256-4.672
                  c0.682,8.456,2.488,14.637,6.136,15.464c0,0,4.42-1.181,5.52-6.949c-0.059-0.001-0.111,0.01-0.173,0.005
                  C186.123,263.549,185.194,262.698,184.058,260.182z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st4" d="M169.491,277.566l-2.147-0.172c0,0-0.288,6.703-0.694,8.292c0,0,1.216,1.32,3.021,0
                  C169.671,285.685,169.055,279.141,169.491,277.566z"/>
              </g>
              <g>
                <path class="st486" d="M171.588,261.158c0.714-4.677-0.274-6.812-0.274-6.812c-0.315-11.333-2.725-6.866-2.725-6.866
                  c-1.637-5.726-3.083,6.139-3.083,6.139c-3.942,8.036-1.001,18.026-1.001,18.026s-2.458-7.921-2.192-0.276
                  c0.266,7.647,5.767,9.094,5.767,9.094c3.792-0.859,5.597-7.494,6.215-16.464C174.913,255.028,171.588,261.158,171.588,261.158
                  z"/>
              </g>
              <g class="st487">
                <path d="M169.981,273.1c-2.253-2.47-2.446-4.905-2.441-7.674c-1.805-3.244-2.444-7.025-2.451-10.883
                  c-3.302,7.858-0.584,17.102-0.584,17.102s-2.458-7.921-2.192-0.276c0.266,7.647,5.767,9.094,5.767,9.094
                  c2.297-0.52,3.861-3.169,4.877-7.164C172.111,273.962,170.709,273.899,169.981,273.1z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st4" d="M150.687,286.76c0.437,1.575-0.18,8.119-0.18,8.119c1.805,1.32,3.021,0,3.021,0
                  c-0.405-1.589-0.694-8.292-0.694-8.292L150.687,286.76z"/>
              </g>
              <g>
                <path class="st17" d="M155.673,280.839c0,0,2.94-9.99-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.725,6.866
                  c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84c0.618,8.97,2.423,15.605,6.215,16.464c0,0,5.501-1.447,5.767-9.094
                  C158.132,272.917,155.673,280.839,155.673,280.839z"/>
              </g>
              <g class="st57">
                <path d="M152.848,275.569c-0.168-3.235-2.829-5.917-3.335-9.427c-0.47-3.263-0.177-6.564,0.38-9.802
                  c-0.481,0.846-0.908,2.865-1.029,7.199c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84
                  c0.618,8.97,2.423,15.605,6.215,16.464c0,0,4.646-1.238,5.598-7.389c-0.457,0.018-0.956-0.06-1.505-0.267
                  C153.462,280.971,152.979,278.078,152.848,275.569z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st4" d="M137.061,295.953l-2.147-0.172c0,0-0.288,6.703-0.694,8.291c0,0,1.216,1.32,3.021,0
                  C137.241,304.073,136.625,297.528,137.061,295.953z"/>
              </g>
              <g>
                <path class="st486" d="M139.158,279.546c0.714-4.677-0.274-6.812-0.274-6.812c-0.315-11.332-2.724-6.866-2.724-6.866
                  c-1.637-5.726-3.083,6.139-3.083,6.139c-3.941,8.036-1.001,18.026-1.001,18.026s-2.458-7.921-2.192-0.276
                  c0.266,7.647,5.767,9.093,5.767,9.093c3.792-0.859,5.597-7.494,6.215-16.464C142.482,273.415,139.158,279.546,139.158,279.546
                  z"/>
              </g>
              <g class="st487">
                <path d="M134.893,284.409c-0.793-1.5-1.491-3.121-1.958-4.709c-0.597-2.031-0.661-4.086-0.526-6.147
                  c-2.913,7.715-0.333,16.479-0.333,16.479s-2.458-7.921-2.192-0.276c0.266,7.647,5.767,9.093,5.767,9.093
                  c1.27-0.288,2.313-1.236,3.168-2.691c-2.342-2.108-3.785-6.578-3.998-8.749C134.719,286.396,134.774,285.398,134.893,284.409z
                  "/>
              </g>
            </g>
            <g>
              <g>
                <path class="st4" d="M118.257,305.688c0.437,1.575-0.18,8.119-0.18,8.119c1.804,1.32,3.021,0,3.021,0
                  c-0.406-1.588-0.694-8.292-0.694-8.292L118.257,305.688z"/>
              </g>
              <g>
                <path class="st17" d="M123.243,299.767c0,0,2.94-9.989-1.001-18.026c0,0-1.446-11.865-3.083-6.139c0,0-2.41-4.466-2.725,6.866
                  c0,0-0.988,2.135-0.274,6.813c0,0-3.325-6.131-2.707,2.84c0.618,8.97,2.423,15.605,6.215,16.464c0,0,5.501-1.446,5.767-9.094
                  C125.701,291.845,123.243,299.767,123.243,299.767z"/>
              </g>
              <g class="st57">
                <path d="M116.744,278.049c0.468-1.121,0.959-2.228,1.432-3.311C117.676,274.703,117.101,275.368,116.744,278.049z"/>
                <path d="M121.423,300.921c-1.665-1.086-1.068-1.927-1.873-3.341c-0.846-1.486-2.687-2.168-3.626-3.748
                  c-1.271-2.139-1.707-4.355-1.628-6.591c-0.629-0.076-1.113,0.959-0.843,4.88c0.618,8.97,2.423,15.605,6.215,16.464
                  c0,0,4.446-1.189,5.53-7.002C123.935,301.844,122.633,301.71,121.423,300.921z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_118_" gradientUnits="userSpaceOnUse" x1="485.4269" y1="279.4832" x2="448.664" y2="264.9715">
            <stop  offset="0" style="stop-color:#FFFFFF"/>
            <stop  offset="1" style="stop-color:#000000"/>
          </linearGradient>
          <polygon class="st504" points="408.236,296.583 509.703,296.583 513.033,242.811 446.09,242.843 			"/>
          <linearGradient id="SVGID_119_" gradientUnits="userSpaceOnUse" x1="406.5636" y1="286.3979" x2="434.5048" y2="286.3979">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st505" points="406.564,296.644 434.505,296.644 434.505,276.152 410.273,278.285 			"/>
          <g>
            <g>
              <g>
                <g id="XMLID_24_">
                  <g>
                    <path class="st56" d="M463.403,272.029v-0.022c-0.003,0.007-0.003,0.015-0.007,0.022H463.403z"/>
                    <path class="st506" d="M461.81,271.918h0.01c0.003-0.007,0.003-0.012,0.003-0.02l1.58,0.108v-1.398l-1.595,0.929
                      L461.81,271.918z"/>
                    <path class="st506" d="M462.234,242.743v1.479v4.602v1.479v4.602v1.479v4.6v1.479l-0.466,0.266l0.039,8.81l1.595-0.929
                      v-28.51c0-1.193-0.421-2.32-1.168-3.204V242.743z"/>
                    <path class="st506" d="M461.82,271.918l1.575,0.111c0.005-0.007,0.005-0.015,0.007-0.022l-1.58-0.108
                      C461.823,271.905,461.823,271.91,461.82,271.918z"/>
                    <path class="st506" d="M462.008,272.265l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.136,0.173-0.229l-1.575-0.111C461.815,272.053,461.877,272.189,462.008,272.265z"/>
                    <polygon class="st506" points="461.768,262.728 462.234,262.462 462.234,260.983 461.764,261.257 								"/>
                    <polygon class="st506" points="461.764,261.257 462.234,260.983 462.234,256.384 461.741,256.667 								"/>
                    <polygon class="st506" points="461.741,256.667 462.234,256.384 462.234,254.905 461.736,255.193 								"/>
                    <polygon class="st506" points="461.736,255.193 462.234,254.905 462.234,250.303 461.714,250.601 								"/>
                    <polygon class="st506" points="461.714,250.601 462.234,250.303 462.234,248.824 461.709,249.127 								"/>
                    <polygon class="st506" points="461.709,249.127 462.234,248.824 462.234,244.222 461.687,244.535 								"/>
                    <polygon class="st506" points="461.687,244.535 462.234,244.222 462.234,242.743 461.682,243.063 								"/>
                    <path class="st506" d="M460.151,239.339l0.042,0.027c0.929,0.604,1.484,1.627,1.484,2.734l0.005,0.964l0.552-0.32v-3.848
                      c-0.143-0.173-0.301-0.335-0.468-0.483L460.151,239.339z"/>
                    <path class="st507" d="M462.234,238.895v-0.752l-0.468,0.269C461.934,238.56,462.091,238.722,462.234,238.895z"/>
                    <path class="st210" d="M461.132,237.919c0.227,0.148,0.436,0.311,0.634,0.493l0.468-0.269v-1.479l-1.6,0.924
                      L461.132,237.919z"/>
                    <path class="st507" d="M419.021,261.641l6.064-3.505l-0.239-0.155c-0.022-0.012-0.042-0.027-0.064-0.042l-0.015-0.012
                      c-0.042-0.034-0.079-0.074-0.106-0.123l-0.02-0.034c-0.239-0.439-0.239-0.971,0-1.41l0.012-0.022
                      c0.131-0.234,0.422-0.284,0.621-0.15l0.002-0.01l1.489,0.988l9.608-5.553l-0.19-0.123c-0.022-0.012-0.042-0.027-0.064-0.042
                      l-0.015-0.012c-0.042-0.034-0.079-0.074-0.106-0.123l-0.02-0.035c-0.239-0.439-0.239-0.971,0-1.412l0.012-0.02
                      c0.131-0.237,0.422-0.284,0.621-0.15l0.002-0.01l1.44,0.956l9.608-5.556l-0.141-0.091c-0.022-0.01-0.042-0.025-0.064-0.039
                      l-0.015-0.012c-0.042-0.035-0.079-0.074-0.106-0.123l-0.02-0.035c-0.239-0.439-0.239-0.971,0-1.412l0.012-0.02
                      c0.131-0.237,0.421-0.284,0.621-0.15l0.003-0.01l1.39,0.922l9.608-5.553l-0.091-0.059c-0.022-0.01-0.042-0.025-0.064-0.039
                      l-0.015-0.012c-0.042-0.035-0.079-0.074-0.106-0.123l-0.02-0.034c-0.239-0.441-0.239-0.971,0-1.413l0.012-0.02
                      c0.131-0.237,0.422-0.283,0.621-0.15l0.003-0.01l1.341,0.89l1.6-0.924v-4.274l-43.213,25.635V261.641z"/>
                    <polygon class="st95" points="419.021,258.025 462.234,232.39 462.234,228.776 419.021,253.754 								"/>
                    <polygon class="st50" points="401.656,236.827 419.021,253.754 462.234,228.776 444.874,211.85 								"/>
                    <polygon class="st508" points="452.064,268.302 452.064,277.217 461.808,271.538 461.768,262.728 								"/>
                    <polygon class="st210" points="452.064,266.862 452.064,268.302 461.768,262.728 461.764,261.257 								"/>
                    <path class="st506" d="M460.635,237.588l-1.681,0.971l1.198,0.779l1.615-0.927c-0.197-0.182-0.407-0.345-0.634-0.493
                      L460.635,237.588z"/>
                    <polygon class="st507" points="452.064,262.223 452.064,266.862 461.764,261.257 461.741,256.667 								"/>
                    <polygon class="st210" points="452.064,260.784 452.064,262.223 461.741,256.667 461.736,255.193 								"/>
                    <polygon class="st507" points="452.064,256.142 452.064,260.784 461.736,255.193 461.714,250.601 								"/>
                    <polygon class="st210" points="452.064,254.703 452.064,256.142 461.714,250.601 461.709,249.127 								"/>
                    <polygon class="st507" points="452.064,250.061 452.064,254.703 461.709,249.127 461.687,244.535 								"/>
                    <polygon class="st210" points="452.064,248.622 452.064,250.061 461.687,244.535 461.682,243.063 								"/>
                    <path class="st507" d="M460.193,239.366l-0.042-0.027l-9.712,5.576c1.03,0.932,1.624,2.26,1.624,3.678v0.03l9.618-5.558
                      l-0.005-0.964C461.677,240.993,461.123,239.97,460.193,239.366z"/>
                    <path class="st506" d="M459.291,236.709c-0.2-0.133-0.491-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.971,0,1.413
                      l0.02,0.034c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.039l0.091,0.059l1.681-0.971
                      l-1.341-0.89L459.291,236.709z"/>
                    <path class="st210" d="M449.345,244.113l0.449,0.298c0.232,0.15,0.446,0.318,0.646,0.503l9.712-5.576l-1.198-0.779
                      L449.345,244.113z"/>
                    <path class="st56" d="M452.064,278.524v-0.025c-0.002,0.007-0.005,0.017-0.007,0.025H452.064z"/>
                    <path class="st506" d="M450.472,278.41h0.01c0.003-0.007,0.003-0.012,0.003-0.02l1.58,0.108v-1.282l-1.592,0.929V278.41z"/>
                    <polygon class="st506" points="450.472,278.147 452.064,277.217 452.064,268.302 450.43,269.241 								"/>
                    <polygon class="st506" points="450.43,269.241 452.064,268.302 452.064,266.862 450.425,267.811 								"/>
                    <polygon class="st506" points="450.425,267.811 452.064,266.862 452.064,262.223 450.403,263.177 								"/>
                    <polygon class="st506" points="450.403,263.177 452.064,262.223 452.064,260.784 450.398,261.747 								"/>
                    <polygon class="st506" points="450.398,261.747 452.064,260.784 452.064,256.142 450.376,257.111 								"/>
                    <polygon class="st506" points="450.376,257.111 452.064,256.142 452.064,254.703 450.371,255.681 								"/>
                    <polygon class="st506" points="450.371,255.681 452.064,254.703 452.064,250.061 450.348,251.047 								"/>
                    <polygon class="st506" points="450.348,251.047 452.064,250.061 452.064,248.622 450.343,249.617 								"/>
                    <path class="st506" d="M448.828,245.841l0.027,0.017c0.929,0.604,1.484,1.627,1.484,2.734l0.005,1.025l1.721-0.996v-0.03
                      c0-1.417-0.594-2.746-1.624-3.678L448.828,245.841z"/>
                    <path class="st506" d="M450.481,278.41l1.575,0.113c0.002-0.007,0.005-0.017,0.007-0.025l-1.58-0.108
                      C450.484,278.398,450.484,278.403,450.481,278.41z"/>
                    <path class="st506" d="M450.669,278.758l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.135,0.173-0.227l-1.575-0.113C450.477,278.546,450.538,278.681,450.669,278.758z"/>
                    <polygon class="st508" points="440.725,274.814 440.725,283.828 450.472,278.147 450.43,269.241 								"/>
                    <path class="st506" d="M449.345,244.113l-1.681,0.971l1.163,0.757l1.612-0.927c-0.2-0.185-0.414-0.353-0.646-0.503
                      L449.345,244.113z"/>
                    <polygon class="st210" points="440.725,273.416 440.725,274.814 450.43,269.241 450.425,267.811 								"/>
                    <polygon class="st507" points="440.725,268.733 440.725,273.416 450.425,267.811 450.403,263.177 								"/>
                    <polygon class="st210" points="440.725,267.338 440.725,268.733 450.403,263.177 450.398,261.747 								"/>
                    <polygon class="st507" points="440.725,262.654 440.725,267.338 450.398,261.747 450.376,257.111 								"/>
                    <polygon class="st210" points="440.725,261.257 440.725,262.654 450.376,257.111 450.371,255.681 								"/>
                    <polygon class="st507" points="440.725,256.573 440.725,261.257 450.371,255.681 450.348,251.047 								"/>
                    <polygon class="st210" points="440.725,255.176 440.725,256.573 450.348,251.047 450.343,249.617 								"/>
                    <path class="st507" d="M448.855,245.858l-0.027-0.017l-9.714,5.578c1.023,0.932,1.612,2.253,1.612,3.668v0.089l9.618-5.559
                      l-0.005-1.025C450.338,247.485,449.784,246.462,448.855,245.858z"/>
                    <path class="st506" d="M447.952,243.201c-0.2-0.133-0.491-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.974,0,1.412
                      l0.02,0.035c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.039l0.141,0.091l1.681-0.971
                      l-1.39-0.922L447.952,243.201z"/>
                    <path class="st210" d="M438.056,250.64l0.399,0.264c0.234,0.153,0.456,0.325,0.658,0.515l9.714-5.578l-1.163-0.757
                      L438.056,250.64z"/>
                    <polygon class="st43" points="431.235,211.191 388.18,236.006 401.656,236.827 444.874,211.85 431.398,211.029 								"/>
                    <path class="st56" d="M440.725,285.016v-0.025c-0.003,0.007-0.005,0.017-0.008,0.025H440.725z"/>
                    <path class="st506" d="M439.133,284.903h0.01c0.002-0.008,0.002-0.012,0.002-0.02l1.58,0.108v-1.163l-1.593,0.927V284.903z"
                      />
                    <polygon class="st506" points="439.133,284.755 440.725,283.828 440.725,274.814 439.091,275.751 								"/>
                    <polygon class="st506" points="439.091,275.751 440.725,274.814 440.725,273.416 439.086,274.365 								"/>
                    <polygon class="st506" points="439.086,274.365 440.725,273.416 440.725,268.733 439.064,269.687 								"/>
                    <polygon class="st506" points="439.064,269.687 440.725,268.733 440.725,267.338 439.059,268.299 								"/>
                    <polygon class="st506" points="439.059,268.299 440.725,267.338 440.725,262.654 439.037,263.623 								"/>
                    <polygon class="st506" points="439.037,263.623 440.725,262.654 440.725,261.257 439.032,262.235 								"/>
                    <polygon class="st506" points="439.032,262.235 440.725,261.257 440.725,256.573 439.01,257.557 								"/>
                    <polygon class="st506" points="439.01,257.557 440.725,256.573 440.725,255.176 439.005,256.172 								"/>
                    <path class="st506" d="M437.504,252.344l0.012,0.007c0.929,0.604,1.484,1.627,1.484,2.736l0.005,1.085l1.721-0.996v-0.089
                      c0-1.415-0.589-2.736-1.612-3.668L437.504,252.344z"/>
                    <path class="st506" d="M439.143,284.903l1.575,0.113c0.002-0.007,0.005-0.017,0.008-0.025l-1.58-0.108
                      C439.145,284.891,439.145,284.896,439.143,284.903z"/>
                    <path class="st506" d="M439.33,285.251l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.135,0.173-0.227l-1.575-0.113C439.138,285.039,439.199,285.174,439.33,285.251z"/>
                    <polygon class="st509" points="429.387,281.324 429.387,290.437 439.133,284.755 439.091,275.751 								"/>
                    <path class="st506" d="M438.056,250.64l-1.681,0.971l1.129,0.732l1.61-0.924c-0.202-0.19-0.424-0.362-0.658-0.515
                      L438.056,250.64z"/>
                    <polygon class="st210" points="429.387,279.971 429.387,281.324 439.091,275.751 439.086,274.365 								"/>
                    <polygon class="st507" points="429.387,275.245 429.387,279.971 439.086,274.365 439.064,269.687 								"/>
                    <polygon class="st210" points="429.387,273.89 429.387,275.245 439.064,269.687 439.059,268.299 								"/>
                    <polygon class="st507" points="429.387,269.164 429.387,273.89 439.059,268.299 439.037,263.623 								"/>
                    <polygon class="st210" points="429.387,267.809 429.387,269.164 439.037,263.623 439.032,262.235 								"/>
                    <polygon class="st507" points="429.387,263.083 429.387,267.809 439.032,262.235 439.01,257.557 								"/>
                    <polygon class="st210" points="429.387,261.73 429.387,263.083 439.01,257.557 439.005,256.172 								"/>
                    <path class="st507" d="M437.516,252.351l-0.012-0.007l-9.717,5.578c1.013,0.932,1.6,2.248,1.6,3.658v0.15l9.618-5.559
                      L439,255.087C439,253.978,438.445,252.955,437.516,252.351z"/>
                    <path class="st506" d="M436.614,249.694c-0.2-0.133-0.491-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.974,0,1.412
                      l0.02,0.035c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.042l0.19,0.123l1.681-0.971
                      l-1.44-0.956L436.614,249.694z"/>
                    <path class="st210" d="M426.766,257.165l0.35,0.232c0.239,0.158,0.464,0.33,0.671,0.525l9.717-5.578l-1.129-0.732
                      L426.766,257.165z"/>
                    <path class="st56" d="M429.387,291.509v-0.025c-0.003,0.007-0.005,0.017-0.007,0.025H429.387z"/>
                    <path class="st506" d="M427.794,291.396h0.01c0.003-0.005,0.003-0.012,0.003-0.017l1.58,0.106v-1.048l-1.592,0.929V291.396z
                      "/>
                    <polygon class="st506" points="427.794,291.366 429.387,290.437 429.387,281.324 427.752,282.263 								"/>
                    <polygon class="st506" points="427.752,282.263 429.387,281.324 429.387,279.971 427.747,280.92 								"/>
                    <polygon class="st506" points="427.747,280.92 429.387,279.971 429.387,275.245 427.725,276.197 								"/>
                    <polygon class="st506" points="427.725,276.197 429.387,275.245 429.387,273.89 427.72,274.853 								"/>
                    <polygon class="st506" points="427.72,274.853 429.387,273.89 429.387,269.164 427.698,270.133 								"/>
                    <polygon class="st506" points="427.698,270.133 429.387,269.164 429.387,267.809 427.693,268.79 								"/>
                    <polygon class="st506" points="427.693,268.79 429.387,267.809 429.387,263.083 427.673,264.069 								"/>
                    <polygon class="st506" points="427.673,264.069 429.387,263.083 429.387,261.73 427.666,262.726 								"/>
                    <path class="st506" d="M426.18,258.846c0.927,0.604,1.481,1.624,1.481,2.734l0.005,1.146l1.721-0.996v-0.15
                      c0-1.41-0.587-2.726-1.6-3.658L426.18,258.846z"/>
                    <path class="st506" d="M427.804,291.396l1.575,0.113c0.002-0.007,0.005-0.017,0.007-0.025l-1.58-0.106
                      C427.807,291.383,427.807,291.391,427.804,291.396z"/>
                    <path class="st506" d="M427.991,291.743l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.136,0.173-0.227l-1.575-0.113C427.799,291.531,427.861,291.667,427.991,291.743z"/>
                    <polygon class="st509" points="419.021,287.277 419.268,296.478 427.794,291.366 427.752,282.263 								"/>
                    <path class="st506" d="M426.766,257.165l-1.681,0.971l1.092,0.707c0,0,0.002,0,0.002,0.002l1.607-0.924
                      c-0.207-0.195-0.431-0.367-0.671-0.525L426.766,257.165z"/>
                    <polygon class="st210" points="419.021,285.963 419.021,287.277 427.752,282.263 427.747,280.92 								"/>
                    <polygon class="st507" points="419.021,281.196 419.021,285.963 427.747,280.92 427.725,276.197 								"/>
                    <polygon class="st210" points="419.021,279.882 419.021,281.196 427.725,276.197 427.72,274.853 								"/>
                    <polygon class="st507" points="419.021,275.117 419.021,279.882 427.72,274.853 427.698,270.133 								"/>
                    <polygon class="st210" points="419.021,273.801 419.021,275.117 427.698,270.133 427.693,268.79 								"/>
                    <polygon class="st507" points="419.021,269.036 419.021,273.801 427.693,268.79 427.673,264.069 								"/>
                    <polygon class="st210" points="419.021,267.722 419.021,269.036 427.673,264.069 427.666,262.726 								"/>
                    <path class="st507" d="M426.18,258.846l-7.158,4.109v4.767l8.645-4.996l-0.005-1.146
                      C427.661,260.47,427.106,259.45,426.18,258.846z"/>
                    <path class="st506" d="M425.275,256.186c-0.2-0.133-0.491-0.084-0.621,0.15l-0.012,0.022c-0.239,0.439-0.239,0.971,0,1.41
                      l0.02,0.034c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.042l0.239,0.155l1.681-0.971
                      l-1.489-0.988L425.275,256.186z"/>
                    <polygon class="st210" points="425.085,258.136 419.021,261.641 419.021,262.955 426.18,258.846 426.177,258.844 								
                      "/>
                    <polygon class="st510" points="419.021,285.963 419.021,281.196 419.021,279.882 419.021,275.117 419.021,273.801 
                      419.021,269.036 419.021,267.722 419.021,262.955 419.021,261.641 419.021,258.025 388.18,240.046 388.18,250.056 
                      388.192,250.076 390.805,248.54 412.839,261.804 412.839,267.636 412.795,267.663 412.839,267.737 412.839,292.673 
                      419.268,296.478 419.021,287.277 								"/>
                    <polygon class="st127" points="388.18,240.046 419.021,258.025 419.021,253.754 388.18,236.006 								"/>
                    <polygon class="st511" points="388.18,236.006 419.021,253.754 401.656,236.827 								"/>
                    <polygon class="st210" points="411.01,268.735 411.01,293.656 411.045,293.678 412.839,292.673 412.839,267.737 
                      412.795,267.663 								"/>
                    <polygon class="st95" points="406.564,265.977 406.564,271.407 408.38,270.316 409.1,269.884 411.01,268.735 
                      412.795,267.663 412.839,267.636 412.839,261.804 406.564,265.844 								"/>
                    <polygon class="st512" points="388.192,250.076 384.155,252.45 406.564,265.977 406.564,265.844 412.839,261.804 
                      390.805,248.54 								"/>
                    <polygon class="st211" points="409.1,292.47 411.01,293.656 411.01,268.735 409.1,269.884 								"/>
                    <polygon class="st210" points="406.564,276.554 406.564,277.294 406.564,282.532 406.564,283.271 406.564,288.509 
                      406.564,289.249 406.564,295.411 406.564,296.644 408.311,295.638 408.38,270.316 406.564,271.407 								"/>
                    <polygon class="st509" points="404.626,294.472 404.68,294.679 404.626,294.694 404.626,295.436 406.564,296.644 
                      406.564,295.411 404.626,294.285 								"/>
                    <polygon class="st509" points="404.626,294.285 406.564,295.411 406.564,289.249 404.626,288.107 								"/>
                    <polygon class="st210" points="404.626,288.107 406.564,289.249 406.564,288.509 404.626,287.383 								"/>
                    <polygon class="st509" points="404.626,283.542 404.626,283.991 404.626,287.383 406.564,288.509 406.564,283.271 
                      404.626,282.13 								"/>
                    <polygon class="st210" points="404.626,282.13 406.564,283.271 406.564,282.532 404.626,281.405 								"/>
                    <polygon class="st509" points="404.626,281.405 406.564,282.532 406.564,277.294 404.626,276.152 								"/>
                    <polygon class="st210" points="404.626,276.152 406.564,277.294 406.564,276.554 404.626,275.428 								"/>
                    <polygon class="st509" points="404.626,270.402 404.626,275.428 406.564,276.554 406.564,271.407 404.69,270.293 								
                      "/>
                    <path class="st513" d="M384.155,258.043c0.141,0.035,2.068,1.208,2.068,1.208v0.04l1.94,1.156l4.052,2.413l3.749,2.233
                      l3.749,2.233l4.977,2.968l1.873,1.114v-5.43l-22.409-13.528V258.043z"/>
                    <polygon class="st509" points="404.626,294.694 403.756,294.918 403.404,293.572 400.907,292.123 400.739,292.68 
                      399.825,292.409 399.706,292.608 406.564,296.644 404.626,295.436 								"/>
                    <polygon class="st514" points="399.598,267.52 399.598,272.504 404.626,275.428 404.626,270.402 404.69,270.293 
                      399.714,267.326 								"/>
                    <polygon class="st509" points="404.626,294.694 404.68,294.679 404.626,294.472 								"/>
                    <polygon class="st509" points="403.404,293.572 403.756,294.918 404.626,294.694 404.626,294.472 404.567,294.25 								
                      "/>
                    <polygon class="st509" points="404.626,294.472 404.626,294.285 404.567,294.25 								"/>
                    <polygon class="st507" points="404.567,294.25 404.626,294.285 404.626,288.107 402.649,286.944 								"/>
                    <path class="st210" d="M397.867,283.451l-0.076-0.042l-0.049,0.086c0.133,0.074,0.239,0.158,0.256,0.227l0.158,0.579
                      l3.512,2.066c0.081-0.14,0.242-0.232,0.412-0.227c0.212,0.002,0.397,0.148,0.451,0.355l0.118,0.449l1.977,1.164v-0.725
                      l-2.909-1.691L397.867,283.451z"/>
                    <path class="st507" d="M403.051,285.532c-0.375,0.328-0.905,0.352-1.301,0.103l-0.032,0.057l2.909,1.691v-3.392h-0.054
                      L403.051,285.532z"/>
                    <path class="st515" d="M400.33,283.582l-0.064-0.037c-0.116-0.064-0.222-0.153-0.311-0.259l1.511,2.09
                      c0.081,0.106,0.178,0.192,0.283,0.259c0.397,0.249,0.927,0.224,1.301-0.103l1.521-1.54h0.054v-0.449l-0.069,0.039
                      C403.241,284.304,401.646,284.304,400.33,283.582z"/>
                    <path class="st514" d="M399.598,281.543v0.131c-0.012,0.003-0.025,0.007-0.037,0.007c-0.076,0.357-0.03,0.789,0.123,1.159
                      c0.069,0.163,0.158,0.315,0.266,0.441c0,0.002,0.002,0.002,0.005,0.005c0.089,0.106,0.195,0.195,0.311,0.259l0.064,0.037
                      c1.316,0.722,2.911,0.722,4.227,0l0.069-0.039v-1.412l-5.029-2.958V281.543z"/>
                    <polygon class="st210" points="399.598,279.172 404.626,282.13 404.626,281.405 399.598,278.482 								"/>
                    <polygon class="st514" points="399.598,278.482 404.626,281.405 404.626,276.152 399.598,273.195 								"/>
                    <path class="st516" d="M403.093,269.94v4.596l1.533,0.892v-5.026l0.064-0.108l-1.66-0.99
                      C403.069,269.511,403.093,269.723,403.093,269.94z"/>
                    <path class="st516" d="M400.772,283.791c1.221,0.502,2.613,0.434,3.785-0.21l0.069-0.039v-1.412l-1.611-0.948
                      C402.759,282.37,401.891,283.357,400.772,283.791z"/>
                    <path class="st516" d="M403.093,280.457c0,0.018-0.003,0.036-0.003,0.055l1.536,0.893v-5.253l-1.533-0.902V280.457z"/>
                    <polygon class="st210" points="399.598,273.195 404.626,276.152 404.626,275.428 399.598,272.504 								"/>
                    <path class="st517" d="M401.668,286.367c-0.024,0.035-0.042,0.071-0.054,0.111l-1.504,4.987l0.035,0.131l-0.081,0.022
                      l-0.005,0.012l0.848,0.493l1.132-3.752l1.365,5.201l1.163,0.678l-1.918-7.306L401.668,286.367z"/>
                    <polygon class="st507" points="400.907,292.123 403.404,293.572 402.038,288.371 								"/>
                    <path class="st210" d="M402.08,286.14c-0.17-0.005-0.33,0.086-0.412,0.227l0.981,0.577l-0.118-0.449
                      C402.477,286.288,402.292,286.143,402.08,286.14z"/>
                    <path class="st507" d="M399.955,283.286c-0.003-0.003-0.005-0.003-0.005-0.005c-0.108-0.126-0.197-0.279-0.266-0.441
                      l-0.441,0.384c-0.375,0.328-0.905,0.353-1.304,0.104l-0.071,0.123l3.85,2.241l0.032-0.057
                      c-0.106-0.066-0.202-0.153-0.283-0.259L399.955,283.286z"/>
                    <path class="st507" d="M400.11,291.465l1.504-4.987c0.012-0.039,0.03-0.076,0.054-0.111l-3.512-2.066L400.11,291.465z"/>
                    <polygon class="st509" points="399.825,292.409 400.739,292.68 400.907,292.123 400.059,291.63 								"/>
                    <polygon class="st517" points="400.064,291.618 400.145,291.595 400.11,291.465 								"/>
                    <polygon class="st517" points="397.012,283.626 395.476,288.709 395.558,289.012 396.258,289.419 397.505,285.288 
                      399.009,291.019 400.046,291.62 400.064,291.618 400.11,291.465 398.156,284.302 								"/>
                    <polygon class="st509" points="399.223,291.834 399.009,291.019 396.258,289.419 396.204,289.596 395.292,289.325 
                      395.299,289.303 394.69,289.463 394.606,289.606 399.706,292.608 399.825,292.409 400.059,291.63 400.046,291.62 								
                      "/>
                    <polygon class="st509" points="399.223,291.834 400.046,291.62 399.009,291.019 								"/>
                    <polygon class="st514" points="395.777,265.408 395.777,270.283 399.598,272.504 399.598,267.52 399.714,267.326 
                      395.964,265.092 								"/>
                    <path class="st515" d="M399.561,281.681c-1.07,0.283-2.053,0.135-3.052-0.414l-0.064-0.035
                      c-0.148-0.081-0.264-0.19-0.352-0.318l-0.03,0.022l1.592,2.135c0.081,0.104,0.177,0.19,0.283,0.256
                      c0.399,0.249,0.929,0.224,1.304-0.104l0.441-0.384C399.531,282.47,399.484,282.039,399.561,281.681z"/>
                    <path class="st514" d="M399.598,281.674v-0.131c-0.015,0.044-0.027,0.091-0.037,0.138
                      C399.573,281.681,399.585,281.676,399.598,281.674z"/>
                    <path class="st514" d="M395.777,279.024v0.281v0.38c0.01,0.281,0.042,0.557,0.116,0.804c0.044,0.158,0.111,0.301,0.2,0.426
                      c0.089,0.128,0.204,0.237,0.352,0.318l0.064,0.035c0.998,0.55,1.982,0.698,3.052,0.414c0.01-0.047,0.022-0.094,0.037-0.138
                      v-2.371l-3.821-2.248V279.024z"/>
                    <polygon class="st210" points="395.777,276.924 399.598,279.172 399.598,278.482 395.777,276.261 								"/>
                    <polygon class="st514" points="395.777,276.261 399.598,278.482 399.598,273.195 395.777,270.946 								"/>
                    <polygon class="st516" points="398.903,272.1 399.598,272.504 399.598,267.52 399.714,267.326 398.903,266.842 								"/>
                    <path class="st516" d="M397.331,280.685c-0.236,0.233-0.501,0.435-0.792,0.597c0.988,0.536,1.962,0.68,3.021,0.399
                      c0.01-0.047,0.022-0.094,0.037-0.138v-2.371l-0.829-0.488C398.537,279.5,398.019,280.209,397.331,280.685z"/>
                    <path class="st516" d="M398.903,277.746c0,0.109-0.007,0.216-0.017,0.322l0.712,0.414v-5.287l-0.695-0.409V277.746z"/>
                    <polygon class="st210" points="395.777,270.946 399.598,273.195 399.598,272.504 395.777,270.283 								"/>
                    <polygon class="st507" points="396.258,289.419 399.009,291.019 397.505,285.288 								"/>
                    <path class="st210" d="M397.742,283.496c-0.256-0.138-0.621-0.237-0.661-0.101l-0.069,0.232l1.144,0.675l-0.158-0.579
                      C397.981,283.653,397.875,283.569,397.742,283.496z"/>
                    <path class="st507" d="M396.063,280.937l0.03-0.022c-0.089-0.126-0.155-0.269-0.2-0.426l-0.03,0.01l-0.441,0.385
                      c-0.375,0.328-0.905,0.352-1.304,0.103l-0.126,0.215l3.799,2.208l0.076,0.042l0.071-0.123
                      c-0.106-0.066-0.202-0.153-0.283-0.256L396.063,280.937z"/>
                    <path class="st210" d="M393.324,280.811c0.067,0.057,0.116,0.136,0.141,0.227l0.155,0.594l3.392,1.994l0.069-0.232
                      c0.039-0.135,0.404-0.037,0.661,0.101l0.049-0.086l-3.799-2.208L393.324,280.811z"/>
                    <polygon class="st507" points="395.476,288.709 397.012,283.626 393.62,281.632 								"/>
                    <polygon class="st509" points="395.612,289.222 395.299,289.303 395.292,289.325 396.204,289.596 396.258,289.419 
                      395.558,289.012 								"/>
                    <polygon class="st514" points="391.956,263.295 391.956,268.06 395.777,270.283 395.777,265.408 395.964,265.092 
                      392.215,262.859 								"/>
                    <path class="st515" d="M395.775,279.308c-1.121,0.362-2.021,0.232-3.086-0.353l-0.064-0.034
                      c-0.074-0.064-0.143-0.133-0.205-0.21l1.415,2.019c0.081,0.104,0.177,0.19,0.283,0.256c0.399,0.249,0.929,0.224,1.304-0.103
                      l0.441-0.385l0.03-0.01c-0.074-0.246-0.106-0.523-0.116-0.804C395.772,279.559,395.77,279.433,395.775,279.308z"/>
                    <path class="st514" d="M391.956,276.813v0.244c-0.005,0.003-0.01,0.005-0.015,0.005c-0.01,0.345,0.03,0.69,0.133,1.003
                      c0.047,0.148,0.108,0.288,0.185,0.417l0.002,0.003l0.158,0.227c0.062,0.077,0.131,0.146,0.205,0.21l0.064,0.034
                      c1.065,0.584,1.965,0.715,3.086,0.353c0-0.096,0-0.19,0.003-0.284v-2.1l-3.821-2.248V276.813z"/>
                    <polygon class="st210" points="391.956,274.676 395.777,276.924 395.777,276.261 391.956,274.038 								"/>
                    <polygon class="st514" points="391.956,274.038 395.777,276.261 395.777,270.946 391.956,268.698 								"/>
                    <polygon class="st516" points="394.959,269.807 395.777,270.283 395.777,265.408 395.964,265.092 394.959,264.493 								
                      "/>
                    <path class="st516" d="M393.397,279.281c0.779,0.289,1.513,0.306,2.378,0.027c0-0.096,0-0.19,0.003-0.284v-2.1l-0.823-0.484
                      C394.923,277.602,394.308,278.646,393.397,279.281z"/>
                    <polygon class="st516" points="394.959,275.785 395.777,276.261 395.777,270.946 394.959,270.465 								"/>
                    <polygon class="st210" points="391.956,268.698 395.777,270.946 395.777,270.283 391.956,268.06 								"/>
                    <polygon class="st509" points="395.412,288.926 395.299,289.303 395.612,289.222 395.558,289.012 								"/>
                    <polygon class="st517" points="395.412,288.926 395.558,289.012 395.476,288.709 								"/>
                    <path class="st517" d="M392.553,281.003c-0.003,0.005-0.005,0.012-0.007,0.02l-1.6,5.307l0.846,0.493l1.181-3.907
                      l1.422,5.418l1.018,0.591l0.064-0.217l-1.856-7.077L392.553,281.003z"/>
                    <polygon class="st509" points="394.69,289.463 395.299,289.303 395.412,288.926 394.394,288.334 								"/>
                    <polygon class="st509" points="391.791,286.823 391.671,287.225 390.759,286.954 390.827,286.722 390.157,286.38 
                      389.977,285.766 387.327,284.225 387.137,284.854 386.223,284.58 386.223,284.664 385.883,284.472 394.606,289.606 
                      394.69,289.463 394.394,288.334 								"/>
                    <polygon class="st507" points="391.791,286.823 394.394,288.334 392.972,282.916 								"/>
                    <path class="st507" d="M392.42,278.711c-0.059-0.071-0.111-0.145-0.158-0.227l-0.002-0.003
                      c-0.076-0.128-0.138-0.269-0.185-0.417l-0.513,0.688c-0.375,0.328-0.907,0.353-1.304,0.104l-0.074,0.128l2.963,1.723
                      c0.066,0.02,0.128,0.054,0.177,0.103l0.668,0.39l0.126-0.215c-0.106-0.066-0.202-0.153-0.283-0.256L392.42,278.711z"/>
                    <path class="st210" d="M393.324,280.811l-0.177-0.103c-0.042-0.015-0.089-0.022-0.136-0.022
                      c-0.209,0-0.392,0.123-0.458,0.318l1.067,0.629l-0.155-0.594C393.44,280.947,393.391,280.868,393.324,280.811z"/>
                    <path class="st507" d="M393.324,280.811l-0.177-0.103C393.214,280.727,393.275,280.762,393.324,280.811z"/>
                    <path class="st210" d="M386.223,276.682v0.596l1.945,1.144c0.086-0.071,0.195-0.111,0.311-0.111
                      c0.214,0.005,0.399,0.15,0.453,0.355l0.064,0.244l3.557,2.093c0.067-0.195,0.249-0.318,0.458-0.318
                      c0.047,0,0.094,0.007,0.136,0.022l-2.963-1.723L386.223,276.682z"/>
                    <path class="st507" d="M390.941,286.328l0.005,0.002l1.6-5.307c0.002-0.008,0.005-0.015,0.007-0.02l-3.557-2.093
                      L390.941,286.328z"/>
                    <polygon class="st514" points="388.136,260.49 388.136,265.839 391.956,268.06 391.956,263.295 392.215,262.859 
                      388.163,260.446 								"/>
                    <path class="st515" d="M388.868,276.643l-0.064-0.035c-0.192-0.106-0.343-0.246-0.451-0.409l-0.007,0.005l1.629,2.396
                      c0.081,0.103,0.177,0.19,0.283,0.256c0.397,0.249,0.929,0.224,1.304-0.104l0.513-0.688
                      c-0.104-0.313-0.143-0.658-0.133-1.003C390.867,277.353,389.876,277.195,388.868,276.643z"/>
                    <path class="st514" d="M391.956,277.057v-0.244c-0.007,0.084-0.012,0.168-0.015,0.249
                      C391.947,277.062,391.952,277.06,391.956,277.057z"/>
                    <path class="st514" d="M388.136,275.393c0,0.012,0,0.025,0,0.037c-0.01,0.269,0.064,0.54,0.217,0.769
                      c0.108,0.163,0.259,0.303,0.451,0.409l0.064,0.035c1.008,0.552,1.999,0.71,3.074,0.419c0.003-0.081,0.007-0.165,0.015-0.249
                      v-2.137l-3.821-2.25V275.393z"/>
                    <polygon class="st210" points="388.136,272.425 391.956,274.676 391.956,274.038 388.136,271.817 								"/>
                    <polygon class="st514" points="388.136,271.817 391.956,274.038 391.956,268.698 388.136,266.448 								"/>
                    <path class="st516" d="M390.85,262.381v5.036l1.106,0.643v-4.765l0.259-0.436l-1.383-0.823
                      C390.844,262.149,390.85,262.265,390.85,262.381z"/>
                    <path class="st516" d="M390.85,274.048c0,1.148-0.577,2.187-1.448,2.846c0.827,0.338,1.655,0.407,2.539,0.168
                      c0.003-0.081,0.007-0.165,0.015-0.249v-2.137l-1.106-0.651V274.048z"/>
                    <polygon class="st516" points="390.85,273.395 391.956,274.038 391.956,268.698 390.85,268.047 								"/>
                    <polygon class="st210" points="388.136,266.448 391.956,268.698 391.956,268.06 388.136,265.839 								"/>
                    <polygon class="st509" points="390.943,286.338 391.079,286.85 390.827,286.722 390.759,286.954 391.671,287.225 
                      391.791,286.823 390.946,286.33 								"/>
                    <polygon class="st509" points="390.827,286.722 391.079,286.85 390.943,286.338 								"/>
                    <polygon class="st56" points="390.943,286.338 390.946,286.33 390.941,286.328 								"/>
                    <polygon class="st509" points="389.977,285.766 390.157,286.38 390.827,286.722 390.943,286.338 390.941,286.328 								
                      "/>
                    <path class="st517" d="M388.168,278.423c-0.071,0.057-0.128,0.135-0.155,0.227l-1.533,5.083l0.848,0.493l1.112-3.68
                      l1.538,5.221l0.964,0.562l-1.945-7.417L388.168,278.423z"/>
                    <path class="st211" d="M388.345,276.204l0.007-0.005c-0.153-0.229-0.227-0.5-0.217-0.769c0-0.012,0-0.025,0-0.037v-2.968
                      l-1.913-1.124v5.381l3.961,2.302l0.074-0.128c-0.106-0.066-0.202-0.153-0.283-0.256L388.345,276.204z"/>
                    <polygon class="st507" points="387.327,284.225 389.977,285.766 388.439,280.545 								"/>
                    <path class="st210" d="M388.478,278.312c-0.116,0-0.224,0.04-0.311,0.111l0.828,0.488l-0.064-0.244
                      C388.878,278.462,388.693,278.317,388.478,278.312z"/>
                    <path class="st211" d="M386.223,283.584l0.256,0.148l1.533-5.083c0.027-0.091,0.084-0.17,0.155-0.227l-1.945-1.144V283.584z
                      "/>
                    <polygon class="st211" points="386.223,264.727 388.136,265.839 388.136,260.49 388.163,260.446 386.223,259.29 								"/>
                    <polygon class="st210" points="386.223,271.301 388.136,272.425 388.136,271.817 386.223,270.705 								"/>
                    <polygon class="st211" points="386.223,270.705 388.136,271.817 388.136,266.448 386.223,265.324 								"/>
                    <polygon class="st210" points="386.223,265.324 388.136,266.448 388.136,265.839 386.223,264.727 								"/>
                    <polygon class="st509" points="386.223,284.58 387.137,284.854 387.327,284.225 386.479,283.732 								"/>
                    <polygon class="st509" points="386.223,284.58 386.479,283.732 386.223,283.584 								"/>
                    <polygon class="st509" points="384.155,283.456 385.883,284.472 386.223,284.664 386.223,284.58 386.223,283.584 
                      384.155,282.381 								"/>
                    <polygon class="st509" points="384.155,282.381 386.223,283.584 386.223,277.279 384.155,276.061 								"/>
                    <polygon class="st210" points="384.155,276.061 386.223,277.279 386.223,276.682 384.155,275.48 								"/>
                    <polygon class="st509" points="384.155,275.48 386.223,276.682 386.223,271.301 384.155,270.084 								"/>
                    <polygon class="st210" points="384.155,270.084 386.223,271.301 386.223,270.705 384.155,269.502 								"/>
                    <polygon class="st509" points="384.155,269.502 386.223,270.705 386.223,265.324 384.155,264.106 								"/>
                    <polygon class="st210" points="384.155,264.106 386.223,265.324 386.223,264.727 384.155,263.525 								"/>
                    <polygon class="st509" points="384.155,263.525 386.223,264.727 386.223,259.29 384.155,258.057 								"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polygon class="st507" points="408.319,292.915 409.1,292.47 409.1,269.884 408.38,270.316 			"/>
        </g>
        <g>
          <linearGradient id="SVGID_120_" gradientUnits="userSpaceOnUse" x1="307.9694" y1="283.399" x2="349.4017" y2="283.399">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st518" points="307.969,287.337 349.402,287.337 349.402,279.461 309.742,279.461 			"/>
          <g>
            <g id="XMLID_23_">
              <g>
                <path class="st56" d="M318.102,272.272c0.042-0.274,0.052-0.55,0.035-0.826h-0.059L318.102,272.272z"/>
                <path class="st49" d="M317.745,260.014l-0.123,0.002c-0.089,1.132-0.7,2.226-1.841,2.852l-0.286,0.158
                  c-3.626,1.989-8.016,1.989-11.642,0l-0.175-0.096c-1.274-0.7-1.888-1.984-1.851-3.251l-0.101-0.003l-0.345,11.77l-0.025,0.885
                  c0.16,1.109,0.803,2.157,1.93,2.778l0.187,0.101c3.86,2.12,8.536,2.12,12.396,0l0.303-0.168
                  c1.124-0.616,1.767-1.664,1.93-2.77l-0.025-0.826L317.745,260.014z"/>
                <path class="st146" d="M317.36,248.473c-0.108,1.067-0.7,2.09-1.775,2.679l-0.276,0.153c-3.51,1.925-7.757,1.925-11.267,0
                  l-0.168-0.094c-1.117-0.611-1.708-1.689-1.785-2.795h-0.037l-0.328,11.26l0.101,0.003c-0.037,1.267,0.577,2.551,1.851,3.251
                  l0.175,0.096c3.626,1.989,8.016,1.989,11.642,0l0.286-0.158c1.141-0.626,1.753-1.72,1.841-2.852l0.123-0.002l-0.338-11.543
                  L317.36,248.473z"/>
                <path class="st49" d="M315.388,239.944l-0.266,0.148c-3.392,1.861-7.498,1.861-10.888,0l-0.165-0.091
                  c-0.998-0.547-1.563-1.477-1.698-2.46l-0.318,10.875h0.037c0.077,1.107,0.668,2.184,1.785,2.795l0.168,0.094
                  c3.51,1.925,7.757,1.925,11.267,0l0.276-0.153c1.075-0.589,1.666-1.612,1.775-2.679l0.047-0.003l-0.318-10.984
                  C316.948,238.47,316.386,239.399,315.388,239.944z"/>
                <path class="st56" d="M317.089,237.487c0.007-0.047,0.012-0.096,0.017-0.143h-0.022L317.089,237.487z"/>
                <path class="st146" d="M316.751,225.906l-0.136,0.005c-0.007,1.065-0.547,2.127-1.619,2.716l-0.249,0.136
                  c-3.155,1.733-6.978,1.733-10.133,0l-0.153-0.084c-1.126-0.619-1.659-1.762-1.61-2.882l-0.138-0.005l-0.328,11.198
                  l-0.015,0.55c0.136,0.984,0.7,1.913,1.698,2.46l0.165,0.091c3.389,1.861,7.496,1.861,10.888,0l0.266-0.148
                  c0.998-0.545,1.56-1.474,1.701-2.458l-0.005-0.143L316.751,225.906z"/>
                <path class="st49" d="M316.285,215.497c-0.173,0.801-0.665,1.541-1.484,1.992l-0.239,0.131c-3.039,1.669-6.72,1.669-9.759,0
                  l-0.145-0.081c-1.082-0.594-1.595-1.686-1.551-2.758l-0.071-0.002l-0.323,11.016l0.138,0.005
                  c-0.049,1.119,0.483,2.263,1.61,2.882l0.153,0.084c3.155,1.733,6.978,1.733,10.133,0l0.249-0.136
                  c1.072-0.589,1.612-1.652,1.619-2.716l0.136-0.005l-0.303-10.414L316.285,215.497z"/>
                <path class="st146" d="M316.101,206.46c0.002,0.037,0.002,0.076,0.002,0.113c0,0.993-0.5,1.987-1.499,2.534l-0.229,0.126
                  c-2.921,1.605-6.461,1.605-9.382,0l-0.141-0.077c-0.993-0.547-1.494-1.528-1.499-2.514h-0.081l-0.237,8.134l0.071,0.002
                  c-0.044,1.072,0.468,2.164,1.551,2.758l0.145,0.081c3.039,1.669,6.719,1.669,9.759,0l0.239-0.131
                  c0.818-0.451,1.311-1.191,1.484-1.992l0.163-0.005l-0.264-9.032H316.101z"/>
                <path class="st147" d="M314.663,204.077c-2.973-1.726-6.633-1.755-9.635-0.081l-0.19,0.106
                  c-0.996,0.555-1.489,1.548-1.484,2.541c0.005,0.986,0.505,1.967,1.499,2.514l0.141,0.077c2.921,1.605,6.461,1.605,9.382,0
                  l0.229-0.126c0.998-0.547,1.499-1.541,1.499-2.534c0-0.037,0-0.077-0.002-0.113
                  C316.066,205.531,315.588,204.612,314.663,204.077z"/>
                <path class="st136" d="M314.045,205.143c-1.295-0.752-2.771-1.149-4.269-1.149c-1.448,0-2.882,0.373-4.147,1.078l-0.191,0.106
                  c-0.536,0.299-0.854,0.844-0.851,1.459c0.001,0.291,0.088,1.015,0.855,1.438l0.145,0.079c1.252,0.688,2.669,1.051,4.097,1.051
                  c1.429,0,2.846-0.363,4.098-1.052l0.23-0.126c0.538-0.295,0.859-0.838,0.859-1.453l0-0.029l-0.002-0.038
                  C314.847,205.93,314.548,205.434,314.045,205.143z"/>
                <path class="st56" d="M302.371,237.541l0.015-0.55h-0.049C302.331,237.176,302.344,237.358,302.371,237.541z"/>
                <path class="st56" d="M301.355,272.331l0.025-0.885h-0.054C301.301,271.742,301.311,272.038,301.355,272.331z"/>
                <path class="st146" d="M318.102,272.272c-0.163,1.107-0.806,2.154-1.93,2.77l-0.303,0.168c-3.86,2.12-8.536,2.12-12.396,0
                  l-0.187-0.101c-1.126-0.621-1.77-1.669-1.93-2.778l-0.281,9.574h-0.025c0,0.106,0.005,0.215,0.017,0.326l-0.007,0.222h0.03
                  c0.158,1.233,0.826,2.608,2.001,3.251l0.192,0.106c3.976,2.184,8.795,2.184,12.773,0l0.313-0.172
                  c1.146-0.629,1.804-2.004,1.982-3.185h0.047l-0.01-0.308L318.102,272.272z"/>
              </g>
            </g>
            <path class="st133" d="M318.102,272.272l-0.025-0.826l-0.333-11.432l-0.338-11.543l-0.318-10.984l-0.005-0.143l-0.333-11.437
              l-0.303-10.414l-0.264-9.032h-0.084c0.002,0.037,0.002,0.076,0.002,0.113c0,0.993-0.5,1.987-1.499,2.534l-0.229,0.126
              c-0.396,0.217-0.803,0.402-1.218,0.561v75.163c0,0.752-0.132,1.461-0.371,2.115c1.127-0.272,2.228-0.691,3.27-1.263l0.313-0.172
              c1.146-0.629,1.804-2.004,1.982-3.185h0.047l-0.01-0.308L318.102,272.272z"/>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_121_" gradientUnits="userSpaceOnUse" x1="331.28" y1="269.9429" x2="372.7125" y2="269.9429">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st519" points="331.28,273.881 372.713,273.881 372.713,266.005 333.053,266.005 			"/>
          <g>
            <g id="XMLID_22_">
              <g>
                <path class="st56" d="M341.106,259.016c0.042-0.274,0.052-0.55,0.034-0.826h-0.059L341.106,259.016z"/>
                <path class="st49" d="M340.749,246.758l-0.123,0.003c-0.089,1.131-0.7,2.226-1.841,2.852l-0.286,0.158
                  c-3.626,1.989-8.016,1.989-11.642,0l-0.175-0.096c-1.275-0.7-1.888-1.984-1.851-3.251l-0.101-0.002l-0.345,11.77l-0.025,0.885
                  c0.16,1.109,0.803,2.157,1.93,2.778l0.187,0.101c3.86,2.12,8.536,2.12,12.396,0l0.303-0.168
                  c1.124-0.616,1.767-1.664,1.93-2.771l-0.025-0.826L340.749,246.758z"/>
                <path class="st146" d="M340.364,235.217c-0.108,1.067-0.7,2.09-1.775,2.679l-0.276,0.153c-3.51,1.925-7.757,1.925-11.267,0
                  l-0.168-0.094c-1.117-0.611-1.708-1.689-1.785-2.795h-0.037l-0.328,11.26l0.101,0.002c-0.037,1.267,0.577,2.551,1.851,3.251
                  l0.175,0.096c3.626,1.989,8.016,1.989,11.642,0l0.286-0.158c1.141-0.626,1.753-1.721,1.841-2.852l0.123-0.003l-0.338-11.543
                  L340.364,235.217z"/>
                <path class="st49" d="M338.392,226.688l-0.266,0.148c-3.392,1.861-7.498,1.861-10.888,0l-0.165-0.091
                  c-0.998-0.547-1.563-1.476-1.698-2.46l-0.318,10.875h0.037c0.076,1.107,0.668,2.184,1.785,2.795l0.168,0.094
                  c3.51,1.925,7.757,1.925,11.267,0l0.276-0.153c1.075-0.589,1.666-1.612,1.775-2.679l0.047-0.002l-0.318-10.984
                  C339.953,225.215,339.391,226.144,338.392,226.688z"/>
                <path class="st56" d="M340.093,224.231c0.007-0.047,0.012-0.096,0.017-0.143h-0.022L340.093,224.231z"/>
                <path class="st146" d="M339.755,212.651l-0.136,0.005c-0.007,1.065-0.547,2.127-1.619,2.716l-0.249,0.136
                  c-3.155,1.733-6.978,1.733-10.133,0l-0.153-0.084c-1.127-0.619-1.659-1.762-1.61-2.882l-0.138-0.005l-0.328,11.198
                  l-0.015,0.55c0.136,0.983,0.7,1.913,1.698,2.46l0.165,0.091c3.389,1.861,7.496,1.861,10.888,0l0.266-0.148
                  c0.998-0.545,1.56-1.474,1.701-2.458l-0.005-0.143L339.755,212.651z"/>
                <path class="st49" d="M339.29,202.241c-0.173,0.801-0.666,1.541-1.484,1.992l-0.239,0.131c-3.039,1.669-6.719,1.669-9.759,0
                  l-0.145-0.081c-1.082-0.594-1.595-1.686-1.55-2.758l-0.072-0.003l-0.323,11.016l0.138,0.005
                  c-0.049,1.119,0.483,2.263,1.61,2.882l0.153,0.084c3.155,1.733,6.978,1.733,10.133,0l0.249-0.136
                  c1.072-0.589,1.612-1.652,1.619-2.716l0.136-0.005l-0.303-10.414L339.29,202.241z"/>
                <path class="st146" d="M339.105,193.205c0.003,0.037,0.003,0.076,0.003,0.113c0,0.993-0.5,1.987-1.499,2.534l-0.229,0.126
                  c-2.921,1.605-6.461,1.605-9.382,0l-0.141-0.077c-0.993-0.547-1.494-1.528-1.499-2.514h-0.081l-0.237,8.134l0.072,0.003
                  c-0.044,1.072,0.468,2.164,1.55,2.758l0.145,0.081c3.039,1.669,6.72,1.669,9.759,0l0.239-0.131
                  c0.818-0.451,1.311-1.191,1.484-1.992l0.163-0.005l-0.264-9.031H339.105z"/>
                <path class="st147" d="M337.668,190.821c-2.973-1.725-6.633-1.755-9.635-0.081l-0.19,0.106
                  c-0.996,0.555-1.489,1.548-1.484,2.541c0.005,0.986,0.505,1.967,1.499,2.514l0.141,0.077c2.921,1.605,6.461,1.605,9.382,0
                  l0.229-0.126c0.998-0.547,1.499-1.541,1.499-2.534c0-0.037,0-0.076-0.003-0.113
                  C339.07,192.275,338.592,191.356,337.668,190.821z"/>
                <path class="st136" d="M337.049,191.887c-1.295-0.752-2.772-1.149-4.269-1.149c-1.448,0-2.882,0.373-4.147,1.078l-0.191,0.106
                  c-0.536,0.298-0.854,0.844-0.851,1.458c0.002,0.291,0.088,1.015,0.855,1.438l0.145,0.079c1.252,0.688,2.669,1.051,4.097,1.051
                  c1.429,0,2.846-0.364,4.098-1.052l0.229-0.126c0.538-0.295,0.859-0.838,0.859-1.453l0-0.029l-0.002-0.038
                  C337.852,192.675,337.552,192.178,337.049,191.887z"/>
                <path class="st56" d="M325.375,224.285l0.015-0.55h-0.049C325.335,223.92,325.348,224.103,325.375,224.285z"/>
                <path class="st56" d="M324.359,259.075l0.025-0.885h-0.054C324.305,258.486,324.315,258.782,324.359,259.075z"/>
                <path class="st146" d="M341.106,259.016c-0.163,1.107-0.806,2.154-1.93,2.771l-0.303,0.168c-3.86,2.12-8.536,2.12-12.396,0
                  l-0.187-0.101c-1.126-0.621-1.77-1.669-1.93-2.778l-0.281,9.574h-0.025c0,0.106,0.005,0.215,0.017,0.325l-0.007,0.222h0.03
                  c0.158,1.232,0.826,2.608,2.002,3.251l0.192,0.106c3.976,2.184,8.795,2.184,12.773,0l0.313-0.173
                  c1.146-0.629,1.804-2.004,1.982-3.185h0.047l-0.01-0.308L341.106,259.016z"/>
              </g>
            </g>
            <path class="st133" d="M341.106,259.016l-0.025-0.826l-0.333-11.432l-0.338-11.543l-0.318-10.984l-0.005-0.143l-0.333-11.437
              l-0.303-10.414l-0.264-9.031h-0.084c0.003,0.037,0.003,0.076,0.003,0.113c0,0.993-0.5,1.987-1.499,2.534l-0.229,0.126
              c-0.395,0.217-0.803,0.402-1.218,0.561v75.163c0,0.752-0.132,1.461-0.371,2.115c1.127-0.272,2.228-0.691,3.27-1.263l0.313-0.173
              c1.146-0.629,1.804-2.004,1.982-3.185h0.047l-0.01-0.308L341.106,259.016z"/>
          </g>
        </g>
        <g>
          <polygon class="st210" points="345.94,277.716 347.686,278.774 347.686,275.787 345.94,274.753 			"/>
          <polygon class="st210" points="348.698,279.463 350.444,280.521 350.444,277.534 348.698,276.501 			"/>
          <polygon class="st210" points="351.457,281.211 353.203,282.269 353.203,279.282 351.457,278.248 			"/>
          <polygon class="st210" points="354.216,282.958 355.962,284.016 355.962,281.029 354.216,279.995 			"/>
          <polygon class="st210" points="356.974,284.705 358.72,285.764 358.72,282.777 356.974,281.743 			"/>
          <polygon class="st210" points="359.733,286.453 361.479,287.511 361.479,284.524 359.733,283.49 			"/>
        </g>
        <g>
          <linearGradient id="SVGID_122_" gradientUnits="userSpaceOnUse" x1="319.5645" y1="296.3508" x2="360.9969" y2="296.3508">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st520" points="319.565,295.03 327.507,295.03 327.804,299.304 360.997,300.182 360.997,292.52 325.034,292.553 
                  "/>
          <g>
            <g>
              <g id="XMLID_21_">
                <g>
                  <polygon class="st210" points="349.265,272.509 349.327,272.472 349.265,272.435 							"/>
                  <polygon class="st210" points="349.265,268.602 349.327,268.565 349.265,268.528 							"/>
                  <polygon class="st210" points="344.654,258.775 344.654,264.461 344.678,264.449 345.267,264.124 348.748,262.211 
                    349.241,261.94 349.86,261.599 349.86,255.832 347.257,256.366 							"/>
                  <polygon class="st507" points="339.46,251.875 347.257,256.366 349.86,255.832 341.858,251.547 							"/>
                  <polygon class="st210" points="349.265,261.986 349.265,268.097 349.265,268.528 349.327,268.565 349.265,268.602 
                    349.265,268.975 349.265,272.004 349.265,272.435 349.327,272.472 349.265,272.509 349.265,272.881 349.265,275.911 
                    349.265,276.34 349.327,276.377 349.265,276.414 349.265,276.786 349.265,286.877 349.86,286.522 349.86,276.69 
                    349.86,276.441 349.86,276.256 349.86,276.076 349.86,272.785 349.86,272.536 349.86,272.349 349.86,272.172 349.86,268.878 
                    349.86,268.63 349.86,268.442 349.86,268.265 349.86,261.599 349.241,261.94 							"/>
                  <polygon class="st507" points="348.748,277.087 349.265,276.786 349.265,276.414 348.748,276.707 							"/>
                  <polygon class="st507" points="348.748,273.182 349.265,272.881 349.265,272.509 348.748,272.803 							"/>
                  <polygon class="st507" points="348.748,269.275 349.265,268.975 349.265,268.602 348.748,268.896 							"/>
                  <g>
                    <polygon class="st509" points="348.748,286.559 349.265,286.877 349.265,276.786 348.748,277.087 								"/>
                    <polygon class="st509" points="348.748,275.613 348.748,276.037 348.748,276.707 349.265,276.414 349.265,276.34 
                      349.265,275.911 349.265,272.881 348.748,273.182 								"/>
                    <polygon class="st509" points="348.748,271.706 348.748,272.132 348.748,272.803 349.265,272.509 349.265,272.435 
                      349.265,272.004 349.265,268.975 348.748,269.275 								"/>
                    <polygon class="st509" points="348.748,262.211 348.748,267.799 348.748,268.225 348.748,268.896 349.265,268.602 
                      349.265,268.528 349.265,268.097 349.265,261.986 349.241,261.94 								"/>
                  </g>
                  <polygon class="st507" points="345.272,279.11 348.748,277.087 348.748,276.707 345.272,278.684 							"/>
                  <polygon class="st507" points="346.655,274.4 348.748,273.182 348.748,272.803 346.3,274.195 							"/>
                  <polygon class="st507" points="346.655,270.493 348.748,269.275 348.748,268.896 346.3,270.288 							"/>
                  <polygon class="st211" points="336.635,254.118 344.654,258.775 347.257,256.366 339.46,251.875 							"/>
                  <polygon class="st507" points="345.95,270.486 346.307,270.695 346.655,270.493 346.3,270.288 							"/>
                  <polygon class="st507" points="345.953,274.392 346.307,274.602 346.655,274.4 346.3,274.195 							"/>
                  <polygon class="st507" points="345.272,270.87 345.272,271.299 346.307,270.695 345.95,270.486 							"/>
                  <polygon class="st507" points="345.272,274.78 345.272,275.206 346.307,274.602 345.953,274.392 							"/>
                  <polygon class="st210" points="344.678,264.449 344.678,265.442 344.678,265.832 344.678,269.349 344.678,269.739 
                    344.678,271.208 344.678,271.644 344.678,273.256 344.678,273.643 344.678,275.117 344.678,275.551 344.678,279.022 
                    344.678,279.458 344.678,289.411 345.272,289.056 345.272,279.11 345.272,278.684 345.272,275.206 345.272,274.78 
                    345.272,273.993 345.272,273.599 345.272,271.299 345.272,270.87 345.272,270.089 345.272,269.692 345.272,266.182 
                    345.272,265.785 345.272,264.133 345.267,264.124 							"/>
                  <polygon class="st509" points="344.597,279.505 344.678,279.458 344.678,279.022 344.597,279.068 							"/>
                  <polygon class="st509" points="344.597,275.598 344.678,275.551 344.678,275.117 344.597,275.164 							"/>
                  <polygon class="st509" points="344.597,271.691 344.678,271.644 344.678,271.208 344.597,271.255 							"/>
                  <polygon class="st509" points="344.654,258.775 336.635,254.118 336.635,259.689 336.655,259.701 337.17,260.007 
                    337.764,260.362 341.39,262.519 341.922,262.834 342.502,263.18 344.161,264.168 344.161,265.526 344.161,269.048 
                    344.161,269.433 344.161,271.001 344.161,271.435 344.161,272.955 344.161,273.34 344.161,274.91 344.161,275.342 
                    344.161,278.815 344.161,279.248 344.161,289.093 344.678,289.411 344.678,279.458 344.597,279.505 344.597,279.068 
                    344.678,279.022 344.678,275.551 344.597,275.598 344.597,275.164 344.678,275.117 344.678,273.643 344.678,273.256 
                    344.678,271.644 344.597,271.691 344.597,271.255 344.678,271.208 344.678,269.739 344.678,269.349 344.678,265.832 
                    344.678,264.422 344.654,264.407 							"/>
                  <polygon class="st507" points="341.908,282.66 342.502,282.305 342.502,278.272 341.908,277.922 							"/>
                  <polygon class="st507" points="341.908,277.511 342.502,277.856 342.502,274.365 341.908,274.018 							"/>
                  <polygon class="st507" points="341.908,273.606 342.502,273.951 342.502,272.364 341.908,272.016 							"/>
                  <g>
                    <polygon class="st210" points="346.307,274.602 348.748,276.037 348.748,275.613 346.655,274.4 								"/>
                    <polygon class="st210" points="345.272,273.993 345.953,274.392 346.3,274.195 345.272,273.599 								"/>
                    <polygon class="st210" points="341.908,271.755 341.908,272.12 342.502,272.467 344.161,273.443 344.161,273.059 
                      342.502,272.098 								"/>
                  </g>
                  <polygon class="st507" points="341.908,271.755 342.502,272.098 342.502,270.458 341.908,270.108 							"/>
                  <polygon class="st507" points="341.908,269.697 342.502,270.042 342.502,268.457 341.908,268.109 							"/>
                  <g>
                    <polygon class="st210" points="346.307,270.695 348.748,272.132 348.748,271.706 346.655,270.493 								"/>
                    <polygon class="st210" points="345.272,270.089 345.95,270.486 346.3,270.288 345.272,269.692 								"/>
                    <polygon class="st210" points="341.908,267.848 341.908,268.213 342.502,268.56 344.161,269.536 344.161,269.152 
                      342.502,268.191 								"/>
                  </g>
                  <polygon class="st507" points="341.908,267.848 342.502,268.191 342.502,264.55 341.908,264.202 							"/>
                  <g>
                    <polygon class="st210" points="345.272,266.182 348.748,268.225 348.748,267.799 345.272,265.785 								"/>
                    <polygon class="st210" points="341.908,263.838 341.908,264.202 342.502,264.55 344.161,265.526 344.161,265.142 
                      342.502,264.18 								"/>
                  </g>
                  <polygon class="st507" points="341.908,262.859 341.908,263.838 342.502,264.18 342.502,263.18 341.922,262.834 							"/>
                  <g>
                    <polygon class="st509" points="341.39,264.232 341.9,263.941 341.9,263.833 341.908,263.838 341.908,262.859 
                      341.922,262.834 341.39,262.519 								"/>
                    <polygon class="st509" points="341.39,282.342 341.908,282.66 341.908,277.922 341.39,277.619 								"/>
                    <polygon class="st509" points="341.39,277.212 341.908,277.511 341.908,274.018 341.39,273.712 								"/>
                    <polygon class="st509" points="341.9,272.12 341.39,272.416 341.39,273.308 341.908,273.606 341.908,272.016 341.9,272.011 
                                      "/>
                    <polygon class="st509" points="341.39,272.046 341.9,271.755 341.9,271.647 341.908,271.651 341.908,270.108 
                      341.39,269.805 								"/>
                    <polygon class="st509" points="341.9,268.213 341.39,268.509 341.39,269.398 341.908,269.697 341.908,268.109 
                      341.9,268.104 								"/>
                    <polygon class="st509" points="341.9,264.202 341.39,264.498 341.39,268.139 341.9,267.848 341.9,267.74 341.908,267.745 
                      341.908,264.202 341.9,264.198 								"/>
                  </g>
                  <polygon class="st210" points="337.747,266.199 337.747,266.621 341.39,264.498 341.9,264.202 341.9,264.094 341.9,263.838 
                    341.39,264.129 							"/>
                  <g>
                    <polygon class="st210" points="340.293,268.763 340.626,268.955 341.39,268.509 341.9,268.213 341.9,268.104 341.9,267.848 
                      341.39,268.139 								"/>
                    <polygon class="st210" points="337.747,270.209 337.747,270.631 340.283,269.155 339.95,268.957 								"/>
                  </g>
                  <g>
                    <polygon class="st210" points="340.291,272.67 340.623,272.862 341.39,272.416 341.9,272.12 341.9,272.011 341.9,271.755 
                      341.39,272.046 								"/>
                    <polygon class="st210" points="337.747,274.116 337.747,274.538 340.281,273.061 339.948,272.864 								"/>
                  </g>
                  <polygon class="st507" points="337.153,260.039 337.153,266.643 337.747,266.303 337.747,260.392 337.764,260.362 
                    337.17,260.007 							"/>
                  <polygon class="st507" points="337.153,284.977 337.747,284.654 337.747,275.477 337.153,275.127 							"/>
                  <polygon class="st509" points="341.908,277.511 341.39,277.212 337.747,275.102 337.153,274.757 337.153,274.883 
                    337.153,275.127 337.747,275.477 341.39,277.619 341.908,277.922 342.502,278.272 344.161,279.248 344.161,278.815 
                    342.502,277.856 							"/>
                  <polygon class="st507" points="337.747,275.102 337.747,274.538 337.261,274.819 							"/>
                  <polygon class="st507" points="337.153,274.757 337.261,274.819 337.747,274.538 337.747,274.116 337.153,274.454 							"/>
                  <polygon class="st507" points="337.153,274.454 337.747,274.116 337.747,271.573 337.153,271.223 							"/>
                  <polygon class="st509" points="341.908,273.606 341.39,273.308 340.623,272.862 340.291,272.67 337.747,271.198 
                    337.153,270.853 337.153,270.976 337.153,271.223 337.747,271.573 339.948,272.864 340.281,273.061 341.39,273.712 
                    341.908,274.018 342.502,274.365 344.161,275.342 344.161,274.91 342.502,273.951 							"/>
                  <polygon class="st507" points="337.747,271.198 337.747,270.631 337.259,270.915 							"/>
                  <polygon class="st507" points="337.153,270.853 337.259,270.915 337.747,270.631 337.747,270.209 337.153,270.55 							"/>
                  <polygon class="st507" points="337.153,270.55 337.747,270.209 337.747,267.663 337.153,267.313 							"/>
                  <polygon class="st509" points="341.908,269.697 341.39,269.398 340.626,268.955 340.293,268.763 337.747,267.289 
                    337.153,266.943 337.153,267.069 337.153,267.313 337.747,267.663 339.95,268.957 340.283,269.155 341.39,269.805 
                    341.908,270.108 342.502,270.458 344.161,271.435 344.161,271.001 342.502,270.042 							"/>
                  <polygon class="st507" points="337.747,267.289 337.747,266.724 337.261,267.005 							"/>
                  <polygon class="st507" points="337.153,266.943 337.261,267.005 337.747,266.724 337.747,266.303 337.153,266.643 							"/>
                  <polygon class="st509" points="337.153,275.127 337.153,274.883 336.943,275.004 							"/>
                  <polygon class="st509" points="337.153,274.757 337.153,274.454 336.886,274.605 							"/>
                  <polygon class="st509" points="337.153,271.223 337.153,270.976 336.941,271.099 							"/>
                  <polygon class="st509" points="337.153,270.853 337.153,270.55 336.886,270.7 							"/>
                  <polygon class="st509" points="337.153,267.313 337.153,267.069 336.943,267.19 							"/>
                  <polygon class="st509" points="337.153,266.943 337.153,266.643 336.889,266.791 							"/>
                  <polygon class="st509" points="336.635,259.736 336.635,266.936 336.635,267.37 336.635,270.843 336.635,271.277 
                    336.635,274.747 336.635,275.184 336.635,284.701 337.153,284.977 337.153,275.127 336.943,275.004 337.153,274.883 
                    337.153,274.757 336.886,274.605 337.153,274.454 337.153,271.223 336.941,271.099 337.153,270.976 337.153,270.853 
                    336.886,270.7 337.153,270.55 337.153,267.313 336.943,267.19 337.153,267.069 337.153,266.943 336.889,266.791 
                    337.153,266.643 337.153,260.039 337.17,260.007 336.655,259.701 							"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g id="XMLID_20_">
                <g>
                  <polygon class="st210" points="366.733,262.261 366.795,262.224 366.733,262.187 							"/>
                  <polygon class="st210" points="366.733,258.354 366.795,258.317 366.733,258.28 							"/>
                  <polygon class="st210" points="362.121,248.527 362.121,254.213 362.146,254.201 362.735,253.876 366.216,251.963 
                    366.709,251.692 367.327,251.351 367.327,245.584 364.724,246.118 							"/>
                  <polygon class="st507" points="356.928,241.627 364.724,246.118 367.327,245.584 359.326,241.299 							"/>
                  <polygon class="st210" points="366.733,251.738 366.733,257.849 366.733,258.28 366.795,258.317 366.733,258.354 
                    366.733,258.727 366.733,261.756 366.733,262.187 366.795,262.224 366.733,262.261 366.733,262.633 366.733,265.663 
                    366.733,266.092 366.795,266.129 366.733,266.166 366.733,266.538 366.733,276.629 367.327,276.275 367.327,266.442 
                    367.327,266.193 367.327,266.008 367.327,265.828 367.327,262.537 367.327,262.288 367.327,262.101 367.327,261.924 
                    367.327,258.63 367.327,258.381 367.327,258.194 367.327,258.017 367.327,251.351 366.709,251.692 							"/>
                  <polygon class="st507" points="366.216,266.839 366.733,266.538 366.733,266.166 366.216,266.459 							"/>
                  <polygon class="st507" points="366.216,262.934 366.733,262.633 366.733,262.261 366.216,262.555 							"/>
                  <polygon class="st507" points="366.216,259.027 366.733,258.727 366.733,258.354 366.216,258.648 							"/>
                  <g>
                    <polygon class="st509" points="366.216,276.311 366.733,276.629 366.733,266.538 366.216,266.839 								"/>
                    <polygon class="st509" points="366.216,265.365 366.216,265.789 366.216,266.459 366.733,266.166 366.733,266.092 
                      366.733,265.663 366.733,262.633 366.216,262.934 								"/>
                    <polygon class="st509" points="366.216,261.458 366.216,261.884 366.216,262.555 366.733,262.261 366.733,262.187 
                      366.733,261.756 366.733,258.727 366.216,259.027 								"/>
                    <polygon class="st509" points="366.216,251.963 366.216,257.551 366.216,257.977 366.216,258.648 366.733,258.354 
                      366.733,258.28 366.733,257.849 366.733,251.738 366.709,251.692 								"/>
                  </g>
                  <polygon class="st507" points="362.74,268.862 366.216,266.839 366.216,266.459 362.74,268.436 							"/>
                  <polygon class="st507" points="364.123,264.152 366.216,262.934 366.216,262.555 363.768,263.947 							"/>
                  <polygon class="st507" points="364.123,260.245 366.216,259.027 366.216,258.648 363.768,260.04 							"/>
                  <polygon class="st211" points="354.103,243.87 362.121,248.527 364.724,246.118 356.928,241.627 							"/>
                  <polygon class="st507" points="363.418,260.238 363.775,260.447 364.123,260.245 363.768,260.04 							"/>
                  <polygon class="st507" points="363.42,264.145 363.775,264.354 364.123,264.152 363.768,263.947 							"/>
                  <polygon class="st507" points="362.74,260.622 362.74,261.051 363.775,260.447 363.418,260.238 							"/>
                  <polygon class="st507" points="362.74,264.532 362.74,264.958 363.775,264.354 363.42,264.145 							"/>
                  <polygon class="st210" points="362.146,254.201 362.146,255.194 362.146,255.584 362.146,259.101 362.146,259.491 
                    362.146,260.96 362.146,261.396 362.146,263.008 362.146,263.395 362.146,264.869 362.146,265.303 362.146,268.774 
                    362.146,269.21 362.146,279.163 362.74,278.808 362.74,268.862 362.74,268.436 362.74,264.958 362.74,264.532 
                    362.74,263.745 362.74,263.351 362.74,261.051 362.74,260.622 362.74,259.841 362.74,259.444 362.74,255.934 362.74,255.537 
                    362.74,253.885 362.735,253.876 							"/>
                  <polygon class="st509" points="362.065,269.257 362.146,269.21 362.146,268.774 362.065,268.821 							"/>
                  <polygon class="st509" points="362.065,265.35 362.146,265.303 362.146,264.869 362.065,264.916 							"/>
                  <polygon class="st509" points="362.065,261.443 362.146,261.396 362.146,260.96 362.065,261.007 							"/>
                  <polygon class="st509" points="362.121,248.527 354.103,243.87 354.103,249.441 354.123,249.454 354.638,249.759 
                    355.232,250.114 358.858,252.271 359.39,252.586 359.969,252.931 361.628,253.92 361.628,255.278 361.628,258.801 
                    361.628,259.185 361.628,260.753 361.628,261.187 361.628,262.707 361.628,263.092 361.628,264.662 361.628,265.094 
                    361.628,268.567 361.628,269 361.628,278.845 362.146,279.163 362.146,269.21 362.065,269.257 362.065,268.821 
                    362.146,268.774 362.146,265.303 362.065,265.35 362.065,264.916 362.146,264.869 362.146,263.395 362.146,263.008 
                    362.146,261.396 362.065,261.443 362.065,261.007 362.146,260.96 362.146,259.491 362.146,259.101 362.146,255.584 
                    362.146,254.174 362.121,254.16 							"/>
                  <polygon class="st507" points="359.375,272.412 359.969,272.057 359.969,268.024 359.375,267.674 							"/>
                  <polygon class="st507" points="359.375,267.263 359.969,267.608 359.969,264.117 359.375,263.77 							"/>
                  <polygon class="st507" points="359.375,263.358 359.969,263.703 359.969,262.116 359.375,261.768 							"/>
                  <g>
                    <polygon class="st210" points="363.775,264.354 366.216,265.789 366.216,265.365 364.123,264.152 								"/>
                    <polygon class="st210" points="362.74,263.745 363.42,264.144 363.768,263.947 362.74,263.351 								"/>
                    <polygon class="st210" points="359.375,261.507 359.375,261.872 359.969,262.219 361.628,263.196 361.628,262.811 
                      359.969,261.85 								"/>
                  </g>
                  <polygon class="st507" points="359.375,261.507 359.969,261.85 359.969,260.21 359.375,259.86 							"/>
                  <polygon class="st507" points="359.375,259.449 359.969,259.794 359.969,258.209 359.375,257.861 							"/>
                  <g>
                    <polygon class="st210" points="363.775,260.447 366.216,261.884 366.216,261.458 364.123,260.245 								"/>
                    <polygon class="st210" points="362.74,259.841 363.418,260.238 363.768,260.04 362.74,259.444 								"/>
                    <polygon class="st210" points="359.375,257.6 359.375,257.965 359.969,258.313 361.628,259.289 361.628,258.904 
                      359.969,257.943 								"/>
                  </g>
                  <polygon class="st507" points="359.375,257.6 359.969,257.943 359.969,254.302 359.375,253.955 							"/>
                  <g>
                    <polygon class="st210" points="362.74,255.934 366.216,257.977 366.216,257.551 362.74,255.537 								"/>
                    <polygon class="st210" points="359.375,253.59 359.375,253.955 359.969,254.302 361.628,255.278 361.628,254.894 
                      359.969,253.932 								"/>
                  </g>
                  <polygon class="st507" points="359.375,252.611 359.375,253.59 359.969,253.932 359.969,252.931 359.39,252.586 							"/>
                  <g>
                    <polygon class="st509" points="358.858,253.984 359.368,253.693 359.368,253.585 359.375,253.59 359.375,252.611 
                      359.39,252.586 358.858,252.271 								"/>
                    <polygon class="st509" points="358.858,272.094 359.375,272.412 359.375,267.674 358.858,267.371 								"/>
                    <polygon class="st509" points="358.858,266.964 359.375,267.263 359.375,263.77 358.858,263.464 								"/>
                    <polygon class="st509" points="359.368,261.872 358.858,262.168 358.858,263.06 359.375,263.358 359.375,261.768 
                      359.368,261.763 								"/>
                    <polygon class="st509" points="358.858,261.798 359.368,261.507 359.368,261.398 359.375,261.404 359.375,259.86 
                      358.858,259.557 								"/>
                    <polygon class="st509" points="359.368,257.965 358.858,258.261 358.858,259.151 359.375,259.449 359.375,257.861 
                      359.368,257.856 								"/>
                    <polygon class="st509" points="359.368,253.955 358.858,254.25 358.858,257.891 359.368,257.6 359.368,257.492 
                      359.375,257.497 359.375,253.955 359.368,253.95 								"/>
                  </g>
                  <polygon class="st210" points="355.215,255.951 355.215,256.373 358.858,254.25 359.368,253.954 359.368,253.846 
                    359.368,253.59 358.858,253.88 							"/>
                  <g>
                    <polygon class="st210" points="357.761,258.515 358.094,258.707 358.858,258.261 359.368,257.965 359.368,257.856 
                      359.368,257.6 358.858,257.891 								"/>
                    <polygon class="st210" points="355.215,259.962 355.215,260.383 357.751,258.906 357.418,258.709 								"/>
                  </g>
                  <g>
                    <polygon class="st210" points="357.758,262.422 358.091,262.614 358.858,262.168 359.368,261.872 359.368,261.763 
                      359.368,261.507 358.858,261.798 								"/>
                    <polygon class="st210" points="355.215,263.868 355.215,264.29 357.749,262.813 357.416,262.616 								"/>
                  </g>
                  <polygon class="st507" points="354.621,249.791 354.621,256.395 355.215,256.055 355.215,250.144 355.232,250.114 
                    354.638,249.759 							"/>
                  <polygon class="st507" points="354.621,274.729 355.215,274.406 355.215,265.229 354.621,264.879 							"/>
                  <polygon class="st509" points="359.375,267.263 358.858,266.964 355.215,264.854 354.621,264.509 354.621,264.635 
                    354.621,264.879 355.215,265.229 358.858,267.371 359.375,267.674 359.969,268.024 361.628,269 361.628,268.567 
                    359.969,267.608 							"/>
                  <polygon class="st507" points="355.215,264.854 355.215,264.29 354.729,264.571 							"/>
                  <polygon class="st507" points="354.621,264.509 354.729,264.571 355.215,264.29 355.215,263.868 354.621,264.206 							"/>
                  <polygon class="st507" points="354.621,264.206 355.215,263.868 355.215,261.325 354.621,260.975 							"/>
                  <polygon class="st509" points="359.375,263.358 358.858,263.06 358.091,262.614 357.758,262.422 355.215,260.95 
                    354.621,260.605 354.621,260.728 354.621,260.975 355.215,261.325 357.416,262.616 357.749,262.813 358.858,263.464 
                    359.375,263.77 359.969,264.117 361.628,265.094 361.628,264.662 359.969,263.703 							"/>
                  <polygon class="st507" points="355.215,260.95 355.215,260.383 354.727,260.666 							"/>
                  <polygon class="st507" points="354.621,260.605 354.727,260.666 355.215,260.383 355.215,259.962 354.621,260.302 							"/>
                  <polygon class="st507" points="354.621,260.302 355.215,259.962 355.215,257.415 354.621,257.065 							"/>
                  <polygon class="st509" points="359.375,259.449 358.858,259.151 358.094,258.707 357.761,258.515 355.215,257.041 
                    354.621,256.695 354.621,256.821 354.621,257.065 355.215,257.415 357.418,258.709 357.751,258.906 358.858,259.557 
                    359.375,259.86 359.969,260.21 361.628,261.187 361.628,260.753 359.969,259.794 							"/>
                  <polygon class="st507" points="355.215,257.041 355.215,256.476 354.729,256.757 							"/>
                  <polygon class="st507" points="354.621,256.695 354.729,256.757 355.215,256.476 355.215,256.055 354.621,256.395 							"/>
                  <polygon class="st509" points="354.621,264.879 354.621,264.635 354.411,264.756 							"/>
                  <polygon class="st509" points="354.621,264.509 354.621,264.206 354.354,264.357 							"/>
                  <polygon class="st509" points="354.621,260.975 354.621,260.728 354.408,260.851 							"/>
                  <polygon class="st509" points="354.621,260.605 354.621,260.302 354.354,260.452 							"/>
                  <polygon class="st509" points="354.621,257.065 354.621,256.821 354.411,256.942 							"/>
                  <polygon class="st509" points="354.621,256.695 354.621,256.395 354.357,256.543 							"/>
                  <polygon class="st509" points="354.103,249.488 354.103,256.688 354.103,257.122 354.103,260.595 354.103,261.029 
                    354.103,264.499 354.103,264.936 354.103,274.453 354.621,274.729 354.621,264.879 354.411,264.756 354.621,264.635 
                    354.621,264.509 354.354,264.357 354.621,264.206 354.621,260.975 354.408,260.851 354.621,260.728 354.621,260.605 
                    354.354,260.452 354.621,260.302 354.621,257.065 354.411,256.942 354.621,256.821 354.621,256.695 354.357,256.543 
                    354.621,256.395 354.621,249.791 354.638,249.759 354.123,249.454 							"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g id="XMLID_19_">
                <g>
                  <polygon class="st210" points="331.798,282.757 331.859,282.72 331.798,282.683 							"/>
                  <polygon class="st210" points="331.798,278.85 331.859,278.813 331.798,278.776 							"/>
                  <polygon class="st210" points="327.186,269.023 327.186,274.709 327.21,274.697 327.799,274.372 331.28,272.459 
                    331.773,272.188 332.392,271.847 332.392,266.079 329.789,266.614 							"/>
                  <polygon class="st507" points="321.992,262.123 329.789,266.614 332.392,266.079 324.39,261.795 							"/>
                  <polygon class="st210" points="331.798,272.234 331.798,278.345 331.798,278.776 331.859,278.813 331.798,278.85 
                    331.798,279.223 331.798,282.252 331.798,282.683 331.859,282.72 331.798,282.757 331.798,283.13 331.798,286.159 
                    331.798,286.588 331.859,286.625 331.798,286.662 331.798,287.034 331.798,297.125 332.392,296.77 332.392,286.938 
                    332.392,286.689 332.392,286.504 332.392,286.324 332.392,283.033 332.392,282.784 332.392,282.597 332.392,282.42 
                    332.392,279.126 332.392,278.877 332.392,278.69 332.392,278.513 332.392,271.847 331.773,272.188 							"/>
                  <polygon class="st507" points="331.28,287.335 331.798,287.034 331.798,286.662 331.28,286.955 							"/>
                  <polygon class="st507" points="331.28,283.43 331.798,283.13 331.798,282.757 331.28,283.051 							"/>
                  <polygon class="st507" points="331.28,279.523 331.798,279.223 331.798,278.85 331.28,279.144 							"/>
                  <g>
                    <polygon class="st509" points="331.28,296.807 331.798,297.125 331.798,287.034 331.28,287.335 								"/>
                    <polygon class="st509" points="331.28,285.861 331.28,286.285 331.28,286.955 331.798,286.662 331.798,286.588 
                      331.798,286.159 331.798,283.13 331.28,283.43 								"/>
                    <polygon class="st509" points="331.28,281.954 331.28,282.38 331.28,283.051 331.798,282.757 331.798,282.683 
                      331.798,282.252 331.798,279.223 331.28,279.523 								"/>
                    <polygon class="st509" points="331.28,272.459 331.28,278.047 331.28,278.473 331.28,279.144 331.798,278.85 
                      331.798,278.776 331.798,278.345 331.798,272.234 331.773,272.188 								"/>
                  </g>
                  <polygon class="st507" points="327.804,289.358 331.28,287.335 331.28,286.955 327.804,288.932 							"/>
                  <polygon class="st507" points="329.187,284.648 331.28,283.43 331.28,283.051 328.832,284.443 							"/>
                  <polygon class="st507" points="329.187,280.741 331.28,279.523 331.28,279.144 328.832,280.536 							"/>
                  <polygon class="st211" points="319.167,264.366 327.186,269.023 329.789,266.614 321.992,262.123 							"/>
                  <polygon class="st507" points="328.482,280.734 328.84,280.943 329.187,280.741 328.832,280.536 							"/>
                  <polygon class="st507" points="328.485,284.641 328.84,284.85 329.187,284.648 328.832,284.443 							"/>
                  <polygon class="st507" points="327.804,281.118 327.804,281.547 328.84,280.943 328.482,280.734 							"/>
                  <polygon class="st507" points="327.804,285.027 327.804,285.454 328.84,284.85 328.485,284.641 							"/>
                  <polygon class="st210" points="327.21,274.697 327.21,275.69 327.21,276.08 327.21,279.597 327.21,279.987 327.21,281.456 
                    327.21,281.892 327.21,283.504 327.21,283.891 327.21,285.365 327.21,285.799 327.21,289.27 327.21,289.706 327.21,299.659 
                    327.804,299.304 327.804,289.358 327.804,288.932 327.804,285.454 327.804,285.027 327.804,284.241 327.804,283.847 
                    327.804,281.547 327.804,281.118 327.804,280.337 327.804,279.94 327.804,276.43 327.804,276.033 327.804,274.381 
                    327.799,274.372 							"/>
                  <polygon class="st509" points="327.129,289.753 327.21,289.706 327.21,289.27 327.129,289.316 							"/>
                  <polygon class="st509" points="327.129,285.846 327.21,285.799 327.21,285.365 327.129,285.412 							"/>
                  <polygon class="st509" points="327.129,281.939 327.21,281.892 327.21,281.456 327.129,281.503 							"/>
                  <polygon class="st509" points="327.186,269.023 319.167,264.366 319.167,269.937 319.187,269.949 319.702,270.255 
                    320.296,270.61 323.922,272.767 324.455,273.082 325.034,273.428 326.693,274.416 326.693,275.774 326.693,279.297 
                    326.693,279.681 326.693,281.249 326.693,281.682 326.693,283.203 326.693,283.588 326.693,285.158 326.693,285.59 
                    326.693,289.063 326.693,289.496 326.693,299.341 327.21,299.659 327.21,289.706 327.129,289.753 327.129,289.316 
                    327.21,289.27 327.21,285.799 327.129,285.846 327.129,285.412 327.21,285.365 327.21,283.891 327.21,283.504 
                    327.21,281.892 327.129,281.939 327.129,281.503 327.21,281.456 327.21,279.987 327.21,279.597 327.21,276.08 327.21,274.67 
                    327.186,274.655 							"/>
                  <polygon class="st507" points="324.44,292.908 325.034,292.553 325.034,288.52 324.44,288.17 							"/>
                  <polygon class="st507" points="324.44,287.759 325.034,288.104 325.034,284.613 324.44,284.266 							"/>
                  <polygon class="st507" points="324.44,283.854 325.034,284.199 325.034,282.612 324.44,282.264 							"/>
                  <g>
                    <polygon class="st210" points="328.84,284.85 331.28,286.285 331.28,285.861 329.187,284.648 								"/>
                    <polygon class="st210" points="327.804,284.241 328.485,284.64 328.832,284.443 327.804,283.847 								"/>
                    <polygon class="st210" points="324.44,282.003 324.44,282.368 325.034,282.715 326.693,283.691 326.693,283.307 
                      325.034,282.346 								"/>
                  </g>
                  <polygon class="st507" points="324.44,282.003 325.034,282.346 325.034,280.707 324.44,280.356 							"/>
                  <polygon class="st507" points="324.44,279.945 325.034,280.29 325.034,278.705 324.44,278.357 							"/>
                  <g>
                    <polygon class="st210" points="328.84,280.943 331.28,282.38 331.28,281.954 329.187,280.741 								"/>
                    <polygon class="st210" points="327.804,280.337 328.482,280.734 328.832,280.536 327.804,279.94 								"/>
                    <polygon class="st210" points="324.44,278.096 324.44,278.461 325.034,278.808 326.693,279.785 326.693,279.4 
                      325.034,278.439 								"/>
                  </g>
                  <polygon class="st507" points="324.44,278.096 325.034,278.439 325.034,274.798 324.44,274.45 							"/>
                  <g>
                    <polygon class="st210" points="327.804,276.43 331.28,278.473 331.28,278.047 327.804,276.033 								"/>
                    <polygon class="st210" points="324.44,274.086 324.44,274.45 325.034,274.798 326.693,275.774 326.693,275.39 
                      325.034,274.428 								"/>
                  </g>
                  <polygon class="st507" points="324.44,273.107 324.44,274.086 325.034,274.428 325.034,273.428 324.455,273.082 							"/>
                  <g>
                    <polygon class="st509" points="323.922,274.48 324.432,274.189 324.432,274.081 324.44,274.086 324.44,273.107 
                      324.455,273.082 323.922,272.767 								"/>
                    <polygon class="st509" points="323.922,292.59 324.44,292.908 324.44,288.17 323.922,287.867 								"/>
                    <polygon class="st509" points="323.922,287.46 324.44,287.759 324.44,284.266 323.922,283.96 								"/>
                    <polygon class="st509" points="324.432,282.368 323.922,282.664 323.922,283.556 324.44,283.854 324.44,282.264 
                      324.432,282.259 								"/>
                    <polygon class="st509" points="323.922,282.294 324.432,282.003 324.432,281.895 324.44,281.9 324.44,280.356 
                      323.922,280.053 								"/>
                    <polygon class="st509" points="324.432,278.461 323.922,278.757 323.922,279.647 324.44,279.945 324.44,278.357 
                      324.432,278.352 								"/>
                    <polygon class="st509" points="324.432,274.45 323.922,274.746 323.922,278.387 324.432,278.096 324.432,277.988 
                      324.44,277.993 324.44,274.45 324.432,274.446 								"/>
                  </g>
                  <polygon class="st210" points="320.279,276.447 320.279,276.868 323.922,274.746 324.432,274.45 324.432,274.342 
                    324.432,274.086 323.922,274.376 							"/>
                  <g>
                    <polygon class="st210" points="322.825,279.011 323.158,279.203 323.922,278.757 324.432,278.461 324.432,278.352 
                      324.432,278.096 323.922,278.387 								"/>
                    <polygon class="st210" points="320.279,280.458 320.279,280.879 322.815,279.402 322.483,279.205 								"/>
                  </g>
                  <g>
                    <polygon class="st210" points="322.823,282.917 323.155,283.11 323.922,282.664 324.432,282.368 324.432,282.259 
                      324.432,282.003 323.922,282.294 								"/>
                    <polygon class="st210" points="320.279,284.364 320.279,284.786 322.813,283.309 322.48,283.112 								"/>
                  </g>
                  <polygon class="st507" points="319.685,270.287 319.685,276.891 320.279,276.551 320.279,270.64 320.296,270.61 
                    319.702,270.255 							"/>
                  <polygon class="st507" points="319.685,295.225 320.279,294.902 320.279,285.725 319.685,285.375 							"/>
                  <polygon class="st509" points="324.44,287.759 323.922,287.46 320.279,285.35 319.685,285.005 319.685,285.131 
                    319.685,285.375 320.279,285.725 323.922,287.867 324.44,288.17 325.034,288.52 326.693,289.496 326.693,289.063 
                    325.034,288.104 							"/>
                  <polygon class="st507" points="320.279,285.35 320.279,284.786 319.793,285.067 							"/>
                  <polygon class="st507" points="319.685,285.005 319.793,285.067 320.279,284.786 320.279,284.364 319.685,284.702 							"/>
                  <polygon class="st507" points="319.685,284.702 320.279,284.364 320.279,281.821 319.685,281.471 							"/>
                  <polygon class="st509" points="324.44,283.854 323.922,283.556 323.155,283.11 322.823,282.917 320.279,281.446 
                    319.685,281.101 319.685,281.224 319.685,281.471 320.279,281.821 322.48,283.112 322.813,283.309 323.922,283.96 
                    324.44,284.266 325.034,284.613 326.693,285.59 326.693,285.158 325.034,284.199 							"/>
                  <polygon class="st507" points="320.279,281.446 320.279,280.879 319.791,281.162 							"/>
                  <polygon class="st507" points="319.685,281.101 319.791,281.162 320.279,280.879 320.279,280.458 319.685,280.798 							"/>
                  <polygon class="st507" points="319.685,280.798 320.279,280.458 320.279,277.911 319.685,277.561 							"/>
                  <polygon class="st509" points="324.44,279.945 323.922,279.647 323.158,279.203 322.825,279.011 320.279,277.536 
                    319.685,277.191 319.685,277.317 319.685,277.561 320.279,277.911 322.483,279.205 322.815,279.402 323.922,280.053 
                    324.44,280.356 325.034,280.707 326.693,281.682 326.693,281.249 325.034,280.29 							"/>
                  <polygon class="st507" points="320.279,277.536 320.279,276.972 319.793,277.253 							"/>
                  <polygon class="st507" points="319.685,277.191 319.793,277.253 320.279,276.972 320.279,276.551 319.685,276.891 							"/>
                  <polygon class="st509" points="319.685,285.375 319.685,285.131 319.475,285.252 							"/>
                  <polygon class="st509" points="319.685,285.005 319.685,284.702 319.419,284.852 							"/>
                  <polygon class="st509" points="319.685,281.471 319.685,281.224 319.473,281.347 							"/>
                  <polygon class="st509" points="319.685,281.101 319.685,280.798 319.419,280.948 							"/>
                  <polygon class="st509" points="319.685,277.561 319.685,277.317 319.475,277.438 							"/>
                  <polygon class="st509" points="319.685,277.191 319.685,276.891 319.421,277.039 							"/>
                  <polygon class="st509" points="319.167,269.984 319.167,277.184 319.167,277.618 319.167,281.091 319.167,281.525 
                    319.167,284.995 319.167,285.432 319.167,294.949 319.685,295.225 319.685,285.375 319.475,285.252 319.685,285.131 
                    319.685,285.005 319.419,284.852 319.685,284.702 319.685,281.471 319.473,281.347 319.685,281.224 319.685,281.101 
                    319.419,280.948 319.685,280.798 319.685,277.561 319.475,277.438 319.685,277.317 319.685,277.191 319.421,277.039 
                    319.685,276.891 319.685,270.287 319.702,270.255 319.187,269.949 							"/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <polygon class="st512" points="327.005,263.196 328.741,264.18 339.275,258.039 337.329,256.895 				"/>
            <polygon class="st511" points="331.674,265.695 339.275,261.311 339.275,258.039 328.685,264.148 				"/>
          </g>
          <g>
            <polygon class="st512" points="344.161,252.779 345.896,253.763 356.43,247.622 354.484,246.478 				"/>
            <polygon class="st511" points="348.829,255.278 356.43,250.894 356.43,247.622 345.84,253.731 				"/>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_123_" gradientUnits="userSpaceOnUse" x1="358.4074" y1="314.619" x2="386.3486" y2="314.619">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st521" points="358.407,324.865 386.349,324.865 386.349,304.374 362.117,306.506 			"/>
          <g>
            <g>
              <g>
                <g id="XMLID_18_">
                  <g>
                    <path class="st56" d="M415.246,300.25v-0.022c-0.002,0.007-0.002,0.015-0.007,0.022H415.246z"/>
                    <path class="st506" d="M413.654,300.139h0.01c0.003-0.007,0.003-0.012,0.003-0.02l1.58,0.108v-1.398l-1.595,0.929
                      L413.654,300.139z"/>
                    <path class="st506" d="M414.078,270.964v1.479v4.602v1.479v4.602v1.479v4.6v1.479l-0.466,0.266l0.04,8.81l1.595-0.929
                      V270.32c0-1.193-0.421-2.319-1.168-3.204V270.964z"/>
                    <path class="st506" d="M413.664,300.139l1.575,0.111c0.005-0.008,0.005-0.015,0.007-0.022l-1.58-0.108
                      C413.667,300.126,413.667,300.131,413.664,300.139z"/>
                    <path class="st506" d="M413.851,300.486l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.136,0.173-0.229l-1.575-0.111C413.659,300.274,413.721,300.41,413.851,300.486z"/>
                    <polygon class="st506" points="413.612,290.95 414.078,290.683 414.078,289.204 413.607,289.478 								"/>
                    <polygon class="st506" points="413.607,289.478 414.078,289.204 414.078,284.605 413.585,284.888 								"/>
                    <polygon class="st506" points="413.585,284.888 414.078,284.605 414.078,283.126 413.58,283.414 								"/>
                    <polygon class="st506" points="413.58,283.414 414.078,283.126 414.078,278.524 413.558,278.822 								"/>
                    <polygon class="st506" points="413.558,278.822 414.078,278.524 414.078,277.045 413.553,277.348 								"/>
                    <polygon class="st506" points="413.553,277.348 414.078,277.045 414.078,272.443 413.531,272.756 								"/>
                    <polygon class="st506" points="413.531,272.756 414.078,272.443 414.078,270.964 413.526,271.284 								"/>
                    <path class="st506" d="M411.995,267.56l0.042,0.027c0.929,0.604,1.484,1.627,1.484,2.733l0.005,0.964l0.552-0.32v-3.848
                      c-0.143-0.172-0.301-0.335-0.468-0.483L411.995,267.56z"/>
                    <path class="st507" d="M414.078,267.116v-0.752l-0.468,0.269C413.777,266.781,413.935,266.943,414.078,267.116z"/>
                    <path class="st210" d="M412.976,266.14c0.227,0.148,0.436,0.311,0.633,0.493l0.468-0.269v-1.479l-1.6,0.924L412.976,266.14z
                      "/>
                    <path class="st507" d="M370.865,289.862l6.064-3.505l-0.239-0.155c-0.022-0.012-0.042-0.027-0.064-0.042l-0.015-0.012
                      c-0.042-0.034-0.079-0.074-0.106-0.123l-0.02-0.034c-0.239-0.439-0.239-0.971,0-1.41l0.012-0.022
                      c0.131-0.234,0.422-0.284,0.621-0.15l0.002-0.01l1.489,0.988l9.608-5.553l-0.19-0.123c-0.022-0.012-0.042-0.027-0.064-0.042
                      l-0.015-0.012c-0.042-0.035-0.079-0.074-0.106-0.123l-0.02-0.034c-0.239-0.439-0.239-0.971,0-1.412l0.012-0.02
                      c0.131-0.237,0.422-0.283,0.621-0.15l0.003-0.01l1.44,0.957l9.608-5.556l-0.141-0.091c-0.022-0.01-0.042-0.025-0.064-0.039
                      l-0.015-0.012c-0.042-0.035-0.079-0.074-0.106-0.123l-0.02-0.035c-0.239-0.439-0.239-0.971,0-1.412l0.012-0.02
                      c0.131-0.237,0.422-0.283,0.621-0.15l0.002-0.01l1.39,0.922l9.608-5.554l-0.091-0.059c-0.022-0.01-0.042-0.025-0.064-0.039
                      l-0.015-0.012c-0.042-0.034-0.079-0.074-0.106-0.123l-0.02-0.034c-0.239-0.441-0.239-0.971,0-1.413l0.012-0.02
                      c0.131-0.237,0.422-0.283,0.621-0.15l0.003-0.01l1.341,0.89l1.6-0.924v-4.274l-43.213,25.635V289.862z"/>
                    <polygon class="st95" points="370.865,286.246 414.078,260.611 414.078,256.997 370.865,281.975 								"/>
                    <polygon class="st50" points="353.5,265.048 370.865,281.975 414.078,256.997 396.718,240.071 								"/>
                    <polygon class="st509" points="403.908,296.523 403.908,305.438 413.652,299.759 413.612,290.95 								"/>
                    <polygon class="st210" points="403.908,295.083 403.908,296.523 413.612,290.95 413.607,289.478 								"/>
                    <path class="st506" d="M412.478,265.81l-1.681,0.971l1.198,0.779l1.615-0.927c-0.197-0.182-0.407-0.345-0.633-0.493
                      L412.478,265.81z"/>
                    <polygon class="st507" points="403.908,290.444 403.908,295.083 413.607,289.478 413.585,284.888 								"/>
                    <polygon class="st210" points="403.908,289.005 403.908,290.444 413.585,284.888 413.58,283.414 								"/>
                    <polygon class="st507" points="403.908,284.363 403.908,289.005 413.58,283.414 413.558,278.822 								"/>
                    <polygon class="st210" points="403.908,282.924 403.908,284.363 413.558,278.822 413.553,277.348 								"/>
                    <polygon class="st507" points="403.908,278.282 403.908,282.924 413.553,277.348 413.531,272.756 								"/>
                    <polygon class="st210" points="403.908,276.843 403.908,278.282 413.531,272.756 413.526,271.284 								"/>
                    <path class="st507" d="M412.037,267.587l-0.042-0.027l-9.712,5.576c1.03,0.932,1.624,2.26,1.624,3.678v0.03l9.618-5.559
                      l-0.005-0.964C413.521,269.214,412.966,268.191,412.037,267.587z"/>
                    <path class="st506" d="M411.135,264.93c-0.2-0.133-0.49-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.971,0,1.413
                      l0.02,0.034c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.039l0.091,0.059l1.681-0.971
                      l-1.341-0.89L411.135,264.93z"/>
                    <path class="st210" d="M401.189,272.334l0.449,0.298c0.232,0.151,0.446,0.318,0.646,0.503l9.712-5.576l-1.198-0.779
                      L401.189,272.334z"/>
                    <path class="st56" d="M403.908,306.745v-0.025c-0.003,0.007-0.005,0.017-0.008,0.025H403.908z"/>
                    <path class="st506" d="M402.315,306.631h0.01c0.002-0.007,0.002-0.012,0.002-0.02l1.58,0.108v-1.282l-1.592,0.929V306.631z"
                      />
                    <polygon class="st506" points="402.315,306.368 403.908,305.438 403.908,296.523 402.274,297.462 								"/>
                    <polygon class="st506" points="402.274,297.462 403.908,296.523 403.908,295.083 402.269,296.032 								"/>
                    <polygon class="st506" points="402.269,296.032 403.908,295.083 403.908,290.444 402.246,291.398 								"/>
                    <polygon class="st506" points="402.246,291.398 403.908,290.444 403.908,289.005 402.241,289.968 								"/>
                    <polygon class="st506" points="402.241,289.968 403.908,289.005 403.908,284.363 402.219,285.332 								"/>
                    <polygon class="st506" points="402.219,285.332 403.908,284.363 403.908,282.924 402.214,283.902 								"/>
                    <polygon class="st506" points="402.214,283.902 403.908,282.924 403.908,278.282 402.192,279.268 								"/>
                    <polygon class="st506" points="402.192,279.268 403.908,278.282 403.908,276.843 402.187,277.838 								"/>
                    <path class="st506" d="M400.671,274.062l0.027,0.017c0.929,0.604,1.484,1.627,1.484,2.734l0.005,1.025l1.721-0.996v-0.03
                      c0-1.417-0.594-2.746-1.624-3.678L400.671,274.062z"/>
                    <path class="st506" d="M402.325,306.631l1.575,0.113c0.003-0.008,0.005-0.017,0.008-0.025l-1.58-0.108
                      C402.328,306.619,402.328,306.624,402.325,306.631z"/>
                    <path class="st506" d="M402.513,306.979l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.136,0.173-0.227l-1.575-0.113C402.32,306.767,402.382,306.903,402.513,306.979z"/>
                    <polygon class="st509" points="392.569,303.035 392.569,312.049 402.315,306.368 402.274,297.462 								"/>
                    <path class="st506" d="M401.189,272.334l-1.681,0.971l1.163,0.757l1.612-0.927c-0.2-0.185-0.414-0.352-0.646-0.503
                      L401.189,272.334z"/>
                    <polygon class="st210" points="392.569,301.637 392.569,303.035 402.274,297.462 402.269,296.032 								"/>
                    <polygon class="st507" points="392.569,296.954 392.569,301.637 402.269,296.032 402.246,291.398 								"/>
                    <polygon class="st210" points="392.569,295.559 392.569,296.954 402.246,291.398 402.241,289.968 								"/>
                    <polygon class="st507" points="392.569,290.876 392.569,295.559 402.241,289.968 402.219,285.332 								"/>
                    <polygon class="st210" points="392.569,289.478 392.569,290.876 402.219,285.332 402.214,283.902 								"/>
                    <polygon class="st507" points="392.569,284.795 392.569,289.478 402.214,283.902 402.192,279.268 								"/>
                    <polygon class="st210" points="392.569,283.397 392.569,284.795 402.192,279.268 402.187,277.838 								"/>
                    <path class="st507" d="M400.698,274.079l-0.027-0.017l-9.714,5.578c1.023,0.932,1.612,2.253,1.612,3.668v0.089l9.618-5.558
                      l-0.005-1.025C402.182,275.706,401.628,274.683,400.698,274.079z"/>
                    <path class="st506" d="M399.796,271.422c-0.2-0.133-0.491-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.974,0,1.412
                      l0.02,0.035c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.039l0.141,0.091l1.681-0.971
                      l-1.39-0.922L399.796,271.422z"/>
                    <path class="st210" d="M389.9,278.861l0.399,0.264c0.234,0.153,0.456,0.325,0.658,0.515l9.714-5.578l-1.163-0.757
                      L389.9,278.861z"/>
                    <polygon class="st43" points="383.079,239.413 340.024,264.227 353.5,265.048 396.718,240.071 383.242,239.25 								"/>
                    <path class="st56" d="M392.569,313.237v-0.025c-0.003,0.007-0.005,0.017-0.007,0.025H392.569z"/>
                    <path class="st506" d="M390.977,313.124h0.01c0.002-0.007,0.002-0.012,0.002-0.02l1.58,0.108v-1.163l-1.592,0.927V313.124z"
                      />
                    <polygon class="st506" points="390.977,312.976 392.569,312.049 392.569,303.035 390.935,303.972 								"/>
                    <polygon class="st506" points="390.935,303.972 392.569,303.035 392.569,301.637 390.93,302.586 								"/>
                    <polygon class="st506" points="390.93,302.586 392.569,301.637 392.569,296.954 390.908,297.908 								"/>
                    <polygon class="st506" points="390.908,297.908 392.569,296.954 392.569,295.559 390.903,296.52 								"/>
                    <polygon class="st506" points="390.903,296.52 392.569,295.559 392.569,290.876 390.881,291.844 								"/>
                    <polygon class="st506" points="390.881,291.844 392.569,290.876 392.569,289.478 390.876,290.457 								"/>
                    <polygon class="st506" points="390.876,290.457 392.569,289.478 392.569,284.795 390.853,285.778 								"/>
                    <polygon class="st506" points="390.853,285.778 392.569,284.795 392.569,283.397 390.848,284.393 								"/>
                    <path class="st506" d="M389.347,280.565l0.012,0.007c0.929,0.604,1.484,1.627,1.484,2.736l0.005,1.084l1.721-0.996v-0.089
                      c0-1.415-0.589-2.736-1.612-3.668L389.347,280.565z"/>
                    <path class="st506" d="M390.987,313.124l1.575,0.113c0.002-0.007,0.005-0.017,0.007-0.025l-1.58-0.108
                      C390.989,313.112,390.989,313.117,390.987,313.124z"/>
                    <path class="st506" d="M391.174,313.472l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.136,0.173-0.227l-1.575-0.113C390.982,313.26,391.043,313.395,391.174,313.472z"/>
                    <polygon class="st509" points="381.23,309.545 381.23,318.658 390.977,312.976 390.935,303.972 								"/>
                    <path class="st506" d="M389.9,278.861l-1.681,0.971l1.129,0.732l1.61-0.924c-0.202-0.19-0.424-0.362-0.658-0.515
                      L389.9,278.861z"/>
                    <polygon class="st210" points="381.23,308.192 381.23,309.545 390.935,303.972 390.93,302.586 								"/>
                    <polygon class="st507" points="381.23,303.466 381.23,308.192 390.93,302.586 390.908,297.908 								"/>
                    <polygon class="st210" points="381.23,302.111 381.23,303.466 390.908,297.908 390.903,296.52 								"/>
                    <polygon class="st507" points="381.23,297.385 381.23,302.111 390.903,296.52 390.881,291.844 								"/>
                    <polygon class="st210" points="381.23,296.03 381.23,297.385 390.881,291.844 390.876,290.457 								"/>
                    <polygon class="st507" points="381.23,291.304 381.23,296.03 390.876,290.457 390.853,285.778 								"/>
                    <polygon class="st210" points="381.23,289.951 381.23,291.304 390.853,285.778 390.848,284.393 								"/>
                    <path class="st507" d="M389.36,280.572l-0.012-0.007l-9.717,5.578c1.013,0.932,1.6,2.248,1.6,3.658v0.15l9.618-5.558
                      l-0.005-1.084C390.844,282.199,390.289,281.176,389.36,280.572z"/>
                    <path class="st506" d="M388.458,277.915c-0.2-0.133-0.49-0.086-0.621,0.15l-0.012,0.02c-0.239,0.441-0.239,0.974,0,1.412
                      l0.02,0.034c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.042l0.19,0.123l1.681-0.971
                      l-1.44-0.957L388.458,277.915z"/>
                    <path class="st210" d="M378.61,285.386l0.35,0.232c0.239,0.158,0.463,0.33,0.671,0.525l9.717-5.578l-1.129-0.732
                      L378.61,285.386z"/>
                    <path class="st56" d="M381.23,319.73v-0.025c-0.002,0.007-0.005,0.017-0.007,0.025H381.23z"/>
                    <path class="st506" d="M379.638,319.617h0.01c0.003-0.005,0.003-0.012,0.003-0.017l1.58,0.106v-1.048l-1.592,0.929V319.617z
                      "/>
                    <polygon class="st506" points="379.638,319.587 381.23,318.658 381.23,309.545 379.596,310.484 								"/>
                    <polygon class="st506" points="379.596,310.484 381.23,309.545 381.23,308.192 379.591,309.141 								"/>
                    <polygon class="st506" points="379.591,309.141 381.23,308.192 381.23,303.466 379.569,304.418 								"/>
                    <polygon class="st506" points="379.569,304.418 381.23,303.466 381.23,302.111 379.564,303.075 								"/>
                    <polygon class="st506" points="379.564,303.075 381.23,302.111 381.23,297.385 379.542,298.354 								"/>
                    <polygon class="st506" points="379.542,298.354 381.23,297.385 381.23,296.03 379.537,297.011 								"/>
                    <polygon class="st506" points="379.537,297.011 381.23,296.03 381.23,291.304 379.517,292.29 								"/>
                    <polygon class="st506" points="379.517,292.29 381.23,291.304 381.23,289.951 379.51,290.947 								"/>
                    <path class="st506" d="M378.023,287.067c0.927,0.604,1.481,1.624,1.481,2.734l0.005,1.146l1.721-0.996v-0.15
                      c0-1.41-0.587-2.726-1.6-3.658L378.023,287.067z"/>
                    <path class="st506" d="M379.648,319.617l1.575,0.113c0.003-0.007,0.005-0.017,0.007-0.025l-1.58-0.106
                      C379.65,319.604,379.65,319.612,379.648,319.617z"/>
                    <path class="st506" d="M379.835,319.964l0.017,0.01c0.365,0.212,0.806,0.212,1.171,0l0.027-0.017
                      c0.091-0.052,0.148-0.135,0.173-0.227l-1.575-0.113C379.643,319.752,379.705,319.888,379.835,319.964z"/>
                    <polygon class="st508" points="370.865,315.498 371.112,324.699 379.638,319.587 379.596,310.484 								"/>
                    <path class="st506" d="M378.61,285.386l-1.681,0.971l1.092,0.707c0,0,0.003,0,0.003,0.002l1.607-0.924
                      c-0.207-0.195-0.431-0.367-0.671-0.525L378.61,285.386z"/>
                    <polygon class="st210" points="370.865,314.184 370.865,315.498 379.596,310.484 379.591,309.141 								"/>
                    <polygon class="st507" points="370.865,309.417 370.865,314.184 379.591,309.141 379.569,304.418 								"/>
                    <polygon class="st210" points="370.865,308.103 370.865,309.417 379.569,304.418 379.564,303.075 								"/>
                    <polygon class="st507" points="370.865,303.338 370.865,308.103 379.564,303.075 379.542,298.354 								"/>
                    <polygon class="st210" points="370.865,302.022 370.865,303.338 379.542,298.354 379.537,297.011 								"/>
                    <polygon class="st507" points="370.865,297.257 370.865,302.022 379.537,297.011 379.517,292.29 								"/>
                    <polygon class="st210" points="370.865,295.943 370.865,297.257 379.517,292.29 379.51,290.947 								"/>
                    <path class="st507" d="M378.023,287.067l-7.158,4.109v4.767l8.645-4.996l-0.005-1.146
                      C379.505,288.692,378.95,287.671,378.023,287.067z"/>
                    <path class="st506" d="M377.119,284.408c-0.2-0.133-0.49-0.084-0.621,0.15l-0.012,0.022c-0.239,0.439-0.239,0.971,0,1.41
                      l0.02,0.034c0.027,0.049,0.064,0.089,0.106,0.123l0.015,0.012c0.022,0.015,0.042,0.03,0.064,0.042l0.239,0.155l1.681-0.971
                      l-1.489-0.988L377.119,284.408z"/>
                    <polygon class="st210" points="376.929,286.357 370.865,289.862 370.865,291.176 378.023,287.067 378.021,287.065 								
                      "/>
                    <polygon class="st510" points="370.865,314.184 370.865,309.417 370.865,308.103 370.865,303.338 370.865,302.022 
                      370.865,297.257 370.865,295.943 370.865,291.176 370.865,289.862 370.865,286.246 340.024,268.267 340.024,278.277 
                      340.036,278.297 342.649,276.761 364.683,290.025 364.683,295.857 364.639,295.884 364.683,295.958 364.683,320.894 
                      371.112,324.699 370.865,315.498 								"/>
                    <polygon class="st127" points="340.024,268.267 370.865,286.246 370.865,281.975 340.024,264.227 								"/>
                    <polygon class="st511" points="340.024,264.227 370.865,281.975 353.5,265.048 								"/>
                    <polygon class="st210" points="362.854,296.957 362.854,321.877 362.889,321.899 364.683,320.894 364.683,295.958 
                      364.639,295.884 								"/>
                    <polygon class="st95" points="358.407,294.198 358.407,299.629 360.224,298.537 360.944,298.105 362.854,296.957 
                      364.639,295.884 364.683,295.857 364.683,290.025 358.407,294.065 								"/>
                    <polygon class="st512" points="340.036,278.297 335.999,280.671 358.407,294.198 358.407,294.065 364.683,290.025 
                      342.649,276.761 								"/>
                    <polygon class="st211" points="360.944,320.691 362.854,321.877 362.854,296.957 360.944,298.105 								"/>
                    <polygon class="st210" points="358.407,304.775 358.407,305.515 358.407,310.753 358.407,311.492 358.407,316.73 
                      358.407,317.47 358.407,323.632 358.407,324.865 360.155,323.859 360.224,298.537 358.407,299.629 								"/>
                    <polygon class="st509" points="356.47,322.693 356.524,322.9 356.47,322.915 356.47,323.657 358.407,324.865 
                      358.407,323.632 356.47,322.506 								"/>
                    <polygon class="st509" points="356.47,322.506 358.407,323.632 358.407,317.47 356.47,316.328 								"/>
                    <polygon class="st210" points="356.47,316.328 358.407,317.47 358.407,316.73 356.47,315.604 								"/>
                    <polygon class="st509" points="356.47,311.763 356.47,312.212 356.47,315.604 358.407,316.73 358.407,311.492 
                      356.47,310.351 								"/>
                    <polygon class="st210" points="356.47,310.351 358.407,311.492 358.407,310.753 356.47,309.626 								"/>
                    <polygon class="st509" points="356.47,309.626 358.407,310.753 358.407,305.515 356.47,304.374 								"/>
                    <polygon class="st210" points="356.47,304.374 358.407,305.515 358.407,304.775 356.47,303.649 								"/>
                    <polygon class="st509" points="356.47,298.623 356.47,303.649 358.407,304.775 358.407,299.629 356.534,298.514 								"/>
                    <path class="st513" d="M335.999,286.264c0.141,0.035,2.068,1.208,2.068,1.208v0.039l1.94,1.156l4.052,2.413l3.749,2.233
                      l3.749,2.233l4.977,2.968l1.873,1.114v-5.43l-22.409-13.528V286.264z"/>
                    <polygon class="st509" points="356.47,322.915 355.6,323.139 355.247,321.793 352.75,320.344 352.583,320.901 
                      351.668,320.63 351.55,320.829 358.407,324.865 356.47,323.657 								"/>
                    <polygon class="st514" points="351.442,295.741 351.442,300.725 356.47,303.649 356.47,298.623 356.534,298.514 
                      351.557,295.547 								"/>
                    <polygon class="st509" points="356.47,322.915 356.524,322.9 356.47,322.693 								"/>
                    <polygon class="st509" points="355.247,321.793 355.6,323.139 356.47,322.915 356.47,322.693 356.411,322.471 								"/>
                    <polygon class="st509" points="356.47,322.693 356.47,322.506 356.411,322.471 								"/>
                    <polygon class="st507" points="356.411,322.471 356.47,322.506 356.47,316.328 354.493,315.165 								"/>
                    <path class="st210" d="M349.711,311.672l-0.076-0.042l-0.049,0.086c0.133,0.074,0.239,0.158,0.256,0.227l0.158,0.579
                      l3.512,2.066c0.081-0.141,0.242-0.232,0.412-0.227c0.212,0.003,0.397,0.148,0.451,0.355l0.118,0.449l1.977,1.163v-0.725
                      l-2.909-1.691L349.711,311.672z"/>
                    <path class="st507" d="M354.895,313.753c-0.375,0.328-0.905,0.352-1.302,0.103l-0.032,0.057l2.909,1.691v-3.392h-0.054
                      L354.895,313.753z"/>
                    <path class="st515" d="M352.174,311.803l-0.064-0.037c-0.116-0.064-0.222-0.153-0.311-0.259l1.511,2.09
                      c0.081,0.106,0.177,0.192,0.283,0.259c0.397,0.249,0.927,0.224,1.302-0.103l1.521-1.541h0.054v-0.449l-0.069,0.04
                      C355.085,312.525,353.49,312.525,352.174,311.803z"/>
                    <path class="st514" d="M351.442,309.764v0.131c-0.012,0.003-0.025,0.007-0.037,0.007c-0.076,0.358-0.03,0.789,0.123,1.159
                      c0.069,0.163,0.158,0.315,0.266,0.441c0,0.003,0.002,0.003,0.005,0.005c0.089,0.106,0.195,0.195,0.311,0.259l0.064,0.037
                      c1.316,0.722,2.911,0.722,4.227,0l0.069-0.04v-1.412l-5.028-2.958V309.764z"/>
                    <polygon class="st210" points="351.442,307.393 356.47,310.351 356.47,309.626 351.442,306.703 								"/>
                    <polygon class="st514" points="351.442,306.703 356.47,309.626 356.47,304.374 351.442,301.416 								"/>
                    <path class="st516" d="M354.937,298.161v4.596l1.533,0.891v-5.026l0.064-0.108l-1.66-0.99
                      C354.913,297.732,354.937,297.944,354.937,298.161z"/>
                    <path class="st516" d="M352.616,312.013c1.221,0.502,2.613,0.434,3.785-0.21l0.069-0.04v-1.412l-1.611-0.948
                      C354.603,310.591,353.735,311.578,352.616,312.013z"/>
                    <path class="st516" d="M354.937,308.678c0,0.019-0.003,0.037-0.003,0.055l1.536,0.893v-5.253l-1.533-0.902V308.678z"/>
                    <polygon class="st210" points="351.442,301.416 356.47,304.374 356.47,303.649 351.442,300.725 								"/>
                    <path class="st517" d="M353.512,314.588c-0.025,0.034-0.042,0.071-0.054,0.111l-1.504,4.987l0.035,0.131l-0.081,0.022
                      l-0.005,0.012l0.848,0.493l1.132-3.752l1.366,5.201l1.163,0.678l-1.918-7.306L353.512,314.588z"/>
                    <polygon class="st507" points="352.75,320.344 355.247,321.793 353.882,316.592 								"/>
                    <path class="st210" d="M353.924,314.361c-0.17-0.005-0.33,0.086-0.412,0.227l0.981,0.577l-0.118-0.449
                      C354.321,314.509,354.136,314.364,353.924,314.361z"/>
                    <path class="st507" d="M351.799,311.507c-0.003-0.002-0.005-0.002-0.005-0.005c-0.109-0.126-0.197-0.279-0.266-0.441
                      l-0.441,0.384c-0.375,0.328-0.905,0.353-1.304,0.104l-0.072,0.123l3.85,2.241l0.032-0.057
                      c-0.106-0.066-0.202-0.153-0.283-0.259L351.799,311.507z"/>
                    <path class="st507" d="M351.954,319.686l1.504-4.987c0.012-0.039,0.03-0.076,0.054-0.111L350,312.523L351.954,319.686z"/>
                    <polygon class="st509" points="351.668,320.63 352.583,320.901 352.75,320.344 351.903,319.851 								"/>
                    <polygon class="st517" points="351.907,319.839 351.989,319.816 351.954,319.686 								"/>
                    <polygon class="st517" points="348.856,311.847 347.32,316.93 347.402,317.233 348.102,317.64 349.349,313.509 
                      350.852,319.24 351.89,319.841 351.907,319.839 351.954,319.686 350,312.523 								"/>
                    <polygon class="st509" points="351.067,320.056 350.852,319.24 348.102,317.64 348.047,317.817 347.135,317.546 
                      347.143,317.524 346.534,317.684 346.45,317.827 351.55,320.829 351.668,320.63 351.903,319.851 351.89,319.841 								"/>
                    <polygon class="st509" points="351.067,320.056 351.89,319.841 350.852,319.24 								"/>
                    <polygon class="st514" points="347.621,293.629 347.621,298.505 351.442,300.725 351.442,295.741 351.557,295.547 
                      347.808,293.313 								"/>
                    <path class="st515" d="M351.405,309.902c-1.07,0.284-2.053,0.136-3.052-0.414l-0.064-0.035
                      c-0.148-0.081-0.264-0.19-0.353-0.318l-0.03,0.022l1.592,2.135c0.081,0.104,0.178,0.19,0.284,0.256
                      c0.399,0.249,0.929,0.224,1.304-0.104l0.441-0.384C351.375,310.691,351.328,310.26,351.405,309.902z"/>
                    <path class="st514" d="M351.442,309.895v-0.131c-0.015,0.044-0.027,0.091-0.037,0.138
                      C351.417,309.902,351.429,309.898,351.442,309.895z"/>
                    <path class="st514" d="M347.621,307.245v0.281v0.38c0.01,0.281,0.042,0.557,0.116,0.803c0.044,0.158,0.111,0.301,0.2,0.426
                      c0.089,0.128,0.205,0.237,0.353,0.318l0.064,0.035c0.998,0.55,1.982,0.698,3.052,0.414c0.01-0.047,0.022-0.094,0.037-0.138
                      v-2.371l-3.821-2.248V307.245z"/>
                    <polygon class="st210" points="347.621,305.145 351.442,307.393 351.442,306.703 347.621,304.482 								"/>
                    <polygon class="st514" points="347.621,304.482 351.442,306.703 351.442,301.416 347.621,299.168 								"/>
                    <polygon class="st516" points="350.746,300.321 351.442,300.725 351.442,295.741 351.557,295.547 350.746,295.064 								
                      "/>
                    <path class="st516" d="M349.175,308.906c-0.235,0.233-0.501,0.435-0.792,0.597c0.988,0.536,1.962,0.68,3.022,0.399
                      c0.01-0.047,0.022-0.094,0.037-0.138v-2.371l-0.829-0.488C350.381,307.721,349.863,308.43,349.175,308.906z"/>
                    <path class="st516" d="M350.746,305.967c0,0.109-0.007,0.216-0.017,0.322l0.712,0.414v-5.287l-0.695-0.409V305.967z"/>
                    <polygon class="st210" points="347.621,299.168 351.442,301.416 351.442,300.725 347.621,298.505 								"/>
                    <polygon class="st507" points="348.102,317.64 350.852,319.24 349.349,313.509 								"/>
                    <path class="st210" d="M349.585,311.717c-0.256-0.138-0.621-0.237-0.661-0.101l-0.069,0.232l1.144,0.675l-0.158-0.579
                      C349.825,311.874,349.719,311.791,349.585,311.717z"/>
                    <path class="st507" d="M347.907,309.158l0.03-0.022c-0.089-0.126-0.155-0.269-0.2-0.426l-0.03,0.01l-0.441,0.384
                      c-0.375,0.328-0.905,0.353-1.304,0.104l-0.126,0.215l3.798,2.209l0.076,0.042l0.072-0.123
                      c-0.106-0.067-0.202-0.153-0.284-0.256L347.907,309.158z"/>
                    <path class="st210" d="M345.168,309.032c0.067,0.057,0.116,0.136,0.141,0.227l0.155,0.594l3.392,1.994l0.069-0.232
                      c0.039-0.136,0.404-0.037,0.661,0.101l0.049-0.086l-3.798-2.209L345.168,309.032z"/>
                    <polygon class="st507" points="347.32,316.93 348.856,311.847 345.464,309.853 								"/>
                    <polygon class="st509" points="347.456,317.443 347.143,317.524 347.135,317.546 348.047,317.817 348.102,317.64 
                      347.402,317.233 								"/>
                    <polygon class="st514" points="343.8,291.516 343.8,296.281 347.621,298.505 347.621,293.629 347.808,293.313 
                      344.059,291.08 								"/>
                    <path class="st515" d="M347.618,307.529c-1.122,0.362-2.021,0.232-3.086-0.352l-0.064-0.035
                      c-0.074-0.064-0.143-0.133-0.205-0.21l1.415,2.019c0.081,0.103,0.177,0.19,0.284,0.256c0.399,0.249,0.929,0.224,1.304-0.104
                      l0.441-0.384l0.03-0.01c-0.074-0.247-0.106-0.523-0.116-0.803C347.616,307.78,347.613,307.654,347.618,307.529z"/>
                    <path class="st514" d="M343.8,305.034v0.244c-0.005,0.002-0.01,0.005-0.015,0.005c-0.01,0.345,0.03,0.69,0.133,1.003
                      c0.047,0.148,0.109,0.288,0.185,0.417l0.002,0.003l0.158,0.227c0.061,0.076,0.131,0.146,0.205,0.21l0.064,0.035
                      c1.065,0.584,1.965,0.715,3.086,0.352c0-0.096,0-0.19,0.003-0.284v-2.1l-3.821-2.248V305.034z"/>
                    <polygon class="st210" points="343.8,302.897 347.621,305.145 347.621,304.482 343.8,302.259 								"/>
                    <polygon class="st514" points="343.8,302.259 347.621,304.482 347.621,299.168 343.8,296.92 								"/>
                    <polygon class="st516" points="346.803,298.028 347.621,298.505 347.621,293.629 347.808,293.313 346.803,292.714 								
                      "/>
                    <path class="st516" d="M345.241,307.502c0.779,0.289,1.513,0.306,2.378,0.027c0-0.096,0-0.19,0.003-0.284v-2.1l-0.823-0.484
                      C346.767,305.823,346.152,306.867,345.241,307.502z"/>
                    <polygon class="st516" points="346.803,304.006 347.621,304.482 347.621,299.168 346.803,298.686 								"/>
                    <polygon class="st210" points="343.8,296.92 347.621,299.168 347.621,298.505 343.8,296.281 								"/>
                    <polygon class="st509" points="347.256,317.147 347.143,317.524 347.456,317.443 347.402,317.233 								"/>
                    <polygon class="st517" points="347.256,317.147 347.402,317.233 347.32,316.93 								"/>
                    <path class="st517" d="M344.397,309.225c-0.002,0.005-0.005,0.012-0.007,0.02l-1.6,5.307l0.845,0.493l1.181-3.907
                      l1.422,5.418l1.018,0.592l0.064-0.217l-1.856-7.077L344.397,309.225z"/>
                    <polygon class="st509" points="346.534,317.684 347.143,317.524 347.256,317.147 346.238,316.555 								"/>
                    <polygon class="st509" points="343.635,315.044 343.514,315.446 342.602,315.175 342.671,314.943 342.001,314.601 
                      341.821,313.987 339.171,312.446 338.981,313.075 338.067,312.801 338.067,312.885 337.727,312.693 346.45,317.827 
                      346.534,317.684 346.238,316.555 								"/>
                    <polygon class="st507" points="343.635,315.044 346.238,316.555 344.816,311.137 								"/>
                    <path class="st507" d="M344.264,306.932c-0.059-0.071-0.111-0.145-0.158-0.227l-0.002-0.003
                      c-0.076-0.128-0.138-0.269-0.185-0.417l-0.513,0.688c-0.375,0.328-0.907,0.353-1.304,0.104l-0.074,0.128l2.963,1.723
                      c0.067,0.02,0.128,0.054,0.177,0.104l0.668,0.389l0.126-0.215c-0.106-0.066-0.202-0.153-0.284-0.256L344.264,306.932z"/>
                    <path class="st210" d="M345.168,309.032l-0.177-0.104c-0.042-0.015-0.089-0.022-0.136-0.022
                      c-0.21,0-0.392,0.123-0.459,0.318l1.067,0.629l-0.155-0.594C345.284,309.168,345.235,309.089,345.168,309.032z"/>
                    <path class="st507" d="M345.168,309.032l-0.177-0.104C345.057,308.949,345.119,308.983,345.168,309.032z"/>
                    <path class="st210" d="M338.067,304.903v0.596l1.945,1.144c0.086-0.072,0.195-0.111,0.311-0.111
                      c0.214,0.005,0.399,0.151,0.454,0.355l0.064,0.244l3.557,2.093c0.067-0.195,0.249-0.318,0.459-0.318
                      c0.047,0,0.094,0.007,0.136,0.022l-2.963-1.723L338.067,304.903z"/>
                    <path class="st507" d="M342.785,314.549l0.005,0.003l1.6-5.307c0.002-0.007,0.005-0.015,0.007-0.02l-3.557-2.093
                      L342.785,314.549z"/>
                    <polygon class="st514" points="339.98,288.711 339.98,294.06 343.8,296.281 343.8,291.516 344.059,291.08 340.007,288.667 
                                      "/>
                    <path class="st515" d="M340.712,304.864l-0.064-0.035c-0.192-0.106-0.343-0.246-0.451-0.409l-0.007,0.005l1.629,2.396
                      c0.081,0.103,0.178,0.19,0.283,0.256c0.397,0.249,0.929,0.224,1.304-0.104l0.513-0.688
                      c-0.103-0.313-0.143-0.658-0.133-1.003C342.711,305.574,341.72,305.416,340.712,304.864z"/>
                    <path class="st514" d="M343.8,305.278v-0.244c-0.007,0.084-0.012,0.168-0.015,0.249
                      C343.79,305.283,343.795,305.281,343.8,305.278z"/>
                    <path class="st514" d="M339.98,303.614c0,0.012,0,0.025,0,0.037c-0.01,0.269,0.064,0.54,0.217,0.769
                      c0.108,0.163,0.259,0.303,0.451,0.409l0.064,0.035c1.008,0.552,1.999,0.71,3.074,0.419c0.002-0.081,0.007-0.165,0.015-0.249
                      v-2.137l-3.821-2.25V303.614z"/>
                    <polygon class="st210" points="339.98,300.647 343.8,302.897 343.8,302.259 339.98,300.038 								"/>
                    <polygon class="st514" points="339.98,300.038 343.8,302.259 343.8,296.92 339.98,294.669 								"/>
                    <path class="st516" d="M342.694,290.602v5.036l1.106,0.643v-4.765l0.259-0.436l-1.383-0.824
                      C342.688,290.371,342.694,290.486,342.694,290.602z"/>
                    <path class="st516" d="M342.694,302.269c0,1.148-0.577,2.188-1.448,2.846c0.827,0.338,1.655,0.407,2.539,0.168
                      c0.002-0.081,0.007-0.165,0.015-0.249v-2.137l-1.106-0.651V302.269z"/>
                    <polygon class="st516" points="342.694,301.616 343.8,302.259 343.8,296.92 342.694,296.268 								"/>
                    <polygon class="st210" points="339.98,294.669 343.8,296.92 343.8,296.281 339.98,294.06 								"/>
                    <polygon class="st509" points="342.787,314.559 342.923,315.071 342.671,314.943 342.602,315.175 343.514,315.446 
                      343.635,315.044 342.79,314.551 								"/>
                    <polygon class="st509" points="342.671,314.943 342.923,315.071 342.787,314.559 								"/>
                    <polygon class="st56" points="342.787,314.559 342.79,314.551 342.785,314.549 								"/>
                    <polygon class="st509" points="341.821,313.987 342.001,314.601 342.671,314.943 342.787,314.559 342.785,314.549 								
                      "/>
                    <path class="st517" d="M340.012,306.644c-0.072,0.057-0.128,0.136-0.155,0.227l-1.533,5.083l0.848,0.493l1.112-3.68
                      l1.538,5.221l0.964,0.562l-1.945-7.417L340.012,306.644z"/>
                    <path class="st211" d="M340.189,304.425l0.007-0.005c-0.153-0.229-0.227-0.5-0.217-0.769c0-0.012,0-0.025,0-0.037v-2.968
                      l-1.913-1.124v5.381l3.961,2.302l0.074-0.128c-0.106-0.067-0.202-0.153-0.283-0.256L340.189,304.425z"/>
                    <polygon class="st507" points="339.171,312.446 341.821,313.987 340.283,308.766 								"/>
                    <path class="st210" d="M340.322,306.533c-0.116,0-0.224,0.039-0.311,0.111l0.828,0.488l-0.064-0.244
                      C340.721,306.683,340.537,306.538,340.322,306.533z"/>
                    <path class="st211" d="M338.067,311.805l0.256,0.148l1.533-5.083c0.027-0.091,0.084-0.17,0.155-0.227l-1.945-1.144V311.805z
                      "/>
                    <polygon class="st211" points="338.067,292.949 339.98,294.06 339.98,288.711 340.007,288.667 338.067,287.511 								"/>
                    <polygon class="st210" points="338.067,299.523 339.98,300.647 339.98,300.038 338.067,298.926 								"/>
                    <polygon class="st211" points="338.067,298.926 339.98,300.038 339.98,294.669 338.067,293.545 								"/>
                    <polygon class="st210" points="338.067,293.545 339.98,294.669 339.98,294.06 338.067,292.949 								"/>
                    <polygon class="st509" points="338.067,312.801 338.981,313.075 339.171,312.446 338.323,311.953 								"/>
                    <polygon class="st509" points="338.067,312.801 338.323,311.953 338.067,311.805 								"/>
                    <polygon class="st509" points="335.999,311.677 337.727,312.693 338.067,312.885 338.067,312.801 338.067,311.805 
                      335.999,310.602 								"/>
                    <polygon class="st509" points="335.999,310.602 338.067,311.805 338.067,305.5 335.999,304.282 								"/>
                    <polygon class="st210" points="335.999,304.282 338.067,305.5 338.067,304.903 335.999,303.701 								"/>
                    <polygon class="st509" points="335.999,303.701 338.067,304.903 338.067,299.523 335.999,298.305 								"/>
                    <polygon class="st210" points="335.999,298.305 338.067,299.523 338.067,298.926 335.999,297.723 								"/>
                    <polygon class="st509" points="335.999,297.723 338.067,298.926 338.067,293.545 335.999,292.327 								"/>
                    <polygon class="st210" points="335.999,292.327 338.067,293.545 338.067,292.949 335.999,291.746 								"/>
                    <polygon class="st509" points="335.999,291.746 338.067,292.949 338.067,287.511 335.999,286.278 								"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <polygon class="st507" points="360.163,321.137 360.944,320.691 360.944,298.105 360.224,298.537 			"/>
        </g>
        <g>
          <linearGradient id="SVGID_124_" gradientUnits="userSpaceOnUse" x1="479.5391" y1="330.0967" x2="442.4126" y2="315.4415">
            <stop  offset="0" style="stop-color:#FFFFFF"/>
            <stop  offset="1" style="stop-color:#000000"/>
          </linearGradient>
          <polygon class="st522" points="403.143,343.418 504.61,345.959 507.398,297.617 440.455,297.649 			"/>
          <g>
            <g id="XMLID_17_">
              <g>
                <polygon class="st156" points="378.016,307.58 400.009,320.07 461.386,284.575 439.344,272.078 						"/>
                <polygon class="st523" points="452.312,314.711 457.824,311.524 457.824,305.157 452.312,308.152 						"/>
                <polygon class="st523" points="438.459,322.719 443.971,319.532 443.971,313.16 438.459,316.155 						"/>
                <polygon class="st523" points="431.429,326.784 436.941,323.597 436.941,317.331 431.429,320.326 						"/>
                <polygon class="st523" points="417.298,328.273 411.784,331.265 411.784,337.862 411.904,338.071 417.418,334.884 
                  417.298,334.674 						"/>
                <path class="st154" d="M404.263,328.31v4.149l7.129-4.099l51.29-29.486v-4.148l-51.973,29.88L404.263,328.31z
                  M458.953,297.634l2.403-1.378v2.566l-2.403,1.38V297.634z M455.81,299.443l2.403-1.38v2.566l-2.403,1.383V299.443z
                  M452.665,301.252l2.403-1.38v2.566l-2.403,1.38V301.252z M449.519,303.059l2.403-1.378v2.564l-2.403,1.383V303.059z
                  M446.374,304.868l2.403-1.38v2.566l-2.403,1.383V304.868z M443.231,306.678l2.403-1.38v2.566l-2.403,1.38V306.678z
                  M440.086,308.485l2.403-1.378v2.563l-2.403,1.383V308.485z M436.941,310.294l2.403-1.38v2.566l-2.403,1.383V310.294z
                  M433.798,312.101l2.403-1.378v2.566l-2.403,1.38V312.101z M430.653,313.91l2.403-1.378v2.564l-2.403,1.383V313.91z
                  M427.507,315.719l2.403-1.38v2.566l-2.403,1.383V315.719z M424.362,317.526l2.403-1.378v2.566l-2.403,1.38V317.526z
                  M421.219,319.335l2.403-1.38v2.566l-2.403,1.383V319.335z M418.074,321.144l2.403-1.38v2.566l-2.403,1.38V321.144z
                  M414.929,322.951l2.403-1.378v2.566l-2.403,1.38V322.951z M411.784,324.761l2.403-1.38v2.566l-2.403,1.383V324.761z
                  M407.899,329.565l-2.403,1.38v-2.568l2.403-1.378V329.565z M411.044,327.755l-2.403,1.38v-2.566l2.403-1.38V327.755z"/>
                <path class="st154" d="M400.009,323.89l2.958,1.602v0.246c0.493-0.74,60.965-35.988,60.965-35.988l-2.546-1.479
                  L400.009,323.89z"/>
                <polygon class="st4" points="444.636,279.366 439.098,276.181 439.098,276.188 435.763,274.271 434.89,274.774 
                  439.129,277.234 439.13,277.251 444.667,280.447 444.667,280.456 456.549,287.375 457.529,286.805 444.635,279.349 						"/>
                <polygon class="st4" points="428.456,278.486 438.267,284.174 438.269,284.237 450.172,291.04 451.136,290.463 
                  429.329,277.981 						"/>
                <polygon class="st4" points="422.025,282.196 443.753,294.808 444.717,294.228 422.898,281.693 						"/>
                <polygon class="st4" points="415.592,285.906 437.344,298.526 438.308,297.946 416.465,285.403 						"/>
                <polygon class="st4" points="409.161,289.615 430.903,302.196 431.867,301.62 410.033,289.113 						"/>
                <polygon class="st4" points="402.73,293.325 424.518,305.962 425.496,305.393 403.6,292.822 						"/>
                <polygon class="st4" points="396.296,297.035 418.102,309.682 419.052,309.094 397.169,296.532 						"/>
                <polygon class="st4" points="389.868,300.745 411.606,313.355 412.57,312.776 390.735,300.244 						"/>
                <polygon class="st4" points="383.434,304.454 405.19,317.072 406.154,316.496 384.304,303.951 						"/>
                <polygon class="st524" points="378.016,308.576 400.009,321.273 400.009,320.07 378.016,307.58 						"/>
                <polygon class="st95" points="400.009,323.89 461.386,288.272 461.386,285.755 400.009,321.273 						"/>
                <path class="st95" d="M402.966,343.24l8.938-5.169l-0.121-0.21v-6.596l5.514-2.992v6.401l0.121,0.21l14.011-8.1v-6.458
                  l5.512-2.995v6.266l1.518-0.878v-6.564l5.512-2.995v6.372l8.341-4.822v-6.559l5.512-2.995v6.367l6.108-3.532v-18.241
                  c0,0-60.472,35.249-60.965,35.988V343.24z"/>
                <path class="st525" d="M400.009,321.273l-21.992-12.697v20.533l24.95,14.132v-17.501v-0.246l-2.958-1.602V321.273z
                  M383.829,318.983l-1.873-1.016v-2.803l1.873,1.033V318.983z M386.856,320.723l-1.871-1.016v-2.803l1.871,1.033V320.723z
                  M389.885,322.463l-1.873-1.016v-2.803l1.873,1.035V322.463z M392.912,324.203l-1.871-1.015v-2.803l1.871,1.035V324.203z
                  M395.941,325.944l-1.873-1.016v-2.803l1.873,1.035V325.944z"/>
                <polygon class="st56" points="449.406,287.057 449.408,287.047 449.406,287.047 						"/>
                <path class="st56" d="M449.399,287.114h0.01l-0.003-0.057C449.406,287.074,449.404,287.094,449.399,287.114z"/>
                <path class="st56" d="M445.746,287.114h0.007c-0.005-0.02-0.005-0.037-0.005-0.057L445.746,287.114z"/>
                <path class="st56" d="M445.748,287.057v-0.059h-0.005C445.743,287.017,445.743,287.037,445.748,287.057z"/>
                <path class="st56" d="M443.108,291.432h0.012l-0.002-0.064C443.115,291.388,443.113,291.41,443.108,291.432z"/>
                <path class="st56" d="M439.457,291.432h0.005c-0.003-0.02-0.005-0.037-0.003-0.054L439.457,291.432z"/>
                <path class="st56" d="M439.46,291.378v-0.062h-0.005C439.455,291.336,439.455,291.356,439.46,291.378z"/>
                <g>
                  <polygon class="st210" points="458.647,300.731 461.662,298.999 461.662,295.728 458.647,297.457 							"/>
                  <path class="st526" d="M458.648,297.103l-0.308,0.176v0.355v2.568v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    V295.2l-0.917,0.525L458.648,297.103z M461.356,298.822l-2.403,1.38v-2.568l2.403-1.378V298.822z"/>
                </g>
                <g>
                  <polygon class="st210" points="455.504,302.541 458.519,300.806 458.519,297.534 455.504,299.266 							"/>
                  <path class="st526" d="M455.505,298.912l-0.307,0.176v0.354v2.568v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L455.505,298.912z M458.213,300.629l-2.403,1.383v-2.568l2.403-1.38V300.629z"/>
                </g>
                <g>
                  <polygon class="st210" points="452.359,304.347 455.374,302.615 455.374,299.343 452.359,301.075 							"/>
                  <path class="st526" d="M452.36,300.722l-0.307,0.176v0.354v2.566v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    v-1.057l-0.917,0.527L452.36,300.722z M455.068,302.438l-2.403,1.38v-2.566l2.403-1.38V302.438z"/>
                </g>
                <g>
                  <polygon class="st210" points="449.213,306.157 452.229,304.422 452.229,301.153 449.213,302.882 							"/>
                  <path class="st526" d="M449.215,302.528l-0.308,0.176v0.355v2.569v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.564
                    v-1.056l-0.916,0.525L449.215,302.528z M451.923,304.245l-2.403,1.383v-2.569l2.403-1.378V304.245z"/>
                </g>
                <g>
                  <polygon class="st210" points="446.068,307.966 449.083,306.231 449.083,302.959 446.068,304.691 							"/>
                  <path class="st526" d="M446.069,304.338l-0.307,0.176v0.354v2.569v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L446.069,304.338z M448.777,306.054l-2.403,1.383v-2.569l2.403-1.38V306.054z"/>
                </g>
                <g>
                  <polygon class="st210" points="442.925,309.772 445.941,308.04 445.941,304.769 442.925,306.5 							"/>
                  <path class="st526" d="M442.926,306.147l-0.307,0.176v0.354v2.566v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    v-1.057l-0.917,0.527L442.926,306.147z M445.635,307.863l-2.403,1.38v-2.566l2.403-1.38V307.863z"/>
                </g>
                <g>
                  <polygon class="st210" points="439.78,311.582 442.795,309.847 442.795,306.578 439.78,308.307 							"/>
                  <path class="st526" d="M439.782,307.954l-0.308,0.176v0.355v2.568v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.563
                    v-1.057l-0.916,0.526L439.782,307.954z M442.489,309.67l-2.403,1.383v-2.568l2.403-1.378V309.67z"/>
                </g>
                <g>
                  <polygon class="st210" points="436.635,313.392 439.65,311.657 439.65,308.385 436.635,310.117 							"/>
                  <path class="st526" d="M436.636,309.763l-0.307,0.177v0.354v2.569v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L436.636,309.763z M439.344,311.479l-2.403,1.383v-2.569l2.403-1.38V311.479z"/>
                </g>
                <g>
                  <polygon class="st210" points="433.492,315.198 436.507,313.466 436.507,310.194 433.492,311.923 							"/>
                  <path class="st526" d="M433.494,311.57l-0.308,0.176v0.355v2.568v1.057l0.917-0.527l2.403-1.38l0.307-0.177v-0.354v-2.566
                    v-1.056l-0.916,0.525L433.494,311.57z M436.201,313.289l-2.403,1.38v-2.568l2.403-1.378V313.289z"/>
                </g>
                <g>
                  <polygon class="st210" points="430.347,317.008 433.362,315.273 433.362,312.003 430.347,313.733 							"/>
                  <path class="st526" d="M430.348,313.379l-0.308,0.176v0.354v2.569v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.564
                    v-1.056l-0.917,0.525L430.348,313.379z M433.056,315.095l-2.403,1.383v-2.569l2.403-1.378V315.095z"/>
                </g>
                <g>
                  <polygon class="st210" points="427.201,318.817 430.217,317.082 430.217,313.81 427.201,315.542 							"/>
                  <path class="st526" d="M427.203,315.188l-0.307,0.176v0.354v2.568v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L427.203,315.188z M429.911,316.905l-2.403,1.383v-2.568l2.403-1.38V316.905z"/>
                </g>
                <g>
                  <polygon class="st210" points="424.056,320.623 427.071,318.891 427.071,315.62 424.056,317.349 							"/>
                  <path class="st526" d="M424.058,316.995l-0.308,0.176v0.355v2.569v1.057l0.917-0.527l2.403-1.38l0.307-0.177v-0.354v-2.566
                    v-1.056l-0.916,0.525L424.058,316.995z M426.766,318.714l-2.403,1.38v-2.569l2.403-1.378V318.714z"/>
                </g>
                <g>
                  <polygon class="st210" points="420.913,322.433 423.929,320.698 423.929,317.426 420.913,319.158 							"/>
                  <path class="st526" d="M420.915,318.804l-0.307,0.176v0.354v2.569v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L420.915,318.804z M423.623,320.521l-2.403,1.383v-2.569l2.403-1.38V320.521z"/>
                </g>
                <g>
                  <polygon class="st210" points="417.768,324.239 420.783,322.507 420.783,319.236 417.768,320.967 							"/>
                  <path class="st526" d="M417.769,320.614l-0.307,0.176v0.354v2.566v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    v-1.057l-0.917,0.527L417.769,320.614z M420.477,322.33l-2.403,1.38v-2.566l2.403-1.38V322.33z"/>
                </g>
                <g>
                  <polygon class="st210" points="414.623,326.048 417.638,324.317 417.638,321.045 414.623,322.774 							"/>
                  <path class="st526" d="M414.624,322.42l-0.308,0.176v0.355v2.568v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    v-1.056l-0.916,0.526L414.624,322.42z M417.332,324.139l-2.403,1.38v-2.568l2.403-1.378V324.139z"/>
                </g>
                <g>
                  <polygon class="st210" points="411.478,327.858 414.493,326.123 414.493,322.852 411.478,324.583 							"/>
                  <path class="st526" d="M411.479,324.23l-0.307,0.176v0.354v2.568v1.058l0.917-0.528l2.403-1.383l0.307-0.177v-0.354v-2.566
                    v-1.057l-0.917,0.527L411.479,324.23z M414.187,325.946l-2.403,1.383v-2.568l2.403-1.38V325.946z"/>
                </g>
                <g>
                  <polygon class="st210" points="408.335,329.664 411.35,327.933 411.35,324.661 408.335,326.393 							"/>
                  <path class="st526" d="M408.336,326.039l-0.307,0.177v0.354v2.566v1.057l0.917-0.527l2.404-1.38l0.307-0.176v-0.354v-2.566
                    v-1.057l-0.917,0.527L408.336,326.039z M411.044,327.755l-2.403,1.38v-2.566l2.403-1.38V327.755z"/>
                </g>
                <g>
                  <polygon class="st210" points="405.19,331.474 408.205,329.742 408.205,326.47 405.19,328.199 							"/>
                  <path class="st526" d="M405.191,327.846l-0.308,0.176v0.355v2.568v1.057l0.917-0.527l2.403-1.38l0.307-0.176v-0.354v-2.566
                    v-1.056l-0.917,0.525L405.191,327.846z M407.899,329.565l-2.403,1.38v-2.568l2.403-1.378V329.565z"/>
                </g>
                <g>
                  <polygon class="st517" points="393.762,325.11 396.247,326.458 396.247,322.98 393.762,321.607 							"/>
                  <polygon class="st517" points="390.735,323.37 393.218,324.718 393.218,321.24 390.735,319.866 							"/>
                  <polygon class="st517" points="387.706,321.63 390.191,322.977 390.191,319.5 387.706,318.126 							"/>
                  <polygon class="st517" points="384.679,319.889 387.162,321.237 387.162,317.757 384.679,316.386 							"/>
                  <polygon class="st517" points="381.649,318.15 384.135,319.497 384.135,316.016 381.649,314.646 							"/>
                  <path class="st56" d="M381.343,313.945v0.184v0.115v0.922v2.803v0.159v0.205v0.093l15.21,8.734v-0.187v-0.174v-0.854v-2.783
                    v-0.184V322.8v-0.184L381.343,313.945z M392.912,321.421v2.783l-1.871-1.015v-2.803L392.912,321.421z M389.885,319.68v2.783
                    l-1.873-1.016v-2.803L389.885,319.68z M386.856,317.938v2.785l-1.871-1.016v-2.803L386.856,317.938z M383.829,316.197v2.785
                    l-1.873-1.016v-2.803L383.829,316.197z M394.068,324.928v-2.803l1.873,1.035v2.783L394.068,324.928z"/>
                </g>
                <g>
                  <path class="st527" d="M449.94,269.384c-0.626-0.365-1.4-0.372-2.036-0.017l-0.039,0.022
                    c-0.21,0.118-0.313,0.328-0.313,0.537c0,0.209,0.106,0.414,0.316,0.53l0.032,0.017c0.616,0.338,1.363,0.338,1.982,0
                    l0.047-0.027c0.212-0.116,0.318-0.325,0.318-0.535c0-0.008,0-0.017-0.002-0.025
                    C450.238,269.689,450.137,269.497,449.94,269.384z"/>
                  <path class="st528" d="M449.997,269.893c-0.003-0.124-0.068-0.231-0.181-0.296c-0.275-0.16-0.589-0.245-0.908-0.245
                    c-0.308,0-0.614,0.08-0.883,0.23l-0.039,0.022c-0.169,0.095-0.188,0.258-0.188,0.323c0,0.133,0.068,0.248,0.186,0.313
                    l0.034,0.018c0.266,0.146,0.568,0.223,0.872,0.223c0.304,0,0.606-0.077,0.869-0.22l0.052-0.03
                    c0.111-0.061,0.181-0.17,0.189-0.296l-0.001-0.002L449.997,269.893z"/>
                  <path class="st529" d="M450.243,269.887c0.002,0.007,0.002,0.017,0.002,0.025c0,0.209-0.106,0.419-0.318,0.535l-0.047,0.027
                    c-0.619,0.338-1.366,0.338-1.982,0l-0.032-0.017c-0.209-0.116-0.316-0.32-0.316-0.53h-0.017l-0.118,4.077
                    c0,0.227,0.141,0.454,0.37,0.579l0.032,0.017c0.665,0.365,1.474,0.365,2.142,0l0.052-0.03
                    c0.227-0.123,0.367-0.348,0.37-0.574l-0.118-4.109H450.243z"/>
                  <path class="st530" d="M450.581,280.853l-0.131-4.553l-0.069-2.305c-0.003,0.227-0.143,0.451-0.37,0.574l-0.052,0.03
                    c-0.668,0.365-1.477,0.365-2.142,0l-0.032-0.017c-0.229-0.126-0.37-0.352-0.37-0.579l-0.017,0.53l-0.128,4.417l-0.032,1.052
                    l-0.17,5.825v0.059c0,0.02,0,0.037,0.005,0.057c0.015,0.123,0.054,0.251,0.118,0.367c0.072,0.133,0.173,0.249,0.303,0.32
                    l0.042,0.022c0.232,0.128,0.478,0.219,0.732,0.276c0.658,0.15,1.361,0.057,1.967-0.276l0.064-0.037
                    c0.242-0.133,0.382-0.421,0.419-0.673c0.005-0.02,0.007-0.039,0.007-0.057v-0.01l-0.113-3.917L450.581,280.853z"/>
                  <path class="st133" d="M450.581,280.853l-0.131-4.553l-0.069-2.305l-0.118-4.109h-0.02c0.002,0.007,0.002,0.017,0.002,0.025
                    c0,0.21-0.106,0.419-0.318,0.535l-0.047,0.027c-0.053,0.029-0.109,0.052-0.164,0.076c0.022,0.157,0.038,0.316,0.038,0.478
                    v12.448c0,1.412-0.87,2.661-2.091,3.236c0.195,0.095,0.396,0.171,0.605,0.217c0.658,0.15,1.361,0.057,1.967-0.276
                    l0.064-0.037c0.242-0.133,0.382-0.421,0.419-0.673c0.005-0.02,0.007-0.039,0.007-0.057v-0.01l-0.113-3.917L450.581,280.853z"
                    />
                </g>
                <g>
                  <path class="st527" d="M443.652,273.702c-0.629-0.362-1.403-0.37-2.036-0.017l-0.039,0.022
                    c-0.21,0.118-0.316,0.328-0.313,0.537c0,0.21,0.104,0.417,0.316,0.532l0.03,0.015c0.616,0.34,1.366,0.34,1.982,0l0.049-0.025
                    c0.212-0.116,0.318-0.325,0.318-0.537c0-0.007,0-0.015-0.003-0.022C443.95,274.01,443.849,273.816,443.652,273.702z"/>
                  <path class="st528" d="M443.529,273.916c-0.277-0.16-0.592-0.244-0.911-0.244c-0.308,0-0.613,0.079-0.881,0.228l-0.039,0.022
                    c-0.119,0.067-0.189,0.187-0.188,0.323c0,0.136,0.068,0.251,0.179,0.312l0.039,0.02c0.266,0.147,0.567,0.224,0.872,0.224
                    c0.304,0,0.606-0.078,0.88-0.229l0.041-0.021c0.114-0.062,0.181-0.169,0.189-0.298l-0.002-0.039
                    C443.706,274.088,443.64,273.98,443.529,273.916z"/>
                  <path class="st529" d="M444.056,277.106l-0.084-2.899h-0.017c0.003,0.007,0.003,0.015,0.003,0.022
                    c0,0.212-0.106,0.421-0.318,0.537l-0.049,0.025c-0.616,0.34-1.366,0.34-1.982,0l-0.03-0.015
                    c-0.212-0.116-0.316-0.323-0.316-0.532h-0.017l-0.037,1.228l-0.03,1.016l-0.052,1.834c0,0.227,0.141,0.454,0.367,0.579
                    l0.032,0.017c0.668,0.367,1.476,0.367,2.142,0l0.052-0.027c0.227-0.126,0.37-0.35,0.372-0.574l-0.005-0.141L444.056,277.106z
                    "/>
                  <path class="st530" d="M444.276,284.637l-0.182-6.32c-0.002,0.224-0.145,0.449-0.372,0.574l-0.052,0.027
                    c-0.665,0.367-1.474,0.367-2.142,0l-0.032-0.017c-0.227-0.126-0.367-0.353-0.367-0.579l-0.131,4.432l-0.03,1.05l-0.182,6.229
                    l-0.005,0.113v0.062c-0.003,0.017,0,0.034,0.002,0.054c0.032,0.261,0.175,0.55,0.424,0.688l0.039,0.022
                    c0.535,0.293,1.141,0.399,1.728,0.318c0.335-0.044,0.663-0.15,0.971-0.318l0.067-0.037c0.244-0.131,0.382-0.424,0.417-0.673
                    c0.005-0.022,0.007-0.044,0.01-0.064l-0.128-4.454L444.276,284.637z"/>
                  <path class="st133" d="M444.276,284.637l-0.182-6.32l-0.005-0.141l-0.032-1.07l-0.084-2.899h-0.017
                    c0.003,0.007,0.003,0.015,0.003,0.022c0,0.212-0.106,0.421-0.318,0.537l-0.049,0.025c-0.083,0.046-0.169,0.085-0.257,0.118
                    c0.049,0.23,0.075,0.467,0.075,0.709v12.294c0,1.356-0.802,2.561-1.946,3.165c0.479,0.212,1.003,0.283,1.512,0.212
                    c0.335-0.044,0.663-0.15,0.971-0.318l0.067-0.037c0.244-0.131,0.382-0.424,0.417-0.673c0.005-0.022,0.007-0.044,0.01-0.064
                    l-0.128-4.454L444.276,284.637z"/>
                </g>
                <polygon class="st531" points="450.128,291.084 443.709,294.797 437.288,298.509 430.87,302.221 424.448,305.936 
                  418.03,309.648 411.609,313.36 405.19,317.072 400.009,320.07 400.009,321.273 461.386,285.755 461.386,284.575 
                  456.549,287.372 						"/>
              </g>
            </g>
          </g>
        </g>
        <g class="st151">
          <g>
            <path class="st149" d="M314.718,199.147c0,3.871-3.138,7.008-7.008,7.008c-3.871,0-7.008-3.138-7.008-7.008
              c0-3.871,3.138-7.008,7.008-7.008C311.581,192.139,314.718,195.276,314.718,199.147z"/>
            <path class="st149" d="M324.741,197.207c0,3.871-3.138,7.008-7.008,7.008s-7.008-3.138-7.008-7.008
              c0-3.871,3.138-7.008,7.008-7.008S324.741,193.337,324.741,197.207z"/>
            <circle class="st532" cx="309.51" cy="186.695" r="8.223"/>
            
              <ellipse transform="matrix(0.9231 -0.3846 0.3846 0.9231 -46.7087 138.3395)" class="st149" cx="322.501" cy="185.944" rx="8.223" ry="8.223"/>
            <circle class="st149" cx="316.733" cy="176.632" r="7.155"/>
            
              <ellipse transform="matrix(0.2309 -0.973 0.973 0.2309 71.6023 462.1234)" class="st149" cx="328.111" cy="185.771" rx="11.517" ry="11.517"/>
            <circle class="st532" cx="326.932" cy="173.323" r="6.733"/>
            <path class="st149" d="M320.9,192.052c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C317.885,185.318,320.9,188.333,320.9,192.052z"/>
            <path class="st149" d="M342.511,176.908c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C339.496,170.175,342.511,173.189,342.511,176.908z"/>
            <path class="st149" d="M338.567,185.559c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C335.553,178.825,338.567,181.84,338.567,185.559z"/>
            <path class="st149" d="M326.932,192.523c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C323.918,185.79,326.932,188.804,326.932,192.523z"/>
            <path class="st149" d="M322.478,185.559c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C319.463,178.825,322.478,181.84,322.478,185.559z"/>
            <path class="st149" d="M330.351,179.612c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C327.336,172.879,330.351,175.893,330.351,179.612z"/>
            <path class="st149" d="M340.399,180.274c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C337.384,173.541,340.399,176.556,340.399,180.274z"/>
            <path class="st149" d="M334.845,188.532c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C331.83,181.799,334.845,184.814,334.845,188.532z"/>
            <path class="st149" d="M325.563,195.106c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C322.549,188.373,325.563,191.387,325.563,195.106z"/>
            <circle class="st149" cx="309.392" cy="203.49" r="5.224"/>
            <circle class="st149" cx="311.055" cy="199.789" r="5.224"/>
            <path class="st532" d="M347.529,184.139c0,3.349-2.715,6.063-6.063,6.063c-3.349,0-6.063-2.715-6.063-6.063
              c0-3.349,2.715-6.063,6.063-6.063C344.815,178.076,347.529,180.791,347.529,184.139z"/>
            <path class="st149" d="M339.019,174.802c1.612-2.393,4.858-3.026,7.251-1.414c2.393,1.612,3.026,4.858,1.414,7.251
              c-1.612,2.393-4.858,3.026-7.251,1.414C338.04,180.442,337.407,177.195,339.019,174.802z"/>
            <path class="st149" d="M315.636,173.226c2.554-3.791,7.697-4.794,11.488-2.24c3.791,2.554,4.794,7.697,2.24,11.488
              c-2.554,3.791-7.697,4.794-11.488,2.24C314.085,182.161,313.082,177.017,315.636,173.226z"/>
          </g>
          <g class="st151">
            <circle class="st532" cx="334.274" cy="173.442" r="8.223"/>
            <circle class="st149" cx="347.265" cy="172.69" r="8.223"/>
            <circle class="st149" cx="341.497" cy="163.379" r="7.155"/>
            <path class="st532" d="M358.43,160.069c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C355.415,153.336,358.43,156.351,358.43,160.069z"/>
            <path class="st149" d="M345.664,178.798c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C342.649,172.065,345.664,175.08,345.664,178.798z"/>
            <path class="st149" d="M367.275,163.655c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C364.26,156.921,367.275,159.936,367.275,163.655z"/>
            <path class="st149" d="M363.331,172.305c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C360.317,165.572,363.331,168.587,363.331,172.305z"/>
            <path class="st149" d="M355.115,166.359c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C352.1,159.625,355.115,162.64,355.115,166.359z"/>
            <path class="st149" d="M365.163,167.021c0,3.719-3.015,6.733-6.733,6.733c-3.719,0-6.733-3.015-6.733-6.733
              c0-3.719,3.015-6.733,6.733-6.733C362.148,160.288,365.163,163.302,365.163,167.021z"/>
            <circle class="st149" cx="334.156" cy="190.237" r="5.224"/>
            <circle class="st149" cx="335.819" cy="186.536" r="5.224"/>
            <circle class="st149" cx="340.458" cy="175.772" r="11.609"/>
            <circle class="st149" cx="347.871" cy="171.278" r="11.609"/>
            
              <ellipse transform="matrix(0.5582 -0.8297 0.8297 0.5582 -1.9166 354.4781)" class="st149" cx="331.907" cy="179.039" rx="11.609" ry="11.609"/>
            <path class="st149" d="M363.783,161.549c1.612-2.393,4.858-3.026,7.251-1.414s3.026,4.858,1.414,7.251
              c-1.612,2.393-4.858,3.026-7.251,1.414C362.804,167.188,362.171,163.942,363.783,161.549z"/>
          </g>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_125_" gradientUnits="userSpaceOnUse" x1="367.2989" y1="389.9355" x2="425.7794" y2="389.9355">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st533" points="367.299,392.767 425.779,391.031 425.779,388.84 367.377,387.104 		"/>
        <linearGradient id="SVGID_126_" gradientUnits="userSpaceOnUse" x1="396.5392" y1="408.4006" x2="459.9175" y2="408.4006">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st534" points="396.539,411.232 459.918,409.785 459.918,407.016 396.617,405.569 		"/>
        <g>
          <path class="st535" d="M367.508,329.071l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.654-0.405,2.654-0.904
            l-1.846-62.871H367.508z"/>
          <path class="st536" d="M367.508,329.071l-0.079,2.684c0.738,1.215,1.175,2.621,1.175,4.107v52.08
            c0,1.773-0.611,3.428-1.619,4.779c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.654-0.405,2.654-0.904l-1.846-62.871H367.508z"
            />
          <g>
            <path class="st537" d="M371.558,324.22l-0.005-0.002c-0.436-0.204-0.893-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.27,0.554-0.69,0.512-1.182
              C372.79,325.275,372.294,324.564,371.558,324.22z"/>
            <path class="st511" d="M367.722,325.41l-0.006-0.003c-1.216-0.569-2.592,0.393-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C369.286,326.748,368.656,325.847,367.722,325.41z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M396.341,331.865l-22.682-5.804l-3.217,1.16l-2.038-0.056l-0.168,1.441l2.223,0.111l2.879,0.78
                l22.989,3.693l1.361-0.255C397.405,332.368,396.341,331.865,396.341,331.865z"/>
            </g>
            <g>
              <path class="st535" d="M365.501,321.562l-9.6-21.213l-0.965-0.994c-0.315,0.546-0.148,1.713-0.148,1.713l7.671,22.121
                l2.741,2.045l1.077,1.731l1.296-0.655l-1.135-1.915L365.501,321.562z"/>
            </g>
            <g>
              <path class="st537" d="M364.374,329.972l-2.231,1.979l-15.7,17.194l-0.533,1.279c0.626,0.073,1.632-0.54,1.632-0.54
                l17.296-15.78l0.795-3.326l1.165-1.672l-1.112-0.933L364.374,329.972z"/>
            </g>
            <g>
              <path class="st535" d="M366.673,325.463c-1.081,0.203-1.792,1.243-1.588,2.325c0.203,1.082,1.244,1.792,2.325,1.589
                c1.082-0.203,1.793-1.244,1.59-2.325C368.796,325.97,367.755,325.259,366.673,325.463z"/>
            </g>
          </g>
        </g>
        <g>
          <path class="st535" d="M396.58,347.5l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.655-0.405,2.655-0.904
            l-1.846-62.871H396.58z"/>
          <path class="st536" d="M396.58,347.5l-0.079,2.683c0.738,1.215,1.175,2.621,1.175,4.107v52.08c0,1.772-0.611,3.428-1.619,4.779
            c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.655-0.405,2.655-0.904l-1.846-62.871H396.58z"/>
          <g>
            <path class="st537" d="M400.629,342.65l-0.005-0.002c-0.436-0.204-0.894-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.271,0.554-0.691,0.512-1.182
              C401.861,343.704,401.365,342.994,400.629,342.65z"/>
            <path class="st511" d="M396.793,343.84l-0.006-0.003c-1.216-0.569-2.592,0.393-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C398.357,345.178,397.727,344.276,396.793,343.84z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M409.462,319.29l-12.558,19.759l0.104,3.419l-0.686,1.919l1.318,0.607l0.795-2.078l1.635-2.495
                l10.647-20.707l0.18-1.373C410.272,318.435,409.462,319.29,409.462,319.29z"/>
            </g>
            <g>
              <path class="st538" d="M393.076,345.396l-2.982,0.011l-23.145,2.541l-1.245,0.609c0.422,0.469,1.582,0.673,1.582,0.673
                l23.409-0.425l2.795-1.971l1.98-0.487l-0.22-1.436L393.076,345.396z"/>
            </g>
            <g>
              <path class="st537" d="M401.812,349.931l-2.915-1.789l-1.227-1.626l-1.232,0.767l1.302,1.806l1.189,2.735l11.47,20.262
                l1.051,0.904c0.263-0.572-0.007-1.719-0.007-1.719L401.812,349.931z"/>
            </g>
            <g>
              <path class="st535" d="M396.4,343.785c-1.092-0.142-2.091,0.628-2.233,1.719c-0.143,1.091,0.626,2.09,1.717,2.232
                c1.091,0.143,2.09-0.626,2.232-1.716C398.259,344.928,397.491,343.928,396.4,343.785z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_127_" gradientUnits="userSpaceOnUse" x1="281.7919" y1="423.9375" x2="340.2724" y2="423.9375">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st539" points="281.792,426.769 340.272,425.033 340.272,422.842 281.87,421.106 		"/>
        <linearGradient id="SVGID_128_" gradientUnits="userSpaceOnUse" x1="311.0322" y1="442.4026" x2="374.4105" y2="442.4026">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st540" points="311.032,445.234 374.411,443.787 374.411,441.018 311.11,439.571 		"/>
        <g>
          <path class="st535" d="M282.001,363.073l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.655-0.405,2.655-0.904
            l-1.846-62.871H282.001z"/>
          <path class="st536" d="M282.001,363.073l-0.079,2.684c0.738,1.215,1.175,2.621,1.175,4.107v52.08
            c0,1.773-0.611,3.428-1.619,4.779c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.655-0.405,2.655-0.904l-1.846-62.871H282.001z"
            />
          <g>
            <path class="st537" d="M286.051,358.222l-0.005-0.002c-0.436-0.204-0.893-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.27,0.554-0.69,0.512-1.182
              C287.283,359.277,286.787,358.566,286.051,358.222z"/>
            <path class="st511" d="M282.215,359.412l-0.006-0.003c-1.216-0.569-2.592,0.392-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C283.779,360.75,283.149,359.849,282.215,359.412z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M310.834,365.867l-22.682-5.804l-3.217,1.16l-2.038-0.056l-0.168,1.441l2.223,0.111l2.879,0.78
                l22.989,3.693l1.361-0.255C311.898,366.37,310.834,365.867,310.834,365.867z"/>
            </g>
            <g>
              <path class="st535" d="M279.994,355.564l-9.601-21.213l-0.965-0.995c-0.315,0.546-0.148,1.713-0.148,1.713l7.671,22.121
                l2.741,2.045l1.077,1.731l1.296-0.655l-1.135-1.915L279.994,355.564z"/>
            </g>
            <g>
              <path class="st537" d="M278.867,363.974l-2.231,1.979l-15.7,17.194l-0.533,1.279c0.626,0.073,1.632-0.54,1.632-0.54
                l17.296-15.78l0.795-3.326l1.165-1.672l-1.112-0.933L278.867,363.974z"/>
            </g>
            <g>
              <path class="st535" d="M281.166,359.465c-1.081,0.203-1.792,1.243-1.588,2.325c0.203,1.082,1.244,1.792,2.325,1.589
                c1.082-0.203,1.793-1.244,1.59-2.325C283.289,359.972,282.248,359.261,281.166,359.465z"/>
            </g>
          </g>
        </g>
        <g>
          <path class="st535" d="M311.073,381.502l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.655-0.405,2.655-0.904
            l-1.846-62.871H311.073z"/>
          <path class="st536" d="M311.073,381.502l-0.079,2.683c0.738,1.215,1.175,2.621,1.175,4.107v52.08
            c0,1.773-0.611,3.428-1.619,4.779c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.655-0.405,2.655-0.904l-1.846-62.871H311.073z"
            />
          <g>
            <path class="st537" d="M315.122,376.652l-0.005-0.002c-0.436-0.204-0.894-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.271,0.554-0.691,0.512-1.182
              C316.354,377.706,315.858,376.996,315.122,376.652z"/>
            <path class="st511" d="M311.286,377.842l-0.006-0.003c-1.216-0.569-2.592,0.393-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C312.85,379.18,312.22,378.278,311.286,377.842z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M323.955,353.292l-12.558,19.759l0.104,3.419l-0.686,1.919l1.318,0.607l0.795-2.078l1.635-2.495
                l10.647-20.707l0.18-1.373C324.765,352.437,323.955,353.292,323.955,353.292z"/>
            </g>
            <g>
              <path class="st538" d="M307.569,379.398l-2.982,0.011l-23.146,2.541l-1.245,0.609c0.422,0.469,1.582,0.673,1.582,0.673
                l23.409-0.425l2.795-1.971l1.98-0.487l-0.22-1.436L307.569,379.398z"/>
            </g>
            <g>
              <path class="st537" d="M316.305,383.933l-2.915-1.789l-1.227-1.626l-1.232,0.768l1.302,1.806l1.189,2.735l11.47,20.262
                l1.051,0.904c0.263-0.572-0.007-1.719-0.007-1.719L316.305,383.933z"/>
            </g>
            <g>
              <path class="st535" d="M310.893,377.787c-1.092-0.142-2.091,0.628-2.233,1.719c-0.143,1.091,0.626,2.09,1.717,2.232
                c1.091,0.143,2.09-0.626,2.232-1.716C312.752,378.93,311.984,377.93,310.893,377.787z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <linearGradient id="SVGID_129_" gradientUnits="userSpaceOnUse" x1="328.8629" y1="415.2808" x2="387.3434" y2="415.2808">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st541" points="328.863,418.112 387.343,416.376 387.343,414.186 328.94,412.45 		"/>
        <linearGradient id="SVGID_130_" gradientUnits="userSpaceOnUse" x1="358.1032" y1="433.7458" x2="421.4815" y2="433.7458">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st542" points="358.103,436.577 421.481,435.13 421.481,432.361 358.181,430.915 		"/>
        <g>
          <path class="st535" d="M329.072,354.416l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.655-0.405,2.655-0.904
            l-1.846-62.871H329.072z"/>
          <path class="st536" d="M329.072,354.416l-0.079,2.684c0.738,1.215,1.175,2.621,1.175,4.107v52.08
            c0,1.773-0.611,3.428-1.619,4.779c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.655-0.405,2.655-0.904l-1.846-62.871H329.072z"
            />
          <g>
            <path class="st537" d="M333.122,349.566l-0.005-0.002c-0.436-0.204-0.893-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.27,0.554-0.69,0.512-1.182
              C334.354,350.62,333.857,349.91,333.122,349.566z"/>
            <path class="st511" d="M329.286,350.756l-0.006-0.003c-1.216-0.569-2.592,0.392-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C330.849,352.094,330.22,351.192,329.286,350.756z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M357.905,357.21l-22.682-5.804l-3.217,1.16l-2.038-0.056l-0.168,1.441l2.223,0.111l2.879,0.78
                l22.989,3.693l1.361-0.255C358.968,357.714,357.905,357.21,357.905,357.21z"/>
            </g>
            <g>
              <path class="st535" d="M327.065,346.907l-9.6-21.213l-0.965-0.995c-0.315,0.546-0.148,1.713-0.148,1.713l7.671,22.121
                l2.741,2.045l1.077,1.731l1.296-0.655l-1.135-1.915L327.065,346.907z"/>
            </g>
            <g>
              <path class="st537" d="M325.938,355.318l-2.231,1.979l-15.7,17.194l-0.533,1.279c0.626,0.072,1.632-0.54,1.632-0.54
                l17.296-15.78l0.795-3.326l1.165-1.672l-1.112-0.933L325.938,355.318z"/>
            </g>
            <g>
              <path class="st535" d="M328.237,350.808c-1.081,0.203-1.792,1.243-1.588,2.325c0.203,1.082,1.244,1.792,2.325,1.589
                c1.082-0.203,1.793-1.244,1.59-2.325C330.36,351.315,329.319,350.605,328.237,350.808z"/>
            </g>
          </g>
        </g>
        <g>
          <path class="st535" d="M358.144,372.846l-1.848,62.871h0.001c0,0.499,1.188,0.904,2.655,0.904c1.467,0,2.655-0.405,2.655-0.904
            l-1.846-62.871H358.144z"/>
          <path class="st536" d="M358.144,372.846l-0.079,2.683c0.738,1.215,1.175,2.621,1.175,4.107v52.08
            c0,1.772-0.611,3.428-1.619,4.779c0.391,0.079,0.843,0.125,1.331,0.125c1.467,0,2.655-0.405,2.655-0.904l-1.846-62.871H358.144z"
            />
          <g>
            <path class="st537" d="M362.193,367.995l-0.005-0.002c-0.436-0.204-0.894-0.15-1.259,0.06l-0.003-0.005
              c0,0-3.989,1.184-4.305,1.358l2.613,3.746l3.76-2.097l-0.01-0.015c0.336-0.27,0.554-0.691,0.512-1.182
              C363.425,369.05,362.929,368.339,362.193,367.995z"/>
            <path class="st511" d="M358.357,369.185l-0.006-0.003c-1.216-0.569-2.592,0.393-2.476,1.73c0.089,1.027,0.719,1.928,1.652,2.365
              l0.006,0.003c1.216,0.569,2.592-0.392,2.476-1.73C359.921,370.523,359.291,369.622,358.357,369.185z"/>
          </g>
          <g>
            <g>
              <path class="st535" d="M371.026,344.635l-12.558,19.76l0.104,3.419l-0.686,1.919l1.318,0.607l0.795-2.078l1.635-2.495
                l10.647-20.707l0.18-1.373C371.835,343.78,371.026,344.635,371.026,344.635z"/>
            </g>
            <g>
              <path class="st538" d="M354.64,370.741l-2.982,0.011l-23.146,2.541l-1.245,0.609c0.421,0.469,1.582,0.673,1.582,0.673
                l23.409-0.425l2.795-1.971l1.98-0.487l-0.22-1.436L354.64,370.741z"/>
            </g>
            <g>
              <path class="st537" d="M363.376,375.276l-2.915-1.789l-1.227-1.626l-1.232,0.767l1.302,1.806l1.189,2.735l11.47,20.262
                l1.051,0.904c0.263-0.572-0.007-1.719-0.007-1.719L363.376,375.276z"/>
            </g>
            <g>
              <path class="st535" d="M357.964,369.131c-1.092-0.142-2.091,0.628-2.233,1.719c-0.143,1.091,0.626,2.09,1.717,2.232
                c1.091,0.143,2.09-0.626,2.232-1.716C359.823,370.273,359.055,369.273,357.964,369.131z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon class="st30" points="396.556,450.262 446.066,479.387 484.358,457.163 434.136,428.271 				"/>
            <polygon class="st509" points="412.612,448.973 449.037,469.868 449.037,440.175 412.728,419.594 				"/>
            <polygon class="st543" points="412.67,429.062 412.612,448.973 449.037,469.868 448.979,449.643 				"/>
            <g>
              <polygon class="st156" points="425.146,444.714 425.091,455.685 431.15,459.088 431.095,447.827 					"/>
              <path class="st56" d="M424.862,444.242l-0.058,11.61l6.635,3.726l-0.058-11.925L424.862,444.242z M425.378,455.518
                l0.052-10.332l5.38,2.815l0.052,10.596L425.378,455.518z"/>
            </g>
            <polygon class="st544" points="405.465,436.481 441.51,457.323 449.037,454.268 449.037,449.209 412.693,428.111 				"/>
            <polygon class="st545" points="449.037,454.268 449.037,449.209 441.51,457.323 				"/>
            <polygon class="st546" points="423.707,425.924 423.707,438.612 434.136,444.731 434.136,431.729 429.122,422.907 				"/>
            <polygon class="st508" points="434.136,444.731 437.854,442.717 437.854,430.057 434.136,432.216 				"/>
            <polygon class="st547" points="441.51,474.248 443.512,473.083 443.512,456.66 441.51,457.323 				"/>
            <polygon class="st546" points="439.553,473.125 441.51,474.248 441.51,457.323 439.553,456.082 				"/>
            <polygon class="st547" points="407.526,454.173 409.528,453.075 409.528,439.281 407.526,437.722 				"/>
            <polygon class="st546" points="405.465,453.1 407.526,454.173 407.526,437.722 405.465,436.481 				"/>
            <polygon class="st56" points="405.465,437.537 441.51,458.564 441.51,457.323 405.465,436.481 				"/>
            <polygon class="st548" points="441.51,458.564 449.037,454.268 449.037,452.745 441.51,457.323 				"/>
            <polygon class="st549" points="449.037,440.175 449.037,469.868 471.438,456.534 471.438,428.111 459.737,424.843 				"/>
            <polygon class="st550" points="438.163,434.677 449.762,441.285 461.021,425.555 442.524,415.23 				"/>
            <polygon class="st550" points="411.105,419.198 423.219,426.149 442.581,415.262 422.801,404.353 				"/>
            <polygon class="st545" points="422.801,404.353 461.021,425.555 472.87,428.398 436.048,407.736 				"/>
            <polygon class="st551" points="420.603,427.183 421.225,427.539 423.707,426.149 423.707,425.924 429.122,422.907 
              428.765,422.312 				"/>
            <polygon class="st552" points="435.816,435.908 438.163,434.677 442.511,415.288 428.765,422.312 				"/>
            <polygon class="st153" points="396.556,452.526 446.021,481.738 446.066,479.387 396.556,450.262 				"/>
            <polygon class="st154" points="446.021,481.738 484.358,459.256 484.358,457.163 446.066,479.387 				"/>
            <g>
              <polygon class="st67" points="454.41,460.407 457.56,458.468 457.56,450.931 454.41,452.75 					"/>
              <path class="st56" d="M454.123,460.919l3.723-2.291v-8.193l-3.723,2.15V460.919z M454.696,452.915l2.578-1.488v6.881
                l-2.578,1.586V452.915z"/>
            </g>
            <g>
              <polygon class="st67" points="464.751,454.459 467.901,452.52 467.901,445.081 464.751,446.997 					"/>
              <path class="st56" d="M464.465,454.971l3.723-2.291v-8.11l-3.723,2.265V454.971z M465.038,447.158l2.577-1.568v6.771
                l-2.577,1.586V447.158z"/>
            </g>
            <g>
              <polygon class="st67" points="454.41,445.892 457.56,443.954 457.56,436.417 454.41,438.236 					"/>
              <path class="st56" d="M454.123,446.405l3.723-2.291v-8.193l-3.723,2.15V446.405z M454.696,438.401l2.578-1.488v6.881
                l-2.578,1.586V438.401z"/>
            </g>
            <g>
              <polygon class="st67" points="464.751,439.944 467.901,438.006 467.901,430.566 464.751,432.482 					"/>
              <path class="st56" d="M464.465,440.457l3.723-2.291v-8.109l-3.723,2.265V440.457z M465.038,432.643l2.577-1.568v6.771
                l-2.577,1.586V432.643z"/>
            </g>
            <g>
              <g>
                <polygon class="st67" points="428.654,438.812 431.641,440.65 431.641,433.358 428.654,431.633 						"/>
                <path class="st56" d="M428.367,438.972l3.56,2.191v-7.97l-3.56-2.056V438.972z M428.94,432.129l2.415,1.394v6.615
                  l-2.415-1.486V432.129z"/>
              </g>
              <g>
                <polygon class="st67" points="425.639,436.895 428.627,438.733 428.627,431.627 425.639,429.809 						"/>
                <path class="st56" d="M425.353,437.054l3.56,2.191v-7.78l-3.56-2.166V437.054z M425.926,430.318l2.415,1.469v6.433
                  l-2.415-1.486V430.318z"/>
              </g>
            </g>
            <g>
              <polygon class="st67" points="441.087,443.425 443.456,444.882 443.456,439.127 441.087,437.685 					"/>
              <path class="st56" d="M440.8,443.584l2.942,1.811v-6.43l-2.942-1.79V443.584z M441.373,438.195l1.797,1.093v5.083l-1.797-1.106
                V438.195z"/>
            </g>
            <g>
              <polygon class="st67" points="417.232,429.677 419.602,431.135 419.602,425.379 417.232,423.938 					"/>
              <path class="st56" d="M416.946,429.837l2.942,1.811v-6.43l-2.942-1.79V429.837z M417.519,424.447l1.797,1.093v5.083
                l-1.797-1.106V424.447z"/>
            </g>
            <g>
              <polygon class="st157" points="455.053,417.802 456.561,418.653 458.039,417.804 456.544,416.941 					"/>
              <polygon class="st158" points="455.053,417.578 456.561,418.448 456.561,417.916 455.053,417.065 					"/>
              <polygon class="st159" points="456.561,418.448 458.039,417.55 458.039,417.068 456.561,417.916 					"/>
              <polygon class="st553" points="455.053,421.229 456.561,421.954 456.561,418.637 455.053,417.786 					"/>
              <polygon class="st554" points="456.561,421.954 458.039,422.175 458.039,417.788 456.561,418.637 					"/>
              <polygon class="st162" points="455.053,417.065 456.561,417.916 458.039,417.068 456.544,416.204 					"/>
            </g>
          </g>
          <g>
            <defs>
              <polygon id="SVGID_131_" points="442.524,415.23 442.524,415.231 422.801,404.353 411.105,419.198 423.219,426.149 
                442.508,415.303 438.163,434.677 449.762,441.285 461.021,425.555 					"/>
            </defs>
            <clipPath id="SVGID_132_">
              <use xlink:href="#SVGID_131_"  style="overflow:visible;"/>
            </clipPath>
            <g class="st555">
              <g>
                <path class="st171" d="M458.203,425.48c-0.079-0.044-0.18-0.015-0.224,0.064s-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L458.203,425.48z"/>
                <path class="st171" d="M452.835,422.506c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L452.835,422.506z"/>
                <path class="st171" d="M447.466,419.532c-0.08-0.044-0.18-0.015-0.224,0.064s-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L447.466,419.532z"/>
                <path class="st171" d="M442.098,416.558c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L442.098,416.558z"/>
                <path class="st171" d="M436.73,413.584c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L436.73,413.584z"/>
                <path class="st171" d="M431.362,410.61c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L431.362,410.61z"/>
                <path class="st171" d="M425.994,407.636c-0.079-0.044-0.18-0.015-0.224,0.064s-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L425.994,407.636z"/>
                <path class="st171" d="M420.626,404.662c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L420.626,404.662z"/>
              </g>
              <g>
                <path class="st171" d="M454.228,425.835c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L454.228,425.835z"/>
                <path class="st171" d="M448.86,422.862c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L448.86,422.862z"/>
                <path class="st171" d="M443.492,419.888c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L443.492,419.888z"/>
                <path class="st171" d="M438.124,416.914c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L438.124,416.914z"/>
                <path class="st171" d="M432.755,413.94c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L432.755,413.94z"/>
                <path class="st171" d="M427.387,410.966c-0.08-0.044-0.18-0.015-0.224,0.064s-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L427.387,410.966z"/>
                <path class="st171" d="M422.019,407.992c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L422.019,407.992z"/>
                <path class="st171" d="M416.651,405.018c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L416.651,405.018z"/>
              </g>
              <g>
                <path class="st171" d="M456.034,429.394c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L456.034,429.394z"/>
                <path class="st171" d="M450.666,426.42c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L450.666,426.42z"/>
                <path class="st171" d="M445.298,423.446c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L445.298,423.446z"/>
                <path class="st171" d="M439.93,420.472c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L439.93,420.472z"/>
                <path class="st171" d="M434.562,417.498c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L434.562,417.498z"/>
                <path class="st171" d="M429.194,414.525c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L429.194,414.525z"/>
                <path class="st171" d="M423.825,411.551c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L423.825,411.551z"/>
                <path class="st171" d="M418.457,408.577c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L418.457,408.577z"/>
              </g>
              <g>
                <path class="st171" d="M452.059,429.75c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L452.059,429.75z"/>
                <path class="st171" d="M446.691,426.776c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L446.691,426.776z"/>
                <path class="st171" d="M441.323,423.802c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L441.323,423.802z"/>
                <path class="st171" d="M435.955,420.828c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L435.955,420.828z"/>
                <path class="st171" d="M430.587,417.854c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L430.587,417.854z"/>
                <path class="st171" d="M425.219,414.88c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L425.219,414.88z"/>
                <path class="st171" d="M419.851,411.906c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L419.851,411.906z"/>
                <path class="st171" d="M414.483,408.933c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L414.483,408.933z"/>
              </g>
              <g>
                <path class="st171" d="M453.866,433.308c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L453.866,433.308z"/>
                <path class="st171" d="M448.497,430.334c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L448.497,430.334z"/>
                <path class="st171" d="M443.129,427.36c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L443.129,427.36z"/>
                <path class="st171" d="M437.761,424.387c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L437.761,424.387z"/>
                <path class="st171" d="M432.393,421.413c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L432.393,421.413z"/>
                <path class="st171" d="M427.025,418.439c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L427.025,418.439z"/>
                <path class="st171" d="M421.657,415.465c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L421.657,415.465z"/>
                <path class="st171" d="M416.289,412.491c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L416.289,412.491z"/>
              </g>
              <g>
                <path class="st171" d="M449.891,433.664c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L449.891,433.664z"/>
                <path class="st171" d="M444.523,430.69c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L444.523,430.69z"/>
                <path class="st171" d="M439.155,427.716c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L439.155,427.716z"/>
                <path class="st171" d="M433.786,424.743c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L433.786,424.743z"/>
                <path class="st171" d="M428.418,421.769c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L428.418,421.769z"/>
                <path class="st171" d="M423.05,418.795c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L423.05,418.795z"/>
                <path class="st171" d="M417.682,415.821c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L417.682,415.821z"/>
                <path class="st171" d="M412.314,412.847c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L412.314,412.847z"/>
              </g>
              <g>
                <path class="st171" d="M451.697,437.223c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L451.697,437.223z"/>
                <path class="st171" d="M446.329,434.249c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L446.329,434.249z"/>
                <path class="st171" d="M440.961,431.275c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L440.961,431.275z"/>
                <path class="st171" d="M435.593,428.301c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L435.593,428.301z"/>
                <path class="st171" d="M430.225,425.327c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L430.225,425.327z"/>
                <path class="st171" d="M424.857,422.353c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L424.857,422.353z"/>
                <path class="st171" d="M419.488,419.379c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L419.488,419.379z"/>
                <path class="st171" d="M414.12,416.405c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L414.12,416.405z"/>
              </g>
              <g>
                <path class="st171" d="M447.722,437.579c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L447.722,437.579z"/>
                <path class="st171" d="M442.354,434.605c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L442.354,434.605z"/>
                <path class="st171" d="M436.986,431.631c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L436.986,431.631z"/>
                <path class="st171" d="M431.618,428.657c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L431.618,428.657z"/>
                <path class="st171" d="M426.25,425.683c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L426.25,425.683z"/>
                <path class="st171" d="M420.882,422.709c-0.079-0.044-0.18-0.015-0.224,0.064s-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L420.882,422.709z"/>
                <path class="st171" d="M415.514,419.735c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L415.514,419.735z"/>
                <path class="st171" d="M410.145,416.761c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
                  c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L410.145,416.761z"/>
              </g>
            </g>
          </g>
        </g>
        <polygon class="st551" points="449.762,441.285 451.045,440.65 461.316,426.507 472.011,428.855 472.87,428.398 461.021,425.555 
              "/>
        <linearGradient id="SVGID_133_" gradientUnits="userSpaceOnUse" x1="407.4439" y1="452.6586" x2="420.4476" y2="452.6586">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st556" points="409.528,451.076 409.528,453.075 407.444,454.241 420.448,454.173 416.066,451.076 		"/>
        <linearGradient id="SVGID_134_" gradientUnits="userSpaceOnUse" x1="441.5103" y1="472.4898" x2="458.2391" y2="472.4898">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st557" points="443.512,470.861 443.512,473.083 441.51,474.248 458.239,473.896 454.413,470.731 		"/>
        <linearGradient id="SVGID_135_" gradientUnits="userSpaceOnUse" x1="449.0368" y1="460.8799" x2="480.375" y2="460.8799">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.6447" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st558" points="471.559,456.492 449.037,469.868 463.556,469.279 480.375,455.101 471.438,451.891 		"/>
      </g>
      <g>
        <g>
          <g>
            <polygon class="st30" points="452.917,482.878 502.409,512.004 540.719,489.779 490.497,460.887 				"/>
            <polygon class="st553" points="468.973,481.589 505.398,502.485 505.398,472.791 469.089,452.21 				"/>
            <g>
              <polygon class="st156" points="481.507,477.33 481.452,488.302 487.511,491.704 487.456,480.443 					"/>
              <path class="st56" d="M481.223,477.063l-0.058,11.611l6.635,3.725l-0.058-11.925L481.223,477.063z M481.739,488.339
                l0.052-10.332l5.38,2.815l0.052,10.596L481.739,488.339z"/>
            </g>
            <polygon class="st544" points="461.826,469.097 497.871,489.939 505.398,486.884 505.398,481.825 469.054,460.727 				"/>
            <polygon class="st545" points="505.398,486.884 505.398,481.825 497.871,489.939 				"/>
            <polygon class="st559" points="480.067,458.54 480.067,471.229 490.497,477.347 490.497,464.345 485.482,455.523 				"/>
            <polygon class="st554" points="490.497,477.347 494.215,475.333 494.215,462.673 490.497,464.833 				"/>
            <polygon class="st554" points="497.871,506.865 499.873,505.7 499.873,489.276 497.871,489.939 				"/>
            <polygon class="st560" points="495.914,505.742 497.871,506.865 497.871,489.939 495.914,488.698 				"/>
            <polygon class="st554" points="463.887,486.789 465.889,485.691 465.889,471.897 463.887,470.339 				"/>
            <polygon class="st560" points="461.826,485.716 463.887,486.789 463.887,470.339 461.826,469.097 				"/>
            <polygon class="st56" points="461.826,470.153 497.871,491.18 497.871,489.939 461.826,469.097 				"/>
            <polygon class="st548" points="497.871,491.18 505.398,486.884 505.398,485.361 497.871,489.939 				"/>
            <polygon class="st554" points="505.398,472.791 505.398,502.485 527.799,489.15 527.799,460.727 516.098,457.459 				"/>
            <polygon class="st550" points="494.524,467.293 506.123,473.902 517.382,458.172 498.885,447.847 				"/>
            <polygon class="st550" points="467.466,451.814 479.58,458.765 498.942,447.878 479.162,436.969 				"/>
            <polygon class="st545" points="479.162,436.969 517.382,458.172 529.231,461.014 492.409,440.352 				"/>
            <polygon class="st551" points="476.964,459.799 477.585,460.155 480.067,458.765 480.067,458.54 485.482,455.523 
              485.126,454.928 				"/>
            <polygon class="st552" points="492.177,468.525 494.524,467.293 498.872,447.904 485.126,454.928 				"/>
            <polygon class="st153" points="452.917,485.142 502.409,514.354 502.409,512.004 452.917,482.878 				"/>
            <polygon class="st154" points="502.409,514.354 540.719,491.872 540.719,489.779 502.409,512.004 				"/>
            <g>
              <polygon class="st67" points="510.771,493.023 513.921,491.084 513.921,483.547 510.771,485.366 					"/>
              <path class="st56" d="M510.484,493.535l3.723-2.291v-8.193l-3.723,2.15V493.535z M511.057,485.532l2.577-1.488v6.881
                l-2.577,1.586V485.532z"/>
            </g>
            <g>
              <polygon class="st67" points="521.112,487.075 524.262,485.137 524.262,477.697 521.112,479.613 					"/>
              <path class="st56" d="M520.826,487.588l3.723-2.291v-8.109l-3.723,2.265V487.588z M521.398,479.774l2.577-1.568v6.771
                l-2.577,1.586V479.774z"/>
            </g>
            <g>
              <polygon class="st67" points="510.77,478.508 513.921,476.57 513.921,469.033 510.77,470.852 					"/>
              <path class="st56" d="M510.484,479.021l3.723-2.291v-8.193l-3.723,2.15V479.021z M511.057,471.017l2.577-1.488v6.881
                l-2.577,1.586V471.017z"/>
            </g>
            <g>
              <polygon class="st67" points="521.112,472.561 524.262,470.622 524.262,463.182 521.112,465.098 					"/>
              <path class="st56" d="M520.826,473.073l3.723-2.291v-8.109l-3.723,2.265V473.073z M521.398,465.26l2.577-1.568v6.77
                l-2.577,1.586V465.26z"/>
            </g>
            <g>
              <g>
                <polygon class="st67" points="485.015,471.428 488.002,473.266 488.002,465.974 485.015,464.25 						"/>
                <path class="st56" d="M484.728,471.588l3.56,2.191v-7.97l-3.56-2.055V471.588z M485.301,464.745l2.415,1.394v6.614
                  l-2.415-1.486V464.745z"/>
              </g>
              <g>
                <polygon class="st67" points="482,469.511 484.988,471.349 484.988,464.243 482,462.425 						"/>
                <path class="st56" d="M481.714,469.671l3.56,2.191v-7.78l-3.56-2.166V469.671z M482.287,462.935l2.415,1.469v6.433
                  l-2.415-1.486V462.935z"/>
              </g>
            </g>
            <g>
              <polygon class="st67" points="497.448,476.041 499.817,477.499 499.817,471.743 497.448,470.301 					"/>
              <path class="st56" d="M497.161,476.201l2.942,1.811v-6.429l-2.942-1.79V476.201z M497.734,470.811l1.797,1.093v5.082
                l-1.797-1.106V470.811z"/>
            </g>
            <g>
              <polygon class="st67" points="473.593,462.293 475.963,463.751 475.963,457.995 473.593,456.554 					"/>
              <path class="st56" d="M473.307,462.453l2.942,1.811v-6.43l-2.942-1.79V462.453z M473.879,457.063l1.797,1.093v5.082
                l-1.797-1.106V457.063z"/>
            </g>
            <g>
              <polygon class="st157" points="511.414,450.418 512.922,451.269 514.4,450.42 512.905,449.557 					"/>
              <polygon class="st158" points="511.414,450.194 512.922,451.064 512.922,450.532 511.414,449.681 					"/>
              <polygon class="st159" points="512.922,451.064 514.4,450.166 514.4,449.684 512.922,450.532 					"/>
              <polygon class="st553" points="511.414,453.845 512.922,454.57 512.922,451.253 511.414,450.402 					"/>
              <polygon class="st554" points="512.922,454.57 514.4,454.791 514.4,450.404 512.922,451.253 					"/>
              <polygon class="st162" points="511.414,449.681 512.922,450.532 514.4,449.684 512.905,448.821 					"/>
            </g>
          </g>
          <g class="st96">
            <path class="st171" d="M514.34,458.16c-0.044,0.079-0.015,0.18,0.064,0.224l2.024,1.121l0.193-0.269l-2.057-1.14
              C514.484,458.052,514.384,458.08,514.34,458.16z"/>
            <path class="st171" d="M509.195,455.122c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L509.195,455.122z"/>
            <path class="st171" d="M503.827,452.148c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064l0,0c0.044-0.079,0.015-0.18-0.064-0.224L503.827,452.148z"/>
            <path class="st171" d="M498.573,449.237l-0.075,0.335l4.675,2.59c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L498.573,449.237z"/>
            <path class="st171" d="M492.867,446.264c-0.044,0.08-0.015,0.18,0.064,0.224l4.273,2.367l0.337-0.19l-4.45-2.465
              C493.012,446.156,492.911,446.185,492.867,446.264z"/>
            <path class="st171" d="M487.723,443.226c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L487.723,443.226z"/>
            <path class="st171" d="M482.355,440.252c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L482.355,440.252z"/>
            <path class="st171" d="M478.331,438.023l-0.206,0.262l3.576,1.981c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.08,0.015-0.18-0.064-0.224L478.331,438.023z"/>
            <path class="st171" d="M510.365,458.516c-0.044,0.08-0.015,0.18,0.064,0.224l4.687,2.597l0.193-0.269l-4.721-2.615
              C510.509,458.408,510.409,458.436,510.365,458.516z"/>
            <path class="st171" d="M505.221,455.478c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L505.221,455.478z"/>
            <path class="st171" d="M499.853,452.504c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L499.853,452.504z"/>
            <path class="st171" d="M494.261,449.594c-0.044,0.08-0.015,0.18,0.064,0.224l0.588,0.326l0.337-0.189l-0.765-0.424
              C494.405,449.486,494.305,449.515,494.261,449.594z"/>
            <path class="st171" d="M498.063,451.513l-0.075,0.335l1.21,0.671c0.08,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L498.063,451.513z"/>
            <path class="st171" d="M489.116,446.556c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L489.116,446.556z"/>
            <path class="st171" d="M483.748,443.582c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L483.748,443.582z"/>
            <path class="st171" d="M478.38,440.608c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L478.38,440.608z"/>
            <path class="st171" d="M476.928,439.804l-0.206,0.262l1.004,0.556c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L476.928,439.804z"/>
            <path class="st171" d="M512.171,462.074c-0.044,0.08-0.015,0.18,0.064,0.224l1.57,0.87l0.193-0.269l-1.604-0.888
              C512.316,461.966,512.215,461.995,512.171,462.074z"/>
            <path class="st171" d="M507.027,459.036c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L507.027,459.036z"/>
            <path class="st171" d="M501.659,456.062c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L501.659,456.062z"/>
            <path class="st171" d="M497.553,453.788l-0.075,0.335l3.527,1.954c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.08,0.015-0.18-0.064-0.224L497.553,453.788z"/>
            <path class="st171" d="M490.699,450.179c-0.044,0.08-0.015,0.18,0.064,0.224l1.858,1.029l0.337-0.19l-2.036-1.128
              C490.843,450.07,490.743,450.099,490.699,450.179z"/>
            <path class="st171" d="M485.554,447.141c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L485.554,447.141z"/>
            <path class="st171" d="M480.186,444.167c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L480.186,444.167z"/>
            <path class="st171" d="M475.526,441.585l-0.206,0.262l4.213,2.334c0.08,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L475.526,441.585z"/>
            <path class="st171" d="M508.197,462.43c-0.044,0.079-0.015,0.18,0.064,0.224l4.234,2.346l0.193-0.269l-4.268-2.364
              C508.341,462.322,508.241,462.351,508.197,462.43z"/>
            <path class="st171" d="M503.052,459.392c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L503.052,459.392z"/>
            <path class="st171" d="M497.684,456.418c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L497.684,456.418z"/>
            <path class="st171" d="M497.043,456.063l-0.075,0.335l0.063,0.035c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.08,0.015-0.18-0.064-0.224L497.043,456.063z"/>
            <path class="st171" d="M486.724,450.535c-0.044,0.079-0.015,0.18,0.064,0.224l3.542,1.962l0.337-0.189l-3.719-2.06
              C486.868,450.426,486.768,450.455,486.724,450.535z"/>
            <path class="st171" d="M481.58,447.497c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L481.58,447.497z"/>
            <path class="st171" d="M476.212,444.523c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L476.212,444.523z"/>
            <path class="st171" d="M474.123,443.365l-0.206,0.262l1.642,0.909c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224
              L474.123,443.365z"/>
            <path class="st171" d="M510.003,465.989c-0.044,0.079-0.015,0.18,0.064,0.224l1.117,0.619l0.193-0.27l-1.15-0.637
              C510.147,465.88,510.047,465.909,510.003,465.989z"/>
            <path class="st171" d="M504.858,462.951c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L504.858,462.951z"/>
            <path class="st171" d="M499.49,459.977c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.179-0.064-0.224L499.49,459.977z"/>
            <path class="st171" d="M496.533,458.338l-0.075,0.335l2.379,1.318c0.08,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L496.533,458.338z"/>
            <path class="st171" d="M483.386,451.055c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.812,2.666
              l0.292-0.164c-0.013-0.036-0.035-0.07-0.071-0.089L483.386,451.055z"/>
            <path class="st171" d="M478.018,448.081c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L478.018,448.081z"/>
            <path class="st171" d="M472.72,445.146l-0.206,0.262l4.85,2.687c0.08,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L472.72,445.146z"/>
            <path class="st171" d="M506.028,466.345c-0.044,0.079-0.015,0.18,0.064,0.224l3.781,2.095l0.193-0.27l-3.814-2.113
              C506.172,466.236,506.072,466.265,506.028,466.345z"/>
            <path class="st171" d="M500.884,463.306c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L500.884,463.306z"/>
            <path class="st171" d="M496.022,460.613l-0.075,0.335l4.283,2.372c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.08,0.015-0.18-0.064-0.224L496.022,460.613z"/>
            <path class="st171" d="M484.556,454.449c-0.044,0.08-0.015,0.18,0.064,0.224l1.127,0.625l0.337-0.189l-1.305-0.723
              C484.7,454.341,484.6,454.37,484.556,454.449z"/>
            <path class="st171" d="M479.411,451.411c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.016-0.18-0.064-0.224L479.411,451.411z"/>
            <path class="st171" d="M474.043,448.437c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L474.043,448.437z"/>
            <path class="st171" d="M471.317,446.927l-0.206,0.262l2.279,1.262c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L471.317,446.927z"/>
            <path class="st171" d="M507.834,469.903c-0.044,0.08-0.015,0.18,0.064,0.224l0.663,0.368l0.193-0.27l-0.697-0.386
              C507.978,469.795,507.878,469.824,507.834,469.903z"/>
            <path class="st171" d="M502.69,466.865c-0.079-0.044-0.179-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L502.69,466.865z"/>
            <path class="st171" d="M497.322,463.891c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L497.322,463.891z"/>
            <path class="st171" d="M495.512,462.889l-0.075,0.335l1.231,0.682c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L495.512,462.889z"/>
            <path class="st171" d="M480.994,455.034c-0.044,0.079-0.015,0.18,0.064,0.224l2.398,1.328l0.337-0.189l-2.575-1.427
              C481.138,454.925,481.038,454.954,480.994,455.034z"/>
            <path class="st171" d="M475.849,451.996c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064c0.044-0.08,0.015-0.18-0.064-0.224L475.849,451.996z"/>
            <path class="st171" d="M470.481,449.022c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.08-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L470.481,449.022z"/>
            <path class="st171" d="M503.86,470.259c-0.044,0.079-0.015,0.18,0.064,0.224l3.327,1.843l0.193-0.27l-3.361-1.862
              C504.004,470.151,503.904,470.179,503.86,470.259z"/>
            <path class="st171" d="M498.715,467.221c-0.079-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.08,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224L498.715,467.221z"/>
            <path class="st171" d="M495.002,465.164l-0.075,0.335l3.134,1.736c0.079,0.044,0.18,0.015,0.224-0.064
              c0.044-0.079,0.015-0.18-0.064-0.224L495.002,465.164z"/>
            <path class="st171" d="M477.019,455.389c-0.044,0.08-0.015,0.18,0.064,0.224l4.081,2.261l0.337-0.19l-4.259-2.359
              C477.163,455.281,477.063,455.31,477.019,455.389z"/>
            <path class="st171" d="M471.875,452.351c-0.08-0.044-0.18-0.015-0.224,0.064c-0.044,0.079-0.015,0.18,0.064,0.224l4.874,2.7
              c0.079,0.044,0.18,0.015,0.224-0.064c0.044-0.079,0.015-0.18-0.064-0.224L471.875,452.351z"/>
            <path class="st171" d="M468.511,450.488l-0.206,0.262l2.916,1.615c0.079,0.044,0.18,0.015,0.224-0.064s0.015-0.18-0.064-0.224
              L468.511,450.488z"/>
          </g>
        </g>
        <polygon class="st551" points="506.123,473.902 507.406,473.266 517.677,459.123 528.372,461.471 529.231,461.014 
          517.382,458.172 		"/>
        <linearGradient id="SVGID_136_" gradientUnits="userSpaceOnUse" x1="463.8865" y1="484.7309" x2="477.8821" y2="484.7309">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st561" points="465.889,482.762 465.889,485.691 463.887,486.7 477.882,486.7 471.017,482.762 		"/>
        <linearGradient id="SVGID_137_" gradientUnits="userSpaceOnUse" x1="497.8713" y1="504.8656" x2="514.6334" y2="504.8656">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.6652" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st562" points="499.873,505.7 497.871,506.865 510.484,506.711 514.633,502.867 499.873,502.867 		"/>
        <linearGradient id="SVGID_138_" gradientUnits="userSpaceOnUse" x1="505.3478" y1="492.3767" x2="532.7589" y2="492.3767">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="0.8427" style="stop-color:#FFFFFF"/>
        </linearGradient>
        <polygon class="st563" points="527.799,489.118 505.348,502.407 519.031,502.305 532.759,485.2 527.799,482.346 		"/>
      </g>
      <g>
        <g>
          <g>
            <g>
              <polygon class="st79" points="354.451,456.788 354.418,478.058 372.416,488.449 372.742,464.77 362.995,451.786 					"/>
              <polygon class="st156" points="360.117,480.982 364.688,483.682 364.777,472.941 360.182,470.46 					"/>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="365.308,463.255 365.357,467.879 367.599,469.166 367.578,464.524 								"/>
                    <path class="st56" d="M365.117,462.905l0.057,5.106l2.616,1.503l-0.021-5.129L365.117,462.905z M365.54,467.746
                      l-0.042-4.141l1.89,1.058l0.021,4.155L365.54,467.746z"/>
                  </g>
                  <polygon class="st169" points="364.777,462.406 364.784,462.951 368.111,464.81 368.11,464.262 							"/>
                  <polygon class="st169" points="364.839,467.555 364.845,468.085 368.128,469.974 368.126,469.441 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="367.66,469.201 369.937,470.508 369.946,465.847 367.64,464.558 								"/>
                    <path class="st56" d="M367.473,469.332l2.656,1.526l0.012-5.15l-2.693-1.503L367.473,469.332z M369.752,465.985
                      l-0.005,4.172l-1.898-1.088l-0.016-4.158L369.752,465.985z"/>
                  </g>
                  <polygon class="st169" points="367.108,464.25 370.488,466.138 370.489,465.588 367.105,463.703 							"/>
                  <polygon class="st169" points="367.138,469.405 370.47,471.323 370.472,470.788 367.135,468.873 							"/>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="356.858,458.498 356.907,463.122 359.15,464.409 359.129,459.767 								"/>
                    <path class="st56" d="M356.667,458.148l0.057,5.106l2.616,1.503l-0.021-5.129L356.667,458.148z M357.091,462.99
                      l-0.042-4.141l1.89,1.058l0.021,4.155L357.091,462.99z"/>
                  </g>
                  <polygon class="st169" points="356.328,457.649 356.334,458.194 359.662,460.053 359.66,459.506 							"/>
                  <polygon class="st169" points="356.39,462.798 356.396,463.328 359.678,465.217 359.676,464.685 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="359.211,464.444 361.488,465.751 361.496,461.09 359.19,459.802 								"/>
                    <path class="st56" d="M359.023,464.575l2.655,1.526l0.012-5.15l-2.693-1.503L359.023,464.575z M361.302,461.229
                      l-0.005,4.172l-1.898-1.088l-0.016-4.158L361.302,461.229z"/>
                  </g>
                  <polygon class="st169" points="358.659,459.493 362.038,461.381 362.04,460.831 358.656,458.946 							"/>
                  <polygon class="st169" points="358.689,464.648 362.021,466.566 362.023,466.031 358.686,464.116 							"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="367.152,481.148 369.878,482.722 369.942,477.062 367.195,475.449 							"/>
                  <path class="st56" d="M366.953,481.33l3.117,1.798l0.072-6.249l-3.143-1.847L366.953,481.33z M367.39,475.865l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L367.39,475.865z"/>
                </g>
                <polygon class="st169" points="366.592,475.081 370.538,477.398 370.546,476.735 366.597,474.412 						"/>
                <polygon class="st169" points="366.55,481.427 370.462,483.682 370.47,483.03 366.554,480.769 						"/>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="356.025,474.742 358.752,476.317 358.815,470.657 356.068,469.044 							"/>
                  <path class="st56" d="M355.826,474.924l3.117,1.798l0.072-6.249l-3.143-1.847L355.826,474.924z M356.264,469.46l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L356.264,469.46z"/>
                </g>
                <polygon class="st169" points="355.466,468.676 359.411,470.992 359.419,470.33 355.47,468.007 						"/>
                <polygon class="st169" points="355.423,475.022 359.335,477.277 359.343,476.625 355.428,474.364 						"/>
              </g>
              <polygon class="st171" points="360.711,475.133 364.183,477.007 364.212,473.467 360.734,471.647 					"/>
              <polygon class="st171" points="360.682,480.477 364.147,482.418 364.176,477.929 360.705,476.042 					"/>
              <polygon class="st564" points="358.326,480.813 364.801,484.519 365.72,483.928 359.313,480.229 					"/>
              <g>
                <polygon class="st565" points="358.326,470.07 364.801,473.776 365.695,473.185 359.289,469.486 						"/>
                <polygon class="st169" points="358.326,470.601 364.825,474.324 364.835,473.776 358.327,470.085 						"/>
                <polygon class="st566" points="364.83,474.309 365.695,473.759 365.695,473.185 364.835,473.776 						"/>
                <polygon class="st567" points="358.326,481.343 364.825,485.067 364.835,484.519 358.327,480.828 						"/>
                <polygon class="st566" points="364.83,485.052 365.695,484.502 365.695,483.928 364.835,484.519 						"/>
              </g>
              <path class="st170" d="M364.272,477.005c-0.178,0-0.322,0.19-0.322,0.425c0,0.235,0.144,0.425,0.322,0.425
                c0.178,0,0.322-0.19,0.322-0.425C364.594,477.196,364.45,477.005,364.272,477.005z"/>
            </g>
            <polygon class="st80" points="372.416,488.449 394.719,475.449 394.719,453.517 372.742,464.77 				"/>
            <path class="st568" d="M352.054,456.78c0,0,0.451,1.038,1.307,0.589l9.495-5.336l11.3,14.576
              c1.144,1.48,1.785,0.783,1.785,0.783l-13.008-16.729L352.054,456.78z"/>
            <polygon class="st156" points="362.933,450.664 375.941,467.393 396.897,455.598 383.889,438.841 				"/>
            <g>
              <g>
                <g>
                  <polygon class="st67" points="376.681,475.852 376.745,481.511 382.23,478.322 382.187,472.623 							"/>
                  <path class="st56" d="M379.625,473.993l-3.143,1.802v6.122l5.926-3.368l-0.045-6.165L379.625,473.993z M376.937,481.138
                    l-0.056-5.103l2.343-1.354l0.027,2.307l0.022,2.801L376.937,481.138z M379.673,479.581l-0.021-1.942l-0.023-3.169
                    l2.341-1.352l0.04,5.098L379.673,479.581z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st67" points="388.595,468.959 388.659,474.619 391.385,473.045 391.342,467.346 						"/>
                <path class="st56" d="M388.395,468.776l0.072,6.249l3.117-1.798l-0.046-6.298L388.395,468.776z M388.85,474.213l-0.056-5.07
                  l2.352-1.38l0.04,5.1L388.85,474.213z"/>
              </g>
              <polygon class="st169" points="387.991,468.632 387.999,469.295 391.945,466.978 391.94,466.309 					"/>
              <polygon class="st169" points="388.067,474.928 388.075,475.579 391.987,473.325 391.983,472.667 					"/>
            </g>
            <polygon class="st169" points="375.941,475.568 375.949,476.23 382.949,472.098 382.944,471.429 				"/>
            <polygon class="st169" points="376.017,481.863 376.025,482.515 383.092,478.57 383.087,477.913 				"/>
          </g>
          <g>
            <path class="st171" d="M365.653,451.554l-1.406,0.799l0.227,0.293l1.359-0.773c0.088-0.05,0.119-0.162,0.069-0.25
              C365.852,451.535,365.741,451.504,365.653,451.554z"/>
            <path class="st171" d="M368.967,449.67l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C369.167,449.65,369.055,449.62,368.967,449.67z"/>
            <path class="st171" d="M372.282,447.785l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C372.482,447.766,372.37,447.735,372.282,447.785z"/>
            <path class="st171" d="M375.596,445.901l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C375.796,445.882,375.684,445.851,375.596,445.901z"/>
            <path class="st171" d="M378.911,444.017l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C379.111,443.998,378.999,443.967,378.911,444.017z"/>
            <path class="st171" d="M382.225,442.133l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C382.425,442.113,382.314,442.083,382.225,442.133z"/>
            <path class="st171" d="M382.672,441.879c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.526-1.436
              l-0.227-0.293L382.672,441.879z"/>
            <path class="st171" d="M368.669,452.706l-2.868,1.63c-0.003,0.002-0.004,0.004-0.007,0.006l0.226,0.291l2.83-1.609
              c0.088-0.05,0.119-0.162,0.069-0.25C368.869,452.687,368.757,452.656,368.669,452.706z"/>
            <path class="st171" d="M371.983,450.822l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C372.183,450.802,372.071,450.771,371.983,450.822z"/>
            <path class="st171" d="M375.298,448.937l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C375.498,448.918,375.386,448.887,375.298,448.937z"/>
            <path class="st171" d="M378.613,447.053l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C378.813,447.034,378.701,447.003,378.613,447.053z"/>
            <path class="st171" d="M381.927,445.169l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C382.127,445.15,382.015,445.119,381.927,445.169z"/>
            <path class="st171" d="M385.242,443.285l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C385.442,443.265,385.33,443.235,385.242,443.285z"/>
            <path class="st171" d="M385.688,443.031c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.054-0.599
              l-0.227-0.293L385.688,443.031z"/>
            <path class="st171" d="M368.116,455.887l-0.777,0.442l0.227,0.293l0.731-0.416c0.088-0.05,0.119-0.162,0.069-0.25
              C368.315,455.868,368.204,455.837,368.116,455.887z"/>
            <path class="st171" d="M371.43,454.003l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C371.63,453.983,371.518,453.953,371.43,454.003z"/>
            <path class="st171" d="M374.745,452.118l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C374.945,452.099,374.833,452.068,374.745,452.118z"/>
            <path class="st171" d="M378.059,450.234l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C378.259,450.215,378.147,450.184,378.059,450.234z"/>
            <path class="st171" d="M381.374,448.35l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C381.574,448.331,381.462,448.3,381.374,448.35z"/>
            <path class="st171" d="M384.689,446.466l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C384.889,446.446,384.777,446.416,384.689,446.466z"/>
            <path class="st171" d="M388.003,444.581l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C388.203,444.562,388.091,444.531,388.003,444.581z"/>
            <path class="st171" d="M371.132,457.039l-2.248,1.278l0.228,0.292l2.202-1.252c0.088-0.05,0.119-0.162,0.069-0.25
              C371.332,457.019,371.22,456.989,371.132,457.039z"/>
            <path class="st171" d="M374.446,455.154l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C374.646,455.135,374.534,455.104,374.446,455.154z"/>
            <path class="st171" d="M377.761,453.27l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.869-1.631c0.088-0.05,0.119-0.162,0.069-0.25C377.961,453.251,377.849,453.22,377.761,453.27z"/>
            <path class="st171" d="M381.076,451.386l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C381.276,451.367,381.164,451.336,381.076,451.386z"/>
            <path class="st171" d="M384.39,449.502l-2.869,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C384.59,449.482,384.478,449.452,384.39,449.502z"/>
            <path class="st171" d="M387.705,447.617l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C387.905,447.598,387.793,447.567,387.705,447.617z"/>
            <path class="st171" d="M388.151,447.364c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.679-0.954
              l-0.227-0.293L388.151,447.364z"/>
            <path class="st171" d="M370.579,460.22l-0.149,0.085l0.227,0.292l0.103-0.059c0.088-0.05,0.119-0.162,0.069-0.25
              C370.779,460.2,370.667,460.17,370.579,460.22z"/>
            <path class="st171" d="M373.893,458.335l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C374.093,458.316,373.981,458.285,373.893,458.335z"/>
            <path class="st171" d="M377.208,456.451l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C377.408,456.432,377.296,456.401,377.208,456.451z"/>
            <path class="st171" d="M380.522,454.567l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C380.722,454.548,380.61,454.517,380.522,454.567z"/>
            <path class="st171" d="M383.837,452.683l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C384.037,452.663,383.925,452.633,383.837,452.683z"/>
            <path class="st171" d="M387.152,450.798l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C387.352,450.779,387.24,450.748,387.152,450.798z"/>
            <path class="st171" d="M390.466,448.914l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C390.666,448.895,390.554,448.864,390.466,448.914z"/>
            <path class="st171" d="M390.912,448.66c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l0.461-0.262
              l-0.227-0.293L390.912,448.66z"/>
            <path class="st171" d="M373.595,461.371l-1.62,0.921l0.228,0.293l1.573-0.895c0.088-0.05,0.119-0.162,0.069-0.25
              C373.795,461.352,373.683,461.321,373.595,461.371z"/>
            <path class="st171" d="M376.91,459.487l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C377.11,459.468,376.998,459.437,376.91,459.487z"/>
            <path class="st171" d="M380.224,457.603l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C380.424,457.584,380.312,457.553,380.224,457.603z"/>
            <path class="st171" d="M383.789,455.787c-0.05-0.088-0.162-0.119-0.25-0.069l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25
              c0.05,0.088,0.162,0.119,0.25,0.069l2.868-1.631C383.808,455.987,383.839,455.875,383.789,455.787L383.789,455.787z"/>
            <path class="st171" d="M386.853,453.834l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C387.053,453.815,386.941,453.784,386.853,453.834z"/>
            <path class="st171" d="M390.168,451.95l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C390.368,451.931,390.256,451.9,390.168,451.95z"/>
            <path class="st171" d="M390.614,451.697c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.303-1.309
              l-0.227-0.293L390.614,451.697z"/>
            <path class="st171" d="M376.356,462.668l-2.836,1.612l0.227,0.293l2.789-1.586c0.088-0.05,0.119-0.162,0.069-0.25
              C376.556,462.649,376.444,462.618,376.356,462.668z"/>
            <path class="st171" d="M379.671,460.784l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C379.871,460.765,379.759,460.734,379.671,460.784z"/>
            <path class="st171" d="M382.986,458.9l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C383.186,458.88,383.074,458.85,382.986,458.9z"/>
            <path class="st171" d="M386.3,457.015l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C386.5,456.996,386.388,456.965,386.3,457.015z"/>
            <path class="st171" d="M389.615,455.131l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C389.815,455.112,389.703,455.081,389.615,455.131z"/>
            <path class="st171" d="M392.929,453.247l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C393.129,453.228,393.017,453.197,392.929,453.247z"/>
            <path class="st171" d="M393.376,452.993c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.086-0.617
              l-0.227-0.293L393.376,452.993z"/>
            <path class="st171" d="M376.058,465.704l-0.991,0.564l0.227,0.293l0.945-0.537c0.088-0.05,0.119-0.162,0.069-0.25
              C376.258,465.685,376.146,465.654,376.058,465.704z"/>
            <path class="st171" d="M379.373,463.82l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C379.573,463.801,379.461,463.77,379.373,463.82z"/>
            <path class="st171" d="M382.687,461.936l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C382.887,461.917,382.775,461.886,382.687,461.936z"/>
            <path class="st171" d="M386.002,460.052l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C386.202,460.032,386.09,460.001,386.002,460.052z"/>
            <path class="st171" d="M389.316,458.167l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C389.516,458.148,389.404,458.117,389.316,458.167z"/>
            <path class="st171" d="M392.631,456.283l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C392.831,456.264,392.719,456.233,392.631,456.283z"/>
            <path class="st171" d="M395.946,454.399l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.021-0.012,0.038-0.027,0.052-0.045l-0.217-0.279C395.956,454.396,395.951,454.396,395.946,454.399z"/>
          </g>
          <g>
            <polygon class="st157" points="382.505,444.03 384.382,445.088 386.221,444.033 384.36,442.958 				"/>
            <polygon class="st158" points="382.505,443.751 384.382,444.834 384.382,444.172 382.505,443.113 				"/>
            <polygon class="st159" points="384.382,444.834 386.221,443.716 386.221,443.116 384.382,444.172 				"/>
            <polygon class="st569" points="382.505,448.294 384.382,450.567 384.382,445.069 382.505,444.01 				"/>
            <polygon class="st570" points="384.382,450.567 386.221,449.449 386.221,444.013 384.382,445.069 				"/>
            <polygon class="st162" points="382.505,443.113 384.382,444.172 386.221,443.116 384.36,442.042 				"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon class="st571" points="386.968,475.458 386.935,496.728 404.932,507.119 405.259,483.439 395.512,470.456 					"/>
              <polygon class="st156" points="392.633,499.651 397.204,502.352 397.294,491.611 392.699,489.13 					"/>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="397.824,481.925 397.873,486.548 400.116,487.836 400.095,483.194 								"/>
                    <path class="st56" d="M397.633,481.575l0.057,5.106l2.616,1.503l-0.021-5.129L397.633,481.575z M398.057,486.416
                      l-0.042-4.141l1.89,1.058l0.021,4.155L398.057,486.416z"/>
                  </g>
                  <polygon class="st169" points="397.294,481.076 397.3,481.62 400.628,483.48 400.626,482.932 							"/>
                  <polygon class="st169" points="397.356,486.225 397.362,486.755 400.644,488.644 400.643,488.111 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="400.177,487.871 402.454,489.178 402.463,484.517 400.157,483.228 								"/>
                    <path class="st56" d="M399.99,488.002l2.656,1.526l0.012-5.15l-2.693-1.503L399.99,488.002z M402.268,484.655l-0.005,4.172
                      l-1.898-1.088l-0.016-4.158L402.268,484.655z"/>
                  </g>
                  <polygon class="st169" points="399.625,482.92 403.004,484.808 403.006,484.258 399.622,482.373 							"/>
                  <polygon class="st169" points="399.655,488.075 402.987,489.993 402.989,489.458 399.652,487.543 							"/>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="389.375,477.168 389.424,481.792 391.666,483.079 391.645,478.437 								"/>
                    <path class="st56" d="M389.184,476.818l0.057,5.106l2.616,1.503l-0.021-5.129L389.184,476.818z M389.607,481.659
                      l-0.042-4.141l1.89,1.058l0.021,4.155L389.607,481.659z"/>
                  </g>
                  <polygon class="st169" points="388.844,476.319 388.851,476.864 392.179,478.723 392.177,478.175 							"/>
                  <polygon class="st169" points="388.906,481.468 388.913,481.998 392.195,483.887 392.193,483.355 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="391.727,483.114 394.005,484.421 394.013,479.76 391.707,478.472 								"/>
                    <path class="st56" d="M391.54,483.245l2.655,1.526l0.012-5.15l-2.693-1.503L391.54,483.245z M393.819,479.898l-0.005,4.172
                      l-1.898-1.088l-0.016-4.158L393.819,479.898z"/>
                  </g>
                  <polygon class="st169" points="391.175,478.163 394.554,480.051 394.556,479.501 391.172,477.616 							"/>
                  <polygon class="st169" points="391.205,483.318 394.538,485.236 394.539,484.701 391.202,482.786 							"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="399.668,499.818 402.395,501.392 402.459,495.732 399.711,494.119 							"/>
                  <path class="st56" d="M399.47,500l3.117,1.798l0.072-6.249l-3.143-1.847L399.47,500z M399.907,494.535l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L399.907,494.535z"/>
                </g>
                <polygon class="st169" points="399.109,493.751 403.054,496.068 403.062,495.405 399.114,493.082 						"/>
                <polygon class="st169" points="399.067,500.097 402.979,502.352 402.986,501.7 399.071,499.439 						"/>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="388.542,493.412 391.269,494.986 391.332,489.327 388.585,487.714 							"/>
                  <path class="st56" d="M388.343,493.594l3.117,1.798l0.072-6.249l-3.143-1.847L388.343,493.594z M388.781,488.13l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L388.781,488.13z"/>
                </g>
                <polygon class="st169" points="387.982,487.346 391.928,489.662 391.936,489 387.987,486.676 						"/>
                <polygon class="st169" points="387.94,493.692 391.852,495.946 391.86,495.295 387.944,493.034 						"/>
              </g>
              <polygon class="st171" points="393.227,493.803 396.7,495.676 396.728,492.137 393.25,490.317 					"/>
              <polygon class="st171" points="393.199,499.147 396.664,501.088 396.692,496.599 393.222,494.712 					"/>
              <polygon class="st564" points="390.842,499.483 397.318,503.189 398.236,502.598 391.829,498.898 					"/>
              <g>
                <polygon class="st565" points="390.842,488.74 397.318,492.446 398.212,491.855 391.805,488.156 						"/>
                <polygon class="st169" points="390.842,489.27 397.341,492.994 397.352,492.446 390.844,488.755 						"/>
                <polygon class="st566" points="397.346,492.979 398.212,492.429 398.212,491.855 397.352,492.446 						"/>
                <polygon class="st567" points="390.842,500.013 397.341,503.737 397.352,503.189 390.844,499.498 						"/>
                <polygon class="st566" points="397.346,503.722 398.212,503.171 398.212,502.598 397.352,503.189 						"/>
              </g>
              <path class="st170" d="M396.789,495.675c-0.178,0-0.322,0.19-0.322,0.425c0,0.235,0.144,0.425,0.322,0.425
                c0.178,0,0.322-0.19,0.322-0.425C397.111,495.866,396.967,495.675,396.789,495.675z"/>
            </g>
            <polygon class="st572" points="404.932,507.119 427.236,494.119 427.236,472.187 405.259,483.439 				"/>
            <path class="st568" d="M384.571,475.45c0,0,0.452,1.038,1.307,0.589l9.495-5.336l11.3,14.576
              c1.144,1.48,1.785,0.783,1.785,0.783l-13.008-16.729L384.571,475.45z"/>
            <polygon class="st156" points="395.45,469.333 408.458,486.063 429.413,474.268 416.405,457.511 				"/>
            <g>
              <g>
                <g>
                  <polygon class="st67" points="409.198,494.521 409.262,500.181 414.747,496.992 414.704,491.293 							"/>
                  <path class="st56" d="M412.142,492.663l-3.143,1.802v6.122l5.925-3.368l-0.045-6.165L412.142,492.663z M409.454,499.807
                    l-0.056-5.103l2.344-1.354l0.026,2.307l0.022,2.801L409.454,499.807z M412.19,498.251l-0.021-1.942l-0.023-3.169l2.341-1.352
                    l0.04,5.098L412.19,498.251z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st67" points="421.112,487.629 421.175,493.289 423.902,491.715 423.859,486.016 						"/>
                <path class="st56" d="M420.912,487.446l0.072,6.249l3.117-1.798l-0.046-6.298L420.912,487.446z M421.367,492.882l-0.055-5.07
                  l2.352-1.38l0.04,5.1L421.367,492.882z"/>
              </g>
              <polygon class="st169" points="420.508,487.302 420.516,487.965 424.461,485.648 424.457,484.979 					"/>
              <polygon class="st169" points="420.584,493.597 420.592,494.249 424.504,491.994 424.499,491.337 					"/>
            </g>
            <polygon class="st169" points="408.458,494.238 408.466,494.9 415.465,490.768 415.461,490.099 				"/>
            <polygon class="st169" points="408.534,500.533 408.542,501.184 415.608,497.24 415.604,496.582 				"/>
          </g>
          <g>
            <path class="st171" d="M398.169,470.224l-1.406,0.799l0.228,0.292l1.359-0.773c0.088-0.05,0.119-0.162,0.069-0.25
              C398.369,470.205,398.257,470.174,398.169,470.224z"/>
            <path class="st171" d="M401.484,468.34l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C401.684,468.32,401.572,468.29,401.484,468.34z"/>
            <path class="st171" d="M404.798,466.455l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C404.998,466.436,404.886,466.405,404.798,466.455z"/>
            <path class="st171" d="M408.113,464.571l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C408.313,464.552,408.201,464.521,408.113,464.571z"/>
            <path class="st171" d="M411.428,462.687l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C411.627,462.668,411.516,462.637,411.428,462.687z"/>
            <path class="st171" d="M414.742,460.803l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C414.942,460.783,414.83,460.753,414.742,460.803z"/>
            <path class="st171" d="M415.188,460.549c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.526-1.436
              l-0.227-0.293L415.188,460.549z"/>
            <path class="st171" d="M401.186,471.376l-2.868,1.631c-0.003,0.002-0.004,0.004-0.007,0.006l0.226,0.291l2.83-1.609
              c0.088-0.05,0.119-0.162,0.069-0.25C401.385,471.356,401.274,471.326,401.186,471.376z"/>
            <path class="st171" d="M404.5,469.491l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C404.7,469.472,404.588,469.441,404.5,469.491z"/>
            <path class="st171" d="M407.815,467.607l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C408.015,467.588,407.903,467.557,407.815,467.607z"/>
            <path class="st171" d="M411.129,465.723l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25l0,0
              c0.05,0.088,0.162,0.119,0.25,0.069l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25
              C411.329,465.704,411.217,465.673,411.129,465.723z"/>
            <path class="st171" d="M414.444,463.839l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C414.644,463.819,414.532,463.789,414.444,463.839z"/>
            <path class="st171" d="M417.758,461.954l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C417.958,461.935,417.847,461.904,417.758,461.954z"/>
            <path class="st171" d="M418.205,461.701c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.054-0.599
              l-0.227-0.293L418.205,461.701z"/>
            <path class="st171" d="M400.632,474.557l-0.777,0.442l0.227,0.293l0.731-0.416c0.088-0.05,0.119-0.162,0.069-0.25
              C400.832,474.537,400.72,474.507,400.632,474.557z"/>
            <path class="st171" d="M403.947,472.672l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C404.147,472.653,404.035,472.622,403.947,472.672z"/>
            <path class="st171" d="M407.262,470.788l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C407.461,470.769,407.349,470.738,407.262,470.788z"/>
            <path class="st171" d="M410.576,468.904l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C410.776,468.885,410.664,468.854,410.576,468.904z"/>
            <path class="st171" d="M413.891,467.02l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C414.091,467,413.979,466.97,413.891,467.02z"/>
            <path class="st171" d="M417.205,465.135l-2.869,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C417.405,465.116,417.293,465.085,417.205,465.135z"/>
            <path class="st171" d="M420.52,463.251l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C420.72,463.232,420.608,463.201,420.52,463.251z"/>
            <path class="st171" d="M403.649,475.708l-2.248,1.278l0.228,0.292l2.202-1.252c0.088-0.05,0.119-0.162,0.069-0.25
              C403.849,475.689,403.737,475.658,403.649,475.708z"/>
            <path class="st171" d="M406.963,473.824l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C407.163,473.805,407.051,473.774,406.963,473.824z"/>
            <path class="st171" d="M410.278,471.94l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C410.478,471.921,410.366,471.89,410.278,471.94z"/>
            <path class="st171" d="M413.592,470.056l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C413.792,470.036,413.68,470.006,413.592,470.056z"/>
            <path class="st171" d="M416.907,468.171l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C417.107,468.152,416.995,468.121,416.907,468.171z"/>
            <path class="st171" d="M420.222,466.287l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C420.422,466.268,420.31,466.237,420.222,466.287z"/>
            <path class="st171" d="M420.668,466.034c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.679-0.954
              l-0.227-0.293L420.668,466.034z"/>
            <path class="st171" d="M403.095,478.89l-0.149,0.085l0.227,0.292l0.103-0.059c0.088-0.05,0.119-0.162,0.069-0.25
              C403.295,478.87,403.183,478.839,403.095,478.89z"/>
            <path class="st171" d="M406.41,477.005l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C406.61,476.986,406.498,476.955,406.41,477.005z"/>
            <path class="st171" d="M409.724,475.121l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C409.924,475.102,409.813,475.071,409.724,475.121z"/>
            <path class="st171" d="M413.039,473.237l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C413.239,473.217,413.127,473.187,413.039,473.237z"/>
            <path class="st171" d="M416.354,471.353l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C416.554,471.333,416.442,471.302,416.354,471.353z"/>
            <path class="st171" d="M419.668,469.468l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C419.868,469.449,419.756,469.418,419.668,469.468z"/>
            <path class="st171" d="M422.983,467.584l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C423.183,467.565,423.071,467.534,422.983,467.584z"/>
            <path class="st171" d="M423.429,467.33c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l0.461-0.262
              l-0.227-0.293L423.429,467.33z"/>
            <path class="st171" d="M406.112,480.041l-1.62,0.921l0.227,0.293l1.574-0.895c0.088-0.05,0.119-0.162,0.069-0.25
              C406.312,480.022,406.2,479.991,406.112,480.041z"/>
            <path class="st171" d="M409.426,478.157l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C409.626,478.138,409.514,478.107,409.426,478.157z"/>
            <path class="st171" d="M412.741,476.273l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C412.941,476.253,412.829,476.223,412.741,476.273z"/>
            <path class="st171" d="M416.056,474.389l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C416.255,474.369,416.144,474.339,416.056,474.389z"/>
            <path class="st171" d="M419.37,472.504l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C419.57,472.485,419.458,472.454,419.37,472.504z"/>
            <path class="st171" d="M422.685,470.62l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C422.885,470.601,422.773,470.57,422.685,470.62z"/>
            <path class="st171" d="M423.131,470.366c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.304-1.31
              l-0.227-0.293L423.131,470.366z"/>
            <path class="st171" d="M408.873,481.338l-2.835,1.612l0.227,0.292l2.789-1.586c0.088-0.05,0.119-0.162,0.069-0.25
              C409.073,481.319,408.961,481.288,408.873,481.338z"/>
            <path class="st171" d="M412.188,479.454l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C412.388,479.435,412.276,479.404,412.188,479.454z"/>
            <path class="st171" d="M415.502,477.57l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C415.702,477.55,415.59,477.52,415.502,477.57z"/>
            <path class="st171" d="M418.817,475.685l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C419.017,475.666,418.905,475.635,418.817,475.685z"/>
            <path class="st171" d="M422.131,473.801l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C422.331,473.782,422.219,473.751,422.131,473.801z"/>
            <path class="st171" d="M425.446,471.917l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C425.646,471.898,425.534,471.867,425.446,471.917z"/>
            <path class="st171" d="M425.892,471.663c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.086-0.617
              l-0.227-0.293L425.892,471.663z"/>
            <path class="st171" d="M408.575,484.374l-0.992,0.564l0.227,0.293l0.945-0.538c0.088-0.05,0.119-0.162,0.069-0.25
              C408.775,484.355,408.663,484.324,408.575,484.374z"/>
            <path class="st171" d="M411.889,482.49l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C412.089,482.471,411.977,482.44,411.889,482.49z"/>
            <path class="st171" d="M415.204,480.606l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C415.404,480.586,415.292,480.556,415.204,480.606z"/>
            <path class="st171" d="M418.519,478.721l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C418.718,478.702,418.607,478.671,418.519,478.721z"/>
            <path class="st171" d="M421.833,476.837l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C422.033,476.818,421.921,476.787,421.833,476.837z"/>
            <path class="st171" d="M425.148,474.953l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C425.348,474.934,425.236,474.903,425.148,474.953z"/>
            <path class="st171" d="M428.462,473.069l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.021-0.012,0.038-0.027,0.052-0.045l-0.217-0.279C428.473,473.066,428.467,473.066,428.462,473.069z"/>
          </g>
          <g>
            <polygon class="st157" points="415.022,462.699 416.898,463.758 418.737,462.702 416.877,461.628 				"/>
            <polygon class="st158" points="415.022,462.421 416.898,463.504 416.898,462.842 415.022,461.783 				"/>
            <polygon class="st159" points="416.898,463.504 418.737,462.386 418.737,461.786 416.898,462.842 				"/>
            <polygon class="st509" points="415.022,466.964 416.898,469.237 416.898,463.738 415.022,462.68 				"/>
            <polygon class="st508" points="416.898,469.237 418.737,468.118 418.737,462.682 416.898,463.738 				"/>
            <polygon class="st162" points="415.022,461.783 416.898,462.842 418.737,461.786 416.877,460.712 				"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon class="st573" points="418.967,494.23 418.934,515.501 436.931,525.892 437.258,502.212 427.511,489.229 					"/>
              <polygon class="st156" points="424.632,518.424 429.203,521.124 429.293,510.384 424.698,507.903 					"/>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="429.823,500.697 429.872,505.321 432.115,506.608 432.094,501.967 								"/>
                    <path class="st56" d="M429.632,500.347l0.057,5.106l2.616,1.503l-0.021-5.129L429.632,500.347z M430.056,505.189
                      l-0.042-4.141l1.89,1.058l0.021,4.155L430.056,505.189z"/>
                  </g>
                  <polygon class="st169" points="429.293,499.849 429.299,500.393 432.627,502.253 432.625,501.705 							"/>
                  <polygon class="st169" points="429.355,504.998 429.361,505.528 432.643,507.417 432.642,506.884 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="432.176,506.644 434.453,507.95 434.462,503.29 432.156,502.001 								"/>
                    <path class="st56" d="M431.989,506.775l2.656,1.526l0.012-5.15l-2.693-1.503L431.989,506.775z M434.267,503.428
                      l-0.005,4.172l-1.898-1.088l-0.016-4.158L434.267,503.428z"/>
                  </g>
                  <polygon class="st169" points="431.624,501.692 435.003,503.581 435.005,503.031 431.621,501.145 							"/>
                  <polygon class="st169" points="431.654,506.847 434.986,508.765 434.988,508.231 431.651,506.316 							"/>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <polygon class="st67" points="421.374,495.941 421.423,500.564 423.666,501.852 423.644,497.21 								"/>
                    <path class="st56" d="M421.183,495.591l0.057,5.106l2.616,1.503l-0.021-5.129L421.183,495.591z M421.606,500.432
                      l-0.042-4.141l1.89,1.058l0.021,4.155L421.606,500.432z"/>
                  </g>
                  <polygon class="st169" points="420.843,495.092 420.85,495.637 424.178,497.496 424.176,496.948 							"/>
                  <polygon class="st169" points="420.905,500.241 420.911,500.771 424.194,502.66 424.192,502.127 							"/>
                </g>
                <g>
                  <g>
                    <polygon class="st67" points="423.727,501.887 426.003,503.194 426.012,498.533 423.706,497.244 								"/>
                    <path class="st56" d="M423.539,502.018l2.656,1.526l0.012-5.15l-2.693-1.503L423.539,502.018z M425.818,498.671
                      l-0.005,4.172l-1.898-1.088l-0.016-4.158L425.818,498.671z"/>
                  </g>
                  <polygon class="st169" points="423.174,496.936 426.554,498.824 426.555,498.274 423.171,496.389 							"/>
                  <polygon class="st169" points="423.204,502.091 426.537,504.009 426.538,503.474 423.201,501.559 							"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="431.667,518.59 434.394,520.164 434.458,514.505 431.71,512.892 							"/>
                  <path class="st56" d="M431.469,518.772l3.117,1.798l0.072-6.249l-3.143-1.847L431.469,518.772z M431.906,513.308l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L431.906,513.308z"/>
                </g>
                <polygon class="st169" points="431.108,512.524 435.053,514.84 435.061,514.178 431.113,511.854 						"/>
                <polygon class="st169" points="431.066,518.87 434.978,521.124 434.985,520.473 431.07,518.212 						"/>
              </g>
              <g>
                <g>
                  <polygon class="st67" points="420.541,512.185 423.267,513.759 423.331,508.1 420.584,506.487 							"/>
                  <path class="st56" d="M420.342,512.367l3.117,1.798l0.072-6.249l-3.143-1.847L420.342,512.367z M420.78,506.903l2.352,1.38
                    l-0.056,5.07l-2.336-1.35L420.78,506.903z"/>
                </g>
                <polygon class="st169" points="419.981,506.118 423.927,508.435 423.935,507.772 419.986,505.449 						"/>
                <polygon class="st169" points="419.939,512.465 423.851,514.719 423.859,514.068 419.943,511.807 						"/>
              </g>
              <polygon class="st171" points="425.227,512.575 428.699,514.449 428.727,510.91 425.249,509.09 					"/>
              <polygon class="st171" points="425.198,517.92 428.663,519.861 428.691,515.372 425.221,513.484 					"/>
              <polygon class="st564" points="422.841,518.256 429.317,521.961 430.235,521.371 423.828,517.671 					"/>
              <g>
                <polygon class="st565" points="422.841,507.513 429.317,511.218 430.211,510.628 423.804,506.928 						"/>
                <polygon class="st169" points="422.841,508.043 429.34,511.767 429.351,511.218 422.843,507.527 						"/>
                <polygon class="st566" points="429.345,511.752 430.211,511.201 430.211,510.628 429.351,511.218 						"/>
                <polygon class="st567" points="422.841,518.786 429.34,522.51 429.351,521.961 422.843,518.27 						"/>
                <polygon class="st566" points="429.345,522.495 430.211,521.944 430.211,521.371 429.351,521.961 						"/>
              </g>
              <ellipse class="st170" cx="428.788" cy="514.873" rx="0.322" ry="0.425"/>
            </g>
            <polygon class="st574" points="436.931,525.892 459.235,512.892 459.235,490.96 437.258,502.212 				"/>
            <path class="st568" d="M416.57,494.223c0,0,0.452,1.038,1.306,0.589l9.496-5.336l11.3,14.576
              c1.144,1.48,1.785,0.783,1.785,0.783l-13.008-16.73L416.57,494.223z"/>
            <polygon class="st156" points="427.449,488.106 440.457,504.836 461.412,493.041 448.404,476.284 				"/>
            <g>
              <g>
                <g>
                  <polygon class="st67" points="441.197,513.294 441.261,518.954 446.746,515.765 446.703,510.066 							"/>
                  <path class="st56" d="M444.141,511.436l-3.143,1.802v6.122l5.925-3.368l-0.045-6.165L444.141,511.436z M441.453,518.58
                    l-0.056-5.103l2.343-1.354l0.026,2.307l0.022,2.801L441.453,518.58z M444.189,517.024l-0.021-1.942l-0.023-3.169l2.341-1.352
                    l0.04,5.098L444.189,517.024z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <polygon class="st67" points="453.111,506.402 453.174,512.062 455.901,510.488 455.858,504.789 						"/>
                <path class="st56" d="M452.911,506.219l0.072,6.249l3.117-1.798l-0.046-6.298L452.911,506.219z M453.366,511.655l-0.056-5.07
                  l2.352-1.38l0.04,5.1L453.366,511.655z"/>
              </g>
              <polygon class="st169" points="452.507,506.075 452.515,506.738 456.46,504.421 456.456,503.752 					"/>
              <polygon class="st169" points="452.583,512.37 452.591,513.022 456.503,510.767 456.498,510.109 					"/>
            </g>
            <polygon class="st169" points="440.457,513.01 440.465,513.673 447.464,509.541 447.46,508.872 				"/>
            <polygon class="st169" points="440.533,519.306 440.541,519.957 447.607,516.013 447.603,515.355 				"/>
          </g>
          <g>
            <path class="st171" d="M430.168,488.997l-1.406,0.799l0.227,0.292l1.359-0.773c0.088-0.05,0.119-0.162,0.069-0.25
              C430.368,488.977,430.256,488.947,430.168,488.997z"/>
            <path class="st171" d="M433.483,487.112l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C433.683,487.093,433.571,487.062,433.483,487.112z"/>
            <path class="st171" d="M436.797,485.228l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C436.997,485.209,436.885,485.178,436.797,485.228z"/>
            <path class="st171" d="M440.112,483.344l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C440.312,483.325,440.2,483.294,440.112,483.344z"/>
            <path class="st171" d="M443.427,481.46l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C443.626,481.44,443.515,481.41,443.427,481.46z"/>
            <path class="st171" d="M446.741,479.575l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C446.941,479.556,446.829,479.525,446.741,479.575z"/>
            <path class="st171" d="M447.187,479.322c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.526-1.436
              l-0.227-0.293L447.187,479.322z"/>
            <path class="st171" d="M433.184,490.148l-2.868,1.631c-0.003,0.001-0.004,0.004-0.007,0.006l0.226,0.291l2.83-1.609
              c0.088-0.05,0.119-0.162,0.069-0.25C433.384,490.129,433.273,490.099,433.184,490.148z"/>
            <path class="st171" d="M436.499,488.264l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C436.699,488.245,436.587,488.214,436.499,488.264z"/>
            <path class="st171" d="M439.814,486.38l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C440.014,486.361,439.902,486.33,439.814,486.38z"/>
            <path class="st171" d="M443.128,484.496l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C443.328,484.477,443.216,484.446,443.128,484.496z"/>
            <path class="st171" d="M446.443,482.612l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C446.643,482.592,446.531,482.561,446.443,482.612z"/>
            <path class="st171" d="M449.757,480.727l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C449.957,480.708,449.845,480.677,449.757,480.727z"/>
            <path class="st171" d="M450.204,480.474c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.054-0.599
              l-0.227-0.293L450.204,480.474z"/>
            <path class="st171" d="M432.631,493.329l-0.777,0.442l0.228,0.292l0.731-0.416c0.088-0.05,0.119-0.162,0.069-0.25
              C432.831,493.31,432.719,493.28,432.631,493.329z"/>
            <path class="st171" d="M435.946,491.445l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C436.146,491.426,436.034,491.395,435.946,491.445z"/>
            <path class="st171" d="M439.26,489.561l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C439.46,489.542,439.349,489.511,439.26,489.561z"/>
            <path class="st171" d="M442.575,487.677l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C442.775,487.658,442.663,487.627,442.575,487.677z"/>
            <path class="st171" d="M445.89,485.793l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C446.09,485.773,445.978,485.742,445.89,485.793z"/>
            <path class="st171" d="M449.204,483.908l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C449.404,483.889,449.292,483.858,449.204,483.908z"/>
            <path class="st171" d="M452.519,482.024l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C452.719,482.005,452.607,481.974,452.519,482.024z"/>
            <path class="st171" d="M435.648,494.481l-2.248,1.278l0.227,0.292l2.202-1.252c0.088-0.05,0.119-0.162,0.069-0.25
              C435.848,494.462,435.736,494.431,435.648,494.481z"/>
            <path class="st171" d="M438.962,492.597l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C439.162,492.578,439.05,492.547,438.962,492.597z"/>
            <path class="st171" d="M442.277,490.713l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C442.477,490.694,442.365,490.663,442.277,490.713z"/>
            <path class="st171" d="M445.591,488.829l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C445.791,488.809,445.679,488.778,445.591,488.829z"/>
            <path class="st171" d="M448.906,486.944l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25l0,0c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C449.106,486.925,448.994,486.894,448.906,486.944z"/>
            <path class="st171" d="M452.221,485.06l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C452.42,485.041,452.309,485.01,452.221,485.06z"/>
            <path class="st171" d="M452.667,484.806c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.679-0.954
              l-0.227-0.293L452.667,484.806z"/>
            <path class="st171" d="M435.094,497.662l-0.149,0.085l0.227,0.292l0.103-0.059c0.088-0.05,0.119-0.162,0.069-0.25
              C435.294,497.643,435.182,497.612,435.094,497.662z"/>
            <path class="st171" d="M438.409,495.778l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C438.609,495.759,438.497,495.728,438.409,495.778z"/>
            <path class="st171" d="M441.724,493.894l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C441.923,493.875,441.812,493.844,441.724,493.894z"/>
            <path class="st171" d="M445.038,492.01l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C445.238,491.99,445.126,491.96,445.038,492.01z"/>
            <path class="st171" d="M448.353,490.125l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C448.553,490.106,448.441,490.075,448.353,490.125z"/>
            <path class="st171" d="M451.667,488.241l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C451.867,488.222,451.755,488.191,451.667,488.241z"/>
            <path class="st171" d="M454.982,486.357l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C455.182,486.338,455.07,486.307,454.982,486.357z"/>
            <path class="st171" d="M455.428,486.103c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l0.461-0.262
              l-0.227-0.293L455.428,486.103z"/>
            <path class="st171" d="M438.111,498.814l-1.62,0.921l0.227,0.293l1.574-0.895c0.088-0.05,0.119-0.162,0.069-0.25
              C438.311,498.795,438.199,498.764,438.111,498.814z"/>
            <path class="st171" d="M441.425,496.93l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C441.625,496.911,441.513,496.88,441.425,496.93z"/>
            <path class="st171" d="M444.74,495.046l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C444.94,495.026,444.828,494.996,444.74,495.046z"/>
            <path class="st171" d="M448.054,493.161l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C448.254,493.142,448.142,493.111,448.054,493.161z"/>
            <path class="st171" d="M451.369,491.277l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C451.569,491.258,451.457,491.227,451.369,491.277z"/>
            <path class="st171" d="M454.684,489.393l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C454.884,489.374,454.772,489.343,454.684,489.393z"/>
            <path class="st171" d="M455.13,489.139c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l2.303-1.31
              l-0.227-0.293L455.13,489.139z"/>
            <path class="st171" d="M440.872,500.111l-2.836,1.612l0.227,0.292l2.789-1.586c0.088-0.05,0.119-0.162,0.069-0.25
              C441.072,500.092,440.96,500.061,440.872,500.111z"/>
            <path class="st171" d="M444.187,498.227l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C444.387,498.207,444.275,498.177,444.187,498.227z"/>
            <path class="st171" d="M447.501,496.342l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C447.701,496.323,447.589,496.292,447.501,496.342z"/>
            <path class="st171" d="M450.816,494.458l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C451.016,494.439,450.904,494.408,450.816,494.458z"/>
            <path class="st171" d="M454.38,492.643c-0.05-0.088-0.162-0.119-0.25-0.069l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25
              c0.05,0.088,0.162,0.119,0.25,0.069l2.868-1.631C454.4,492.842,454.43,492.731,454.38,492.643L454.38,492.643z"/>
            <path class="st171" d="M457.445,490.69l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C457.645,490.67,457.533,490.64,457.445,490.69z"/>
            <path class="st171" d="M457.891,490.436c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069l1.086-0.617
              l-0.227-0.293L457.891,490.436z"/>
            <path class="st171" d="M440.574,503.147l-0.992,0.564l0.228,0.292l0.945-0.537c0.088-0.05,0.119-0.162,0.069-0.25
              C440.774,503.128,440.662,503.097,440.574,503.147z"/>
            <path class="st171" d="M443.888,501.263l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C444.088,501.243,443.976,501.213,443.888,501.263z"/>
            <path class="st171" d="M447.203,499.379l-2.868,1.63c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C447.403,499.359,447.291,499.328,447.203,499.379z"/>
            <path class="st171" d="M450.518,497.494l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.088-0.05,0.119-0.162,0.069-0.25C450.717,497.475,450.606,497.444,450.518,497.494z"/>
            <path class="st171" d="M453.832,495.61l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C454.032,495.591,453.92,495.56,453.832,495.61z"/>
            <path class="st171" d="M457.147,493.726l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.631c0.088-0.05,0.119-0.162,0.069-0.25C457.347,493.706,457.235,493.676,457.147,493.726z"/>
            <path class="st171" d="M460.461,491.841l-2.868,1.631c-0.088,0.05-0.119,0.162-0.069,0.25c0.05,0.088,0.162,0.119,0.25,0.069
              l2.868-1.63c0.021-0.012,0.037-0.027,0.051-0.045l-0.217-0.279C460.472,491.839,460.466,491.839,460.461,491.841z"/>
          </g>
          <g>
            <polygon class="st157" points="447.021,481.472 448.897,482.531 450.736,481.475 448.876,480.401 				"/>
            <polygon class="st158" points="447.021,481.193 448.897,482.277 448.897,481.615 447.021,480.556 				"/>
            <polygon class="st159" points="448.897,482.277 450.736,481.159 450.736,480.559 448.897,481.615 				"/>
            <polygon class="st573" points="447.021,485.737 448.897,488.009 448.897,482.511 447.021,481.452 				"/>
            <polygon class="st574" points="448.897,488.009 450.736,486.891 450.736,481.455 448.897,482.511 				"/>
            <polygon class="st162" points="447.021,480.556 448.897,481.615 450.736,480.559 448.876,479.485 				"/>
          </g>
        </g>
      </g>
      <path class="st1" d="M106.211,383.426l55.657,32.8c4.44,2.617,4.042,7.345-1.095,10.719l0,0
        c-5.403,3.548-13.958,4.271-18.872,1.434l-61.782-35.667c-5.251-3.031-3.011-7.625,4.646-10.123l0,0
        C92.031,380.218,101.495,380.646,106.211,383.426z"/>
      <path class="st1" d="M708.953,733.851l55.657,32.8c4.44,2.617,4.042,7.345-1.095,10.719l0,0
        c-5.403,3.548-13.958,4.271-18.872,1.434l-61.782-35.667c-5.251-3.031-3.011-7.625,4.646-10.123l0,0
        C694.772,730.644,704.236,731.072,708.953,733.851z"/>
      <g>
        <g>
          <g>
            <g>
              <linearGradient id="SVGID_139_" gradientUnits="userSpaceOnUse" x1="727.415" y1="759.6976" x2="758.7903" y2="759.6976">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st575" points="728.441,757.418 727.415,761.977 758.79,761.977 757.764,757.418 					"/>
              <linearGradient id="SVGID_140_" gradientUnits="userSpaceOnUse" x1="714.1066" y1="747.4576" x2="745.4821" y2="747.4576">
                <stop  offset="0" style="stop-color:#000000"/>
                <stop  offset="1" style="stop-color:#FFFFFF"/>
              </linearGradient>
              <polygon class="st576" points="715.133,745.178 714.107,749.738 745.482,749.738 744.456,745.178 					"/>
              <g>
                <path class="st4" d="M715.488,736.349c-1.883,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
                  c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.485c0,0-0.458,2.995,1.579,4.627
                  c1.223,0.98,2.793,1.33,2.793,1.33s0.916,6.518-1.372,9.14c0,0,0.739,1.939,2.64,1.089c1.107-0.495,1.925-0.369,3.196-0.812
                  c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.029c1.329-0.611,2.997-1.94,3.161-3.048h-1.166
                  C718.812,734.023,717.372,736.239,715.488,736.349z"/>
                <g>
                  <path class="st5" d="M724.615,722.014c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                    c-2.187-0.64-4.583-1.105-6.702-0.266c-0.698,0.276-1.356,0.691-2.1,0.784c-0.422,0.052-0.856-0.002-1.271,0.091
                    c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.95-4.56,5.207
                    c-0.13,1.189,0.352,2.469,1.319,3.173c0.812,0.592,1.878,0.719,2.881,0.651c-0.749,0.26-1.037,1.223-0.848,1.993
                    c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.133-0.482
                    c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35C728.715,723.702,724.615,722.014,724.615,722.014z"/>
                  <path class="st6" d="M721.683,728.598c-0.767,3.114-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                    c-2.183-0.565-3.488-2.145-4.224-4.05c-1.52,0.068-3.019-0.178-4.58-0.981c-0.169-0.087-0.302-0.192-0.448-0.29
                    c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.882,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                    c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.133-0.482
                    c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.325-0.306-3.908-1.148-4.961
                    C725.881,725.884,724.032,727.418,721.683,728.598z"/>
                  <g>
                    <path class="st7" d="M723.909,716.842c0,0,1.462-1.734-0.891-5.091c-1.009-1.439-4.473-2.064-4.473-2.064
                      s-1.261-1.327-4.529-1.317c-2.336,0.007-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                      c-1.232,1.431-2.286,2.558-2.586,4.423c-0.3,1.865,1.148,4.019,2.895,4.736c2.013,0.825,5.708,0.327,5.708,0.327
                      s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.891c0.171-0.119,3.721,0.134,5.173-1.005
                      c1.321-1.036,2.338-2.456,1.147-4.758C724.982,717.945,723.909,716.842,723.909,716.842z"/>
                    <path class="st8" d="M714.016,708.37c-2.336,0.008-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                      c-0.307,0.356-0.603,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.385
                      c0.332,0.092,0.678,0.125,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.548,0,1.025,0.105,1.462,0.259
                      c0.687-0.008,1.389,0.148,2.067,0.55c0.209,0.124,0.457,0.159,0.71,0.177c0.265-1.245,1.025-2.36,2.289-2.884
                      c-0.888-2.038-0.25-4.791,1.97-5.725c-0.797-0.241-1.433-0.361-1.433-0.361S717.284,708.359,714.016,708.37z"/>
                    <path class="st9" d="M723.909,716.842c0,0,0.698-0.875,0.311-2.548c-0.434,1.229-1.292,2.245-2.367,3.091
                      c0.08,0.935-0.08,1.914-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.304,1.148-3.207,1.627-5.19,1.151
                      c-0.594-0.143-1.059-0.395-1.466-0.689c-2.207,1.383-4.842,1.965-7.009,0.857c0.441,0.447,0.945,0.819,1.487,1.041
                      c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.608,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.891
                      c0.171-0.119,3.722,0.134,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758C724.982,717.945,723.909,716.842,723.909,716.842
                      z"/>
                  </g>
                </g>
              </g>
              <g>
                <path class="st4" d="M746.017,745.956c-1.884,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
                  c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.485c0,0-0.458,2.995,1.579,4.627
                  c1.223,0.98,2.793,1.329,2.793,1.329s0.916,6.518-1.372,9.14c0,0,0.739,1.939,2.64,1.089c1.107-0.495,1.925-0.369,3.196-0.812
                  c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.029c1.33-0.61,2.997-1.94,3.161-3.048h-1.166
                  C749.34,743.629,747.9,745.844,746.017,745.956z"/>
                <g>
                  <path class="st5" d="M755.143,731.62c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                    c-2.187-0.64-4.583-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                    c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.874-1.021,2.934c-1.303-0.12-4.203,1.95-4.56,5.207
                    c-0.13,1.19,0.353,2.468,1.319,3.173c0.812,0.592,1.879,0.719,2.881,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                    c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.594-1.52,7.594-1.52s1.707,1.874,6.132-0.482
                    c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35C759.243,733.308,755.143,731.62,755.143,731.62z"/>
                  <path class="st6" d="M752.211,738.203c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                    c-2.183-0.564-3.488-2.145-4.224-4.05c-1.52,0.068-3.018-0.178-4.579-0.981c-0.169-0.087-0.303-0.192-0.448-0.291
                    c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.882,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                    c0.189,0.77,1.117,3.474,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.133-0.482
                    c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                    C756.409,735.49,754.561,737.024,752.211,738.203z"/>
                  <g>
                    <path class="st7" d="M754.437,726.448c0,0,1.462-1.734-0.891-5.091c-1.009-1.438-4.473-2.064-4.473-2.064
                      s-1.262-1.327-4.53-1.317c-2.336,0.007-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                      c-1.233,1.431-2.287,2.558-2.587,4.423c-0.3,1.864,1.147,4.019,2.895,4.736c2.013,0.826,5.708,0.327,5.708,0.327
                      s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.892c0.171-0.118,3.721,0.134,5.173-1.005
                      c1.321-1.036,2.338-2.456,1.147-4.758C755.51,727.551,754.437,726.448,754.437,726.448z"/>
                    <path class="st8" d="M744.544,717.976c-2.336,0.007-3.294,1.974-3.294,1.974s-6.626,0.375-6.017,6.01
                      c-0.307,0.356-0.603,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.384
                      c0.331,0.092,0.678,0.125,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.547,0,1.025,0.104,1.461,0.259
                      c0.687-0.008,1.389,0.148,2.067,0.55c0.21,0.124,0.457,0.159,0.71,0.177c0.265-1.245,1.025-2.36,2.288-2.884
                      c-0.887-2.038-0.25-4.791,1.97-5.725c-0.797-0.24-1.433-0.361-1.433-0.361S747.812,717.965,744.544,717.976z"/>
                    <path class="st9" d="M754.437,726.448c0,0,0.698-0.874,0.311-2.548c-0.434,1.229-1.292,2.245-2.368,3.091
                      c0.08,0.934-0.08,1.914-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.304,1.148-3.206,1.627-5.19,1.151
                      c-0.594-0.143-1.058-0.395-1.466-0.689c-2.207,1.383-4.841,1.965-7.008,0.857c0.441,0.448,0.945,0.818,1.487,1.041
                      c2.013,0.826,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.892
                      c0.171-0.118,3.721,0.134,5.173-1.005c1.321-1.036,2.338-2.456,1.147-4.758C755.51,727.551,754.437,726.448,754.437,726.448
                      z"/>
                  </g>
                </g>
              </g>
              <g>
                <path class="st4" d="M728.463,750.453c-1.688,0.099-1.319-2.086-1.319-2.086h-1.263c0,0,0.241,2.385,1.263,2.732
                  c0,0-0.841,1.117-2.43-0.423c-1.589-1.54-1.054-2.881-1.054-2.881h-0.436c0,0-0.411,2.684,1.415,4.147
                  c1.096,0.878,2.504,1.192,2.504,1.192s0.821,5.844-1.23,8.193c0,0,0.663,1.739,2.367,0.977
                  c0.992-0.444,1.725-0.331,2.865-0.728c0,0-1.192-0.596-1.589-2.383c-0.397-1.788-1.093-7.546,0.099-8.093
                  c1.192-0.547,2.687-1.739,2.833-2.732h-1.045C731.442,748.367,730.151,750.354,728.463,750.453z"/>
                <g>
                  <path class="st10" d="M736.644,737.603c0,0-1.093-2.791-2.063-3.485c-0.97-0.695-2.119-1.09-3.264-1.424
                    c-1.961-0.574-4.109-0.991-6.008-0.239c-0.625,0.247-1.215,0.619-1.883,0.702c-0.378,0.047-0.767-0.002-1.139,0.082
                    c-0.575,0.129-1.488,1.126-1.865,1.589c-0.598,0.733-0.942,1.68-0.916,2.629c-1.168-0.108-3.768,1.748-4.087,4.668
                    c-0.117,1.066,0.316,2.213,1.182,2.845c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.096-0.76,1.786
                    c0.169,0.69,1.001,3.113,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                    c0.819-0.436,1.418-1.649,1.012-2.484c0.904-0.051,4.484-0.757,4.859-3.003C740.319,739.116,736.644,737.603,736.644,737.603
                    z"/>
                  <path class="st11" d="M734.016,743.504c-0.688,2.792-3.713,4.423-6.345,3.896c-1.147,0.482-2.502,0.595-3.947,0.222
                    c-1.957-0.506-3.127-1.923-3.786-3.63c-1.363,0.061-2.706-0.159-4.105-0.88c-0.151-0.078-0.271-0.172-0.402-0.26
                    c0.097,0.83,0.499,1.629,1.17,2.118c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                    c0.169,0.69,1.001,3.113,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                    c0.82-0.436,1.418-1.649,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003c0.348-2.083-0.274-3.503-1.029-4.447
                    C737.778,741.072,736.122,742.447,734.016,743.504z"/>
                  <g>
                    <path class="st12" d="M736.011,732.966c0,0,1.31-1.554-0.799-4.563c-0.904-1.29-4.009-1.85-4.009-1.85
                      s-1.131-1.19-4.06-1.18c-2.094,0.006-2.953,1.769-2.953,1.769s-5.94,0.336-5.394,5.388
                      c-1.105,1.283-2.049,2.293-2.318,3.965c-0.269,1.671,1.028,3.603,2.595,4.245c1.804,0.74,5.117,0.293,5.117,0.293
                      s1.252,1.442,3.709,1.45c2.996,0.01,3.405-2.313,3.808-2.592c0.154-0.106,3.336,0.12,4.637-0.901
                      c1.184-0.928,2.096-2.201,1.028-4.265C736.973,733.956,736.011,732.966,736.011,732.966z"/>
                    <path class="st13" d="M727.143,725.372c-2.094,0.007-2.953,1.769-2.953,1.769s-5.939,0.336-5.394,5.388
                      c-0.275,0.319-0.54,0.622-0.787,0.921c0.138,0.102,0.278,0.191,0.411,0.317c0.696,0.656,1.745,0.991,2.649,1.241
                      c0.297,0.082,0.608,0.112,0.896,0.131c0.601-0.839,1.56-1.429,2.907-1.429c0.49,0,0.918,0.094,1.31,0.232
                      c0.615-0.007,1.245,0.133,1.852,0.493c0.188,0.111,0.409,0.143,0.636,0.159c0.238-1.116,0.918-2.116,2.052-2.586
                      c-0.796-1.827-0.224-4.294,1.766-5.132c-0.714-0.216-1.285-0.324-1.285-0.324S730.072,725.363,727.143,725.372z"/>
                    <path class="st14" d="M736.011,732.966c0,0,0.625-0.784,0.278-2.284c-0.389,1.102-1.159,2.012-2.122,2.771
                      c0.072,0.838-0.072,1.716-0.577,2.628c-0.794,1.431-2.127,2.257-3.602,2.544c-1.17,1.029-2.875,1.458-4.653,1.031
                      c-0.532-0.128-0.949-0.354-1.314-0.617c-1.979,1.24-4.34,1.762-6.283,0.768c0.395,0.401,0.847,0.734,1.333,0.933
                      c1.167,0.478,2.964,0.461,4.088,0.388c0.631-0.041,1.235,0.163,1.766,0.507c0.613,0.396,1.606,0.844,2.971,0.848
                      c2.996,0.01,3.404-2.313,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                      C736.973,733.956,736.011,732.966,736.011,732.966z"/>
                  </g>
                </g>
              </g>
              <g>
                <path class="st15" d="M720.15,756.029c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
                  c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.498-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.339
                  c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.183-0.154,0.378-0.179,0.576c-0.528-0.02-1.033,0.21-1.288,0.674
                  c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.313,0.124,0.473,0.156c0.111,0.819,0.619,1.596,1.435,2.044
                  c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111c0.839,0.46,1.836,0.301,2.426-0.324
                  c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.502,2.035,0.227,2.496-0.616C721.435,757.622,721.066,756.531,720.15,756.029z"
                  />
                <path class="st16" d="M718.271,757.984c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.055-0.114-0.08
                  c-0.013-0.002-0.023,0.003-0.036,0.001c-1.404-0.246-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
                  c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.313,0.124,0.473,0.156
                  c0.111,0.819,0.619,1.596,1.435,2.044c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111
                  c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.645,0.353,1.384,0.315,1.93-0.028
                  c-0.049,0.013-0.097,0.029-0.154,0.031C719.35,759.124,718.719,758.682,718.271,757.984z"/>
              </g>
              <g>
                <path class="st17" d="M726.491,759.094c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
                  c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.497-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.338
                  c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.182-0.154,0.378-0.179,0.575c-0.528-0.02-1.033,0.21-1.288,0.674
                  c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156c0.111,0.819,0.62,1.596,1.436,2.044
                  c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112c0.839,0.46,1.836,0.301,2.426-0.324
                  c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.503,2.034,0.227,2.496-0.616C727.776,760.687,727.407,759.596,726.491,759.094z
                  "/>
                <path class="st18" d="M724.612,761.049c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.056-0.114-0.081
                  c-0.013-0.001-0.023,0.004-0.035,0.002c-1.404-0.247-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
                  c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.313,0.124,0.472,0.156
                  c0.111,0.819,0.62,1.596,1.436,2.044c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112
                  c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.644,0.353,1.384,0.315,1.93-0.028
                  c-0.05,0.013-0.098,0.029-0.154,0.031C725.691,762.188,725.06,761.747,724.612,761.049z"/>
              </g>
              <g>
                <path class="st15" d="M731.959,760.872c-0.163-0.06-0.328-0.076-0.49-0.083c-0.233-0.793-0.853-1.485-1.727-1.804
                  c-0.573-0.21-1.164-0.218-1.699-0.077c-0.233-0.42-0.619-0.77-1.121-0.954c-0.899-0.328-1.86-0.021-2.349,0.685
                  c-0.02-0.008-0.038-0.02-0.058-0.028c-0.982-0.359-2.045,0.082-2.375,0.984c-0.33,0.903,0.199,1.925,1.18,2.284
                  c-0.36,0.986,0.204,2.098,1.259,2.484c0.414,0.151,0.84,0.165,1.23,0.07c0.181,0.433,0.53,0.806,1.023,0.986
                  c0.743,0.272,1.518,0.018,1.946-0.545c0.148,0.106,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.984
                  c0.072-0.196,0.096-0.397,0.091-0.596c0.525-0.06,0.99-0.363,1.172-0.86C733.17,761.979,732.747,761.16,731.959,760.872z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <linearGradient id="SVGID_141_" gradientUnits="userSpaceOnUse" x1="101.137" y1="392.6754" x2="132.5127" y2="392.6754">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st577" points="102.163,390.396 101.137,394.955 132.513,394.955 131.486,390.396 			"/>
          <g>
            <path class="st4" d="M102.805,381.783c-1.883,0.111-1.472-2.327-1.472-2.327h-1.409c0,0,0.269,2.661,1.409,3.048
              c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214H96.96c0,0-0.458,2.995,1.579,4.627
              c1.223,0.98,2.794,1.329,2.794,1.329s0.916,6.519-1.373,9.14c0,0,0.739,1.939,2.64,1.089c1.107-0.495,1.925-0.369,3.196-0.812
              c0,0-1.33-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.028c1.329-0.611,2.997-1.94,3.161-3.048h-1.166
              C106.129,379.457,104.689,381.673,102.805,381.783z"/>
            <g>
              <path class="st5" d="M111.931,367.448c0,0-1.219-3.113-2.301-3.888c-1.082-0.775-2.364-1.215-3.642-1.589
                c-2.187-0.64-4.583-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.95-4.56,5.207
                c-0.13,1.189,0.352,2.468,1.319,3.173c0.812,0.592,1.879,0.719,2.881,0.65c-0.749,0.26-1.036,1.223-0.848,1.993
                c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.084,7.593-1.52,7.593-1.52s1.707,1.874,6.132-0.482
                c0.914-0.486,1.582-1.84,1.129-2.772c1.008-0.057,5.002-0.845,5.42-3.35C116.032,369.136,111.931,367.448,111.931,367.448z"/>
              <path class="st6" d="M109,374.031c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.664-4.403,0.248
                c-2.183-0.565-3.488-2.146-4.224-4.05c-1.52,0.068-3.018-0.177-4.579-0.981c-0.169-0.087-0.303-0.192-0.448-0.29
                c0.108,0.926,0.556,1.817,1.305,2.363c0.813,0.592,1.879,0.719,2.882,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                c0.189,0.77,1.117,3.473,3.319,3.931c5.226,1.084,7.593-1.52,7.593-1.52s1.707,1.874,6.132-0.482
                c0.914-0.487,1.582-1.84,1.13-2.772c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                C113.197,371.318,111.349,372.852,109,374.031z"/>
              <g>
                <path class="st7" d="M111.226,362.276c0,0,1.462-1.734-0.891-5.091c-1.008-1.439-4.473-2.065-4.473-2.065
                  s-1.261-1.327-4.529-1.316c-2.336,0.007-3.295,1.974-3.295,1.974s-6.626,0.375-6.017,6.01
                  c-1.233,1.431-2.286,2.558-2.586,4.423s1.147,4.019,2.895,4.736c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.608,4.138,1.618
                  c3.342,0.012,3.798-2.581,4.248-2.891c0.171-0.119,3.721,0.134,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758
                  C112.299,363.38,111.226,362.276,111.226,362.276z"/>
                <path class="st8" d="M101.332,353.804c-2.336,0.007-3.295,1.974-3.295,1.974s-6.626,0.375-6.017,6.01
                  c-0.307,0.356-0.602,0.693-0.878,1.027c0.153,0.113,0.309,0.213,0.458,0.353c0.777,0.732,1.947,1.106,2.955,1.384
                  c0.331,0.092,0.678,0.126,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.548,0,1.025,0.104,1.462,0.259
                  c0.687-0.008,1.389,0.148,2.067,0.55c0.21,0.124,0.457,0.159,0.71,0.177c0.265-1.245,1.024-2.36,2.288-2.884
                  c-0.887-2.038-0.25-4.791,1.971-5.725c-0.797-0.241-1.433-0.362-1.433-0.362S104.6,353.793,101.332,353.804z"/>
                <path class="st9" d="M111.226,362.276c0,0,0.698-0.874,0.31-2.549c-0.434,1.229-1.292,2.245-2.367,3.091
                  c0.08,0.934-0.08,1.915-0.644,2.931c-0.885,1.596-2.373,2.517-4.018,2.837c-1.305,1.148-3.206,1.626-5.19,1.15
                  c-0.594-0.143-1.058-0.395-1.466-0.689c-2.207,1.383-4.841,1.965-7.009,0.857c0.441,0.447,0.945,0.818,1.487,1.041
                  c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.342,0.012,3.798-2.581,4.248-2.891
                  c0.171-0.119,3.721,0.133,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758C112.299,363.38,111.226,362.276,111.226,362.276z"
                  />
              </g>
            </g>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_142_" gradientUnits="userSpaceOnUse" x1="111.554" y1="398.6524" x2="142.9297" y2="398.6524">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st578" points="112.58,396.373 111.554,400.932 142.93,400.932 141.903,396.373 			"/>
          <g>
            <path class="st4" d="M112.171,389.456c-1.688,0.099-1.319-2.086-1.319-2.086h-1.263c0,0,0.241,2.385,1.263,2.732
              c0,0-0.841,1.117-2.43-0.423c-1.589-1.54-1.054-2.881-1.054-2.881h-0.436c0,0-0.411,2.684,1.415,4.147
              c1.096,0.878,2.504,1.192,2.504,1.192s0.821,5.843-1.23,8.193c0,0,0.663,1.739,2.367,0.977c0.992-0.444,1.725-0.331,2.865-0.728
              c0,0-1.192-0.596-1.589-2.384c-0.397-1.787-1.093-7.546,0.099-8.093c1.192-0.547,2.687-1.739,2.833-2.732h-1.045
              C115.151,387.371,113.86,389.357,112.171,389.456z"/>
            <g>
              <path class="st21" d="M120.352,376.606c0,0-1.093-2.791-2.063-3.485c-0.97-0.695-2.119-1.089-3.264-1.424
                c-1.961-0.574-4.109-0.99-6.008-0.239c-0.625,0.247-1.215,0.619-1.883,0.702c-0.378,0.047-0.767-0.002-1.139,0.082
                c-0.575,0.129-1.488,1.126-1.866,1.589c-0.598,0.733-0.942,1.68-0.916,2.629c-1.168-0.107-3.768,1.748-4.087,4.668
                c-0.116,1.066,0.316,2.213,1.182,2.844c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                c0.169,0.69,1.001,3.113,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                c0.819-0.436,1.418-1.65,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003C124.027,378.119,120.352,376.606,120.352,376.606z"
                />
              <path class="st22" d="M117.724,382.508c-0.688,2.792-3.713,4.423-6.345,3.896c-1.147,0.482-2.502,0.595-3.947,0.222
                c-1.957-0.506-3.127-1.923-3.787-3.63c-1.362,0.061-2.706-0.159-4.105-0.88c-0.151-0.078-0.271-0.172-0.402-0.26
                c0.097,0.83,0.499,1.628,1.17,2.118c0.728,0.531,1.684,0.645,2.583,0.583c-0.671,0.233-0.929,1.097-0.76,1.787
                c0.169,0.69,1.001,3.113,2.975,3.523c4.684,0.972,6.807-1.363,6.807-1.363s1.531,1.68,5.497-0.432
                c0.82-0.436,1.418-1.65,1.013-2.484c0.904-0.051,4.484-0.757,4.858-3.003c0.348-2.083-0.274-3.503-1.029-4.447
                C121.487,380.075,119.83,381.45,117.724,382.508z"/>
              <g>
                <path class="st23" d="M119.72,371.97c0,0,1.31-1.554-0.799-4.564c-0.904-1.289-4.009-1.85-4.009-1.85s-1.131-1.19-4.06-1.18
                  c-2.094,0.007-2.953,1.769-2.953,1.769s-5.94,0.336-5.394,5.388c-1.105,1.282-2.05,2.293-2.318,3.965
                  c-0.269,1.671,1.028,3.603,2.595,4.245c1.804,0.74,5.117,0.293,5.117,0.293s1.252,1.442,3.709,1.45
                  c2.996,0.011,3.404-2.314,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                  C120.682,372.959,119.72,371.97,119.72,371.97z"/>
                <path class="st24" d="M110.851,364.376c-2.094,0.007-2.953,1.769-2.953,1.769s-5.939,0.336-5.394,5.388
                  c-0.275,0.319-0.54,0.621-0.787,0.921c0.138,0.102,0.278,0.191,0.411,0.317c0.696,0.656,1.745,0.991,2.649,1.241
                  c0.297,0.082,0.608,0.112,0.896,0.131c0.601-0.839,1.56-1.429,2.907-1.429c0.49,0,0.918,0.094,1.31,0.232
                  c0.615-0.007,1.245,0.133,1.852,0.493c0.188,0.111,0.409,0.143,0.636,0.159c0.238-1.116,0.918-2.116,2.052-2.586
                  c-0.796-1.827-0.224-4.295,1.766-5.132c-0.714-0.216-1.285-0.324-1.285-0.324S113.78,364.366,110.851,364.376z"/>
                <path class="st25" d="M119.72,371.97c0,0,0.625-0.784,0.278-2.284c-0.389,1.102-1.158,2.012-2.122,2.771
                  c0.072,0.838-0.072,1.716-0.577,2.628c-0.794,1.431-2.127,2.257-3.602,2.543c-1.17,1.029-2.875,1.458-4.653,1.031
                  c-0.532-0.128-0.949-0.354-1.314-0.617c-1.979,1.24-4.34,1.762-6.282,0.768c0.395,0.401,0.847,0.734,1.333,0.933
                  c1.167,0.478,2.964,0.461,4.088,0.389c0.631-0.041,1.235,0.164,1.766,0.507c0.613,0.396,1.606,0.844,2.971,0.848
                  c2.996,0.011,3.404-2.314,3.808-2.592c0.153-0.106,3.336,0.12,4.637-0.901c1.184-0.928,2.096-2.201,1.028-4.265
                  C120.682,372.959,119.72,371.97,119.72,371.97z"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <linearGradient id="SVGID_143_" gradientUnits="userSpaceOnUse" x1="132.3958" y1="415.6682" x2="163.7715" y2="415.6682">
            <stop  offset="0" style="stop-color:#000000"/>
            <stop  offset="1" style="stop-color:#FFFFFF"/>
          </linearGradient>
          <polygon class="st579" points="133.422,413.388 132.396,417.948 163.771,417.948 162.745,413.388 			"/>
          <g>
            <path class="st4" d="M134.217,404.984c-1.884,0.111-1.472-2.326-1.472-2.326h-1.408c0,0,0.269,2.661,1.408,3.048
              c0,0-0.938,1.246-2.71-0.472c-1.773-1.718-1.176-3.214-1.176-3.214h-0.486c0,0-0.458,2.994,1.579,4.626
              c1.223,0.98,2.793,1.33,2.793,1.33s0.916,6.518-1.372,9.14c0,0,0.74,1.939,2.64,1.09c1.107-0.495,1.925-0.369,3.196-0.812
              c0,0-1.329-0.665-1.773-2.659c-0.443-1.994-1.219-8.418,0.111-9.028c1.329-0.61,2.997-1.94,3.161-3.048h-1.166
              C137.54,402.658,136.1,404.874,134.217,404.984z"/>
            <g>
              <path class="st27" d="M143.342,390.649c0,0-1.219-3.113-2.301-3.887c-1.082-0.775-2.364-1.216-3.641-1.589
                c-2.187-0.64-4.584-1.105-6.702-0.266c-0.697,0.276-1.356,0.691-2.1,0.783c-0.422,0.052-0.856-0.002-1.271,0.091
                c-0.642,0.144-1.66,1.256-2.081,1.773c-0.667,0.818-1.051,1.875-1.021,2.933c-1.303-0.12-4.203,1.951-4.56,5.207
                c-0.13,1.19,0.352,2.469,1.319,3.174c0.812,0.592,1.878,0.719,2.881,0.651c-0.749,0.26-1.037,1.223-0.848,1.993
                c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.085,7.593-1.52,7.593-1.52s1.708,1.874,6.132-0.482
                c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35C147.443,392.337,143.342,390.649,143.342,390.649z"/>
              <path class="st22" d="M140.411,397.233c-0.767,3.115-4.142,4.934-7.078,4.346c-1.28,0.538-2.791,0.665-4.403,0.248
                c-2.183-0.564-3.488-2.145-4.224-4.05c-1.52,0.068-3.018-0.178-4.58-0.981c-0.169-0.087-0.302-0.192-0.448-0.29
                c0.108,0.926,0.556,1.817,1.305,2.363c0.812,0.592,1.878,0.719,2.881,0.65c-0.749,0.26-1.037,1.223-0.848,1.993
                c0.189,0.77,1.117,3.473,3.319,3.93c5.226,1.084,7.593-1.52,7.593-1.52s1.708,1.874,6.132-0.482
                c0.914-0.487,1.582-1.84,1.13-2.771c1.008-0.057,5.002-0.845,5.42-3.35c0.388-2.324-0.306-3.908-1.148-4.961
                C144.609,394.519,142.76,396.053,140.411,397.233z"/>
              <g>
                <path class="st28" d="M142.637,385.477c0,0,1.462-1.734-0.891-5.091c-1.008-1.439-4.473-2.064-4.473-2.064
                  s-1.261-1.327-4.529-1.316c-2.336,0.007-3.295,1.973-3.295,1.973s-6.626,0.376-6.017,6.01
                  c-1.233,1.431-2.286,2.558-2.586,4.423c-0.3,1.864,1.147,4.019,2.895,4.736c2.013,0.826,5.708,0.327,5.708,0.327
                  s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.892c0.172-0.118,3.721,0.134,5.173-1.005
                  c1.321-1.036,2.338-2.456,1.147-4.758C143.71,386.58,142.637,385.477,142.637,385.477z"/>
                <path class="st24" d="M132.744,377.005c-2.336,0.007-3.295,1.973-3.295,1.973s-6.626,0.375-6.017,6.01
                  c-0.307,0.356-0.602,0.693-0.878,1.028c0.154,0.113,0.31,0.213,0.458,0.353c0.777,0.732,1.947,1.105,2.955,1.384
                  c0.332,0.091,0.678,0.125,0.999,0.146c0.67-0.936,1.741-1.594,3.243-1.594c0.548,0,1.025,0.105,1.462,0.258
                  c0.687-0.008,1.389,0.148,2.067,0.55c0.209,0.124,0.457,0.16,0.71,0.177c0.265-1.245,1.025-2.36,2.288-2.884
                  c-0.887-2.038-0.25-4.791,1.971-5.725c-0.797-0.241-1.433-0.362-1.433-0.362S136.012,376.994,132.744,377.005z"/>
                <path class="st25" d="M142.637,385.477c0,0,0.698-0.874,0.31-2.549c-0.434,1.229-1.292,2.245-2.368,3.091
                  c0.08,0.935-0.08,1.915-0.644,2.932c-0.886,1.595-2.373,2.517-4.018,2.837c-1.305,1.148-3.207,1.626-5.19,1.15
                  c-0.594-0.143-1.058-0.395-1.465-0.689c-2.207,1.383-4.842,1.966-7.009,0.857c0.441,0.447,0.945,0.818,1.487,1.041
                  c2.013,0.825,5.708,0.327,5.708,0.327s1.397,1.609,4.138,1.618c3.343,0.012,3.798-2.581,4.248-2.892
                  c0.172-0.118,3.721,0.134,5.173-1.005c1.32-1.036,2.338-2.456,1.147-4.758C143.71,386.58,142.637,385.477,142.637,385.477z"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path class="st15" d="M105.634,395.785c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
            c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.498-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.339
            c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.183-0.154,0.378-0.179,0.576c-0.528-0.02-1.033,0.21-1.288,0.674
            c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.312,0.124,0.473,0.156c0.111,0.819,0.619,1.596,1.435,2.044
            c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111c0.839,0.46,1.836,0.301,2.426-0.324
            c0.019,0.011,0.034,0.025,0.053,0.036c0.917,0.502,2.035,0.227,2.496-0.616C106.919,397.378,106.55,396.287,105.634,395.785z"/>
          <path class="st16" d="M103.755,397.74c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.055-0.114-0.08
            c-0.013-0.002-0.023,0.003-0.036,0.001c-1.404-0.246-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
            c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.549,0.66,1.952c0.152,0.083,0.312,0.124,0.473,0.156
            c0.111,0.819,0.619,1.596,1.435,2.044c0.535,0.293,1.117,0.391,1.668,0.332c0.167,0.45,0.496,0.854,0.965,1.111
            c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.645,0.353,1.384,0.315,1.93-0.028
            c-0.05,0.013-0.097,0.029-0.154,0.031C104.834,398.88,104.203,398.438,103.755,397.74z"/>
        </g>
        <g>
          <path class="st17" d="M111.975,398.85c0.504-0.92,0.114-2.105-0.872-2.645c-0.386-0.212-0.806-0.289-1.205-0.255
            c-0.114-0.455-0.403-0.876-0.864-1.129c-0.694-0.38-1.497-0.246-2.006,0.246c-0.13-0.128-0.274-0.246-0.443-0.338
            c-0.917-0.503-2.034-0.227-2.496,0.616c-0.1,0.182-0.154,0.378-0.179,0.575c-0.528-0.02-1.033,0.21-1.288,0.674
            c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.312,0.124,0.472,0.156c0.111,0.819,0.62,1.596,1.436,2.044
            c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112c0.839,0.46,1.836,0.301,2.426-0.324
            c0.019,0.011,0.034,0.025,0.053,0.036c0.916,0.503,2.034,0.227,2.496-0.616C113.26,400.443,112.891,399.352,111.975,398.85z"/>
          <path class="st18" d="M110.096,400.805c-1.357,0.385-3.319-0.309-3.267-1.691c-0.038-0.023-0.076-0.056-0.114-0.081
            c-0.013-0.001-0.023,0.004-0.035,0.002c-1.404-0.247-2.619-1.727-2.723-3.268c-0.011,0.052-0.041,0.101-0.048,0.154
            c-0.528-0.02-1.033,0.21-1.288,0.674c-0.37,0.676-0.075,1.55,0.66,1.953c0.152,0.083,0.312,0.124,0.472,0.156
            c0.111,0.819,0.62,1.596,1.436,2.044c0.535,0.293,1.118,0.391,1.668,0.331c0.167,0.45,0.496,0.854,0.965,1.112
            c0.839,0.46,1.836,0.301,2.426-0.324c0.019,0.011,0.034,0.025,0.053,0.036c0.644,0.353,1.384,0.315,1.93-0.028
            c-0.05,0.013-0.098,0.029-0.154,0.031C111.175,401.944,110.544,401.503,110.096,400.805z"/>
        </g>
        <g>
          <path class="st15" d="M117.443,400.628c-0.163-0.06-0.328-0.076-0.49-0.083c-0.233-0.793-0.853-1.485-1.727-1.804
            c-0.573-0.21-1.164-0.218-1.699-0.077c-0.233-0.42-0.619-0.77-1.121-0.954c-0.899-0.329-1.86-0.021-2.349,0.685
            c-0.02-0.008-0.038-0.02-0.058-0.028c-0.982-0.359-2.045,0.082-2.375,0.984c-0.33,0.903,0.199,1.925,1.18,2.284
            c-0.36,0.986,0.204,2.098,1.259,2.484c0.414,0.151,0.84,0.165,1.23,0.07c0.181,0.433,0.53,0.806,1.024,0.986
            c0.743,0.272,1.518,0.018,1.946-0.545c0.148,0.106,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.984
            c0.072-0.196,0.096-0.397,0.091-0.596c0.525-0.06,0.99-0.363,1.172-0.86C118.654,401.735,118.231,400.915,117.443,400.628z"/>
        </g>
        <g>
          <path class="st16" d="M113.051,402.497c-1.148-0.128-2.137-0.614-2.217-1.748c-0.825-0.515-1.403-1.429-1.094-2.426
            c-0.903-0.202-1.815,0.205-2.115,1.028c-0.33,0.903,0.199,1.925,1.18,2.284c-0.36,0.986,0.204,2.098,1.259,2.484
            c0.414,0.151,0.84,0.164,1.23,0.07c0.181,0.433,0.53,0.806,1.024,0.986c0.743,0.272,1.518,0.018,1.946-0.545
            c0.148,0.107,0.308,0.202,0.489,0.268c0.982,0.359,2.045-0.082,2.375-0.985c0.047-0.127,0.048-0.258,0.059-0.388
            C115.907,404.233,113.734,403.838,113.051,402.497z"/>
        </g>
      </g>
    </g>
    <g id="chmury" style="position:relative";>
      <path id="Kształt_3" class="st580 right-cloud" d="M772.837,145.213h57.659v-2.417c-0.004-8.04,6.396-14.621,14.433-14.842h5.758l0.327-2.038
        c1.74-10.896,11.983-18.318,22.879-16.578c0.755,0.121,1.502,0.284,2.238,0.491l2.267,0.598l0.692-2.209
        c5.162-16.043,22.352-24.863,38.394-19.701c10.866,3.496,18.872,12.764,20.751,24.022l4.816-0.732
        c-3.215-19.289-21.458-32.32-40.747-29.106c-12.279,2.046-22.582,10.391-27.135,21.978c-12.899-2.352-25.405,5.729-28.562,18.455
        h-1.681c-9.78,0.209-17.93,7.553-19.152,17.259h-52.939V145.213L772.837,145.213L772.837,145.213z M1019.508,145.213h57.66v-4.821
        h-52.937c-1.223-9.705-9.372-17.048-19.151-17.259h-1.684c-3.154-12.728-15.662-20.81-28.562-18.455
        c-5.266-13.618-18.406-22.559-33.006-22.459v4.821c13.294-0.094,25.094,8.496,29.088,21.177l0.696,2.209l2.27-0.598
        c10.622-2.974,21.644,3.227,24.617,13.849c0.206,0.736,0.37,1.484,0.49,2.239l0.33,2.038h5.761
        c8.037,0.22,14.435,6.803,14.427,14.842L1019.508,145.213L1019.508,145.213L1019.508,145.213z"/>
      <path id="Kształt_3_1_" class="st580 left-cloud" d="M88.583,63h57.659v-2.417c-0.004-8.04,6.396-14.621,14.433-14.842h5.757l0.327-2.038
        c1.74-10.896,11.983-18.318,22.879-16.578c0.755,0.121,1.502,0.284,2.238,0.49l2.267,0.598l0.692-2.209
        c5.162-16.043,22.352-24.863,38.394-19.701c10.866,3.496,18.872,12.764,20.751,24.022l4.816-0.732
        c-3.215-19.289-21.457-32.32-40.747-29.106c-12.279,2.046-22.582,10.391-27.135,21.978c-12.899-2.352-25.405,5.729-28.562,18.455
        h-1.681c-9.78,0.209-17.93,7.553-19.152,17.259H88.583V63L88.583,63L88.583,63z M335.254,63h57.66v-4.821h-52.937
        c-1.223-9.705-9.372-17.048-19.151-17.259h-1.684c-3.154-12.728-15.662-20.81-28.562-18.455
        c-5.266-13.618-18.406-22.559-33.006-22.459v4.821c13.294-0.094,25.094,8.496,29.088,21.177l0.696,2.209l2.27-0.598
        c10.622-2.974,21.644,3.227,24.617,13.849c0.206,0.736,0.37,1.484,0.49,2.239l0.33,2.038h5.761
        c8.037,0.22,14.435,6.803,14.427,14.842L335.254,63L335.254,63L335.254,63z"/>
      <path id="Kształt_1_kopia_3" class="st580 right-small-cloud" d="M1137.34,30.336c-3.797,0.023-7.46-1.395-10.253-3.967
        c-1.99-1.587-3.552-2.82-6.909-2.82c-3.357,0-4.919,1.233-6.908,2.82c-5.817,5.289-14.701,5.289-20.518,0
        c-2.002-1.587-3.576-2.82-6.933-2.82s-4.931,1.233-6.933,2.82c-2.795,2.576-6.464,3.994-10.265,3.967v-5.285
        c3.369,0,4.943-1.245,6.933-2.82c5.821-5.289,14.709-5.289,20.53,0c1.99,1.575,3.564,2.82,6.933,2.82
        c3.344,0,4.919-1.245,6.908-2.82c5.812-5.289,14.694-5.289,20.506,0c1.99,1.575,3.564,2.82,6.909,2.82s4.919-1.245,6.908-2.82
        c2.791-2.574,6.456-3.992,10.253-3.967v5.285c-3.357,0-4.919,1.233-6.908,2.82C1144.802,28.944,1141.137,30.362,1137.34,30.336
        L1137.34,30.336L1137.34,30.336z"/>
      <path id="Kształt_1_kopia_3_1_" class="st580 left-small-cloud" d="M74.423,195.735c-3.796,0.023-7.46-1.395-10.253-3.967
        c-1.99-1.587-3.552-2.82-6.909-2.82s-4.919,1.233-6.909,2.82c-5.817,5.289-14.701,5.289-20.518,0
        c-2.002-1.587-3.576-2.82-6.933-2.82s-4.931,1.233-6.933,2.82c-2.795,2.576-6.464,3.994-10.265,3.967v-5.285
        c3.369,0,4.943-1.245,6.933-2.82c5.821-5.289,14.709-5.289,20.53,0c1.99,1.575,3.564,2.82,6.933,2.82
        c3.344,0,4.919-1.245,6.909-2.82c5.812-5.289,14.694-5.289,20.506,0c1.99,1.575,3.564,2.82,6.909,2.82
        c3.344,0,4.919-1.245,6.909-2.82c2.791-2.574,6.456-3.992,10.253-3.967v5.285c-3.357,0-4.919,1.233-6.909,2.82
        C81.884,194.342,78.22,195.76,74.423,195.735L74.423,195.735L74.423,195.735z"/>
    </g>
    <g id="znaczniki">
      <g>
        <g>
          
            <image style="overflow:visible;opacity:0.3;" width="375" height="374" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXsAAAF7CAYAAAAzPisLAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAR2FJREFUeNrsnQ2P86CuhE3a//+L
    34arI53VrXICnrENIaktrXa3X9tN4WEYjBHJyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjI
    yMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIWCtKXoKMbMd01LzkGdlJMjKy
    jeaAkJEdKSMj22EOBBnZyTKyvf1q5ACQkZ0vI9tWDgAZGdkhM7It/dICbQ4AGQn7jMe2nbu10/rQ
    v5WRHTYj20u2xQXAnODPSNhnLNU+st3NgXTCPyM7Xca0NlF+pM3WH3u9jIR9xo+3g5Jtcghs60Lv
    JSNhn/GDn3254O+v1m7rBc+tC7z3jIR9xoM/7zLhOU9rl3Xw41ebOWQk7DNu+jmPgnV5SJsdrc7r
    pPeT4E8IZCTg3Y8tE9776PY8w3KpF7xeQj+BkPFjn2kkkCMHgCv8/buAPcGfkbDPzzH0ueXi+1dv
    r3XSY+qk95HQT0hk/BDkPYAecd+qbdYL2BH3JfgzEvY//Ll5AW+5L/o5K7ZbK3Rr4HOuBH9CP2Gf
    scDnNRvwZdBjfwX2ox6b0E94ZCTkh9xeBgwCUe22BEPKAvsZt10F/oR+wj5jAchHKHUr3D23Wa6J
    t31HZbpEQrwaX8c6IFgBnsBP2GcM+myskB8J+MgBIHIQGDUAeC2YGvz7CPAn9BP2GQ+E/OjfPQMC
    c63Ya+rZ2YpC1Av7KPAn9BMsGTeGfKSK9wB91mAwqz1fqdyvAv8qm7oyEvY/9zmMgrwV2uh9I2YF
    6LUboeytCn4E0GvQ30/oJ2QyHgL5KJXO/jxyELiiHY9U7ZbHeV/LOxAk9BP2GTeE/Go/rwb8SK99
    hZ8j1b8F5An8hH1ec/Hnu4+GeuRjPd7/yrBv3VfB20Y+1jsIaLBOlZ+wz2sdrOYjIW+5P/q2SNhH
    V730wB4FcTU+PmJguBL6CfyE/c9c50jII/eNuq0YHjsD/FeoeRTE1fAYz2AwG/qp8hP2eX0NytQC
    +REQZ0BveS0W9FHgt2TaIPdZQM5+HzlDmAn9BH7C/mfUvAfyqBXjuS8K8h77iIF+pLK3gj0K6qMH
    gJELuwn9hH2C3gEzFphWhT4T+qsAPwr0oyA/ciYwKqUzgZ+wT8grt3l9eM93D/RXsHgiQM8o9hGq
    XbvN8rrWgYFV/V7oJ/AT9o8AfYRlEwV5BvSWAWCU3aPdN1LVjwA8A3bPIOCdDXitnlT5Cfu0bAgl
    OxruHvCPUv93hn0E1KN+jraAZlg7CfyE/aNAb8mqibBlNNBfMRBcZeMwEItQ8MefIx53FfxT5Sfs
    f+rajVLzV8N99ODAwj4yBZPJqY9S9BqgPY+1DgbogOW1eVjln8BP2D8C9Kwv74U8AvLRz7PC3pKh
    M0LVW735CGiPfB4zO2HhH63yE/gJ+6VsG4uaR1W9BfKt29jHel97hqUTqeyjrBsU0gy8o25HbSev
    tZPAnxjvvAS3UfMW2FvAjdzvfY2rgO9V91Zlb1H1lbxfe4x2X+nc5r1+pfGa5XCtSgPcyPsoCf1U
    9leCnsmb1yDGWDWsavc8LnIw8EB/po2jQd9j3SBfI5/D2D0eiydVfir7x4I+KruGtWqi4O398ir9
    1m2ta3kF7CMsG+uXBL/WmdL3qn1W5ddGv0JUfgI/lf3loPdm14xQ8ZavbdAA0Prf7gZ7TdGzkN6D
    B4eIgQQd7Eao/FT4qeynDoKjQc968V7Ab8GP3cS/FmCFPVMumq1yaYG9V31vRvBHDRJnylkrdFYc
    Kl+bTaTCT2V/e9sGVfMRyn1z3L9NUvvI9dEGVqatezZRjYL9HnT7qBmC9v8zKj8VfsL+52wbRs1H
    QX4z3rZJnNXT+5+Ra8cofBQQ3s1TFtCjIN+Nj7HOELT/gwW/KIOANgAk9BP2twI9o+ZbcNyMkN8c
    v28yRu1HqHuLsu8px5mqngW49T4U+uigIEbwI8o+VX7CfnnQt24boeYZ26X1Owr3zTEAeBZuvXaO
    R9nPUPUajKN+jhoIPCo/gZ+wvx3oNX/eU84gwqLZCMhrEJ8F/Ej/PsKzjyiHYIE9A3HL/ZYBQLtP
    hM/VTx9/cLwT9JeAvpdlgwIf9clReG9Bj2FhvwH/rzYwosBv3a75wBF59ZGw3+X/s3P+fv7+/p3p
    8v1z+XrO2W1/v+/A//H9HrTsHQ26pXN7L2vn7PfWbWJ4TML+R0CPgMLrz49IlbTCPeq7Bn5U6QsI
    fQ34lhkso+49Fg6ycNpT42eg74G/Bf39APnSgX8P9LuzT/beK7sBK4GfNo4Z9CP8+QhPPhLuEY+d
    4eHLQOBbbByvfbM7YH/8vg94rGXRN2Ixl/Xx09JJZT8V9Gw6Zes21qbRQM+Cm7nfYu0g4BeHnYN+
    ligorJAXiVuI1cC9nfy8NWyboqjontrXZi8WhV8b9ppm5RRA4WtA/wmF/07QDwd9hG0TCfion63Q
    j1D3kb49krd9tapnVXoL+Ef4nw0E+8lAcIR8z9dHLB4Nrj3wi3C7b9G/+XjgvxP0U0HPwB5ZaEXV
    +vHniPtY68cKfYu6R4EvTtBHevVI9owG+yPYe3BvKfwW+PeOupcG6L8Bv0nMAi6q9sXo4yfsf3Ag
    GAF6j2WjKWcN2hG/syqfsXSQ7BzGu7cAnz2ZKjrVElX1Pdgffz8OAKXz+xn4W8Dveewa+FnIMwu3
    ngqdjx4M3j8M8xGgn2HZbCDoUXijz2XtHU3ls3YOA3o2397i11tVvde+0aybM9ifKf7SAP0R9mfA
    l4bK7yn8zeDrjwD+z9o57x8HvSwEektWjQXi0fdF2Dk98PdmRyvbOGdK16vsEbi3YH+m6vcT0J/B
    v5zcdqbyNYXfs3VqZ9BN4Cfsw3362aC3+vIomJH7LY+PAL5loRbx6+9i47DK3gv6FtxLR/UjwJcO
    9M82f3nz8a3AZ4H+OOC/E/ThoEdgPwPyli/PwIACn/Hv75iNwy7OIspegz4DenQA6AFfFOj3fH2L
    l48s0jIpmj8J/HeCXr0/EvReyI8AfOvr5bR1Iqwckfm7aCM2VR3B77FwrB49+/UhZ1k96B8HymMa
    6Dfkt8WBn8r+YQNBGQz6HtgsC68ecHsfzyzcjlykRUE/Wtkzqn6khRMBeMsggCj9KHvnCuA/ZjB4
    /wjMVwI9kjo5GvCvwIEhwsphFmitFs4Iz15T9iL+HbOIhXO8/UOAHgE7A305AX3ruu0HZY9AP4Gf
    sF8O9Kxlg9o2LwPkPbdZvH6PlRORemkBvQb5K5S9N83yo3j0PfifwZ2xevYG+L+trZ6104P+KOBr
    bKkJ+3v49CuC3mrZvMDbX+B9FrXPbria5dkjoC8dNW8B/gjPnt041YL+B4D9p6Hgz6CvAV9Aha+p
    fM3PHwH8Ry/Yvn8E9GUB0KNZNlab5uX42aP0iwP43pIJFr8e3VTl8e2tBdDY2jfft39I4Bfl5x70
    Pwroe/bO9zVtlWZGF3CvAH4q+xsNBKuAnoU9C3f0NlblR3j3UYuzzBm0miiIOMAkwsqxLtBuHc/+
    DPg9wO8A5D8A6Jl1kTP/fiTwe4r9kf79+4EwR0EvF4Negz1qu/Sg/jLAHrWIRto4Ir5snCjYs1aO
    Z2MV6tsjNs6ZZ38E/hnsj4D/KJD/KH1ERF/I/Xtvmq0TBfwIy+aWwH//EOgZwI8AvcW2YeDc+46q
    fYvKZ22cCL8eUfajbJyesu/59pZql96Uy60D/O+fC/j9CP/ebEsUha+p+20A8EV+OEPnV0scowr/
    KtCztkwP7AzsrXaONRtnxGYq5rOuAIAY717z77WF2t4iLbJz9gMAv6foz75/wO+9Abhn7xzV/ijg
    i8Rn6KSyX9y+YY8T7E1JI0Gv2TSsgo9W+B7PXoO9ZuGwfj0KfMZTZnz7lsLfCDvHUiKhlXJZGvBH
    IN8CPzIb7qn7lq0zCvgWtf8odf9O0IdvlmJB74G852erb19I4M9YmLWo+igbpwd5xL8/qxWjWTma
    nbOBfn3vewv8Zz+3VL5n0PUCX6R9MPlPAv+doG/aNtIBfQkAfSTktd8jYI/UyimAsveeP8sCn4VM
    T91bfXshQL+Jfr4s4tt77Zvjz0fIC6DyvdD3AB/18VFY3x74v1wbpyi2DVt73gr6lqWCQt4KftS3
    RzdVeRZmRWz1cDygZzz7HvA9mTk90G/Cl0zYg+0bbUNVawBAZ1VlEvBF/DXx07O/mapHMm+uAD2z
    0PoKAr93kRaFvaVEgsi4SpfR2Tgo6EXx61tWzibYWbOMurcq+iPUv39vKX1kAGCAX04gj2b8tA4s
    b4mAR9k57x8CfU/he06WigQ9a9V44O9R9lGqvqfsWRvH6tf3gG9R96zC3xrg7ylaBvhn9s0m5xuo
    WoeUtOrkSOM2j1evAV9OIM+USW4B/fH+/S9tqkLgwJ4sVQaCHoE78jiPd48szrLlEVq3acBHIF8M
    bcOTfikSs0hbD5CPAv5H+mURUNj3SiR8Gtd2BPiPwGfVvabyM/VycaAjj2Uybxgr52wR0gp6Btov
    4yCAwN6aXx9RByfCvvEuzvYUPWLjCAh6kfPUy03OD/xggX9WwrhV90azbnpqHhkASiALjmfpnvn4
    vdkamqHzOHX/vjnoPT695tWLYOUPmNRKK+hfAaDvzSa8O2dHFDyboeo1z56xclBL5+y2Tf53w9VG
    Av8bgL1a9cjCbEvd99R871p/BnDhzMdH2oC28eqx/v2TNlVZfXrWq2cqVqKgZ2H/dnr3nsVZZLfs
    zEXZGcoetXGigV8U8PcOC0fsHGZh1voVBfp6ss7TUvno+oym8pf34Z8E+xL0fI9Pb1HzLeizoH/L
    GKU/0sKZad+MAD1q54wAfjF4+C3oa4eSaEXOUNtmVPSu/d6APrs+gKRl9hT7rdT9+6agt9o3lqyb
    3mJs6z4krbIFeg3w7wGgZ4BvWZC1LMpqwPeCHlmgnQH8egBW7QwAZ77ydqLqzypNnoEeya1H1PmM
    QaB2/PuWuu/ZdaUB/Uj/PpX9wEEB3chRgAa7Ab9blH0Lwm8D4Fk7p+fVM7VwtB2ybJqlZTE2AvQS
    CHzvoeSlMRCUjq1TTuwN7dDw3qlUVgtn1Myc+Yx2pY3UDuC9/r3cQd2/bwBwSwOylEEQwTZRWWyb
    M+huBpijAwLj2aOplz2v3lP/RmRMZUtG2Wt17otD3bNWznHDVemA/8y776n7M9B/BNstixw/WAKh
    37qWPQ9fpJ2hg+yv0PLvLVBfBvh330HL2jdWCwe1bjQ1vxG2zdsIfatfzxxBWBzAF/GrelTNF0Ob
    QkBfAmDfg/wZ3L/Bf6bq68m1PztDtgd6xLNvzYajFL2W9ipfs5szOwfdXa193o+zc943AHq0fWNd
    kEVTLM+gvok92+atQP4tvg1VlkXZiIqWQnRQj09fHNBBQF8aPzMKXwN/S+HvDdgfVX7Pzjn7LqLn
    zSOQj4R+T+Wzls7Za7QycyKU+hIDwftGoPfaN70BAVH13sPAI0FvVfZsfj1yriy6KBul6D1+fSEA
    4wF9maTwz76fqfxe8bJeZo4QFs5Ir743ePb67Q7692cePmLn3Erd33UHrcW+EVBhogoVAT6bL6/B
    nbVzvNUuZ+2Q1Ww3FvhW4PSKpFlA37MBGJW/d1T9Wb58FT33vYDK/qOIJMs1rx2on61poD7+mbpn
    /PsW9CPsnMsHgvfCQPc8x+vRW1X9yzEAMKBnlL3Vr/ceHj5iMdZrGxQDhEpH9aOg96r8cgL+M9BX
    0Xe/asAXwXLsI5V8/W8bRNQ9o/A30NLRoF8DGVcT9jZVP8K+GanotwGgfxv8+h7stYqWSE59FOi9
    OfXF2daqA/qoyu/93AL/GfCr6MXMWsA/XvMz6EdcX6SwnCgKvxIKf1faWQX+N/aUq2XtnPeiQGee
    E23fzFb0LyPoEXWP7pptVbW0njwV4c971HwJbpcI9M+goIHeA/xWhg4DfAHVvci41Mqz2c5rAYV/
    9vlGQP2ygeBu5RKYabq13o21Jg6r6JGcecS3/74NKX4WkWbJgB716aMUfXGAnOmgBQC+AKC3Ar+n
    8r+96R0UPC11z4JfgyeaZaN5+JEKvzWgy8mAbWkPqezJKbj1DFnUvunVXh+p6N/Kd3aBltk1G1kO
    YQXrpgS0O49KQ4HQswLKAWQa9FvgO1P5ovSXAoA+8jr2smwQD//4/29iy9Dp7Z1AB28G8JcMBO9F
    QR81C2Dtm9bhHOzB4ZFliy2ZOL3TqSw+fdRirAZ8BPpauymT26B1hyV6WpIG/Z66byn7XZmBoaCP
    gLr2XftqDXZHVb6BCr/3/llIZ7kEp32DqHqk9o1lQRaxcJCaOKzKtxRDG2HfsOmVVp9eg3008BFY
    FfA+1M4RAPTlBGwFBF7LzmmtpzCDahkI/z9Vf6buUUunVw6ZsXNalt8tUzGfUi4BWZRlgI8AnrVv
    NrFXsrSkXVrTLNna9CJz6t2MKIeAAEkI0GsDgyi2DqLyzwCxdcB/puT3jhjSFC+q9tFdxGeQb6n7
    l2LpfFs5IvyCbeuzu2WVy5Vgb/HqBQBERE69Ffq9HasR9g0LeouiX9Wjj1aa2gIts/FGOt5vIVQd
    oxStHr6m7rXr/AEhr8GfGQBQS+fMwz8Lq51za3V/J2VvOZnKMwgwql5T78zhJBEHliCqvoAWjgZ8
    cQB/BTXPdPDecyzVMxlfHwH/3lD5Z+pUU/eWfuYBvKbyGegj6v7YLqvw+fdZLmFBVS/CZ94UUNUz
    Rw96DiWxqnpr2eJWeqXImN2xrG/s9Y7Z1EvG6qkG+4dRgaVh6WwNldvz70etcSAWDgv+488I8LUM
    nWNbRQZtkbhUzGkDxFNq46C7ZEX4zBsv6C0ZOlZ1H2nfrHic4AiP3lPPRSumdXZbJf4PBCwtW+db
    5e9flsYZlPZGX2KyVV6da9XLoz/z5ZmfUeCfqfxehk41fDZLq/v3AuCOUPUitjRLpLxxJOitvn0E
    6F/Cp1kip0xF2TaeA0iuarc9e0croCbSrq0TARaPncOq+FfnuvQsnFaK5QjglxMPH83QiVL3jIX3
    k8reouo9i7ToQqwF9Cz80YGDrVWPLMZuxADKQN5q28zOnxfCv7XYOkjlTBYQx4JpR/D3QGy5hr0M
    GVE8eK342yjgR/n3t6t8+b4Y3KNU/RlgLAeWWOvWe0HPLPRaDiXxZt6g9g3q01uV/Qx1X4hBoBjA
    z1TOZOC/nYB/UywdFPDoAICkU/ag/ToA+9W4PQr4lfDvo9X9z3v2s1Q9at30ioj1TqSy5N73PH8t
    3ZMtidBbsF7tKMGyaNusRvB71D0KlO3Ex9f8+whLR8unR4C/NeC+keoeBT6q7rWTrVh1P3xQuMux
    hCNVPQN65lBx9jjCzXEfe8yg5QASkXHWjQbxMrBtMaoVfW0W/B51r8HjqOy/ob8ffOxKXpujFfPq
    KHwhrRsN+pq63w9tGgW+gOreUhPpZ5R9cd4eoepb4PMcQaiVS7BaNVoWD3KsoPUcWUu9G0TNr1zj
    Bn2NOgD8qLpHb2tZOvuJh/8N/CP8mWvwAqwcxG/fTsDegn5L3e8nAwAK/Cp4dg56bu0S6v4OZ9CO
    9uoLAX+0LrwG+U1sNg+yW5dJs4zYIRsJ+dlVK0fYNxbwI9D3LNz2oH98Xyjwe2r/1fiZXWDtgb9l
    7+wdH78Iln9/tHKs6n6pNMzXYqq+B2wRPWfeuvvVU7Xy3flCHv86+VmrcmmBPXsACQL91vdeFcXe
    jAxpR0XW8e3Z94T+/0g/QNdFtH6nWW3V0K+Pz6vEYCEduwgpC4EsIGuPZ99vcbLucTaOZXCYmYGD
    QJKxS9jFV20HLrIoa9k45Sluhqr5mZukVpmZMkfetdShV+X3QvPvvw/sjlD3LTsHsW405X+2cKt5
    98fbrOpeBK9zhLah+nTYM4eHs149AnqLd++pXYPsstXWBLYBoPecMIUqmCszbpgOOxr8yLGHzNm2
    Ithi7jEHvwV8VB0f1TcC+p49w66XtWB/ZumcQf4M+Ix3z8yEHp1nb12YneHVM1UvGUXPLuiih4Rb
    rRoW9OjOWFbNz6pBX4KfUweDH4U+crYtAppNAb6m6FsHhJ9BX/PtLYoeUfg9757NzGHVfa8iJgL/
    IYPCnQ4ctx416K1oyQDfqu5bh51Y3lsJ8OcR0K9QzGyWzVMCB4BigD6i8o/vTbsuPeCLovJbNW5E
    sKycnpXj6ZM9hY8woafumaqYS6r710UdpShqvgzy3yPtGXRBVnu+VrP+Lf6dsp6SxaxPzxaps7Sn
    FRZqve9BuzaiXFcRX/lopB+yMxNmYKgk9NAa9syCrWWxtjq4d2m8JnQIxIcXBfIicZuikCwb7+Ej
    b+BLO2P27LZe5s1L/JUsWZ/eml5ZJoN1ZfAzGUpMv5lhrUVkwDCHkqADwdlgog0UCMzR/5epHDrq
    6Mdbwd6j6j2bpF4BgGcrWqJnz16RU4/AJDK9MkL93xH8FpXPflYI8NHqj2y2Ua8+jlXRVxDiFXh9
    AdV9HfCZPlbZMxYOAvzeQSNH6FtVvXZ+LJpT/+5YNuwh4iPtGwb00Y17dRU/EvxelT8K+IiVg9o4
    jOquDvBrAww7MFjtHI+6vw3soyycI4B6J0x5PfuIevOIwkc8ekbVv4Q7NBytedNS+gxAvKB7SrDX
    AlX5kcC32DmohcOqe8Sb96h7bXMWauPUgfAO7Q+rwN66MGspYobsmEVUPXN+7FuBumbfjFL1Inyp
    Ys3GYRvq0yFv/X/R6xoF/Aqo0WK0NywHmEjndsvh4wK8PvO/1EAO3t7GibBwetC3VqvsFRTzKnoN
    8qiKZw8Q11T9GfAtoNcWZSNg9kvQ9/QjZt0LsRIi1X0FIInCW8DBwWvnMCBHB4aIhdrlYX/Vwqyn
    rAGq6hHQaz6+57Qqi6r3FDiLKlN8qar5AZVfyH7m6c8FAB9yNKFVpUcAXxRbp3UfMqBF9oOwvvJe
    pMFr00XteczA0PP6Nf//BdpClmMN2bIHUV890COd/ZKG+0DoW0vjIgeTn23GQj6P741XrUO6vytn
    nm1oOtakOSubcFYbZ2/0m73ze+trl/Ndwmz/qCf9pbfrmf18H6PsIyycXg0bJN1S28XK+vRWWwZ5
    LFNW2ZJmKQavvvV7gn6uyrcofIuwquRnaMm7j1DuFoXvsXOY/PxlrJz3oEYbYfOI9HdLavndnoJo
    W+AXU/oAmXWgsxYr6AUER6r561S+ReG3ary0VH7tgGprAHA7QLRVm2aT82qXmpL3qPtW/Z9dbDNi
    dJZUAz7vR9g4FgsHWby9AvSaxfMSrHRyhAdflMES8eIT9Nf3izoJ+CLtgmsCzMBbdk6r5PB2AHRV
    +tUutvpQZ+LpDPhnh5xYgD8K6iEDwja58bJTWmRnZwToLTtuX+JfGO5BH3mPCPC1gVJIvz5BPx/6
    kZZObxc0s4lR+73VRiOE1MvRnzziSTqCCS07cZmV874A6t5/sneBraBHjyUcVfZY6zS9uvvsTAfp
    /NEAyhhn63gVvoheJbM2Bodjpcyz+vCIlbMH9B3UxinCL9ZKQ9VLkJUzJbaLGzFj4RRSlQgAbWTR
    N6KRIXZRkfg0ysjTphL0z1T4vf7E7HtBVH7Uulhx9kmmbzFOw0ihW+4A+ygLR0ALx6rwPVAvBrj3
    nufx6rWZjyTobwv8EgR8dKZssUoRmDJ9xDo4eKworWzIaCvnEco+2sLx+vVahgvbKLWDRNCOUDpe
    Pbp+ofn0DBgS9PdQ+daKlmgWm0jcehiaIIEOAkwftVaBFUWAImJ1hECeDntLYSWvhWNRIejCbBFO
    9VvVB+PfMwXNGJ8+Qf9s4AvYBhg7xyuoouxOxA7tzSys6ctXWDm39ey9yiQiUwfJGGDq76ANUfvb
    Hr8eHSwjppIJ+nsAnz3vmc3k0qBeiP5gyY5jz7RgIY9clxlWjqu/bRc2SFSdI96iJ91S883ZqSfj
    w1umlyJxGThWSCTo7w181L+3WDnMupNHTDELwJrQa71PpK8JMGAuUfRvW6BhsnVwxAi8aFWPAh+1
    aryHgqMNMcKnT9A/B/hIGWQt282zoxsFPtOnWHXvzXLTrp2HecvBfrRfj6h4TZlYVT3aIJGpaHQx
    MxF944cAnTpBnwMGYuew0EfXxtC1Kqu692TmCGiXLm+Lbos1OMSCYJ5rUR6MekDz8pGGj0x9hehs
    zPQ9I+0cUSwJdh8H2ueY/mTpn9GZOUKITVGU/lTffruoAbJvPHphthAfPjNtRAuVbeKb+iILQmgt
    +lT1CXxmxm0VH6y3z6ZZorvOvdk2jDXjSb+8rbKPmNqgULMq+mJsRIiC8G6SsnSstG8S+OxjmV21
    VoWvHZupraUxfTQirdJSZHC2GF7SxrGkemmqPQrymtpAsnGK9GvLbxLnzZeJcE7Q/6by96Rhojn3
    FptzI/oompDhVfdM37ykr20LgQCpMifkBbacWFXIxqQtzmoNWTsjtvd/zlL1Gb9t5wjZxqy+PboJ
    q4gN+L1BxLJ5yrJGFn028OXKfoRfLxKTc6594FbV4C0Jaz0bFrlmad8k8L0iDLVP0T7I7mz39NFo
    G4c5I2KEb296nW3xhoduAhpRM4f1Gq2bpJjKe8j/GtnhE/TPA360ukdmnRLQFz2zb6+FisD8alG8
    BOwZhcmCHL0/yq9HTrqyqPje/yHC1eGIVPUZqfwZdY/exu5wt+7QHZHxxvj2S4msbZGGh2zmQKeR
    0Vu8LZ5gcTRIEV45parPiLRKUXUvpFCxzqbRmfKMzJyeTYM6EiIX+PbbQkBA6q9r06velDPyCEP0
    dKkRPiCyi8+r6hP0v2fnWPsnWrrDaotaRdmI9Eq0MBorZqfENrFxWR+j5dd7VIX14IXIkqmsVdVT
    FFY1l5EDgefISqtv32v7SOYO0k9Zdd/rW8ighkJ/+sx7xaqX2tSR3TXLWDhsTR3UX4zaSIUorMgp
    fcZv2TmMwrdWphWJX7yNnlmzs25WqF7y+c3eQeutxChkw/Ku/kct9HhOsGcVFjorStBneG1AxG6M
    tFG990XNtjW7iulT0/rctkDjYjdTMQePy+CG5T3txmLhWI48S4hnRB0JKorIiu6XW0Dfi1pDs86k
    Ldd+2RLHM1QGe/D4LGXRa5QifEql5VSpiJX9HBAy0HagHdSBzLhlcD8cUfyMSQ5hrtkI6ycU9uXC
    BsdstlrlS8CfUUsmF2YzRqv7EVaOCJdYIYv0WcbKYWZD02Kb1JissEFz8K3lfyNVgsfnE4OFM+J6
    Z2QwgwKjUL31nkbMAtC07Vm8Gyq4t5s0NCYDhTlogLVarKVPPfm6kRZOLsxmRG24Q6wcIfqBkDMA
    T19EZhcisfn2M0XydNhHnJDDeIbRKZmjbBvWE4y0cDIyrGq+BPZ5q5ASibVjUEZYObRU/9wubkTe
    TBxNEVt21o4aBBgVjqqkJRRDRlo5RrtGxLeH5mrfnh0MLu1/2w0aGlIzGq1dYbVwWGXQel32byP2
    ltfCyUiQR/VTti2ifdqq1nv9rScIe+xBhSUqXqfN2LeHNVh2euaZ1rEplOyWbHQKnRDPuMLK8R5r
    iPYjMYimCBawMxBEmCIDyLA+vQU3isgGh/r6lq3MyEhvqaqJqAoRfWFKSIWfFk7GlVYOq06ZmW2k
    tcOo+yIPmy1vExqG9zUZnwzdymypuc0oeFS5aJ2jPLnxZTzCyum1YXQnLdLvmY2H7HqBJhx7TGBn
    RI+AfUQDYxcfrWWPrUC3ZNn0pm7o2ZXMlDkHgYyZal475AMZDFiLxOPZj8rIKYt9XpfDfuaAgY7W
    YlDqyIBi3VwS0YjSwsmY0f8si5Ca+heyn3lUPStAkQXV6PNmw/rsdnFjsQLKeiQYA28ms4ZR/8gU
    9oqpeEaq+Kg2ZjnRyeLRR9g4iLIX8P/wKP/hfXm7qBGhhx57Ljxad7vXKJjHMMWg2GmuONRCDggZ
    l9oHYrMvLYkNHuA/vg/NOrwEBbW3kVmVvWdxx3ICPZt1s9TmjIwEOQH0Yng8Ouu2pGEis/pIN2GZ
    Pro9qDGiBdIQdc2qAVTZj6hcmWo/4+pBwHJ8ISIGkdktuvaF9GWrm2Ad8Kb2x23RxuTNsWf+BqIA
    mGmlt2N4d9ElyDNmqniPRWPtTxbv33vGLnM9SmD/DuvP2w81YGRUR5U9C2urws+dsxl3HBC8pQPY
    jB7LYMJk1zH9Hb0+1r/3E7CPqkLnVfbISG6pb7NcqlZGxuD2xdg4zAEh7CzdWr2y3KmvbYt86KtN
    Ry2NyaL8mSldgjvjjgOBxQJBBwMkd561eRkReavYnP/4lQ3J4tmjGzkYJYBcH6bGtWWDVebmZ9xh
    VogubnrX4BglPnoH7DIlE7aFGw+7bRm1S6zKG63HgzyvLNwhMzJm9P9i7MPF2HctfXnFkgnmv7Et
    2BhK0IVglL91sShq63hUJ0n4Z1wpGkpwny/BfRSdMZcn9qUtG7m5UXiLk2Vk3LnPMDvDvTZjVCaM
    tX+Wxfs09L62bKjhCsdqV2Vk5EDinwGzQF8d5KnsAz80iy0SaTXlrCDjbrPgESJptLgqN7/GCfuJ
    F7w4Xi/hnZF9iBNpJaDfzYBqxOawVPaLTrfKgEaxZOPIyHjIDCMa7taU70tjy0Zyjw8qIyPDfS7z
    KCF3i7gT7Ovhu/X53vvqhP9xxt/KyLhb1MH95NH9LT37eQ0hQZ6RMbYf1gkQr4cvr5hM2N8Y6q3G
    kQojI/sQr9Znz+QjRdxSAm97YAOrExpFDWocdZWGkJGxCDxHDSz15tc4YR88IFTj8zyDTpUEfUaG
    V81Xo4DyziBuE1s2LvOUq95UPWRkjAByD7bV2CejZ9jWmfTqAwL0vrYbNCLPh9lbSEFBX8HnIY20
    BnyQyy8EZdxa9Mx8HWamXQfeV39htr0t3KDOYN2CeaQ6qORzqqFBRQE74Z5x1wHFOnOuhsGhEv26
    9Tykz9YLruNQ2NfFGw7zoaANggG2d/BhFEgOEBmrq30U1tbEhUr0UXTGvppjsZSyrzdqnKzd4m1M
    1qlpQjrjCYMFK2pQkVM7fdOi6tn3eLv++LQdtIhnL2DD6E3htA+f2ek3cuTPASJjZfVfiX6Bbmaq
    hpk6yg2ro5Cwn9wINSWATCMZ5V6BwQBpsAnyjLtBnRE5nhmvpc+iDED7onWmYP17j4E9uzrPbltm
    pnyIykdnAp7Gk7tvM1ZT5xYrk4Wx5XFI/9UYYLlGjM0TsVC9JOzrxNetnSkf8mEjU74a1Ci9YI/0
    IDMyvO2GgSbjyWv9SUBBhih7UZjBqHLLdbmtjYNO46qjgVguHPph9x4bDX3EH7RmASXYM65Q+54Z
    bAXFWCWew4o97X0JOZtfoi9uizSmClxcyyhrUfZII7JMK71WT5ZVyLjrgOCxerR+VoP68+NnwttC
    jYStY2EZZdEFGgb4rUGFURZWayfhnzEa2JF1aixeO9LPRvVjVGBGbLga3pe3BzZQS8kEa0NBGgM6
    q4jIBx7diTMyokqEoOVHtH4WOUO3CEwBeHPFbOpy2LOZMtpjEVXdayCMItcez64HMB3BUpIhwZ4x
    Y+bNrl+hyhoRbqP6cJU1SyW4+vZ2xR8lX9N68ZnGMMrGEcGnn3KDhpXxWxaO9bUQGwfZHauBOXJ2
    jrgA7Cx8qf66LdBQ2AvEwhxV99oIj/p66AxDBNt4FZGemVZOxgyVj7ZXxMq0ePhIP2Xt2MfMmLcH
    N0TkQ9cam0UVIIMIatFE7qRNsGdE9CkE4qgFg/QjUfoUo+pZG8djNfUGvyoXJF5sN2h02gWM8vq8
    qoFpJMw0V4BBIS2ejCtm5tXYFtHNTxZIo0JNcwAQMDOLt8isZ+iZtdvEhqWtXKMXh8mGQbNlGHVv
    HRyY/8OrDNLKyZhl4Xj2sCCKuV78xQg2Ifrx9P63XdBY0H88ahF2hUbCWj2sWk8rJ2OmhYO+BppW
    6YVuxCwgOhlkuX633aTRtUAY5duPVAGoeukpf20m47VychBIqLOvYSkHgtSssm6wsqRFezJxmH65
    xAx8u6AhVUejEqWBRPj2I3169Dmatxlt5WRkRFg4jDixLKYyX7vYLFamj13d/6jX3mb9oUGDhpZe
    ydTNsTSc/eTn3algRLgNWGnlZERDXBMbWn9EhRYixEYIsyjrSOPNUgeZbws1QvQ0KHSKKM4PdDeo
    hV3i0j0lrZyMRW0eSxHACIjvEqvorTN1Ifojcs08/fY2sEcrW6KeoaUh7TJ2imiFv+XoNO+BCQn8
    VPVCtCHvzlmmr+1KH9wH9lfGVRByNjRNfG2TG5r3QG70okdOATV7Zpf4BV2LlZM59xmRA4LXwom0
    U/ag+3aJ3ScjgqVZLzGjXvWkKiQnf2Sj2gErZx84dWStnMiFohwgUtUjAgstWawJsEg7Zw/ujwjk
    UXF7BUeHwz56s48nzxVtTKgX31MKo1QEOj1OqGeMUvXMDNtbNiQqKWI3ijMhLZyrFmfp19pm/0HS
    kvEcEjJD3Z/9vAufpcNaOWgjFKAB5kCQqt7TPysIQVREoQJqJ/rrqJ2z7K7/EdBfxsZBlDqzQUOU
    wYBpaF51P8LGYcu0jtyxl8B/Lug9qp7ZfBQ1q97B/rZP6KvIDHvJNbZt4caI1tKZ6d2zjchq7aB5
    x5ZGtORW7owlBwFNjbIliUV8yRCWvS3RWTmRB5BPFVrbhQ0rwrf3An13NKRdsXHQqam1DrcItlgm
    APBT3f+ufWNV9czMU8Q/09bsUUaYeVIuJdCWmboDd5vc0LyvFaHu9wagET/++Pi9M3Cgit+zkMtM
    EaNmWBnPU+4Rqt67XqaJp6r0vxk+Pru+ptmtUz/fbZGGFZnm1Gt47PQQBX1L2WuvuUvMIhF7sHra
    Ob8J+tGqXiRmr8oOijQL6Fv9M3JXbe+zsFa2vYWNg0yDmMbJpEp5rJ290zhYZW/NyEF90Jmzq4xn
    DALogeCoqmdm1mxmm1fZ9/ooCn0RPCVztLWzNOwjFivYM2VRsO9GJd96jKbsLerG0sGi1X0C//n2
    DXManKZyrRk2CJQZ4I/IlkOVvfezus1JVdZ/xptvb12VtyqIvdOAUY8faZgivoPVWVWXwE/7xnLS
    m3VGjSj7PWDG7bFvmFP1kOdMjW2xxurJt9caGmqlIN5grxEym6ssDY8posZYZhm/C3pN4UcuwCJp
    zjvwO2q1Igu9qPpHBCdqfyGuRajg2iY0NsSW8RxBGJF+iXiDO6DS985XRFE1MfiIaedkWPskumP2
    +DPqw7MzXtTGQdbS0MVhrY95wD21b12xg9Zq94xIwUT8REZJoH4jauVYFnSZHX0J/FT1miplZ5TW
    ypTI2pdFgFVFgEUeZILWw7nkhKsVq15avDEW8Ey6JAp49LGalYPuuhVHQ/TWvU/g/559w+6CRdKZ
    2dmxBndUfKF1sER4QYVY0pfEdnEjRU99GVUaIULdo40VmWKOWKhl7Jylpp0ZQ0Dv7Yfo6W6Id74L
    B3qrFcTsf7EUPovoh8P72zap8Y0eIJgT4VF171EdaGNFF3ORXH1NjWlTd4u6T+CvD3qtH6HFBEXs
    i7VoCeJe9gy7Noaq+shDiEbb2bdS9uyp9dbzLRk7R2sQe/BggKSGWc/AZeqHI6ojgX9/0FsGe2+K
    M5NK6VH8lbzdU34cEZiI4LpMOG8XN06mwXqsHKQ4WTTUPUqEPWhBQKgzFTET+OtBPgL0PaWP5Ipb
    Ksaifc0ze0ZTLL1plldZOMvBfmQKJmvlWLdrj2iAVfDFJWZXoffQhQT+/dU8C3rkM7fm1aPlCjzr
    XZY+qPW/6CycERZOXQ32d7FyLLVtRn6h9XTYnGBrGQVxAj+hfw/Qs0BDSo6gYobpH5+gWbc1tRnh
    UZSFMyzeExtscT7+76IVZwP9/sC3w3fP1+fkdf5u+/z3fX/fV06+f3/Vk/v/bvserHsKo3QgUL6u
    c+u75/NkP/OM+aCXjgU44kjPEcrd4uNbdrJr1nKUhXP7QmjRVg6zq5bNs2Ua0sepStDpK1OeGVUj
    2mJdJaCSts5cfz4K9JZsNsvpblpb/xD95wM8l6k+6ymYFmHhyKx+9B7UUK2q73h7T6nWw+M9PiOq
    8D/KYz6CzxLK4edyUOrft38//luVawc+t6KczJTKyedwvL7S+HwK0FBT5cd17EjQW7x6VJgw61gf
    g1CKWEMbcRYtY+FME0SvQa9bgNtL5/vx53Lymq3HtL5aj90a34vy+N5tG/G+0Pfcu7alcz17n1Hp
    fC691yrEZ84+JiEfD3oxevXsJkRklvw5+f799Z/b/p3c/jm5vfW4T+N1P8QaADJrvo2FM9OzR1T+
    UUUW4nneXbTbQT171TsyAyiHn1ve/X6A7t74v/evmQHTcHrXvirK3zKjS+jPBz2rRpkSCNGZNB+j
    neMtQsjsnkVSMZezM18DXxtRf4i6116fVfioamcV/gY8zvo+EZVdgFmANksSRe1HKfxU+jF+rxX0
    UdVi0R3fn4ayb6n7EYr+A/j8kZusoiycelfYM1aOGAAYaedEWTrMexHSzum9hgDgtwBfe10W5CVB
    71LzjEcvgu9L2QHAozWjjqBFgf8PvA0FPZos4amVs6SFsxLsNXD3/GYRv3IupG8fAXz2faMKu4Cw
    jwR+qvxr1LwX9MyhPwj0EdBrfv2H8Ow9Pv1HuNpTjBVm+RxvbeNEWjnMQq0QwGRtmGJ4zAaqeW2G
    glgsrLoXwRaArbOFhL6tc88E/QhV/wFBv4PQZiHP2DeMhSOgso/aTFXvDntU3XsU72x1b83C0WDd
    ezwK5gKClLXXRgL/KeCPKoDlLXDmLX/ALMhqC6peGyc6+wYteSziL2x2qcpfAfaoukch5fHvo1Im
    tdcT8ueiWDSItWX10hHgJ/T9Hdej5kWwUgiIP4+mUDJZNh+xL86iA8A+UNWjlhe61+WS7JzXhL/h
    tXIEADaj6pHHboMHAQ3gRfRMmqIMeKydY/lcrCrfA/DyIMBHqPkRoEf9+QpAHrFvopT9PkjVsztm
    IxZm61Ngj1o5Iv2FWjEMDoy634TL6EEHCKtvz26eigC+5bVmQP9q+NeBz6/g70yKpYhtk5S1vDey
    MGtR9pGwZzZQMUXjLlPvd4A9oyJnqfviAH2EshfQu7fulI0YiBmVPxr6IweAOum1osogCACt3qE5
    aEGxs5TKj9gWZneDst+dPj0DehF7zSJZYRB4Tfo7kQu1KNC8wN+CBgBkUxW7XoF490XsG6Osah99
    /TIZ1shrj+yE3qJmPTWv2Tdn8EJOR4uoAquBPkrZW+ybiEOCtHTLZSyc1WAvYkvD9NbMiVDlEc+3
    zGbQujka8I+3VeCznLXx6q4ZOREFzRD/N+IUN6byaw/qWrZNFOgZRf+RGPsGqYGz9OE+r4l/y7Op
    h91oNcPOiYB/D/g96CP2jzggbIHujLTM8gDAo4rOU8XSWu+GKUf8AaDPpFruMkfRexZlmcqW1qMH
    61Nhz/jDRVG8iLrvDQ7IZitmtoEOBhq4PZk5zCzK8llGZ+ncbfPVqDx69BhBa2ogCnok22Zv+PZo
    5s0udkXf27zlKYpmUfWez/gnlD0KoQh1j6jjKGXOQh9V6ogXH52dY2mcV6RmlgXhLmLPuvGoeRF7
    Pj1TnvgM3BEpl8j9Owl7tiwCq+ovP1B8Jdhfre5RyHoyaTzQZ2HMevfo4FBIYKGlHFhLbxTIZyzQ
    jsq4YTx66UANPU2KqWvDqvXdAXhtw9THAfoRqt46w3s87Eepe5E4dc74/mIYMISEt3WnLLK4W52f
    9Z0ydWYC3uPRj/Lpv4HIHBW4k/DenQOAxaOPqGfPqPpLLZsVYI928Jnqnt3UxNpFloFGs2W0/5UF
    I/uc6vyMi7FdrAR/a+bFbOvGWg4BrWuDlCzwzAZ2p0dvBb04Vf0S6ZYrwj5a3YsD4shjLTMEBPis
    zYLk0heDJWO1OyzKfZSPXyZB3dqRvZulhLRuWH8eqUMfvfMVXcgdDfrHqfq7wN6i7tHyC1bgo349
    AnwG9pYDYSyfSzECrjo+8ztaOh5vHgX+yMybOgD0+wD4R4L+LP1SG0BHq/rHwn6Eume8Y0ShM9YP
    uxFMxAb7YoT1SOjXoNeOTM0sF4DdCvgz5c768yJYaWI0n76XP/+RfrplNNhHgB6pDaQNotGqvibs
    cXVvrYopih8ecVzgLNijg6XVkvm7jV1s9LaNUVk6RcYVM4vOuIlakLX69B4vftYBJFo+/S5YzX50
    J2wlVf1P2jirqHvG/vEssnp+tqh0dIGXaaSFAB4L/RFHHV5RCK0af/ccPCJGRc/YN97DwT1w94J+
    l9jsmyrY+sqS1s6dYI/42Yy6j/LvEVVvAfxMH74OgKJ3k0lxtJnRyr46gT8q64bJvKkSU5JYOy82
    +tBw5ivap19eva8K+wh1jyp7BPYtK8drGbHgRwY1z3UuJKC0z4axLKLbyiiV7/Fao0ofWGwbz4Ks
    purP4PsvUNUzhc168NfsG6tPf2tVf0fYM5aMgK+BgB+dDRTyvYrYLJxRpzx5Ie05EDsS9JE2TnX8
    z141L+KrSx+p6hn75p/ynQV/b3EYUfbVCXwPoH869XIU8NG67hY7Bx0YRDj/n/nuBT6z4Oqt7VGN
    r1edbSYa9F7rxlP6gLFvvDVvmENGUNCjz0HOlEV38zJplmwFS6uqX2YQeBLsUUvHmqGDzAgK+FwR
    7nBwZhGzOq55JX9mGjTaWZC/eYVasmQhWQ4cYSCPgD46+wYFvVXdezz6nrK3LNayRw0unZnzWuR9
    XJGKqYHXu8DL7G4dreojII16+VFZKCsBf2TpA6uat4Deat8gC7P/CNXOllr4gCrfA3PNuvHYkEtY
    O6vDXmRMKiYC74gZgYDQtqj6MqFRMQ2cOYKtGl4fBX50R7oC9CL2hVjGwmHTLHfRs2v+GSwbFugt
    68YzALSsG1T4LJ9v/1rovYxMxWTtHM2eiZhNMHBnc9HZnO9iVOJMA4+AIQP8Ogj0VfCF6KtB7/Xp
    2bz6SHXPDgCWBVmvfXMbVX8X2HvsHMSasSp873oBqtq96ZcRp+hELEhZga8tbAow/Wbyp5GpvACw
    Rvx4S1plNOi1s2LPfkfV/T/hF3GZk7Gs8GfsG0sfWDIP/7XY+5m9WMvOECwpoJYZBmPfFBLglQQ8
    qlw9u0wth3QIAWUBoYpM5RFFLoIdLOKBfMRibE/NezNxLBk6Vyh67TNnhU013pewn2DnWAcMTw2b
    KLUf7b1rnUBAxY1aPBY7B1nUFOHrnaDKvhqBLyTYdwDykeUQ0Po3/xzQP/t9B+Du3T1rqWWPCqNl
    Uy1Xh70V+OjOWjRjBvHjEe+/DAR+lLKvQYNGdQwerHfPZK9Ylb2mxj2eO6s+d+Hz5i2gj6iDw4C+
    B3dv5g179OBj7ZuVYb+CncM8H/HXmdfwQt6qnmsA7DXAi6NzWUFfJTYdrxohr5XaRQt7WbJsPgNA
    z6RiWqyc3iHnXq+etW+8URP219g5YgS3JW9fq7kjBqBrz68GUKN+N2LpsFvPI47liwa95XV3w+2I
    OrWq+l7teWbH7C5cdcuIwmfexdio7JvHqPr/xHvR91UVe6KAz68nECykh/yfhrN9NaKIGYlXFfTg
    +Wo87qV8375+/vv9+FUP1/bv2nxf6/L1WOuxjiK+4nBFxlTylIGDEzsj6JXzRewby0aqTwD4Gch/
    COVuhT46wxQj6GvCfszA0BoI6gkAtPz0P5DJAWy1A/6IssSM/aF50S8Q+FsH+medajsZUL/hfgb6
    TbhzezX4I8AfCXpkduT186Pq3mjAj7BxvJbNR/qVLtkFWcSC0+yb6MKACfsAdX8G/NZ35kM467R/
    kD9T+7OqLTKwOIJ7A0G/db560D+DfU/prwr8GaAX0gZCIF+lf8SgpRbOLvEHlWigZw4qQY8c1GbH
    luyb29S2X13Z14AOWzrqHy1s9Ae27+9ygP6Z2o9Sl0waoQb8o1XTA/2ro+yP0P+2dPaDktcsnZHA
    j5hdjQY9AixvNcuIzVTRXx7Qe/155LPzgL4m7McNBBY7B13U1FT+ldfgDOxn920nP28A6DVlvwHA
    3xs2jhf4PbusBF5j1qNHrBuZAHoN+NpRgCOgbgE9u7GMXXxHfPrbx+sm7zM6OwfNkkHLKjDvn/Xy
    axCoerCyeMuIIrJUcqwGYGp/g7W+EPAiENZgXI12i5aPztacscB9dzzWoujRkhEeNY8O9LdT9XdS
    9pWEY1HsnJaVgx7Zd7RztkEqvwIDcq9hb6C6Zz377UTVIwr/7/fjgq0InqHD2DijPHvPIeGR1SzR
    XHskvx5V9XsQ3K0evUXFa3WVHm3fPMHGQQYCbeEWsXK+45ihI/K/i5V7EGDOQM/YNr3F2ZaVs3VA
    /zqxb6zA/4Y+a+uczahYKwc98CVily9bKiEC9Ix9412kRW5Hc/ytJRC0xVghZ6WPjPcDod7z77X8
    e2YXaWvRNkrln6mRVwf8jFfPwv6o9nvAL4fbyuHnEuTfi9hr/ltgz5w2hQJ/F3v2DZtu6YE9Cv/W
    8/Yg0HuUPXtc5KNU/R2VfQTwW/YOqvK/1X0k8JkTm3pwfxlgv//3ecefN+W2799LB/xH60ZT9Sjo
    Ry7UrrB5yqPq2c1U6EItutiKwN0Kestu2Z/06Z9m47ADRe+7gCo/AvjM5o16Yu30oL9JO9WyfsH7
    G+Kauv9+bFH8+9L4HqHuEe/eA/wRdXu8qZaRFo4H9sxC6wcccCz+vLWCpfVIzEdYPO8bQ5tV9wj4
    RWxpmVEKn/GIz6CvqXvke0vJt+ybM2XfsnJKA/yssr/SxolenN0lZgMVujhrtXP2yZDXDmZBoN/6
    PKJ9+pqwv5d/3/LuWyr/W917gc9kCPTA/iL8+Z6V0wO+Bvqesu+BfpP+kZCerBxG3V+RhbMbYa8d
    Ih5l50TYNK2ZRp0AeotV+hj75gk2zsgF21YdnToQ+BpcvmF+pvJb5RAYRV8Brx4FfQv2ZxbOcQBA
    gY+oe4tvbwX+6EJnPTB+gu0cBPoWT/4DDFge0AuwhvJzoP9Vz94C/57KHwF8EXwrfqu4mUXRo4uz
    DOgR2J/d1gM+qu5boEdsHEuJhCvz6q2gZzJmLCoeLX0QBfo6CfTp2S+s7lH1b7F1UOCXhn3DbgI5
    U/laVUtU0fcyb3oLtIyV01ug3QBlP6JODlryWmR++WJvJk4U7CMBv4t+tGIE6MUI+kf49E9T9iMX
    bKOBf1T3SF1+RLWc+fatHbLIgiy7QKuBvgV5LSMn2rePhP3shVl0gTbCzvF48FbLZgevi3UhloV1
    fRLof8XG8SzYjgY+ovRR+PeqWZ6p+KM/XxU1vzlBr3n2njRM1LtnbBzWq0eAz2yiGnX27G4AO6rc
    vRukRoGeBf/jQP8k2Fstm9nAPwIJVfrokXlnKZcvYABA1PyrA3jUr0dtHLZeDqvuLWsmV/v1VuAj
    YGYU+8fw95gFWCan3gr6n/Hpn6rsVwd+AdR9Mfr5LcC3oI9snIrIsdd8e8bKYdS9B/hsJo7FxmkB
    jQG+Jdd+d0Kd9eI9an5F0NeE/X2A33v8COAXQuXL4e9vhJ/PQF+rbBmRX29doGUyckakYHpSLllV
    zyh7T0aOFfYRkEezbNgdsiNAL08G/VM9ezZDZyTwz6CPqPxCQB2Bfg/8G2jd9MokeDJxRij70TaO
    R9nvMt/KsQL8YwT7PgjyI0Ffnwz6p8LeMhiMBn4P+j1YHRV+FPgtteqjLZxRnr3HyrlqM9UoK2cP
    BjoK+cgF2AR9wj7Uv0fsnQjgt6C/KfCvB/DXgK/dAP9RFs6sksde3z668Fm0lRNh76wI+atA/6h4
    srL3LNh6gY/EWa33EdDvQd0K/yIxWThMnr23KNoIzz76oJIIK6cGA/xqyF8J+txBm8BXgc/AfyT0
    9xOo98COQJ/Nq7cuznoXaKOzcUYv0FqtnBoEbMZ/nwV55rzjBH169rcA/jf0GaC1zpqth/d2Bvke
    /FvQt1o3oxZnraAvQKf2ZuKIxC/SVgK6URD35srv4DVArmOCPmHvBj4zACCHnyCv3aoJI6DC751C
    1YJ9IQG/i38x1ptf/1QbB1H2FnWPWjvMgNH7WxE589ZCZgn6hH3IYGAFvtfO0cr+VvnfjBVpAL80
    LJ2jvbN3AL9LbObNrMXZ6Hr2HhtHCJVrWbCtAyAeUZFyhjcflUf/aND/GuwtGTqzgd+Cvwb+s+9H
    2BdyEGgNALvgtW+iFmZZVR+ZjTNrkXbEoq0F4KwXjwJ+B66PAKreA/r6q6D/RWV/FfAt1s42Afxn
    cGfAvjkhvxGK3lL50lMILers2ZmWjhfiFsBb1XzPn9fsm2jQ/0TkpqoxwD+DSyXfT+vovh0AIAr+
    M+iXBvzPwL+TsL9a1c8sl2BV9xEK3/MYS1aNJcsmwp+P8Oh/ZiD4Vc/eCnwRfaetKPAvjsal+frf
    fn49Abj2vVeSuQf+HuyZVEvWqx9V+ZI9tCTKu4/M1KmT4b4HwD1BPzCK/HYU42MQ9dj73rqtZ2Gg
    sOyBtgfmjXhMCYC9RdUX4hprn3Ehpv3MYqHX0mFgz4LbAncmdXKkkmerVSboE/bLAF9TqtavzTgA
    eG4bYd1Y7ZsrauNE2DnWRVuLv86AnZmFVOI6aDOlBH3Cflngt+DSug0BPwv/jbiPnQF44O4pi2AB
    /czaOKiVM9LD9/4cqeAZq2ZE/fkEfcL+UuBbbR0hoLjJWOWPplDOWJC1qvlRC7Sjge/5nX2uB/bR
    vnyCPmH/SOBHq/wRnj8LdC/oZ9k3UdAfubvWc9vuhPhO/B8o4FHbRoN+gj5hPx34rI8fofIj4L8F
    37c5308L9qNV/Qx1z/r30V66x5Lx2DVp2yTsb39drlL5IvhGpEj4R4M9OvuGAXwBQBDt3UsAWPeg
    x0TB3WvXJOgT9j9p61hU/kjwj4Y9MlthIV8C2zdTNsEKe3QAGA1wdrcrqtwRuGtgT9An7G8LfMbW
    QQeAq8Hv+bu9/+OusNdAH6HyI4BtLWlgsWki1HyCPmG/JPBHq3wRbjGXgb5ngBDxAd5aFqEEt28U
    9pqqlyB17x0oRPw2DTqbYeGeoE/Y/yTwGTVrUfsajC2wtt43StVHwx61IkZCH4G1OJ4vEu/FWzZG
    pW2TsL/N9fLYOhaLR7svwvJh4Y6+jkyybyKycUYDX4x2SnW8DlvKINKqSTWfsE+VD8KfVf+Rg4AE
    PGYG6K1tHM319mTooHYJe5sF6ijQGbh71XyCPmH/aOBHWTsM9C0DgPX5UbCfpexZlTpK5UcPElaL
    xnuYSDVc8wR9wv7Wts5oa4exfqLgHQH2qxW9BfwWS4eFLwN8z2t7vqeaT9jnNSSAb1GvUdCfBfEo
    yDOD5Qwrx6vwPcC3/hwF94ha86nmE/ap8sWesjkC/hEA9+yOvTvsUcVvGQRYuFve+1VqPkGfsP9J
    lT8a+iOUeTTgr4Q9A0Ev+COhzqj06MyaVPMJ+4S+Q+UjILTA1KP+I79fpeoROI1S+t4ZQJQlMxLy
    CfqEfQIfVPkIECPUf7RKj4D7DNBH2Tkj4B8JcmtWzQzLJkGfsH/k9R0BfQ/sR8wIoq2a4riGFsBY
    oG+F72iFPhLyqeYT9nmdHY+bDX3Pbd7HoD+j12WkleNV/h417oH4qMXWVPMJ+7zWQY+NKsXAquoy
    8bZowEcpewv8rcXUIsHtsZ28kE81n7DPax5s7URDP2JgsA4iV6v5SKXvBf/Ix64C+QR9wj6vu1Gx
    Fsfvq/18d9h7lfXonxPyCZ2MH4f+SGCXwPe4Auy9APUC2vscBujVeH0S9An7jAug7wH9VVD3QD7S
    s/fC0QpgbyZQQj4hk3HTz6IY7rfcFjUbsAKdsWxGt2cm02Qk+L2ARx+TkE/YZ9wc+la1Hw13r4Iv
    g66pJ3tkBPivUOyRkE/QJ+wzbgJ9K6hnK/erlH0ETEcMCBaYJ+QTKBk3+Xw8QPSANxLsUTnz0YXQ
    LI/zWD9Rj/ECPiGfsM+4MfStCjpK9VthH9VuSyCURsHee5v1vSXkHxivvAQJfYeS9ua7Xyk2aiCY
    auB9ddBjRwI+QZ+wz1hsRhaRpliCbn9SWH3uSFhHeO0J+YR9xsOgPxr8o+67K+yj1X8CPmMqLDJ+
    R+17wR81uKzcZiPAusprJOQTEBmp9qfDfdZgdgUMZ8E9IZ+RsM/PdhqIVwX7DODVix6XgM9I2Ofn
    PPT5ZfD7Wq3drjIIREE6IZ8QyMjPe1j655Pb5QxYJ+AzEvYZQz/7K9T5U5T9jFlBQj4jYZ8xpB2U
    bJPLK+8EfHbyjIzwNlF+pM3WH3u9jIR9RraPbHeLgDgBn5GdLmPJ9vL09lhv/voZ2Xkzsu1kO70I
    ugn4jIR9xs+0pZl/+2q4JtwzEvYZ2b4eFgn2jOyMGdnmEuwZGdnxMrIdJtgzMhL2GdlGE+gZ2ZEy
    MrIdJ8gzMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIy
    MjIyMjIyMjIWjv8TYADlEH5ZJJ+MZgAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 1028.8463 399.0136)">
          </image>
          <g class="burgundy-circle click-pin" data-id="1" data-title-pl="Dystrybucja" data-title-en="Distribution" data-color="#660033">
            <circle class="st581" cx="1066.82" cy="436.888" r="25.851"/>
            <circle class="st582" cx="1066.82" cy="436.888" r="25.851"/>
          </g>
        </g>
        <path data-id="1" class="st581 pin pin-label" data-title-pl="Dystrybucja" data-title-en="Distribution" data-color="#660033" d="M1045.697,439.938l-11.872-6.854c-2.966-9.839-12.13-17.06-22.903-17.06H861.758
          c-13.153,0-23.915,10.762-23.915,23.915v0c0,13.153,10.762,23.915,23.915,23.915h149.165c10.773,0,19.937-7.221,22.903-17.06
          L1045.697,439.938z"/>
        <g class="pin pin-pl" data-id="1">
          <path class="st56" d="M865.708,433.617c0-0.216,0.198-0.414,0.45-0.414h5.113c4.069,0,5.941,2.179,5.941,6.212
            c0,4.051-1.89,6.302-5.941,6.302h-5.149c-0.216,0-0.414-0.145-0.414-0.378V433.617z M870.983,443.484
            c2.539,0,3.188-1.729,3.188-4.069s-0.648-3.979-3.188-3.979h-2.286v8.049H870.983z"/>
          <path class="st56" d="M884.446,439.325h0.54l2.449-6.122h2.809c0.198,0,0.307,0.144,0.307,0.324c0,0.072-0.036,0.162-0.055,0.198
            l-4.23,8.174v3.403c0,0.234-0.127,0.414-0.379,0.414h-2.341c-0.216,0-0.378-0.162-0.378-0.414v-3.403l-4.231-8.174
            c-0.018-0.036-0.054-0.126-0.054-0.198c0-0.181,0.108-0.324,0.307-0.324h2.809L884.446,439.325z"/>
          <path class="st56" d="M896.866,443.898c0.648,0,1.333-0.036,1.765-0.307c0.414-0.233,0.595-0.72,0.595-1.278
            c0-0.396-0.072-0.756-0.324-1.098c-0.307-0.36-0.811-0.486-1.585-0.667l-2.106-0.468c-0.954-0.216-1.675-0.576-2.215-1.08
            c-0.647-0.612-0.864-1.477-0.864-2.413c0-1.351,0.541-2.196,1.369-2.755c1.008-0.666,2.124-0.828,3.799-0.828
            c1.422,0,2.953,0.162,4.268,0.595c0.18,0.054,0.252,0.162,0.252,0.306c0,0.126-0.036,0.234-0.072,0.324l-0.54,1.513
            c-1.044-0.396-2.737-0.721-3.854-0.721c-0.846,0-1.404,0.054-1.782,0.396c-0.234,0.18-0.378,0.504-0.378,0.882
            c0,0.522,0.18,0.937,0.647,1.188c0.396,0.216,0.883,0.36,1.513,0.504l1.963,0.433c1.801,0.414,2.971,1.206,2.971,3.601
            c0,1.423-0.504,2.395-1.332,3.025c-0.937,0.702-2.359,0.9-4.088,0.9c-1.134,0-3.042-0.198-4.303-0.666
            c-0.145-0.055-0.216-0.127-0.216-0.253c0-0.071,0.018-0.162,0.071-0.323l0.469-1.531
            C894.309,443.664,895.679,443.898,896.866,443.898z"/>
          <path class="st56" d="M910.331,445.375c0,0.216-0.108,0.342-0.307,0.342h-2.341c-0.197,0-0.342-0.108-0.342-0.324v-9.957h-3.691
            v-1.854c0-0.234,0.127-0.378,0.414-0.378h9.579c0.252,0,0.378,0.126,0.378,0.396v1.836h-3.69V445.375z"/>
          <path class="st56" d="M918.88,445.717h-2.558c-0.306,0-0.432-0.162-0.432-0.45v-11.613c0-0.252,0.144-0.45,0.432-0.45h6.158
            c3.007,0,4.627,0.72,4.627,3.727c0,1.639-1.116,2.863-2.322,3.296l3.025,4.969c0.054,0.091,0.126,0.217,0.126,0.288
            c0,0.145-0.072,0.234-0.253,0.234h-2.916l-2.396-4.375c-0.144-0.252-0.359-0.433-0.846-0.433h-2.646V445.717z M918.88,439.144
            h2.845c0.773,0,1.386-0.216,1.782-0.558c0.36-0.343,0.685-0.774,0.685-1.495c0-1.134-0.595-1.656-1.945-1.656h-3.366V439.144z"/>
          <path class="st56" d="M934.485,439.325h0.54l2.449-6.122h2.809c0.198,0,0.307,0.144,0.307,0.324c0,0.072-0.036,0.162-0.055,0.198
            l-4.23,8.174v3.403c0,0.234-0.127,0.414-0.379,0.414h-2.341c-0.216,0-0.378-0.162-0.378-0.414v-3.403l-4.231-8.174
            c-0.018-0.036-0.054-0.126-0.054-0.198c0-0.181,0.108-0.324,0.307-0.324h2.809L934.485,439.325z"/>
          <path class="st56" d="M949.175,433.203c1.422,0,2.16,0.09,2.862,0.486c0.828,0.45,1.278,1.351,1.278,2.341
            c0,0.773-0.252,1.44-0.702,1.962c-0.45,0.522-1.099,0.864-1.891,1.009c-0.216,0.036-0.288,0.126-0.288,0.252
            s0.09,0.198,0.307,0.198c1.152,0,1.962,0.233,2.448,0.684s0.685,1.135,0.685,2.053c0,1.333-0.469,2.232-1.261,2.773
            c-0.792,0.558-1.908,0.756-3.169,0.756h-6.302c-0.271,0-0.414-0.108-0.414-0.396v-11.703c0-0.252,0.18-0.414,0.432-0.414H949.175
            z M945.753,438.587h2.827c0.576,0,1.045-0.162,1.387-0.469c0.324-0.288,0.521-0.72,0.521-1.26c0-0.433-0.126-0.792-0.359-1.045
            c-0.234-0.252-0.576-0.378-0.99-0.378h-3.386V438.587z M945.753,440.297v3.188h3.439c0.45,0,0.882-0.145,1.206-0.414
            c0.324-0.271,0.522-0.685,0.522-1.188c0-0.486-0.162-0.883-0.486-1.152c-0.342-0.271-0.846-0.433-1.566-0.433H945.753z"/>
          <path class="st56" d="M962.314,443.718c0.792,0,1.477-0.144,1.854-0.396c0.685-0.469,0.756-1.261,0.756-2.07v-8.049h2.539
            c0.252,0,0.45,0.144,0.45,0.396v7.525c0,1.873-0.27,3.277-1.513,4.016c-0.81,0.486-2.269,0.811-4.087,0.811
            s-3.277-0.324-4.087-0.811c-1.243-0.738-1.513-2.143-1.513-4.016v-7.525c0-0.253,0.198-0.396,0.45-0.396h2.539v8.049
            c0,0.81,0.071,1.602,0.756,2.07C960.838,443.574,961.522,443.718,962.314,443.718z"/>
          <path class="st56" d="M980.423,435.922c-1.08-0.36-2.251-0.685-3.241-0.685c-1.53,0-2.395,0.522-2.862,1.314
            c-0.45,0.757-0.612,1.783-0.612,2.863c0,1.584,0.108,2.448,0.612,3.115c0.522,0.684,1.404,0.99,2.845,0.99
            c0.99,0,2.16-0.234,3.277-0.576l0.468,1.584c0.126,0.45,0.018,0.648-0.54,0.847c-1.063,0.414-2.232,0.576-3.691,0.576
            c-2.179,0-3.673-0.505-4.627-1.531c-1.009-1.08-1.404-2.683-1.404-5.005c0-2.035,0.521-3.709,1.53-4.771
            c1.026-1.08,2.538-1.639,4.501-1.639c1.332,0,2.646,0.216,3.907,0.648c0.324,0.108,0.414,0.342,0.342,0.612L980.423,435.922z"/>
          <path class="st56" d="M982.328,443.7c0.469,0.144,0.99,0.234,1.387,0.234c0.666,0,0.918-0.108,1.188-0.36
            c0.233-0.234,0.342-0.63,0.342-1.242v-8.751c-0.018-0.216,0.162-0.378,0.378-0.378h2.233c0.233,0,0.378,0.126,0.378,0.396v7.525
            c0,1.639-0.126,2.846-0.595,3.638c-0.485,0.792-1.53,1.188-3.007,1.188c-0.882,0-1.656-0.072-2.196-0.234
            c-0.252-0.072-0.433-0.234-0.433-0.522c0-0.09,0.019-0.216,0.036-0.288L982.328,443.7z"/>
          <path class="st56" d="M999.287,442.637h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L999.287,442.637z M994.966,440.747
            h3.763l-1.584-5.312h-0.595L994.966,440.747z"/>
        </g>
        <g class="pin pin-en" data-id="1">
          <path class="st56" d="M868.164,433.137c0-0.216,0.198-0.414,0.45-0.414h5.113c4.069,0,5.942,2.179,5.942,6.212
            c0,4.051-1.891,6.302-5.942,6.302h-5.149c-0.216,0-0.414-0.145-0.414-0.378V433.137z M873.439,443.005
            c2.539,0,3.188-1.729,3.188-4.069s-0.648-3.979-3.188-3.979h-2.286v8.049H873.439z"/>
          <path class="st56" d="M885.391,444.895c0.018,0.216-0.127,0.342-0.307,0.342h-2.358c-0.198,0-0.324-0.09-0.324-0.306v-11.848
            c0-0.217,0.162-0.36,0.396-0.36h2.214c0.252,0,0.379,0.126,0.379,0.396V444.895z"/>
          <path class="st56" d="M892.788,443.419c0.648,0,1.333-0.036,1.765-0.307c0.414-0.233,0.595-0.72,0.595-1.278
            c0-0.396-0.072-0.756-0.324-1.098c-0.307-0.36-0.811-0.486-1.585-0.667l-2.106-0.468c-0.954-0.216-1.675-0.576-2.215-1.08
            c-0.647-0.612-0.864-1.477-0.864-2.413c0-1.351,0.541-2.196,1.369-2.755c1.008-0.666,2.124-0.828,3.799-0.828
            c1.422,0,2.953,0.162,4.268,0.595c0.18,0.054,0.252,0.162,0.252,0.306c0,0.126-0.036,0.234-0.072,0.324l-0.54,1.513
            c-1.044-0.396-2.737-0.721-3.854-0.721c-0.846,0-1.404,0.054-1.782,0.396c-0.234,0.18-0.378,0.504-0.378,0.882
            c0,0.522,0.18,0.937,0.647,1.188c0.396,0.216,0.883,0.36,1.513,0.504l1.963,0.433c1.801,0.414,2.971,1.206,2.971,3.601
            c0,1.423-0.504,2.395-1.332,3.025c-0.937,0.702-2.359,0.9-4.088,0.9c-1.134,0-3.042-0.198-4.303-0.666
            c-0.145-0.055-0.216-0.127-0.216-0.253c0-0.071,0.018-0.162,0.071-0.323l0.469-1.531
            C890.231,443.184,891.6,443.419,892.788,443.419z"/>
          <path class="st56" d="M906.253,444.895c0,0.216-0.108,0.342-0.307,0.342h-2.341c-0.197,0-0.342-0.108-0.342-0.324v-9.957h-3.691
            v-1.854c0-0.234,0.127-0.378,0.414-0.378h9.579c0.252,0,0.378,0.126,0.378,0.396v1.836h-3.69V444.895z"/>
          <path class="st56" d="M914.802,445.237h-2.558c-0.306,0-0.432-0.162-0.432-0.45v-11.613c0-0.252,0.144-0.45,0.432-0.45h6.158
            c3.007,0,4.627,0.72,4.627,3.727c0,1.639-1.116,2.863-2.322,3.296l3.025,4.969c0.054,0.091,0.126,0.217,0.126,0.288
            c0,0.145-0.072,0.234-0.253,0.234h-2.916l-2.396-4.375c-0.144-0.252-0.359-0.433-0.846-0.433h-2.646V445.237z M914.802,438.665
            h2.845c0.773,0,1.386-0.216,1.782-0.558c0.36-0.343,0.685-0.774,0.685-1.495c0-1.134-0.595-1.656-1.945-1.656h-3.366V438.665z"/>
          <path class="st56" d="M928.895,444.895c0.018,0.216-0.126,0.342-0.307,0.342h-2.358c-0.198,0-0.324-0.09-0.324-0.306v-11.848
            c0-0.217,0.162-0.36,0.396-0.36h2.214c0.253,0,0.379,0.126,0.379,0.396V444.895z"/>
          <path class="st56" d="M938.562,432.723c1.422,0,2.16,0.09,2.862,0.486c0.828,0.45,1.278,1.351,1.278,2.341
            c0,0.773-0.252,1.44-0.702,1.962c-0.45,0.522-1.099,0.864-1.891,1.009c-0.216,0.036-0.288,0.126-0.288,0.252
            s0.09,0.198,0.307,0.198c1.152,0,1.962,0.233,2.448,0.684s0.685,1.135,0.685,2.053c0,1.333-0.469,2.232-1.261,2.773
            c-0.792,0.558-1.908,0.756-3.169,0.756h-6.302c-0.271,0-0.414-0.108-0.414-0.396v-11.703c0-0.252,0.18-0.414,0.432-0.414H938.562
            z M935.141,438.107h2.827c0.576,0,1.044-0.162,1.387-0.469c0.324-0.288,0.522-0.72,0.522-1.26c0-0.433-0.127-0.792-0.36-1.045
            c-0.234-0.252-0.576-0.378-0.99-0.378h-3.386V438.107z M935.141,439.817v3.188h3.439c0.45,0,0.882-0.145,1.206-0.414
            c0.324-0.271,0.522-0.685,0.522-1.188c0-0.486-0.162-0.883-0.486-1.152c-0.342-0.271-0.846-0.433-1.566-0.433H935.141z"/>
          <path class="st56" d="M951.702,443.238c0.792,0,1.477-0.144,1.854-0.396c0.685-0.469,0.756-1.261,0.756-2.07v-8.049h2.539
            c0.252,0,0.45,0.144,0.45,0.396v7.525c0,1.873-0.271,3.277-1.513,4.016c-0.81,0.486-2.269,0.811-4.087,0.811
            s-3.277-0.324-4.087-0.811c-1.242-0.738-1.513-2.143-1.513-4.016v-7.525c0-0.253,0.198-0.396,0.45-0.396h2.539v8.049
            c0,0.81,0.071,1.602,0.756,2.07C950.225,443.094,950.91,443.238,951.702,443.238z"/>
          <path class="st56" d="M965.886,444.895c0,0.216-0.108,0.342-0.307,0.342h-2.341c-0.197,0-0.342-0.108-0.342-0.324v-9.957h-3.691
            v-1.854c0-0.234,0.127-0.378,0.414-0.378h9.579c0.252,0,0.378,0.126,0.378,0.396v1.836h-3.69V444.895z"/>
          <path class="st56" d="M974.434,444.895c0.018,0.216-0.126,0.342-0.307,0.342h-2.358c-0.198,0-0.324-0.09-0.324-0.306v-11.848
            c0-0.217,0.162-0.36,0.396-0.36h2.214c0.253,0,0.379,0.126,0.379,0.396V444.895z"/>
          <path class="st56" d="M983.327,432.525c4.537,0,6.14,1.747,6.14,6.464c0,4.825-1.603,6.482-6.14,6.482
            c-4.538,0-6.14-1.657-6.14-6.536C977.187,434.2,978.789,432.525,983.327,432.525z M986.424,438.989
            c0-3.007-0.576-4.268-3.097-4.268s-3.097,1.261-3.097,4.286c0,3.097,0.576,4.267,3.097,4.267S986.424,442.104,986.424,438.989z"
            />
          <path class="st56" d="M995.26,434.956h-0.378l0.27,10.281h-2.574c-0.198,0-0.36-0.145-0.36-0.378V433.21
            c0-0.307,0.198-0.486,0.522-0.486h3.889l3.709,10.281h0.378l-0.342-10.281h2.503c0.252,0,0.468,0.162,0.468,0.45v11.686
            c0,0.216-0.144,0.378-0.342,0.378h-4.142L995.26,434.956z"/>
        </g>
      </g>
      <g>
        <g>
          
            <image style="overflow:visible;opacity:0.3;" width="375" height="375" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXsAAAF7CAYAAAAzPisLAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAR8tJREFUeNrsnYuOKy3PrE0n93/F
    a5qtLX2vlL8FuMo2NJ3Y0mgyOU0O8FAUxohkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRk
    ZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkfFOU/Agysn0Po+bHmJGdISMj22sODhnZeTIysj3m
    QJCRnSsj211GDgIZ2ekyso3lAJCRkR0xI9vTmteyE3RzAMhI2Gf8TPvZuc3WL/9/GdlZM7LNZJu8
    GcwJ/oyEfcaWbSTb3lxIJ/wzssNlLG8X5QvbcH3Qcyf4MxL2GVPaQsn2GQrautnryUjYZ/zo919u
    fg2r2/HdAK8bvY+MhH1GAv62xzxV1ddF/yfBnx0/I7/rsMeXjV/b3WCsE/9Xvfm9ZSTsM74c8OXG
    55zxHKvVcr3xORP8CYOMH/xeI2FcJr3eHdvtXWCfMUgk9BMKGT8O+BJwn7L49TwF8HXRcyT4ExAZ
    CflweM987N1tOAKs9abHRsA7oZ+wz9j8u7sL8CXwuZ4Cewusa/DzRc0aEvoJ+4wHfGczAR9x/azZ
    wa6wj7g+wZ+RsM/vKgzyXmB7rotQ/dbPLsqL98K7Op/TC/6EfsI+4+GQZyGKwtgK9zsGgGiIRYN9
    lwHBCvAEfsI+Y0PIexQ8+3fUY9jPifk8PYubUXCvAc8RNRgk9BP2GRt8Lx6rJkp1j/723HcW+Fco
    exbOXvivBn9CP2GfsQnkI/12j5ovhvt51X5EG7eqXA+Uq+F+MweCaOgn8BP2+T0E3n+WJWMBegl8
    Lq+ij7RxUDBa4O25zL6GiBlAQj9hn7FYzVsXUb0qPQr6HuDvAHuvYrdCPmKmkNBP0GQ8FPKsio8G
    /MxBgVH8d8EeAXBdeLtnELBAP4GfsM/YGPJRAC8Bz/FNsLdAO+I6rwWU0E/YZ9wM+ugF1kiIF8Nj
    WPCzsPe0cWvuvAX2yG3e+0Qo/5nQT+An7BPywcp9BdhnDAAo6Ge0a6t1w8Ie/R31GOssgIV+qvyE
    fX7GCyFvVfHI70jYRyr86NRLT1ZNJOxHvy0DxG7QT+An7NOyCYb8LMDPUvpeW2e2ql8Beetts1R/
    WjsJ+/xcHw756Oui7J5Vdg6iTmfZNCjIoweDldBP4CfsE/SGvz0WjeW6OwaA1Qo/StHPVO3IZea5
    I6yehH7CPj9Lh5pHlD0KeSvcrbBfBfxodW/ZnTpTufdunwH/WdBP4CfsE/QOy+YOuLNgj1L9vet2
    Ufaz4D66bL0tEvoROfsozBP4CfttP8MINW/x4q2KO+LviBlBpKXT+5zZDVQRsPcqdO/fvdtmKv5U
    +Qn7VPMGmwa1PTxgR69jnyfK5kEGAGs7X6noo0HOXGedTSDqfuYmrQR+wv4RoPeqeS/kUUAzA4B1
    sLCCHwU9sneBUfYW4COwjwR47zr0/l7LB4F/tMpP4Cfst7dtPLtaIyA/gjdyuwQ8xmrvzFT2UfaN
    xa6pjuutsLfaQF5rJ1V+wv7rQG9R89ZF1yjIW+4rxvtYgT8jKyfKwkFBb1HnM+9rUf4e+KMqP4Gf
    sH+cbROl5i0q3gL6qIHAuhaggT5qwZYF0Qxv3grwVYNC7/VbLB52AEhbxxnv/Ahus20YNW9Jk5wJ
    +MgfVOl71X0E7NmFWQvkZ4HeOjj899n1rmOtsc/HXS/L4G/m/1peW4IsP5+pah5V8VbAs5A/AuCN
    PIdIrJ+/m7KfYdVoP+fCAQCxeTS171X5qfBT2W8NeibThvHkZ8I96j4zvfzRDMkiarw7ZS1q3gv6
    snAwQFR0VT53TeVrswhU4Sf0U9lvAXpUzVsUvQXOs6/3ZAJpsGcWaz3AvxP25+Trtfuy9pNF5bM7
    cVPlp7LfEvQeNR/hu6NwPoLuE+3pz7Zy0EwcNvvGCvsrlI/O9QVQ+2fj/ifw/4+Bqh8p7XJ536jK
    b3n36eOnsl/+eURk23jUfO82i9XC/M3cFg393meC/F5l40SrelSRn+Bt7H0jrJ/RZ4DMkDRVnz5+
    wv6xto1XzR/gbSi4PZetAxA6uKGg9yp7JBNnhn1jBbfnMjMI9G4XEP5WWyeBn7B/LOgR2HttGgbU
    nusY4Fs9fQvwre2cBX2UR38q12mXPdfNUv0jW4v5jfr4CfyE/Rb+fIQvb/HeEXh77zPL4rFYOVbo
    R1s4LcCxitkKcuS2iBmA1+ZJ4C+Id4J+Kugttk20imfA7f3Ngn90vTYQWoHfup45fjA6++Y0wP5z
    4fR6Xev3dQG09fu/Bd3P+7cu997bSfa9Ubpm7/fn564t3CJplz+1aFsS9GbQWxZivSmUrN9u+e25
    LzIAWBQ+a+egwGfVPQt8j4XDqHLmN3IbM5tAByy0EFtP8c/08X8C+Jl66Qe9N52yd/0hPpuGBTpz
    uzYw9K5jwR+l7hnge22cSFXvhfoh/zcFs3SuOz8+06vK/1T1Aij9/xT+IdimrJ66R1R/S51bNmD9
    hMJ/J8iXgd5q28wAfNTlaOjPzLnXvv+oHPuIRVkN+Cjke5ev0G9ZJufgN7NmcTr6KmI/JfAT9stA
    f70uwp9fDXjkdhT8jKePgp+1caJ30HqKnkVCfgR9BPI90LfUvhf8PYV/AqC9evQJ/IT9NqCPUPUj
    CKKwZ4FuvY8F/OiCbYS6j/TsLTbOCPrWBdmRqj87f/cg3wP9FfZn4/IJfhbSAfwBWjt3AT+VfYLe
    BfredWxGjRf0o+uZxyDwR5X9rJx7i28/snJm2TjMoix6eXTdFfrn5XOtl79PAP4tlf+p8I8gW0cW
    AP9rB4N3gn4Z6BnrhoU8qtAPwwAQBXyrnaMNmhYbpwCQb4FdU7Cexdlo0LfUfQ/wPdXfg7wAKl8U
    W+ckwI4qfWnA3wLwrwT+O0F/O+hnQJ6F/WEcFGYA/5ttHMvuWATuPdiPBgFkAGAUvxX6GvgZ4Evn
    OjEMAF8H/NxUFQt66w7YSJtm9g+zsBtp5dxh4zDZON6CZxbQj2COgP6PGGRrR/EL6evLwMe3qv0E
    /o/DvjwA9NfLXiXP/rwWA5/ZVSviL4pmVfYs6BnoW3LrGTWPgP7vAmiv8m9ZO6zSH0H/mnufwE/Y
    mzv3CtAzu12jIf+aAHztdaLAty7S7qbsWVUfZeGwls1fB/KMytegr3n615IOCPQtwEcBn5uqfsSn
    nw16q2UToeJfjus80GfLKUQr+0jPPlrZM4uzTJplBOiZAaDVr3rg10ofINAvne8FSc/0KvivGAze
    CfploO8Bz5oyyah19m/ttmIAvnc3LerZW9W9ZQetV92PlD1q5zA/x/8A3rJnRjbPH/B9nIM+dw4+
    zyvoj8ZzHTLOmLIA/+dSMn95U5VWDdEDem0HbMTC64uEPHMbq/Y1K4fZTWuxcqJsHETVo6AXia2F
    w+TSnx2oj8CuQf7PsD7VU/ifkBdpV+1kcvIjga8xpCbs91b1kUcHWkE/w655TbgctXAbtblKBteh
    6p61crypl4jCZ3bNWiycowP9Mvi7KIOANgAURUSdnc+5VZZZOip/NvC/dsH2/YOgR2+fBXoL7F8G
    0KPXISqfsXNm5NqjFs7dJY49tXGQjByLjXO1bq5/FxDuPcj/dUBfFJunp/qfAPxU9g8BPVvYbDbo
    r9ejsEWgztzHY+essHHkAbAfgV6E31RlSb/8A4DfUvMI6FuQFwX0qMq/2jqi+Ph3Av+Rg8EvV71k
    yxNHgJ61bCIA/zIMBLN8+7tgzwB/RvqlSGyePVIe4W8A/M/LRYF+77co4GdUPmLrzAK+1aN/HPDf
    XwRvxqe31KFfBXpkIXX0OwL6Vt/e49lHLcxa69lroEchLxNgb8nGORS/vhh+a+Dv9Rk0PtU9A/za
    +F9oHv7PLNi+E/RDUFjr0LOFyUb2ycsJ+giFH+HZW2G/2q+f5dt/AizSyrH69Ra4F0Dx/yn9qQQD
    v1c7R2S8m/bn/Pv3D4Beu1/0rlimOFmkikcuWxS+pvKLEfZ3HFriKXEcAfxeLXdLvn3Lu2f8euR3
    C/w9wKOzLXaGpQG/Bf+eyrfA/2vsnF8rccxUrpwBeiaFkoG8FfpRvn0xwr51ncg9+fUI5Fnfvufh
    X0F/OGBfhUu3ZBQ9cvkT9FHQ/1T3V+CXBuRb9o6nns5XAv/9cHhb7ZuewveUKPaC/gpcC+Qtg8As
    2M/06gswkKPfu4hez94D/J5/fzbUfm83KQp7xr455P9m3iAHlbSAfwU9Anlk8O0BX+T/Fl4bPedV
    5feUfdbG+RGfXgg1z2yU8qp5FvJW8HsXaT2qHlX2VlWPtpdKKvyIw0yOgcK/Xu6dMzuydnqbp67g
    b0F/BPgr5Fug/wOVvDYIfL7G699MPn4d2DreU68eo+5/NfUSSbFE4e8B/UjNo4Bnro+GPZNfH7Uw
    uyPsGRtHpJ2Vcyjq/hgA/yBgj/jz18u93bJ/jX7yR1qoSLSg3wI+sg4wOtFKvtnOeT8Q1FZVj2be
    iGDnxvbq3ZTG9WyWDQJyy20s7EfAZ0E/sw5OtF+P2jgj2I9A31P2dQB6LSPnE/h/AbAfwb0o4BcF
    /NY4GypfOj4+Av0ZC7ap7B/g07MqvlfVkVH0mpJHoG4BvSftcpRuaa2DI4GwjwK+dXPVSNmPvPzj
    ovZboP+0MLSDw7ViZ63F2NZzoGBH7uP5/BGVz/wfph4+y6qasL9vgIjKo9cqWXpAb4G61dJhsnGi
    FmYjDxX3LMz2bmOtnGjY14ZPfzQua5aO5t/3Klz2vHmkL/wBfdEL/xH0T8Ueqp3/rS3YfpWd834Y
    tBlVj/j0QkLdAnnGsokCvkXVs8C3nDWLwl4kdhNVhLJHPPsI4PcgX6W9OHlePlvt+EE0rx6xbjQL
    TsDBgFX3I+iPNl2t9O9T2U8cBKw+vTWv3lvX5rXwx6rqUfvGmn0j4luY9fj1BQTKajunXuDzefkK
    NM3KGdk5SF49o95RRR8R9WLlyEDdo4u22tm1j7dz3g8DOmPZaD49Yum0VD26EKulWHp/3g9U9asq
    W1p2WHtsHAT0LcD3oH9eYH9V97VhYZSBpdM7lKTn13vOo40AeW8WJR+fhzTeu9XD9xxv+Bg75/1Q
    0Bfi8ZryQ/zjQ4F7AVX8MQnsb/Fl41gWZmeVL2YOkvEoeivsLepeBK+MeQX99boygP4p4xOjWqD3
    +PWjzzhqQbYH+p6dg6R4VsC/Z+yctHE2s288i7Geg79bufQeyL8lzsphc+vZBVl0MdZyAlV0Bk4B
    VJ9F3bN2TgEtndZvxLu/gn5k4fwBAEcOIp+l8llLp/V9ortsrZbNNgPD+yFA9zymKA2WXaBl8+gt
    GTcI5CNtHCYL545yCB7rxgsbFBa1AQxG3Yv0C6QxsD8U7545hUoD/wzAXz8nVOmfnQHv09KpwMxu
    hp2Tyj5Y1Wv2TcSmKbRkcWtXLOrbvw2Qf0+wcKxe/YzMm2hFX0ALYaQAR4AvAQq/GuyclsrvgbtX
    wRLNwEGsm5ke/sjS6al75sB5xM5huFUT9jaFbrFvEPsAVfWWTBzEU3+DkGetHEsGDpJ9E1GjXlPz
    UcXOrBYOAv4R9HvK0FJSoSjQR2GPVLBE8+ejIN9bx0AsnTrop5qlgwzuPTvnUYu174eA3vtcRWK9
    ehT4EZk2GuTfYku5RPx6y1GD3jIIbNG6VW0IXdQroLovgMov0i59fIX+CcD+kPEibK9UMavurer9
    BVo6jMI/BzNFbS3m6+ycp5ZLsJw2pSmTGYqeGQDeBOityp5ZmPWA/hBuZ2yEmo840GZk65QOEDSV
    j6j7Qlo6n17+aINUuQwCMlD4MhgAEHVv/XxHoB+B36PwK/CaZ2Te3DoQvDcGumcQ8No3sxQ9o/hR
    0L/FtzAbkWYZVQYBUfMzbBwE7KOpO6LyR79Hl3vgbwG/DoB/At/Hn+iLsZ6c+gpcp202i1b4Vjvn
    cer+ieUSmBrZnl2y1o1TFkvnDah7SzbOQcDemn1jWYiN9OdnZOFonVbrzKuAf/XvR2UPEP++9R38
    BfRPpvTEawB/ROFXpQ+fAXaOB+q3DQTvTYGO+vCoEhTBc+3Ryo5WRW9R94xvz+6Y9WberKx1M9O6
    8Q4ETD52FPBHu3UZ4Pf604x0yxGkXw2ga/BvlY5u2TpHQ+Fb7RzvYm0q+4AOiyp4VuF7NlRZF2c9
    ufYj4LOHkqAevQi/GDvLvlkBe1TRa3AYzQKu1zOWzqnYN70Zb8/K+SM/40pAfqTwETvns2LoJ8SP
    jpWD2jnMTI0B/C0DwXtjoHsXZZnNU9qCLHMiFavo3wq4rTbOKN2SWZCNKIGAAj8S9hHArxMGDc0a
    Kh3Ij6DfUvetapYCrGlFWDkWG6d2QP9SnrcO1D26YKt919bF2qyNs3DQYDx7z6apK2S99XDQAYCB
    vXeXLAp7xpuPqGJZjG0DnbpHDQSIutdmBq3Hfx5wclX61/cyAr+lqBwDdxG9SNwI+sjPVd23LB3E
    zkH2V3gXa5cPBO8NAR2l6r0/FlXPVLdkcuYtaZdsWQSrR6/ZNyLcrliLR18WtUsN9FEzAQT6yMHl
    PZtEs3O0vljEvxDL2Dso9Efq/gDtnBH4t/Tiv1XZo6mWQlg4UYeII+e7riprbM2+ibRuZqn5O336
    keKfbfn0FGMP9HXg3Z9KPxGxnQCG2jRIGejX5TcK/Za6r4ItTlsWbB+j7t8bgds6dY8sh6ClG6KQ
    9+TUo1bOClVvsW4kGPYr7RtNqRZiAKiEJ4x69yNLRwCVP7JwvKUlrBZOD/ICDASIuh95+KNyClq9
    pKhUzFT2RmuHUSstr74YQT+yb9gBwOPTW716r6KPhjwL97KozWmerhf0rO8/ytK5qvzW6z5BIVWA
    94uo+9ZlBPw9la+p+6uyv266Og0KPzoVc9kA8d4E3HeoesTCsYL+ruMH0Z2yUTVvdlX0s5Q9CrwC
    gt8zCGiQOAYKXwTbE4B+Ri+HhdP6+zUB+KLYOVdLZvQ5ZW2cG1U9cqhC9IIsC/rjRtC/xF+u+C6P
    /k6fnskj1w61ZjzhYgB9ART+eQEfYueMFG4vNTLiEJdo4BeDf49Yd49R9087lpA5YlDEtonKk3nD
    gP4Qe5liJN3TeuqUpurFaN/0rmMBX4KhzSh6BO4y8H8Zhc+o/hEsDhln6YzUq3dGxBze8toA+AL6
    99pRhlkbx9iJWVXP1Gexgv4leMplpKJHDiVhfXqLqmfsG8b79QC+LGijjLVjrYVv2TXeU5QnAH0J
    BtM1awaFvXRg3YL6MfDmPcAXaZeo1r5bRt2njROo6hkbx6PotQPF2YNLXuBgwaR7ehdkD2LWJMLX
    o/cAvmzUNjVlrFXJnOXlX317ASwddMbTO2Dk1VH4iBp/AcA/DOoeBX4VfrNVVLrl9EHhvUFHma3q
    xQD60c7aF+DdMzCPsHmsRc4iC5sxPv1u3nxku0bBj1g6o0XD4rBz/vv7vPz2qPkW6F8DRS8DKL+A
    61+kum9BvgV8Af37x6n7Jy7QelT9oYC+l46JLtBqJRSsls6hKHxvyeLozBvGn58B+OiBoU4A/6iM
    boSPL6SHrwG/Ep9Jq76NlnVjgf7RUP1MWYUW8K9WjkfdM9bb9EFhx0JoBQS+VdVb8+kR4FsAfxgt
    HI9PH3VuLKPmZ5U+KDe0URF+EZdZwGUsHSZ7Z+ThI8Af2TlV2jtdXwMrB7Vfjg7YRx7+tb5/ucC9
    B/xeO7eo+58thGbtxDO8esuRg+yuVItaR71/i6qPKoXAqPoZkN/Fzikk/NnsDs3SQZV/y8O/Qt8C
    fKu6bwH+AKB/hf/r8vf1ec+B0m/ZN1dPn8m9R8oc356x85QDx9GDSRCvvgiegTKjbv2hePTHBFU/
    o1xxhEePwntnv94C/0JAv3eQeZSVwwK/GtU9sqCK9rUe+EcqX/PvkfZele86Qt1PGwTeG0FcU/Uy
    UO8rVP0BANcyMGj5+lZvHgX9IXgFy6jSBwjAnwB4j3evQd+r8lHoM8C/7kYdvTdvfjxr7Ryd3+eg
    z/fsHE3dW/32PHCc6PwFuM1y9OCoVs6M06iQuvea+h8dEt7bGYwsyor4N01ps7BVgI94vhr4Ohjo
    sweZMx4+A/xWKm5P6Vt8+0OxcU5C4Y+g31P37Gx2VN5aBt/P7WmYO1e9LIBPb61/w2bfFKe6Zk+0
    0v7PqHplr7gbmn2j2WIWn34F5FcWQ6sBz8Vk6vRUvpayicK/BfwCePealcP49hWAOqrwrymllsVa
    i7pHQP3VB47P7shWVY+UOGZg61X3nt2vGvi9oN8ttfJum6cEwJ+xBHoqX8vHt1o6RfT8+6ulUwdW
    zjUvvpc6qVXwHP3Uzt/aYu2IFS3gawNovRvqvXht4tf3IIKqdmSjUNSZsZ8lhz9/0Pu2ru/Vr38L
    dqbsS5mVRNWo1+DPLMpaa9wU2c/PLwHvCbm+ACIHtdiQPots3NKANjqVSkCPfrQm0HsekX65BuR+
    2vupIPMKwcGpYuYu2BfQukGAfwx8eKS8QcSZsaNB4A2AXwM9coB45C5ZDSaIV1+CIL8r4KNfL7OL
    vIB9hwX+LPBUxePXHjsaAJBDTAQcNNgjFOWmz3NLG6dMug+a/81k4VgPHfdk57zI65HdvncdJxjZ
    wJ+eiYNYNcz9ES8fWbBFN2BJw/tuefi97By0mNlL9LTLF2nnHJfXjmTnaKWPR3YO+r1aFmofpewZ
    C0dT9+giq8dvtx468gYVPqrqvfVvtH0Ex+DztYI+Qs2XLwK9R+1ba0UhMzJtMLUM1qjirYS6t6h5
    r7of2VMVmK1Ei5XQvrBTiWP2vmgJYySvvhBWELK5CVH4jKofHZ94iO0IwWjQe77rbwS8V+0jOfg9
    hc+ofVbdXxU+qu57aZE9dT9S9C9Q5Z+Ny0juPXqalbbJSu5W86uU/V0Ls0iJg4gDw9/CnR/7Fv7c
    Wdart+6UFYnPpUftuV8CfdRA2Bu0EWWvfX/V+Jot6l4bHEaK3qLwBXw+bRagKXvEwVgueHaBfeTC
    rHUXLFte+E1YOcxjPIuylsNIrKAvwufT/6qa9w526GeN9K3R47xK05LN0rNyNFvGC/yqWDnWjJwa
    3AfC+slq2LNZONe/DxnvhI0sVeAB/VtR+Uju/SFYTr7n5CkP6FPN76PyI4DP/G9koxmqjKsCfsaX
    twBfu5/Fs99S2LxvbLxsqlkBPXvUt4+cIbD1a9isneLw5hHPPgL0qeZj+oylvgri4/cySZh0zM/N
    Vr1TzUYHinz65i3fvtdXTuH9+pGHj/j3veMKr/2GqZlza1bOTvXsUdXRU6Wo/eMF/Uv8xckYyGs7
    fZldsTKYOc0EfUKe6w+WxVumlo4Ino5ZFIGllSCo0i4j3IL+AQLf0n9awG8NXr3DTCwLtSJZz57y
    M5kt/RGKHsnciVLsaD0epKgZm30jCfqvV/magkRU/gj2R8evRoqQMbC3wL9cAF8U4KMlFDTgs4P4
    smqZx01Qj6htblHwSHkF66YqC9yRQcZq4Xi8+gjQpzd/X1+y7mPpPR7pU6OSHEgqcaT1WYSvF+Xp
    R1p7Z7JyvkLZey0c5ANmdssyNe1nAN9q4Vi9eqbxRYA+Y62tg1RaRC2IqnzvI6HV8+5bVk9rl2uU
    kGK8+1O4GbJ0FH6ElfO1h5cwFo4GJzZP32PflMDG6LVokIY58ukRFZKg39/WQY+9Gy3Yomfdsvap
    djygB/YF6Fut13MO/kYElOVEqlutnOPGhjvLwhHh/XptV621sXmhz+yWFeG8e8avT9A/w9ZBZnDs
    9+09LwIRNEjfYfsZU/7bmtX2KCvnvbgxzrBwvD/MaVVeO6cQII9slNrn6AV5Qn4fWwctp9BT+y37
    oWXxVOmfYjVaqB1l5ZwOa4ZV+Ii6R+zQx1g5x6aN2ToiWhUHqrI9VTK1QURbTLbaNwJaYeh3kKD/
    DoWvzeq8AgoVV0jmGSO+Cjg7j6oOyyr128olHBs0TA3aFgtHHI1Sa1hodgGaXROxKCvCZQ4wPn2C
    /pnAZ+1Q1L7z2DmaXWmxUdHzLJgsvV2zcsrusI8Y1TxZOF6vvhgblyXlkm18mq+KdPAE/XcC31KZ
    VCt7bVkXi9zYaBFfUepeFCvH05eWxLGo4Xn9ek1leBQHo8CtWTrR+b1sBo5HTWR8J/AL2CdZgYGe
    O4GeqBYlwCL2pLBWDpL6vExMHRs2XC2DJMrCYcB8CH4EIjKAoAuzyPsUwbNv0r5J4HsLpTFWIjJT
    Za0ctk8iiRgRdmmEmJrar44bG6HlPkgOeWR2TqSNE23hIKcfRdaoT9D/zoCBWKQWceWxVw9H/0Q2
    V45m3SJYEoTHNps+IBw3NSbL45gTlpgsHG27t8cfZP+/d0qJ+vWWRpqg/z07BxES3pPi0E2OGuiR
    PhtdagR1IJDPc3rcualKOwgDXem2+PaIN1jIRjR7VyzbeBgVkpHAH4kCNgtldBbFAQisKIu1TOp/
    yAY07XtZ7tsfCxoZ+zyWhQ/0jFXLdC6iER3E/y9gBxLBrJ20bxL43v7IqHtPllxE8gRa6NDi2wvI
    nAgn41HKPmLkKsDfqLKwpoExoEfTu1CLB+1UjAcboQozvmMgYEQBKigifXtkfQu1R72Ls8isepVv
    v7WNU5yPQVW9BDQ2No0M8RkjNm54OqF1AEjQf7+d4xEKK8uYoJZotI0jRkdBE6TLZ293bapC/HoZ
    eILRDY3x6z1KJAr0hejQ3kXZjO+2cxhxMPMwISaJgp2Be3fGWtcPt+pjx2aNky1nHK0orP69p3iZ
    pZKnV9Xf7h9mbGvnWGYK6G5uq7VqWVvzzuBF+Nk1a80sXaQ9JjSclQ00QmkcZIPQTpXyWjYi/iyc
    iI6eoP8ddT+afWv9bjQDjzo6FLVuUDsnqs7UrFnzlL533NjI2Ho42pRKwPtbyicwNk4Rv4WDTA0t
    dXAS6Al8j7pHUw6tWTlIf7EkOkSVIWFOhNNm2csXaXeueqk9x8yFIVQtRG7SEPH59WyGTto3GdpA
    wJbuLRP6Y1QyhWd3urVEOFInZ1ncsYPWsjhr9QBlcaOJPhjc2ulS1WfM+q5R9Yuo2NnizCrStNeJ
    3L7dd3ps1BgjRs+7lEbUTj0U3jMbWA4COSggFqlm53isnFWq3WPlIJ9HVH8K6ZPHQ8AQkcES1aDu
    tnBmdNQEfVo2FmsVmZnPLKA280dzIbQUTPQzXtbnjs0bJjpysosoUfV0kAFABK+LzYDeWic7oZ7h
    bRvIgmPUuROzfX3rDGQ0sAkotpYOAscmjYUZFa0j7y5qYdQBGAsnFXvGbHXvsXIQ6CMCZ6WKL46+
    iXym1sq0j1D27MiFplBaGsTO00Z0ymu1cCKn8xk5KGhWDgLzFWdTeI7zRB0DMSj6W2J1bRxmezGr
    ODSLpyxsUCL2tC72c0uIZ0TPvC3PgwqUVeILHWg8JY0F6JfbxLFpA0SLCiGKWGS+yhdDIxPhFmc1
    BbEDADK+V80zVg7a/9AZ7UrxxdhNls/itoH7eFBDtIyuUWe5sgcPW0+kR22taAsnI8Nq5aAza3RA
    sMyKZ6RVWmYoqID1fKa3KPvZoxUDQXQLs0i8Sp99zBnyWSfcM+6ycpizkJkDh6zqnhV5UYeViNgT
    KB5p45QFzxexVTlquigBDeXuLdc5IGREWTkW3x4ZDASYCWjPI8KtmxUja6w8m943j40aFAJ3RKUj
    AEe/eGuufkRj1zoXm7qVUM9YJRCYPHS0cqYn8QGxbrUBxjL7Rgo1egaP7WFvaUwW/0sro2BRBCJ8
    +hirKJj3mwDPmDmjW5W5Y511i2OGzoo55L1Z9yM80saJsGfQx1orYVrUu0VloDYNoig8U++0cDJm
    92Vr+2WOG0VmxJZ+jvZT1ILdNv3yzgPHtRERVbxW+8Q6ACC3e6Z/TB3sjIxV/Zbx7TWYs7vkkYw3
    EWwHLHuwugCQf4SwWlnP3jIKsrmtllN1Rl8m2qA0m8hiNaGdSJ7Q0DK+2u5B+iE6eLCDBXuQEdOX
    ZVJfvuX7Ob6oEbJTLMuWZ4saWFFEqSxqWBkJcnbm7ilmyPRlts6Npy+jg1rZ6PvaDvZeny9KDXiU
    PasGZjSY9Oszdh1cLDtQNRCzxcyYviyAI2G1oZf2x+MLGxOjJhh1MVIQ3s0Y1i8+gZ2xk3XDrj8J
    IMw00cSkN6LKXnt+j+twWxwPbYSWdEVNpWujdlT6J7N2kTDPeMIgYK3bzmTWCTgYeBdp2f74GAv1
    CPzSZ/tTTLE0ZtoY7dlbpqcz4Z4DRsYMFR/FAounr80krIu0O/TXr1H2nt2z2pfnUSCRK/jIJiuL
    UkDPqs3I2EkwFLINs7VlmGwchA+F6M+PEl/HAxpWcTQsZKqHDCKMCkAUyQz1k2o/40n92PI3s4GL
    uYwIK69t+xN59nc3OCZdyrpZYkaBMus0OUGe8QTFj4CQyWH3XL673yz539+4QGvJZGGKE3nPpkxA
    Z2SsmUFYz4Pw9OltPf0jG4a5YVhmGOhUMuGf8XTVbhVgbL14Sx+aLcSKbDj7Pn60YXpHaG/dH+vf
    ORvI+NZBJbJvsP0RreHz6H739HIJlnzY6GnXVzaMjFTxhvsi61aRa1uz+rTlf1kEJ/p5fRXsdz04
    m7VbEu4ZvzpA3O1nr/z/MxMyUtkvmDauAnYWLcv4ZfUf3VeY7Lon9Mdpr2fXYwlL8P+ZUd2vPKTx
    ZGRkZPxMnn1GRkZGwv5Lo378ZGRkZCTsb4Cwdltd+D+tj62L30NGRkZGKnsDvFmIr/r/GRk795vZ
    4ortK3Xwd31gf5z2eo4HNqSVH1RVnrsuHigyMnaFfzX2p5UMifr/j7SIj4c3NuYDr5MawOh11IWd
    LSPjbtWO9MdISM7q05b/VQ3Ph35ePw97zweCgNmj4KvxeXKmkPGrA83MvqH1x9qB8GoBl8o+YBRl
    G0y0n1+VBlcnT3EzMu6YObL9tRr6ygjas2cy1tnLLf37+MLGabF1EPXd+l0nDEgZGRkxAg6BP3Of
    u9clEvYOpSDiX3DVpn3eUZ2dSuYgk/EU9Y9mxrGgZi/f3W+W/O/jAQ0owpvr3aZNFzV1jzRiVmmw
    jaHu2rgyciAI/rsqAs4K/GoQieigtM1M/7i5AaAKFoWydcqGPD9r44jxdTINMAGesbvyZNanLOtX
    1Ql8rZ8y/XlroXUEvuDZebMWb46xcRAVzqzeV6IDzGwIOQhkRLePuogFVYEwu1Cribcd+uvXKPvI
    L98yOCBfdO00MkbZV6BBWpV/RsZuA4MV/qz9UYE+VYF+qIE+YiaesL9xMNC+6JnK3qP8U7VnPEXt
    e5IfKghzTcBZlX2PARaYb7lf5ti0kTFTKhSyLLyjlL1leho5zc6BImOn/u2xO1v9mb3M9GV0YEMW
    faME3k8qe82mQUHMNhrLgNJrpMyghUydE+AZsxU8+lh2ll2dfblO6MvM7H+Fonc937GwQVlWtSMW
    PxllzzYSbeEoIn8Ymeamis/YzdJB2jNio3hn6azQs/bVu6vo3gp7b/0YdBGW8fkqqLgrcX8Rm7JH
    OkZCOmMXuKMlCVhYo7P0UX9DRBuTnIHMwlng/lyePdpIPDMF1EKJsHHq4AedgiKDhadzpuLPmN2X
    re1XA3gVbAFV63/arBxV9ugeny371bFxA2Knhcy0ymrjVFCpV+XxSINhgZ7gzoie/teJ/VnrW2K4
    DyLw2D7ODlgoo5b312OzBqSpdXQLNfoFotejMwEkEwBRKEjjWVWxMyODbUtMEoLFbq3Czaqtlg8y
    iKDsYvr0lL56PLBRWRZMGBuH9eyZKaTWsCwdZhf1lvG9EGf8emRnKqO6NZGHiDuL3RqRaMHwbHrf
    PCY0jKgG58m4YWwWVt2zjUgct7NKKiNjZd9FM8TYdEZm9hyh6i19WGPRdn3zeFDjjJ4CWsHvURCM
    ahDBizexs56MDK/KRwWbJe0ywnK19E1mkZkti+JV8O6+e9zccJDR0Fr6NGL0tloxaF4+uzikDSJp
    5WTcZeEgII+a9c76QftvROnj5TO0Y3EDYvNVPRsZPGlbHt8vwi9k1H8CO+MuCwcRONaZd2SfjJgd
    IJ9j3bkfHg9plBEZN17In8rfHvgjygGtZW+1cnKwyGDbCALD6DW1VaLMMvtAnImvgn10+qW1/GmE
    ejgd17G+Perrz7JyMhLiERYOkwU3U+GjYgx97RZ3QZSBbKXls/2mKksRpdU+H9vQ2DWAGVZOwj8j
    UqwhyRPo4muUUPP69Z71NFThL62CeSxoGFEN8M5FnpO4zylxm7wsBZssiiwHgVT1VsAj63KrZt3a
    zPsUm40jwtmr0Zk4X6XsLYqWSd+KAvupNJ5TfB6iBFg5dVHnz/juAcFj4USulVlFFjsgeDJ1rO7E
    UlF93NCQmIwcdpF29IVYF2BP8an+M6iBaco+St1n/KY145llM6c9af0R6S/ePrlyQ+Q23+mxaUNj
    yhWvsGg0VX/K3EwBdpDcCQ4Zz1LyItxh3L3Z+ayNUCcx056Rwul1HW7rW8eCRmS9j3URxNOwTkcj
    smbpiNh326Ir+2nZJNC93z17+ltUujMrqjx9mFm8ZcsoiDL7md4fj4mNaabyEMFTF5mpo0fdfzbO
    U1Eg1iwdzcpBlEOdBIOM59o3jKpnrMYoRX8CNivbh6PVPtu/ljN1xwVaywYNkZiNFIgyaP2eMX1k
    0jRZdZ8pmjkIRKh6Bu5IX0X9+tPYV6P9es1tiChgGNYHj5saWWSpVKQRzfICtSnmSJFELBpFduaM
    VPWsqmcrUlrV/ElYOsza2ik2kaW5CVEK/5HKvjofF+nLR2fWnICNoz3Gm/vrPYw57ZzfAf1MVS8S
    n0KprYVZsuZO4nFCsEcGfRS1Wafx9M4Dx9n7WKpLIsoB9QJH9o1m5ZxKg2MXhize/B2Ddcbeg0Ck
    qheJy4c/CbXP2jiecidRfv3yxdlI2M84DAG5jzbVElBNawuq50C5j5T96fAPNT9UhM/Rt6r7BP73
    2TcolBivHhUpWiJDHfSjagT+7Pz7KHh/VW0cJm/cWjJVa2js4s8JNKxT8B17p8Tl4QvYGRPqad9o
    92VVvUhcFo42Sz4D+qfHwtXEKNvflpcvOTZpiMzgMPvUGotyYBUJYgMhVfsQi6cGNLocCL4X9MhB
    3Nrs2ltTCpklo9k4THp0BPRX+/Xbwn6Gb89OKdGppKVRaRkDVbF9Iuvks95g2jkZaLuxqHrGc9dm
    yadwfj0680bTpoXsayLz/Hpz/zs2bHxow5pZWElrhFpjQgYP667cyOJoCfy0b1ABII7+hwocpM8w
    /TG6hIIIPtv2DrJbK3vL6BQBJGZA8EwXNXV/EjOGCjxHhLqfuS07gf+99o0mIJDrLRYO07fQmTbb
    JyPPnfDUw9munv2MaSMzrfSoDW0jFNJokM0f2nNGbe8W4Q9Q9qj7jOeDfiTC2M1FSB68VQChA4E1
    Jdp6upUoM+2t+tLOVS+rYKqeXaiNUveI6mAGCm2xatQgtfedds5vgT56hs2mUzJ15UeDANLXZqr6
    6N3tt/n1dyh7pKFZUjAjUr4QJR51vWWDVes+IvhCredQ8gT+s0A/w76JKEHCKnIE2MjlqHRoxsKJ
    dje2U/YrKmBG1OM4ycaIqgrtpxruG5mLj9o5Fssgof8c0DP2DWvhoGUMPH69t6+hr1ETU5Zkids4
    emzUWJFa7ZGlERAFEA3wk/zfM7IJGHXnBU3GnqC3FiT0VI6NALh1Efc0zKRXnxH9FZ695U2xVs5I
    iTAFySK8wOiG6z3SUFNnjH+fwL8P8rNAz6z7WM53tVgykbPlmVVoV1o4dUfYz0rB1Bqqx6dn7ZzP
    n79A0LON0jL9RFPGEvjPUPPM5x3t0yP9CNmZrv38EX0OXcBl6+Zos56tLZz/H+8bG3ABrv9848Vp
    5bQa6nH5/d9lVvVfG+Kh3Ofz9vK/n+Pyd/34+7/3fzYGa3ZTx/VzLB+fe73cXgbfV+87lMbzZ6wF
    PWPZobNBi0U6w8Kxqn2kTLl1jWx7C2c17CvZ8Xvg78HImobZAv6pAPsv4PbyAfMr6D9/zsvviB18
    rc9xBvAt33uG7yQxBCzozFiE2/fhgfmfUdX/AfaNtjHSs6/lERbOncq+BQMNLNJRpLUBQVEaZLko
    +AMAPKvme8D/BP0V5MUB/F7jK0rjKYuAnyp/nZoXwRdcGQWPWIpMzvwI7H9Oi3RGqXEtm21bC+f/
    x2vicxfgujL4XTr3L8r9Rz+t+x7K36Mf9LHH4L2h70GDZe9+Rbmv9j0I8ZzWdpERp+YRdR+1+5wp
    /T2C+R/480/5e/RzNn4zac8nAP5tLZw7YC8EZBDwW2CJgPswwp4dLAoxOCDvdQRyz3eBAj+hvzfo
    vSXBmZz5kXL/I4H/D7yOAf2fU/n3fHomoaH+IuwR0BdFFQsJVFGUuQf4yONF+NlI6zMrwMAgk4Cf
    Kv8+yGtqXUDQ96we9PxXZvNhD/Y94P8j4K+BHlH1KOxFsDpCEW2hPgH2UVYOCimvwj+cyhyFvhig
    L4p61+whuQH4Cf0Yb9aq5lHQW1MstWM8oy0cC+g1Nf8n2JkTEaWMb7Vw7oI9C5cRAL3q3grwqPsj
    QGfVvTwU+L8C/Ug1bwW9xa9HTmSbYeFYIc/YN6iqF8EWZrezcHaDvQZuRsVagBqh7q02jjajQVU7
    o+6LAmwL8BP6MR3bA3rkmEEm4+YUe00bVtWfRqhHL8oilWW176lO+L63hn2klVNAQDF+v+dHJij6
    qMwcBtIW4K9Q+d8A/pm+bTTokbOYLZuj/jrqPtLGOQGvXluUHaWWojMk7Xu9dWf5XbBH1T0Kbu1x
    IrGpmAiQDwfkhYS9JTNnNvCjof8U8EefSuSpecN49Mz5Cahd8yfY4myUsj8lJtXyVEAvwpUiud3C
    2Qn2qLoXxY5AoIn6+8fEWQG6+Mpm5yCfixiAjwwCrMr3wrs8GO6Rah5V8h77hlX0f8F+fUTmDZpq
    iaZcot9pNYK/PhH2EVaO5sNbHseoe2TwKEHPo/npkXbO59+V+P6iN12VSW1sR7izsBj9zSzIRto3
    zKLsTNCfTp/eourRHbPbFQO8E/aolTPy6xkbSLvO67VH+vUivO0jhoGQ+d5W5OCXhW1vFdg9kLfa
    Nqh9Y6laiUCdtXBOWavoWVUvgufQb1sRdifYM0Bh0zAlGNwSDH8h/fgCQty6sxb9Pi3rAej//5aM
    nOhaN1H2DVJ+2GrXIMreC/lTuHz60eYvTdUzp8GNBuDbLJyVsPeqe8S/j1L3IlyGTcRAgcKb9eSt
    IBYjuGdl6JQvBDwLeYt907oOKWLGplKiSp8F/SlzFmSZevaoqrccMPOVyj5K3WuwFBLuXjtHHODX
    bBlG3RfH52yF6V1pmeXhgEcVnTXrhvHnkdIHjDeP2jceKwdV9B77RlPvSGVLa7pl/QbYox4uk4bZ
    U7rIzMCq0iXw+TTPPlLdo7aPF7arc/HLA+Cu3T9KzVtAj5Q60MA9Aj8K64iF2JGtVINUPbOugn7H
    X6fso9W9Bn4B7RqLx8/mx4vYUy9loPBRiLP2TCEbZVEGajFA3wvyciPYLYCPUvMifF169ujNP0XV
    a169xabxLMYy9g2j6pkB+vbYHfYz1T0D/INU9yz0EZWulTzw2jllwve5e6bOasVvtW96G3ciMm/Q
    SpUa5BGIW60axJ+P9Om1Mgk9nz667tGjYY9CCPXgEXWvQVibATDqHvHwxQB7xDpBBwjGb7c0yEJ8
    tx4750mbqqIqV3qtG9a+0UodsBaOdUBAFmLRQ8iZA8a1RW/UxtlC4e8K+xGIkNx7tiqmBfjorAAB
    PqPSC2FfsVDUPssa+FwzfPyyCdyZTj8zjx6xb9BTpWbufEX9/NmgF8Fr02uqfot0y7thP1vdo6mc
    kQqfBT4De+8RgxGWCwK0Egj9CPBbH7fCn59RvVJI66aVc/4nWK0bSyrkrDx6BvSnwbpBPPztVf3u
    sGfUPfpc0cCXIOB7YI8OlNbvphgB5v3Od7Z0Iqwbr6Jn7Bs28ybysJFIW8cLeut5u0J8l7eq92+A
    PWqZWM6rFdAz9wLfCnvWuxfycXUxQBn//s7a+Ctr0Ecreq2KpcW+YUH/T/yHjvxNAP0JKnwRrv6N
    R9XXb4b9KnWvWTki3CKpBfjM/0OKwbGfrzetMlrhWwaB3dS9J6XSAnmLR+/dNBVhxcwqU/wHvgct
    +0Y7pAT5jm/Pn/9G2FvUPevfM/e1VN/0wt4DvRU569aqf7PfUwkG+g6KHimFYIH9nwP2/4y3M9Ur
    0dr0rFePDqBWVX+7tXMn7Feqe+Sx1vNte/aMNptgr4tUsMXZ4ArR0Kvzte2Sjz+jYmWkbWPZNFUF
    y6WfoezRE6eQlE80C8fj0Uvne3mEqn8i7BHV6zmgXADPHEmLZFS+xcJhFe3sPHqLXWFtHzNmLlHg
    t6r5HkQi6t2gqt7r1f+Tvl/P+vZoXj96jizq01uUfXVYO/WXYD/DzmEBiNSHR60jdoDy+PVRO16L
    zEkZY9R9DW4rHtU/u2JlpHWjVbBkDyKxWjgMzC3gb23asir7EwQ5soHqUfFNsBfRN1pZLBrNjrGu
    FaDwZ4HvBWd0jQ9UDUXZPlGK32vbMO8dye8WsZ0dO/KzI44P/CfjLByvuh+Bf6TqT3D2g9g4j1f1
    u8B+lrovgu2yRewcNEMHGVxY6FstnFVWxfV1RVUFjPBEy6TPgH2fszx6dNNUhH1zEqCPzMZhDk/R
    3q91cdba/n66EJqlU1qAaSnDO/LeR49h6/SgMI+wcWZZMZZNRcjJSzMtoNmwt54sZQU9uhhrOYhE
    28X6T2yePToYaLt0rYuyiIXjmZ1ulVe/K+yj7RwveL2WDuKTs+8F+TzEAWcv8CM9bHagmTFV3rH8
    Qe98WCvoI2rgaDaONROHVfOj1EtPuqW1Df7sgeOR6h6B5agOPGrnaK/FMtBY3gsC/NnTSgb41m3m
    M4HPptVFKfpZoO/lzkdtnvJaOP9A+P9zKvoKAN5q4cjge/T0p5qwj1f3XjvHs3kKBb0X8sUIaY//
    yJzL6ZkWI/DX6osj8LfkV2uvx1uOeHTgN5JWyYLeW9nyH3AZ9fVXKHpUlEQvyqayd6p7DfQRPjo7
    SEhnwECfywv5SO+dUeFeL589kUmDfhXfbIY9tMJbax5RpJqqPZ2gR48N/OeEviX1Ekm3RBW9xb7x
    Rk3Y24CPbGyy2DmjwYJduLXkf0cUCkMshggrZwR9NEfZ628jFkxEpUOk7nk08LXiZVbQIztlrco+
    AvoeRW85fQq1bx6VavkE2N9h5zD+v8WGsVarnKnsWRsGsVM0lW2ZcTA7S2cre9Tr9cD8VKybCMiz
    9s0JWjEo9LVF2T9l5qHB3XpQCWLfiAH0aeNsYueglk4RPD3TAv3oAz0q4bsjDRVZWEXP7GQPbLYe
    tO1V9lbgI+l+qAo9A39YNW/dPcvk3492yKJljEdrGlqFSw3yEQp9i0HgvSnsq/g2xHw+/r/Lo1OV
    KgDF83+Xj8vf3hkJ20hQq+LVuP6l/K7/e3/H5brz431/fp6fl0vj8nH5mykkx24wa33GNagtaoMm
    M0BF1LrxZN8gGTiWwmeWxdgR/FHgo4Mr8pnPsG9S2S9W94ydY7V02AXfEvTeLcBCrBzWfmGOdEM8
    0og67xF+OQITDTw9O0a73mvZjCwcFvSn+EsZM5C3/FiAj84urcp9m0HgvTHsR+r+eltPyZeGsi/k
    F3RV98elUf33nGfAIObxkzWVXy/q/fP6o3O5Ba/jMrs5BspeU/VMuWjrzmLvDJG1srwDEVvczFr/
    htkxGw12y4ElyGIsa930vhPGxpQngH532EfaObUB+R70S+eLPRsWzsjSiQC7JS9Ys3Batk0P+q2f
    lrXz32Uv7K3A1ywd5vtgOrt1hiPC17uZtVP2dICeGQgsZ8yii7He0sWoFfdI++YpsGcGgpa617x8
    xL//D2TSgdyd71/7eQ3+1tR9S+GfDfD/d931txX2kad4RRdCm10GAS1upuXY1w40UVU/6xjCU/Ci
    Z0wN+14de6uFqK3VPMq+eYJnP9O/Z0GAHlZifX2zp4SM/cD4m6zCnZUVw7x+ryfPKO1KevBojjkD
    Sm/hsUi1fyqgt2yi8pxEhaRZPrZ+/Tcp+wg7p4Bf6FXdS8POOQNAPFLwL/B+rZ9DUfdHR9Efyk/P
    zvlU95+Xo6yc6LISyHcSdfgI6tVH2TeshXMGQF6zbDTQo1bOqah6ET0V1+rTP0rVPwn23sXaEfxF
    uEXblm2B+PdW2+AFQv01uL23QNv63QP9q2PlHJfrPj+X0rg8G/bsmbUrLRzkpKlo0Hthf4pd6Ws2
    EQv6UckIZH1rhU+/7UzgScp+RnZOL//eu2h7Br3fF6DwNbC31P0B/D4GSn8E/HL5fVX5RQG/GPz7
    EeyjKoR6avhE5NR76uFEw/4UmyevZdugm6gsp09ZffqviV+xcUQBvwyg39qc01u09ah8TVm8OoDX
    Mm8iYF8VNf8J96Mz+7kC/hT7Ym1rvWR2OWjPISSRqZbaWsBfIOxPEuhMKmWEmrdUt2R9+q9Q9U+E
    fYSdw+Tfj3ZhngOwiaLyWZC8OuoezbxhYH/+77Ea7Aug7nt2jmbpCAn92Z59pIUzS9VHwP4UXql7
    LBu2JEIP9KiV83M+/dOV/QzgywX67KYJC/BZqLSA0hoENMAjir42IP4CYF8G4L+C3qLsPWf1lkDQ
    j+Cu+fSrFmU1Be2xc87JkI8GfRVuV3baOA8dDKwLtsgX/2nnWIHPQP8FQH60eaqCir4F95ZPP4J9
    afwegf4Qvaw0C3yPjYOCXiS2UNq5IexHtkzU4qsF9BHpldXQJx+n6p8Me8/OWnTB1pKWOQI+unuT
    qan9agwEWnolquiR1MuR0h/BvgX6cxMrhyl8FlHsjIW9NeXSCnv2OouK1xZjT8HLIWjfxU/59N+i
    7K12Dgp+xLdngM9m6WinIbXgPoJ877ZPFV8V64YFfQvyBwB6BPhX8Eere6R4nMjcDJwKqF0P6C3Q
    P0HIjzaE1UmgF8W2+VnQf4ONM2vB1uLje4AfURESLV88gvwV8P+9/hcB+p6qH6n7Q9Ys1I6uZw9X
    mbkwi5ZGqIKXGbDA3gN46wlTHtAjefQM6NOz/0L/fmTlMLYOAvxWLZ4DUPitBt0DO5paWUFF/+nX
    vwaQbwEeUfe962bAvgRZOF6v3lOGIeK8WRb6EYC3ePNW0CN59MyBPY9W9d8Ce9a/9wAfUfka8BEv
    H1H5PVvnAOGvKfqemj8dnr3m3Xvz7lfC3uvXewqeWT17BvZWuP8FKvkZoP+ZBdlvVfaMnXMn8BEg
    jZS+VgrhpcCfWZBFPXvWxvEAXwjoz7JxvBup0EJrbH49a+Wwit+r4lklPxv0X1G2+FdtHBb4lgHB
    A/wC2DpX0KO+r7VWvcW+QSwcyyItk2vvybdn2pMG/Ui/HrVvvIeNW+DvAb0GeCR3fifQ14T9s/37
    lqoXRe2jwC+KykfBr1k8tQN5rVY9qua1PPsier49quyPDuAttXIswPdk4nhq1jN+fXWCHk2R9G6G
    Yuwa9MjHBH3CnlbwlpRMa2pmqwLk2VGkI4Xfu75XD2d0mHgP6taUy9ECLQt878Hkszz7qCwcNhuH
    tXJQODO+u9ePZ3bCRuXQR2XePN7a+YUdtAzwPdUyLTtuERviE/BVAf+o4iVTq34EfIuFg2bkIMo+
    Kv3SYuNYzpqdoewjDyG3DggWFe+B/GzQ128G/TfbOLsDv4DQv4J+5OcfA7CP4H8YbRt0Q5Unzz5a
    2RcC8CuUvbdUQjT0vQXKGKuGgfydoP+a+GbPfmZKZg/4THigfy1/oF0366fIHM8+qjCaR91blf0M
    zx4tb7zipzr9eBby2kJ4D/BRPnweS/ilg4EX+Br86wXwn5C/evqM0teAz6rNCDXPWjjRJY9Zz96b
    ehld1ng28KsT9KsgbwE9uonqZ0D/C7CPSrmMAP7nc50Nf/qq9hHo9wBfnDDX7scq+ihlz6j6mSWO
    2eMH0Rx7ZnOVxdKpjtsRL16DvPVwcK918/Og/xVlPxv4Lc++dYxhUeyclrrXoI/C/wp6dAA4bwC9
    Vur4GxZoPVaOBfjM9VGpkwjkT+AzQwGfoE/YTwW+Bnmvl38OoDaqfdMC/hX0J6jmT8EXYCOLoK1O
    vbzDxrEqeyZLpxoAb02b1ADPZNrMAr38Iuh/zbOfAXyR/kItOghUxdLpKXwN/NfLBQT/9W+t5o21
    PMLsxdm7bRwhAO8pilYN0I5caLWmUUZZNizoq/xo/OqB4zOBb1mw7Vk6I4WPgL8q113Bf3YUfQ/+
    ZxDkZy/OztxB61X3XjsHUfnMZcaPjwC85/jAaNBnieMEvlrjHknJtFo8Jwj++gHNntK/vr4W6D8v
    nzI+TNwD+VWLs1oxNHQzjTXPXgg745wIfU8mDevHs3YNquoT9I4o8rtRDLePrAEEQKPLo+tQRdz7
    G0mD1ABusWsOw3u482jC1UcSMureYq2cxHUe9e6xa5CDYBL0qeyXzwCQnbeezVdM1k4LhvUCz5bC
    L5fLLaXf+x3hzc/w6qOPJrTaONHqnoV9nQB3VLmfEq/kI06ZStAn7KEdthHAlyA7R9uQpQ0ALdAf
    4CBVlIHACvhDYjNwIlMvPTYOq+yjFm4tg4InZTJCyVv8eQT0lfx+f97KyM/AZ+l4bR0xqGAErAd4
    20FeZ7FqVvr1xQCAGQeYMAC1Alq7zrvQ6kmjZAGfoE/YP8LDj/TztQHgIAcC5m+rTRMJeouyt7bz
    SgCfUfYivFJGoRy1oGrNqGELl7ElD6JSK2sCLiNC5fdA01OeCPitKt+q/BGF7l18jd5ENXuBNtLK
    YYHPAhqFd5RNw1g11nTKBH3C/lG2jqb2e9fNgP9huD3Ki49clI3OsdfgEgF8cUDWu2Bq/R/V8L48
    vnyCPmH/9T6+R+VbB4DDeZ8IuN+t6leqe9TH94LbY8ec4GtlAO+tVFkdEK8JtYy7gc+q/Nng9wKc
    eRz62qO9+kKAgC2IpsFexO+HR3jqd/nxqeYT9l8FfEZlelT+KvDP/hGJWZiNsnA8Vo6AUJyh9L0e
    +4zsmoic+QR9wv5RwI9W+SL8Tlwv9CMHDeT1ee2bSNh7FH5UhcyIWQHzXMx7EJlT0yZBn7D/ClvH
    o/I9ah+F9qr79V7zTrDXYIX41R5rx6vEmfuwNk2EVYOWJk7QJ+wfBXyPykdAGAF9qyXEPI5R8jMX
    ZVeoezEC32r9WG5nId97n3ep+QR9wv4Rtg4Kegvs2UGAHRxYkM8GfTF8N+wibTTw0QGAgT96Xy/k
    o9R82jYJ+8d+bqtVPjoQRHn+EUCP2CU7S90zwPIC3zsQWAYLBPIeqybiqMAEfcL+p1V+FPSjYG29
    jVXwERbODGVvgb0F9IwVxNweDflVaj5Bn7B/LPBZlT8D+h5oWxV7FORLcBtH1ehs6EcODFGQZ4Ef
    reYT9An7x9k6VpW/SvFHgpx9Ldp7QhW991jCWbaOBfhRgwMzEAl5nVXNp22TsP9J4K+EvgfWXphH
    q/kS0NZRELEgjFD93sFgtoJPNZ+wz890grXDwDAK/l47hoH5HaC3qvtI4DNAjoL6bMgn6BP2CfxJ
    1g4LfY8Sj1Lt6GxFg35k20aghVo6M2BvhXlCPqGU8WXWzizLZ8bv2ZAvTqgwFgW7wSga/tb/txPk
    E/QJ+6/9jGdA3wP7VUBnBibUvols2xHqfqbNwz53JORR8KeaT9jnZ+1Upl7ozxgAVqh4y+Ypyxm0
    MxV+1ACwAuze/PhU8wn7/LwXQH+W3eOBeXG8NivsR/fzKM4I2EdDPCofPmITVKr5hH1+7sHA90Df
    CuWowcIL9yfBPlL9R1oyd0E+QZ+wz89+EfS9Sts7IHjBvzPsI+2TGRkz1oXWhHzCPuNh0I8GcDTM
    o3PpvbC3AtEC30iPPaqscDV8Ngn6hH2G8XuIgH6k+p8F8hWKPlLhe1R+xIDgBT7zvhLyCfuMTaDP
    wHHlQGBR7tH59JZgbYsa+HfkYxPyCZeMH4P+KvBHKvZZFk4kuGaD36rQ7y5GlqBP2GcEfjeR0I8C
    v/V5PHDfQdl7VfVMjz0hn0DJ+BHozwL/atVeJnwuUbbEHQOABfDIe7YAOyGfsM94CPQjwD8b9ne1
    Y48CXjEI3KXiE/Ibxys/gp8ZoFeC3wvv3QHjBWWEtVID/3fU55mgT9hnPAz6VvBbIf+0GWZ0euZM
    uCfgMxL2Pw79FeD33r7rIBBV68Vz+52AT8j/GCgyEvwzbre8jzvacXR++Up4J+QTDhn5vYaCP/q5
    dm23s9IRd9nQlIBPKGQk+B+j2iMOHL9b/a8EfEI+QZDxw9/1TFCXh7Tluvg5Vm9iSsAnADLye3c/
    R/mhdlo3fkxCPjt9Rn7/tzxXeVBb3gGuCfiMhH3GNm2hZBvdEsoJ+OzgGRlT20X5onZcH/b8CfiM
    hH3Gre3k19tffehzZ2Qnzsg2k+3yJvAm3DMS9hlf14Z2bLf1R/5nRnbUjIzt2lTEa9kJqAn3jIR9
    RrazL4sEe0Z2woxsewn2jIzscBnZJhPqGRkJ+4xsrwnzjOw8GRnZxhPeGRkZGRkZGRkZGRkZGRkZ
    GRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZ
    0+P/CTAAVOoeKnrJ9qIAAAAASUVORK5CYII=" transform="matrix(0.24 0 0 0.24 421.9792 403.4)">
          </image>
          <g class="green-circle click-pin" data-id="3" data-title-pl="Sprzedaż" data-title-en="Sales" data-color="#BFD73B">
            <circle class="st583" cx="459.915" cy="441.391" r="25.851"/>
            <circle class="st582" cx="459.915" cy="441.391" r="25.851"/>
          </g>
        </g>
        <path class="st583 pin pin-label" data-id="3" data-title-pl="Sprzedaż" data-title-en="Sales" data-color="#BFD73B" d="M482.232,441.332l11.872-6.854c2.966-9.839,12.13-17.06,22.903-17.06h149.165
          c13.153,0,23.915,10.762,23.915,23.915v0c0,13.153-10.762,23.915-23.915,23.915H517.006c-10.773,0-19.937-7.221-22.903-17.06
          L482.232,441.332z"/>
        <g class="pin pin-pl" data-id="3">
          <path class="st56" d="M538.212,446.613c0.648,0,1.333-0.036,1.765-0.307c0.414-0.233,0.595-0.72,0.595-1.278
            c0-0.396-0.072-0.756-0.324-1.098c-0.307-0.36-0.811-0.486-1.585-0.667l-2.106-0.468c-0.954-0.216-1.675-0.576-2.215-1.08
            c-0.647-0.612-0.864-1.477-0.864-2.413c0-1.351,0.541-2.196,1.369-2.755c1.008-0.666,2.124-0.828,3.799-0.828
            c1.422,0,2.953,0.162,4.268,0.595c0.18,0.054,0.252,0.162,0.252,0.306c0,0.126-0.036,0.234-0.072,0.324l-0.54,1.513
            c-1.044-0.396-2.737-0.721-3.854-0.721c-0.846,0-1.404,0.054-1.782,0.396c-0.234,0.18-0.378,0.504-0.378,0.882
            c0,0.522,0.18,0.937,0.647,1.188c0.396,0.216,0.883,0.36,1.513,0.504l1.963,0.433c1.801,0.414,2.971,1.206,2.971,3.601
            c0,1.423-0.504,2.395-1.332,3.025c-0.937,0.702-2.359,0.9-4.088,0.9c-1.134,0-3.042-0.198-4.303-0.666
            c-0.145-0.055-0.216-0.127-0.216-0.253c0-0.071,0.018-0.162,0.071-0.323l0.469-1.531
            C535.655,446.379,537.024,446.613,538.212,446.613z"/>
          <path class="st56" d="M549.282,448.053c0,0.216-0.108,0.378-0.379,0.378h-2.214c-0.217,0-0.396-0.126-0.396-0.396v-11.667
            c0-0.288,0.162-0.45,0.45-0.45h5.798c3.367,0,4.969,0.774,4.969,4.249c0,3.151-1.692,4.304-4.753,4.304h-3.475V448.053z
            M549.282,442.256h3.114c1.495,0,2.143-0.559,2.143-2.07c0-1.314-0.468-2.035-2.053-2.035h-3.204V442.256z"/>
          <path class="st56" d="M563.214,448.431h-2.558c-0.306,0-0.432-0.162-0.432-0.45v-11.613c0-0.252,0.144-0.45,0.432-0.45h6.158
            c3.007,0,4.627,0.72,4.627,3.727c0,1.639-1.116,2.863-2.322,3.296l3.025,4.969c0.054,0.091,0.126,0.217,0.126,0.288
            c0,0.145-0.072,0.234-0.253,0.234h-2.916l-2.396-4.375c-0.144-0.252-0.359-0.433-0.846-0.433h-2.646V448.431z M563.214,441.859
            h2.845c0.773,0,1.386-0.216,1.782-0.558c0.36-0.343,0.685-0.774,0.685-1.495c0-1.134-0.595-1.656-1.945-1.656h-3.366V441.859z"/>
          <path class="st56" d="M577.235,446.199h6.59v1.584c0,0.396-0.198,0.648-0.63,0.648h-8.985c-0.414,0-0.612-0.198-0.612-0.576
            v-1.242c0-0.271,0.127-0.504,0.217-0.612l6.481-7.851h-6.626v-1.62c0-0.378,0.216-0.612,0.648-0.612h8.841
            c0.486,0,0.666,0.198,0.666,0.648v1.08c0,0.324-0.145,0.54-0.252,0.685L577.235,446.199z"/>
          <path class="st56" d="M586.612,448.431c-0.216,0-0.324-0.09-0.342-0.306v-11.848c0-0.217,0.18-0.36,0.396-0.36h8.426
            c0.252,0,0.378,0.126,0.378,0.396v1.836h-6.211v2.917h5.726v2.143h-5.726v2.989h6.211v1.891c0.019,0.216-0.09,0.342-0.342,0.342
            H586.612z"/>
          <path class="st56" d="M598.204,436.332c0-0.216,0.198-0.414,0.45-0.414h5.113c4.069,0,5.941,2.179,5.941,6.212
            c0,4.051-1.89,6.302-5.941,6.302h-5.149c-0.216,0-0.414-0.145-0.414-0.378V436.332z M603.479,446.199
            c2.539,0,3.188-1.729,3.188-4.069s-0.648-3.979-3.188-3.979h-2.286v8.049H603.479z"/>
          <path class="st56" d="M620.292,445.352h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L620.292,445.352z M615.971,443.462
            h3.763l-1.584-5.312h-0.595L615.971,443.462z"/>
          <path class="st56" d="M629.38,446.199h6.59v1.584c0,0.396-0.198,0.648-0.63,0.648h-8.985c-0.414,0-0.612-0.198-0.612-0.576
            v-1.242c0-0.271,0.127-0.504,0.217-0.612l6.481-7.851h-6.626v-1.62c0-0.378,0.216-0.612,0.648-0.612h8.841
            c0.486,0,0.666,0.198,0.666,0.648v1.08c0,0.324-0.145,0.54-0.252,0.685L629.38,446.199z M632.278,432.316
            c0.216,0,0.307,0.162,0.324,0.36v1.692c0,0.217-0.108,0.379-0.342,0.379h-2.106c-0.234,0-0.343-0.181-0.343-0.379v-1.692
            c0-0.233,0.108-0.36,0.36-0.36H632.278z"/>
        </g>
        <g class="pin pin-en" data-id="3">
          <path class="st56" d="M566.945,446.676c0.648,0,1.333-0.036,1.765-0.307c0.414-0.233,0.595-0.72,0.595-1.278
            c0-0.396-0.072-0.756-0.324-1.098c-0.307-0.36-0.811-0.486-1.585-0.667l-2.106-0.468c-0.954-0.216-1.674-0.576-2.215-1.08
            c-0.647-0.612-0.864-1.477-0.864-2.413c0-1.351,0.54-2.196,1.369-2.755c1.008-0.666,2.124-0.828,3.799-0.828
            c1.423,0,2.953,0.162,4.268,0.595c0.18,0.054,0.252,0.162,0.252,0.306c0,0.126-0.036,0.234-0.072,0.324l-0.54,1.513
            c-1.045-0.396-2.737-0.721-3.854-0.721c-0.846,0-1.404,0.054-1.782,0.396c-0.234,0.18-0.378,0.504-0.378,0.882
            c0,0.522,0.18,0.937,0.648,1.188c0.396,0.216,0.882,0.36,1.512,0.504l1.963,0.433c1.801,0.414,2.971,1.206,2.971,3.601
            c0,1.423-0.504,2.395-1.332,3.025c-0.937,0.702-2.359,0.9-4.088,0.9c-1.134,0-3.043-0.198-4.303-0.666
            c-0.145-0.055-0.216-0.127-0.216-0.253c0-0.071,0.018-0.162,0.071-0.323l0.469-1.531
            C564.389,446.441,565.758,446.676,566.945,446.676z"/>
          <path class="st56" d="M582.877,445.415h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L582.877,445.415z M578.556,443.524
            h3.763l-1.584-5.312h-0.595L578.556,443.524z"/>
          <path class="st56" d="M592.037,446.261h5.059v1.854c0,0.216-0.09,0.378-0.359,0.378h-7.347c-0.216,0-0.324-0.09-0.342-0.306
            v-11.812c-0.019-0.217,0.162-0.396,0.378-0.396h2.215c0.234,0,0.396,0.144,0.396,0.414V446.261z"/>
          <path class="st56" d="M599.182,448.494c-0.216,0-0.324-0.09-0.342-0.306v-11.848c0-0.217,0.18-0.36,0.396-0.36h8.426
            c0.252,0,0.379,0.126,0.379,0.396v1.836h-6.212v2.917h5.726v2.143h-5.726v2.989h6.212v1.891c0.018,0.216-0.091,0.342-0.343,0.342
            H599.182z"/>
          <path class="st56" d="M614.95,446.676c0.648,0,1.333-0.036,1.765-0.307c0.414-0.233,0.595-0.72,0.595-1.278
            c0-0.396-0.072-0.756-0.324-1.098c-0.307-0.36-0.811-0.486-1.585-0.667l-2.106-0.468c-0.954-0.216-1.675-0.576-2.215-1.08
            c-0.647-0.612-0.864-1.477-0.864-2.413c0-1.351,0.541-2.196,1.369-2.755c1.008-0.666,2.124-0.828,3.799-0.828
            c1.422,0,2.953,0.162,4.268,0.595c0.18,0.054,0.252,0.162,0.252,0.306c0,0.126-0.036,0.234-0.072,0.324l-0.54,1.513
            c-1.044-0.396-2.737-0.721-3.854-0.721c-0.846,0-1.404,0.054-1.782,0.396c-0.234,0.18-0.378,0.504-0.378,0.882
            c0,0.522,0.18,0.937,0.647,1.188c0.396,0.216,0.883,0.36,1.513,0.504l1.963,0.433c1.801,0.414,2.971,1.206,2.971,3.601
            c0,1.423-0.504,2.395-1.332,3.025c-0.937,0.702-2.359,0.9-4.088,0.9c-1.134,0-3.042-0.198-4.303-0.666
            c-0.145-0.055-0.216-0.127-0.216-0.253c0-0.071,0.018-0.162,0.071-0.323l0.469-1.531
            C612.393,446.441,613.763,446.676,614.95,446.676z"/>
        </g>
      </g>
      <g>
        <g>
          
            <image style="overflow:visible;opacity:0.3;" width="375" height="375" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXsAAAF7CAYAAAAzPisLAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAR4tJREFUeNrsneuS6yyvhIUn93/F
    K2b/+d6qlLdB3ZLA2JaqpiaT0+QAD00jhEhGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZG
    RkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkbGWlHyI8jItkxFzY86IztIRka2
    0RwUMrIjZWRkO8yBICM7WUa2tzdHDgAZ2fkysm3lAJCRkR0yI9vRzNdwNYBzAMhI2Gc8tu3crZ3W
    h/6vjOywGdlWsi0uAueEf0bCPmOJ9pFtbh6gE/wZ2fEybgv3VdtsfdnzZWTnznhxGyjZJofBti70
    WjIS9hkv++7LDR+7GsjrBf8zwZ8dPiO/7yHPUV7eLuvg+0dBO8GfnT8jAX/5fZ+o7Oug51/JMspI
    2Gcs/t1GQ7tMeN2j2/MMy6Ve8HwJ/gRCRgLedL8y+f9FPcdsK6ROvI/3/SX0E/YZN/8eZwE+YoBY
    ub1GAXf07Qn+hETGi76/CMAXx/OPeuzKoK8DbvM+1gvvhH7CPuPGkI8GfAn+P6u1Ww/o66THRAxI
    Cf2EfcZLAR9xfTG+5ifDvgY8x1XgT+gn7DMu+J5GWCllwnVe1R/VxqMWWr2QvmpASOgnRDIW/n48
    Kj4a8LMHgRntOdJCsV7neS4P+BP6CfuMB0LeAvNRj/HAn71/hJqPVOwRj4kaDDwQT+gn7DMGw2s2
    4KP/Ru8zo02PUO6z/44YDKIHx4yEfX4XF0Ge+dt6m3ZfBvKRn5sXXtGAt94WORAgn0tCP2GfsQDk
    rwL8iEEhCvrR6t4LefS2Gvx8zECQ0E/YZzwE8l6IR0J/ReBHgZ6Fshf2ntkAOwNI4CfsMwaB3gL5
    0VCfMTBcAXzrwmsNuBz9OHYQSOgn7DNeAvno65D7svBfRdmPgvuIx7CDQEI/YZ8R+FmPhPxIwLO3
    Rc0Goj5fa/aJB/bsdaPuPxr6CfyEfX7GDmsiEvJWsBfHYyJmBDNUvgfwEVC23If9X9ZZQEI/YZ8x
    UM2PgLwH7NbflhmAxdqJUvYa1CyKPhLsEc8dYf1EWjsJ/IR9qvlg9R75OxL+yEBwtbIfAXnP74gB
    ZAb0E/gJ+9er+dUgP+K2yEHA8nl6IDMD9N77XKn6E/oJ+/w8DWCaAXkW+OhzzFT90cC/AvRR10UN
    JiOgn8BP2CfklesifXgW/FGXoxQ/etnTxiNg71X0DMyt9/VYP63rtAEgoZ+wfyXorZYNc10k3K23
    jVb7s2wcT6ZNBLB7l1eCf6r8hP0rP79Rlk0U5BmAs38jt1mhb7V0vND3KHoW8sxtswaCSJvHC/0E
    fsL+drbNLC/eA2fPdewMIFrhM+3cmlcfBXvk76j7REHfU6ohVX7CPtU8CXcP5FvAjro+wu5hQR/R
    tiNU/UjAW66PGgBY+FtVfgI/Yf8o0LOWTSTke+BGb/cOCqOAb23jltx6BvQa+LXrtdsst8+C/kiV
    n8BP2F8Keo+aP95m9eE1YCMw9zwmQu3PAv5o0Ecodu99rcrfYvWwit+r8hP4CftHqfkoyHuBHzE4
    eKCPgJ9t6yjsvamUXuUe9YMONK2/WdXvVflp6yTsbw96q2XjhfxVP5raZ9X9KNhr6ZYM7KNUPPqz
    D4C+R+1bVH7aOgn729s2GvA12Fv9d8/PdjH4rbAfYeN4FH003PfAx7CzDRT8qNpHbZ4EfsJ+WdBH
    LLzOUPDb4Nst6wjaQNiCvrWNe1Mt0QVY7XYU4rvj8bvRSoqyebwqP4EPxic/giVBvwrgN8d1kWof
    VfmInWOFvgZ8D+xbPxsI5tKAeAHgXgCl//t5tm7rfYYFuL0e/sfx8tnfrevEcJ+EfUIeug9iITC2
    TUQevFWFb53bN5kH/tb7t6h7BvzRqt4D+70B/x2AfWk8T+k8BzITkM4AgML193WUw4BQE/gJ+zfZ
    NpFqHgHyZrhtBvg14Gsq36PuLQuz0aBvKfvt5O/eAHAG/tIYCCz+PwpVRuUfgS+dASCBHwS5/AzG
    gb4H+yi7xgvyzXDfkSofBb0V+lZlP1LRH69jLiP31W5rvRYRPstH8/AjsnUsp44l6PL9h1emRFMp
    UeBvBtCjMO9d5wX+JjZV703B9Hr2GvCRhc2dhL0F7uxtKPC96ZxiAH8CP2G/POi9ufJRVg0Db+Y+
    2v+IUPtXWDkzLZydgP1OXof+Rp7bmuKJKv0W+BH4J/DTsx8Kem0h1rIIa1XyGwH6Wb89vr72uWgq
    v/cdsweOs5uorPYNoub3Ez//eN3v7yLtxdCjb18Ofv5+4uFL57Wj/Y7183s+vnfh9lUefknQu0Fv
    8eejlfxmAP2I20b4+VY7x9u+EXXfAz4CfdausfxGr7PMACLUvsXWSYWfyt4Neja10rIL1gp7Rr1b
    oY1cjoa+V92vZONYVD2r6BG4/yr7M5XfU/575/fx/R0zhnZn/2ylYJZU+An7GQMBe5iIdwGWXXBl
    QI9etg4AXuhHbbLyAN9bC2eGqtcgj1wuDchbwX/29w70uQrYOUxefgI/YU+rO+9CLAp7S+qkBn0r
    yNm/UbXfW8DVwB9t4zCevSft0qvsWYvmePkI+XICegT8Owj+4zqCyP/fJ4Bs0mKUvjTgn8B/MeyL
    A+wRoI+ybJDfFoBbr0PAjy7eRqn7KN9+RNplhH3DwP4I/CP0e+BvAZ8d+HbjZ19ApS+Cl1t4NfA/
    CfopoNeuZ1MnGRXfuk67Db3eY+ug0Peoexb47KYqxsaxpFkycD+D/Rng95PvYD/53CsA/+Mg8Pve
    Z3n6CfyEvXlBdgToI2ybzQB65Db2vl7go3aOSNwirfZ9X2HjWH16BvQa9PcG6I+wPwP+0TbZO5+P
    B/wtpZ/AT9ibQe+tVGmFPWLbsFaN5cczIFiA7ymJfGcbh7VwIkCvgb2Aan8/fPaItaP5+TsA2iuA
    n8r+oQPBFaBH8uNnQD5qgECBz/r3rI3jsXK0bJwzNd8CvIiv3s0I0EcNClHQP4K/t3A7G/iPGgw+
    L4L5SqBnFmAtPvzvz58R4n8Bto7FyokqiuZR9S1lr9k3jJXjsXCiIf912I6R0L/K0tFYUhP2CXoW
    9LMg/+eAPzI4IIu3aFaOZZH2CmXP+PUivmqWbMZNBOi9A8B++KyP0D+Wc/BCX0jg9wD+Cv/+8yLQ
    y4Wg13a/tmDP2jR/jus8s4AIKydK2Y/07GcrexT0tQNyFvTfA8Atqn8HPrtf+MvhMgN4FPga4B8P
    /M+LQF8uAD1boMzjw/85//bA3mPlRHr2XnUfWcve69m3VD6j8DcF+qUDfwb6rf7WAv9R7VtU/hXA
    T2V/w4HgStBvAOzPrkOtmT/wtj/xq31009XIbJw72TieXbMeC2drAL8ooEegL42+cJZhsx8g31P5
    CPRnA//Wg8HnoTAXQ6cfBfpINf9HgP6PgH2EymesHCTPXiQm9dJi5TAHjVttHM+u2Z7C/xLALyDo
    j5D/gn1BOvDXvHzU2mGAH2HZ3Bb4n5eB3gL4EaD3qHkL3P8kRuV7gG9V96iFE+nZW9IvNStnNwKf
    zcTZOuAvjb8LOACcAV8UW2cnBlSrl48C/9UpmZ+XgZ453CIa9Jptw3rxCNTZAYC1cyw2zso59h7Y
    a559dE0ci31z/Lt0AN8C/Rnkv4qtIx1L50zdW4EvoqdlRqVkprK/ycDAnhlrBT2TUjkK8JaBwJuK
    udribHRtHFTZi4zZQdtS+V8S+EWBPvq7Z6+dwX/vfO4e4J9B/syvR4H/KHX/eQi8GZ+eOXBkBOjP
    AInaNcjvKNiP8Oy9sNdUvbW8sQheG4f17BF1b1H1vQqXXwX4xfEbBb90wH8Wv4u2kcBvKfvXAf+T
    oIeUSTTo2awaFOYjoO8FvhX2QkC/BXyrZ8+CngH+sSAYm5GD2Dmb4td74H68DlnrQsEfDXwR7OQr
    hDe3B/7n4aDX7oecNjUD9FbIs5db11nsnBIE+1FZOBHKngG9Bvz/flqg34J9+55989/lreHTt0oa
    H4EvIPyZQbgqXj4DfMS7R446TM/+pj79iF2xVtCzkEcvs2q/t2DbU/rFAPsrKl1GLdCyoO9Bv1f6
    N2qRtmXfHIF+Bv1WquX38D18G/C3Qv9X3Y8Gfk+lP87O+dwY3lb7pqXwUduG2TxlXYBlQM7+zVo4
    zKaqqIVZxMIRGVcqIRL4jKWjKXxrOWN0cbZ3+biZqgX3LzGDRuycHvAtC+/aRqvHAv/zMtAjcLAA
    3ppp44W8B/xe2FtUvVXRo6o+yq/3+PYi+G7aHuhbqYhnRw1a1D0C+zPAFwD6msIvQD9GgN9S97Xz
    vEhK5iPjjamXkSdLbcGgRwGPgj8C9qxfj6j61nUI4GfBvped4ymMJnKelbMp6t4D/GKE/dnf30af
    aYHe8z382ky/f/e+89aZtSJ4SuYj1f3nZqC2qnp0QfbsuXuAb22eYkCvwb4HfHZQYLz7iCwci7Jv
    AX+WX8/YOCzsRVH2VVH3VfqHiSOVLVnYtwqgfTv9JBr8Z9BvqXttEb6Vkvl4//7zAtBrPr3lgHDG
    n2dAz8AduR/r3bOwR8sjMBYOouatXj1b9dJj51gsnf3k756dowH/CHok80azajTwSwf03pkVkpqJ
    fseayvcAP5X9RQMEouYttg3q1bOgZ4EfAXsL8EeA3ntIyUjPPkLd92B/BvzSAP/x4PByct1ZrfqW
    wv8q1g0KfXQQ8MTx/aPrAQj0o/z7JQeCz42gzah6xKf3ePVakS8v6P8mgh5NudROp4pelLWq+tHK
    HvHurer+CPzfy78g2jqq/gjtXgnjr2ALsyMgXgggM9BHFm1LA/qP9u8/DwQ949MXwKNHKjaiNk40
    6D9O7z7awlnRvhnh2bOgZ4FfAHW/dVQ9cvKUpu4tJ1RdEUfon33ntQP4kf59KvsJlg3q06PefXEC
    3gv6T6DKt1a+ZDNwLPn07ILsKNC3VKU3FfMM8qLAvqXu6wnoe8cEsupes216/TEC/rUzyB7bl3TU
    fRV9ty7i39/ezvncAOCWDoxO9TUARZ4Ny4L+EwT/XjZOlFfP1L8RQtlfpeqjgc9697+w30/gszVs
    jDOFf+bHt3bLIpk3LNgtdk0l7nv23lkPn62jc0t1f/cdtKh9482nR3fK9tT85oQ8e30vy2eEfeO1
    bpD1lZmwbylCFPYo6C2Wzq+6RRQ+cpj4LrZF2DP4Rw6uvc+8ZelosEf9e8bOWV7df24AdM9jkHx6
    y6IsovBZyLfA/QmwdBC/3rJbdtbGKU/2TQkGfXHAnlH3PdjvJ7DXrJwW5L+il0PQLJwi4za0aWsl
    Z22ut2CL+vesnbP8Yu3nRqC3ZN+I6Bk4IvbSCKh9YwH9xzAQjAC9p1b9jI1TLGQ0pScE6FunIllg
    34P/LljFRka4aCWLrRZO5EKtZpNtHXV/Zukg/v1j7Zy77qBl7Rsh4IPsjC2gP28dAD4GZf8Rf369
    1brZOgNllEc/ItVSu19vul8UpXgEQ4SH34P+3lG5Lf++V7IY6TOjIH/2PVRlBtVT954cfM3OYRhW
    E/bxjSUyl76l6LXDwZnNUh/yMmPnRBxU0qoDhGbf9GZUjHUTYdsgyr7n54oC+nIyGLSUoQZ7kfNN
    VnsD8kfoVUXRn50w9RU8n97q0dcO0HvVQlGFr2286r0O1s65jbq/Y7kERNUjDdHi0yP1b/4ANY8o
    fBT6Z9chxc8is29GlSyOsG6KoY31rJ3aua5Iv3Z6bQwAIu2UzNKxc3plD7SdsKXj26OLtBH2zH9/
    /w1Q+D1bEFmAj7ZzLh0IPosC3TMIoPaNDFL0LbgzgP8Yoa/59dqO2VGZNyLcgiyj5kdZCoiqP+vE
    7KlIZwuxvfz7/eTvnp2zG9ZMRPCdssz5AS1F/zdZ4aNtZMTJVZcB/27lEgrR2CwLhT3Aaz59hD+P
    qPueZx+ZbjkC9J5NUzNBj3RQVs1pv8+AXwjg/4LpDPi9ma1Ie7FWZMxu2ZYyj1b4xyyl3joLa+fc
    arH2syDQI+wb5H/OyLxhNlV9AHWP+vaeDJxemiUL+zsqes8goFVMHA3842/Grvx2vo9vwIwc2WU8
    QuHv0l64Re2cnm9/m8XaJ1W9ZFQ9qujRPHpv2qXH2rEuzLIWTjToR3j05aJ2hwAfVfotS0ezedD6
    9D2VbrVyUF8e+d1S+C3QH88G2Br+/Vk7rMJl6KDf7ZLq/rMQoKNUvWXz1CZ6ZUs2A8dSJuFDqHwL
    7KPsm95nxto2Uar+SuB7rSBtRlBPbJpN+ou6++H+rX4g4Hej9S+LV68p/N/31oM/m4PPZOcIMVNb
    Wt0/5cBxBO6MhcPUv7HaNpY0yk+AV4/YN0iK5dV59MXYVqwKtSiKz+P3t+Dfgv4m+mHmFv9elO/k
    a4Q7WhUUgT5ykHtP3Z+1yQq0GXSxdll1/7kBuNmdsqNq1LPVLnu1cqz2jWUjFVv/JrLejVXNz9o0
    hcCKOeZOgz+q7uUE8tLw73uwr433o1k51rUwtHAcUh76zwD97UTdIyUVmPx7y2LtEur+TsoeTbUU
    0sKxljCOqGzpKXj2Ea66pWVBFoW9GFX9qtYNe7SdFf4CqPsz6J8NBkdrB7F0NIUfMWAiNf6PkD+q
    +wpCXzoe/u8AsBsUvketv7o2ToSqZ5S9CJdPz6p6Tc23QGzx4pFDSyyLskxt+ijrZoR9MwL+lto5
    Z9dVw2tr2QJH+G+gpSPK96cNwtrnw57qhYD/78S/R9X97+d+ViyOfa+3VfdPqo2DqJUzUPVU7Ezb
    xuPlW1ItIxQ9s0HHatmssBjL1s6JAn0PDD1Lp6XyBVD4kadOoYA/U/BnwBcA+C11XwHvvnRsHC+k
    X1kbZxVV31ucRRdpEdBHLNgyls0f4dVbM28Q28ZTi96TQz9qAKjg/+odeXf2NzoIMKpxa4BfCJix
    nyl7Hq9IP5X0j7zcWsDWUjKPCp19vxHqPj17UNWXYJ9+FOhHHCgesSgbXQYhStGvouoRsLfuh4Df
    q/xRhb8fgMfaORr4/hTYiwJ7za6JAH45fAba7toKtAcm9fLSmjmfGwAdVYJoVkhE2qUF9NaCaMj9
    LHVv2BTLGQXNmJnfLIVfHQOAFfwjFL7mn1vVfCsHvqfwe5U+UeBvoqeitoCP+Pfad4PslF5K3X8u
    hvoIVY/aDd78ei/oPXBHa9WPUPUWj96i6tH2Ui5qs9ayyGfgt9g62mCwn4A/wtKpwO1/DcgjC62a
    4v8j1L0G/AJYOuxGq2XV/V2OJWRUPWPjWED/J9wxhJsD6IgH/we+JrbuDWLfaMp+hDdfFm2n1QB+
    BPrMQu8vMDaDpRMxCzrmx5+lU2rqfjsB+O91G6HuGeBX4copIBvjlonPxRBnrreqehF8UZYtfKZB
    ngG91dLR7CXLgqyWZtmCfbR1sxLcmfbNevkVsBAsO3XPLJ39oPJ/wc8q+yO0Nd/ekkbZg/4fCPtf
    vz46O4edAVxm7dzhDNoRql7LvrFWvLSeVGWxgLRBJ7JksYhvw9Rof37moFADwa/5+C1177F0zuwc
    Fvhn8QdaOQJC/Xj71oD7BgwaVfB0zDN1jqp7b82coYPAqoXQNOBbVf0mXJola+n0oMxWw2QtGnQh
    1ptL34I9AvxIFV8WabPVAX7kIJQe/C2WjsfO6aVUolaOlkGDgB9ZtP09yatIOx2zKlbOKHX/WBvH
    ujA7yqtHDxY/grJn3VhKHbdKLWyKFaRl3xSJ9ekj1Pzs+jYrwr+A0Lcs3KLqf/tR+qydU5XbtJo2
    6KJqVfrT7+0tD/+sMmjpPGa2up++UHuHA8dRYKBefWQtHGv9emQhVzvTFlHz6C5Z64KsR82XGwPe
    Y+H07mNZuLVYPD3/voCWDrIjVkRPn0Ttl94M4AjuXfHwz/p/dap7BNSXqvu/RSAugqXxsWqdWVyN
    PlJQe9zvz9/JZaSUccTmqdZ6RS9dVYiBoDUjE3DgHtUmi8xJ20Tfb++61uBqWSdB+lzrsUxOfgUu
    HweI1vW9U6q0fQSMJYXcz+JMLHHYzl0OHGc6DHqYuDYgoCdURe6CRVI4WZVfJKYUgtW+YcEz2za0
    lu61vqZKqL4itvRMVuWfWTlHlV8VxV8BZR/l29eOtXNU+D0O1AvUPdpWwmcAVyn7K1W9BkrPZihU
    xZ/dhtawZ8oilI5XbwF9kfisG6/CHnEY9sj/gZT4sKh8RqGj/S8KVL2yCZUYQLTCZ4zCZ+7vUffL
    WJOfC0A/UvWzqh49c7ZIP9USUe/WxVZr2QPNl9/El2LphfyVbWpE2/YUF7Nk6fSUPZKl87tQ+/uc
    qHd/LC5WT0Rka9drK3++tRiLevgbqfAZdY/uqj37rpZYqP27CPasH2ndCYvWt/EcJ/gRXNW31L1W
    7dLq1TP59JGgLxKfQ19k7QVbz2tElX4h/HtkwdxaapqBkFYETXss+9NS7VZ1z/69bOLBFbBnLBym
    iJnVxvEeEo5aNr1BorUgaz1AvGffaKBH/HprnZvyMMBHvnZmgGRtndZz1AA7x7sYiizEWq0d5H8h
    g4NlATjaHlsa9h5VLwqMtg7wW7DXbJIIyCODQOtxGvxZqwdV9SL2BVlms1sE9O4YlvePAt+zB4J5
    HWwxOBT4FqijgwQ6i7B4/cj7H/GdPwL21oVZa8VKpjDZZoA8sijLpnOOWJT1gD6ysd5ZxY9Q+8zA
    GbFgK4bvloV/y84RUNUj1TERtc7OBlD75lKQI7FyiWMmR5j1+COyeTTP33KcIarS2YXX3sEUI0B/
    h7IHV4M/shxub0GwVXIB+U7YxVq0Tv3ZTlikv+zAddvhurMNY+hmq1ZVTLTuDbNQe1sbZ/bCrEfR
    t2ySnqr/CK7IP8oMgD1InFH1bDmE3gCg2ThWNf/WiFD5IxX+SHVvtXIYhd+zaFC/H3kPrfd91WE7
    yyh7TaEzb9xaGiHiSEKrymeUP5peiaxlaCofWXz1bpp6s5JHPg/LZp2z8goRCv83LfO3bWwdABZA
    3R8rVG6Kyv/v+t2g8FvqvpeO2auEKQ2F32rXSEnjInM2aU1X9mwWjmVhlj1wZBP+ZKkP8Le2KUrz
    5dkSxkyd+pmlihP0scoOUfmswtdAxQSrjFm1zTzGs1iLlHqwLNRempXzN7HRjrRw0HNjEftGs3uY
    PPnIg8S1QWtE9k0U6N9u2YywdqKBr80eCjkj0RY5PdBmb7cOLohdUyewMyS2BRo3a+EUwSowon61
    JT/fUlPe+oPYNz01L9IuAT0L9BljVL4X+NpMmsmC85zlHFF1lulDm3BVX5H9JxEieGgfWr2efU/1
    WGrYR4J+NPiRIxPZxlnEtgnnzmfF3hn4Vh8f9fA1714avn2v/IDI/68t3zpAZJd+iQQ0+wbpv/vJ
    6z97L9vhM2n1o9ZnqX1Pl2XlfC5qxMj1nsNJxAF6b517r3pnsmo2w3vXvPoE/XoKv04Afut7q2Lb
    /9I7F/YX2L2F2h7cWcHEpGL2+k2V9kJtxOazYYPANgnkkRaOx+dHslYYtRAF9159eUtevTZYSoL+
    UbaOx9I5mzWjYLfMSIuzn0QVBxwxMx5p5dzCs4+2cND8bxTsmoofOQAwHcbiK2qfYYL+ncBHoI6A
    ni1KWMTmrUf1K89pbewJbsh3MXXz4So7aBkLh5lOaotIPeU8C/CMZbMJviEK/QwjQJ+QX8PHR2/v
    7QbtWRFMKfGWV3+0cvaOt+5dgG31o73zdyH60a2snG0CyKOfP2oTlaWufaQS6ZVj1mYgzFoFMjtK
    0N8L+Mz3UcD+pH33UX3MI6aKo39GzJrlzlbOdmGDRXxDxMKRAf599LSyN4VE/E6m/o0Gh6jGmaBf
    19ZhSyIzdg4zACCqe2SaspYWaikpclsrZ7uwQWrKhTmY2lNPh8kV1how4sEzxwSih4CL4N4i4+sm
    6J8PfE18jfxBZtgb2Oe0xIZIyPeEqGV2PCW2BRomuwMzqsqlV9VrYEeftxgaoHa6FHvGb4L+vQMF
    mxXn6WOWI0C1zJ+Z6p6xcrxnFof3sW0wyD1eIas8Pd6iVdWz/qG2gcsKfeagaraCYsYz1b2nH7Xu
    py2MIgUIteydIngJ8Gh1j1o5I4XvLZQ9o941WEVOG3sZL2zjQhRIREol2gBHNKwcCN5t50RYPOg+
    F0SIaf1Ty7iLSmu2fB9TffvtogaoeVtII9QsnohDyovwqh4poawNLt7GJ2LPvkn75p3AZ7xnZGGS
    WbRFNw8y6h4RYt6ceqSES49rU2NboCFavMXI06nQ82y1xmSpczNqYZYZKBP07wA++/hIdb8Jvn/E
    knHT+42IKOuuWqSfeQ75Ce1z20WNMSrlshjUBaO+I44JjKq4h7xfAW/LyIEAtXNYdR9p7zD9FBVj
    0TtmPZUwp/bPbUAj8jwPm3LJ/o1aOkwDQreBW1f8i6GDWaboqerTzkGEVeSMmi1dwvZTzbL12DkF
    +GxH7HtZTtlHTlGQDxhZKddW+JF6H1bFwJQ/YPxPdjoeAYiM59s5LLQ88GezY1hlb/0/7GCnQT/S
    szc9z7Z4w2MP34iaQm7kdWgWQcRCrLbxxTJtT9CnncOqe0ZwRW+uYkBv7XfI+0U2WC0zcF+xqcqS
    X4+MmKNAvxGPiVAu3gp77E6+BHraORZ1z1inInHevRf43h8RPEV8KTG1LdIokQ9PCPhFnlyF+vfe
    hSerh4/6h1daARlr2zkedY8kDUTsgWGtnFF9MarfTbdOt0mNyaMeRPAsHAv0N/ENBMhBDuj/0lQR
    6qFaZ0sJ+hwI2PYzIiuH6VNasUBmEBHAwkHvZ5ld324HbQm+D/OhRu6uZZVGlIoQoEOJ4Hn1CfSE
    ulfdo0oUyRJjK2Yy9mm0urdYNSPq5IT03xWrXiIK1HLe6oiBILr0gdWvL4M6fcazgW8dCJjFTM+5
    0NZkCo+6j9pNu1zM3kHL1mxhGo4oI6llhI9QCJ6GxkzzLsndzXjsoMD2WaTy6qikihHrY4wAQ3g3
    2glZBvaiNBIWcGhKZmRDGqUO2Po2Ini65Sill/Fuda/1yygrZ/RuXGvdKe09oJ/v1EXa7WYNld1F
    6vUHPbXwNQvGcvBIATsZ47cm6DOsShPxt5m2zuy4nSHEUL6g1hcyeC5ZLqFMaHCFAFrElNFaBpUp
    ZIbaTGwjSE8+I1rdW60cZCCwCrHN2TejShozO/sFnMkPjW1SY/LAxrK5SlPTq/yI+LJwUrFnXDko
    IBUgo+s+jbJwmAFJs61WmoUtbeOgaYbsqOtVGN5NT5bTbyIsnFT7GSP6KNKfhOifEti3IivKzlik
    vaWyRxW5p8YzonStjUMkZqrHviakU6SFk7GKlYMKtah+ps3kmbOZ2Y2MzCIt6kbMcEQuUfbM4cVI
    A0Pq17NTyVk2DdMQ0YHt8kaU8Xorhx0ckHx8mdQvIxdprdlxt1f20VNGZidpdM2c0fC3dKooCycj
    Qe59HnZnuwCCbYR9KoLXpmf6aNQh40OLFN7Fs0dvRz165qhD6+wAeX4hHp8WTsbVaj6qoiratyy2
    TYSVI0QfZWY4zPnRyyj7skhjLIYvKRLeIrYNU6wfOAruOQBkRPd/6yZAyznLETn0jE2DKPNlyyZs
    kxqAB+bIdEdT8BKs6tlUMibnHu0MCe+M0f3Vu7vWkqduOWc5KrNGyNdxq4ycbcEG1oMcotq10TZq
    mqdlC2jvg4E26tengs+4atBA2qA2KHgWaS331V4DOkNZye1YBvYzgMQoBnYAYFS7tnjFLDJfqdoy
    UvH32q0mQNDMGwEEGnosohD9G3UBLIUbl2LktlBjQu5nWUFHvmj2vojfrg0urNpPYGfcQYRZZpzs
    7nJEdInjsiWzCHEiLu3L20UNxJKbii50oidYMbMAVG2gaV3aZ4TaQDkgZFylFNkMMdb+YCpoFmPf
    LyQDbt3HZh1e4jlGj9mogBRQ8zQMbVqK/F/EwrFMDxPqGasOJEhNHaYkitafmb6P9tXblEVYycYZ
    oUgQWKK+OWrnWDMNhPg7YqqcA0PGDOvGUgKFOWPastiKzuoRhqA2TkT/fgXsmdRCVBEjZ7Ra1ID2
    5ZeA92jteBkZs/one1gHOoO1vqYoZW9de7CIsyl9dntBY2TBzcwEmFmHxQOcepJNRqr44LaG2poi
    3A53Zg3OsyZosWnQDZPW2cErYG9RxGzDEOCylgnENJDRhxXngJCxwgBiLX3CzBQYZY8IPvR9lEGf
    3bLHEpaFGhPjEbK7VZnpnmUhNZV8xtNmBVHPj2xoQkWbVnuKFYC36J/bxC84Il8XrUPBjMDISO45
    RNiacmbJCEi1n7EKyCNry7DZfBY4s32oBH8OS8J+ZuMqYgcZc34tM91DU8ai4JzgznjSAIIKNOv6
    lkeRlyf3sZVLHEc2LnZwQF/HiBNpPP8j4Z9xZZ8rgX2FHSyYvh09I56p3s3/Y3tZg7Uqbks2ToI3
    400Dg6Xde1IW0dm3pajg0qWKE/b2ka84GvbIaW1GxtPFFfq8ZUBfjxyUEvaDGlfUArFV3VuBzjx/
    wj/j7iJqdh8vxn70mpn4lo2WVvejB5vZHSwj4w4Dgfd/v74vbdlO3Q0jshZ9wj3jjf2nJ4ZmpXrf
    edCEHrdl47utosnIyMh+m8o+IyMjI+N9sK83e96MjIyMhP2CA0ltgL8anisj403iqjYeU519ot6w
    n9WRj9uyIar3qc7HZ2Rkn7r+f7++T24vbpB1EsAr8P+zgWYk0P1q3tOvH9/Xths2thrcKC2DQA1q
    gFXObaCEfEYOKlw/9M7YHz8gbA9vJOyXFgH31yiFjFfDGPHYq7NfWe1U9nEtAZbK/kbTxTqhUYye
    6s74HxkJ8NHP47VReyAeJeLY9YC68ne1Ld7gIu0SRIEg969gAxSJzzCYOVBmZIxon9F9BX1cJS8/
    bqa+Gb+wGSCq4JfhHTh6aj1y+lkD3neEys8BIWOldmJt5xUUdCjYRwuwy/vddtOGV8W2uFmD1L10
    ppPoIBWZg58Az1i1z45OZkD6MzPLRwaJWyZVbMFf7MoDAtMwagfeiI1TQchrg8cTvpeMVP/aDNlj
    n1SiX7N9kF3HW3om8LTUSytkWQVQBbd4zgYddKE3YZ1xJ6gzUGQWbNFZtNa/qvCWqMWGRRJD0Kyf
    MAZsizaqOqhh9qBawcbRm9Ix07o6uQNmZES1I9b+RGbBqLLWZtGMX6/1abYvW/cDTOnL240bZhXd
    x+upasu0D1X2FVDtjIKJ7rw5UGREDgxef16zcSrQz7ziDel7GnPY2czU2C5qHNFTQnaErUSDiFD2
    iI3DDgpp+WSsqvZRWGvWBrMw6hVv2uwieqB7DOyjRkhL+mUFGpDnsoieL+xdeIrM981BIGOFAYG1
    O2uHFchs3avsqzxMSG0LNhJ0AQWxcdCpH3q5dhpFTxGwr3HJaWDGK4HtUflMX9cuy6D+jCp7dMBi
    ZwbT+vZ204aHzgSYqZ9VCWiKg6nFwS7e1MmdOCODXWy0zNorILY0Iea5LIZ+zDoQM2ZTy8Jeg6Gm
    8JEBwTP1q6T9VJVBx1O3JyNjtuhC7RZE0CCiTFs4jbRxevasNxNnBZEcDvsINenJXEEakUcNIA2E
    2YQloGphPpuokswZaeF4nitqT4xmvVh+BBRe1n660kBwC2XvGUUrMSXzNBwRbuEoIg8/uvxqDgIZ
    kSpfBFtbQ5U9K86YGTpyuxj627J9alsQEuwGBWThVGt4lgbi/ek1pIjNWQnyjGi4M369Z3GWFVQR
    Ak1Et49Ru4ndGDal7243boTMpiZmKme5f+/LZO5jzfFNKyfjCrHGVp1EoBht1Qg562bW1irJJ6bg
    Wnif3S5uWGgqE2u/iOibmqKUOgpvywJU735p5WSsYOGghQY9s2fEForqvxGcscx0lrVxLI0F8cGY
    PHR08YdtLDKwAbE7adnCaQnyjBkWDtPnvX1MgvqnZaZv5Qyr0F9fGwfdqLD6T8//Q+wjMXS2kWfs
    ZqSFUwXzty2QHN23exYOO3uY2afcz7dd8IK86ZcWVcz87IOmhyKY5x/ZqRLkGTMtnF6/ZDc9XS3o
    WFZZNkVOHQBW31TFbuRAPUC2Ie0DBgTUE7R0soR/RsTMjqlNhWaZrQLzCCsXmZ1o/XZabAMazqhG
    al08sf7sjus8jYztQCIxVTBzEMiwzM49tWSi+uCofoq+D3F+Zp5+eytlb0k7tGyuGqkErI1L8ynR
    qXGCPCNa1XssnFF9S5thI/1wF17Vi/Le2d3vAvLrlrBHs0oEhBzasET83vxOWDu/P6N247IdckTH
    z3j+gBBt4YjYxZfWpyJn15a9Nx6hNa3PrXp4CXpeJePJM9PGnbwtupGJQclHHX2WwH+nNWOdiTPZ
    NJGqfzf0Ye3xCDNYIbrM97dd1LCsaYToyVGsimAbDgN+5r7alBCtr59Qz7AqeTFAiy1L0mrzFlAz
    Am0X+8IrOouZtThLP9d244bJLKhYGg2zQOtR+4glhXY86zQ8B4L3qXrrSVOoLSNgf9wd1+9OqEel
    ZbKHmVzSv7ZBDck7NRQQcDMXX7UGtQcPAtFVNBPqCXqLSEAKeTH58qwYa62F7Y7+Gi3OpPNZWYXZ
    crC3NLyoRVpP7XqrUkCAvzttH7awGjKVTHWfEa3qWdsUVfi7s89GbZpkLdVIcN+26uWoRVpkABll
    5xxvj8wUEMCaQqbVEd9PAj9VvTYDH7EAuwuWleMVZ5HJEqNm1mF9cLuwoVkWaT0bIbwjvjYd3MFp
    p9faEdFLRbDqPqH+HtCPUvUe0O9gfzqD/ijQe2wc5ozpaQJ6tQPHLTvLPA1Ns1t6v1Flr/n71gwB
    zfaJgnoOBM8dBLyq3tL/LBujopS9dz1Nm3UjlukI22ca7C1bhhHPeWSVyx6sNcDvhBLZgZnACIsH
    bXRp57zXvkEew9gZ2nqTNstlZ8kW0O8BfZCpxjtNta+k7CPe+OiSx63GuAONKzrfPqJEa7Sdk8B/
    ln3DlCFBVb2IbQc7s/61G4Af2e8YZT/D6l4C9pG+vZaJEln8jG1MO6DsmRx+6yISs2iU4E7Qa2KA
    VfUituQEZpas9TmPGIuAvvZZXRqfxRtsIT5EBuib9OvZII2n1eh6ucLb4Xc5/LANriiNqJzct5x8
    lqXzt4C3ZTxngIisXInkzWv9SAO+1Wa1FkfTZkRMATWrFX6Zsh/h24vgO0gR8KMV8ix2jqY2Rlk5
    SL2cmnZOqnqDfRN5/iuSmozsWdnJPqkp+4iKmJH1cB63qcrz2JGHkWiNqiqWjcXK0WYTEYcmMwou
    gZ/2Dbqpb4SFg/YfZrbtrUrb+wyQwdJ7xOhtYB9xSDGqPiIhz/iDqKLXHhd5/GHktDGB/1zQ90SV
    p5YTMjtmQW8RUZq9ysy2W/2JSbm8NLYbNF4ktclbJoFV92iaJTNYsOoeed8j7ZwE/rqg91qqolil
    EfXmtf5TBVsj86j6UceLIt/ZVL8+GvZ1UCMTQlVEQt4ytbRMQZFcf+9hC6PsnAT+mqD32jeevoXm
    zqNZbZbsHFTVWzY2eqF9WYbctkgjZT+oqFo4SGNkFD6jOhBF4j07k7FzEvjvAb3FvokogWCdNVts
    Hm8GDioqkYFyifOgVyyEho6qkbVwmGmm9wfd9IG8vojNH9UAjQT+vUHvnVlbZ88eeKMg1xZhLWJK
    g/4MC8cdn4sb7jH3W8vxlsNjohTIFmTVoD/l5PfZz/7zPveTgZpN/Wrl3ffy8M++MwG+o4y5gskK
    +pEVKy2z2ijwWzc3sgu0sywc98CwLdAovVYOOgqjlSgti60RDbU3zY04zxapy42ovUvSxhL0ZtBr
    fQeZGYvgi67onpVRs2fUvomqiRNt4dxe2bM7LyupTAUAfZH2LtpfZb85rJwvMEv4VfT7iYo/+3s/
    zGqQTR3H3bWlM0PqfV/IrtuI7zxjzMCKDO7WDVRMwT8P5L/K35b1tqj8+2gLZ9jg8DfgOQtw/dnl
    cnL5+HP2mN79kdu3w2/kZ+v8zTwP8hg5ef9nn21pfDba99B7XFG+1+JsExkxs1ztb+aUJe9B4Ay8
    vyeXo36O/+97MlB8jVZPpIUz+rFDlb1H9aFqMOrQhKOy38Q/jUTUfWn49z3YC7BOYfHupePb18Z9
    WY8+Vf54NY+eA+H16pHsGiTXHVXqX/GpfOS1WXbQRlk402yez+RGXIIa/ZmdwzTWX0UdAfwj4L8/
    z7+fXM/A/mwxVyvwhjaiQgLfa+ukyo9X8yjoWZ/ZY9+gkNcg/pWxGXBRa1/LWzijbJzRVo7HAkKt
    HMSK2Ug7BlHtx+dpWS0WK6c07CDNHmt9p2nr3Av0Qs6GrSdIITC32jf/lL9bdk7rN+rrVxDyVRYt
    lXA17HtwKR2fWoOlOLxzFtwe4ItxQBDD/XtevQD/iwF+Qn8O5BGgI7aNpvDZOvMt6H8dfv0/8LoW
    6L/CLewi+1gE+CxHWXZLwZ5V9xq4NVXKwLOnpC2DxCa+RVfmtSKALCComcF3pMp/I/S99VAsOfVI
    Bceo3bI7Afse8P+R1yOqnqlt5cnAWcrCWQn2FitHsypm2TmeLBxkkBPA9kFnAzIZ+An9WMijoEfT
    LKNKDzN+vDcDxwN6LfvGkoGDePyXwH012KPqHvkfPQha1P9I+4dZYxBlgPOqe+ssbBTwnwb96APA
    vaBvQQotL+D16q3A/yd86iWj6nsWjjYjEtC7n9F2LoF9hJWDKFiLnSNiy6H3DgBiBDs7m0FUOjrj
    Yn38SOjfGfyzIB+h6K0ZNYjq/wI2zogc+69wOfUtC0eEy77pfX+XqvyVYC/CLdSK2BcxIxS6BAwK
    URYN+7mwM6VIW8cD8PJAwGuP89S8YawbpsQIat1Eqfqewt+DVf3vZRHu0BZLSfFH2DjRVg6iYHuP
    iRoQRMYpe4u9g3z+BbRzooEfrfJXg38d+PhqgL6mRJlTpSyWTSs/foaqj9opi6RcMt+rVdXXu8He
    q+7RVExxwlyCbRvrwNECM5KZ0xsIGf8eGThEGXTY7JwyuK2tAncL5BE1PwL0UZUmLVk40bCPUvXo
    sY2Xqfc7wB5V9xrURqt7RoF7MnJE2qmmqMIvwi+wiuE+kbV0ygXtcOYU2wN5xrbx+vSsffNVFP6o
    Wjh7x77RgI+qehF7uuUyFs4s2KMdPyINk4E58vxRufLIY72v3WLLsIBF7JyoHPynZORYOz+r5nvq
    UwTfGauV29bsG9ar32Wsov+Kvvmrp+rZoz97A/BlFs7VsEetHBEsDVME35HrhXaUwkfVOrqoawV+
    1IAxKi2zPAzwTEdHTphCz2fWTkOzWjSad/+VWHXfU/Re+8ZTxnjp09xWgr0G60h177Vz2MVUK/DF
    aeWwwP/vumr4bt+8+WrWZinEttE85apYGJZNUhrkLaDfJX5BFrFvRLCDS7TKltajB+vdYX+Futds
    mgjgixPsjFrXFl+twPeoa6vKj4L+VfCfvVGqB3wW9tZF2a/ou2K/Dr8eVfo7qOi/gu2SRWrgoGC3
    fs+PUvYrqns2zZN5jIjdsx+p7jWlXYK+Y0bZj87SKROBzjyHN7WShf0uMSURvh2f3mLR7IbbmU1T
    np2ymqpn7LbL42/y//Ms1I5Q96i3jyzUivgsHtSLZ3a3olBGAFwNSj26eNrdN1WNyLiRjiplzom1
    7IJFvHrr4iwC+RE+vVYmoefTzzh4/Nawv1rdW6wcbSCy2DfMBiYkN9/ynUR6+B7o3wX8sxdirdZN
    z75hattcUe4AgTxzJi1yUhXy2TI2zqPr2UfBPlLdCwlar2+PWDvIa2A+u4hNU8hjK/G6Zu+2LReC
    3evbapAXwdIrWY++CrcQi4I5+n4twH9J0DPHD6LFzrzVLm9d4nhlda9ZPSL+tEkL8BnffgTwEeVt
    XWiKtHTKgPY36kDoiB2xCOxb6ZWomq/S3yCFlEKYreijQG+p8X8rVX8X2HvUPTpYeIGP2jqRsC9i
    T3esAep+VHtYfRNW9IHgFp9+dOaNpQ595Oaoq0DPHBgeqeofC/vR6l6DoXYC1mzgawMNo96LEYCW
    jJkZCv+K9MwrShMzkBdFjaJZN9ZF2e+En5Gg34VfmB2t6mvC3qbumUO1I858RYBvhT3ifWufE+vB
    WyBZB7QJy8B1dSG0UYp+VC59Fb2+DQr6f47b90DQo9k3u+AHiUeo+lfaOCPVPft87MHb7BGJiGpn
    oR+t2lnQlwFT1VkKvzhhHq3oo8oUj94hO1PVe0FfRT+chFXyEar+cmvnTrBH1H3P1kFVNzsTYFU6
    A37GshGnukcaaDE8tgaDf7bKrwOA702tFLFvmhpxhOC/k8v/hD9OUKt5gyp6FPKMRy+N7+QWqn5V
    2HvUPesVe/x7zT6yqnzUyhmp7kdkpkTtKpxRF98Ldo+a96j6XfSc+iuPEWQPC/dsmmJ9equyrw5r
    Z+rgcCXsR6h7JBUTgXgRvuSyAI9FByz2zFd2kIu0Miy+5oj/eXU2jrfGzSzrpgq2cQqFfU/V9yD/
    T/hzZK0/yI5Z6XzWt1Hvd4Q9ou5FATTyf5Ha8JZa8+ggZfk9C2yoEi+EwkXqfNfgdmS1oUbDPqp6
    pXeHLGLhnP0dAXTmsPDWfZniZmiJhJ51c0tVvwLsI9W9OKwW5pxb1sP3Qt9i41TH5x6RacBuJ5/R
    KaJmNdXxuY1Kr/QuyKKLsjsAdc2r/ydcRs5X+Hr6taPuLYuz1rb46kJoUepeU+eiWC3Mc4hgpRes
    MxTPAd+IurY2uErCOsLWQP7HyF2KIzdMzViMRXbIMmmWiFf/D1T3rMqPsm5QC0creGZR9UsNAivA
    Plrde2vniNhKL1htnWhVH9moPIrcam1Yp8bIukB1flZ3BD1S98ZyMPg/h7r3lEZGvXlW0Z+1lUpA
    fXlv/2+h1zJysdaitqPLN1jBryl7xo7x7g6tHQ+zGuBosTW050ahz6Tc9YAgALxFbHVYtIwSK+hb
    gLfk1f8DlHwP/rtwiv4rWG69RdGjZ8pKUH9K2IMq/OxyTwWzdo6IPV+/EP/TeznCY46ydCo4sDBp
    iNrlqgDdOqOpIOSZWYp14RU9BNwC+p6q3w2QR6ydKEVfQeBba99EL8qmsl/IzmFAH/k/PZAvJKSt
    C06I+mYsFe11sSofAb8HABr4Kwl162vzlCxGQc/m1f9zXEYVPqL6maybUYuyS6r6O8F+lp3DPgeq
    8KMGNIuy12wcy6KSBmimwUdtMkJUuVXZ9wYWJINGSIi31L0ljZI9jMSq7L3qvpXrr5VKYC0cISCv
    2YyMqr9c7a8G+xHqnrFzGLVegEFCgAFHCKijnw1TQ10MkK4A9BHFb0lRRBYyo5V9FW4R1Qp3dOER
    Sa3sbZTy2Dce6FvKJ6D1cJC0VO07Q+wbcfajhP0gdc9YKx61HvF/WavGUrJXa9TWDUOapcOktEUo
    fM8iLHuKURW7at8F9+zZvPkdUM0o6HcA1pGbqtjqlsxOWe95srdalF0d9lbgM+UNkOfulUBAHjcC
    +iL23bPIlFSIjiGE6tZUf2Q5ARF7jfIa/IOoTKagl/cHLSlstXEsA0JrtyybRx/l00fbN6nsF7Fz
    GP++NWAgjxsB/d7t1QB8i+eOph5a1FSUpTMS5lbII9Dy7HpFvW90B6u3yJkF8pYiaBafnlmXsij3
    ZQaBj9wvqvz/HaPlcLn1+/jho3Vdfn/v/7u8Hf5GrCDPe2YyTv4a128/t//+3n4u//d376cePtv9
    f9f/ftbHy1Hn+WqzMM+sKKJccXQFSy2f3jM47EGgt1a57JU09lo3u3AplpVoA0vvlL0j7Kv0NwwV
    5b5nwBfRD984+7L+g9muQH7256P9/HX+3k5Av52AvwWiX/CfDawM7C2HuViB7/VbWdBb/X4txbKC
    tg+yKMt49hG+PFK7/uy1I1AfUbb41vbNnZU9M0j0lD0C/VZDQKBfJsAcgcffQHX/C/3jgFgOlz3A
    F9FLVyC2mhXw7LrCCNBbd8n2Tnrygv47EfTaRjIL+AXw6cUI+pqwn6Pua8fLLidWTm0A4vf6/QRq
    crgc+b6Z9ZQ/BSQboO63E8j/Kar+CP0I4DPq/kplP6oufdSB4REWzh4IdPZAcda+2Z2QR5X8bePv
    Jq9zdDomUt5AhK+lj2QOsaoyUplaVKiAishbn53JYReJS5VEFldRe8WjwC1WS6/++y42tW0tb4DM
    FCznzDJ16Zk0Wtanv5Wqf6qNI4qiP/r3zBfzn0qVE0UrQX5+JVQ+at0c1f3W+X3m4fd+elbOUeEX
    QOWLcOf6jlL40YuyPQDtgvn16MlTljLGOwlqC9wRy0YD/U6o+V6fGeXTLzsT+NwM1h47p+XfF/JL
    qoqt44V+7YAezbo5wp2FPQp6FPjlBPpn4EdAfyfYe2YV1iMGrYuzFu8enUXsTtBHbE5j8ukfGXdS
    9qP9exT6v+pey9JB/Hym2mML/H+N62bBvpxcPv4+U/b7wt59VA0fBPiITTTKFrJ494yCR+HOVLaM
    8OqF6He3V/Vvt3HObB3U2qkNpS8Nlb8TAGkNBEegWwHfgvz+v8cfL7PqvjRU/hH6iLK/St2PsHBW
    UfUs7HcD1HvPG2HbRGbdPNqnvzPsvbn3PfALofLP1H3Lxz8bADwFwc6gz2TeoIreo+73E3V/dhlV
    9hHevXY90/ktO4etmTcW2Gslgq2w3w2Q72XbeEGP1sCpgicTpI3zcP++tWBbQAjsHb+6Z+l4Dqb+
    O1zW4N+C/a+Krwdg/xlAj1o5PdB7lP1oGwetu8/aNyzsW9ehoEdgz6p27wJspKI/+44YwD9K1d/Z
    xhm9YHtWUkH78hHgFyNYjiD/A69DYN8CPwr8Qvr3SEYOs9EK9e4tyt5agnnUTllrUTQG9hFQt0Ke
    AT17vqy2IFufDPo3evaMvSPSrqPT+pI14GsLtj0Voql8DfK/C8h/HUVfG178HwD6Huxb4F9R3bMb
    qTx+fbRXzy7SWqDPgJ3dKOUBvSi2zWtBf3fYM+oe9e29efgI8Jk69D2I/Bl/Wxdnd4nJyhmt7lGF
    b4F9T81bYG8tdladoGdgHwF45IQpNNtmJ9Q8C/f07B9o56DgjwD+EUYt757JItDAj6ZWthR9T817
    rBwr8EX6VTGjYY8AIbKqpdXCsWyoYmDP3hZt2XhAj+TRM1l3t1b1b7BxPAu2UcAXRd3Xn/v1FP5Z
    oz7z6TcF/i3Ynyl6DfqMjdOycDaZWw1zBuw14FsXZj0HmSDK+wteh1o1X8EqdM4E/WsWZJ8Ie9Sy
    WQn4mq2D+pG9hdkz+G+dAcBr4TCgPwP8Bij6yEqYHs+eya0X4evuWEskWOwcC9CRmQOq5JGUyhGg
    f0TZ4jcq+0p26NnAL4St01P6SC2cHtyRXHrLhiot9dJr48zcSXuFX+9R9Wy+vQf+GtS/hO1Ubwr6
    mrC/l38/Evj1ADPG1jmCntklqB1UooGfBT5q4SCLs9biaD113wK8NfVydtEzT5kEVumjUP8a/le0
    mk/QJ+yXAP7xf+wnwO+pfMTLZw8q2UDwW60bK+hZVR9VOmG2jTMqx74Gw95yPXpIeoQ3Pxr08mTQ
    PxH2kf5+lKWzN1R+ETxF8wjwHlRah45sYithHJWJM0LZM7n2CPhnFD/bJX5TVQ0Evjc/foRlMwP0
    9cmgfyrsPQu22gzAU16hB/2e/9wCPAL9DYT/KMhHpV4iyh717kcq+1mefRT0LbZMNOTREghXgf4x
    8VRlHwX86Ho6v4AvBw9/FPSj4B4JejQbB83IYUA/y7NHMnFGWDkRnv4qSl6Du+XgERb0NWGfwPdm
    6ZypfS/0q+FnnwD6CGUvYt9YxSh8bzaOpwDaCsCvYltwjVbyI0Bf3wr6N3j2npRMD/Atxx2elf5l
    ob8ZwY6Cfwbot4aa1zJxZmyqGrlAa91cVZ3AZm5HMmusC68J+oT9UOBrGTrSgboX+PXEwz+q/SP0
    j5uhRPqHlJyBXoN/63oP6D2Ls6yqX9XGibByUOCzAwBy/2qAPHOMoBCwT9An7MMGA2TXbe+0K+Z/
    7g1lu4uefaKVMP79KQ3It8C/iy/rZuTi7N1tHI+ytxRKY2DOKPfeIOX15UeBXt4I+jfBnvXvPcDX
    1L32Ws7gv3cghwK/gODfO8DfRd8lqy3IekolWGwci5UzK8+eOYMWhT0CbdSWYXLkvb681bJhQV/l
    pfEmZb8S8Jlo+fn1B5JnA8AR+Gew3wHI7w4177VvRtg4I4BvVffWhVrW3mEXVfdAwI+0bKJBnyWO
    E/hu4DOvrfVzdpRfS92fgb133Rn0j+fp9s6XjViUjTh4fORJVSPV/Ujos9d5AO+xbLTPUxL0CfsV
    gX8GmOp4TYy9cwT+1lH5CPT3AZAfreotu2etnv0Mda+B1wPwHRxcIjNsIjNtkJz614PeO819+ntH
    gKGl+2keM+JNI0pYAyrip1t/exdjN+G8ehb0qx5eEgl+izJn7sPaNKxtY/HnEdBX8ntOZf9ihS8n
    il6kXTxNgmwddhH3DJIthV8Ol7XfvWMWy8nlCDVvtW9Qv76AHX6Gb8/Wurcqfla570awj8q0SdCn
    sp/yGWgqv6Ummd+oX40qfUbxRyh2S8YNat3MqmVvtXIsOfcoRPeAy57nsMBehF+AnenPvw70b1f2
    jF+OlEcW0scf6ee3FP6Z4m8p/TN1f3b5bP1gE31xeQToGcgXoNOPsnLECHuL1cP67l4fHlHzEf58
    gj6V/aUK3+rjM36+iM0OYX1+ZlbAZttYShl7Ui5HK3sv8BHAMjaPFer7ILhHpFMm6BP2jwO+EPBj
    BwAGxp7rInbIWg4rifbsGdCzwBcHbPcJ10VaNYiaR/35BH3C/nLgsz4+AnkL+L0evxXg1gVY5oCS
    Gap+lroX8WfrsHV3PJBnbCnWrhmt5l8P+oT9fVU+A/7e7QygWZhvwg9EIv5NVCWgnfeg0oK+p0Ba
    1M8edB92oPLYNWnbJOxfBXxG5Wug9yj+CIU+wrphYO8FPrqTFlH2Iviu0avgH6HgvYuvadsk7B8L
    fI/KR9V+hMc/A+7IQixr31wJe9TOYaA648ei4lmbJurIwAS9ITL1Ems01tTMX8hoG7JKYAOtB0ie
    1d3RavFYwW1V8ZYUSzTlckQ2Dgo1K/StwJeA+2vXsSreUurAWvogQZ/KftpnNUrlo9aOkBaPBm0U
    6qx9JOJbjB2h6qPVvQf4GrQRqLPPYVXxrFUzyrZJ0Cfsl7R1UGCNsHiQQcBqA7EK3qPoNdB72/cs
    hc/AftT1zOuMsmpSzSfsHwt8r8q3wH7GAMAod6+St6ZbRnn2FtCPUPoW+8UC9lnZNQn6hP0rbB1W
    5Y+CPjsAWBS7dRF2hn0z086xgt/zd+u2CMi3rhup5hP0CftbAN+q8i2Kf4T6Z9V6lKJvXcd8zlaA
    oFUwR0Pfez/0dYy2aRL0CftX2zoe6M+EvxfoUbaNpfiZBR6MlROl9K3gngX36A1RCfmE/StVPgq1
    UR6/BdqegSVKzV8JewaaHm/fA/IID74aPpdU8wn7BH6wteOBvnUQsN7fAnhP9k1xguQK4FvBHfW/
    EeCnZZOwz8/1QmsnAvYRg4LXqpkF+lnAj4S99TEeyEdZNgn6hP0rP9sVoD96QGAhj1o3ZUK7ZlTr
    LPhHq/YVIZ+gT9i/3toZAf1RA8FMJV8CvodK3oYAMGIx1zowWGA/CvKp5hP2+TkHqXwLLEvAdd6B
    w3KZ/awilT0Lfhb2kfCOyqKZBfkEfcI+VX4Q9COVf9R1HsCXQe3bo/AjwO8ZGK6wZ9KySdhnDFD5
    Vuh7YeuF9yi4RwO/Gm4f4etHAHy0ck81n7DPuBH0RwwCs1R8GdDGrfVYrDC96rLl74R8wj5jwudv
    UbAl8O8oayjidY0C/UjgjxwAPLcl5BM2GTdT+Qz0I8EfdRsKeevANwL8bB2daFBHq/bRkE/QJ+wz
    FoL+yBmBVbWXhdqxtaxCNPgjgT7j+L+EfMI+w/GdjC7CFqXGI22aMrldV+PtXtBfAXjP+03IJ+wz
    bgh9j+K2gnwFy2aEuveAPPpxMyGfoE/YZwz+jjzKN3oWEAF75nMY1aa9Oz89A8BItT7qJKiEfMI+
    YyHojwZ/pIIf+RlEgSoiP38WyBPyL4u//AgS+gPAv5KQqKQqr87/NVr1eyA+8jzXhHzCPmOxWVkJ
    uE+ZfNuswaNOeI660G0J+YR9xgugP1rxzxpcVoO9F/gzbo94vwn5F4Ei433QnwX32e/paiBG2CsJ
    +IyEfX6vl4F/xPOt0oZHLl5edb+EfEIh46Xfbxl0//LgtrrCIJCAz0jY53e9JPxntsdyIdhGw7pO
    fo0ZCfuMF3zn5SaPu4Oyv+JxCfjs+Bn5/V/yXOXC9zwTdivZKwn57OwZ2RYeNZDcRd3PAHICPjt4
    RsbwdlFu8J6uhGG9yXNmZKfOyHaSbXABACfgM7KjZdyqzdy9fdaH/Z+M7LgZ2YZe2X7rS/93RnbU
    jIxsUwn2jOyYGdnOMhLsGdkJM7L9JdgzMrKzZWTbTKBnZCTsM7LtJsgzMjIyMjIyMjIyMjIyMjIy
    MjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIeEv8nwAAB
    GtCJEiHlkAAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 378.6842 233.7644)">
          </image>
          <g class="orange-circle click-pin" data-id="2" data-title-pl="Wytwarzanie" data-title-en="Generation" data-color="#F47C20">
            <circle class="st584" cx="416.653" cy="271.689" r="25.851"/>
            <circle class="st582" cx="416.653" cy="271.689" r="25.851"/>
          </g>
        </g>
        <path class="st584 pin pin-label" data-id="2" data-title-pl="Wytwarzanie" data-title-en="Generation" data-color="#F47C20" d="M395.356,273.637l-11.872-6.854c-2.966-9.839-12.13-17.06-22.903-17.06H211.417
          c-13.153,0-23.915,10.762-23.915,23.915v0c0,13.153,10.762,23.915,23.915,23.915h149.165c10.773,0,19.937-7.221,22.903-17.06
          L395.356,273.637z"/>
        <g class="pin pin-pl" data-id="2" >
          <path class="st56" d="M216.545,264.988h3.205c0.324,0,0.468,0.108,0.504,0.36l1.423,9.921h0.378l1.675-10.281h2.7
            c0.198,0,0.307,0.108,0.307,0.27c0,0.072-0.019,0.181-0.036,0.271l-2.269,11.56c-0.055,0.252-0.234,0.414-0.54,0.414h-4.142
            l-1.423-10.281h-0.396l-1.423,10.281h-4.141c-0.307,0-0.486-0.162-0.541-0.414l-2.269-11.56c-0.018-0.09-0.036-0.198-0.036-0.271
            c0-0.161,0.108-0.27,0.307-0.27h2.701l1.674,10.281h0.378l1.423-9.921C216.058,265.114,216.239,264.988,216.545,264.988z"/>
          <path class="st56" d="M233.554,271.11h0.54l2.449-6.122h2.809c0.198,0,0.307,0.144,0.307,0.324c0,0.072-0.036,0.162-0.055,0.198
            l-4.231,8.174v3.403c0,0.234-0.126,0.414-0.378,0.414h-2.341c-0.216,0-0.378-0.162-0.378-0.414v-3.403l-4.231-8.174
            c-0.018-0.036-0.054-0.126-0.054-0.198c0-0.181,0.108-0.324,0.307-0.324h2.809L233.554,271.11z"/>
          <path class="st56" d="M247.181,277.159c0,0.216-0.108,0.342-0.307,0.342h-2.341c-0.197,0-0.342-0.108-0.342-0.324v-9.957H240.5
            v-1.854c0-0.234,0.126-0.378,0.415-0.378h9.578c0.252,0,0.378,0.126,0.378,0.396v1.836h-3.69V277.159z"/>
          <path class="st56" d="M258.88,264.988h3.205c0.324,0,0.468,0.108,0.504,0.36l1.423,9.921h0.378l1.675-10.281h2.7
            c0.198,0,0.307,0.108,0.307,0.27c0,0.072-0.019,0.181-0.036,0.271l-2.269,11.56c-0.055,0.252-0.234,0.414-0.54,0.414h-4.142
            l-1.423-10.281h-0.396l-1.423,10.281h-4.141c-0.307,0-0.486-0.162-0.54-0.414l-2.27-11.56c-0.018-0.09-0.035-0.198-0.035-0.271
            c0-0.161,0.107-0.27,0.306-0.27h2.701l1.674,10.281h0.378l1.423-9.921C258.393,265.114,258.574,264.988,258.88,264.988z"/>
          <path class="st56" d="M278.555,274.422h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L278.555,274.422z M274.233,272.531
            h3.763l-1.584-5.312h-0.595L274.233,272.531z"/>
          <path class="st56" d="M287.715,277.501h-2.558c-0.306,0-0.432-0.162-0.432-0.45v-11.613c0-0.252,0.144-0.45,0.432-0.45h6.158
            c3.007,0,4.627,0.72,4.627,3.727c0,1.639-1.116,2.863-2.322,3.296l3.025,4.969c0.054,0.091,0.126,0.217,0.126,0.288
            c0,0.145-0.072,0.234-0.253,0.234h-2.916l-2.396-4.375c-0.144-0.252-0.359-0.433-0.846-0.433h-2.646V277.501z M287.715,270.929
            h2.845c0.773,0,1.386-0.216,1.782-0.558c0.36-0.343,0.685-0.774,0.685-1.495c0-1.134-0.595-1.656-1.945-1.656h-3.366V270.929z"/>
          <path class="st56" d="M301.736,275.269h6.59v1.584c0,0.396-0.198,0.648-0.63,0.648h-8.985c-0.414,0-0.612-0.198-0.612-0.576
            v-1.242c0-0.271,0.127-0.504,0.217-0.612l6.481-7.851h-6.626v-1.62c0-0.378,0.216-0.612,0.648-0.612h8.841
            c0.486,0,0.666,0.198,0.666,0.648v1.08c0,0.324-0.145,0.54-0.252,0.685L301.736,275.269z"/>
          <path class="st56" d="M318.622,274.422h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L318.622,274.422z M314.301,272.531
            h3.763l-1.584-5.312h-0.595L314.301,272.531z"/>
          <path class="st56" d="M327.836,267.22h-0.378l0.27,10.281h-2.574c-0.198,0-0.36-0.145-0.36-0.378v-11.649
            c0-0.307,0.198-0.486,0.522-0.486h3.889l3.709,10.281h0.378l-0.342-10.281h2.503c0.252,0,0.468,0.162,0.468,0.45v11.686
            c0,0.216-0.144,0.378-0.342,0.378h-4.142L327.836,267.22z"/>
          <path class="st56" d="M342.11,277.159c0.018,0.216-0.127,0.342-0.307,0.342h-2.358c-0.198,0-0.324-0.09-0.324-0.306v-11.848
            c0-0.217,0.162-0.36,0.396-0.36h2.214c0.252,0,0.379,0.126,0.379,0.396V277.159z"/>
          <path class="st56" d="M345.673,277.501c-0.216,0-0.324-0.09-0.342-0.306v-11.848c0-0.217,0.18-0.36,0.396-0.36h8.426
            c0.252,0,0.379,0.126,0.379,0.396v1.836h-6.212v2.917h5.726v2.143h-5.726v2.989h6.212v1.891c0.018,0.216-0.091,0.342-0.343,0.342
            H345.673z"/>
        </g>
        <g class="pin pin-en" data-id="2">
          <path class="st56" d="M232.692,276.153c0,0.324-0.108,0.594-0.271,0.774c-0.18,0.198-0.45,0.306-0.828,0.414
            c-1.116,0.342-3.024,0.486-4.465,0.486c-2.161,0-3.565-0.576-4.412-1.692c-0.846-1.099-1.17-2.737-1.17-4.862
            c0-2.07,0.486-3.673,1.513-4.753s2.574-1.639,4.735-1.639c1.404,0,3.331,0.198,4.537,0.666c0.234,0.091,0.342,0.252,0.342,0.45
            c0,0.108-0.018,0.217-0.035,0.288l-0.469,1.53c-1.404-0.396-2.953-0.702-4.015-0.702c-1.351,0-2.179,0.36-2.684,1.063
            c-0.504,0.702-0.666,1.747-0.666,3.115c0,1.548,0.108,2.646,0.54,3.367c0.433,0.702,1.171,1.044,2.431,1.044
            c0.45,0,1.405-0.072,1.927-0.216v-4.609h2.503c0.324,0,0.486,0.162,0.486,0.486V276.153z"/>
          <path class="st56" d="M236.234,277.594c-0.216,0-0.324-0.09-0.342-0.306V265.44c0-0.217,0.18-0.36,0.396-0.36h8.426
            c0.252,0,0.379,0.126,0.379,0.396v1.836h-6.212v2.917h5.726v2.143h-5.726v2.989h6.212v1.891c0.018,0.216-0.091,0.342-0.343,0.342
            H236.234z"/>
          <path class="st56" d="M250.869,267.312h-0.378l0.27,10.281h-2.574c-0.198,0-0.36-0.145-0.36-0.378v-11.649
            c0-0.307,0.198-0.486,0.522-0.486h3.889l3.709,10.281h0.378l-0.342-10.281h2.503c0.252,0,0.468,0.162,0.468,0.45v11.686
            c0,0.216-0.144,0.378-0.342,0.378h-4.142L250.869,267.312z"/>
          <path class="st56" d="M262.496,277.594c-0.216,0-0.324-0.09-0.342-0.306V265.44c0-0.217,0.18-0.36,0.396-0.36h8.426
            c0.252,0,0.379,0.126,0.379,0.396v1.836h-6.212v2.917h5.726v2.143h-5.726v2.989h6.212v1.891c0.018,0.216-0.091,0.342-0.343,0.342
            H262.496z"/>
          <path class="st56" d="M277.077,277.594h-2.558c-0.306,0-0.432-0.162-0.432-0.45V265.53c0-0.252,0.144-0.45,0.432-0.45h6.158
            c3.007,0,4.627,0.72,4.627,3.727c0,1.639-1.116,2.863-2.322,3.296l3.025,4.969c0.054,0.091,0.126,0.217,0.126,0.288
            c0,0.145-0.072,0.234-0.253,0.234h-2.916l-2.396-4.375c-0.144-0.252-0.359-0.433-0.846-0.433h-2.646V277.594z M277.077,271.021
            h2.845c0.773,0,1.386-0.216,1.782-0.558c0.36-0.343,0.685-0.774,0.685-1.495c0-1.134-0.595-1.656-1.945-1.656h-3.366V271.021z"/>
          <path class="st56" d="M296.032,274.514h-4.88l-0.918,3.079h-2.629c-0.271,0-0.378-0.162-0.378-0.342
            c0-0.055,0.018-0.145,0.036-0.198l3.799-11.451c0.108-0.324,0.342-0.522,0.702-0.522h3.637c0.36,0,0.612,0.198,0.721,0.522
            l3.799,11.451c0.019,0.054,0.036,0.144,0.036,0.198c0,0.18-0.108,0.342-0.378,0.342h-2.629L296.032,274.514z M291.711,272.624
            h3.763l-1.584-5.312h-0.595L291.711,272.624z"/>
          <path class="st56" d="M307.587,277.252c0,0.216-0.108,0.342-0.307,0.342h-2.341c-0.197,0-0.342-0.108-0.342-0.324v-9.957h-3.691
            v-1.854c0-0.234,0.127-0.378,0.414-0.378h9.579c0.252,0,0.378,0.126,0.378,0.396v1.836h-3.69V277.252z"/>
          <path class="st56" d="M316.136,277.252c0.018,0.216-0.126,0.342-0.307,0.342h-2.358c-0.198,0-0.324-0.09-0.324-0.306V265.44
            c0-0.217,0.162-0.36,0.396-0.36h2.214c0.253,0,0.379,0.126,0.379,0.396V277.252z"/>
          <path class="st56" d="M325.028,264.882c4.537,0,6.14,1.747,6.14,6.464c0,4.825-1.603,6.482-6.14,6.482
            c-4.538,0-6.14-1.657-6.14-6.536C318.889,266.556,320.49,264.882,325.028,264.882z M328.125,271.345
            c0-3.007-0.576-4.268-3.097-4.268s-3.097,1.261-3.097,4.286c0,3.097,0.576,4.267,3.097,4.267S328.125,274.461,328.125,271.345z"
            />
          <path class="st56" d="M336.961,267.312h-0.378l0.27,10.281h-2.574c-0.198,0-0.36-0.145-0.36-0.378v-11.649
            c0-0.307,0.198-0.486,0.522-0.486h3.889l3.709,10.281h0.378l-0.342-10.281h2.503c0.252,0,0.468,0.162,0.468,0.45v11.686
            c0,0.216-0.144,0.378-0.342,0.378h-4.142L336.961,267.312z"/>
        </g>
      </g>
    </g>
</svg>`;

var importSVG = function () {
  $(window).scroll(function () {
    if ($('.svg-ready').length <= 0) {
      var aboveHeight = $('.content-box').outerHeight();
      if ($(window).scrollTop() > aboveHeight) {
        $('#svg').addClass("svg-ready")
        $('#svg').html(svg)
        
        if($('html').attr('lang') === "pl"){
          $('.pin-pl[data-id="1"]').addClass('active');
        } else {
          $('.pin-en[data-id="1"]').addClass('active');
        }
        $('.pin-label[data-id="1"]').addClass('active');
      }
    }
  })
}()